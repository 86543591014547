/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect,  AntDatePicker,
     InputText,  AutoComplete,
    Terms, ImageViewer, PopUpModal,  ImageCropHook, ReactDatePicker
} from '../../../components';
import { CustomToggle, CustomSearchMenu } from '../../../components/customdropdown';
import { default as _images } from "../../../images";
import Translate from '../../../constants/translate';
import Contacts from '../../contacts/list/index';
import ContactInfo from '../../contacts/contactInfo'
import CommonHelper from '../../../services/common';
import DLScan from '../../contacts/digitalLicenseScan'
import SignOptions from '../../common/signOptions'
//import Languages from 'lookups-languages';
import { objAttendees, attendeesMapVM, defaultMandatoryAttendees, eventProMapVM } from '../viewModel'
import { contactResponseCode } from '../../../services/enum';
import { firestoreDB, storageBucket } from '../../../services/helper';

import AttendeeInfo from '../../eventsProAttendees/details'

//#region  ASSIGN VARIABLES
let titles = [];
let license_state = [];
let defaultCountry = '';
let license_type = [];
let dietary_preferences = [];
let event_slots = [];
let eventDates = [];
let _enquiryAllStatus = [];
let _dealers = [];
let genders = [];
let sources = [];

const opts = [
    { active: true, value: 'true', label: <Translate text={'Opt In'} /> },
    { active: true, value: 'false', label: <Translate text={'Opt Out'} /> }
]
const sign_type = [
    { active: true, ico: 'ico icon-dynamic-checkbox', value: 'verbal', label: 'Verbal consent', text: 'Has the customer verbally provided you with their consent to capture and record their details/information' },
    { active: true, ico: 'ico icon-sign', value: 'sign', label: 'Sign consent on-sight', text: 'Get signature on-site from customer' }
]

let _fileInfo = {
    file: null,
    id: '',
    type: '',
    title: ''
}

//#endregion

class AddContact extends Component {
    constructor(props) {
        super(props);
        this.isNewContact = false;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('eventsProAttendees');
        this.newDocID = null;
        this.selectedClientID = null;
        this.selectedGroupID = null;
        this.mandatoryFields = defaultMandatoryAttendees;
        this.state = {
            fields: Object.assign({}, objAttendees),
            existingContact: {},
            errors: {},
            clientUsers: [],
            countries: [],
            phoneCodes: [],
            states: [],
            isLoading: false,
            showContacts: false,
            showTerms: {
                show: false,
                isVerbal: false,
                origin: null
            },
            fileInfo: Object.assign({}, _fileInfo),
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            showContactInfo: {
                show: false,
                data: null,
                isAttendee: false
            },
            showDLScan: false,
            showTermsOption: {
                show: false,
                origin: null
            },
            attendeeInfoModal: { show: false, docID: null },
            imageViewer: { show: false, images: null }
        }
    }


    //#region FUNCTIONS

    //#region EXISITING CONTACT

    // handleXContactClose = (type, objData) => {

    //     //console.log('handleXContactClose', type, objData);
    //     //let state = Object.assign({},this.state.fields);
    //     if (type && !_.isEmpty(objData)) {
    //         if (type === 'fillMissing') {
    //             this.fillMissingContact(objData);
    //         }
    //         else if (type === 'replaceAll' || type === 'merge') {
    //             this.replaceContact(objData)
    //         }
    //     }
    //     else if (type) {
    //         this.props.handleClose(type, 'delete');
    //     }

    //     this.setState({
    //         showContactInfo:
    //         {
    //             show: false,
    //             data: null
    //         }
    //     });
    // }

    // fillMissingContact = (objData) => {
    //     const fields = Object.assign({}, this.state.fields);

    //     for (let [key, value] of Object.entries(objData)) {
    //         if (fields[key] === null || fields[key] === undefined || fields[key] === false || fields[key] === '' || fields[key] === [] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) {
    //             fields[key] = value;
    //         }
    //     }
    //     fields.documentID = objData.documentID;
    //     fields.owner = objData.owner;
    //     fields.addedBy = objData.addedBy;
    //     fields.addedDate = objData.addedDate;

    //     if (_.isEmpty(fields.owner)) fields.owner = localStorage.uid;
    //     if (_.isEmpty(fields.country) && !_.isEmpty(fields.state)) fields.country = defaultCountry;

    //     this.newDocID = objData.documentID;
    //     this.isNewContact = false;
    //     this.setState({ fields: fields, errors: {} }, () => { this.loadSettings(); });
    // }

    // replaceContact = (objData) => {
    //     const fields = Object.assign({}, objAttendees);
    //     for (let [key, value] of Object.entries(objData)) {
    //         if (key === 'dob' || key === 'licenseExpiry')
    //             fields[key] = value && moment(value).isValid() ? value : null;
    //         else
    //             fields[key] = value;
    //     }

    //     if (_.isEmpty(fields.owner)) fields.owner = localStorage.uid;
    //     if (_.isEmpty(fields.country) && !_.isEmpty(fields.state)) fields.country = defaultCountry;

    //     this.newDocID = fields.documentID;
    //     this.isNewContact = false;
    //     this.setState({ fields: fields, errors: {} }, () => { this.loadSettings(); });
    // }

    handleXContactShow = (data, isAttendee) => {
        if (!_.isEmpty(data)) {
            data = this.convertContactVM(data);
            if (isAttendee) {
                this.setState({
                    attendeeInfoModal: { show: true, docID: data.documentID }
                });
            }
            else {
                this.setState({
                    showContactInfo:
                    {
                        show: true,
                        data: data,
                        isAttendee: isAttendee ? true : false
                    }
                });
            }
        }
    }

    handleAttendeeInfoClose = () => {
        this.setState({
            attendeeInfoModal: { show: false, docID: null }
        });
    }
    //#endregion

    //#region PAGE LOAD

    handleContactClose = (ID, data) => {
        if (data === 'enquiry') {
            this.props.handleClose(ID, 'delete');
            this.setState({ showContacts: false });
        }
        else if (ID && !_.isEmpty(data)) {
            let objData = Object.assign({}, this.state.fields);

            for (let [key, value] of Object.entries(data)) {
                if (attendeesMapVM.hasOwnProperty(key) || key === 'mergeID') {
                    if (key === 'dob' || key === 'licenseExpiry')
                        objData[key] = value && moment(value).isValid() ? value : null;
                    else
                        objData[key] = value;
                }
            }

            objData.contactID = ID;
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === undefined || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            this.setState({
                fields: objData,
                errors: {},
                showContacts: false,
                showContactInfo:
                {
                    show: false,
                    data: null,
                    isAttendee: false
                }
            });

        }
        else if (ID) {
            let _contact = data;
            const { dealersettings, groupUsers, clientUsers } = this.props;

            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {
                    _contact = doc.data();
                }

                let objData = Object.assign({}, this.state.fields);

                for (let [key, value] of Object.entries(_contact)) {
                    if (attendeesMapVM.hasOwnProperty(key) || key === 'mergeID') {
                        if (key === 'dob' || key === 'licenseExpiry')
                            objData[key] = value && moment(value).isValid() ? value : null;
                        else
                            objData[key] = value;
                    }
                }

                objData.contactID = ID;
                this.setState({
                    fields: objData,
                    errors: {},
                    showContacts: false,
                    showContactInfo:
                    {
                        show: false,
                        data: null,
                        isAttendee: false
                    }
                }, () => { this.loadSettings(); });
            });
        }
        else {
            this.setState({
                showContacts: false,
                showContactInfo:
                {
                    show: false,
                    data: null,
                    isAttendee: false
                }
            });
        }


    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadAttendees(this.props);

    }

    componentWillUnmount() {
    }

    async loadAttendees(props) {
        const { dealersettings, show, docID, clientID, eventsPro } = props;
        this.isNewContact = docID ? false : true;
        let fields = Object.assign({}, objAttendees);
        const states = [];
        if (show === true && docID) {
            this.newDocID = docID;
            const refData = firestoreDB(dealersettings).firestore().collection('eventsProAttendees').doc(docID);
            refData.get().then((doc) => {
                if (doc.exists) {
                    const objData = Object.assign({}, doc.data());

                    for (let [key, value] of Object.entries(objData)) {
                        fields[key] = value;
                    }
                    fields.documentID = doc.id;

                    if (_.isNil(objData?.optinSMS)) fields.optinSMS = null;

                    if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries)) {
                        fields.country && dealersettings.states &&
                            dealersettings.states.filter(e => e.country_code === fields.country).forEach((doc) => {
                                states.push({
                                    value: doc.code,
                                    active: _.isBoolean(doc.active) ? doc.active : true,
                                    label: doc.name
                                });
                            });
                    }

                    // if (fields.contactID) {
                    //     const refContactData = await firestoreDB(dealersettings).firestore().collection('contactDetails').doc(fields.contactID).get();
                    //     if (refContactData.exists) {
                    //         // const objcontactData = Object.assign({}, refContactData.data());
                    //         // for (let [key, value] of Object.entries(objcontactData)) {
                    //         //     _contact[key] = value;
                    //         // }
                    //         // fields.contact = _contact;
                    //         // fields.contactID = _contact.documentID;

                    //     }
                    // }

                    this.setState({ fields: fields, existingContact: fields, states: states }, () => { this.loadSettings(); this.errorOnLoad() });

                } else {
                    console.error("No such document2!");
                }
            });
        }
        else if (show === true && (!docID)) {
            this.newDocID = this.ref.doc().id;
            fields.documentID = this.newDocID;
            fields.owner = dealersettings ? dealersettings.id : '';
            // let currentClientID = clientID && dealersettings?.clients?.some(a => a.documentID === clientID) ? clientID : dealersettings.client.id;
            // fields = CommonHelper.setClientOrGroupEventsPROContactClause(fields, dealersettings, currentClientID, true);

            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
                let countryCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
                let phone_code = dealersettings.countries.filter(m => m.code === (eventsPro?.country || countryCode))[0];
                if (!_.isEmpty(phone_code)) {
                    fields.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
                }
            }

            this.setState({ fields: fields }, () => { this.loadSettings(); this.errorOnLoad() });
        }
    }

    loadSettings = async () => {
        const { dealersettings, clientUsers, groupUsers, eventsProID, eventsPro } = this.props;

        let eventSettings = null;
        if (eventsProID) {
            const refSettingsData = await firestoreDB(dealersettings).firestore().doc(`eventsPro/${eventsProID}/settings/${eventsProID}`).get();
            if (refSettingsData.exists)
                eventSettings = Object.assign({}, refSettingsData.data());
        }


        const { fields } = this.state;
        const countries = []; const states = []; const phoneCodes = [];

        this.selectedClientID = fields && fields.clientID ? fields.clientID : null;
        this.selectedGroupID = fields && fields.groupID ? fields.groupID : null;

        _dealers = [];
        dealersettings.clients.forEach(rec => {
            _dealers.push({
                label: rec.name,
                value: rec.id,
                data: rec,
                active: true,
                projectId: rec.projectId
            })
        })


        let _clientData = this.selectedClientID && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
            && !_.isEmpty(dealersettings.group.clients[this.selectedClientID]) ? dealersettings.group.clients[this.selectedClientID] : dealersettings.client;

        if (_clientData) {
            const setttings = _clientData.settings;
            const event_setttings = _clientData.eventsPro;

            //defaultCountry = _clientData.countryCode ? _clientData.countryCode.toUpperCase() : 'AU';
            defaultCountry = eventsPro?.country ? eventsPro?.country : dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';

            _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.filter(e => !_.includes(['delivered', 'pendingLost', 'prospectLost', 'pending', 'closeWon'], e.value) && !Boolean(e.afterSold)).forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            dietary_preferences = [];
            eventSettings?.dietary && eventSettings.dietary.forEach((doc) => {
                dietary_preferences.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            sources = [];
            eventSettings?.source && eventSettings.source.forEach((doc) => {
                sources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            event_slots = [];
            eventSettings?.slots && eventSettings.slots.forEach((doc) => {
                event_slots.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    sortTime: doc?.startTime?.seconds ? moment.unix(doc.startTime.seconds).tz(eventsPro.timezone).format('HH:mm') : `0`
                });
            });

            eventDates = []
            for (var m = moment.unix(eventsPro.startDate.seconds).tz(eventsPro.timezone).startOf('day'); m.isSameOrBefore(moment.unix(eventsPro.endDate.seconds).tz(eventsPro.timezone).startOf('day')); m.add(1, 'days')) {
                eventDates.push({
                    value: m.format('YYYY-MM-DD'),
                    label: m.format('DD/MM/YYYY'),
                })
            }

            if (!_.isEmpty(event_slots) && fields.eventSlot && _.find(event_slots, { value: fields.eventSlot })?.date)
                fields.eventDate = _.find(event_slots, { value: fields.eventSlot })?.date;

            titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            genders = [];
            setttings.genders && setttings.genders.forEach(doc => {
                genders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            license_type = [];
            setttings.licenseType && setttings.licenseType.forEach(doc => {
                license_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                if (doc.phone_code) {
                    phoneCodes.push({
                        country_code: doc.code,
                        value: doc.phone_code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                    });
                }

                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });

            });

            fields.country && dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === fields.country).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

            license_state = [];
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });


            if (!_.isEmpty(event_setttings?.mandatoryFields) && !_.isEmpty(event_setttings?.mandatoryFields?.attendees))
                this.mandatoryFields = event_setttings.mandatoryFields.attendees.split(',');

        }

        let _clientUsers = [];
        let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        if (fields.clientID && !_.isEmpty(groupUsers)) {
            _clientUsers = groupUsers.filter(e => e.clientID === fields.clientID);
        }
        else if (!_.isEmpty(_allUsers)) {
            _.uniq(_allUsers.map(v => _.pick(v, ['id']).id)).map(e => { return { ..._.find(_allUsers, { id: e }) } }).forEach((rec) => {
                _clientUsers.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                });
            });
        }

        this.setState({
            fields: fields,
            clientUsers: _clientUsers,
            countries: countries,
            phoneCodes: phoneCodes,
            states: states
        });
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = _.trim(value);
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str ? str.replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    handleOnNameChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = _.trim(value);
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase().replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '') + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {

                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0).replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '');
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str ? str.replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }


    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9 \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[name] = _.trim(value);
            this.setState({ fields: state }, () => { this.errorChange(name) });
        }
    };

    handleAddPhoneCode = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = value ? value : '';
        this.setState({ fields: state });
    };

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

        if (!_.isEmpty(errors)) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }
        //console.log('errorChange', isScroll, document.getElementById(key), errors, key)
        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }

    errorOnLoad = () => {
        if (!_.isEmpty(this.props.mandatoryFields)) {
            let errors = {};
            let fields = Object.assign({}, this.state.fields);
            let errorClass = 'input_error';
            let errorUploadClass = 'input_upload_error';
            let _mandatoryFields = this.mandatoryFields;

            if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
                _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

            for (let [key] of Object.entries(fields)) {

                if ((_mandatoryFields && _mandatoryFields.indexOf(key) >= 0)) {

                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                        if (key === 'licenseFrontURL' || key === 'licenseBackURL') {
                            if (!fields.isDLScan) errors[key] = errorUploadClass;
                        }
                        else
                            errors[key] = errorClass;
                    }

                    if (key === 'email') {
                        if (typeof fields[key] !== "undefined") {
                            let lastAtPos = fields[key].lastIndexOf('@');
                            let lastDotPos = fields[key].lastIndexOf('.');

                            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {
                                errors[key] = errorClass;
                            }
                        }
                    }
                }
            }

            this.setState({ errors: errors });
        }
    }

    handleReactSelectChange = (e, data) => {
        //console.log('handleReactSelectChange', e, data)
        //e.preventDefault();
        let state = Object.assign({}, this.state.fields);
        if (e) {
            if (data.name === 'clientID')
                state.clientName = e.label;

            state[data.name] = e.value;
        }
        else {
            state[data.name] = '';
        }

        if (data.name === 'country') {
            if (e)
                this.handleCountryChange(e.value);
            else
                this.handleCountryChange('');
            state['state'] = '';
        }

        if (data.name === 'eventDate') {
            state['eventSlot'] = null;
        }

        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleSlotSelect = (value) => {
        let state = Object.assign({}, this.state.fields);
        state['eventSlot'] = value;
        this.setState({ fields: state }, () => { this.errorChange('eventSlot') });
    }

    handleCountryChange = make => {
        const { dealersettings } = this.props;
        const states = [];
        if (make && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === make).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        this.setState({ states: states });
    }


    //#endregion

    //#region DATEPICKER

    handleDateChange = (val, name) => {
        //console.log('handleDateChange', val, name)
        let state = Object.assign({}, this.state.fields);
        if (val)
            state[name] = moment(val).format('YYYY-MM-DD');
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name) });
    };

    //#endregion

    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, type, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.selectedClientID ? this.selectedClientID : this.props.dealersettings.client.id;
        if (!this.newDocID) {
            this.newDocID = this.props.docID ? this.props.docID : this.ref.doc().id;
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/contacts/${this.newDocID}/${type}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                //console.log(error);   
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/contacts/${this.newDocID}/${type}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.fields);
                        state[id] = dataURL;
                        this.setState({ fields: state }, () => { this.errorChange(id) });
                    })
            })
    };

    isImageUploading = () => {
        let state = Object.assign({}, this.state.fields);
        if ((state.licenseBackURL && state.licenseBackURL.indexOf('base64') > 0) ||
            (state.licenseFrontURL && state.licenseFrontURL.indexOf('base64') > 0) ||
            (state.userImageURL && state.userImageURL.indexOf('base64') > 0))
            return true;
        else
            return false;
    }


    //#endregion

    //#region  LOCATION AUTOCOMPLETE SEARCH
    bindAddress = (place) => {

        let state = Object.assign({}, this.state.fields);
        let street = '';
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };

        if (place.formatted_address !== null)
            state['address'] = place.formatted_address;

        if (!_.isEmpty(place.address_components)) {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];

                    if (addressType === "postal_code")
                        state['areaCode'] = val;

                    if (addressType === "administrative_area_level_1")
                        state['state'] = val;

                    if (addressType === "locality")
                        state['suburb'] = val;

                    if (addressType === "country") {
                        state['country'] = val;
                        this.handleCountryChange(val);
                    }

                    if (addressType === "street_number" || addressType === "route") {
                        street += ` ${val}`;
                    }
                }
            }
        }

        if (street) {
            state['street'] = street.trim();
        }

        this.setState({ fields: state }, () => {
            this.errorChange('address');
            this.errorChange('areaCode');
            this.errorChange('state');
            this.errorChange('country');
        });
    };
    //#endregion

    //#region ADD, EDIT & SUBMIT
    handleSubmit = async () => {

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let errorUploadClass = 'input_upload_error';
        let _mandatoryFields = this.mandatoryFields;

        if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

        _mandatoryFields = _.pull(_mandatoryFields, 'dataLocation')

        for (let [key] of Object.entries(fields)) {

            if ((_mandatoryFields && _mandatoryFields.indexOf(key) >= 0) || (key === 'email' && fields[key])) {

                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                    if (key === 'licenseFrontURL' || key === 'licenseBackURL') {
                        if (!fields.isDLScan) {
                            formIsValid = false;
                            errors[key] = errorUploadClass;
                        }
                    }
                    else {
                        formIsValid = false;
                        errors[key] = errorClass;
                    }
                }

                if (key === 'email' && fields[key]) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            if (_mandatoryFields && _mandatoryFields.indexOf('phoneORemail') >= 0 && !fields['phone'] && !fields['email']) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Please enter Phone or Email'), '', 'info');
                return false;
            }
            else if (_mandatoryFields && _mandatoryFields.indexOf('phoneORemailORlicenseNo') >= 0 && !fields['phone'] && !fields['email'] && !fields['licenseNo']) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Please enter Phone or Email or License No.'), '', 'info');
                return false;
            }
            else {
                let response = await this.validateAttendee();
                if (response.success === false) {
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists ||
                        response.code === contactResponseCode.LicenseAlreadyExists) {
                        this.handleExistingContact(response, true);
                    }
                    this.setState({ isLoading: false })
                    return false;
                }
                else {
                    if (fields.clientID)
                        this.saveContact();
                    else
                        this.onSavedProcess();
                }
            }
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }
    };

    validateAttendee = async () => {
        this.setState({ isLoading: true })
        const { dealersettings, eventsProID } = this.props;
        const { documentID, phone, licenseNo, email } = this.state.fields;
        const attendeeRef = firestoreDB(dealersettings).firestore().collection('eventsProAttendees');
        const contactID = documentID ? documentID : '';
        const phone8digit = phone ? phone.replace(/\D/g, '').slice(-8) : '';
        let exists = false;
        let existingContact;

        if (phone8digit !== '') {
            const checkPhone8digit = attendeeRef.where('eventsProID', '==', eventsProID).where('keywords', 'array-contains', phone8digit)
                .where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    if (contactID !== '') {
                        snapshot.forEach(doc => {
                            const contct = doc.data();
                            if (contactID !== doc.id && contct.phone && contct.phone.replace(/\D/g, '').slice(-8) === phone8digit) {
                                exists = true;
                                existingContact = contct;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkPhone8digit]);
            if (checkQuery) {
                return { success: false, message: `Attendee already exists with phone: ${phone}`, data: existingContact, code: 'PhoneAlreadyExists' }
            }
        }


        if (phone) {
            const checkPhone = attendeeRef.where('eventsProID', '==', eventsProID).where('keywords', 'array-contains', phone).where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    if (contactID !== '') {
                        snapshot.forEach(doc => {
                            const contct = doc.data();
                            if (contactID !== doc.id && contct.phone && contct.phone.replace(/\D/g, '') === phone) {
                                exists = true;
                                existingContact = contct;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkPhone]);
            if (checkQuery) {
                return { success: false, message: `Attendee already exists with phone: ${phone}`, data: existingContact, code: 'PhoneAlreadyExists' }
            }
        }


        if (email) {
            const checkEmail = attendeeRef.where('eventsProID', '==', eventsProID).where('keywords', 'array-contains', email.toLowerCase()).where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    if (contactID !== '') {
                        snapshot.forEach(doc => {
                            if (contactID !== doc.id) {
                                existingContact = doc.data();
                                if (existingContact.email && existingContact.email.toLowerCase() === email.toLowerCase()) exists = true;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkEmail]);
            if (checkQuery) {
                return { success: false, message: `Attendee already exists with email: ${email}`, data: existingContact, code: 'EmailAlreadyExists' }
            }
        }


        if (licenseNo) {
            const checkLicenseNo = attendeeRef.where('eventsProID', '==', eventsProID).where('keywords', 'array-contains', licenseNo.toLowerCase()).where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    if (contactID !== '') {
                        snapshot.forEach(doc => {
                            if (contactID !== doc.id) {
                                existingContact = doc.data();
                                if (existingContact.licenseNo && existingContact.licenseNo.toLowerCase() === licenseNo.toLowerCase()) exists = true;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkLicenseNo]);
            if (checkQuery) {
                return { success: false, message: `Attendee already exists with licenseNo: ${licenseNo}`, data: existingContact, code: 'LicenseAlreadyExists' }
            }
        }
        return { success: true }
    }

    saveContact = async () => {
        this.setState({ isLoading: true })
        const { docID, dealersettings } = this.props;
        const { existingContact } = this.state;
        let _param = {};
        let state = Object.assign({}, this.state.fields);
        let objData = Object.assign({}, attendeesMapVM);

        for (let [key, value] of Object.entries(state)) {
            if (attendeesMapVM.hasOwnProperty(key) || key === 'mergeID') {
                if (key === 'dob' || key === 'licenseExpiry')
                    objData[key] = value && moment(value).isValid() ? value : null;
                else
                    objData[key] = value;
            }
        }

        if (!docID || !state.contactID) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.isDeleted = false;


        if (state.contactID) {
            objData.documentID = state.contactID
        }

        if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('contactDetails').doc().id;

        //Remove empty value from object
        for (var propName in objData) {
            if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
            }

            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                if (docID && !_.isEmpty(existingContact) && existingContact[propName] && objData[propName] !== existingContact[propName])
                    objData[propName] = null;
                else
                    delete objData[propName];
            }
        }

        if (_.isEmpty(objData.country)) objData.country = defaultCountry;

        if (objData.documentID)
            _param['contactID'] = objData.documentID;

        objData = CommonHelper.setClientOrGroupEventsPROContactClause(objData, dealersettings, objData.clientID, true);

        _param = CommonHelper.setClientOrGroupEventsPROContactClause(_param, dealersettings, objData.clientID);

        if (objData.mergeID) {
            _param['mergeID'] = objData.mergeID;
            //_param['pmds'] = null;
            delete objData['mergeID'];
        }

        //set projectid
        objData.projectId = window.secondaryProjectId;//_.find(_dealers, { value: objData.clientID })?.projectId || null


        //console.log('contact updating, ', objData, _param)

        try {
            const addContact = window.firebase.functions().httpsCallable('contact-saveContact');
            addContact({
                "model": objData,
                "param": _param
            }).then((res) => {
                //console.log('Contact_docRef', data);
                this.setState({ isLoading: false })
                let response = res.data;
                if (response.success === false) {

                    //console.log('contact-saveContact', response);
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists ||
                        response.code === contactResponseCode.LicenseAlreadyExists ||
                        response.code === contactResponseCode.PMDSAlreadyExists ||
                        response.code === contactResponseCode.KeyloopAlreadyExists) {
                        this.handleExistingContact(response);
                    }
                    else {
                        // toast.notify(response.message, {
                        //     duration: 2000
                        // })
                    }
                    //console.log('contact update no success')

                    return false;
                }
                else {
                    // toast.notify(response.message, {
                    //     duration: 2000
                    // })
                    this.onSavedProcess(objData);
                }
            });
        } catch (error) {
            this.setState({ isLoading: false })
            toast.notify('Something went wrong', {
                duration: 2000
            })

            const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
            const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
            ref.set({
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedBy: localStorage.uid,
                clientID: objData.clientID ? objData.clientID : dealersettings.client.id,
                oemID: dealersettings.client.settingsID,
                error: error.message ? error.message : '',
                description: error.message ? error.message : '',
                currentPath: window.location.pathname,
            }).then(() => {
                console.log('Error has been uploaded')
            }).catch((error) => {
                console.error("Error adding log: ", error);
            });
            return false;
        }



    };


    onSavedProcess = (objcontactData) => {
        const { dealersettings, eventsProID, eventsPro, docID } = this.props;
        let objData = Object.assign({}, this.state.fields);
        this.setState({ isLoading: true })

        if (!_.isEmpty(objcontactData)) {
            for (let [key, value] of Object.entries(objcontactData)) {
                if (attendeesMapVM.hasOwnProperty(key))
                    objData[key] = value;
            }

            if (objcontactData.documentID) objData.contactID = objcontactData.documentID;
            if (objcontactData.clientID) objData.clientID = objcontactData.clientID;
            if (objcontactData.groupID) objData.groupID = objcontactData.groupID;
        }

        if (!docID) {
            if (!_.isEmpty(eventsPro)) {
                let eventData = Object.assign({});
                for (let [key, value] of Object.entries(eventsPro)) {
                    if (eventProMapVM.hasOwnProperty(key) && value !== undefined) eventData[key] = value;
                }
                objData.eventsPro = eventData;
            }

            if (eventsProID) objData.eventsProID = eventsProID;
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
            objData.isShow = false;
            let categories = dealersettings?.client?.eventsPro?.categories;
            if (categories.length > 0) {
                let category = {};
                categories.map(rec => { return category = { ...category, [rec.value]: false } });
                objData.category = category;
            }
            else {
                objData.category = { invitation: false };
            }
        }

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (!objData.projectId)
            objData.projectId = window.secondaryProjectId;

        //Remove empty value from object
        if (docID) {
            Object.keys(objData).forEach(key => objData[key] === undefined ? delete objData[key] : {});
        }
        else {
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
        }

        if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('eventsProAttendees').doc().id;

        const updateRef = firestoreDB(dealersettings).firestore().collection('eventsProAttendees').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.setState({ isLoading: false })
            toast.notify('Attendee added successfully', {
                duration: 2000
            })

            let documentID = objData.documentID;
            //this.props.history.push("/eventspro/details/" + documentID);
            //REDIRECT 
            this.handleModalClose(objData.documentID, objData);

        }).catch((error) => {
            console.error("Error adding company: ", error);
        });

    }

    handleModalClose = (ID, objData) => {
        this.setState({ fields: Object.assign({}, objAttendees) });
        this.setState({ errors: {} });
        this.props.handleClose(ID, objData, (this.props.docID ? false : true));
    };

    handleExistingContact = (response, isAttendee) => {

        let _entity;
        let _msg;
        let _title;
        let _fullName = CommonHelper.displayContactName([], response.data);

        if (response.code === contactResponseCode.PhoneAlreadyExists) {
            _entity = "mobile no.";
            _title = "Mobile No. already exist";
        } else if (response.code === contactResponseCode.EmailAlreadyExists) {
            _entity = "email address";
            _title = "Email already exist";
        } else if (response.code === contactResponseCode.LicenseAlreadyExists) {
            _entity = "license no.";
            _title = "License No. already exist";
        }

        _msg = _entity ? `${isAttendee ? 'An attendee' : 'A contact'} "${_fullName}" already has this ${_entity}.` : '';
        if (response.code === contactResponseCode.PMDSAlreadyExists || response.code === contactResponseCode.KeyloopAlreadyExists)
            _title = response.message;


        Swal.fire({
            title: CommonHelper.showLocale(this.props, (_title ? _title : '')),
            html: _msg ? _msg : '',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#17232D',
            confirmButtonText: CommonHelper.showLocale(this.props, (isAttendee ? 'View Attendee' : 'View Contact')),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Okay'),
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                this.handleXContactShow(response.data, isAttendee)
            }
        });
    }

    convertContactVM = (doc) => {

        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objAttendees);
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key) || key === 'mergeID')
                contact[key] = value;
        }
        return contact
    }

    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        let state = Object.assign({}, this.state.fields);
        state[id] = src;
        this.setState({ fields: state })
        this.saveImageToStorage(src, id, type, ext);

        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = (e, type, id, title) => {
        let state = Object.assign({}, this.state.fields);

        if (e.target.files && e.target.files.length > 0) {
            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            // const { dealersettings } = this.props;
            // let _client = state.clientID ? dealersettings.client : null;
            // let _customerConsent = CommonHelper.getTermsByField(_client, 'customerConsent', state.language);
            // if (!_.isEmpty(_customerConsent) && _.isEmpty(state.terms) && (id === 'licenseFrontURL' || id === 'licenseBackURL')) {
            //     if (dealersettings?.client?.clientSettings?.disableVerbalConsent)
            //         this.setState({
            //             showTerms: { show: true, isVerbal: false, origin: null },
            //             fileInfo: {
            //                 file: e.target.files[0],
            //                 id: id,
            //                 type: type,
            //                 title: title,
            //             }
            //         });
            //     else {
            //         this.setState({
            //             showTermsOption: {
            //                 show: true,
            //                 origin: null
            //             },
            //             fileInfo: {
            //                 file: e.target.files[0],
            //                 id: id,
            //                 type: type,
            //                 title: title,
            //             }
            //         })
            //     }
            // }
            // else {
            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
            //}
        }
    }

    handleTermsClose = (data) => {
        if (!_.isEmpty(data)) {

            let _origin = this.state.showTerms.origin;
            let state = Object.assign({}, this.state.fields);
            state.terms = data;

            this.setState({
                showTerms: {
                    show: false,
                    isVerbal: false,
                    origin: null
                }, fields: state
            }, () => { if (_origin === 'save') this.handleSubmit(); });
            //console.log('fileInfo', this.state.fileInfo)
            if (_.isEmpty(_origin) && !_.isEmpty(this.state.fileInfo) && !_.isEmpty(this.state.fileInfo.id))
                this.handleSelectedFile();
            else if (_.isEmpty(_origin)) {
                const { dealersettings } = this.props;
                const _digitallicence_nsw = Object.assign({}, !_.isEmpty(dealersettings) &&
                    dealersettings.client &&
                    dealersettings.client.integrations &&
                    dealersettings.client.integrations.filter(e => e.type === "digitallicence_nsw")[0]);
                if (!_.isEmpty(_digitallicence_nsw) && _digitallicence_nsw.active && _digitallicence_nsw.enabled)
                    this.setState({ showDLScan: true });
            }

        }
        else {
            if (!_.isEmpty(this.state.fileInfo) && this.state.fileInfo.id)
                document.getElementById(this.state.fileInfo.id).value = "";
            this.setState({
                showTerms: {
                    show: false,
                    isVerbal: false,
                    origin: null
                }
            })
        }

    }

    handleTermsChange = (type) => {
        let _origin = this.state.showTermsOption.origin;
        if (type === 'sign') {

            this.setState({
                showTerms: {
                    show: true,
                    isVerbal: false,
                    origin: _origin
                },
                showTermsOption: {
                    show: false,
                    origin: null
                }
            });
        }
        else if (type === 'verbal') {

            this.setState({
                showTerms: {
                    show: true,
                    isVerbal: true,
                    origin: _origin
                },
                showTermsOption: {
                    show: false,
                    origin: null
                }
            });

        }
        else {
            this.setState({
                showTermsOption: {
                    show: false,
                    origin: null
                }
            })
        }
    }

    handleSelectedFile = () => {
        const { file, id, type, title } = this.state.fileInfo;
        let reader = new FileReader();

        reader.onloadend = () => {

            this.setState({
                cropShow: {
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                },
                fileInfo: {
                    file: null,
                    id: '',
                    type: '',
                    title: ''
                }
            })
        }
        reader.readAsDataURL(file)

    }

    handleImageDel = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, `Are you sure?`),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                state[id] = null;
                this.setState({ fields: state })
                this.handleSaveAuditLog(id, true);
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    //#endregion

    //#region RE-AUTHENTICATION
    handleDLScanOpen = () => {
        const { terms, clientID, language } = Object.assign({}, this.state.fields);
        const { dealersettings } = this.props;
        let _client = clientID ? dealersettings.client : null;
        let _customerConsent = CommonHelper.getTermsByField(_client, 'customerConsent', language);
        if (!_.isEmpty(_customerConsent) && _.isEmpty(terms)) {

            if (dealersettings?.client?.clientSettings?.disableVerbalConsent)
                this.setState({ showTerms: { show: true, isVerbal: false, origin: null } });
            else
                this.setState({ showTermsOption: { show: true, origin: null } })

        }
        else {
            this.setState({ showDLScan: true });
        }
    }

    handleDLScanClose = (data) => {
        if (!_.isEmpty(data)) {

            const objData = Object.assign({}, data);
            const fields = Object.assign({}, this.state.fields);

            if (objData.dob && moment(objData.dob).isValid())
                fields.dob = objData.dob;

            if (objData.expirydate && moment(objData.expirydate).isValid())
                fields.licenseExpiry = objData.expirydate;

            if (objData.licencenumber)
                fields.licenseNo = objData.licencenumber;

            if (!_.isEmpty(objData.licenseename)) {
                const nameList = objData.licenseename.match(/(\S+)\s*(\S*)\s*(.*)/);
                if (nameList) {
                    if (nameList[1]) fields.firstName = (nameList[1] || '');
                    if (nameList[2] && nameList[3]) {
                        fields.middleName = nameList[2];
                        fields.lastName = nameList[3];
                    }
                    else if (nameList[2]) fields.lastName = (nameList[2] || '');
                } else {
                    var fullName = objData.licenseename.split(' ');
                    fields.firstName = fullName.slice(0, -1).join(' ');
                    fields.lastName = CommonHelper.autoCaps(fullName[fullName.length - 1]);
                }
            }
            if (objData.address) {
                var fullAddress = objData.address.split(' ');

                fields.address = objData.address;
                fields.country = "AU";

                fields.state = fullAddress[fullAddress.length - 2];
                fields.areaCode = fullAddress[fullAddress.length - 1];
            }

            fields.isDLScan = true;

            this.setState({ fields: fields, errors: {} }, () => { this.loadSettings(); });

            if (objData.photo)
                this.saveImageToStorage("data:image/png;base64," + objData.photo, 'userImageURL', 'profile', 'png');

            if (objData.signature)
                this.saveImageToStorage("data:image/png;base64," + objData.signature, 'userSignature', 'license', 'png');


        }
        this.setState({ showDLScan: false });
    }
    //#endregion

    handleSaveAuditLog = (imagePath, isDelete) => {
        const { docID, dealersettings } = this.props;
        let _name = CommonHelper.getFullNameWithID(null, this.state.fields, '');
        let _notes = `License image ${isDelete ? 'deleted' : 'viewed'} for ${_name}`;

        if (docID) {
            let _objLogData = {
                notes: _notes,
                type: 'licenseImage',
                subType: 'contactDetails',
                recordId: docID,
                contactID: docID,
                imageURL: imagePath,
            }

            _objLogData = CommonHelper.setClientOrGroupEventsPROContactClause(_objLogData, dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }

    }



    //#endregion

    render() {
        //console.log('R E N D E R - C O N T A C T ', this.selectedClientID, this.selectedGroupID);
        const { clientID, title, firstName, middleName, lastName, userImageURL, dob, phoneCode,
            gender, language, address, state, areaCode, country,
            licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL,
            phone, email, licenseState, clientName,
            licenseType, isDLScan, terms, isDeleted,
            dietaryPreference, leadStatus, eventSlot, eventDate,
            optinEmail, optinPhone, optinPost, optinSMS
        } = this.state.fields;
        const { showContacts, showContactInfo, attendeeInfoModal, countries, states,
            phoneCodes, showDLScan, showTerms, showTermsOption, isLoading, fields } = this.state;
        const { show, dealersettings, isFromLayout, docID, eventsPro } = this.props;

        const _digitallicence_nsw = Object.assign({}, !_.isEmpty(dealersettings) &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "digitallicence_nsw")[0]);

        let _clientName = clientName;
        if (clientID && !_clientName)
            _clientName = CommonHelper.getOtherDealerName(dealersettings, clientID);

        const isPrivacyEnabled = false;//CommonHelper.isPrivacyEnabled(dealersettings);

        const canModifyContact = (isDeleted) ? false : true

        return (
            this.newDocID
                ?
                <>

                    <Modal
                        show={show}
                        onHide={this.handleModalClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-contact"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title><Translate text={this.props.title} /></Modal.Title>

                            {_clientName ? <div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{_clientName}</div></div> : <></>}
                            {/* {isDLScan ? <div className="contact-pop-groupname"><div className="badge badge-pill badge-white ml-2">
                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                <Translate text={`Digital Driving Licence`} />
                            </div></div> : <></>} */}

                            {/* {!_.isEmpty(terms) ? <div className="contact-pop-groupname"><div className="badge badge-pill badge-white ml-2">
                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                {`Customer Consent ${terms.verbalConsent ? '(Verbal)' : (terms.consentType ? `(${terms.consentType})` : '')}`}
                            </div></div> : <></>} */}

                            {
                                isDeleted ? (
                                    <div className="contact-pop-groupname ml-2">
                                        <div className="badge badge-pill badge-hot">
                                            <i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                        </Modal.Header>

                        <Modal.Body /*className="modal-body"*/>
                            <div className={`container-fluid ${(canModifyContact) ? '' : 'btn-disable'}`}>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <div className="add-popcontact-left form-style">

                                            <div className={`contactleft-profileimage ${this.state.errors["userImageURL"] ? 'image-error' : ''}`}>
                                                <div className="contactleft-profileimage-upload">
                                                    <label htmlFor="userImageURL">
                                                        <i className={`ico ${userImageURL ? 'icon-edit-circle' : 'icon-camera'}`} htmlFor="userImageURL"></i>
                                                        <input className="fileInput"
                                                            type="file"
                                                            name="testee"
                                                            id="userImageURL"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => this.onSelectFile(e, 'profile', 'userImageURL', 'Profile Image')} />
                                                    </label>
                                                </div>

                                                {
                                                    (userImageURL)
                                                        ?
                                                        <a data-fancy-box={`userImageURL`}
                                                            href={userImageURL}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({
                                                                    imageViewer: {
                                                                        show: true,
                                                                        images: userImageURL
                                                                    }
                                                                });
                                                            }}>
                                                            <img src={userImageURL} alt="" className=" rounded-circle img-object-fit" />
                                                        </a>
                                                        :
                                                        <img src={_images.nouser} alt="" className=" rounded-circle img-object-fit" />
                                                }

                                            </div>

                                            <div className="contactleft-header">
                                                <div className="form-group">
                                                    <label ><Translate text={'dealer'} /></label>
                                                    <ReactSelect
                                                        options={_dealers}
                                                        name={"clientID"}
                                                        placeholder={'select dealer'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={clientID}
                                                        classNamePrefix={`${this.state.errors["clientID"]} basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {/* <div className="contactleft-header">
                                                <div className="form-group">
                                                    <label ><Translate text={'preferredContactType'} /></label>
                                                    <ReactSelect
                                                        options={contact_type}
                                                        name={"contactType"}
                                                        placeholder={'select contact type'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={contactType}
                                                        classNamePrefix={`${this.state.errors["contactType"]} basic-select-img`}
                                                        removeClearable={true}
                                                        isDisabled={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group">
                                                    <label ><Translate text={'ownership'} /></label>
                                                    <ReactSelect
                                                        options={this.state.clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={owner ? owner : addedBy}
                                                        classNamePrefix={`${this.state.errors["owner"]} basic-select-img`}
                                                        removeClearable={true}
                                                        isSearchable={true}
                                                        isDisabled={modifyContactOwner ? false : true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div> */}


                                            <div className="contactleft-license">
                                                <h3><Translate text={'licenseInfo'} /></h3>

                                                {/* {
                                                    !_.isEmpty(_digitallicence_nsw) && _digitallicence_nsw.active && _digitallicence_nsw.enabled
                                                        ?
                                                        <>
                                                            <div className="digital-license-btn">
                                                                <button type="button" className="btn btn-default"
                                                                    onClick={(e) => { e.preventDefault(); this.handleDLScanOpen(); }}>
                                                                    <i className="ico icon-card"></i> <Translate text={'isDLScan'} />
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                } */}

                                                <div className="form-group">
                                                    <label ><Translate text={'licenseNo'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'license #'}
                                                        className={`form-control ${this.state.errors["licenseNo"]}`}
                                                        name="licenseNo"
                                                        onChange={this.handleOnChange}
                                                        value={licenseNo}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label ><Translate text={'licenseExpiry'} /></label>
                                                    <div className="inner-addon right-addon">
                                                        {
                                                            isFromLayout
                                                                ?
                                                                <>
                                                                    {/* <i className="bg-icons ico icon-date"></i> */}
                                                                    <ReactDatePicker
                                                                        value={licenseExpiry}
                                                                        name={'licenseExpiry'}
                                                                        onChange={this.handleDateChange}
                                                                        format={this.dateFormat}
                                                                        placeholder='DD/MM/YYYY'
                                                                        onValueChange={this.handleDateValueChange.bind(this, 'licenseExpiry')}
                                                                        className={`form-control ${this.state.errors["licenseExpiry"]}`}
                                                                        //readOnly={true}
                                                                        isClearable={true}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <AntDatePicker
                                                                        value={licenseExpiry}
                                                                        name={'licenseExpiry'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'licenseExpiry') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["licenseExpiry"]}`}
                                                                    />
                                                                </>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label ><Translate text={'licenseState'} /></label>
                                                    <ReactSelect
                                                        options={license_state}
                                                        name={"licenseState"}
                                                        placeholder={'select license state'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={licenseState}
                                                        classNamePrefix={`${this.state.errors["licenseState"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className="form-group">
                                                    <label ><Translate text={'licenseType'} /></label>
                                                    <ReactSelect
                                                        options={license_type}
                                                        name={"licenseType"}
                                                        placeholder={'select license type'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={licenseType}
                                                        classNamePrefix={`${this.state.errors["licenseType"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className="form-group">
                                                    <label ><Translate text={'primaryID'} /></label>
                                                    <div>
                                                        <div className="contactleft-licenseboxwrap">
                                                            <div className={`contactleft-licensebox ${this.state.errors["licenseFrontURL"]}`}>
                                                                {
                                                                    (licenseFrontURL && licenseFrontURL.indexOf('base64') > 0)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <label htmlFor="licenseFrontURL" className={`${licenseFrontURL ? 'licensebox-edit mini-button' : ''}`}>
                                                                    <i className={`ico ${licenseFrontURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="licenseFrontURL"></i>
                                                                    <input className="fileInput"
                                                                        type="file"
                                                                        name="licFront"
                                                                        id="licenseFrontURL"
                                                                        accept="image/*"
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => this.onSelectFile(e, 'license', 'licenseFrontURL', 'License Front')} />
                                                                </label>
                                                                {
                                                                    licenseFrontURL
                                                                        ?
                                                                        <>
                                                                            <label title="Delete" htmlFor="licenseFronttDel"
                                                                                className="licensebox-edit mini-button mini-hover-red"
                                                                                style={{ marginRight: '22px' }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleImageDel('licenseFrontURL');
                                                                                }}>
                                                                                <i className="ico icon-delete" htmlFor="licenseFronttDel"></i>
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }

                                                                {
                                                                    (licenseFrontURL)
                                                                        ?
                                                                        <>
                                                                            {
                                                                                (isPrivacyEnabled && docID)
                                                                                    ?
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                        <img src={_images[`lock${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                    :
                                                                                    <a data-fancy-box={`licenseFrontURL`}
                                                                                        href={licenseFrontURL}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleSaveAuditLog(licenseFrontURL);
                                                                                            this.setState({
                                                                                                imageViewer: {
                                                                                                    show: true,
                                                                                                    images: licenseFrontURL
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                        <img src={licenseFrontURL} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                                }


                                                            </div>
                                                            <small className="form-text text-muted"><Translate text={'front'} /></small>
                                                        </div>

                                                        <div className="contactleft-licenseboxwrap">
                                                            <div className={`contactleft-licensebox ${this.state.errors["licenseBackURL"]}`}>
                                                                {
                                                                    (licenseBackURL && licenseBackURL.indexOf('base64') > 0)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <label htmlFor="licenseBackURL" className={`${licenseBackURL ? 'licensebox-edit mini-button' : ''}`}>
                                                                    <i className={`ico ${licenseBackURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="licenseBackURL"></i>
                                                                    <input className="fileInput"
                                                                        type="file"
                                                                        name="licBack"
                                                                        id="licenseBackURL"
                                                                        accept="image/*"
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => this.onSelectFile(e, 'license', 'licenseBackURL', 'License Back')} />
                                                                </label>
                                                                {
                                                                    licenseBackURL
                                                                        ?
                                                                        <>
                                                                            <label title="Delete" htmlFor="licenseBackDel"
                                                                                className="licensebox-edit mini-button mini-hover-red"
                                                                                style={{ marginRight: '22px' }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleImageDel('licenseBackURL');
                                                                                }}>
                                                                                <i className="ico icon-delete" htmlFor="licenseBackDel"></i>
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    (licenseBackURL)
                                                                        ?
                                                                        <>
                                                                            {
                                                                                (isPrivacyEnabled && docID)
                                                                                    ?
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                        <img src={_images[`lock${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                    :
                                                                                    <a data-fancy-box={`licenseBackURL`}
                                                                                        href={licenseBackURL}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleSaveAuditLog(licenseBackURL);
                                                                                            this.setState({
                                                                                                imageViewer: {
                                                                                                    show: true,
                                                                                                    images: licenseBackURL
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                        <img src={licenseBackURL} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                                }

                                                            </div>
                                                            <small className="form-text text-muted"><Translate text={'back'} /></small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="clearfix"></div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-md-9 pl-0 form-style">
                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'personalInfo'} /></h3>
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'title'} /></label>
                                                    <ReactSelect
                                                        options={titles}
                                                        name={"title"}
                                                        placeholder={'select title'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={title}
                                                        classNamePrefix={`${this.state.errors["title"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'firstName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'first name'}
                                                        className={`form-control ${this.state.errors["firstName"]}`}
                                                        name="firstName"
                                                        onChange={this.handleOnNameChange}
                                                        value={firstName}
                                                    />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label ><Translate text={'middleName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'middle name'}
                                                        className={`form-control ${this.state.errors["middleName"]}`}
                                                        name="middleName"
                                                        onChange={this.handleOnNameChange}
                                                        value={middleName}
                                                    />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label ><Translate text={'lastName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'last name'}
                                                        className={`form-control ${this.state.errors["lastName"]}`}
                                                        name="lastName"
                                                        onChange={this.handleOnNameChange}
                                                        value={lastName}
                                                    />
                                                </div>



                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'mobile'} /></label>
                                                    <div className={`input-group country-code ${this.state.errors["phone"] ? 'phone_error' : ''}`}>

                                                        <div className="input-group-prepend">
                                                            <Dropdown className={`btn btn-outline-secondary`} >
                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                    <span id="spn-code-name" data-name={phoneCode}>
                                                                        {phoneCode && phoneCodes.find(item => item.value === phoneCode)
                                                                            ? phoneCodes.find(item => item.value === phoneCode).value + ' ' :
                                                                            'Code'
                                                                        }</span>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu as={CustomSearchMenu}
                                                                    className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                                                    ChildClass="pipeline-dropdown"
                                                                    xplacement="bottom-end"
                                                                >
                                                                    {
                                                                        !_.isEmpty(phoneCodes) && phoneCodes.length > 0 ?
                                                                            phoneCodes.map((rec, index) => {
                                                                                return (<Dropdown.Item key={index} className={`current-pipeline ${(phoneCode && rec.value === phoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleAddPhoneCode('phoneCode', rec.value)
                                                                                }}>
                                                                                    {rec.label}
                                                                                </Dropdown.Item>)
                                                                            })
                                                                            : (<></>)
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder={'phone'}
                                                            pattern="[0-9]*"
                                                            className={`form-control `}
                                                            name="phone"
                                                            onChange={this.onHandleTelephoneChange}
                                                            value={phone}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4">

                                                    <label ><Translate text={'email'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'email'}
                                                        className={`form-control ${this.state.errors["email"]}`}
                                                        name="email"
                                                        onChange={this.handleOnChange}
                                                        value={email}
                                                    />
                                                </div>



                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'gender'} /></label>
                                                    <ReactSelect
                                                        options={genders}
                                                        name={"gender"}
                                                        placeholder={'select gender'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={gender}
                                                        classNamePrefix={`${this.state.errors["gender"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                {/* <div className="form-group col-md-3">
                                                    <label ><Translate text={'dateOfBirth'} /></label>
                                                    <div className="inner-addon right-addon">
                                                        {
                                                            isFromLayout
                                                                ?
                                                                <>
                                                                    <ReactDatePicker
                                                                        value={dob}
                                                                        name={'dob'}
                                                                        onChange={this.handleDateChange}
                                                                        format={this.dateFormat}
                                                                        placeholder='DD/MM/YYYY'
                                                                        onValueChange={this.handleDateValueChange.bind(this, 'dob')}
                                                                        className={`form-control ${this.state.errors["dob"]}`}
                                                                        //readOnly={true}
                                                                        isClearable={true}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <AntDatePicker
                                                                        value={dob}
                                                                        name={'dob'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'dob') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["dob"]}`}
                                                                    />
                                                                </>
                                                        }

                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>



                                        <div className="add-popcontact-right ">

                                            <h3><Translate text={'address'} /></h3>


                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label ><Translate text={'address'} /> </label>
                                                    <AutoComplete
                                                        className={`form-control ${this.state.errors["address"]}`}
                                                        bindAddress={this.bindAddress}
                                                        types={['address']}
                                                        value={address}
                                                        placeholder={'search address'}
                                                        onChange={this.handleOnChange}
                                                        componentRestrictions={country ? { country: country.toLowerCase() } : { country: [] }}
                                                        name="address"
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'areaCode'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'area/zip code'}
                                                        className={`form-control ${this.state.errors["areaCode"]}`}
                                                        value={areaCode}
                                                        name="areaCode"
                                                        onChange={this.handleOnChange}
                                                    />

                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'country'} /></label>
                                                    <ReactSelect
                                                        options={countries}
                                                        name={"country"}
                                                        placeholder={'select country'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={country}
                                                        classNamePrefix={`${this.state.errors["country"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'state'} /></label>
                                                    <ReactSelect
                                                        options={states}
                                                        name={"state"}
                                                        placeholder={'select state'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={state}
                                                        classNamePrefix={`${this.state.errors["state"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'marketing'} /></h3>
                                            <div className="form-row">


                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'optinEmail'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinEmail"}
                                                        placeholder={'select opt in email'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinEmail ? 'true' : 'false'}
                                                        classNamePrefix={`${this.state.errors["optinEmail"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'optinPhone'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinPhone"}
                                                        placeholder={'select opt in phone'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinPhone ? 'true' : 'false'}
                                                        classNamePrefix={`${this.state.errors["optinPhone"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'optinSMS'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinSMS"}
                                                        placeholder={'select opt in sms'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinSMS === false ? 'false' : (optinSMS ? 'true' : null)}
                                                        classNamePrefix={`${this.state.errors["optinSMS"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'optinPost'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinPost"}
                                                        placeholder={'select opt in post'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinPost ? 'true' : 'false'}
                                                        classNamePrefix={`${this.state.errors["optinPost"]} basic-select`}
                                                        menuPlacement='top'
                                                    >
                                                    </ReactSelect>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'moreInfo'} /></h3>

                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'dietaryPreference'} /></label>
                                                    <ReactSelect
                                                        options={dietary_preferences}
                                                        name={"dietaryPreference"}
                                                        placeholder={'select dietary preference'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={dietaryPreference}
                                                        classNamePrefix={`${this.state.errors["dietaryPreference"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'status'} /></label>
                                                    <ReactSelect
                                                        options={_enquiryAllStatus}
                                                        name={"leadStatus"}
                                                        placeholder={'select status'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={leadStatus}
                                                        classNamePrefix={`${this.state.errors["leadStatus"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'eventSlot'} /></label>
                                                    <ReactSelect
                                                        options={eventDates}
                                                        name={"eventDate"}
                                                        placeholder={'select date'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={fields.eventDate}
                                                        classNamePrefix={`${this.state.errors["eventSlot"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'Preferred Retailer'} /></label>
                                                    <ReactSelect
                                                        options={_dealers.map(r => {
                                                            return {
                                                                label: r.label,
                                                                value: r.label
                                                            }
                                                        })}
                                                        name={"preferredRetailer"}
                                                        placeholder={'select preferred retailer'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={fields.preferredRetailer}
                                                        classNamePrefix={`${this.state.errors["preferredRetailer"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                {
                                                    sources.length > 0 ? (
                                                        <div className="form-group col-md-4">
                                                            <label ><Translate text={'Source'} /></label>
                                                            <ReactSelect
                                                                options={sources}
                                                                name={"source"}
                                                                placeholder={'select source'}
                                                                onChange={this.handleReactSelectChange}
                                                                value={fields.source}
                                                                classNamePrefix={`${this.state.errors["source"]} basic-select`}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                                {/* <div className="form-group col-md-4">
                                                    <label ><Translate text={'eventSlot'} /></label>
                                                    <ReactSelect
                                                        options={event_slots}
                                                        name={"eventSlot"}
                                                        placeholder={'select event slot'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={eventSlot}
                                                        classNamePrefix={`${this.state.errors["eventSlot"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div> */}

                                                {
                                                    fields.eventDate && event_slots.filter(a => a.date === fields.eventDate).length ? (
                                                        <div className="form-group col-md-12">
                                                            <div className={`custom-slot-listwrap mt-2`}>
                                                                <div className="custom-slot-availablity">Available timeslots on <span className="custom-slot-bluelink">{moment(fields.eventDate).format('dddd,Do MMM YYYY')}</span> </div>
                                                                <div className={`custom-slot-list ${this.state.errors["eventSlot"] ? 'border-error' : ''}`}>
                                                                    {
                                                                        _.orderBy(event_slots.filter(a => a.date === fields.eventDate), 'sortTime', 'asc').map(rec => {
                                                                            return <div id={rec.value} key={rec.value} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handleSlotSelect(rec.value)
                                                                            }} className={`custom-slot-loopbox ${rec.value === fields.eventSlot ? 'active' : rec.total && rec.booked === rec.total ? 'inactive form-disable' : ''}`}>
                                                                                {moment.unix(rec.startTime.seconds).tz(eventsPro.timezone).format('hh:mm A')} - {moment.unix(rec.endTime.seconds).tz(eventsPro.timezone).format('hh:mm A')}
                                                                                {
                                                                                    rec?.slotName ? (
                                                                                        <span className='dynamic-slot-value-span ml-1'>{rec.slotName}</span>
                                                                                    ) : (<></>)
                                                                                }
                                                                                {
                                                                                    rec.total && (_.isEmpty(rec.booked) || rec.total !== rec.booked) ? (
                                                                                        <div className="custom-slot-counterwrap"><div className="custom-slot-counter">{rec.booked ? rec.booked : 0} / {rec.total}</div></div>
                                                                                    ) : (<></>)
                                                                                }
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div></div>
                                                    ) : (<>{fields.eventDate && (<div className="alert alert-danger w-100" role="alert">
                                                        There are no open time slots available on <span className="custom-slot-bluelink">{moment(fields.eventDate).format('dddd,Do MMM YYYY')}</span>
                                                    </div>)}</>)
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        {
                            canModifyContact
                                ?
                                <Modal.Footer className={`${isLoading ? 'form-disable' : ''}`}>

                                    <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null, null)}>
                                        <Translate text={'cancel'} />
                                    </button>

                                    <button type="button"
                                        className={`btn btn-primary float-right ${this.isImageUploading() ? 'form-disable' : ''}`}
                                        onClick={(e) => { e.preventDefault(); this.handleSubmit() }} >
                                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={'save'} />
                                    </button>
                                </Modal.Footer>
                                :
                                <></>
                        }

                    </Modal>

                    <ImageCropHook
                        cropShow={this.state.cropShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>

                    <PopUpModal show={showTerms.show}>
                        <Terms
                            show={showTerms.show}
                            clsActive='overlay-modal active'
                            imagePath='contactDetails'
                            title={'customerConsent'}
                            isVerbalTerms={showTerms.isVerbal}
                            terms={this.state.fields.terms}
                            termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'customerConsent', language)}
                            isDisable={false}
                            handleClose={this.handleTermsClose}
                            dealersettings={this.props.dealersettings}

                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={showContactInfo.show}>
                        <ContactInfo
                            show={showContactInfo.show}
                            existingContact={showContactInfo.data}
                            newContact={this.state.fields}
                            isNewContact={this.isNewContact}
                            //handleClose={this.handleXContactClose}
                            handleClose={this.handleContactClose}
                            title={showContactInfo.isAttendee ? 'Attendee Details' : 'Contact Details'}
                            dealersettings={this.props.dealersettings}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                            isAttendee={showContactInfo.isAttendee}
                            isViewOnly={true}
                        >
                        </ContactInfo>
                    </PopUpModal>

                    <PopUpModal show={showContacts}>
                        <Contacts
                            show={showContacts}
                            dealersettings={this.props.dealersettings}
                            handleContactClose={this.handleContactClose}
                            showAdd={false}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                            clientID={this.props.clientID}
                        />
                    </PopUpModal>
                    <PopUpModal show={showDLScan}>
                        <DLScan
                            show={showDLScan}
                            dealersettings={this.props.dealersettings}
                            handleClose={this.handleDLScanClose}
                            digitalLicence={_digitallicence_nsw}
                        />
                    </PopUpModal>

                    <PopUpModal show={showTermsOption.show}>
                        <SignOptions
                            show={showTermsOption.show}
                            menu={sign_type}
                            handleClose={this.handleTermsChange}
                            title='Customer Consent'
                            hideCancel={true}
                        />
                    </PopUpModal>

                    <PopUpModal show={attendeeInfoModal.show}>
                        <AttendeeInfo
                            {...this.props}
                            attendeeid={attendeeInfoModal.docID}
                            show={attendeeInfoModal.show}
                            clsActive='overlay-modal active'
                            handleClose={this.handleAttendeeInfoClose}
                            title={'attendee'}
                        >
                        </AttendeeInfo>
                    </PopUpModal>
                    <PopUpModal show={this.state.imageViewer.show}>
                        <ImageViewer
                            {...this.state.imageViewer}
                            handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                        />
                    </PopUpModal>
                </>
                :
                <></>
        );
    }
}

export default AddContact;
