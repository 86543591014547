/** LIBRARIES */
import React, {
    useState, useRef,
    useEffect
} from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import CustomerlogDetails from '../pipeline/quickview/customerlogDetails';
import { ContentOverlay } from '../../components';
import { default as _images } from '../../images';
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';

function ViewNotesHistory(props) {
    const [tempData, setTempData] = useState([])
    const [customerlogLoader, setCustomerlogLoader] = useState(true);
    const textareaRef = useRef(null);

    useEffect(() => {
        if (!props.activityID)
            return;

        let activitiesCollectionzz = firestoreDB(props.dealersettings)
            .firestore()
            .collection(`activities/${props.activityID}/notesHistory`)
            .onSnapshot(onCollectionUpdate);

        return () => {
            activitiesCollectionzz && activitiesCollectionzz();
        }

    }, [])

    const onCollectionUpdate = querySnapshot => {
        let _logData = [];
        querySnapshot.forEach(_log => {
            let objData = Object.assign({}, _log.data());
            objData.documentID = _log.id;
            _logData.push(objData);
        });
        setCustomerlogLoader(false);

        if (!_.isEmpty(_logData)) {
            setTempData(_.orderBy(_logData, ["modifiedDate"], ["desc"]))
        }
        else if (props.activityLog && props.activityLog.notes) {
            _logData.push(props.activityLog);
            setTempData(_logData)
        }

    };

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-log-activity"
            >
                <Modal.Header closeButton className="modal-header-border">
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="pop-layout form-style" style={{ minHeight: '350px' }}>


                                <div className='quickview-activity-wraper mt-0'>
                                    {customerlogLoader ? (
                                        <div className='mt-5'>
                                            <ContentOverlay active={true} />
                                        </div>
                                    ) : (<>
                                        {tempData ? (
                                            <>
                                                <div className='quickview-activity-item'>

                                                    {
                                                        tempData.map((obj, index) => {
                                                            return (
                                                                <CustomerlogDetails
                                                                    key={index}
                                                                    type={'note'}
                                                                    clientUsers={props.clientUsers}
                                                                    customerlog={obj}
                                                                    handlecancellog={(e) => { e.preventDefault(); }}
                                                                    handlechangelog={(e) => { e.preventDefault(); }}
                                                                    handlesavelog={(e) => { e.preventDefault(); }}
                                                                    handleEditlog={(e) => { e.preventDefault(); }}
                                                                    isNewlog={false}
                                                                    isEditable={false}
                                                                    id={obj.documentID}
                                                                    notes={obj.notes}
                                                                    addedDate={obj.addedDate}
                                                                    textareaRef={textareaRef}
                                                                    handleDeletelog={(e) => { e.preventDefault(); }}
                                                                    handleDateChangelog={(e) => { e.preventDefault(); }}
                                                                    loading={false}
                                                                    dealersettings={props.dealersettings}
                                                                    handleUnlickEmail={(e) => { e.preventDefault(); }}
                                                                    handlePreviewEmail={(e) => { e.preventDefault(); }}
                                                                    handleReplylog={(e) => { e.preventDefault(); }}
                                                                    sendLoading={false}
                                                                    hideMoreOption={true}
                                                                    originType={props.originType}
                                                                    handleFileChange={(e) => { e.preventDefault(); }}
                                                                ></CustomerlogDetails>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </>
                                        ) : (
                                            <div className='spinner-loader h-100 p-5'>
                                                <div className='no-data-flex h-100 justify-content-center align-items-center '>
                                                    <div className='no-data-img'>
                                                        {' '}
                                                        <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
                                                    </div>
                                                    <div className='no-data-txt mt-2'>  <Translate text={'No Logs'} /></div>
                                                </div>
                                            </div>
                                        )}

                                    </>
                                    )}
                                </div>



                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    );
}

export default ViewNotesHistory;
