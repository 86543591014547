import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { ContentOverlay } from '../../components'
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import TIPHistory from '../tradeinplus/info';
const TradeinCount = (props) => {
    const [loader, setLoader] = useState(true)
    const [tipLogs, setTipLogs] = useState({ Today: [], Upcoming: [] })
    //const allTags = ["Today", "Upcoming"]
    const [activeTag, setActiveTag] = useState('Upcoming')
    const [hasMore, setHasMoreData] = useState({})
    const [checkDataLoad, setDataload] = useState({ "Today": true, "Upcoming": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const pageLimit = 20
    const [totalCounts, settotalCounts] = useState(0)
    const [pageNum, setPageNum] = useState({ Today: 0, Upcoming: 0 });




    useEffect(() => {
        let dataload = { "Today": true, "Upcoming": true }
        setPageNum({ Today: 0, Upcoming: 0 });
        setHasMoreData({});
        setTipLogs({ Today: [], Upcoming: [] })
        setDataload({
            ...dataload,
            [activeTag]: true
        })
    }, [props.selectedUser, props.selectedClient, props.selectedPipeline, props.dateRange, props.refreshData])


    useEffect(() => {
        if (!checkDataLoad[activeTag]) {
            return;
        }
        const fetchTIP = async () => {
            let _filter = {
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }

            if (props.selectedPipeline) {
                _filter.pipeline = props.selectedPipeline.value
            } else if (!_.isEmpty(props.pipeline)) {
                _filter.pipeline = props.pipeline.map(item => item.value).join(',')
            }
            if (props.selectedUser) {
                _filter.owner = props.selectedUser.value
            }
            if (props.ownerDashboard) {
                _filter.owner = localStorage.uid
            }

            if (props.selectedClient) {
                _filter.clientID = props.selectedClient.value
            }
            else
                _filter.clientID = CommonHelper.getTradeinProDealers(props.dealersettings, false, true)

            _filter.dateType = 'addedDate'
            if (props.dateRange) {
                _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
            }
            else {
                _filter.startDate = moment().startOf('month').format('YYYY-MM-DD');
                _filter.endDate = moment().endOf('month').format('YYYY-MM-DD');
            }

            let _searchObject = {
                "type": "searchTradeinpro",
                "filters": JSON.stringify(_filter),
                "sortOrder": `modifiedDate desc`,
                "pageNum": pageNum[activeTag],
                pageLimit,
            }
            _searchObject.clientID = _filter.clientID;

            setPagingloader(true)
            const getData = window.firebase.functions().httpsCallable('generic-searchData');
            const resp = await getData(_searchObject);
            setDataload({
                ...checkDataLoad,
                [activeTag]: false
            })
            setLoader(false)
            setPagingloader(false)
            if (resp.data.success) {
                settotalCounts(resp.data.total);
                let data_ = [];
                !_.isEmpty(resp?.data?.data) && resp.data.data.forEach(function (doc) {
                    var logVM = CommonHelper.convertTradeinProVM(doc, props);
                    if (logVM?.objDoc) data_.push(logVM?.objDoc)
                });

                setHasMoreData({
                    ...hasMore,
                    [activeTag]: data_.length >= pageLimit
                })
                if (data_.length >= pageLimit)
                    setPageNum({
                        ...pageNum,
                        [activeTag]: pageNum[activeTag] + 1
                    })
                setTipLogs({
                    ...tipLogs,
                    [activeTag]: _.uniqBy([...tipLogs[activeTag], ...data_], 'documentID')
                })
            }
        }
        fetchTIP();
    }, [checkDataLoad, activeTag])

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <h3><Translate text={'Trade-Ins'} />
                        {totalCounts > 0 ? <span className="badge badge-pill badge-widget-counter ml-1 mt-1">{totalCounts || 0}</span> : <></>}
                    </h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        {
                                            tipLogs[activeTag].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item cursor-pointer" style={hasMore[activeTag] ? { height: 'calc(100% - 50px)' } : {height: 'calc(100%)'}}>
                                                        {
                                                            tipLogs[activeTag].map((tradein, index) => {
                                                                return <div
                                                                    key={index}
                                                                >
                                                                    <TIPHistory
                                                                        key={index}
                                                                        fields={tradein}
                                                                        clientUsers={props.clientUsers}
                                                                        groupUsers={props.groupUsers}
                                                                        sidepanelOpen={props.sidepanelOpen}
                                                                        history={props.history}
                                                                        showMore={false}
                                                                        //companyView={true}
                                                                        //isList={true}
                                                                        dealersettings={props.dealersettings}
                                                                    ></TIPHistory>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!pagingLoader && tipLogs[activeTag].length == 0) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'No Data Available'} /> </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                        {
                                            pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                                hasMore[activeTag] ?
                                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeTag]: true
                                                        })
                                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                    : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default TradeinCount;