import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { default as _images } from '../../images';
import { Dropdown } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import FullheightIframe from './resizableIFrame';
import FileItem from './fileItem';
import Swal from 'sweetalert2';
import _ from 'lodash';
import CommonHelper from '../../services/common';
import { PopoverPanel } from '../../components';
import Translate from '../../constants/translate';

const EmailListItem = ({
	message,
	isMsgLatest,
	updateThread,
	selectedThread,
	token,
	disableLinkedinfo,
	dealersettings,
	userData,
	handleOpenComposeModal,
	history,
	selectedCustomer,
	selectedEnquiry,
	clientUsers,
	groupUsers
}) => {
	//console.log('EmailListItem', message)
	const [expand, setExpand] = useState(isMsgLatest);
	const [recentLoad, setRecentLoad] = useState(false);
	const [recentPopover, setRecentPopover] = useState({ showpopover: false, targetpopover: null, logs: null })
	const nylasEmail = dealersettings.nylas_v3 && dealersettings.nylas_v3.email_address ? dealersettings.nylas_v3.email_address : dealersettings.email
	const _allUser = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
	const handleToggleExpand = () => {
		if (isMsgLatest) {
			return;
		}

		setExpand(!expand);
	};

	const handleReply = isReplyToAll => {
		if (_.isEmpty(dealersettings.nylas_v3?.grantId)) {
			Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Please configure email settings'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Add Settings'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
			}).then(result => {
				if (result.value) {
					history.push('/settings/profile?key=emailsettings');
				}
			});
			return
		}
		let lastMessage = message;

		let replyEmailDate = lastMessage.date;
		let replyEmailName = lastMessage.from[0].name;
		let replyEmailAddress = lastMessage.from[0].email;
		let replyToMessageId = lastMessage.id;
		let replyToEmailContent = lastMessage.body;
		let replyTo;
		let replyToCC;
		let replyToAll;
		let subject = lastMessage.subject;


		if (isReplyToAll) {
			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];

			if (!_.isEmpty(lastMessage.replyTo)) {
				replyTo = [
					{ name: lastMessage.replyTo[0].name, email: lastMessage.replyTo[0].email },
				];
			}

			replyToCC = message.cc.filter((participant, i) => {
				if (participant.email === nylasEmail) {
					return false;
				}
				if (participant.email === replyTo[0].email) {
					return false;
				}

				return true;
			});

			replyToAll = true;
		} else {
			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];

			replyToAll = false;
		}

		handleOpenComposeModal({
			type: 'reply',
			title: 'Reply',
			emailData: {
				replyTo,
				replyToMessageId,
				replyToEmailContent,
				replyEmailDate,
				replyEmailName,
				replyEmailAddress,
				replyToAll,
				replyToCC,
				subject
			},
			contact: selectedCustomer,
			enquiry: selectedEnquiry
		});
	}

	const showRecents = async (target) => {
		let _logs = [];

		try {
			const querySnapshot = await window.firebase.firestore()
				.collection('nylasv3-logs')
				.where('message_id', '==', message.id)
				.get();


			const ipCache = {}; // Cache for storing IP responses

			for (const doc of querySnapshot.docs) {
				const _data = doc.data();
				if (!_.isEmpty(_data.recents)) {
					for (const recent of _data.recents) {
						const _ip = recent.ip ? recent.ip.split(',')[0] : '';
						if (_ip) {
							// Check if IP data is already in the cache
							if (!ipCache[_ip]) {
								try {
									const ipstackResp = await fetch(`https://api.ipstack.com/${_ip}?access_key=bd0542ba9b4743c0df5f8cf5920661cd`);
									const ipstack = await ipstackResp.json();
									ipCache[_ip] = ipstack; // Store the response in the cache
								} catch (ipError) {
									console.error(`Error fetching IP data for ${_ip}:`, ipError);
									ipCache[_ip] = null; // Mark as null to avoid refetching
								}
							}

							// Use cached or fetched IP data
							_logs.push({
								...recent,
								date: moment.unix(recent.timestamp).format('MMM DD, YYYY hh:mm A'),
								type: _data.type,
								ipstack: ipCache[_ip],
							});
						}
					}
				}
			}
			setRecentLoad(false);
			console.log('_logs', _.orderBy(_logs, ['timestamp'], ['desc']))
			let _log = <>{
				!_.isEmpty(_logs)
					?
					<>

						<div className="email-trackhistory-wrap" style={{
							height: '300px',
							overflow: 'auto',
							position: "relative"
						}}>

							{
								_.orderBy(_logs, ['timestamp'], ['desc']).map((log, index) => {
									return <div key={index} className="email-trackhistory-loop">

										<div className="email-trackhistory-sub">
											<div className="email-trackhistory-head">
												<div className={`badge badge-pill badge-input-${log.type} mr-1 text-capitalize cursor-pointer`}>{log.type}</div>
												<div className="email-trackhistory-date"> <span>{log.date}</span> </div>
											</div>

											{
												log.ip && (<div className="email-trackhistory-ip ">
													<i className="ico icon-settings-sites"></i>
													<span title={log.user_agent || ''}>{log.ip.split(',')[0]}</span>
													{
														log?.ipstack?.location?.country_flag && (
															<span className="email-trackhistory-flag" title={log.ipstack?.country_name || ''}><img src={log.ipstack.location.country_flag} /></span>
														)
													}

												</div>)
											}
											{
												log?.ipstack && (<div className="email-trackhistory-device mt-2">
													<i className="ico icon-location mr-1"></i>
													{log?.ipstack?.city || ''}, {log?.ipstack?.region_name || ''}, {log?.ipstack?.country_name || ''}
												</div>)
											}

											{/* <div className="email-trackhistory-device mt-2">
												<i className="ico icon-monitor mr-1"></i>
												{log.user_agent}
											</div> */}
										</div>
									</div>
								})
							}
						</div>

					</>
					:
					<>
						<div className='email-trackhistory-wrap'>
							<div className='no-data-flex justify-content-center align-items-center pb-3'>
								<div className='no-data-txt mt-2'>  <Translate text={'No Recent Views'} /></div>
							</div>
						</div>
					</>
			}</>

			setRecentPopover({ showpopover: true, targetpopover: target, logs: _log })

		} catch (error) {
			console.error('Error fetching logs:', error);
		}



	}

	return (
		<div key={message.id} className={`email-loop ${isMsgLatest ? '' : 'pb-2'}`} id={`message-${message.id}`}>
			<div className={`email-details-subhead ${isMsgLatest ? '' : 'email-thread-hilight'}`}>
				<div className='email-loop-head'>
					<div className='email-loop-avatar' onClick={handleToggleExpand}>
						{/* <img src={CommonHelper.getUserImagebyId(_allUser, (_resPerson ? _resPerson : addedBy))} alt="" width="50" height="50" className="rounded-circle img-object-fit" /> */}
						<img className='bdrs-50p w-3r h-3r' alt='' src={CommonHelper.getUserImagebyId(_allUser, message.from[0].email, message.from[0]?.name)} />
					</div>
					<div className='email-loop-deatils'>
						<table width='100%' border='0' cellSpacing='0' cellPadding='0'>
							<tbody>
								<tr>
									<td onClick={handleToggleExpand}>
										<div className='email-details-username'>
											{`${!_.isEmpty(message.from) && message.from.length > 0 && message.from[0]?.name ? message.from[0].name : ''} `}
											<span>&lt;{message.from[0].email}&gt;</span>
										</div>
									</td>
									<td className='td-mail-controls'>
										<div className='mail-controls-inner'>
											{!_.isEmpty(message?.attachments) ? (
												<span className='mail-attach-icon' onClick={handleToggleExpand}>
													<i className='ico icon-mail-attachment-linear'></i>
												</span>
											) : (
												<></>
											)}
											<span className='email-datetime' onClick={handleToggleExpand}>
												{`${moment
													.unix(message.date)
													.format('MMM DD, YYYY hh:mm A')} (${moment
														.unix(message.date)
														.fromNow()})`}
											</span>

											{
												selectedThread.folders.includes(CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Sent', 'id')) && (
													<div className='mail-control-icon' title={`Recent Activity`} onMouseOver={(e) => e.preventDefault()}>

														<a href='#' onClick={(e) => { e.preventDefault(); setRecentLoad(true); showRecents(e.target) }}>
															<i
																className='fa fa-eye custom-icon-preview'
																aria-hidden='true'
																style={{ paddingTop: '0px', marginRight: '4px' }}
															></i>
														</a>
														{
															recentLoad ?
																<><span
																	className='spinner-border spinner-button mr-1'
																	role='status'
																	aria-hidden='true'
																></span></>
																:
																<></>
														}
													</div>)
											}


											{expand ? (
												<>
													<div
														className='mail-control-icon'
														onClick={() => handleReply(false)}
													>
														<a href='#' onClick={(e) => { e.preventDefault(); }}>
															<i className='ico icon-mail-reply'></i>
														</a>
													</div>

													<Dropdown className='mail-control-more'>
														<Dropdown.Toggle as={CustomToggle} className=''>
															<i className='ico icon-more'></i>
														</Dropdown.Toggle>
														<Dropdown.Menu
															as={CustomMenu}
															ChildClass='more-dropdown'
															xplacement='bottom-end'
														>
															<Dropdown.Item
																eventKey='1'
																onClick={() => handleReply(false)}
																className='check'
															>
																<i className='ico icon-mail-reply'></i>{' '}
																<span className='pl-0'>Reply</span>{' '}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey='1'
																onClick={() => handleReply(true)}
																className='check'
															>
																<i className='ico icon-mail-replyall'></i>{' '}
																<span className='pl-0'>Reply To All</span>{' '}
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</>
											) : (
												<></>
											)}
										</div>
									</td>
								</tr>
								{expand ? (
									<>
										{!_.isEmpty(message.to) && (
											<tr onClick={handleToggleExpand}>
												<td colSpan='2'>
													<div className='email-details-secondary-name'>
														To: {message.to && message.to.length > 0 && message.to.map(item => item.name && item.email && item.name !== item.email ? `${item.name} <${item.email}>` : item.email ? `${item.email}` : '').join(', ')}
													</div>
												</td>
											</tr>
										)}
										{!_.isEmpty(message.cc) && (
											<tr onClick={handleToggleExpand}>
												<td colSpan='2'>
													<div className='email-details-secondary-name'>
														cc: {message.cc[0].email}
													</div>
												</td>
											</tr>
										)}
										{!_.isEmpty(message.bcc) && (
											<tr onClick={handleToggleExpand}>
												<td colSpan='2'>
													<div className='email-details-secondary-name'>
														bcc: {message.bcc[0].email}
													</div>
												</td>
											</tr>
										)}
									</>
								) : (
									<></>
								)}
							</tbody>
						</table>
						{expand ? (
							<></>
						) : (
							<>
								<div
									className='email-details-secondary-name'
									style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
									onClick={handleToggleExpand}
								>
									{message.snippet.substring(0, 100) + '...'}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{expand ? (
				<>
					{
						disableLinkedinfo ? (
							<FullheightIframe
								message={message}
								accountID={message.grantId}
								threadID={message.threadId}
								messageID={message.id}
								token={selectedThread.token ? selectedThread.token : token}
								apiURL={dealersettings.apiURL}
								projectId={dealersettings?.client?.projectId}
								linkedID={selectedThread.documentID}
								nylasToken={dealersettings.nylasv3Config?.apiKey || null}
								nylasURL={dealersettings.nylasv3Config?.apiUri || null}
							/>
						) :
							(<FullheightIframe
								message={message}
								accountID={message.grantId}
								threadID={message.threadId}
								messageID={message.id}
								token={selectedThread.token ? selectedThread.token : token}
								apiURL={dealersettings.apiURL}
								projectId={dealersettings?.client?.projectId}
								nylasToken={dealersettings.nylasv3Config?.apiKey || null}
								nylasURL={dealersettings.nylasv3Config?.apiUri || null}
							/>)
					}

					{!_.isEmpty(message?.attachments) && (
						<div className='email-details-attachment'>
							<div className="mailbox-attachments-title">Attachments</div>
							<ul className='mailbox-attachments attachment-flex-layout clearfix'>
								{message.attachments.map(file => (
									<FileItem
										file={file}
										key={file.id}
										token={token}
										apiURL={dealersettings.apiURL}
										message={message}
										nylasToken={dealersettings.nylasv3Config?.apiKey || null}
										nylasURL={dealersettings.nylasv3Config?.apiUri || null}
									/>
								))}
							</ul>
						</div>
					)}
				</>
			) : (
				<></>
			)}

			<PopoverPanel
				showpopover={recentPopover.showpopover}
				targetpopover={recentPopover.targetpopover}
				title='Recent Activity'
				position='bottom'
				id='total-stock-cost'
				className='popover-pipeline-switch stocknew-tooltip-width'
				closepopover={() => {
					setRecentPopover({ showpopover: false, targetpopover: '', logs: null })
				}}>
				<>{recentPopover.logs}</>
			</PopoverPanel>
		</div>
	);
};

export default EmailListItem;
