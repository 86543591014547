import React, { useEffect, useState, useReducer } from 'react';
import _ from 'lodash';
import moment from 'moment';
import CustomToolbar from './toolbar'
import CustomEvent from './eventView';
import CommonHelper from '../../../../services/common';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { calendarEventColor } from '../../../../services/enum';
import { schedulerVM } from './viewModel';
const localizer = momentLocalizer(moment);

const ScheduleListReducer = (state, action) => {
	function updateEnquiry() {
		return state.map((item) => {
			if (item.documentID === action.data.documentID) {
				return action.data;
			}
			return item;
		});
	}
	switch (action.type) {
		case "SUCCESS": {
			return action.data
		}
		case "APPEND_LIST": {
			return [...state, ..._.differenceBy(action.data, state, 'documentID')]
		}
		case "ADD_LOG_LIST": {
			return [action.data, ...state, action.data]
		}
		case "REMOVE_LOG_LIST": {
			return state.filter(item => item.documentID !== action.data.documentID)
		}
		case "REMOVE_LOG": {
			return state.filter(item => item.documentID !== action.documentID)
		}
		case "UPDATE_LOG_LIST": {
			return updateEnquiry()
		}
		default:
			return state;
	}
};

const SchedulerLink = props => {
	const [schedulerList, dispatch] = useReducer(ScheduleListReducer, [])
	const [tvDevices, setTVDevices] = useState();
	const [clients, setClients] = useState();
	const [loader, setLoader] = useState(true);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const [viewstate, setViewState] = useState('day')
	const [monthData, setMonthData] = useState(moment()._d.getMonth())
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf('day'),
		endDate: moment().endOf('day'),
		date: moment().format('DD-MM-YYYY')
	})
	const [checkDataLoad, setDataload] = useState(true)
	const [allUsers, setAllUsers] = useState([])
	const [otherUsers, setOtherUsers] = useState([])
	const [workingStartHour, setworkingStartHour] = useState()
	const [workingEndHour, setworkingEndHour] = useState()
	const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 10)
	const [clientSettings, setClientSettings] = useState()

	useEffect(() => {
		const snaptvDevices = window.firebase.firestore().collection(`tvDevices`)
			.where('isDeleted', '==', false)
			.where('oemID', '==', props.oemID)
			.onSnapshot(querySnapshot => {
				let _devices = [];
				querySnapshot.forEach(function (doc) {
					_devices.push(
						{
							...doc.data(),
							id: doc.id
						})
				});
				setTVDevices(_.orderBy(_.uniqBy(_devices, 'id'), ['addedDate.seconds'], ['asc']).map((rec, index) => {
					return {
						...rec,
						resourceId: rec.documentID,
						resourceTitle: rec.name,
						wrapper: calendarEventColor[index] ? calendarEventColor[index].value : null
					}
				}));
				loadClients();
			});
		return () => {
			snaptvDevices && snaptvDevices();
		}
	}, [])

	const loadClients = async () => {
		let settingsDoc = {};
		const settingsSnap = await window.firebase.firestore().doc(`clientSettings/${props.oemID}`).get();
		if (settingsSnap.exists) {
			settingsDoc = settingsSnap.data();
		}

		const snapClients = await window.firebase.firestore().collection(`clients`)
			.where('settingsID', '==', props.oemID)
			.where('moduleSettings.tvModule.enabled', '==', true)
			.get();
		let _clients = [];
		const clientpromises = []
		snapClients.docs.forEach(function (doc) {
			_clients.push(doc.data())
			clientpromises.push(window.firebase.firestore().doc(`clientSettings/${props.oemID}/clients/${doc.id}`).get())
		});
		const clientsnapshots = await Promise.all(clientpromises);
		const objList = !_.isEmpty(settingsDoc) && Object.keys(settingsDoc).filter(function (e) { return _.isObject(settingsDoc[e]) })
		clientsnapshots.forEach(snap => {
			if (snap.exists) {
				var dataDoc = snap.data();
				objList.forEach((key) => {
					if (_.isArray(dataDoc[key])) {
						dataDoc[key].forEach((lookups) => {
							settingsDoc[key] = [
								...settingsDoc[key],
								lookups
							]
						})
					}
				})
			}
		})
		setClientSettings(settingsDoc)
		moment.tz.setDefault(_clients[0]?.timezone ? _clients[0]?.timezone : moment.tz.guess())
		setworkingStartHour(_clients[0]?.workingHours?.split(';')[0] ? moment(`${moment().format('YYYY-MM-DD')} ${_clients[0]?.workingHours.split(';')[0]}`).hours() : 6)
		setworkingEndHour(_clients[0]?.workingHours?.split(';')[1] ? moment(`${moment().format('YYYY-MM-DD')} ${_clients[0]?.workingHours.split(';')[1]}`).hours() : 22)
		setClients(_clients);
		setLoader(false)
	}

	useEffect(() => {
		function handleResize() {
			setCaleandarHeight(window.innerHeight - 10)
		}
		window.addEventListener('resize', handleResize);
		if (window.reminderTimeout) {
			clearInterval(window.reminderTimeout);
		}
		window.reminderTimeout = setInterval(() => {
			if (dateRange.date !== moment().format('DD-MM-YYYY')) {
				setDateRange({
					startDate: moment().startOf('week').add(-1, 'day').startOf('day'),
					endDate: moment().endOf('week').add(1, 'day').endOf('day'),
					date: moment().format('DD-MM-YYYY')
				})
				window.location.reload();
			}
		}, 60000);
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unrefSchedulatData && window.unrefSchedulatData();
		}
	}, []);

	useEffect(() => {
		if (loader)
			return;

		let refActivitiesData = window.firebase.firestore().collection('tvScheduler')
			.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(dateRange.startDate.toDate()))
			.where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(dateRange.endDate.toDate()))
			.where('oemID', '==', props.oemID)
			.where('isDeleted', '==', false)

		window.unrefSchedulatData = refActivitiesData
			.onSnapshot(onCollectionUpdate);

		//}

	}, [dateRange, loader])

	const onCollectionUpdate = (querySnapshot) => {
		let actionType;
		const _activities = [];
		if (!actionType) {
			querySnapshot.forEach(function (doc) {
				var logVM = convertLogVM(doc.data())
				_activities.push(logVM)
			});
		}
		let _otherUsers = [];
		_activities.forEach(rec => {
			if (rec.enqOwner && !allUsers.some(a => a.id === rec.enqOwner)) {
				_otherUsers.push(rec.enqOwner)
			}
		})
		if (JSON.stringify(_otherUsers) !== JSON.stringify(otherUsers))
			setOtherUsers(_.uniq(_otherUsers))
		dispatch({
			type: "SUCCESS",
			data: _activities
		});
		setDataload(false)
	}

	useEffect(() => {
		if (_.isEmpty(otherUsers))
			return;

		let userPromise = [];

		otherUsers.forEach(user => {
			if (!allUsers.some(a => a.id === user))
				userPromise.push(window.firebase.firestore().doc(`users/${user}`).get());
		})

		Promise.all(userPromise)
			.then(snapshot => {
				let users = [];
				snapshot.forEach(doc => {
					users.push({
						...doc.data(),
						id: doc.id
					})
				})
				setAllUsers([
					...allUsers,
					...users
				])
			})

	}, [otherUsers])

	const convertLogVM = (doc) => {

		doc.startDate = !_.isObject(doc.startDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate)._d) : doc.startDate;
		doc.endDate = !_.isObject(doc.endDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate)._d) : doc.endDate;

		const objlogData = Object.assign({}, doc);
		const logVM = Object.assign({}, schedulerVM);
		for (let [key, value] of Object.entries(objlogData)) {
			if (logVM.hasOwnProperty(key))
				logVM[key] = value;
		}
		logVM.clientID = doc.clientID
		logVM.start = moment.unix(doc.startDate.seconds).toDate()
		logVM.end = moment.unix(doc.endDate.seconds).toDate()
		logVM.contactName = doc?.enquiry?.contact ? CommonHelper.displayContactName([], doc.enquiry.contact) : ''
		logVM.vehiclemodel = doc.enquiry && doc.enquiry.requirement ? !_.isEmpty(doc.enquiry.requirement) ? (!_.isEmpty(doc.enquiry.requirement.stock) && !_.isEmpty(doc.enquiry.requirement.stock.make)) ? (!_.isEmpty(doc.enquiry.requirement.stock.make) ? (doc.enquiry.requirement.stock.make + ' ' + doc.enquiry.requirement.stock.model) : '') : (!_.isEmpty(doc.enquiry.requirement.make) ? (doc.enquiry.requirement.make + ' ' + doc.enquiry.requirement.model) : '') : '' : '';
		logVM.saleType = doc?.enquiry?.requirement?.saleType;
		logVM.regNo = doc?.enquiry?.requirement?.stock?.regNo ? doc.enquiry.requirement.stock.regNo : doc?.enquiry?.requirement?.regNo ? doc.enquiry.requirement.regNo : doc?.enquiry?.regNo ? doc.enquiry.regNo : '';
		logVM.stockNo = doc?.enquiry?.requirement?.stock?.stockNo ? doc.enquiry.requirement.stock.stockNo : doc?.enquiry?.requirement?.stockNo ? doc.enquiry.requirement.stockNo : doc?.enquiry?.stockNo ? doc.enquiry.stockNo : '';
		logVM.enqOwner = doc?.enquiry?.owner ? doc.enquiry.owner : '';
		// logVM.enqOwner = doc?.enquiry?.owner ? common.getUserNamebyId(allUsers, doc.enquiry.owner) : '';
		// logVM.enqOwnerImg = doc?.enquiry?.owner ? common.getUserImagebyId(allUsers, doc.enquiry.owner) : '';
		let saleTypeColor = null;
		if (doc?.enquiry?.requirement?.saleType) {
			const settings = clientSettings?.salesType?.find(a => a.value === doc?.enquiry?.requirement?.saleType)
			const make = doc?.enquiry?.requirement?.stock?.make ? doc?.enquiry?.requirement?.stock?.make.toLowerCase().trim() : doc?.enquiry?.requirement?.make ? doc?.enquiry?.requirement?.make.toLowerCase().trim() : '';
			if (settings?.colors?.find(a => a.make.toLowerCase().trim() === make)) {
				saleTypeColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === make).color;
			}
			else if (settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others')) {
				saleTypeColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others').color;
			}
			logVM.saleTypeName = settings?.name || logVM.saleType;
		}
		if (_.find(tvDevices, { documentID: logVM.deviceID })) {
			logVM.resourceId = logVM.deviceID;
			logVM.resourceTitle = _.find(tvDevices, { documentID: logVM.deviceID }).name;
			logVM.deviceName = _.find(tvDevices, { documentID: logVM.deviceID }).name;
			logVM.wrapper = saleTypeColor ? saleTypeColor : _.find(tvDevices, { documentID: logVM.deviceID }).wrapper;
		}
		logVM.dealerName = CommonHelper.getOtherDealerName({ clients }, doc.clientID);


		return logVM
	}

	const getResource = () => {
		return viewstate === 'day' ? {
			resources: tvDevices.filter(a => !Boolean(a.showScheduler)),
			resourceIdAccessor: "resourceId",
			resourceTitleAccessor: "resourceTitle"
		} : {}
	}


	const handleSelectSlot = () => {
		return;
	}

	return loader ? (
		<>
			<div className='loader-center-screen'>
				<div className='spinner-loader h-100 '>
					<div className='d-flex h-100 justify-content-center align-items-center'>
						<div role='status' className='spinner-border' style={{ color: '#4466F2' }}></div>
						<p className='text-muted mt-3'></p>
					</div>
				</div>
			</div>
		</>
	) : (
		<div className={`${props.activityLink ? '' : 'popup-wrap-fullscreen h-100'}`}>
			<div className={`${props.activityLink ? '' : 'container-fluid'}`}>
				<Calendar
					className='tv-scheduler'
					popup={false}
					localizer={localizer}
					events={schedulerList.filter(a =>
						(props?.clientIDs?.length > 0 ? props.clientIDs.includes(a.clientID) : true)
						&& (props?.salesType?.length > 0 ? props.salesType.includes(a.saleType) : true)
					)}
					style={props.inIframe ? { height: calendarHeight } : props.height ? { height: `${props.height}px` } : { zoom: '75%' }}
					defaultView="day"
					views={['day', 'week']}
					min={moment().set('hour', workingStartHour).set('minute', 0).toDate()}
					max={moment().set('hour', workingEndHour).set('minute', 0).toDate()}
					components={{
						//toolbar: CustomToolbar,
						toolbar: propstb => (<CustomToolbar {...propstb}
							popupModel={true}
							viewstate={viewstate}
							handleChangeviewstate={(view) => {
								setViewState(view);
							}}
							handlechangemonth={(month) => setMonthData(month)}
							monthData={monthData}
							setDateRange={(dateRange) => {
								setDateRange(dateRange)
								setDataload(true)
							}}
							device={'device'}
							hideSearch={true}
							hideAddSchedule={true}
							events={schedulerList.filter(a =>
								(props?.clientIDs?.length > 0 ? props.clientIDs.includes(a.clientID) : true)
								&& (props?.salesType?.length > 0 ? props.salesType.includes(a.saleType) : true)
							)}
						/>),
						eventWrapper: propsevt => (<CustomEvent {...propsevt}
							onSelectEvent={handleSelectSlot}
							allUsers={allUsers}
							viewstate={viewstate} />)
					}}
					selectable
					onSelectSlot={handleSelectSlot}
					dayPropGetter={(date) => {
						return {
							className: 'special-day',
						}
					}}
					{...getResource()}
				/>
			</div>
		</div>
	);
};

export default SchedulerLink;
