import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { ContentOverlay, BarChart, PieChart, LineChart, NoDataPieChart } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import { reportGroupBy, reportDynColumns } from '../../serviceReports/viewModel'
import Translate from '../../../constants/translate';
import NumberFormat from 'react-number-format';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


const ServiceDynamicReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [progressReport, setProgressReport] = useState([])
    const [tempData, setTempData] = useState([])
    const [reportData, setReportData] = useState({
        enquiries: [],
        walkins: [],
        testdrives: [],
        doneActivities: [],
        scheduleActivities: [],
        quotations: []
    })
    const [dynReport, setDynReport] = useState()
    const [dealersettings, setDealersettings] = useState()
    const [clientUsers, setClientUsers] = useState()
    const [propsSettings, setPropsSettings] = useState()
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [inclInboundLeadsinRpt, setInclInboundLeadsinRpt] = useState(false);
    const [currencySymbol, setcurrencySymbol] = useState('');
    const [calcCurrentStatus, setCalcCurrentStatus] = useState('false');
    const [multiTDperLead, setMultiTDperLead] = useState('false');

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    let _startdate = params.get('startDate');
    let _enddate = params.get('endDate');

    useEffect(() => {
        window.firebase.firestore().doc(`serviceReports/${props.reportid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    const schedularDoc = await window.firebase.firestore().doc(`schedular/${props.reportid}`).get();
                    setDynReport({
                        ...doc.data(),
                        'documentID': doc.id,
                        'pipeline': _.isArray(doc.data().pipeline) ? doc.data().pipeline : doc.data().pipeline !== 'all' ? [doc.data().pipeline] : [],
                        'columns': _.isArray(doc.data().columns) ? doc.data().columns : [doc.data().columns],
                        'upcomingDays': schedularDoc?.data()?.upcomingDays ? schedularDoc?.data().upcomingDays : [],
                        'dataParam': schedularDoc?.data()?.dataParam ? schedularDoc?.data().dataParam : ''
                    })
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(dynReport) || _.isEmpty(dynReport.clientID))
            return


        window.firebase.firestore().doc(`clients/${dynReport.clientID}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setcurrencySymbol(!_.isEmpty(doc.data().currency) ? doc.data().currency.symbol + ' ' : '$ ');
                }
                window.firebase.firestore().doc(`clients/${dynReport.clientID}/currentSettings/${dynReport.clientID}`)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            let timezone = !_.isEmpty(doc.data().timezone) ? doc.data().timezone : moment.tz.guess();
                            const _currentDate = moment().tz(timezone).format('YYYY-MM-DD HH:mm');
                            if (_startdate && _enddate) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_startdate).tz(timezone).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_enddate).tz(timezone).endOf('day').toDate())
                                });
                            }
                            else if (_date === 'today') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                                });
                            }
                            else if (_date === 'lwd') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                                });
                            }
                            else if (_date === 'wtd') {
                                if (!_.isEmpty(doc.data().weekStart)) {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: moment().day(doc.data().weekStart).day(),
                                        },
                                    })
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate()),
                                    });
                                }
                                else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                                    });
                                }
                            }
                            else if (dynReport.dataParam === 'upcoming' && !_.isEmpty(dynReport.upcomingDays)) {
                                if (!_.isEmpty(doc.data().weekStart)) {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: moment().day(doc.data().weekStart).day(),
                                        },
                                    })
                                }
                                if (moment().startOf('week').day() >= 1 && dynReport.upcomingDays.includes('0')) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('week').toDate())
                                    })
                                }
                                else if (moment().startOf('week').day() === 0 && dynReport.upcomingDays.includes('0')) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                                    })
                                }
                                else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays)).toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays)).toDate()),
                                    })
                                }

                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('month').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('month').toDate())
                                });
                            }
                            setPropsSettings({
                                dealersettings: {
                                    ...doc.data(),
                                    client: {
                                        id: doc.id,
                                        services: doc.data().services,
                                        integrations: doc.data().integrations,
                                        workingHours: doc.data().workingHours,
                                    }
                                },
                                pipeline: doc.data().services.allPipelines,
                                clientUsers: doc.data().users,

                            })
                            setDealersettings({
                                ...doc.data(),
                                'clientID': doc.id
                            })
                            setClientUsers(doc.data().users.filter(a => !Boolean(a.isDisabled)))
                        }
                    })
            })

    }, [dynReport])

    useEffect(() => {
        if (_.isEmpty(propsSettings))
            return
        let _leadSource = []
        if (dynReport.pipeline
            && dynReport.pipeline.length === 1
            && propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.services &&
            propsSettings.dealersettings.client.services.allPipelines
            && propsSettings.dealersettings.client.services.allPipelines.find(a => a.value === dynReport.pipeline[0])) {
            propsSettings.dealersettings.client.services.allPipelines.find(a => a.value === dynReport.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    _leadSource.push({
                        label: `${rec.name} (Stage)`,
                        searchlabel: `${rec.name} Stage`,
                        value: `stage-${rec.value}`,
                        name: `${rec.name} (Stage)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Stage',
                    })
                }
            })
        }
        setReportColumns([...reportColumns.filter(a => _.isEmpty(a.sourceType)), ..._leadSource]);
    }, [propsSettings])

    useEffect(() => {
        if (_.isEmpty(dealersettings))
            return
        setLoader(true)
        let _filters = {};
        if (!_.isEmpty(dynReport.objFilters) && dynReport.objFilters.length > 0) {
            dynReport.objFilters.forEach(rec => {
                _filters = rec.name === 'date' ? {
                    ..._filters,
                    startDate: moment.unix(rec.value.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(rec.value.endDate.seconds).format('YYYY-MM-DD')
                } : {
                    ..._filters,
                    ...rec.value,
                    ...(rec.subList ? rec.subList : {})
                }
            })
        }
        else {
            _filters = {
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            }
        }
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getServiceDynamicReport',
                params: JSON.stringify({
                    clientID: dealersettings.clientID,
                    reportJSON: JSON.stringify(dynReport),
                    ..._filters,
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD')
                })
            }).then((response) => {
                setLoader(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
            });
        }
        catch (error) {
            console.log(error)
            setLoader(false)
            // Swal.fire('Something went wrong', '', 'error')
        }

    }, [dealersettings])

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        const _score = _.sumBy(progressReport, '4plus') > 0 ? Math.round((_.sumBy(progressReport, '15plus') / _.sumBy(progressReport, '4plus')) * 100) : 0;
        var chart = am4core.create(`progressive-${dynReport.documentID}`, am4charts.PieChart);
        chart.logo.disabled = true;
        chart.data = [{
            "category": "",
            "value": _score,
            "color": "#4466f2"

        }, {
            "category": "",
            "value": 100 - _score,
            "color": '#d3d9e6'
        }];

        chart.innerRadius = am4core.percent(90)
        //chart.radius = am4core.percent(90)

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.tooltip.disabled = true;
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        var label = pieSeries.createChild(am4core.Label)
        label.text = `${_score}%`;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = "15px";
        //chart.validateNow();

        pieSeries.slices.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.dataContext.color)
                return am4core.color(target.dataItem.dataContext.color);
            return color
        });

        window.persistencyChart = chart;
    }, [progressReport])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][dealersettings.clientID] : []
        let reports = [];
        _report.forEach(rec => {
            if (Object.keys(rec).filter(r => Boolean(dynReport.includeZero) ? true : _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                reports.push({
                    ...rec,
                    profileImage: (dynReport.groupBy === 'user'
                        || dynReport.groupBy === 'technician'
                        || dynReport.groupBy === 'partsInterpretor'
                        || dynReport.groupBy === 'workshopManager'
                        || dynReport.groupBy === 'serviceAdvisor'
                    ) && _.find(clientUsers, { id: rec.id }) ? _.find(clientUsers, { id: rec.id }).profileImage : '',
                    totalJobs: rec.arrtotalJobs ? rec.arrtotalJobs.length : 0,
                    cancelled: rec.arrcancelled ? rec.arrcancelled.length : 0,
                    scheduled: rec.arrscheduled ? rec.arrscheduled.length : 0,
                    checkIn: rec.arrcheckIn ? rec.arrcheckIn.length : 0,
                    technicianRequested: rec.arrtechnicianRequested ? rec.arrtechnicianRequested.length : 0,
                    partsRequested: rec.arrpartsRequested ? rec.arrpartsRequested.length : 0,
                    workshopRequested: rec.arrworkshopRequested ? rec.arrworkshopRequested.length : 0,
                    advisorRequested: rec.arradvisorRequested ? rec.arradvisorRequested.length : 0,
                    ownerRequested: rec.arrownerRequested ? rec.arrownerRequested.length : 0,
                    pendingjobs: rec.arrpendingjobs ? rec.arrpendingjobs.length : 0,
                    ownerApproved: rec.arrownerApproved ? rec.arrownerApproved.length : 0,
                    ownerDeclined: rec.arrownerDeclined ? rec.arrownerDeclined.length : 0,
                    completed: rec.arrcompleted ? rec.arrcompleted.length : 0,
                    //total job duration
                    arroveralljobDuration: rec.arrjobDuration ? _.uniq(rec.arrjobDuration.map(r => { return r.documentID })) : [],
                    totaloveralljobDuration: _.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.sumBy(rec.arrjobDuration, "jobDuration") : 0,
                    overalljobDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.sumBy(rec.arrjobDuration, "jobDuration") : 0, propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //avg response time
                    arrjobDuration: rec.arrjobDuration ? _.uniq(rec.arrjobDuration.map(r => { return r.documentID })) : [],
                    totaljobDuration: _.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.meanBy(rec.arrjobDuration, "jobDuration") : 0,
                    jobDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.meanBy(rec.arrjobDuration, "jobDuration") : 0, propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxjobDuration: _.maxBy(rec.arrjobDuration, 'jobDuration') ? [_.maxBy(rec.arrjobDuration, 'jobDuration').documentID] : [],
                    totalmaxjobDuration: _.maxBy(rec.arrjobDuration, 'jobDuration') ? _.maxBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0,
                    maxjobDuration: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrjobDuration, 'jobDuration') ? _.maxBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminjobDuration: _.minBy(rec.arrjobDuration, 'jobDuration') ? [_.minBy(rec.arrjobDuration, 'jobDuration').documentID] : [],
                    totalminjobDuration: _.minBy(rec.arrjobDuration, 'jobDuration') ? _.minBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0,
                    minjobDuration: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrjobDuration, 'jobDuration') ? _.minBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //total service response time
                    arroverallserviceDuration: rec.arrserviceDuration ? _.uniq(rec.arrserviceDuration.map(r => { return r.documentID })) : [],
                    totaloverallserviceDuration: _.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.sumBy(rec.arrserviceDuration, "serviceDuration") : 0,
                    overallserviceDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.sumBy(rec.arrserviceDuration, "serviceDuration") : 0, propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //avg service response time
                    arrserviceDuration: rec.arrserviceDuration ? _.uniq(rec.arrserviceDuration.map(r => { return r.documentID })) : [],
                    totalserviceDuration: _.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.meanBy(rec.arrserviceDuration, "serviceDuration") : 0,
                    serviceDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.meanBy(rec.arrserviceDuration, "serviceDuration") : 0, propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxserviceDuration: _.maxBy(rec.arrserviceDuration, 'serviceDuration') ? [_.maxBy(rec.arrserviceDuration, 'serviceDuration').documentID] : [],
                    totalmaxserviceDuration: _.maxBy(rec.arrserviceDuration, 'serviceDuration') ? _.maxBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0,
                    maxserviceDuration: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrserviceDuration, 'serviceDuration') ? _.maxBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminserviceDuration: _.minBy(rec.arrserviceDuration, 'serviceDuration') ? [_.minBy(rec.arrserviceDuration, 'serviceDuration').documentID] : [],
                    totalminserviceDuration: _.minBy(rec.arrserviceDuration, 'serviceDuration') ? _.minBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0,
                    minserviceDuration: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrserviceDuration, 'serviceDuration') ? _.minBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //revenue
                    arrtotalRecommCost: rec.arrtotalRecommCost ? _.uniq(rec.arrtotalRecommCost.map(r => { return r.documentID })) : [],
                    arrtotalLabourCost: rec.arrtotalLabourCost ? _.uniq(rec.arrtotalLabourCost.map(r => { return r.documentID })) : [],
                    arrtotalPartsCost: rec.arrtotalPartsCost ? _.uniq(rec.arrtotalPartsCost.map(r => { return r.documentID })) : [],
                    arrtotalServicePartsCost: rec.arrtotalServicePartsCost ? _.uniq(rec.arrtotalServicePartsCost.map(r => { return r.documentID })) : [],
                    arrtotalServicePackagesCost: rec.arrtotalServicePackagesCost ? _.uniq(rec.arrtotalServicePackagesCost.map(r => { return r.documentID })) : [],
                    arrtotalServiceCost: rec.arrtotalServiceCost ? _.uniq(rec.arrtotalServiceCost.map(r => { return r.documentID })) : [],
                    arrtotalDeclinedCost: rec.arrtotalDeclinedCost ? _.uniq(rec.arrtotalDeclinedCost.map(r => { return r.documentID })) : [],
                    totalServiceCost: rec.arrtotalServiceCost ? Math.round((_.sumBy(rec.arrtotalServiceCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalDeclinedCost: rec.arrtotalDeclinedCost ? Math.round((_.sumBy(rec.arrtotalDeclinedCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalRecommCost: rec.arrtotalRecommCost ? Math.round((_.sumBy(rec.arrtotalRecommCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalLabourCost: rec.arrtotalLabourCost ? Math.round((_.sumBy(rec.arrtotalLabourCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalPartsCost: rec.arrtotalPartsCost ? Math.round((_.sumBy(rec.arrtotalPartsCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalServicePartsCost: rec.arrtotalServicePartsCost ? Math.round((_.sumBy(rec.arrtotalServicePartsCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalServicePackagesCost: rec.arrtotalServicePackagesCost ? Math.round((_.sumBy(rec.arrtotalServicePackagesCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    formattotalServiceCost: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrtotalServiceCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100}
                    />,
                    formattotalDeclinedCost: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrtotalDeclinedCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100}
                    />,
                    formattotalRecommCost: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrtotalRecommCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100}
                    />,
                    formattotalLabourCost: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrtotalLabourCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100}
                    />,
                    formattotalPartsCost: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrtotalPartsCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100}
                    />,
                    formattotalServicePartsCost: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrtotalServicePartsCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100}
                    />,
                    formattotalServicePackagesCost: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrtotalServicePackagesCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100}
                    />,
                    ...getDynamicTotalLength(rec)
                })
            }
        });

        dynReport.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setProgressReport(reports)
    }

    const getDynamicTotalLength = (data) => {
        let leadSourceData = {};

        if (dynReport.pipeline
            && dynReport.pipeline.length === 1
            && propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.services &&
            propsSettings.dealersettings.client.services.pipelines
            && propsSettings.dealersettings.client.services.pipelines.find(a => a.value === dynReport.pipeline[0])) {
            let _leadSource = []
            let _sourceFields = []
            propsSettings.dealersettings.client.services.pipelines.find(a => a.value === dynReport.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: data[`arrstage-${rec.value}`] ? data[`arrstage-${rec.value}`].length : 0
                    }
                }
            })
        }

        return leadSourceData;
    }


    return (_.isEmpty(dealersettings) || loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        dealersettings && dealersettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={dealersettings.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{dealersettings.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> {dynReport.name} <br></br>
                                    {
                                        _date === 'wtd' || _date === 'lwd' || _startdate || _enddate || dynReport.dataParam === 'upcoming' ? (
                                            <span> {`${moment.unix(dateRange.startDate.seconds).format('DD MMM')}-${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`}</span>
                                        ) : !_.isEmpty(dealersettings.timezone) ? (
                                            <span> {moment().tz(dealersettings.timezone).format('DD MMMM YYYY')}</span>
                                        ) : (
                                            <span> {moment().format('DD MMMM YYYY')}</span>
                                        )
                                    }
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : progressReport && progressReport.length === 0 ? (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>
                    ) : dynReport.reportType === 'table' ? (<div className="table-wraper">
                        <table className="table-loop">
                            <thead>
                                <tr>
                                    <th className="cell-head" width="10%">
                                        {CommonHelper.getCustomValue(reportGroupBy, dynReport.groupBy, 'tableHeader', 'value')}
                                    </th>
                                    {
                                        dynReport.columns.map((rec, index) => {
                                            return <th key={index} className="head-light text-center" width={`${(90 / (dynReport.columns.length + (dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                {
                                                    reportColumns.find(item => item.value === rec) ?
                                                        (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                        (<span className="pipeline-inactive-span">(In Active)</span>)
                                                }
                                                {
                                                    rec.includes('enqtype-') ? (
                                                        <span className="th-subhead">(Enquiry Type)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('origin-') ? (
                                                        <span className="th-subhead">(Origin)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('outcome-') ? (
                                                        <span className="th-subhead">(Activity Outcome)</span>
                                                    ) : (<></>)
                                                }
                                            </th>
                                        })
                                    }
                                    {
                                        dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.map((rec, index) => {
                                            return <th key={index} className={`head-light text-center`} width={`${(90 / (dynReport.columns.length + (dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                <div className="sort-cell cursor-pointer" >
                                                    {rec.name}
                                                </div>
                                            </th>
                                        }) : (<></>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    progressReport.length > 0 ?
                                        progressReport.map((rec, index) => {
                                            return <tr key={index}>
                                                <td>
                                                    {
                                                        (dynReport.groupBy === 'user'
                                                            || dynReport.groupBy === 'technician'
                                                            || dynReport.groupBy === 'partsInterpretor'
                                                            || dynReport.groupBy === 'workshopManager'
                                                            || dynReport.groupBy === 'serviceAdvisor'
                                                        ) ? (<div className="table-user-list">
                                                            <a href="#">
                                                                <div className="table-user-avatar">
                                                                    <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                                {rec.displayName}
                                                            </a>
                                                        </div>) : (
                                                            <div className="dealership-tbl float-left text-left">
                                                                {rec.displayName}
                                                            </div>
                                                        )
                                                    }

                                                </td>
                                                {
                                                    dynReport.columns.map((val, index) => {
                                                        return <td key={index} className={`count-large ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}>
                                                            {
                                                                (val === 'totalServiceCost' || val === 'totalDeclinedCost' || val === 'totalRecommCost' || val === 'totalLabourCost' || val === 'totalPartsCost' || val === 'totalServicePartsCost' || val === 'totalServicePackagesCost') ? rec[`format${val}`] :
                                                                    `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                            }
                                                        </td>
                                                    })
                                                }
                                                {
                                                    dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.map((val, index) => {
                                                        return <td key={index} className={`count-large`}>
                                                            {rec[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                        </td>
                                                    }) : (<></>)
                                                }
                                            </tr>
                                        }) : (
                                            <tr>
                                                <td colSpan={dynReport.columns.length + 1}>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2"> No data</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                }
                            </tbody>
                            {
                                progressReport.length > 0 ?
                                    (<tfoot>
                                        <tr>
                                            <td className="td-head table-sub-total">Totals</td>
                                            {
                                                dynReport.columns.map((val, index) => {
                                                    return <td key={index} className="count-large text-center table-sub-total">
                                                        {
                                                            (val === 'jobDuration' ? (_.sumBy(progressReport, `totaljobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totaljobDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                val === 'overalljobDuration' ? (_.sumBy(progressReport, `totaloveralljobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totaloveralljobDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                    val === 'maxjobDuration' ? (_.sumBy(progressReport, `totalmaxjobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxjobDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                        val === 'minjobDuration' ? (_.sumBy(progressReport, `totalminjobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminjobDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                            val === 'overallserviceDuration' ? (_.sumBy(progressReport, `totaloverallserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totaloverallserviceDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                val === 'serviceDuration' ? (_.sumBy(progressReport, `totalserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalserviceDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                    val === 'maxserviceDuration' ? (_.sumBy(progressReport, `totalmaxserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxserviceDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                        val === 'minserviceDuration' ? (_.sumBy(progressReport, `totalminserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminserviceDuration), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                            val === 'totalServiceCost' || val === 'totalDeclinedCost' || val === 'totalRecommCost' || val === 'totalLabourCost' || val === 'totalPartsCost' || val === 'totalServicePartsCost' || val === 'totalServicePackagesCost' ? <NumberFormat
                                                                                                thousandSeparator={true}
                                                                                                prefix={currencySymbol}
                                                                                                displayType={'text'}
                                                                                                value={Math.round((_.sumBy(progressReport, val) + Number.EPSILON) * 100) / 100}
                                                                                            /> : _.sumBy(progressReport, val))
                                                        }
                                                        {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                                    </td>
                                                })
                                            }
                                            {
                                                dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.map((val, index) => {
                                                    let ptg = val.columns ? _.sumBy(val.columns.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                    let total = val.columns1 ? _.sumBy(val.columns1.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                    return <td key={index} className="count-large text-center table-sub-total">
                                                        {
                                                            val.action === 'percentage' ? (
                                                                <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                            ) : (<>{_.sumBy(progressReport, val.id)}</>)
                                                        }
                                                    </td>
                                                }) : (<></>)
                                            }
                                        </tr>
                                    </tfoot>
                                    ) : (<></>)
                            }
                        </table>
                    </div>
                    ) : dynReport.reportType === 'column' ?
                        (
                            <div className="text-center w-100 float-left">
                                <BarChart
                                    id={'view-report-bar-chart'}
                                    height={`550px`}
                                    data={progressReport}
                                    columns={dynReport.columns ? _.isArray(dynReport.columns) ? dynReport.columns : [dynReport.columns] : []}
                                    dynamicColumns={reportColumns}
                                    xCategory={'displayName'}
                                    calculationType={dynReport.calculationType}
                                    handleShowEnquiryList={(val, rec) => {
                                        console.log(val)
                                    }}
                                />
                            </div>
                        ) : dynReport.reportType === 'pie' ? (<>
                            {
                                progressReport.some(item => item[dynReport.columns] > 0) ? (
                                    <div className="text-center w-100 float-left">
                                        <PieChart
                                            id={'view-report-pie-chart'}
                                            height={`550px`}
                                            data={progressReport}
                                            dynamicReport={true}
                                            columns={_.isArray(dynReport.columns) ? dynReport.columns[0] : dynReport.columns}
                                            handleShowEnquiryList={(val, rec) => {
                                                console.log(val)
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <NoDataPieChart
                                        id={'no-data-pie-chart'}
                                        height={`550px`}
                                    />)
                            }
                        </>) : dynReport.reportType === 'target' ? (
                            <div className="text-center w-100 float-left">
                                <LineChart
                                    id={'view-report-line-chart'}
                                    height={`550px`}
                                    data={progressReport.map(rec => { return { ...rec, 'name': rec.displayName } })}
                                    dynamicColumns={reportColumns}
                                    xCategory={'displayName'}
                                    columns={dynReport.target > 0 ? (_.isArray(dynReport.columns) ? [...dynReport.columns, 'target'] : [dynReport.columns, 'target']) : (_.isArray(dynReport.columns) ? dynReport.columns : [dynReport.columns])}
                                    calculationType={dynReport.calculationType}
                                    handleShowEnquiryList={(val, rec) => {
                                        console.log(val)
                                    }}
                                />
                            </div>
                        ) : (<></>)
                }
                {
                    dealersettings ? (<div className="footer">
                        <div className="footer-bottom">
                            <p>
                                <span style={{ paddingLeft: 0 }} >
                                    {dealersettings.address ? <>{dealersettings.address} &nbsp; | &nbsp;</> : <></>}
                                </span>
                                <span style={{ paddingLeft: 0 }}>
                                    {dealersettings.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{dealersettings.phone}</span></> : <></>}
                                    {dealersettings.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >&nbsp; | &nbsp;{dealersettings.website}</span></> : <></>}
                                </span>
                            </p>
                        </div>
                        <div className="footer-cpy">
                            <div className="footer-left">{'Powered by FusionSD.'}</div>

                            <div className="footer-right"></div>

                        </div>
                        <div className="clear"></div>
                    </div>) : (<></>)
                }

            </section>
        </div>
    </>)
}

export default ServiceDynamicReport;