import { useGridFilter } from 'ag-grid-react';
import React, { useCallback } from 'react';


export default ({ model, onModelChange, colDef, context }) => {
    const dynColors = context?.settings?.fieldColors?.[colDef?.field] || context?.tagColors;

    const onFilterChange = useCallback((val) => {
        onModelChange(val)
    })

    const onFilterClear = useCallback((e) => {
        onModelChange(null)
    })

    const doesFilterPass = useCallback((params) => {
        return params?.data?.fieldTags?.[colDef.field] === model
    }, [model]);

    useGridFilter({
        doesFilterPass,
    });

    return (
        <div className="deallog-filterlist-bycolor">
            <div className='mr-4'>Filter by Colour</div>
            <div className="deallog-color-circle-list">
                {
                    dynColors.length ? (
                        dynColors.map((r, i) => {
                            return <div title={r.name} key={i} className={`deallog-color-circle ${model === r.value ? 'deallog-color-active' : ''}`} style={{ background: r.value }} onClick={() => { onFilterChange(model === r.value ? null : r.value) }}></div>
                        })
                    ) : (<></>)
                }
            </div>
            {/* <button onClick={onFilterClear}>clear</button> */}
        </div>
    );
};