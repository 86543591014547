import React, { useState, useEffect } from 'react';
import { default as _images } from '../../../images';
import { Modal, Accordion, Card, Tabs, Tab } from "react-bootstrap";
import Translate from '../../../constants/translate';
import _ from 'lodash'
import moment from 'moment'
import CommonHelper from '../../../services/common'
import { firestoreDB } from '../../../services/helper';

const AISummary = (props) => {

    const [activeAcc, setActiveAcc] = useState('')
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [aiSummary, setAiSummary] = useState({})



    const reloadAI = () => {

        setReload(true)
        const reportData = window.firebase.functions().httpsCallable('enquiries-aiSummary');
        reportData({
            enquiryID: props.enquiryID,
            timezone: moment.tz.guess(),
            projectID: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null,
        }).then((response) => {

            setReload(false)
        });
    }

    useEffect(() => {
        //console.log('AISummary', props.enquiryID)
        if (_.isEmpty(props.enquiryID)) { setLoading(false); return; }

        const refaiSummary = firestoreDB(props.dealersettings).firestore().doc(`/enquiries/${props.enquiryID}/aiSummary/${props.enquiryID}`)
            .onSnapshot(doc => {
                setLoading(false);
                if (doc.exists) {
                    setAiSummary(
                        {
                            ...doc.data(),
                            documentID: doc.id
                        })
                    setActiveAcc('0')
                }
                else {
                    setAiSummary(null)
                }
            })
        return () => {
            refaiSummary && refaiSummary();
        }
    }, [])

    const SummaryView = () => {
        return <>   {
            !_.isEmpty(aiSummary)
                ?
                <>

                    <div className="ai-summary-loop mt-2">
                        {/* <div className="ai-summary-head">
    <div className="ai-summary-title"> <img src={_images.ai_logo_gradient} /> AI Summary <div className="badge badge-beta-black ml-2">Beta</div> </div>
    <div className="ai-summary-refresh">
        <div className="ai-summary-refreshicon"><a href="#" className="ai-summary-mini-refresh"> <i className="ico icon-refresh"></i></a></div>
        <div className="ai-summary-refreshdate">
            16-01-2025 11:20 AM
        </div>
    </div>
</div> */}

                        {aiSummary?.engagement && (<div className="ai-summary-block">
                            <label>Engagement</label>
                            {reload ? <span>{CommonHelper.customPlaceholder(1, 1, true)}</span> : <span>{aiSummary?.engagement}</span>}
                        </div>)}
                        {aiSummary?.lastInteraction && (<div className="ai-summary-block">
                            <label>Last Interaction</label>
                            {reload ? <span>{CommonHelper.customPlaceholder(1, 1, true)}</span> : <span>{aiSummary?.lastInteraction}</span>}
                        </div>)}

                        {aiSummary?.recentActivity && (<div className="ai-summary-block">
                            <label>Recent Activity</label>
                            {reload ? <span>{CommonHelper.customPlaceholder(1, 1, true)}</span> : <span>{aiSummary?.recentActivity}</span>}
                        </div>)}

                        {aiSummary?.keyObservations && (<div className="ai-summary-block">
                            <label>Key Observations</label>
                            {reload ? <span>{CommonHelper.customPlaceholder(1, aiSummary?.keyObservations.length, true)}</span> : <ul>
                                {
                                    aiSummary?.keyObservations.map((data, index) => (<li key={index}>{data}</li>))
                                }
                            </ul>}
                        </div>)}

                        {aiSummary?.recommendations && (<div className="ai-summary-block">
                            <label>Recommendations</label>
                            {reload ? <span>{CommonHelper.customPlaceholder(1, aiSummary?.recommendations.length, true)}</span> : <ul>
                                {
                                    aiSummary?.recommendations.map((data, index) => (<li key={index}>{data}</li>))
                                }
                            </ul>}
                        </div>)}

                        {reload ? <></> : <div className="ai-summary-info">This feature uses AI. Results may be inaccurate.</div>}

                    </div>
                </>
                :
                <>
                    <div className="spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente" style={{ minHeight: '2px' }}>
                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                            <div className="no-data-img"> <img src={_images[`ai_logo_gradient${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                            <div className="no-data-txt mt-2">  <Translate text={'No AI Summary Generated'} /></div>

                            <button
                                className="btn btn-sm btn-default mt-4"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!reload) reloadAI();
                                }}
                            >
                                {reload ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className="ico icon-campaign-draft mr-1"></i>}
                                <Translate text={"Generate Now"} />
                            </button>
                        </div>
                    </div>

                </>
        }
        </>

    }
    return (
        <>
            {
                props.isModalView
                    ?
                    <>

                        <Modal show={props.show}
                            onHide={props.handleClose}
                            backdrop="static"
                            backdropClassName={props.show ? 'overlay-modal active' : ''}
                            enforceFocus={false}
                            dialogClassName="modal-dialog-scrollable modal-dialog-centered ai-summery-notify"
                        >
                            <Modal.Header >
                                <Modal.Title className='w-100'>

                                    <div className="ai-summary-head mb-0">
                                        <div className="ai-summary-title"> <img src={_images.ai_logo_gradient} /> AI Summary <div className="badge badge-beta-black ml-2">Beta</div> </div>
                                        <div className="ai-summary-refresh">

                                            <div className="ai-summary-refreshicon"><a href="#" className="ai-summary-mini-close" onClick={e => {
                                                e.preventDefault();
                                                props.handleClose();

                                            }}> <i className="ico icon-remove" style={{ fontSize: '12px' }}></i></a></div>

                                            {
                                                !_.isEmpty(aiSummary) && (<div className="ai-summary-refreshicon" title='Re-Generate Summary'>
                                                    <a href="#" className="ai-summary-mini-refresh"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!reload) reloadAI();
                                                        }}
                                                    >
                                                        {reload ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true" style={{ fontSize: '12px' }}></span> : <i className="ico icon-refresh" style={{ fontSize: '12px' }}></i>}
                                                    </a>
                                                </div>)
                                            }
                                            {
                                                !_.isEmpty(aiSummary?.logDate) && (<div className="ai-summary-refreshdate">
                                                    {moment.unix(aiSummary.logDate.seconds).format('DD MMM YYYY hh:mm A')}

                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </Modal.Title>

                            </Modal.Header>

                            <Modal.Body>
                                {SummaryView()}
                            </Modal.Body>
                        </Modal>

                    </>
                    :
                    <>
                        <div className="finance-accordion-wraper enquiry-sidebar mt-3" >
                            <Accordion defaultActiveKey={activeAcc}>
                                <Card>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={'0'}
                                        onClick={e => {
                                            e.preventDefault();

                                            if (activeAcc === '0')
                                                setActiveAcc(null);
                                            else
                                                setActiveAcc('0');

                                        }}
                                    >
                                        <div className='mb-0'>
                                            <div className=" cardlink ai-summary-title">
                                                <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeAcc === '0' ? '' : 'collapsed'}`}>
                                                    <img src={_images.ai_logo_gradient} />
                                                    <Translate text={'AI Summary'} />
                                                    <div className="badge badge-beta-black ml-2">Beta</div>
                                                    <i className="arrow-collapse"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>

                                    <div className="add-button ai-summary-refresh">
                                        {
                                            !_.isEmpty(aiSummary) && (<div className="ai-summary-refreshicon" title='Re-Generate Summary'>
                                                <a href="#" className="ai-summary-mini-refresh"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!reload) reloadAI();
                                                    }}
                                                >
                                                    {reload ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className="ico icon-refresh"></i>}
                                                </a>
                                            </div>)
                                        }

                                        {
                                            !_.isEmpty(aiSummary?.logDate) && (<div className="ai-summary-refreshdate">
                                                {moment.unix(aiSummary.logDate.seconds).format('DD MMM YYYY hh:mm A')}

                                            </div>)
                                        }

                                    </div>

                                    <Accordion.Collapse eventKey={'0'}>
                                        <Card.Body>
                                            {SummaryView()}

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </>

            }


        </>
    )
}

export default AISummary