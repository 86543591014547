import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { levelOptions } from './viewModel'
import { ContentOverlay, ReactSelect, AntDateRangePicker } from '../../../../components'
import moment from 'moment'
import { combineLatest } from 'rxjs';
import { collectionData } from 'rxfire/firestore';
import images from '../../../../images'
import NumberFormat from 'react-number-format'
import { Fragment } from 'react'
import CommonHelper from '../../../../services/common'
import { apisOptions } from '../../../../admin/billing/pricing/viewModel'


const ApiLogs = (props) => {
    const [loader, setLoader] = useState(true)
    const [settingsloader, setSettingsLoader] = useState(true)
    const [clientOptions, setClientOptions] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [dates, setDates] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [groupOptions, setGroupOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [groupedApiLogs, setGroupedApiLogs] = useState([])
    const [nestedTbl, setNestedTbl] = useState({});
    const [dateType, setDateType] = useState('thisMonth')
    const [apiPrice, setApiPrice] = useState({})
    const [clientapiPrice, setClientApiPrice] = useState({})

    const clients = props.dealersettings && props.dealersettings.clients ? props.dealersettings.clients : [];
    const groups = props.dealersettings && props.dealersettings.groups ? props.dealersettings.groups : [];
    const regions = props.dealersettings && props.dealersettings.regions ? props.dealersettings.regions : [];

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const dateOptions = [
        { active: true, value: 'today', label: 'Today' },
        { active: true, value: 'yesterday', label: 'Yesterday' },
        { active: true, value: 'thisWeek', label: 'This Week' },
        { active: true, value: 'lastWeek', label: 'Last Week' },
        { active: true, value: 'thisMonth', label: 'This Month' },
        { active: true, value: 'lastMonth', label: 'Last Month' },
        { active: true, value: 'thisQuarter', label: 'This Quarter' },
        { active: true, value: 'lastQuarter', label: 'Last Quarter' },
        { active: true, value: 'custom', label: 'Custom' }
    ]

    useEffect(() => {
        if (localStorage.defaultModule === "oem")
            setSelectedLevel({
                'level': props.dealersettings.level,
                'levelID': props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                    props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                        props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                            props.dealersettings.client.id
            })
        else
            setSelectedLevel({
                'level': 'individual',
                'levelID': props.dealersettings.client.id
            })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({
                    label: _client.name,
                    value: _client.documentID,
                    data: _client
                })
            });
            setClientOptions(_clients)
        }
        else {
            setClientOptions([{ name: 'oem' }])
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
                }
            }))

    }, [clientOptions, groups, regions])

    useEffect(() => {
        const fetchOptions = async () => {
            const autograbDoc = await window.firebase.firestore().collection(`integrations`).where("type", "==", 'autograb')
                .limit(1).get()
                .then(async snapshot => {
                    if (snapshot && snapshot.size > 0) {
                        return snapshot.docs[0].data()
                    }
                    else return null;
                });

            const ppsrDoc = await window.firebase.firestore().collection(`integrations`).where("type", "==", 'ppsr')
                .limit(1).get()
                .then(async snapshot => {
                    if (snapshot && snapshot.size > 0) {
                        return snapshot.docs[0].data()
                    }
                    else return null;
                });

            let priceLog = {};
            if (autograbDoc?.price) {
                priceLog = {
                    ...priceLog,
                    ...autograbDoc.price
                }
            }
            if (ppsrDoc?.price) {
                priceLog = {
                    ...priceLog,
                    ...ppsrDoc.price
                }
            }
            let logPromise = [];
            let _clientPrice = [];
            clients.forEach(rec => {
                logPromise.push(window.firebase.firestore().doc(`clients/${rec.id}/modulePricing/${rec.id}`).get());
            })

            const clientPriceSnap = await Promise.all(logPromise);
            clientPriceSnap.forEach(snap => {
                if (snap.exists)
                    _clientPrice.push({
                        ...snap.data().autograb,
                        ...snap.data().ppsr,
                        id: snap.id
                    })
            })
            setClientApiPrice(_clientPrice)
            setApiPrice(priceLog);
            setSettingsLoader(false)
        }
        fetchOptions()
    }, [])

    useEffect(() => {
        if (!loader || settingsloader || _.isEmpty(clientOptions) || _.isEmpty(selectedLevel) || (_.isEmpty(selectedLevel.levelID) && selectedLevel.level !== 'oem'))
            return

        let logPromise = [];
        let clients = clientOptions;
        if (selectedLevel.level === 'region') {
            clients = clientOptions.filter(a => a.data.region === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'group') {
            clients = clientOptions.filter(a => a.data.group === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'individual') {
            clients = clientOptions.filter(a => a.value === selectedLevel.levelID);
        }

        clients.forEach(c => {
            logPromise.push(collectionData(window.firebase.firestore().collection('autoGrabLogs')
                .where('clientID', '==', c.value)
                .where('logDate', '>=', dateRange.startDate)
                .where('logDate', '<=', dateRange.endDate)
                .orderBy('logDate', 'desc')))
        })

        clients.forEach(c => {
            logPromise.push(collectionData(window.firebase.firestore().collection('ppsr')
                .where('clientID', '==', c.value)
                .where('logDate', '>=', dateRange.startDate)
                .where('logDate', '<=', dateRange.endDate)
                .orderBy('logDate', 'desc')))
        })

        var unsubApiLogs = combineLatest(logPromise)
            .subscribe(allDocs => {
                let logs = [];
                allDocs.forEach(docs => {
                    logs = [
                        ...logs,
                        ...docs
                    ]
                });
                let clientLogs = _.chain(logs)
                    .groupBy("clientID")
                    .map((value, key) => ({
                        value: key,
                        name: _.find(clientOptions, { value: key })?.label,
                        logs: _.chain(value)
                            .groupBy("type")
                            .map((value, key) => ({
                                clientID: value[0]?.clientID,
                                type: _.find(apisOptions, { value: key })?.['name'] || key,
                                totalQty: value.length,
                                unitPrice: _.find(clientapiPrice, { id: value[0]?.clientID })?.[key] || apiPrice[key] || 0,
                                totalAmount: (value.length * (_.find(clientapiPrice, { id: value[0]?.clientID })?.[key] || apiPrice[key] || 0)),
                            }))
                            .value()
                    }))
                    .value();
                let allLogs = [];
                clientLogs.forEach(rec => {
                    allLogs.push({
                        ...rec,
                        totalAmount: _.sumBy(rec.logs, 'totalAmount')
                    })
                });
                if (clientLogs.length > 0) {
                    setNestedTbl({
                        ...nestedTbl,
                        [clientLogs[0].value]: true
                    })
                }
                setGroupedApiLogs(allLogs);
                setLoader(false)
            });
        return () => {
            unsubApiLogs && unsubApiLogs.unsubscribe();
        }
    }, [clientOptions, selectedLevel, loader, settingsloader])

    const subtotal = _.sumBy(groupedApiLogs, (rec) => parseFloat(rec.totalAmount));
    const gst = 0.1 * subtotal; // Assuming GST is 10% of the Subtotal
    const total = subtotal + gst;
    const amountDue = total;

    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }

    const handleDateTypeChange = (e, data) => {
        let state = {};
        if (e) {
            state['type'] = e.value;
            state['date'] = e.value;
            const _dateRange = CommonHelper.handleDateRangeFilter({ date: state }, props.dealersettings);
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment(_dateRange.startDate).startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment(_dateRange.endDate).endOf('day')._d)
            })
            setLoader(true);
            setDateType(e.value)
        }
    }

    return (
        <>

            <div className="settings-container">
                <div className="settings-container-sub">

                    <h2>APIs</h2>
                    <div className="divider-line"></div>

                    <div className="settings-api-billing-wrap mt-3">

                        <div className="settings-head form-style">
                            <div className="row">
                                <div className="col-md-9 settings-api-billing-padding">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <ReactSelect
                                                options={dateOptions}
                                                id={"date-type"}
                                                name={"date"}
                                                placeholder={'select date option'}
                                                onChange={handleDateTypeChange}
                                                value={dateType}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                {/* <label for="">Date</label> */}
                                                <AntDateRangePicker
                                                    value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                                    id={"dashboard-date-range"}
                                                    name={'billing-date'}
                                                    format='DD/MM/YYYY'
                                                    onChange={(e) => { handleDateRangeChange(e, 'billing-date') }}
                                                    placeholder='DD/MM/YYYY'
                                                    onCalendarChange={(value) => {
                                                        if (value) {
                                                            const [start, end] = value;
                                                            setDates([start, end]);
                                                            setLoader(true);
                                                        }
                                                        else {
                                                            setDates([])
                                                        }
                                                    }}
                                                    className={'ant-picker-billing'}
                                                //disabledDate={disabledDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                {/* <label for="">Level</label> */}
                                                <ReactSelect
                                                    options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                                    name={"level"}
                                                    placeholder={'select level'}
                                                    onChange={(e, data) => {
                                                        if (e) {
                                                            if (e.value === 'oem')
                                                                setLoader(true)
                                                            setSelectedLevel({
                                                                level: e.value
                                                            });
                                                            setGroupedApiLogs([])
                                                            setNestedTbl({})

                                                        }
                                                        else
                                                            setSelectedLevel(null);
                                                    }}
                                                    value={selectedLevel ? selectedLevel.level : ''}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                {/* <label for="">Dealer</label> */}
                                                {
                                                    (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ?
                                                        (
                                                            // <div className="col-md-3 pr-0">
                                                            <ReactSelect
                                                                options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                                                name={'levelID'}
                                                                placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                                                onChange={(e, data) => {
                                                                    if (e) {
                                                                        setLoader(true)
                                                                        setSelectedLevel({
                                                                            ...selectedLevel,
                                                                            levelID: e.value
                                                                        });
                                                                    }
                                                                    else
                                                                        setSelectedLevel(null);
                                                                }}
                                                                value={selectedLevel ? selectedLevel.levelID : ''}
                                                                classNamePrefix={`basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                            //  </div>
                                                        ) : (<></>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="divider-line"></div>

                            {/* <h3 className="mt-1">McLaren Dubai</h3> */}
                            {/* {selectedLevel && (
                                <h3 className="mt-1">{selectedLevel.levelID.label}</h3>
                            )} */}
                            {
                                loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) :
                                    groupedApiLogs.length > 0 ? (<>
                                        <div className="api-billing-table">
                                            <table className="table table-striped">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th scope="col" className="head-light" width="5%">#</th>
                                                        <th scope="col" className="head-light" width="35%">API</th>
                                                        <th scope="col" className="head-light" width="20%">Usage/Qty</th>
                                                        <th scope="col" className="head-light text-right" width="20%">Unit Cost</th>
                                                        <th scope="col" className="head-light text-right" width="20%">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {groupedApiLogs.map((rec, index) => (<Fragment key={index}>
                                                        {
                                                            groupedApiLogs.length > 1 ? (
                                                                <>
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>
                                                                            <div className="dealership-tbl cursor-pointer position-relative"
                                                                                onClick={() => {
                                                                                    setNestedTbl({
                                                                                        ...nestedTbl,
                                                                                        [rec.value]: nestedTbl[rec.value] && !_.isEmpty(nestedTbl[rec.value].toString()) ? !nestedTbl[rec.value] : true
                                                                                    })
                                                                                }}>
                                                                                {rec.name}
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td className="text-right">
                                                                            <NumberFormat
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol}
                                                                                displayType={'text'}
                                                                                value={rec.totalAmount.toFixed(2)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr className={`tr-hover-fix ${nestedTbl[rec.value] === true ? '' : 'hide'}`}>
                                                                        <td colSpan='5' style={{ padding: ` 0.5rem !important` }}>
                                                                            <div className="api-billing-table">
                                                                                <table className="table table-striped">
                                                                                    {
                                                                                        rec.logs.map((r, i) => {
                                                                                            return <tr>
                                                                                                <td width="5%">{i + 1}</td>
                                                                                                <td width="35%">{r.type}</td>
                                                                                                <td width="20%" className="usage-blue">{r.totalQty}</td>
                                                                                                <td width="20%" className="text-right">
                                                                                                    <NumberFormat
                                                                                                        thousandSeparator={true}
                                                                                                        prefix={currencySymbol}
                                                                                                        displayType={'text'}
                                                                                                        value={r.unitPrice}
                                                                                                    />
                                                                                                </td>
                                                                                                <td width="20%" className="text-right">
                                                                                                    <NumberFormat
                                                                                                        thousandSeparator={true}
                                                                                                        prefix={currencySymbol}
                                                                                                        displayType={'text'}
                                                                                                        value={r.totalAmount.toFixed(2)}
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : (<>
                                                                {
                                                                    rec.logs.map((r, i) => {
                                                                        return <tr>
                                                                            <td width="5%">{i + 1}</td>
                                                                            <td width="35%">{r.type}</td>
                                                                            <td width="20%" className="usage-blue">{r.totalQty}</td>
                                                                            <td width="20%" className="text-right">
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    prefix={currencySymbol}
                                                                                    displayType={'text'}
                                                                                    value={r.unitPrice}
                                                                                />
                                                                            </td>
                                                                            <td width="20%" className="text-right">
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    prefix={currencySymbol}
                                                                                    displayType={'text'}
                                                                                    value={r.totalAmount.toFixed(2)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </>
                                                            )
                                                        }

                                                    </Fragment>))}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-5">
                                            </div>
                                            <div className="col-lg-4 col-sm-5 ml-auto">
                                                <table className="table table-clear">
                                                    <tbody>
                                                        <tr>
                                                            <td className="left">
                                                                <strong>Subtotal</strong>
                                                            </td>
                                                            <td className="text-right">
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    prefix={currencySymbol}
                                                                    displayType={'text'}
                                                                    value={subtotal.toFixed(2)}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left">
                                                                <strong>GST 10%</strong>
                                                            </td>
                                                            <td className="text-right">
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    prefix={currencySymbol}
                                                                    displayType={'text'}
                                                                    value={gst.toFixed(2)}
                                                                />
                                                            </td>

                                                        </tr>

                                                        <tr>
                                                            <td className="left">
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td className="text-right">
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    prefix={currencySymbol}
                                                                    displayType={'text'}
                                                                    value={total.toFixed(2)}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr className="billing-total-blue">
                                                            <td className="left">
                                                                <strong>Total Amount ({currencySymbol.trim()})</strong>
                                                            </td>
                                                            <td className="text-right">
                                                                <strong>
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={amountDue.toFixed(2)}
                                                                    />
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>) : ((
                                        <div className="common-table" style={{ height: `${window.innerHeight - 340}px` }}>
                                            <div className='h-100  w-100'>
                                                <div className='spinner-loader h-100'>
                                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
                                                        <div className="no-data-img"> <img src={images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                        <div className="no-data-txt mt-2">
                                                            {
                                                                selectedLevel?.level !== 'oem' && _.isEmpty(selectedLevel?.levelID) ? (
                                                                    <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>

                                                                ) : (
                                                                    <p>{`No data found`}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApiLogs