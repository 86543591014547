import React, { useState, useContext } from 'react';
import "../../../styles/enquiry-contract.scss";
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import _ from 'lodash'
import moment from 'moment'

import NumberFormat from 'react-number-format';
import CommonHelper from '../../../services/common'

const Contract = (props) => {
    const { contract } = props;
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    let _request = contract?.request || {}

    const displayContact = (_contact, alternate) => {
        if (!_.isEmpty(_contact)) {
            return ((_contact['title'] ? _contact['title'].replace(/\u2028/g, '') + ' ' : '') +
                (_contact['first-name'] ? _contact['first-name'].replace(/\u2028/g, '') + ' ' : '') +
                (_contact['middle-name'] ? _contact['first-name'].replace(/\u2028/g, '') + ' ' : '') +
                (_contact['last-name'] ? _contact['last-name'].replace(/\u2028/g, '') : ''));
        }
        return alternate ? alternate : '';
    }

    const displayAddress = (_contact, alternate) => {

        if (!_.isEmpty(_contact) && !_.isEmpty(_contact['postal-address-1'])) {
            return ((_contact['postal-address-1'] ? _contact['postal-address-1'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['postal-address-2'] ? _contact['postal-address-2'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['postal-suburb'] ? _contact['postal-suburb'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['postal-state'] ? _contact['postal-state'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['postal-country'] ? CommonHelper.getNameByCode(props?.dealersettings?.countries, _contact['postal-country'], _contact['postal-country']) + ', ' : '') +
                (_contact['postal-postcode'] ? _contact['postal-postcode'] + ' ' : ''));
        }
        else if (!_.isEmpty(_contact) && !_.isEmpty(_contact['residential-address-1'])) {
            return ((_contact['residential-address-1'] ? _contact['residential-address-1'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['residential-address-2'] ? _contact['residential-address-2'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['residential-suburb'] ? _contact['residential-suburb'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['residential-state'] ? _contact['residential-state'].replace(/\u2028/g, '') + ', ' : '') +
                (_contact['residential-country'] ? CommonHelper.getNameByCode(props?.dealersettings?.countries, _contact['residential-country'], _contact['residential-country']) + ', ' : '') +
                (_contact['residential-postcode'] ? _contact['residential-postcode'] + ' ' : ''));
        }
        return alternate ? alternate : '';
    }

    const vehDescription = (_contact, alternate) => {

        if (!_.isEmpty(_contact) && !_.isEmpty(_contact['vehicle-description'])) {
            return _contact['vehicle-description'];
        }
        else if (!_.isEmpty(_contact)) {
            return ((_contact['vehicle-make'] ? _contact['vehicle-make'] + ' ' : '') +
                (_contact['vehicle-model-code'] ? _contact['vehicle-model-code'] + ' ' : '') +
                (_contact['vehicle-model'] ? _contact['vehicle-model'] + ' ' : '') +
                (_contact['vehicle-year'] ? _contact['vehicle-year'] + ' ' : '') +
                (_contact['vehicle-colour'] ? _contact['vehicle-colour'] + ' ' : ''));
        }
        return alternate ? alternate : '';
    }

    const CustomDivInput = ({ value, onChange }) => {
        return <div>{value}</div>;
    };

    return (
        <>
            <div className="mid-contractnew-container">

                <div className="mid-contractnew-table-wraper">
                    <div className="mid-contractnew-table-head-inner">Contact Information </div>
                    <div className="mid-contractnew-table-details">
                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Name</span> {displayContact(_request, '--')} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Phone</span>{_request['mobile-telephone'] || _request['work-telephone'] || _request['home-telephone'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>EMAIL</span> {_request['email'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>DOB</span>{_request['date-of-birth'] ? moment(_request['date-of-birth']).format('DD/MM/YYYY') : '--'} </div></td>
                                </tr>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Company</span>{_request['business-name'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>License No</span>{_request['drivers-licence-number'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>License Expiry</span>{_request['drivers-licence-expiry'] ? moment(_request['drivers-licence-expiry']).format('DD/MM/YYYY') : '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>License Type</span>{_request['drivers-licence-type'] || '--'} </div></td>
                                </tr>

                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>ABN</span>{_request['abn'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>ACN</span>{_request['acn'] || '--'} </div></td>
                                    <td width="25%" colspan="2"><div className="mid-contractnew-grid-one"> <span>Address</span>{displayAddress(_request, '--')} </div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mid-contractnew-table-wraper">
                    <div className="mid-contractnew-table-head-inner">Order Details </div>
                    <div className="mid-contractnew-table-details">
                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Customer No.</span> {_request['client-customer-id'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Deal No.</span> {_request['client-purchase-id'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Order No.</span>{_request['purchase-order-number'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Order Date</span> {_request['date-of-sale'] ? moment(_request['date-of-sale']).format('DD/MM/YYYY') : '--'} </div></td>

                                </tr>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Est. Delivery</span>{_request['estimated-delivery-date'] ? moment(_request['estimated-delivery-date']).format('DD/MM/YYYY') : '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Delivered Date</span>{_request['date-delivered'] ? moment(_request['date-delivered']).format('DD/MM/YYYY') : '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Form Issue Date</span>{_request['date-of-form-issue'] ? moment(_request['date-of-form-issue']).format('DD/MM/YYYY') : '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Department</span>{_request['department'] || '--'} </div></td>

                                </tr>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Business Manager</span>{_request['business-manager'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Lead Owner</span>{_request['salesperson'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Lead Status</span>{_request['sales-Status'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Sales Order No</span>{_request['sales-contract-id'] || '--'} </div></td>
                                </tr>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Ctp Policy No.</span>{_request['ctp-policy-number'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Ctp Supplier</span>{_request['ctp-supplier'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Ctp Expiry Date</span>{_request['ctp-expiry-date'] ? moment(_request['ctp-expiry-date']).format('DD/MM/YYYY') : '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Ctp Cost</span>{_request['ctp-cost'] ? CommonHelper.formatCurrency(currencySymbol, _request['ctp-cost']) : '--'} </div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="mid-contractnew-table-wraper">
                    <div className="mid-contractnew-table-head-inner">Vehicle Information </div>
                    <div className="mid-contractnew-table-details">
                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Make</span> {_request['vehicle-make'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Model</span>{_request['vehicle-model'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Model Code</span>{_request['vehicle-model-code'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Year</span> {_request['vehicle-year'] || '--'}</div></td>

                                </tr>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Stock No.</span> {_request['stock-number'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Rego No.</span>{_request['rego'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>VIN No.</span> {_request['vin'] || '--'}</div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Color </span>{_request['vehicle-colour'] || '--'} </div></td>
                                </tr>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Trim </span>{_request['vehicle-trim'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Build Date</span>{_request['build-date'] ? moment(_request['build-date']).format('DD/MM/YYYY') : '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Odometer</span> {_request['odometer'] || '--'}</div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Compliance Date</span>{_request['compliance-date'] ? moment(_request['compliance-date']).format('DD/MM/YYYY') : '--'} </div></td>
                                </tr>
                                <tr>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Engine Code</span> {_request['engine-code'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Glassguide code</span>{_request['glassguide-code'] || '--'} </div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Warranty Insurer Name</span> {_request['warranty-insurer-name'] || '--'}</div></td>
                                    <td width="25%"><div className="mid-contractnew-grid-one"> <span>Condition </span>{_request['vehicle-condition'] || '--'} </div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* {
                    !_.isEmpty(_request['trade-in-vehicles'])
                        ?
                        <>
                            <div className="mid-contractnew-table-wraper">
                                <div className="mid-contractnew-table-head-inner">Trade-In Vehicles </div>

                                {
                                    _request['trade-in-vehicles'].map((rec, index) => {
                                        return <div key={index} className={`mid-contractnew-table-details ${index > 0 ? 'mt-3' : ''}`}>
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Vehicle</span> {rec['description'] || '--'} </div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Year</span> {rec['year'] || '--'}</div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Rego No.</span>{rec['rego'] || '--'} </div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>VIN No.</span> {rec['vin'] || '--'}</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Engine No.</span> {rec['engine-number'] || '--'} </div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Build Date</span>{rec['build-date'] ? moment(rec['build-date']).format('DD/MM/YYYY') : '--'} </div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Color </span>{rec['colour'] || '--'} </div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Trim </span>{rec['trim'] || '--'} </div></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Appraised Odometer</span> {rec['appraised-odometer'] || '--'}</div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Acquisition Odometer</span> {rec['acquisition-odometer'] || '--'}</div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Trade Amount</span>{rec['trade-amount'] ? CommonHelper.formatCurrency(currencySymbol, rec['trade-amount']) : '--'} </div></td>

                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Payout Amount</span>{rec['payout-amount'] ? CommonHelper.formatCurrency(currencySymbol, rec['payout-amount']) : '--'} </div></td>
                                                    </tr>
                                                    <tr>

                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Finance Account No.</span>{rec['credit-account-number'] || '--'} </div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Finance Provider Name</span> {rec['credit-provider-name'] || '--'}</div></td>
                                                        <td width="25%"><div className="mid-contractnew-grid-one"> <span>Finance Due Date </span>{rec['credit-due-date'] ? moment(rec['credit-due-date']).format('DD/MM/YYYY') : '--'} </div></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    })
                                }


                            </div>
                        </>
                        :
                        <></>
                } */}


                <div className="mid-contractnew-qt-table">
                    <table className="mid-contractnew-table-loop page-break">
                        <thead>
                            <tr>
                                <th width="75%" className="mid-contractnew-border-right-none">Vehicle</th>
                                <th width="25%" className="mid-contractnew-border-left-none">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><strong>{vehDescription(_request)}</strong></td>
                                <td className="mid-contractnew-value-right-align">
                                    <strong>{_request['sale-price'] ?
                                        <NumberFormat
                                            value={_request['sale-price']}
                                            displayType={'text'}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                        /> : '--'}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>



                    {
                        !_.isEmpty(_request['factory-options']) || _request['vehicle-paint-cost']
                            ?
                            <>
                                <table className="mid-contractnew-table-loop page-break">
                                    <thead>
                                        <tr>
                                            <th width="75%" className="mid-contractnew-border-right-none">Factory Options</th>
                                            <th width="25%" className="mid-contractnew-border-left-none">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _request['factory-options'].map((rec, index) => {
                                                return <tr key={index} >
                                                    <td>{rec['description'] || '--'}</td>
                                                    <td className="mid-contractnew-value-right-align">
                                                        {rec['charge'] ?
                                                            <NumberFormat
                                                                value={rec['charge']}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                            /> : '--'}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {_request['vehicle-paint-cost'] && (<tr>
                                            <td>{'Vehicle Paint'}</td>
                                            <td className="mid-contractnew-value-right-align">
                                                <NumberFormat
                                                    value={_request['vehicle-paint-cost']}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    prefix={currencySymbol}
                                                />
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>

                            </>
                            :
                            <></>
                    }

                    {
                        !_.isEmpty(_request['genuine-accessories'])
                            ?
                            <>
                                <table className="mid-contractnew-table-loop page-break">
                                    <thead>
                                        <tr>
                                            <th width="75%" className="mid-contractnew-border-right-none">Genuine Accessories</th>
                                            <th width="25%" className="mid-contractnew-border-left-none">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _request['genuine-accessories'].map((rec, index) => {
                                                return <tr key={index} >
                                                    <td>{rec['description'] || '--'}</td>
                                                    <td className="mid-contractnew-value-right-align">
                                                        {rec['charge'] ?
                                                            <NumberFormat
                                                                value={rec['charge']}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                            /> : '--'}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                            </>
                            :
                            <></>
                    }

                    {
                        !_.isEmpty(_request['non-genuine-accessories'])
                            ?
                            <>
                                <table className="mid-contractnew-table-loop page-break">
                                    <thead>
                                        <tr>
                                            <th width="75%" className="mid-contractnew-border-right-none">Non-Genuine Accessories</th>
                                            <th width="25%" className="mid-contractnew-border-left-none">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _request['non-genuine-accessories'].map((rec, index) => {
                                                return <tr key={index} >
                                                    <td>{rec['description'] || '--'}</td>
                                                    <td className="mid-contractnew-value-right-align">
                                                        {rec['charge'] ?
                                                            <NumberFormat
                                                                value={rec['charge']}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                            /> : '--'}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                            </>
                            :
                            <></>
                    }

                    {
                        !_.isEmpty(_request['aftermarket-accessories'])
                            ?
                            <>
                                <table className="mid-contractnew-table-loop page-break">
                                    <thead>
                                        <tr>
                                            <th width="75%" className="mid-contractnew-border-right-none">Aftermarket Accessories</th>
                                            <th width="25%" className="mid-contractnew-border-left-none">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _request['aftermarket-accessories'].map((rec, index) => {
                                                return <tr key={index} >
                                                    <td>{rec['description'] || '--'}</td>
                                                    <td className="mid-contractnew-value-right-align">
                                                        {rec['charge'] ?
                                                            <NumberFormat
                                                                value={rec['charge']}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                            /> : '--'}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                            </>
                            :
                            <></>
                    }

                    {
                        !_.isEmpty(_request['trade-in-vehicles'])
                            ?
                            <>
                                <table className="mid-contractnew-table-loop page-break">
                                    <thead>
                                        <tr>
                                            <th width="75%" className="mid-contractnew-border-right-none">Trade-In Vehicles</th>
                                            <th width="25%" className="mid-contractnew-border-left-none">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _request['trade-in-vehicles'].map((rec, index) => {
                                                return <tr key={index} >
                                                    <td>
                                                        <div class="mid-contractnew-td-head"> {rec['description'] || '--'} </div>
                                                        <div className="dashboard-testdrive-activity-column">
                                                            Color: <div title={CommonHelper.showLocale(props, 'exteriorColor')}>{rec['colour'] || '--'}</div>
                                                            {rec['year'] && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{rec['year']}</div></>)}
                                                            {rec['rego'] && (<><span className="veh-details-seperator">|</span><strong>REG#:</strong>&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{rec['rego']}</div></>)}
                                                            {rec['vin'] && (<><span className="veh-details-seperator">|</span><strong>VIN#:</strong>&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{rec['vin']}</div></>)}
                                                            {rec['engine-number'] && (<><span className="veh-details-seperator">|</span><strong>Engine No.:</strong>&nbsp;<div >{rec['engine-number']}</div></>)}
                                                            {rec['build-date'] && (<><span className="veh-details-seperator">|</span><strong>Build Date:</strong>&nbsp;<div >{moment(rec['build-date']).format('DD/MM/YYYY')}</div></>)}
                                                            {rec['trim'] && (<><span className="veh-details-seperator">|</span><strong>Trim:</strong>&nbsp;<div >{rec['trim']}</div></>)}
                                                            {rec['appraised-odometer'] && (<><span className="veh-details-seperator">|</span><strong>Appraised Odometer:</strong>&nbsp;<div >{rec['appraised-odometer']}</div></>)}
                                                            {rec['acquisition-odometer'] && (<><span className="veh-details-seperator">|</span><strong>Acquisition Odometer:</strong>&nbsp;<div >{rec['acquisition-odometer']}</div></>)}
                                                            {rec['credit-account-number'] && (<><span className="veh-details-seperator">|</span><strong>Finance Account No.:</strong>&nbsp;<div >{rec['credit-account-number']}</div></>)}
                                                            {rec['credit-provider-name'] && (<><span className="veh-details-seperator">|</span><strong>Finance Provider:</strong>&nbsp;<div >{rec['credit-provider-name']}</div></>)}
                                                            {rec['credit-due-date'] && (<><span className="veh-details-seperator">|</span><strong>Finance Due Date:</strong>&nbsp;<div >{moment(rec['credit-due-date']).format('DD/MM/YYYY')}</div></>)}
                                                            
                                                            {rec['trade-amount'] && (<><span className="veh-details-seperator">|</span><strong><Translate text={'Trade Amount'} />:</strong>&nbsp;
                                                                <NumberFormat value={rec['trade-amount']} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)}
                                                        </div>
                                                    </td>

                                                    <td className="mid-contractnew-value-right-align">
                                                        {rec['payout-amount'] ?
                                                            <NumberFormat
                                                                value={rec['payout-amount']}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                            /> : '--'}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                            </>
                            :
                            <></>
                    }

                    {
                        _request['dealer-charge']
                            ?
                            <>
                                <table className="mid-contractnew-table-loop page-break">
                                    <thead>
                                        <tr>
                                            <th width="75%" className="mid-contractnew-border-right-none">Dealer Delivery</th>
                                            <th width="25%" className="mid-contractnew-border-left-none">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{'Delivery'}</td>
                                            <td className="mid-contractnew-value-right-align">
                                                {_request['dealer-charge'] ?
                                                    <NumberFormat
                                                        value={_request['dealer-charge']}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                    /> : '--'}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </>
                            :
                            <></>
                    }

                    <table className="mid-contractnew-table-loop  page-break mid-contractnew-margin-top-fix">
                        <tbody>

                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Registration'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">
                                    <NumberFormat
                                        value={_request['registration-cost'] || 0}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /></td>
                            </tr>
                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Stamp-Duty'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">
                                    <NumberFormat
                                        value={_request['stamp-duty'] || 0}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /></td>
                            </tr>


                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Luxury Car Tax (LCT)'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">
                                    <NumberFormat
                                        value={_request['luxury-car-tax'] || 0}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /></td>
                            </tr>
                            {_request['fuel-cost'] > 0 && (<tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Fuel Cost'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">{_request['fuel-cost'] ?
                                    <NumberFormat
                                        value={_request['fuel-cost']}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /> : '--'}</td>
                            </tr>)}
                            {_request['miscellaneous-charges'] > 0 && (<tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Miscellaneous Charges'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">{_request['miscellaneous-charges'] ?
                                    <NumberFormat
                                        value={_request['miscellaneous-charges']}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /> : '--'}</td>
                            </tr>)}




                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Deposit'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">
                                    <NumberFormat
                                        value={_request['deposit'] || 0}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /></td>
                            </tr>
                            {_request['refund'] > 0 && (<tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Customer Refund'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">
                                    <NumberFormat
                                        value={_request['refund']}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /></td>
                            </tr>)}
                            {_request['dealer-discount'] > 0 && (<tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'Dealer Discount'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">{_request['dealer-discount'] ?
                                    <NumberFormat
                                        value={_request['dealer-discount']}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={`${currencySymbol}-`}
                                    /> : '--'}</td>
                            </tr>)}
                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align">{'GST payable'}</td>
                                <td width="25%" className="mid-contractnew-value-right-align">
                                    <NumberFormat
                                        value={_request['gst-amount'] || 0}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                    /></td>
                            </tr>
                        </tbody>
                    </table>

                    {/* <table className="mid-contractnew-table-loop  page-break mid-contractnew-margin-top-fix">
                        <tbody>
                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align">Vehicle Sub Total excluding GST</td>
                                <td width="25%" className="mid-contractnew-value-right-align"><span>$ xxx,xxx</span></td>
                            </tr>
                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align">GST payable</td>
                                <td width="25%" className="mid-contractnew-value-right-align"><span>$ xxx,xxx</span></td>
                            </tr>
                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align"><strong>Vehicle Sub Total inclusive of GST </strong></td>
                                <td width="25%" className="mid-contractnew-value-right-align"><strong><span>$ xxx,xxx</span></strong></td>
                            </tr>
                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align"><strong>Vehicle Total inclusive of GST </strong></td>
                                <td width="25%" className="mid-contractnew-value-right-align"><strong><span>$ xxx,xxx</span></strong></td>
                            </tr>
                        </tbody>
                    </table> */}

                    <table className="mid-contractnew-table-loop mid-contractnew-margin-top-fix page-break">
                        <tbody>
                            <tr>
                                <td width="75%" className="mid-contractnew-value-right-align mid-contractnew-costtxt-preview mid-contractnew-border-left-none mid-contractnew-border-bottom-none">
                                    <strong>Balance Payable </strong></td>
                                <td width="25%" className="mid-contractnew-value-right-align mid-contractnew-total-preview"><strong>
                                    {_request['balance-payable'] ?
                                        <NumberFormat
                                            value={_request['balance-payable']}
                                            displayType={'text'}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                        /> : '--'}
                                </strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div >
        </>
    )
}

export default Contract