import React, { useState, useEffect, useReducer, useMemo, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

import { StageProvider, StageConsumer } from '../pipeline/provider'
import { ContentOverlay, SidePanel, PopUpModal, TableView } from '../../components'
import QuickView from "../pipeline/quickview";
import InBoundLeadConvert from '../pipeline/inBoundLeadConvert'
import Translate from '../../constants/translate';
import { stockStatus, inboundLeadStatus } from '../../services/enum'
import FilterPanel from './filterPanel'
import EnquiryListReducer from '../fleet/fleetReducer'
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import ReAuthenticate from '../common/reAuthenticate';
import OEMLeadAllocation from '../pipeline/oemLeadAllocation';
import { allUnallocatedFields, allAllocatedFields } from './viewModel'
import { objEnquiryListVM } from '../pipeline/viewModel'


const FleetListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, { unallocated: [], allocated: [] })
    const [checkDataLoad, setDataload] = useState({ "unallocated": true, "allocated": false })
    const [settingsLoader, setSettingsLoader] = useState(false);
    const [unallocatedFields, setUnallocatedFields] = useState(props.dealersettings.unallocatedFields ? props.dealersettings.unallocatedFields : [])
    const [allocatedFields, setAllocatedFields] = useState(props.dealersettings.allocatedFields ? props.dealersettings.allocatedFields : [])
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])

    const [activeTab, setActiveTab] = useState('unallocated')

    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.inBoundSearchText ? localStorage.inBoundSearchText : '')
    const [enquiryLoader, setEnquiryLoader] = useState({ "unallocated": true, "allocated": false })

    const [defaultPipeline, setDefaultPipeline] = useState('LeadsBucket')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])
    const [pipelineView, setPipelineView] = useState('INBOUND')

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [modalTransferShow, setModalTransferShow] = useState(false)
    const [modalMoveShow, setModalMoveShow] = useState(false)
    const [pipelineCount, setPipelineCount] = useState(0)
    const [inboundCount, setInboundCount] = useState({ unallocated: 0, allocated: 0 })

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [enquiryTypes, setEnquiryTypes] = useState([])
    const [enquiryOptions, setEnquiryOptions] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])
    const [financeStatuses, setFinanceStatuses] = useState([])
    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [enquiryAllStatus, setAllStatus] = useState([]);

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);
    const [inboundModal, setInboundModal] = useState({ show: false, docID: null })
    const [showOEMLeadConvert, setshowOEMLeadConvert] = useState({ show: false, enquiryID: null, fields: null, enquiries: null })

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);

    const financeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.finance) &&
        _moduleSettings.finance.enabled) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const respMins = 15

    useEffect(() => {
        if (props.dealersettings?.allocatedFields) setAllocatedFields(props.dealersettings.allocatedFields)
        if (props.dealersettings?.unallocatedFields) setUnallocatedFields(props.dealersettings.unallocatedFields)
    }, [props.dealersettings.unallocatedFields])

    useEffect(() => {
        const interval = setInterval((enq) => {
            if (enq.allocated && enq.allocated.length > 0) {
                let _enquiries = enq.allocated.map(rec => {
                    return rec.statusValue === 'pending' ? {
                        ...rec,
                        unActioned: diffinMins(rec)
                    } : { ...rec }
                });
                dispatch({
                    type: "UPDATE_ALL_LIST",
                    data: _enquiries,
                    activetab: 'allocated'
                });
            }
        }, 90000, enquiries);
        //60000 1min
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [enquiries])

    useEffect(() => {
        if (isFilterApplied || props.pipeline.length === 0)
            return
        const { dealersettings } = props;
        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
        const dpl = 'LeadsBucket'
        var querySet = `clients/${dealersettings.client.id}`
        let accessSalesType = [];
        // if (!viewOtherEnquiry) {
        //     querySet = `users/${localStorage.uid}/userClients/${props.dealersettings.client.id}`
        // }
        if (dealersettings && localStorage.defaultModule === 'oem') {
            if (dealersettings.level === 'oem' && dealersettings.oemID)
                querySet = `clientSettings/${dealersettings.oemID}/counts/${dealersettings.oemID}`
            else if (dealersettings.level === 'group' && dealersettings.groupID)
                querySet = `clientSettings/${dealersettings.oemID}/groups/${dealersettings.groupID}/counts/${dealersettings.groupID}`
            else if (dealersettings.level === 'region' && dealersettings.regionID)
                querySet = `clientSettings/${dealersettings.oemID}/regions/${dealersettings.regionID}/counts/${dealersettings.regionID}`
        }

        window.unSubPipelineLstCount = window.firebase.firestore().doc(querySet)
            .onSnapshot(docSnapshot => {
                var stageCount = {};
                let _selectedPipeline = props.pipeline.find(item => item.value === 'LeadsBucket');
                if (docSnapshot.exists && docSnapshot.data() && docSnapshot.data().inbound && docSnapshot.data().inbound[dpl] && docSnapshot.data().inbound[dpl].stages) {
                    _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                        stageCount = {
                            ...stageCount,
                            [stage.value]: {
                                ...docSnapshot.data().inbound[dpl].stages[stage.value],
                                ['total']: sumObj(docSnapshot.data().inbound[dpl].stages[stage.value], accessSalesType, stage.value)
                            }
                        }
                    })
                }
                if (stageCount) {
                    var pending = 0;
                    var converted = 0;
                    // 'Contacted', 
                    for (let [key, value] of Object.entries(stageCount)) {
                        if ((_selectedPipeline ? _selectedPipeline.stages.filter(a => a.value !== 'Archived').map(r => { return r.value }) : ['New', 'Contacted', 'InboundLead', 'Converted']).indexOf(key) >= 0) {
                            if (key === 'Converted') {
                                converted += parseFloat((value && value.total) ? value.total : 0);
                            }
                            else {
                                pending += parseFloat((value && value.total) ? value.total : 0);
                            }
                        }
                    }
                    setInboundCount({
                        allocated: converted,
                        unallocated: pending
                    });
                }
                setPipelineCount(stageCount)

            })
    }, [props.pipeline, isFilterApplied])

    const sumObj = (obj, arrSaleType, stage) => {
        var sum = 0;
        if (stage === 'InboundLead' && !_.isEmpty(arrSaleType)) {
            arrSaleType = [...arrSaleType, 'na'];
        }
        for (var el in obj) {
            if (_.isNumber(obj[el]) && (_.isEmpty(arrSaleType) || arrSaleType.some(a => a === el))) {
                sum += parseFloat(obj[el] ? obj[el] : 0);
            }
        }
        return sum;
    }

    // useEffect(() => {
    //     if(stateInterval && !_.isEmpty(stateInterval.toString())){
    //         clearInterval(stateInterval)
    //     }
    //     const interval = setInterval(() => {
    //         var ele = document.getElementsByClassName('blinking');
    //         if (ele.length > 0) {
    //             Array.from(ele).forEach((rec) => {
    //                 rec.style.visibility = (rec.style.visibility == 'hidden' ? '' : 'hidden');
    //             });
    //         }
    //     }, 1000);
    //     setStateInterval(interval)
    //     //60000 1min
    //     return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // }, [])

    useEffect(() => {
        let headerFields = (activeTab === 'unallocated') ? unallocatedFields : allocatedFields;
        let allHeaderFields = (activeTab === 'unallocated') ? allUnallocatedFields : allAllocatedFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [unallocatedFields, activeTab])

    useEffect(() => {
        if (_.isEmpty(enquiryOptions))
            return
        enquiryOptions.forEach(rec => {
            if (!allAllocatedFields.some(e => e.value === rec.value))
                allAllocatedFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        allAllocatedFields.forEach(rec => {
            if (!allAllocatedFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setAllocatedFields(_.differenceBy(allocatedFields, deleteids))
    }, [enquiryOptions])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });


            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                _origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                _enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _enquiryOptions = [];
            setttings.enquiryOptionsDF && setttings.enquiryOptionsDF.forEach(doc => {
                _enquiryOptions.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                _financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                _campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _pipelines = [];
            setttings.pipelines && setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _countries = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                _countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _states = [];
            props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _lostReasons = [];
            const _lostSubReasons = [];
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });

                !_.isEmpty(doc.subList) && doc.subList.forEach(docsub => {
                    _lostSubReasons.push({
                        value: docsub.value,
                        label: docsub.name
                    });
                });
            });

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            setAllStatus(_enquiryAllStatus);
            setTitles(_titles);
            setEnquiryTypes(_enquiryTypes);
            setEnquiryOptions(_enquiryOptions.filter(e => e.active === true));
            setCampaigns(_campaigns);
            setOrigins(_origins);
            setFinanceStatuses(_financeStatuses)
            setLanguages(_languages);
            setNationalities(_nationalities)
            setLostReasons(_lostReasons);
            setLostSubReasons(_lostSubReasons);
            setCountries(_countries);
            setStates(_states);


            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })

            refreshStageCount();
        }
    }, [])


    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'enquiry')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubinboundAllocList && window.unsubinboundAllocList();
            window.unsubenquiryAllocList && window.unsubenquiryAllocList();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
    }, [])

    useEffect(() => {
        if (localStorage.inBoundFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.inBoundFilter])

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        if (isFilterApplied || isValidFilter())
            return;

        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }


        let searchKeywords = searchText;

        let refInboundData = firestoreDB(props.dealersettings).firestore().collection('inboundLeads')
            //.where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', 0)
            .where('isDeleted', '==', false)

        refInboundData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refInboundData, dealersettings, 'INBOUND', true);

        if (searchKeywords) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
            if (formattedSearchText.trim())
                refInboundData = refInboundData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
        }

        window.unsubinboundAllocList = refInboundData
            .onSnapshot(onInboundCollectionUpdate);

    }, [checkDataLoad])

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (props.pipeline.length === 0) {
            return
        }

        if (isFilterApplied && pageNum === 0) {
            setEnquiryLoader({
                ...enquiryLoader,
                [activeTab]: true
            })
        }


        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if ((isFilterApplied || localStorage.inBoundFilter) && isValidFilter()) {
            let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            //_filter.pipeline = activeTab === 'unallocated' ? 'LeadsBucket' : null;
            _filter.inbound = true;
            _filter.inboundType = activeTab

            if (!viewOtherEnquiry)
                _filter.owner = dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                delete _filter['date'];
            }

            // CONTACT AGE FILTER
            if (!_.isEmpty(_filter.contactAge)) {
                _filter.dateType = 'dob';
                _filter.startDate = moment().add(parseInt(`-${_filter.contactAge[1]}`), 'years').format('YYYY-MM-DD');
                _filter.endDate = moment().add(parseInt(`-${_filter.contactAge[0]}`), 'years').format('YYYY-MM-DD');
                delete _filter['contactAge'];
            }

            /* MAKE & MODEL FILTER */
            if (!_.isEmpty(_filter.make))
                _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

            if (_filter.rottenDays &&
                !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.settings) &&
                !_.isEmpty(dealersettings.client.settings.pipelines)) {
                _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
            }

            _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, 'INBOUND', null, true);
            _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()

            // Region/Subregion/Group/Client filter
            if ((!_.isEmpty(_filter?.region) || !_.isEmpty(_filter?.subregion) || !_.isEmpty(_filter?.group)) && _.isEmpty(_filter?.clientID)) {
                _filter.clientID = dealersettings?.clients ? dealersettings.clients.filter(client =>
                    (_filter?.region?.length > 0 ? _filter.region.includes(client.region) : true) &&
                    (_filter?.subregion?.length > 0 ? _filter.subregion.includes(client.subregion) : true) &&
                    (_filter?.group?.length > 0 ? _filter.group.includes(client.group) : true)
                ).map(r => { return r.id }).join(',') : ''
                //console.log('_filter',dealersettings.level, _filter)
            }

            let _searchObject = {
                "type": "searchEnquiries",
                "filters": JSON.stringify(_filter),
                "sortOrder": activeTab === 'unallocated' ? "addedDate asc" : "stageDate desc",
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, 'INBOUND', null, true);

            //console.log('filter-searchEnquiries', _searchObject, pageNum, _filter);
            const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
            searchEnquiries(_searchObject).then((response) => {
                //console.log('generic-searchData', _rec, response);
                if (response.data.success && localStorage.inBoundFilter && isValidFilter()) {
                    onCollectionEnquiry(response.data);
                    setInboundCount({
                        [activeTab]: response.data.total
                    })
                    setPipelineCount({
                        advance: {
                            total: response.data.total
                        }
                    })
                }
                else {
                    setEnquiryLoader({
                        ...enquiryLoader,
                        [activeTab]: false
                    })
                    setDataload({
                        ...checkDataLoad,
                        [activeTab]: false
                    })
                    setPaging(false)
                }
            });

            window.unsubenquiryAllocList && window.unsubenquiryAllocList();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
        else {

            let searchKeywords = searchText;
            let searchOwners = [];

            if (localStorage.inBoundFilter) {
                let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                setSearchText(searchKeywords);
                localStorage.inBoundSearchText = searchKeywords;
            }

            let refEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('isDeleted', '==', false)

            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                if (formattedSearchText.trim())
                    refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }


            refEnquiryData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refEnquiryData, dealersettings, 'INBOUND', true);

            if (!viewOtherEnquiry)
                refEnquiryData = refEnquiryData.where('owner', 'in', [localStorage.uid])
            else if (!_.isEmpty(searchOwners))
                refEnquiryData = refEnquiryData.where('owner', 'in', searchOwners)

            if (activeTab === 'allocated') {
                refEnquiryData = refEnquiryData
                    .where('oemStatus', '==', 'converted')
                    .orderBy('stageDate', 'desc');
            }
            else {
                refEnquiryData = refEnquiryData
                    .where('oemStatus', '==', 'pending')
                    .orderBy('stageDate', 'desc');

            }

            let lastRecord = hasMore.filter(e => e.type === activeTab);
            if (lastRecord.length > 0) {
                refEnquiryData = refEnquiryData
                    .startAfter(lastRecord[0].lastDoc)
                    .limit(pageLimit)
            }
            else {
                refEnquiryData = refEnquiryData
                    .limit(pageLimit)
            }

            window.unsubenquiryAllocList = refEnquiryData
                .onSnapshot(onCollectionUpdate);
        }


    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })

        let _enquiries = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]

                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach((doc) => {
                if (doc.data().stage !== 'Archived') {
                    _enquiries.push(convertEnquiryVM({
                        ...doc.data(),
                        documentID: doc.id,
                        stageDate: doc.data().stageDate ? doc.data().stageDate : doc.data().addedDate,
                    }));
                }
            });
        }


        // if (activeTab === 'unallocated')
        //     _enquiries = _enquiries.filter(m => m.stage !== 'Archived')

        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: _enquiries,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "APPEND_LIST",//"SUCCESS_LIST",//"APPEND_LIST",
                data: actionType ? snapshotDoc : _enquiries,
                activetab: activeTab
            });
        }


        setEnquiryLoader({
            ...enquiryLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
    }

    const onInboundCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id,
                    stage: 'InboundLead',
                    pipeline: 'LeadsBucket',
                    clientID: change.doc.data().clientID ? change.doc.data().clientID : null
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    if (activeTab === 'unallocated' && !_.isEmpty(snapshotDoc.clientID))
                        actionType = "REMOVE_LIST"
                    else
                        actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        let _enquiries = [];
        if (!actionType) {

            querySnapshot.forEach(function (doc) {
                var logVM = convertEnquiryVM({
                    ...doc.data(),
                    documentID: doc.id,
                    stage: 'InboundLead',
                    pipeline: 'LeadsBucket',
                    stageDate: doc.data().stageDate ? doc.data().stageDate : doc.data().addedDate,
                    clientID: doc.data().clientID ? doc.data().clientID : null
                });
                _enquiries.push(logVM)
            });
        }
        if (activeTab === 'unallocated')
            _enquiries = _enquiries.filter(m => _.isEmpty(m.clientID))
        else
            _enquiries = _enquiries.filter(m => !_.isEmpty(m.clientID))

        dispatch({
            type: actionType ? actionType : "APPEND_LIST",
            data: actionType ? snapshotDoc : _enquiries,
            activetab: activeTab
        });
    }

    const onCollectionEnquiry = (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
            {
                type: activeTab,
                lastDoc: querySnapshot.data[querySnapshot.data.length - 1]

            }])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
        }

        querySnapshot.data.forEach(function (doc) {
            //console.log('doc', doc)
            var logVM = convertEnquiryVM(doc);
            logVM.stageDate = logVM.stageDate ? logVM.stageDate : logVM.addedDate;
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: _enquiries,
            activetab: activeTab
        });
        setEnquiryLoader({
            ...enquiryLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
        setDefaultPipeline('LeadsBucket')
    }

    const convertEnquiryVM = (doc) => {

        doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;
        doc.convertedDate = doc.convertedDate && doc.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDate._seconds)._d) : doc.convertedDate;
        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
        doc.wonDate = doc.wonDate && doc.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.wonDate._seconds)._d) : doc.wonDate;
        doc.lostDate = doc.lostDate && doc.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lostDate._seconds)._d) : doc.lostDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;

        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
        doc.confirmedDate = doc.confirmedDate && doc.confirmedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.confirmedDate._seconds)._d) : doc.confirmedDate;
        doc.verifiedDate = doc.verifiedDate && doc.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.verifiedDate._seconds)._d) : doc.verifiedDate;
        doc.receivedDate = doc.receivedDate && doc.receivedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.receivedDate._seconds)._d) : doc.receivedDate;
        if (!_.isEmpty(doc.lastNote)) {
            doc.lastNote.modifiedDate = doc.lastNote.modifiedDate && doc.lastNote.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lastNote.modifiedDate._seconds)._d) : doc.lastNote.modifiedDate;
        }
        doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
            return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]
        if (!doc.receivedDate) {
            doc.receivedDate = doc.addedDate;
        }


        const objenquiryData = Object.assign({}, doc);
        const listVM = Object.assign({}, objEnquiryListVM);
        for (let [key, value] of Object.entries(objenquiryData)) {
            //if (listVM.hasOwnProperty(key))
            listVM[key] = value;
        }
        listVM.objDoc = Object.assign({}, objenquiryData);
        listVM.displayID = listVM.displayID ? listVM.displayID.toUpperCase() : '--';
        listVM.originValue = doc.origin;
        listVM.enquiryTypeValue = doc.enquiryType;
        listVM.campaignValue = doc.campaign;
        listVM.statusValue = doc.stage === 'InboundLead' ? 'pending' : doc.status;
        listVM.labelValue = doc.label;
        listVM.pipelineValue = doc.pipeline;
        listVM.stageValue = doc.stage;
        listVM.ownerValue = doc.owner;
        listVM.statusName = doc.stage === 'InboundLead' ? 'pending' : CommonHelper.getNameByValue(enquiryAllStatus, doc.status);
        listVM.financeStatusName = doc.financeStatus;
        listVM.labelName = doc.label;
        listVM.receivedDateValue = doc.receivedDate;

        if (listVM.statusValue === 'pending') {
            listVM.unActioned = diffinMins(listVM);
            listVM.strunActioned = diffinMins(listVM, 'string');
        }
        else {
            listVM.unActioned = null;
            listVM.actionedOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        }

        if (doc.stage === 'InboundLead') {
            listVM.sortDate = doc.modifiedDate;
        }
        else {
            listVM.sortDate = doc.addedDate;
        }

        //if (doc.responseTime === 0 || _.isEmpty(doc.responseTime)) {
        doc.responseTime = getresponseTime(listVM);
        //}

        if (!_.isEmpty(doc.systemType)) {
            listVM.inboundType = (doc.systemType === 'oem' || doc.systemType === 'region' || doc.systemType === 'group') ? ['LeadsBucket'] : [doc.systemType]
            doc.tags = [{ type: doc.systemType }];
        }

        let dealersettings = props.dealersettings;

        if (doc.clientID && (doc.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
            listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.clientID);
        else
            listVM.dealerName = '';

        listVM.pipeline = CommonHelper.getNameByValue(props.dealersettings.allPipelines, doc.pipeline, '');
        if (_.find(props.dealersettings.allPipelines, { value: doc.pipeline }) && doc.stage !== 'InboundLead') {
            listVM.stage = CommonHelper.getNameByValue(_.find(props.dealersettings.allPipelines, { value: doc.pipeline }).stages, doc.stage, '');
        }
        listVM.appointmentDateOn = doc.appointmentDate ? moment.unix(doc.appointmentDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.bdcStatusName = doc.bdcStatus === 'show' ? 'Show' : doc.bdcStatus === 'noshow' ? 'No Show' : doc.bdcStatus === 'allocated' ? 'Allocated' : '';
        listVM.strResponseTime = doc.responseTime > 0 ? CommonHelper.timeformatfromSeconds(doc.responseTime, props.dealersettings.client) : '';
        listVM.strAllocatedTime = doc.allocatedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.allocatedTime, props.dealersettings.client) : '';
        listVM.strContactedTime = doc.contactedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.contactedTime, props.dealersettings.client) : ''; listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.wonOn = doc.wonDate && doc.status && (doc.status.toLowerCase() === 'won' || doc.status.toLowerCase() === 'delivered') ? moment.unix(doc.wonDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.lostOn = doc.lostDate && doc.status && (doc.status.toLowerCase() === 'lost' || doc.status.toLowerCase() === 'prospectLost') ? moment.unix(doc.lostDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.deliveredOn = doc.deliveredDate && doc.status && doc.status.toLowerCase() === 'delivered' ? moment.unix(doc.deliveredDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.deliveryOn = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.warrantyExpiry = doc.warrantyExpiry ? moment(doc.warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.warrantyStartDate = doc.warrantyStartDate ? moment(doc.warrantyStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.deliveryDate = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.confirmedBy = doc.confirmedBy ? CommonHelper.getUserNamebyId(allUsers, doc.confirmedBy) : '';
        listVM.confirmedDate = doc.confirmedDate ? moment.unix(doc.confirmedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.verifiedBy = doc.verifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.verifiedBy) : '';
        listVM.verifiedDate = doc.verifiedDate ? moment.unix(doc.verifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.receivedDate = doc.receivedDate ? moment.unix(doc.receivedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwner = doc.convertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.convertedBy) : '';

        listVM.convertedOnOEM = doc.convertedDateOEM ? moment.unix(doc.convertedDateOEM.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnRegion = doc.convertedDateRegion ? moment.unix(doc.convertedDateRegion.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnGroup = doc.convertedDateGroup ? moment.unix(doc.convertedDateGroup.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwnerOEM = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.convertedOwnerRegion = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.convertedOwnerGroup = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.nextActivityDate = doc?.nextActivity?.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc?.nextActivity?.startDate?._seconds)._d) : doc?.nextActivity?.startDate;


        let _setttings = doc.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_setttings) {
            listVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.campaign, '');
            listVM.origin = CommonHelper.getNameByValue(_setttings.origins, doc.origin, '');

            listVM.enquiryType = CommonHelper.getNameByValue(_setttings.enquiryTypes, doc.enquiryType, '');
            listVM.lostReason = CommonHelper.getNameByValue(_setttings.lostReasons, doc.lostReason, '');
            listVM.labelName = CommonHelper.getNameByValue(_setttings.enquiryLabels, doc.label);
            listVM.label = CommonHelper.bindEnquiryLabel(_setttings.enquiryLabels, doc.label);
        }

        //listVM.lostReason = CommonHelper.getLabelByValue(lostReasons, doc.lostReason, '');
        listVM.lostSubReason = CommonHelper.getLabelByValue(lostSubReasons, doc.lostSubReason, '');
        //listVM.origin = CommonHelper.getLabelByValue(origins, doc.origin, '');
        //listVM.enquiryType = CommonHelper.getLabelByValue(enquiryTypes, doc.enquiryType, '');
        //listVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.campaign, '');


        if (financeEnabled)
            listVM.financeStatus = doc.financeStatus ? (<div className={`badge badge-pill badge-${doc.financeStatus.toLowerCase()} ml-1`}>{doc.financeStatus.toUpperCase()}</div>) : '';
        else
            listVM.financeStatus = CommonHelper.getLabelByValue(financeStatuses, doc.financeStatus, '');

        let _objCurrentStatus = doc.status && enquiryAllStatus.filter(e => e.value === doc.status)[0];
        if (doc.stage === 'InboundLead')
            listVM.status = <span className={`status-pending`}>{'Pending'}</span>;
        else
            listVM.status = doc.status ? (
                _objCurrentStatus
                    ?
                    <>
                        <span
                            className={`status-${doc.status === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <></>
            ) : '';

        listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.owner = CommonHelper.getUserNamebyId(allUsers, doc.owner);
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.addedBy = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.modifiedBy = doc.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.modifiedBy) : '';
        listVM.overdueActivity = 0;
        listVM.todayActivity = 0;
        listVM.upcomingActivity = 0;
        listVM.doneActivity = 0;
        listVM.totalActivity = 0;
        listVM.depositAmount = doc.depositAmount ? currencySymbol + doc.depositAmount : '';
        listVM.grossAmount = doc.grossAmount ? currencySymbol + doc.grossAmount : '';
        listVM.csvQuoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? currencySymbol + doc.quotation.amount : '';

        listVM.quoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? <NumberFormat
            decimalScale={2}
            thousandSeparator={true}
            prefix={currencySymbol}
            displayType={'text'}
            value={doc.quotation.amount}
        /> : '';

        listVM.enquiryAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month').replace('an hour', '1 Hour')) : '');
        props.pipeline.filter(e => e.default)[0].stages.forEach(col => {
            if (listVM.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                    listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
                }
            }
        })

        if (!_.isEmpty(doc.requirement)) {
            listVM.vehicleModel = (!_.isEmpty(doc.requirement.stock) && doc.requirement.stock.make) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : (doc.requirement.make ? doc.requirement.make + ' ' + doc.requirement.model : '')
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;

            listVM.vehicleSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.requirement.saleType, '');
            listVM.vehicleType = listVM.vehicleModel ? <>
                <>{listVM.vehicleSaletype ? <>{listVM.vehicleSaletype}</> : <></>}</>
                <>{listVM.soldCount > 1 ? <div className="badge badge-pill badge-available badge-mini ml-1">{listVM.soldCount} WON</div> : <></>}</>
            </> : <></>;
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings);
            listVM.displayPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings, true);
            listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
            listVM.email = doc.contact.email;
            listVM.licenseNo = doc.contact.licenseNo;
            listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            listVM.contactType = doc.contact.contactType;
            listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            listVM.gender = doc.contact.gender;
            listVM.maritalStatus = doc.contact.maritalStatus;
            listVM.contactMethod = doc.contact.contactMethod;
            listVM.licenseType = doc.contact.licenseType;
            listVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            listVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');
            listVM.state = CommonHelper.getNameByCode(dealersettings.states.filter(a => doc?.contact?.country ? a.country_code === doc.contact.country : true), doc.contact.state, '');
            listVM.country = CommonHelper.getNameByCode(dealersettings.countries, doc.contact.country, '');
            // listVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.contact.campaign, '');
            // listVM.origin = CommonHelper.getLabelByValue(origins, doc.contact.origin, '');
            let _setttings = doc.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                listVM.gender = CommonHelper.getNameByValue(_setttings.genders, doc.contact.gender, '');
                listVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
                listVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
                listVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
                listVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');

                listVM.contactcampaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
                listVM.contactorigin = CommonHelper.getNameByValue(_setttings.origins, doc.contact.origin, '');
            }

            if (doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                listVM.contactdealerName = CommonHelper.getOtherDealerName(dealersettings, doc.contact.clientID);
            }
            else if (doc.clientName)
                listVM.contactdealerName = doc.clientName;
            else
                listVM.contactdealerName = '';

            listVM.address = doc.contact.address;
            listVM.companyName = doc.contact.companyName;
            listVM.designation = doc.contact.designation;

            listVM.licenseState = doc.contact.licenseState;
            listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            listVM.secondaryPhone = CommonHelper.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, props.dealersettings);
            listVM.secondaryEmail = doc.contact.secondaryEmail;
            listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            listVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
            listVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
            listVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
            listVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
            listVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
            listVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
        }
        else if (!_.isEmpty(doc.engageNewContact)) {
            let _contact = Object.assign({}, doc.engageNewContact);
            listVM.contactName = CommonHelper.displayFullContact([], _contact, '', true);
            listVM.phone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
            listVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings, true);
            listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
            listVM.email = _contact.email ? _contact.email : '';
            listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
        }

        if (!_.isEmpty(doc.dynamicFields)) {

            enquiryOptions.forEach(rec => {
                if (rec.type === 'toggle') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<></>)
                }
                else {
                    listVM[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        if (!_.isEmpty(doc.td)) {
            listVM.testdriveModel = !_.isEmpty(doc.td) && !_.isEmpty(doc.td.make) ? doc.td.make + ' ' + doc.td.model : ''
            listVM.testdriveSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.td.saleType, '');
        }

        if (!_.isEmpty(doc.lastNote)) {
            listVM.lastNotes = doc.lastNote.notes ? doc.lastNote.notes : ''
            listVM.actActionOn = doc.lastNote.modifiedDate ? moment.unix(doc.lastNote.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            listVM.actActionBy = doc.lastNote.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.lastNote.modifiedBy) : '';
            if (doc.lastNote.type === 'activity')
                listVM.lastActivityType = _setttings.activityTypes && _.find(_setttings.activityTypes, { value: doc.lastNote.subType }) ? _.find(_setttings.activityTypes, { value: doc.lastNote.subType }).name : '';
            if (doc.lastNote.type === 'log')
                listVM.lastActivityType = `${CommonHelper.autoCaps(doc.lastNote.subType)} ${doc.lastNote.subType === 'call' ? 'Log' : ''}`;
        }
        if (!_.isEmpty(doc.nextActivity)) {
            listVM.nextActivityStr = listVM.nextActivityDate ? moment.unix(listVM.nextActivityDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            listVM.nextActivityOn = listVM.nextActivityDate ? <span className={`${moment.unix(listVM.nextActivityDate.seconds) < moment().startOf('day') ? 'activity-due' : ''}`}>{moment.unix(listVM.nextActivityDate.seconds).format('DD/MM/YYYY hh:mm A')}</span> : '';
            listVM.nextActivityBy = doc.nextActivity.owner ? CommonHelper.getUserNamebyId(allUsers, doc.nextActivity.owner) : '';
        }

        if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
            listVM.domleadSource = (<div className="Vehicle-availability">
                {
                    doc.tags.map((rec, index) => {
                        return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type) ?
                            (<div key={index}><span>{CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)}</span></div>)
                            : (<></>)
                    })
                }
            </div>);
            listVM.strleadSource = doc.tags.map((rec, index) => {
                return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)
            }).join(',');
        }

        return listVM
    }

    const diffinMins = (enq, str) => {
        const startTime = enq.receivedDateValue ? moment.unix(enq.receivedDateValue.seconds) : moment.unix(enq.addedDate.seconds);
        const duration = moment.duration(moment().diff(startTime));
        const minutes = duration.asMinutes();
        const result = moment(startTime).fromNow().replace('ago', '').replace('a month', '1 month').replace('a minute', '1 Min').replace('minutes', 'Mins').replace('hours', 'Hours');
        if (minutes > respMins)
            return str ? result : (<span className="blinking" style={{ color: '#fe0707' }}>{result}</span>)
        else
            return str ? result : (<span>{result}</span>)
    }

    const getresponseTime = (enq) => {
        const startTime = enq.receivedDateValue ? moment.unix(enq.receivedDateValue.seconds) : null;
        if (_.isEmpty(startTime))
            return 0;
        const duration = moment.duration(moment.unix(enq.addedDate.seconds).diff(startTime));
        const _seconds = duration.asSeconds();
        return _seconds;
    }

    const isValidFilter = () => {
        if (localStorage.inBoundFilter) {
            //let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
            //let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            //if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
            //    return false;

            return true;
        }
        else {
            return false;
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        if (activeTab === 'unallocated') {
            window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
                .set({ unallocatedFields: fields }, { merge: true })
                .then(() => {
                    setUnallocatedFields(fields)
                    setSettingsLoader(false)
                    props.updateDealerSettings('unallocatedFields', fields)
                    if (showToast)
                        toast.notify('Settings updated successfully', {
                            duration: 2000
                        })

                }).catch(error => {
                    setSettingsLoader(false)
                    console.error(error)
                });
        }
        else {
            window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
                .set({ allocatedFields: fields }, { merge: true })
                .then(() => {
                    setAllocatedFields(fields)
                    setSettingsLoader(false)
                    props.updateDealerSettings('allocatedFields', fields)
                    if (showToast)
                        toast.notify('Settings updated successfully', {
                            duration: 2000
                        })

                }).catch(error => {
                    setSettingsLoader(false)
                    console.error(error)
                });
        }
    }

    const handleEditFleet = (id, obj) => {

        // let _stock = Object.assign({}, objStock);
        // for (let [key, value] of Object.entries(obj)) {
        //     if (_stock.hasOwnProperty(key))
        //         _stock[key] = value;
        // }

        // if (_stock.status === stockStatus.AVAILABLE) {
        //     setModelFleetShow(true);
        //     setModelStock(_stock);
        // }
        // else if (_stock.status === stockStatus.ONTESTDRIVE && obj.testDriveID) {
        //     setEditDocument(obj.testDriveID);
        //     setModelTestDriveShow(true);
        //     setModelStock(_stock);
        // }
        // else if (_stock.status === stockStatus.ONLOAN && obj.fleetID) {
        //     setEditDocument(obj.fleetID);
        //     setModelFleetShow(true);
        //     setModelStock(_stock);
        // }
    }

    const handleActionClick = (id, obj) => {
        if (activeTab === 'unallocated') {
            let _objData = obj.objDoc ? obj.objDoc : obj;
            return (<div className="fleet-btn-loan width-fix">
                <button
                    style={{ cursor: 'pointer' }}
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                        e.preventDefault();

                        setshowOEMLeadConvert({
                            show: true,
                            enquiryID: (_objData.enquiryID ? _objData.enquiryID : (_objData.stage !== 'InboundLead' ? id : null)),
                            fields: _objData,
                            enquiries: null
                        })
                        //handleShowInboundLead(id);
                    }}
                >
                    <Translate text={'allocate'} />
                </button>
            </div>)
        }
        else {
            return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                e.preventDefault();
                if (!_.isEmpty(obj) && obj.stageValue === 'InboundLead')
                    handleShowInboundLead(id);
                else
                    sidepanelOpen(id)
            }}>
                <i className="ico icon-more"></i>
            </div>)
        }

    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();

        if (!_.isEmpty(obj) && obj.stageValue === 'InboundLead') {
            handleShowInboundLead(id);
        } else { sidepanelOpen(id) }
    }

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }

    const handleShowInboundLead = (id) => {
        //console.log(id);
        setInboundModal({ show: true, docID: id });
    }

    const handleCloseInboundLead = () => {
        setInboundModal({ show: false, docID: null })
    }

    const handleOEMLeadModalClose = (enqIds) => {
        if (!_.isEmpty(enqIds)) {
            for (let [key, value] of Object.entries(enqIds)) {
                var logVM = convertEnquiryVM(value);
                dispatch({
                    type: "REMOVE_LIST",
                    data: logVM,
                    activetab: activeTab
                });
            }

            refreshStageCount();
        }
        setshowOEMLeadConvert({
            show: false,
            enquiryID: null,
            fields: null,
            enquiries: null
        })
        setSelectedIds({});
    }

    const refreshStageCount = () => {


        const { dealersettings, pipeline } = props;

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client)) {
            try {

                let _searchObject = {
                    "pipeline": 'LeadsBucket',
                    "owner": localStorage.uid,
                    "settingsID": dealersettings.oemID ? dealersettings.oemID : null
                }

                _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, pipelineView, null, true);

                const stageCountsBQ = window.firebase.functions().httpsCallable('enquiries-stageCountsBQ');
                stageCountsBQ(_searchObject).then((response) => {
                    //console.log('stageCountsBQ', _dpl, response);
                });
            }
            catch (error) {
                console.error(error)
            }
        }
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries[activeTab].find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_LIST",
                data: enq,
                activetab: activeTab
            });

            if (enq.stageValue === 'InboundLead') {

                firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${id}`).set({
                    isFav: Boolean(val)
                }, { merge: true })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
            else {
                firestoreDB(props.dealersettings).firestore().doc(`enquiries/${id}`).set({
                    isFav: Boolean(val)
                }, { merge: true })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }

        }
    }

    const handleSearchText = (val) => {
        setSearchText(val);
        if (!_.isEmpty(_.trim(val))) {
            if (localStorage.inBoundFilter) {
                let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);
                _filter['keywords'] = _.trim(val);
                _pipelineFilter.value = _filter;
                handleApplyFilter(_pipelineFilter);
            }
            else {
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else {
            handleClearFilter();
        }

    }

    const handleSetActiveTab = (val) => {
        if (activeTab !== val) {
            setActiveTab(val);
            setDataload({
                ...checkDataLoad,
                [val]: true
            })
            setHasMoreData([...hasMore.filter(e => e.type !== val)]);
            setPageNum(0);
            setEnquiryLoader({
                ...enquiryLoader,
                [val]: true
            });
            setSelectedIds({});
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('inBoundFilter', JSON.stringify(_filter));
            //localStorage.inBoundSearchText = '';
            setFilterApplied(true);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            //setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.inBoundFilter)) {
            localStorage.removeItem('inBoundFilter');
            localStorage.inBoundSearchText = '';
            setFilterApplied(false);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            setSearchText('');
        }
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            let enq = convertEnquiryVM(objEnquiry);

            if (!_.isEmpty(enq)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: enq,
                    activetab: activeTab
                });

            }
        }

    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries[activeTab]) && enquiries[activeTab].map(file => {
                let record = Object.assign({}, enquiries[activeTab].filter(e => e.documentID === file.documentID)[0]);
                if (!_.isEmpty(record))
                    _selectedIds[file.documentID] = record.objDoc;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries[activeTab].filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleOpenAllocateModal = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to allocate.'));
            return;
        }
        else {
            const _leadIds = Object.keys(_selectedIds).slice(0, 50).reduce((result, key) => {
                result[key] = _selectedIds[key];
                return result;
            }, {});

            setshowOEMLeadConvert({
                show: true,
                enquiryID: null,
                fields: null,
                enquiries: _leadIds
            })
        }
    }

    const excelDownload = async (total) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let _selectedIds = Object.assign({}, selectedIds);
        if (!_.isEmpty(_selectedIds)) {
            let _enquiries = [];
            for (let [key, value] of Object.entries(_selectedIds)) {
                var logVM = convertEnquiryVM(value);
                _enquiries.push(logVM)
            }

            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader));
            setTimeout(() => {
                csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();
                toast.closeAll();
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })
            }, 1000);


            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'enquiries',
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {

            let _pipelineFilter = localStorage.inBoundFilter ? JSON.parse(localStorage.inBoundFilter) : {};
            let _filter = Object.assign({}, _pipelineFilter.value);
            //_filter.pipeline = activeTab === 'unallocated' ? 'LeadsBucket' : null;
            _filter.inbound = true;
            _filter.inboundType = activeTab;

            const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
                !_.isEmpty(props.dealersettings.rolePermissions) &&
                !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
                props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

            if (!viewOtherEnquiry)
                _filter.owner = props.dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }

            // CONTACT AGE FILTER
            if (!_.isEmpty(_filter.contactAge)) {
                _filter.dateType = 'dob';
                _filter.startDate = moment().add(parseInt(`-${_filter.contactAge[1]}`), 'years').format('YYYY-MM-DD');
                _filter.endDate = moment().add(parseInt(`-${_filter.contactAge[0]}`), 'years').format('YYYY-MM-DD');
                delete _filter['contactAge'];
            }

            /* MAKE & MODEL FILTER */
            if (!_.isEmpty(_filter.make))
                _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

            if (_filter.rottenDays &&
                !_.isEmpty(props.dealersettings) &&
                !_.isEmpty(props.dealersettings.client) &&
                !_.isEmpty(props.dealersettings.client.settings) &&
                !_.isEmpty(props.dealersettings.client.settings.pipelines)) {
                _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.settings.pipelines)
            }
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
            _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings, 'INBOUND', null, true);
            _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()

            // Region/Subregion/Group/Client filter
            if ((!_.isEmpty(_filter?.region) || !_.isEmpty(_filter?.subregion) || !_.isEmpty(_filter?.group)) && _.isEmpty(_filter?.clientID)) {
                _filter.clientID = props.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
                    (_filter?.region?.length > 0 ? _filter.region.includes(client.region) : true) &&
                    (_filter?.subregion?.length > 0 ? _filter.subregion.includes(client.subregion) : true) &&
                    (_filter?.group?.length > 0 ? _filter.group.includes(client.group) : true)
                ).map(r => { return r.id }).join(',') : ''
            }

            let _searchObject = {
                "type": "searchEnquiries",
                "filters": JSON.stringify(_filter),
                "sortOrder": activeTab === 'unallocated' ? "addedDate asc" : "stageDate desc",
                "pageNum": 0,
                "pageLimit": 999
                //"pageLimit": total > 999 ? 5000 : (total ? total : 5000)
            }
            _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, 'INBOUND', null, true);

            const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
            const resp = await searchEnquiries(_searchObject);
            //console.log('generic-searchData', _rec, response);
            if (resp.data.success) {
                let _enquiries = [];
                resp.data.data.forEach(function (doc) {
                    var logVM = convertEnquiryVM(doc);
                    _enquiries.push(logVM)
                });

                setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader));
                setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                toast.closeAll();
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })

                let _objLogData = {
                    notes: 'Excel export from pipeline',
                    type: 'excelExport',
                    subType: 'enquiries',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);
            }
            else {
                toast.closeAll();
                Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
            }

        }

    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }

    //#endregion

    return (
        <div className="middle-wrapper">

            <FilterPanel
                pipeline={props.pipeline}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                stageCounts={pipelineCount}
                inboundCount={inboundCount}
                csvHeader={csvHeader}
                csvData={csvData}
                //datalist={enquiries}
                view="list"
                isFilterApplied={isFilterApplied}
                searchText={searchText}
                handlesearchText={(val) => {
                    handleSearchText(val);
                }}
                excelDownload={excelDownload}
                csvBtn={csvBtn}
                pipelineView={pipelineView}
                activeTab={activeTab}
                handleSetActiveTab={handleSetActiveTab}
                selectedIds={selectedIds}
                handleOpenAllocateModal={handleOpenAllocateModal}
            />

            <div className="calendar-panel">
                {
                    enquiryLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        enquiries[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={true}
                                    datalist={_.orderBy(enquiries[activeTab], ['sortDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={
                                        (activeTab === 'unallocated')
                                            ?
                                            allUnallocatedFields
                                            :
                                            allAllocatedFields
                                    }
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={
                                        (activeTab === 'unallocated')
                                            ? (unallocatedFields && unallocatedFields.length > 0) ? [...unallocatedFields.filter(item => item !== 'checkbox'), 'checkbox'] : allUnallocatedFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                            : (allocatedFields && allocatedFields.length > 0) ? [...allocatedFields.filter(item => item !== 'checkbox')] : allAllocatedFields.filter(e => e.default === true && e.type !== 'checkbox').map(v => _.pick(v, ['value']).value)
                                    }
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    handleFavorite={handleFavorite}
                                    isSettings={true}
                                    hasMore={hasMore.find(e => e.type === activeTab)}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                    selectedIds={selectedIds}
                                    handleAllChecked={handleAllChecked}
                                    handleCheckChange={handleCheckChange}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                }


            </div>

            <PopUpModal show={inboundModal.show}>
                <InBoundLeadConvert
                    show={inboundModal.show}
                    docID={inboundModal.docID}
                    stage={inboundModal.stage}
                    handleModalClose={handleCloseInboundLead}
                    pipeline={'LeadsBucket'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    loader={false}
                />
            </PopUpModal>

            <PopUpModal show={showOEMLeadConvert.show}>
                <OEMLeadAllocation
                    show={showOEMLeadConvert.show}
                    handleModalClose={handleOEMLeadModalClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={showOEMLeadConvert.enquiryID}
                    enquiry={showOEMLeadConvert.fields}
                    enquiries={showOEMLeadConvert.enquiries}
                />
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

            <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
                <StageConsumer>
                    {() => (

                        <SidePanel
                            clsActive={showpanel.clsActive}
                            clsName={showpanel.clsName}
                            sidepanelClose={sidepanelClose}
                            title=''
                        >
                            <QuickView {...props}
                                enquiryid={showpanel.enquiryid}
                                isFilterApplied={isFilterApplied}
                                updateListEnquiry={updateListEnquiry}
                                sidepanelClose={sidepanelClose}
                            />
                        </SidePanel>
                    )}
                </StageConsumer>
            </StageProvider>

        </div>
    )
}

export default FleetListView;