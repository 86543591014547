import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ContentOverlay, TableView, PopUpModal, ReactSelect } from "../../../../components";
import { vehicleModelFields } from './viewModel'
import { ClientContext } from '../../../layout/clientContext'
import AddModel from "./addModel";
const Makes = () => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
    const [loader, setLoader] = useState(true)
    const [searchText, setSearchText] = useState('');
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([]);
    const [showModelPopup, setShowModel] = useState(false)
    const [selectedMake, setSelectedMake] = useState(null)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedType, setSelectedType] = useState(null)
    const [selectedModel, setSelectedModel] = useState(null)
    const [popHeader, setPopHeader] = useState('')
    const { oemBrands } = useContext(ClientContext)

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 259)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(selectedMake))
            return
        setLoader(true)
        const modelsSnapshot = window.firebase.firestore().doc(`brands/${selectedMake.documentID}/models/${selectedMake.documentID}`)
            .onSnapshot(querySnapshot => {
                let _models = [];
                querySnapshot.exists && querySnapshot.data().models.forEach((rec) => {
                    _models.push({ ...rec, 'documentID': rec.value })
                })
                setModels(_models)
                setLoader(false)
            })
        return () => {
            modelsSnapshot && modelsSnapshot();
        }

    }, [selectedMake])


    useEffect(() => {
        if (!_.isEmpty(oemBrands)) {
            let _makes = [];
            oemBrands.forEach(rec => {
                _makes.push({
                    searchlabel: rec.name,
                    label: rec.category !== 'Automotive' ? <>{rec.name}<span className="user-role-span">{rec.category}</span></> : rec.name,
                    value: rec.documentID,
                    data: rec
                })
            })
            setMakes(_makes);
            setSelectedMake(_makes.length > 0 ? _makes[0].data : null)
            setLoader(false);
        }
    }, [oemBrands])

    const hadleEditModel = (data) => {
        setSelectedModel(data)
        setPopHeader('Edit Model')
        setShowModel(true)
    }

    const handleAddModel = () => {
        setSelectedModel(null)
        setPopHeader('Add Model')
        setShowModel(true)
    }

    const handleClientdetailsClick = (e, id, data) => {
        e.preventDefault();
        hadleEditModel(data)
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditModel(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
                <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteModel(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>
            </div>
        )
    }

    const hadleDeleteModel = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {

                window.firebase.firestore().doc(`brands/${selectedMake.documentID}/models/${selectedMake.documentID}`)
                    .set({
                        models: [...models.filter(item => item.value !== data.value)],
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid
                    }, { merge: true })
                    .then(() => {
                        toast.notify('Model deleted successfully', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })

            }
        })
    }

    const handleModelClose = () => {
        setShowModel(false)
    }

    return (<>
        <div className="mt-2">

            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={makes}
                                    name={"make"}
                                    placeholder={'select make'}
                                    onChange={(e, data) => {
                                        setSelectedMake(e.data)
                                    }}
                                    value={!_.isEmpty(selectedMake) ? selectedMake.documentID : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={_.chain(models.filter(item => !_.isEmpty(item.group)))
                                        .groupBy("group")
                                        .map((value, key) => ({
                                            label: key,
                                            value: key
                                        }))
                                        .value()}
                                    name={"group"}
                                    placeholder={'select group'}
                                    onChange={(e, data) => {
                                        if (e)
                                            setSelectedGroup(e.value)
                                        else
                                            setSelectedGroup(null);
                                    }}
                                    value={!_.isEmpty(selectedGroup) ? selectedGroup : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={false}
                                >
                                </ReactSelect>
                            </div>
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={_.chain(models.filter(item => !_.isEmpty(item.type)))
                                        .groupBy("type")
                                        .map((value, key) => ({
                                            label: key,
                                            value: key
                                        }))
                                        .value()}
                                    name={"type"}
                                    placeholder={'select type'}
                                    onChange={(e, data) => {
                                        if (e)
                                            setSelectedType(e.value)
                                        else
                                            setSelectedType(null);
                                    }}
                                    value={!_.isEmpty(selectedType) ? selectedType : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={false}
                                >
                                </ReactSelect>
                            </div>
                            <div className="filter-search search-icon col-md-3">
                                <input placeholder="model,group,type" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="float-right">
                            <button type="button" className="btn btn-primary float-right"
                                onClick={() => {
                                    setShowModel(true)
                                    setPopHeader('Add Model')
                                    setSelectedModel(null)
                                }}
                            ><i className="ico icon-add mr-2"></i> Add Model </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    models.length > 0 ? (
                        <div className="common-table">
                            <TableView
                                datalist={models.filter(({ name, group, type }) =>
                                (!_.isEmpty(searchText) ? (
                                    (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                    (!_.isEmpty(group) ? group.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                    (!_.isEmpty(type) ? type.toLowerCase().includes(searchText.toLowerCase()) : false)
                                ) : ((!_.isEmpty(selectedGroup) || !_.isEmpty(selectedType)) ? (
                                    (!_.isEmpty(group) && !_.isEmpty(selectedGroup) ? group === selectedGroup : true) &&
                                    (!_.isEmpty(type) && !_.isEmpty(selectedType) ? type === selectedType : true)
                                ) : true))
                                )}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={vehicleModelFields}
                                dynamicFields={vehicleModelFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleClientdetailsClick}
                                isSettings={false}
                                isReorderable={false}
                            />
                        </div>
                    ) : (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <p>No models found.</p>
                                <button className="btn btn-sm btn-default" onClick={() => { handleAddModel(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                    Add Model
                                </button>
                            </div>
                        </div>
                    )
            }
        </div>
        <PopUpModal show={showModelPopup}>
            <AddModel
                show={showModelPopup}
                title={popHeader}
                handleClose={handleModelClose}
                model={selectedModel}
                models={models}
                make={selectedMake}
                superAdmin={true}
            />
        </PopUpModal>
    </>);
}

export default Makes;