/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect, ReactMultiSelect, InputCheckBox, AntDatePicker,
    ReactAsyncSelect, InputText, InputRadio, AutoComplete,
    Terms, ImageViewer, PopUpModal, DropDownMenu, ImageCropHook, ReactDatePicker
} from '../../../components';
import { CustomToggle, CustomSearchMenu } from '../../../components/customdropdown';
import { default as _images } from "../../../images";
import Translate from '../../../constants/translate';
import AddCompany from '../../company/add'
import Contacts from '../../contacts/list/index';
import ContactInfo from '../contactInfo'
import CommonHelper from '../../../services/common';
import ReAuthenticate from '../../common/reAuthenticate';
import DLScan from '../digitalLicenseScan'
import SignOptions from '../../common/signOptions'
//import Languages from 'lookups-languages';
import EnquiryList from '../enquiryList'
/** VIEW-MODELS */
import { objContact, objOwnedVehicle } from '../viewModel'
import { companyBasicVM } from '../../company/viewModel'
import { contactResponseCode } from '../../../services/enum';
import { firestoreDB, storageBucket } from '../../../services/helper';
import enquiryHelper from '../../../services/enquiryHelper';

//#region  ASSIGN VARIABLES
let titles = [];
let languages = [];
let nationalities = [];
let license_state = [];
let defaultCountry = '';
let dependent = [];
let campaigns = [];
let origins = [];

let genders = [];
let marital_status = [];
let license_type = [];
let interested = [];
let contact_method = [];

const phoneList = [
    { active: true, value: 'Home', label: <Translate text={'home'} /> },
    { active: true, value: 'Work', label: <Translate text={'Work'} /> },
    { active: true, value: 'Other', label: <Translate text={'Other'} /> },
]
const emailList = [
    { active: true, value: 'Secondary', label: <Translate text={'Secondary'} /> },
    { active: true, value: 'Other', label: <Translate text={'Other'} /> },
]
const opts = [
    { active: true, value: 'true', label: <Translate text={'Opt In'} /> },
    { active: true, value: 'false', label: <Translate text={'Opt Out'} /> }
]

const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]

const sign_type = [
    { active: true, ico: 'ico icon-email', value: 'send', label: 'Send sign request', text: 'Send signature request to customer' },
    { active: true, ico: 'ico icon-dynamic-checkbox', value: 'verbal', label: 'Verbal consent', text: 'Has the customer verbally provided you with their consent to capture and record their details/information' },
    { active: true, ico: 'ico icon-sign', value: 'sign', label: 'Sign consent on-sight', text: 'Get signature on-site from customer' }
]

let _fileInfo = {
    file: null,
    id: '',
    type: '',
    title: ''
}

/*var excluded = [
    "documentID",
    "clientID",
    "customerNumber",
    "addedBy",
    "modifiedBy",
    "addedDate",
    "modifiedDate",
    "isDeleted",
    "keywords",
    "terms",
    "mergeID",
];
const order = [
    "userImageURL",
    "displayID",
    "businessContact",
    "title",
    "firstName",
    "lastName",
    "preferredName",
    "gender",
    "dob",
    "phoneCode",
    "phone",
    "phones",
    "email",
    "emails",
    "contactType",
    "licenseNo",
    "licenseExpiry",
    "licenseState",
    "licenseType",
    "licenseFrontURL",
    "licenseBackURL",
    "address",
    "areaCode",
    "state",
    "country",
    "maritalStatus",
    "nationality",
    "language",
    "contactMethod",
    "interests",
    "dependents",
    "visitingCardURL",
    "driverName",
    "isVIP",
    "company",
    "designation",
    "secondaryTitle",
    "secondaryFirstName",
    "secondaryLastName",
    "secondaryPhoneCode",
    "secondaryPhone",
    "secondaryPhones",
    "secondaryEmail",
    "secondaryEmails",
    "secondaryLicenseFrontUrl",
    "secondaryLicenseBackUrl",
    "campaign",
    "origin",
    "optinPhone",
    "optinEmail",
    "optinPost",
];
*/
//#endregion

class AddContact extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.isNewContact = false;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.refCompany = firestoreDB(this.props.dealersettings).firestore().collection('companies');
        this.unsubscribeCOMPS = null;
        this.newDocID = null;
        this.unsubscribeFinance = null;
        this.selectedClientID = null;
        this.selectedGroupID = null;
        this.mandatoryFields = [];
        this.mandatoryContactFields = [];
        this.mandatoryBusinessFields = [];
        this.state = {
            fields: Object.assign({}, objContact),
            existingContact: Object.assign({}, objContact),
            errors: {},
            companys: [],
            clientUsers: [],
            countries: [],
            phoneCodes: [],
            states: [],
            showCompany: {
                show: false,
                companyName: null,
                clsCompanyActive: '',
                id: null,
            },
            showContacts: false,
            requestFinance: Object.assign({}),
            fileInfo: Object.assign({}, _fileInfo),
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            showContactInfo: {
                show: false,
                data: null
            },
            showReAuthentication: false,
            showDLScan: false,
            showTerms: {
                show: false,
                isVerbal: false,
                origin: null
            },
            showFinanceTerms: {
                show: false,
                isVerbal: false,
                origin: null
            },
            showTermsOption: {
                show: false,
                origin: null
            },
            showFinanceTermsOption: {
                show: false,
                origin: null
            },
            showContactEnqList: {
                show: false,
                data: null
            },
            imageViewer: { show: false, images: null }
        }
        //this.onBtnClicked = this.onBtnClicked.bind(this);
    }


    //#region FUNCTIONS

    //#region EXISITING CONTACT

    handleXContactClose = (type, objData) => {

        //console.log('handleXContactClose', type, objData);
        //let state = Object.assign({},this.state.fields);
        if (type && !_.isEmpty(objData)) {
            if (type === 'fillMissing') {
                this.fillMissingContact(objData);
            }
            else if (type === 'replaceAll' || type === 'merge') {
                this.replaceContact(objData)
            }
        }
        else if (type) {
            this.props.handleClose(type, 'delete');
        }

        this.setState({
            showContactInfo:
            {
                show: false,
                data: null
            }
        });
    }

    fillMissingContact = (objData) => {
        const fields = Object.assign({}, this.state.fields);

        for (let [key, value] of Object.entries(objData)) {
            if (fields[key] === null || fields[key] === undefined || fields[key] === false || fields[key] === '' || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) {
                fields[key] = value;
            }
        }
        fields.documentID = objData.documentID;
        fields.owner = objData.owner;
        fields.addedBy = objData.addedBy;
        fields.addedDate = objData.addedDate;

        if (_.isEmpty(fields.owner)) fields.owner = localStorage.uid;
        if (_.isEmpty(fields.country) && !_.isEmpty(fields.state)) fields.country = defaultCountry;

        this.newDocID = objData.documentID;
        this.isNewContact = false;
        this.setState({ fields: fields, errors: {} }, () => { this.loadSettings(); });
    }

    replaceContact = (objData) => {
        const fields = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objData)) {
            if (key === 'dob' || key === 'licenseExpiry')
                fields[key] = value && moment(value).isValid() ? value : null;
            else
                fields[key] = value;
        }

        if (_.isEmpty(fields.owner)) fields.owner = localStorage.uid;
        if (_.isEmpty(fields.country) && !_.isEmpty(fields.state)) fields.country = defaultCountry;

        this.newDocID = fields.documentID;
        this.isNewContact = false;
        this.setState({ fields: fields, errors: {} }, () => { this.loadSettings(); });
    }

    handleXContactShow = (data) => {
        if (!_.isEmpty(data)) {
            data = this.convertContactVM(data);
            this.setState({
                showContactInfo:
                {
                    show: true,
                    data: data
                }
            });
        }

    }
    //#endregion

    //#region ADD COMPANY

    handleCompanyClose = (companyID, objData) => {
        let state = Object.assign({}, this.state.fields);

        if (companyID && !_.isEmpty(objData)) {

            let _company = Object.assign({}, companyBasicVM);
            for (let [key, value] of Object.entries(objData)) {
                if (_company.hasOwnProperty(key))
                    _company[key] = value;
            }
            _company.documentID = companyID;
            state['company'] = _company;

            this.setState({
                fields: state,
                showCompany: {
                    show: false,
                    companyName: null,
                    id: null,
                    clsCompanyActive: ''
                }
            }, () => { this.errorChange('company') });

        }
        else {
            this.setState({
                showCompany: {
                    show: false,
                    companyName: null,
                    id: null,
                    clsCompanyActive: ''
                }
            }, () => { this.errorChange('company') });
        }

    }

    handleCompanyShow = (name) => {
        this.setState({
            showCompany: {
                show: true,
                companyName: name,
                id: null,
                clsCompanyActive: 'overlay-modal active'
            }
        });
    }

    handleEditCompany = (id) => {
        this.setState({
            showCompany: {
                show: true,
                companyName: null,
                id: id,
                clsCompanyActive: 'overlay-modal active'
            }
        });
    }
    //#endregion

    //#region PAGE LOAD

    handleContactClose = (ID, data) => {
        if (data === 'enquiry') {
            this.props.handleClose(ID, 'delete');
            this.setState({ showContacts: false });
        } else if (ID) {
            let _contact = data;

            const { dealersettings, enquiryID, groupUsers, clientUsers } = this.props;
            const accessToCreateLeadByContactOwner = (dealersettings?.rolePermissions?.permissions?.accessToCreateLeadByContactOwner) ? true : false;

            if (accessToCreateLeadByContactOwner && !_.isEmpty(enquiryID) && _contact.owner !== localStorage.uid) {
                let _txt = CommonHelper.showLocale(this.props, `Cannot select another owners contact`)
                if (_contact.owner) _txt = `${CommonHelper.showLocale(this.props, 'The contact owner is')} ${CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, _contact.owner)}, ${CommonHelper.showLocale(this.props, "Cannot select another owners contact")}`
                Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.'), _txt, 'info');
                return false;
            }


            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {
                    _contact = doc.data();
                }
                const objData = Object.assign({}, _contact);
                const fields = Object.assign({}, objContact);
                for (let [key, value] of Object.entries(objData)) {
                    if (key === 'dob' || key === 'licenseExpiry')
                        fields[key] = value && moment(value).isValid() ? value : null;
                    else
                        fields[key] = value;
                }
                if (_.isNil(objData?.optinSMS)) fields.optinSMS = null;
                if (_.isNil(objData?.marketingOptinEmail)) fields.marketingOptinEmail = null;
                if (_.isNil(objData?.marketingOptinSMS)) fields.marketingOptinSMS = null;
                this.newDocID = ID;
                this.setState({ fields: fields, errors: {} }, () => { this.loadSettings(); });
            });

            this.setState({ showContacts: false });
        }
        else {
            this.setState({ showContacts: false });
        }


    }


    onCollectionUpdate = (querySnapshot) => {
        const companys = [];
        querySnapshot.forEach((doc) => {
            const { name } = doc.data();
            companys.push({
                value: doc.id,
                active: _.isBoolean(doc.active) ? doc.active : true,
                doc, // DocumentSnapshot
                label: name ? name : ''
            });
        });
        this.setState({
            companys: companys
        });
    }

    componentDidMount() {
        const { dealersettings, company, show, docID, contactName, engageNewContact, campaign, origin, selectedClientID } = this.props;
        this.isNewContact = docID ? false : true;
        let fields = Object.assign({}, objContact);
        const states = [];
        if (show === true && docID) {
            this.newDocID = docID;
            const refData = this.ref.doc(docID);
            refData.get().then((doc) => {
                if (doc.exists) {
                    const objData = Object.assign({}, doc.data());

                    for (let [key, value] of Object.entries(objData)) {
                        fields[key] = value;
                    }
                    fields.documentID = doc.id;
                    if (_.isNil(objData?.optinSMS)) fields.optinSMS = null;
                    if (_.isNil(objData?.marketingOptinEmail)) fields.marketingOptinEmail = null;
                    if (_.isNil(objData?.marketingOptinSMS)) fields.marketingOptinSMS = null;
                    if (origin && !fields.origin) fields.origin = origin;
                    if (campaign && !fields.campaign) fields.campaign = campaign;

                    if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries)) {
                        fields.country && dealersettings.states &&
                            dealersettings.states.filter(e => e.country_code === fields.country).forEach((doc) => {
                                states.push({
                                    value: doc.code,
                                    active: _.isBoolean(doc.active) ? doc.active : true,
                                    label: doc.name
                                });
                            });
                    }
                    this.setState({ fields: fields, existingContact: fields, states: states }, () => { this.loadSettings(); this.errorOnLoad() });

                } else {
                    console.error("No such document2!");
                }
            });
        }
        else if (show === true && (!docID)) {
            this.newDocID = this.ref.doc().id;
            let countryCode = dealersettings?.client?.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';

            fields.firstName = contactName ? contactName : '';
            if (!_.isEmpty(engageNewContact)) {
                for (let [key, value] of Object.entries(engageNewContact)) {
                    fields[key] = value;
                }
                if (!_.isEmpty(engageNewContact.documentID))
                    this.newDocID = engageNewContact.documentID;
            }

            fields.documentID = this.newDocID;
            fields.owner = dealersettings ? dealersettings.id : '';
            fields = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(fields, dealersettings, selectedClientID, true);

            if (!_.isEmpty(company)) {
                let _company = Object.assign({}, companyBasicVM);
                for (let [key, value] of Object.entries(company)) {
                    if (_company.hasOwnProperty(key) && value)
                        _company[key] = value;
                }
                fields['company'] = _company;
                fields.businessContact = true;
            }

            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
                let phone_code = dealersettings.countries.filter(m => m.code === countryCode)[0];
                if (fields.phoneCode) {
                    fields.secondaryPhoneCode = fields.phoneCode;
                } else if (!_.isEmpty(phone_code)) {
                    fields.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
                    fields.secondaryPhoneCode = phone_code.phone_code ? phone_code.phone_code : '';
                }
            }
            // if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client)) {
            //     fields.phoneCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU'
            // }
            if (origin) fields.origin = origin;
            if (campaign) fields.campaign = campaign;

            if (_.isEmpty(fields.country)) fields.country = countryCode;

            this.setState({ fields: fields }, () => { this.loadSettings(); this.errorOnLoad() });
        }

        setTimeout(() => {
            let elem = document.getElementById('firstName')
            elem.focus()
        }, 100)


    }

    componentWillUnmount() {
        this.unsubscribeCOMPS && this.unsubscribeCOMPS();
        this.unsubscribeFinance && this.unsubscribeFinance();

        const { requestFinance } = this.state;
        if (requestFinance && requestFinance.status === 'requested')
            firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${requestFinance.contactID}/signRequest/${requestFinance.id}`).delete();
    }

    loadSettings = async () => {
        const { dealersettings, clientUsers, groupUsers } = this.props;
        const { fields } = this.state;
        const countries = []; const states = []; const phoneCodes = [];

        this.selectedClientID = fields && fields.clientID ? fields.clientID : dealersettings.client.id;
        this.selectedGroupID = fields && fields.groupID ? fields.groupID : dealersettings.client.group;

        let setttings = dealersettings.client.settings;
        let _currentSettings = null;
        if (this.selectedClientID && this.selectedClientID !== dealersettings.client.id) {
            _currentSettings = await CommonHelper.updateClientSettings(this.selectedClientID, Object.assign({}, dealersettings));
            if (!_.isEmpty(_currentSettings?.client?.settings)) setttings = _currentSettings.client.settings;
        }

        if (!_.isEmpty(setttings)) {

            const _clientData = _currentSettings?.client ? _currentSettings.client : dealersettings.client;
            defaultCountry = _clientData?.countryCode?.toUpperCase() || _clientData?.country?.toUpperCase() || dealersettings.client.country;

            titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            genders = [];
            setttings.genders && setttings.genders.forEach(doc => {
                genders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            marital_status = [];
            setttings.maritalStatus && setttings.maritalStatus.forEach(doc => {
                marital_status.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            contact_method = [];
            setttings.contactMethod && setttings.contactMethod.forEach(doc => {
                contact_method.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            interested = [];
            setttings.interests && setttings.interests.forEach(doc => {
                interested.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            license_type = [];
            setttings.licenseType && setttings.licenseType.forEach(doc => {
                license_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.contact))
                this.mandatoryContactFields = setttings.mandatoryFields.contact.split(',');

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.businessContact))
                this.mandatoryBusinessFields = setttings.mandatoryFields.businessContact.split(',');

            if (fields.businessContact)
                this.mandatoryFields = this.mandatoryBusinessFields;
            else
                this.mandatoryFields = this.mandatoryContactFields;

        }

        dependent = [];
        for (var i = 0; i <= 25; i++) {
            dependent.push({
                value: i.toString(),
                label: i.toString(),
                active: true
            });
        }

        languages = [];
        dealersettings.languages && dealersettings.languages.forEach((doc) => {
            languages.push({
                value: doc.code,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        nationalities = [];
        dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
            nationalities.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        dealersettings.countries && dealersettings.countries.forEach((doc) => {
            if (doc.phone_code) {
                phoneCodes.push({
                    country_code: doc.code,
                    value: doc.phone_code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                });
            }

            countries.push({
                value: doc.code,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });

        });

        fields.country && dealersettings.states &&
            dealersettings.states.filter(e => e.country_code === fields.country).forEach((doc) => {
                states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

        license_state = [];
        dealersettings.states &&
            dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                license_state.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
        license_state.push({ label: 'International', value: 'International', active: true })

        let _clientUsers = [];
        let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        if (fields.clientID && !_.isEmpty(groupUsers)) {
            _clientUsers = groupUsers.filter(e => e.clientID === fields.clientID);
        }
        else if (!_.isEmpty(_allUsers)) {
            _.uniq(_allUsers.map(v => _.pick(v, ['id']).id)).map(e => { return { ..._.find(_allUsers, { id: e }) } }).forEach((rec) => {
                _clientUsers.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                });
            });
        }

        this.setState({
            clientUsers: _clientUsers,
            countries: countries,
            phoneCodes: phoneCodes,
            states: states
        });
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = _.trim(value);
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str ? str.replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    handleOnNameChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = _.trim(value);
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase().replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '') + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {

                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0).replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '');
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str ? str.replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    handleTypeChange = e => {
        const { name, value } = e.target;
        let val = (value === 'Business' ? true : false);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;

        if (value === 'Business')
            this.mandatoryFields = this.mandatoryBusinessFields;
        else
            this.mandatoryFields = this.mandatoryContactFields;

        this.setState({ fields: state }, () => { this.errorChange('company', true); });
    };

    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9 \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[name] = _.trim(value);
            this.setState({ fields: state }, () => { this.errorChange(name) });
        }
    };

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

        if (fields.businessContact === true)
            _mandatoryFields = _.union(_mandatoryFields, ['company']);

        if (!_.isEmpty(errors)) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }
        //console.log('errorChange', isScroll, document.getElementById(key), errors, key)
        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }

    errorOnLoad = () => {
        if (!_.isEmpty(this.props.mandatoryFields)) {
            let errors = {};
            let fields = Object.assign({}, this.state.fields);
            let errorClass = 'input_error';
            let errorUploadClass = 'input_upload_error';
            let _mandatoryFields = this.mandatoryFields;

            if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
                _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

            if (fields.businessContact === true)
                _mandatoryFields = _.union(_mandatoryFields, ['company']);

            for (let [key] of Object.entries(fields)) {

                if ((_mandatoryFields && _mandatoryFields.filter(e => !['creditScoreTerms'].includes(e)).indexOf(key) >= 0) || (key === 'company' && fields['businessContact'] === true)) {

                    if (key === 'company' && _.isEmpty(fields[key])) {
                        errors[key] = errorClass;
                    }

                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {

                        if (key === 'licenseFrontURL' || key === 'licenseBackURL' || key === 'secondaryLicenseFrontUrl' || key === 'secondaryLicenseBackUrl' || key === 'visitingCardURL') {
                            if (!fields.isDLScan) errors[key] = errorUploadClass;
                        }
                        else
                            errors[key] = errorClass;

                    }

                    if (key === 'email') {
                        if (typeof fields[key] !== "undefined") {
                            let lastAtPos = fields[key].lastIndexOf('@');
                            let lastDotPos = fields[key].lastIndexOf('.');

                            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {
                                errors[key] = errorClass;
                            }
                        }
                    }
                }
            }

            if (_.isEmpty(errors) && _mandatoryFields && _mandatoryFields.indexOf('creditScoreTerms') >= 0) {
                let _creditScoreTC = CommonHelper.getTermsByField(this.props.dealersettings.client, 'creditScoreTC', fields.language);
                if (!_.isEmpty(_creditScoreTC) && _.isEmpty(fields.creditScoreTerms))
                    this.setState({ showFinanceTermsOption: { show: true, origin: '' } })
            }
            this.setState({ errors: errors });
        }
    }

    searchArray = (key, myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].value === key) {
                return myArray[i];
            }
        }
    }

    handleReactSelectChange = (e, data) => {
        //console.log('handleReactSelectChange', e, data)
        //e.preventDefault();
        let state = Object.assign({}, this.state.fields);
        if (e) {
            if (data.name === 'company') {
                if (e.__isNew__) {
                    this.handleCompanyShow(e.value);
                }
                else
                    state[data.name] = {
                        'documentID': e.value,
                        'name': e.searchLabel,
                        'phoneCode': e.phoneCode ? e.phoneCode : '',
                        'phone': e.phone ? e.phone : '',
                        'email': e.email ? e.email : '',
                        'owner': e.owner ? e.owner : '',
                    }
            }
            else
                state[data.name] = e.value;
        }

        else {
            if (data.name === 'company')
                state[data.name] = {}
            else
                state[data.name] = '';
        }

        if (data.name === 'country') {
            if (e)
                this.handleCountryChange(e.value);
            else
                this.handleCountryChange('');
            state['state'] = '';
        }

        if (data.name === 'optinEmail' || data.name === 'optinPhone' || data.name === 'optinSMS' ||
            data.name === 'optinPost' || data.name === 'marketingOptinEmail' || data.name === 'marketingOptinSMS') {
            state[data.name] = e && e.value === 'true' ? true : false;
        }

        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleCountryChange = make => {
        const { dealersettings } = this.props;
        const states = [];
        if (make && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === make).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        this.setState({ states: states });
    }

    handleReactMultiSelectChange = (selectedOptions) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        state['interests'] = value;
        this.setState({ fields: state });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    //#endregion

    //#region DATEPICKER
    // handleDateChange = (val, name) => {
    //     let state = Object.assign({},this.state.fields);
    //     if (val)
    //         state[name] = moment(new Date(val)).format('YYYY-MM-DD');
    //     else
    //         state[name] = null;

    //     this.setState({ fields: state });
    //     this.errorChange(name);
    // };

    handleDateChange = (val, name) => {
        //console.log('handleDateChange', val, name)
        let state = Object.assign({}, this.state.fields);
        if (val)
            state[name] = moment(val).format('YYYY-MM-DD');
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name) });
    };

    // handleLicenseDateChange = (moment) => {
    //     let state = Object.assign({}, this.state.fields);
    //     state['licenseExpiry'] = moment.format('YYYY-MM-DD');
    //     this.setState({ fields: state }, () => { this.errorChange('licenseExpiry') });
    // }

    // handleDOBDateChange = (moment) => {
    //     let state = Object.assign({}, this.state.fields);
    //     state['dob'] = moment.format('YYYY-MM-DD');
    //     this.setState({ fields: state }, () => { this.errorChange('dob') });
    // }

    handleDateValueChange = (name, date) => {
        var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
        if (date.formattedValue !== null && date.formattedValue !== "" && pattern.test(date.formattedValue)) {
            let state = Object.assign({}, this.state.fields);
            state[name] = moment(date.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
            this.setState({ fields: state });
        }
        else if (!date.formattedValue) {
            let state = Object.assign({}, this.state.fields);
            state[name] = null;
            this.setState({ fields: state });
        }
    }


    limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    dateFormat = (val) => {
        let date = this.limit(val.substring(0, 2), '31');
        let month = this.limit(val.substring(2, 4), '12');
        let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
        let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
        return fullDate
    }

    //#endregion

    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, type, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        //let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = this.props.docID ? this.props.docID : this.ref.doc().id;
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${this.selectedClientID}/contacts/${this.newDocID}/${type}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                //console.log(error);   
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${this.selectedClientID}/contacts/${this.newDocID}/${type}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.fields);
                        state[id] = dataURL;
                        this.setState({ fields: state }, () => { this.errorChange(id) });
                    })
            })
    };

    isImageUploading = () => {
        let state = Object.assign({}, this.state.fields);
        if ((state.licenseBackURL && state.licenseBackURL.indexOf('base64') > 0) ||
            (state.licenseFrontURL && state.licenseFrontURL.indexOf('base64') > 0) ||
            (state.secondaryLicenseBackUrl && state.secondaryLicenseBackUrl.indexOf('base64') > 0) ||
            (state.secondaryLicenseFrontURL && state.secondaryLicenseFrontURL.indexOf('base64') > 0) ||
            (state.visitingCardURL && state.visitingCardURL.indexOf('base64') > 0) ||
            (state.userImageURL && state.userImageURL.indexOf('base64') > 0))
            return true;
        else
            return false;
    }


    //#endregion

    //#region  LOCATION AUTOCOMPLETE SEARCH
    bindAddress = (place) => {

        let state = Object.assign({}, this.state.fields);
        let street = '';
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };

        if (place.formatted_address !== null)
            state['address'] = place.formatted_address;

        if (!_.isEmpty(place.address_components)) {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];

                    if (addressType === "postal_code")
                        state['areaCode'] = val;

                    if (addressType === "administrative_area_level_1")
                        state['state'] = val;

                    if (addressType === "locality")
                        state['suburb'] = val;

                    if (addressType === "country") {
                        state['country'] = val;
                        this.handleCountryChange(val);
                    }


                    if (addressType === "street_number" || addressType === "route") {
                        street += ` ${val}`;
                    }

                    // if (addressType === "route") {
                    //     var sublocality_level_1 = val;
                    // }
                }
            }
        }

        if (street) {
            state['street'] = street.trim();
        }

        this.setState({ fields: state }, () => {
            this.errorChange('address');
            this.errorChange('areaCode');
            this.errorChange('state');
            this.errorChange('country');
        });
    };
    //#endregion

    //#region  ADDTIONAL PHONE & EMAIL
    handleAddPhoneCode = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = value ? value : '';
        this.setState({ fields: state });
    };
    handlePhoneChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9+ \b]+$/;
        const newPhone = !_.isEmpty(state.phones) && state.phones.map((data, sidx) => {
            if (idx !== sidx) return data;

            let telephone = evt.target.value;
            // if value is not blank, then test the regex
            if (telephone === '' || regexp.test(telephone)) {
                return { ...data, value: evt.target.value };
            }
            else
                return { ...data };
        });
        state['phones'] = newPhone
        this.setState({ fields: state });
    };
    handleAddPhone = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['phones'] = state.phones.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemovePhone = idx => {
        let state = Object.assign({}, this.state.fields);
        state['phones'] = state.phones.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };

    handleEmailChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.emails) && state.emails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['emails'] = newEmail
        this.setState({ fields: state });
    };
    handleAddEmail = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveEmail = idx => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };



    handleSecPhoneChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9+ \b]+$/;
        const newPhone = !_.isEmpty(state.secondaryPhones) && state.secondaryPhones.map((data, sidx) => {
            if (idx !== sidx) return data;

            let telephone = evt.target.value;
            // if value is not blank, then test the regex
            if (telephone === '' || regexp.test(telephone)) {
                return { ...data, value: evt.target.value };
            }
            else
                return { ...data };
        });
        state['secondaryPhones'] = newPhone
        this.setState({ fields: state });
    };
    handleAddSecPhone = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['secondaryPhones'] = state.secondaryPhones.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveSecPhone = idx => {
        let state = Object.assign({}, this.state.fields);
        state['secondaryPhones'] = state.secondaryPhones.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };

    handleSecEmailChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.secondaryEmails) && state.secondaryEmails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['secondaryEmails'] = newEmail
        this.setState({ fields: state });
    };
    handleAddSecEmail = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['secondaryEmails'] = state.secondaryEmails.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveSecEmail = idx => {
        let state = Object.assign({}, this.state.fields);
        state['secondaryEmails'] = state.secondaryEmails.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };
    //#endregion

    //#region ADD, EDIT & SUBMIT
    handleSubmit = async () => {

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let errorUploadClass = 'input_upload_error';
        let _mandatoryFields = Object.assign([], this.props.skipMandatory ? ['firstName'] : this.mandatoryFields);

        if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

        if (fields.businessContact === true)
            _mandatoryFields = _.union(_mandatoryFields, ['company']);

        if (!_.isEmpty(_mandatoryFields)) {
            for (let [key] of Object.entries(fields)) {

                if ((_mandatoryFields?.filter(e => !['creditScoreTerms'].includes(e)).indexOf(key) >= 0) || (key === 'company' && fields['businessContact'] === true) || (key === 'email' && fields[key])) {

                    if (key === 'company' && _.isEmpty(fields[key])) {
                        formIsValid = false;
                        errors[key] = errorClass;
                    }

                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                        if (key === 'licenseFrontURL' || key === 'licenseBackURL' || key === 'secondaryLicenseFrontUrl' || key === 'secondaryLicenseBackUrl' || key === 'visitingCardURL') {
                            if (!fields.isDLScan) {
                                formIsValid = false;
                                errors[key] = errorUploadClass;
                            }
                        }
                        else {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }

                    if (key === 'email' && fields[key]) {
                        if (typeof fields[key] !== "undefined") {
                            let lastAtPos = fields[key].lastIndexOf('@');
                            let lastDotPos = fields[key].lastIndexOf('.');

                            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {
                                formIsValid = false;
                                errors[key] = errorClass;
                            }
                        }
                    }
                }
            }
        }


        if (!_.isEmpty(errors)) {
            let isChange = false;
            for (let [key] of Object.entries(errors)) {
                if (key === 'phones' || key === 'emails' || key === 'secondaryPhones' || key === 'secondaryEmails') {
                    fields[key] = fields[key].concat([{ value: "", name: 'Other' }])
                    isChange = true;
                }
            }
            if (isChange) this.setState({ fields: fields });
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            const { docID, dealersettings, enquiryID, enquiryPipeline, groupUsers, clientUsers, skipSave } = this.props;
            if (skipSave) {
                this.handleModalClose(this.newDocID, fields);
            }
            else {
                let _client = dealersettings.client;
                let _prevPurchased = _client?.clientSettings?.prevPurchased;
                const accessToCreateLeadByContactOwner = (dealersettings?.rolePermissions?.permissions?.accessToCreateLeadByContactOwner) ? true : false;
                let _customerConsent = CommonHelper.getTermsByField(_client, 'customerConsent', fields.language);
                let _creditScoreTC = CommonHelper.getTermsByField(_client, 'creditScoreTC', fields.language);
                if (accessToCreateLeadByContactOwner && !_.isEmpty(enquiryID) && fields.owner !== localStorage.uid) {
                    let _txt = CommonHelper.showLocale(this.props, `Cannot select another owners contact`)
                    if (fields.owner) _txt = `${CommonHelper.showLocale(this.props, 'The contact owner is')} ${CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, fields.owner)}, ${CommonHelper.showLocale(this.props, "Cannot select another owners contact")}`
                    Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.'), _txt, 'info');
                    return false;
                }
                else if (_mandatoryFields && _mandatoryFields.indexOf('phoneORemail') >= 0 && !fields['phone'] && !fields['email']) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Please enter Phone or Email'), '', 'info');
                    return false;
                }
                else if (_mandatoryFields && _mandatoryFields.indexOf('phoneORemailORlicenseNo') >= 0 && !fields['phone'] && !fields['email'] && !fields['licenseNo']) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Please enter Phone or Email or License No.'), '', 'info');
                    return false;
                }
                else if (_mandatoryFields && _mandatoryFields.indexOf('creditScoreTerms') >= 0 && !_.isEmpty(_creditScoreTC) && _.isEmpty(fields.creditScoreTerms)) {
                    this.setState({ showFinanceTermsOption: { show: true, origin: 'save' } })
                    return false;
                }
                else if (!_.isEmpty(_customerConsent) && _.isEmpty(fields.terms)) {
                    if (dealersettings?.client?.clientSettings?.disableVerbalConsent)
                        this.setState({ showTerms: { show: true, isVerbal: false, origin: 'save' } });
                    else
                        this.setState({ showTermsOption: { show: true, origin: 'save' } })
                    return false;
                }
                else if (!_.isEmpty(enquiryID) && _.isEmpty(docID) &&
                    !_.isEmpty(_prevPurchased?.text) &&
                    ((_.isEmpty(_prevPurchased?.pipelines)) ||
                        (enquiryPipeline && !_.isEmpty(_prevPurchased?.pipelines) && _.includes(_prevPurchased?.pipelines, enquiryPipeline)))
                ) {

                    const result = await Swal.fire({
                        title: _prevPurchased.text,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })

                    fields['prevPurchased'] = result.value ? 'Yes' : 'No';
                    this.setState({ fields: fields }, () => { this.addContact(); });
                }
                else {
                    this.addContact();
                }
            }
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }
    };

    addContact = async () => {
        this.isLoading = true;
        this.forceUpdate();
        let objData = Object.assign({}, this.state.fields);
        let _param = {};

        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        const { docID, dealersettings, enquiryID } = this.props;
        const { existingContact } = this.state;

        //Remove empty value from object
        for (var propName in objData) {
            if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
            }

            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                if (docID && !_.isEmpty(existingContact) && existingContact[propName] && objData[propName] !== existingContact[propName])
                    objData[propName] = null;
                else
                    delete objData[propName];
            }
        }

        if (objData.documentID)
            _param['contactID'] = objData.documentID;

        objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, dealersettings, objData.clientID, true);

        _param = CommonHelper.setClientOrGroupOrRegionOrOemID(_param, dealersettings);

        const _pmds = Object.assign({}, !_.isEmpty(dealersettings) &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "pmds")[0]);
        if (_.isEmpty(docID) && !_.isEmpty(_pmds) && _pmds.active && _pmds.enabled)
            _param['pmds'] = _pmds;

        if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'pmds')))
            _param['pmds'] = null;

        const _keyloop = Object.assign({}, !_.isEmpty(dealersettings) &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "keyloop")[0]);
        _param['keyloop'] = _.isEmpty(docID) && !_.isEmpty(_keyloop) && _keyloop.active && _keyloop.enabled ? true : false;

        if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'keyloop')))
            _param['keyloop'] = null;

        const _cap = Object.assign({}, !_.isEmpty(dealersettings) &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "cap")[0]);
        _param['cap'] = _.isEmpty(docID) && !_.isEmpty(_cap) && _cap.active && _cap.enabled ? true : false;

        if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'cap')))
            _param['cap'] = null;

        if (objData.mergeID) {
            _param['mergeID'] = objData.mergeID;
            //_param['pmds'] = null;
            delete objData['mergeID'];
        }

        _param['isShared'] = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            dealersettings.group.enableSharedContact) ? true : false);

        //console.log('contact updating, ', objData, _param, this.state.existingContact)

        try {
            const addContact = window.firebase.functions().httpsCallable('contact-saveContact');
            addContact({
                "model": objData,
                "param": _param
            }).then((res) => {
                //console.log('Contact_docRef', data);
                this.isLoading = false;
                this.forceUpdate();
                let response = res.data;
                if (response.success === false) {

                    //console.log('contact-saveContact', response);
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists ||
                        response.code === contactResponseCode.LicenseAlreadyExists ||
                        response.code === contactResponseCode.PMDSAlreadyExists ||
                        response.code === contactResponseCode.KeyloopAlreadyExists ||
                        response.code === contactResponseCode.CAPAlreadyExists) {
                        this.handleExistingContact(response);
                    }
                    else {
                        toast.notify(response.message, {
                            duration: 2000
                        })
                    }
                    //console.log('contact update no success')

                    return false;
                }
                else {
                    toast.notify(response.message, {
                        duration: 2000
                    })

                    if (enquiryID && _.isEmpty(docID)) {
                        this.handleOpenEnquiryList(objData)
                        return false;
                    }
                    else {
                        this.onSavedProcess(objData);
                        return true;
                    }

                }
            });
        } catch (error) {
            this.isLoading = false;
            toast.notify('Something went wrong', {
                duration: 2000
            })

            const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
            const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
            ref.set({
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedBy: localStorage.uid,
                clientID: objData.clientID ? objData.clientID : dealersettings.client.id,
                oemID: dealersettings.client.settingsID,
                error: error.message ? error.message : '',
                description: error.message ? error.message : '',
                currentPath: window.location.pathname,
            }).then(() => {
                console.log('Error has been uploaded')
            }).catch((error) => {
                console.error("Error adding log: ", error);
            });
            return false;
        }

        // const updateRef = this.ref.doc(this.newDocID);
        // updateRef.set(objData).then((docRef) => {

        //     this.isLoading = false;
        //     toast.notify('Contact saved successfully', {
        //         duration: 2000
        //     })
        //     //  window.firebase.firestore().collection("contacts").doc(this.newDocID).set({
        //     //      clientID: objData.clientID,
        //     //      firstName: objData.firstName,
        //     //      lastName: objData.lastName,
        //     //      phone: objData.phone,
        //     //      email: objData.email
        //     //  }).catch((error) => {
        //     //      console.error("Error updating contacts: ", error);
        //     //  });

        //     this.handleModalClose(this.newDocID);
        // }).catch((error) => {
        //     console.error("Error updating contact_details: ", error);
        // });

        // await this.saveContact({
        //     "model": objData,
        //     "param": _param
        // }).then((response) => {
        //     this.isLoading = false;
        //     console.log('response', response)
        //     this.forceUpdate();

        //     if (response.success === false) {
        //         //console.log(response);
        //         if (response.code === contactResponseCode.PhoneAlreadyExists ||
        //             response.code === contactResponseCode.EmailAlreadyExists ||
        //             response.code === contactResponseCode.LicenseAlreadyExists) {
        //             this.handleExistingContact(response);
        //         }
        //         else {
        //             toast.notify(response.message, {
        //                 duration: 2000
        //             })
        //         }

        //         return false;
        //     }
        //     else {
        //         toast.notify(response.message, {
        //             duration: 2000
        //         })

        //         if (this.props.isFilterApplied)
        //             this.props.updateContact(objData);

        //         //update changes on change log
        //         this.contactChangeLog(objData, _param);

        //         //update contact into to finance
        //         this.saveContactinfoFinance(objData)

        //         this.handleModalClose(this.newDocID, objData);

        //         if (this.props.isRedirectToProfile)
        //             this.props.history.push("/contacts/details/" + this.newDocID);

        //         return true;
        //     }
        // }).catch((error) => {
        //     console.error("Error updating contact_details: ", error);
        // });

    };

    async saveContact(data) {
        const contactDetailsRef = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        const contactID = data.param?.contactID ? data.param?.contactID : '';
        const clientID = data.model.clientID ? data.model.clientID : data.param.clientID;
        const mergeID = data.param?.mergeID ? data.param?.mergeID : undefined;
        let exists = false;
        let existingContact;
        const phone = data.model.phone ? data.model.phone : '';
        const email = data.model.email ? data.model.email : '';
        const licenseNo = data.model.licenseNo ? data.model.licenseNo : '';

        const checkPhone = contactDetailsRef.where('clientID', '==', clientID).where('phone', '==', phone).where('isDeleted', '==', false).limit(2).get()
            .then(snapshot => {
                if (snapshot.size === 1 && contactID !== '') {
                    snapshot.forEach(doc => {
                        if (contactID !== doc.id && doc.id !== mergeID) {
                            exists = true;
                            existingContact = doc.data();
                        }
                    });
                    return exists;
                }
                return exists;
            });
        if (phone !== '') {
            const [checkQuery] = await Promise.all([checkPhone]);
            if (checkQuery) {
                return { success: false, message: `Contact already exists with phone: ${data.model.phone}`, data: existingContact, code: 'PhoneAlreadyExists' }
            }
        }

        const checkEmail = contactDetailsRef.where('clientID', '==', clientID).where('email', '==', email).where('isDeleted', '==', false).limit(2).get()
            .then(snapshot => {
                if (snapshot.size === 1 && contactID !== '') {
                    snapshot.forEach(doc => {
                        if (contactID !== doc.id && doc.id !== mergeID) {
                            exists = true;
                            existingContact = doc.data();
                        }
                    });
                    return exists;
                }
                return exists;
            });
        if (email !== '') {
            const [checkQuery] = await Promise.all([checkEmail]);
            if (checkQuery) {
                return { success: false, message: `Contact already exists with email: ${data.model.email}`, data: existingContact, code: 'EmailAlreadyExists' }
            }
        }

        const checkLicenseNo = contactDetailsRef.where('clientID', '==', clientID).where('licenseNo', '==', licenseNo).where('isDeleted', '==', false).limit(2).get()
            .then(snapshot => {
                if (snapshot.size === 1 && contactID !== '') {
                    snapshot.forEach(doc => {
                        if (contactID !== doc.id && doc.id !== mergeID) {
                            exists = true;
                            existingContact = doc.data();
                        }
                    });
                    return exists;
                }
                return exists;
            });
        if (licenseNo !== '') {
            const [checkQuery] = await Promise.all([checkLicenseNo]);
            if (checkQuery) {
                return { success: false, message: `Contact already exists with licenseNo: ${data.model.licenseNo}`, data: existingContact, code: 'LicenseAlreadyExists' }
            }
        }
        //console.log('return')
        return this.saveContactDB(data.model, contactID, clientID, mergeID);
    }

    async saveContactDB(data, contactId, clientID, mergeID) {

        // Get a new write batch
        const { dealersettings } = this.props;
        const batch = firestoreDB(dealersettings).firestore().batch();
        const contactDetailsRef = firestoreDB(dealersettings).firestore().collection('contactDetails');
        const contact = contactId ? contactDetailsRef.doc(contactId) : contactDetailsRef.doc();

        if (clientID) {
            data.clientID = clientID;
        }

        //if (!data.displayID) data.displayID = await common.getDisplayID(data.clientID, 'contact');

        // if (data.modifiedDate && data.modifiedDate.seconds)
        //     data.modifiedDate = window.firestore.Timestamp(data.modifiedDate.seconds, data.modifiedDate.nanoseconds);
        // else
        //     data.modifiedDate = window.firestore.Timestamp.now();

        // if (data.addedDate && data.addedDate.seconds)
        //     data.addedDate = window.firestore.Timestamp(data.addedDate.seconds, data.addedDate.nanoseconds);


        batch.set(contact, data, { merge: true });

        if (mergeID && mergeID !== '' && mergeID !== contactId) {
            const contactObj = Object.assign({}, data);//common.mapData(data, common.model.contact);
            await firestoreDB(dealersettings).firestore().collection('enquiries')
                .where('contact.documentID', '==', mergeID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => {
                        batch.update(doc.ref, {
                            contact: contactObj
                        });
                    });
                });
            await firestoreDB(dealersettings).firestore().collection('testdrives')
                .where('contact.documentID', '==', mergeID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => {
                        batch.update(doc.ref, { contact: contactObj });
                    });
                });
            await firestoreDB(dealersettings).firestore().collection('fleet')
                .where('contact.documentID', '==', mergeID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => {
                        batch.update(doc.ref, { contact: contactObj });
                    });
                });
            await firestoreDB(dealersettings).firestore().collection('tradeins')
                .where('contact.documentID', '==', mergeID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => {
                        batch.update(doc.ref, { contact: contactObj });
                    });
                });
            await firestoreDB(dealersettings).firestore().collection('activities')
                .where('contact.documentID', '==', mergeID)
                .where('isDeleted', '==', false).get().then(response => {
                    response.docs.forEach((doc) => {
                        batch.update(doc.ref, { contact: contactObj, contactID: contactObj.documentID });
                    });
                });
            batch.update(contactDetailsRef.doc(mergeID), { isDeleted: true, mergeID: contactObj.documentID });

        }
        // Commit the batch
        return batch.commit().then(function () {
            //console.log(`Contact saved: ${contact.id}`)
            return { success: true, data: contact.id, message: "Contact saved successfuly", code: 'Success' }
        }).catch(function (error) {
            console.error("Error adding document: ", error);
            return { success: false, message: "Error: Contact could not be saved", data: error, code: 'Exception' }
        });
    }


    saveContactinfoFinance = (contact) => {
        var objParam = {
            Id: 0,
            Title: contact.title ? contact.title : null,
            FirstName: contact.firstName ? contact.firstName : null,
            MiddleName: contact.middleName ? contact.middleName : null,
            LastName: contact.lastName ? contact.lastName : null,
            Phone: contact.phone ? contact.phone : null,
            Email: contact.email ? contact.email : null,
            DOB: contact.dob ? moment(contact.dob)._d.toISOString() : null,
            Gender: contact.gender ? contact.gender : null,
            Address: contact.address ? contact.address : null,
            LicenseNo: contact.licenseNo ? contact.licenseNo : null,
            LicenseState: contact.licenseState ? contact.licenseState : null,
            LicenseExpiry: contact.licenseExpiry
                ? moment(contact.licenseExpiry)._d.toISOString()
                : null,
            MaritalStatus: contact.maritalStatus ? contact.maritalStatus : null,
            LicenseType: contact.licenseType ? contact.licenseType : null,
            Dependents: contact.dependents ? contact.dependents : null,
            HomePhone: _.isArray(contact.phones) && contact.phones.some(a => a.name === 'Home') ? contact.phones.filter(a => a.name === 'Home')[0].value : null,
            WorkPhone: _.isArray(contact.phones) && contact.phones.some(a => a.name === 'Work') ? contact.phones.filter(a => a.name === 'Work')[0].value : null,
            LicenseImageURL: contact.licenseFrontURL ? contact.licenseFrontURL : null,
            LicenseImageBackURL: contact.licenseBackURL ? contact.licenseBackURL : null,
            SecondaryCardFront: contact.secondaryLicenseFrontUrl ? contact.secondaryLicenseFrontUrl : null,
            SecondaryCardBack: contact.secondaryLicenseBackUrl ? contact.secondaryLicenseBackUrl : null,
            DealerUrl: null,
            Bas64LicenseImage: null,
            Bas64LicenseImageBack: null,
            Bas64SecondaryCardFront: null,
            Bas64SecondaryCardBack: null,
            InquiryId: 0,
            AMSProCustomerId: contact.documentID,
            DealerCode: contact.clientID ? contact.clientID : this.props.dealersettings.client.id,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(objParam),
        };

        try {
            fetch(this.props.dealersettings.financeURL + 'api/AMSProFinance/Action/UpdateCustomerDetails', requestOptions)
                .catch(error => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }

    }

    saveContactInfoQuotation = async contactObject => {
        if (!contactObject.documentID) {
            return
        }
        const contactQuoteObj = {
            address: contactObject.address ? contactObject.address : '',
            businessContact: contactObject.businessContact ? contactObject.businessContact : '',
            dob: contactObject.dob ? contactObject.dob : '',
            email: contactObject.email ? contactObject.email : '',
            firstName: contactObject.firstName ? contactObject.firstName : '',
            middleName: contactObject.middleName ? contactObject.middleName : '',
            lastName: contactObject.lastName ? contactObject.lastName : '',
            licenseNo: contactObject.licenseNo ? contactObject.licenseNo : '',
            phone: contactObject.phone ? contactObject.phone : '',
        }

        const ref = firestoreDB(this.props.dealersettings).firestore().collectionGroup(`quotations`)
            .where('contactID', '==', contactObject.documentID)
        const snapshots = await ref.get()
        if (snapshots.size > 0) {
            let quotations = []
            snapshots.docs.forEach((doc) => {
                quotations.push(doc.data())
            });

            const batch = firestoreDB(this.props.dealersettings).firestore().batch();

            quotations.forEach(quote => {
                batch.set(firestoreDB(this.props.dealersettings).firestore().doc(`enquiries/${quote.enquiryID}/quotations/${quote.documentID}`), {
                    contact: contactQuoteObj
                }, { merge: true });
            })
            batch.commit().catch((error) => {
                console.error("Error updating document: ", error);
            });
        }
    }

    onBtnClicked = (btnId) => {
        //Swal.close();
        Swal.fire("you choosed: " + btnId);
    };

    handleModalClose = (ID, objData) => {

        // Swal.fire({
        //     title: "What you want to do?",
        //     icon: "warning",
        //     showConfirmButton: false,
        //     showCloseButton: true,

        //     //  html: "Some Text" +
        //     //  "<br>" +
        //     //  '<button type="button" role="button" tabindex="0" className="btn btn-primary" onClick="this.onBtnClicked("reply")">Reply</button>' +
        //     //  '<button type="button" role="button" tabindex="0" className="btn btn-primary" onClick={this.onBtnClicked("delete")}>Delete</button>',
        //     html:
        //         `
        //         <div>
        //             <button type="button" className="btn btn-primary" click={this.onBtnClicked('reply')}>Reply</button>
        //             <button type="button" className="btn btn-danger" Click={this.onBtnClicked('delete')}>Delete</button>
        //             <button type="button" className="btn btn-secondary" tabindex="111" id="testtt" >Cancel</button>
        //         </div>
        //         `
        // }).then((result) => {
        //     console.log(result)
        // });
        this.setState({ fields: Object.assign({}, objContact) });
        this.setState({ errors: {} });
        this.props.handleClose(ID, objData, (this.props.docID ? false : true));
    };

    handleExistingContact = (response) => {

        let _entity;
        let _msg;
        let _title;
        let _fullName = CommonHelper.displayContactName([], response.data);

        if (response.code === contactResponseCode.PhoneAlreadyExists) {
            _entity = "mobile no.";
            _title = "Mobile No. already exist";
        } else if (response.code === contactResponseCode.EmailAlreadyExists) {
            _entity = "email address";
            _title = "Email already exist";
        } else if (response.code === contactResponseCode.LicenseAlreadyExists) {
            _entity = "license no.";
            _title = "License No. already exist";
        }

        _msg = _entity ? `A contact ${_fullName} already has this ${_entity}.` : '';
        if (response.code === contactResponseCode.PMDSAlreadyExists || response.code === contactResponseCode.KeyloopAlreadyExists || response.code === contactResponseCode.CAPAlreadyExists)
            _title = response.message;


        Swal.fire({
            title: CommonHelper.showLocale(this.props, (_title ? _title : '')),
            html: _msg ? _msg : '',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#17232D',
            confirmButtonText: CommonHelper.showLocale(this.props, 'View Contact'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Okay'),
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                this.handleXContactShow(response.data)
            }
        });
    }

    convertContactVM = (doc) => {

        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key) || ['Vehicles', 'Leads', 'capGuid', 'capID', 'capOwner', 'capTags'].includes(key))
                contact[key] = value;
        }

        // let dealersettings = this.props.dealersettings;
        // if (dealersettings && dealersettings.client && dealersettings.client.settings) {
        //     //contact.language = CommonHelper.getNameByCode(dealersettings.languages, contact.language, '');
        //     contact.nationality = CommonHelper.getNameByValue(dealersettings.nationalities, contact.nationality, '--');
        //     contact.campaign = CommonHelper.getNameByValue(dealersettings.client.settings.campaigns, contact.campaign, '--');
        //     contact.origin = CommonHelper.getNameByValue(dealersettings.client.settings.origins, contact.origin, '--');

        //     contact.gender = CommonHelper.getNameByValue(dealersettings.client.settings.genders, contact.gender, '--');
        //     contact.maritalStatus = CommonHelper.getNameByValue(dealersettings.client.settings.maritalStatus, contact.maritalStatus, '--');
        //     contact.contactMethod = CommonHelper.getNameByValue(dealersettings.client.settings.contactMethod, contact.contactMethod, '--');
        //     contact.interests = CommonHelper.getLabelByMultipleValues(dealersettings.client.settings.interests, contact.interests, '--', true);
        //     contact.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, contact.licenseType, '--');
        // }

        // contact.fullName = (contact.title ? contact.title + ' ' : '') + contact.firstName + ' ' + contact.lastName + (contact.preferredName ? ' (' + contact.preferredName + ')' : '');
        // contact.licenseExpiry = contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD/MM/YYYY') : '';
        // contact.createdOn = moment.unix(contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        // contact.updatedOn = moment.unix(contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
        // contact.company = !_.isEmpty(contact.company) ? contact.company.name : '';
        // contact.dob = contact.dob ? moment(contact.dob).format('DD/MM/YYYY') : '';
        // contact.secondaryName = contact.secondaryFirstName ? (contact.secondaryFirstName + ' ' + contact.secondaryLastName) : '';
        // contact.addedBy = CommonHelper.getUserNamebyId(props.clientUsers, contact.addedBy);
        // contact.modifiedBy = CommonHelper.getUserNamebyId(props.clientUsers, contact.modifiedBy);
        // contact.optinPhone = doc.optinPhone === false ? 'Yes' : '';
        // contact.optinEmail = doc.optinEmail === false ? 'Yes' : '';
        // contact.optinPost = doc.optinPost === false ? 'Yes' : '';
        //if (doc.capGuid) contact.capGuid = doc.capGuid;
        //if (doc.capID) contact.capID = doc.capID;
        return contact
    }

    //#endregion

    //#region save change log
    contactChangeLog = (objData, _param) => {

        const { docID, enquiryID } = this.props;

        let _logNote = 'Contact created'

        if (!docID) {
            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, _param.contactID, _logNote, 'contact', 'added');
        }
        else if (_param.mergeID) {
            _logNote = 'Contact merged'
            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, _param.contactID, _logNote, 'contact', 'merged');
        }
    }
    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        let state = Object.assign({}, this.state.fields);
        state[id] = src;
        this.setState({ fields: state })
        this.saveImageToStorage(src, id, type, ext);

        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = (e, type, id, title) => {
        let state = Object.assign({}, this.state.fields);

        if (e.target.files && e.target.files.length > 0) {
            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            const { dealersettings } = this.props;
            let _client = state.clientID ? dealersettings.client : null;
            let _customerConsent = CommonHelper.getTermsByField(_client, 'customerConsent', state.language);
            if (!_.isEmpty(_customerConsent) && _.isEmpty(state.terms) && (id === 'licenseFrontURL' || id === 'licenseBackURL')) {
                if (dealersettings?.client?.clientSettings?.disableVerbalConsent)
                    this.setState({
                        showTerms: { show: true, isVerbal: false, origin: null },
                        fileInfo: {
                            file: e.target.files[0],
                            id: id,
                            type: type,
                            title: title,
                        }
                    });
                else {
                    this.setState({
                        showTermsOption: {
                            show: true,
                            origin: null
                        },
                        fileInfo: {
                            file: e.target.files[0],
                            id: id,
                            type: type,
                            title: title,
                        }
                    })
                }
            }
            else {
                reader.onloadend = () => {

                    this.setState({
                        cropShow: {
                            show: true,
                            clsActive: 'overlay-modal active',
                            src: reader.result,
                            id: id,
                            type: type,
                            ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                            title: title
                        }
                    })
                }
                reader.readAsDataURL(file)
            }
        }
    }

    handleTermsClose = (data) => {
        if (!_.isEmpty(data)) {

            let _origin = this.state.showTerms.origin;
            let state = Object.assign({}, this.state.fields);
            state.terms = data;

            this.setState({
                showTerms: {
                    show: false,
                    isVerbal: false,
                    origin: null
                }, fields: state
            }, () => { if (_origin === 'save') this.handleSubmit(); });
            //console.log('fileInfo', this.state.fileInfo)
            if (_.isEmpty(_origin) && !_.isEmpty(this.state.fileInfo) && !_.isEmpty(this.state.fileInfo.id))
                this.handleSelectedFile();
            else if (_.isEmpty(_origin)) {
                const { dealersettings } = this.props;
                const _digitallicence_nsw = Object.assign({}, !_.isEmpty(dealersettings) &&
                    dealersettings.client &&
                    dealersettings.client.integrations &&
                    dealersettings.client.integrations.filter(e => e.type === "digitallicence_nsw")[0]);
                if (!_.isEmpty(_digitallicence_nsw) && _digitallicence_nsw.active && _digitallicence_nsw.enabled)
                    this.setState({ showDLScan: true });
            }

        }
        else {
            if (!_.isEmpty(this.state.fileInfo) && this.state.fileInfo.id)
                document.getElementById(this.state.fileInfo.id).value = "";
            this.setState({
                showTerms: {
                    show: false,
                    isVerbal: false,
                    origin: null
                }
            })
        }

    }

    handleTermsChange = (type) => {
        let _origin = this.state.showTermsOption.origin;
        if (type === 'sign') {

            this.setState({
                showTerms: {
                    show: true,
                    isVerbal: false,
                    origin: _origin
                },
                showTermsOption: {
                    show: false,
                    origin: null
                }
            });
        }
        else if (type === 'verbal') {

            this.setState({
                showTerms: {
                    show: true,
                    isVerbal: true,
                    origin: _origin
                },
                showTermsOption: {
                    show: false,
                    origin: null
                }
            });
        }
        else {
            this.setState({
                showTermsOption: {
                    show: false,
                    origin: null
                }
            })
        }
    }

    handleSelectedFile = () => {
        const { file, id, type, title } = this.state.fileInfo;
        let reader = new FileReader();

        reader.onloadend = () => {

            this.setState({
                cropShow: {
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                },
                fileInfo: {
                    file: null,
                    id: '',
                    type: '',
                    title: ''
                }
            })
        }
        reader.readAsDataURL(file)

    }

    handleImageDel = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, `Are you sure?`),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                state[id] = null;
                this.setState({ fields: state })
                this.handleSaveAuditLog(id, true);
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    //#endregion

    //#region RE-AUTHENTICATION
    handleReAuthOpen = () => {
        this.setState({ showReAuthentication: true });
    }

    handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            this.forceUpdate();
        }
        this.setState({ showReAuthentication: false });
    }
    //#endregion

    //#region RE-AUTHENTICATION
    handleDLScanOpen = () => {
        const { terms, clientID, language } = Object.assign({}, this.state.fields);
        const { dealersettings } = this.props;
        let _client = clientID ? dealersettings.client : null;
        let _customerConsent = CommonHelper.getTermsByField(_client, 'customerConsent', language);
        if (!_.isEmpty(_customerConsent) && _.isEmpty(terms)) {

            if (dealersettings?.client?.clientSettings?.disableVerbalConsent)
                this.setState({ showTerms: { show: true, isVerbal: false, origin: null } });
            else
                this.setState({ showTermsOption: { show: true, origin: null } })

        }
        else {
            this.setState({ showDLScan: true });
        }
    }

    handleDLScanClose = (data) => {
        if (!_.isEmpty(data)) {

            const objData = Object.assign({}, data);
            const fields = Object.assign({}, this.state.fields);

            if (objData.dob && moment(objData.dob).isValid())
                fields.dob = objData.dob;

            if (objData.expirydate && moment(objData.expirydate).isValid())
                fields.licenseExpiry = objData.expirydate;

            if (objData.licencenumber)
                fields.licenseNo = objData.licencenumber;

            if (!_.isEmpty(objData.licenseename)) {
                const nameList = objData.licenseename.match(/(\S+)\s*(\S*)\s*(.*)/);
                if (nameList) {
                    if (nameList[1]) fields.firstName = CommonHelper.autoCaps(nameList[1] || '');
                    if (nameList[2] && nameList[3]) {
                        fields.middleName = CommonHelper.autoCaps(nameList[2]);
                        fields.lastName = CommonHelper.autoCaps(nameList[3]);
                    }
                    else if (nameList[2]) fields.lastName = CommonHelper.autoCaps(nameList[2] || '');
                } else {
                    var fullName = objData.licenseename.split(' ');
                    fields.firstName = fullName.slice(0, -1).join(' ');
                    fields.lastName = CommonHelper.autoCaps(fullName[fullName.length - 1]);
                }
            }
            if (objData.address) {
                var fullAddress = objData.address.split(' ');

                fields.address = objData.address;
                fields.country = "AU";

                fields.state = fullAddress[fullAddress.length - 2];
                fields.areaCode = fullAddress[fullAddress.length - 1];
            }

            fields.isDLScan = true;

            this.setState({ fields: fields, errors: {} }, () => { this.loadSettings(); });

            if (objData.photo)
                this.saveImageToStorage("data:image/png;base64," + objData.photo, 'userImageURL', 'profile', 'png');

            if (objData.signature)
                this.saveImageToStorage("data:image/png;base64," + objData.signature, 'userSignature', 'license', 'png');


        }
        this.setState({ showDLScan: false });
    }
    //#endregion

    handleSaveAuditLog = (imagePath, isDelete) => {
        const { docID, dealersettings } = this.props;
        let _name = CommonHelper.getFullNameWithID(null, this.state.fields, '');
        let _notes = `License image ${isDelete ? 'deleted' : 'viewed'} for ${_name}`;

        if (docID) {
            let _objLogData = {
                notes: _notes,
                type: 'licenseImage',
                subType: 'contactDetails',
                recordId: docID,
                contactID: docID,
                imageURL: imagePath,
            }

            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }

    }

    handleOpenEnquiryList = (_data) => {
        this.setState({
            showContactEnqList:
            {
                show: true,
                data: _data
            }
        });
    }

    handleCloseEnquiryList = (_enquiryid) => {
        if (_enquiryid) {
            // if (_enquiryid === 'new') { 
            const { enquiryID } = this.props;
            let _redirectEnqid = _enquiryid !== 'new' && enquiryID !== _enquiryid ? _enquiryid : null;
            if (_redirectEnqid) {
                this.props.handleClose(_redirectEnqid, 'delete');
            }
            else {
                let objData = Object.assign({}, { ...this.state.showContactEnqList.data })
                this.onSavedProcess(objData,);
            }
            this.setState({
                showContactEnqList:
                {
                    show: false,
                    data: null
                }
            });
        }
        else {
            this.setState({
                showContactEnqList:
                {
                    show: false,
                    data: null
                }
            });
        }

    }

    onSavedProcess = (objData) => {
        if (this.props.isFilterApplied)
            this.props.updateContact(objData);

        //update changes on change log
        //this.contactChangeLog(objData, _param);
        //console.log('contact update success')
        //update contact into to finance
        this.saveContactinfoFinance(objData)
        this.saveContactInfoQuotation(objData)

        const { docID } = this.props;
        const { existingContact } = this.state;
        if (!_.isEmpty(objData?.Vehicles) && (!docID || (docID && _.isEmpty(existingContact?.Vehicles))))
            this.createOwnedVehicles(objData.documentID, objData?.Vehicles, true);

        this.handleModalClose(this.newDocID, objData);

        if (this.props.isRedirectToProfile)
            this.props.history.push("/contacts/details/" + this.newDocID);
    }

    createOwnedVehicles = (_contactID, _vehicles, _currentlyOwned) => {
        if (_.isEmpty(_contactID)) return;

        const { dealersettings } = this.props;
        _vehicles && _vehicles.forEach(async (_tdData) => {
            let objData = Object.assign({});
            if (_tdData.vin) objData.vinNo = _tdData.vin;
            for (let [key, value] of Object.entries(_tdData)) {
                if (objOwnedVehicle.hasOwnProperty(key) && value !== undefined) objData[key] = value;
            }
            objData.addedBy = localStorage.uid;
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
            objData.modifiedBy = localStorage.uid;
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';
            objData.isDeleted = false;
            objData.currentlyOwned = _currentlyOwned;
            objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            objData.documentID = window.firebase.firestore().collection('contactDetails').doc().id;
            Object.keys(objData).forEach(key => objData[key] === undefined ? delete objData[key] : {});
            if (_tdData.capID) objData.documentID = _tdData.capID;
            if (_tdData.hasOwnProperty('owned')) {
                objData.owned = (_tdData.owned || false);
                objData.currentlyOwned = objData.owned;
            }
            firestoreDB(dealersettings).firestore().doc(`contactDetails/${_contactID}/ownedVehicles/${objData.documentID}`).set(objData, { merge: true })
        });
    }


    //#region Finance Credit Terms
    handleFinanceTermsClose = (data) => {
        if (!_.isEmpty(data)) {
            let state = Object.assign({}, this.state.fields);
            state.creditScoreTerms = data;
            this.setState({
                showFinanceTerms: {
                    show: false,
                    isVerbal: false,
                    origin: null
                }, fields: state
            }, () => { this.handleSubmit(); });

        }
        else {
            this.setState({
                showFinanceTerms: {
                    show: false,
                    isVerbal: false,
                    origin: null
                }
            })
        }

    }

    handleFinanceTermsChange = (type) => {
        let _origin = this.state.showFinanceTermsOption.origin;

        if (type === 'sign' || type === 'verbal') {

            this.setState({
                showFinanceTerms: {
                    show: true,
                    isVerbal: type === 'verbal' ? true : false,
                    origin: _origin
                },
                showFinanceTermsOption: {
                    show: false,
                    origin: null
                }
            });
        }
        else if (type === 'send') {

            const { requestFinance, fields } = this.state
            if (_.isEmpty(requestFinance) && fields.documentID) {

                const { dealersettings } = this.props;

                const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
                ) ? true : false);

                const isAmsMeContact = amsMeEnabled && fields.amsMe && !_.isEmpty(fields.amsMe.meID) ? true : false;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                objData.clientID = fields.clientID ? fields.clientID : dealersettings.client.id;
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                objData.status = 'requested'
                objData.contactID = fields.documentID;

                if (isAmsMeContact)
                    objData.amsMe = fields.amsMe
                if (fields.email || fields.phone) {
                    objData.contactName = CommonHelper.displayFullContact(null, fields, '');
                    objData.email = fields.email ? fields.email : ''
                    objData.phone = fields.phone ? fields.phone : ''
                    objData.phoneCode = fields.phoneCode ? fields.phoneCode : ''
                } else if (!isAmsMeContact) {
                    this.setState({ showFinanceTerms: { show: true, origin: _origin } })
                    return;
                }

                let _creditScoreTC = CommonHelper.getTermsByField(dealersettings?.client, 'creditScoreTC', fields?.language);
                objData.title = 'Finance Credit Score Consent';
                objData.type = 'termsConditions'
                objData.terms =
                {
                    'termsAndConditions': !_.isEmpty(_creditScoreTC) ? enquiryHelper.mapScoreTemplate(_creditScoreTC, fields, dealersettings) : ''
                }


                const refOnjData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeFinance = firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestFinance: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeFinance && this.unsubscribeFinance();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.creditScoreTerms = _signRequest.terms;
                                    fields.creditScoreTerms['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    this.setState({ fields: fields }, () => { if (_origin === 'save') this.handleSubmit(); });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }
            this.setState({
                showFinanceTermsOption: {
                    show: false,
                    origin: null
                }
            })
        }
        else {
            this.setState({
                showFinanceTermsOption: {
                    show: false,
                    origin: null
                }
            })
        }
    }
    //#endregion

    //#endregion

    render() {
        //console.log('R E N D E R - C O N T A C T ', this.selectedClientID, this.selectedGroupID);
        const { clientID, contactType, title, firstName, middleName, lastName, preferredName, userImageURL, dob, businessContact, phoneCode,
            gender, maritalStatus, language, isVIP, nationality, address, state, areaCode, country, phones, emails, owner,
            company, designation, contactMethod, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL, userSignature,
            phone, email, licenseState, secondaryLicenseFrontUrl, secondaryLicenseBackUrl, secondaryPhoneCode, visitingCardURL,
            secondaryTitle, secondaryFirstName, secondaryMiddleName, secondaryLastName, secondaryPreferredName,
            secondaryPhones, secondaryEmails, secondaryPhone, secondaryEmail,
            secondaryDob, secondaryGender, creditScoreTerms,
            licenseType, interests, origin, campaign, optinPhone, optinSMS, optinEmail, optinPost, dependents, driverName, addedBy, isDLScan, terms,
            banLoanVehicle, isDeleted, gdprDeleted, marketingOptinEmail, marketingOptinSMS,
            secondaryIDNumber, secondaryIDType, secondaryIDExpiry, tvDisplayName,
        } = this.state.fields;
        const { showContacts, showContactInfo, countries, states, phoneCodes, showDLScan,
            showTerms, showTermsOption, showFinanceTermsOption, showFinanceTerms, requestFinance,
            showContactEnqList, showCompany, existingContact } = this.state;
        const { show, dealersettings, isFromLayout, docID, enquiryID, isNewEnquiry, selectedClientID } = this.props;

        const isGroupDealer = ((localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id) || localStorage.defaultModule === 'oem') ? true : false;

        const modifyContactOwner = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.modifyContactOwner) ? (isGroupDealer ? false : true) : false);

        const _digitallicence_nsw = Object.assign({}, !_.isEmpty(dealersettings) &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "digitallicence_nsw")[0]);

        let _client = clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[clientID] ? dealersettings.group.clients[clientID] : dealersettings.client;


        let dealerName = '';
        if (clientID && (selectedClientID || clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'tradeinPro'))
            dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);

        const enableSharedContact = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            dealersettings.group.enableSharedContact) ? true : false);

        const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);
        const isDisabled = dealersettings?.rolePermissions?.permissions?.disableModifyContact ? (_.isEmpty(docID) ? false : true) : false;
        const canModifyContact = (isDeleted || gdprDeleted) ? false : true;
        const isFinanceRequested = requestFinance && requestFinance.status === 'requested' ? true : false;
        const tvModuleEnabled = ((dealersettings && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.tvModule) &&
            dealersettings.client.moduleSettings.tvModule.enabled) ? true : false);
        return (
            this.newDocID
                ?
                <>

                    <Modal
                        show={show}
                        onHide={this.handleModalClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-contact"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title><Translate text={this.props.title ? this.props.title : 'contact'} /></Modal.Title>

                            {dealerName ? <div className="contact-pop-groupname ml-2"><div className="badge badge-pill badge-groupname ml-2">{dealerName}</div></div> : <></>}
                            {isDLScan ? <div className="contact-pop-groupname"><div className="badge badge-pill badge-white ml-2">
                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                <Translate text={`Digital Driving Licence`} />
                            </div></div> : <></>}

                            {!_.isEmpty(terms) ? <div className="contact-pop-groupname"><div className="badge badge-pill badge-white ml-2">
                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                {`Customer Consent ${terms.verbalConsent ? '(Verbal)' : (terms.consentType ? `(${terms.consentType})` : '')}`}
                            </div></div> : <></>}

                            {!_.isEmpty(creditScoreTerms) ? <div className="contact-pop-groupname"><div className="badge badge-pill badge-white ml-2">
                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                {`Finance Credit Consent ${creditScoreTerms.verbalConsent ? '(Verbal)' : (creditScoreTerms.consentType ? `(${creditScoreTerms.consentType})` : '')}`}
                            </div></div> : <></>}

                            {
                                !canModifyContact ? (
                                    <div className="contact-pop-groupname ml-2">
                                        <div className="badge badge-pill badge-hot">
                                            <i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }

                            {
                                isDeleted ? (
                                    <div className="contact-pop-groupname ml-2">
                                        <div className="badge badge-pill badge-hot">
                                            <i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }

                            {
                                gdprDeleted ? (
                                    <div className="contact-pop-groupname ml-2">
                                        <div className="badge badge-pill badge-warm">
                                            <i className="ico icon-delete mr-1"> </i> <Translate text={'gdprDeleted'} upperCase={true} />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }

                        </Modal.Header>
                        {(() => {
                            if (this.props.showSearch === true) {
                                return (
                                    <>
                                        <div className={`popup-head-button ${(canModifyContact && !isDisabled) ? '' : 'btn-disable'}`}>
                                            <button type="button" className="btn btn-secondary float-right" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    showContacts: true
                                                });
                                            }}>
                                                <i className="ico icon-search"></i><Translate text={'searchFromExistingContacts'} /> </button>
                                        </div>
                                    </>
                                )
                            }
                        })()}


                        <Modal.Body /*className="modal-body"*/>
                            <div className={`container-fluid ${(canModifyContact) ? '' : 'btn-disable'}`}>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <div className="add-popcontact-left form-style">

                                            <div className={`contactleft-profileimage ${this.state.errors["userImageURL"] ? 'image-error' : ''}`}>
                                                <div className={`contactleft-profileimage-upload ${(isDisabled && existingContact.userImageURL) ? 'btn-disable' : ''}`}>
                                                    <label htmlFor="userImageURL">
                                                        <i className={`ico ${userImageURL ? 'icon-edit-circle' : 'icon-camera'}`} htmlFor="userImageURL"></i>
                                                        <input className="fileInput"
                                                            type="file"
                                                            name="testee"
                                                            id="userImageURL"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => this.onSelectFile(e, 'profile', 'userImageURL', 'Profile Image')} />
                                                    </label>
                                                </div>

                                                {
                                                    (userImageURL)
                                                        ?
                                                        <a data-fancy-box={`userImageURL`}
                                                            href={userImageURL}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({
                                                                    imageViewer: {
                                                                        show: true,
                                                                        images: userImageURL
                                                                    }
                                                                });
                                                            }}>
                                                            <img src={userImageURL} alt="" className=" rounded-circle img-object-fit" />
                                                        </a>
                                                        :
                                                        <img src={_images.nouser} alt="" className=" rounded-circle img-object-fit" />
                                                }

                                            </div>

                                            <div className="contactleft-header">
                                                <div className={`form-group ${(isDisabled && existingContact.contactType) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'preferredContactType'} /></label>
                                                    <ReactSelect
                                                        options={contact_type}
                                                        name={"contactType"}
                                                        placeholder={'select contact type'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={contactType}
                                                        classNamePrefix={`${this.state.errors["contactType"]} basic-select-img`}
                                                        removeClearable={true}
                                                        isDisabled={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className={`form-group ${(isDisabled && existingContact.owner) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'ownership'} /></label>
                                                    <ReactSelect
                                                        options={this.state.clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={owner ? owner : addedBy}
                                                        classNamePrefix={`${this.state.errors["owner"]} basic-select-img`}
                                                        removeClearable={true}
                                                        isSearchable={true}
                                                        isDisabled={modifyContactOwner ? false : true}
                                                    >
                                                    </ReactSelect>
                                                </div>


                                            </div>


                                            <div className="contactleft-license">
                                                <h3><Translate text={'licenseInfo'} /></h3>

                                                {
                                                    !_.isEmpty(_digitallicence_nsw) && _digitallicence_nsw.active && _digitallicence_nsw.enabled && !isDisabled
                                                        ?
                                                        <>
                                                            <div className="digital-license-btn">
                                                                <button type="button" className="btn btn-default"
                                                                    onClick={(e) => { e.preventDefault(); this.handleDLScanOpen(); }}>
                                                                    <i className="ico icon-card"></i> <Translate text={'isDLScan'} />
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <div className={`form-group ${(isDisabled && existingContact.licenseNo) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'licenseNo'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'license #'}
                                                        className={`form-control ${this.state.errors["licenseNo"]}`}
                                                        name="licenseNo"
                                                        onChange={this.handleOnChange}
                                                        value={licenseNo}
                                                    />
                                                </div>
                                                <div className={`form-group ${(isDisabled && existingContact.licenseExpiry) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'licenseExpiry'} /></label>
                                                    <div className="inner-addon right-addon">
                                                        {
                                                            isFromLayout
                                                                ?
                                                                <>
                                                                    {/* <i className="bg-icons ico icon-date"></i> */}
                                                                    <ReactDatePicker
                                                                        value={licenseExpiry}
                                                                        name={'licenseExpiry'}
                                                                        onChange={this.handleDateChange}
                                                                        format={this.dateFormat}
                                                                        placeholder='DD/MM/YYYY'
                                                                        onValueChange={this.handleDateValueChange.bind(this, 'licenseExpiry')}
                                                                        className={`form-control ${this.state.errors["licenseExpiry"]}`}
                                                                        //readOnly={true}
                                                                        isClearable={true}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <AntDatePicker
                                                                        value={licenseExpiry}
                                                                        name={'licenseExpiry'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'licenseExpiry') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["licenseExpiry"]}`}
                                                                    />
                                                                </>
                                                        }
                                                    </div>
                                                </div>

                                                <div className={`form-group ${(isDisabled && existingContact.licenseState) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'licenseState'} /></label>
                                                    <ReactSelect
                                                        options={license_state}
                                                        name={"licenseState"}
                                                        placeholder={'select license state'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={licenseState}
                                                        classNamePrefix={`${this.state.errors["licenseState"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group ${(isDisabled && existingContact.licenseType) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'licenseType'} /></label>
                                                    <ReactSelect
                                                        options={license_type}
                                                        name={"licenseType"}
                                                        placeholder={'select license type'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={licenseType}
                                                        classNamePrefix={`${this.state.errors["licenseType"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className="form-group">
                                                    <label ><Translate text={'primaryID'} /></label>
                                                    <div>
                                                        <div className="contactleft-licenseboxwrap">
                                                            <div className={`contactleft-licensebox ${(isDisabled && existingContact.licenseFrontURL) ? 'btn-disable' : ''} ${this.state.errors["licenseFrontURL"]}`}>
                                                                {
                                                                    (licenseFrontURL && licenseFrontURL.indexOf('base64') > 0)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <label htmlFor="licenseFrontURL" className={`${licenseFrontURL ? 'licensebox-edit mini-button' : ''}`}>
                                                                    <i className={`ico ${licenseFrontURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="licenseFrontURL"></i>
                                                                    <input className="fileInput"
                                                                        type="file"
                                                                        name="licFront"
                                                                        id="licenseFrontURL"
                                                                        accept="image/*"
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => this.onSelectFile(e, 'license', 'licenseFrontURL', 'License Front')} />
                                                                </label>
                                                                {
                                                                    licenseFrontURL
                                                                        ?
                                                                        <>
                                                                            <label title="Delete" htmlFor="licenseFronttDel"
                                                                                className="licensebox-edit mini-button mini-hover-red"
                                                                                style={{ marginRight: '22px' }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleImageDel('licenseFrontURL');
                                                                                }}>
                                                                                <i className="ico icon-delete" htmlFor="licenseFronttDel"></i>
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }

                                                                {
                                                                    (licenseFrontURL)
                                                                        ?
                                                                        <>
                                                                            {
                                                                                (isPrivacyEnabled && docID)
                                                                                    ?
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                        <img src={_images[`lock${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                    :
                                                                                    <a data-fancy-box={`licenseFrontURL`}
                                                                                        href={licenseFrontURL}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleSaveAuditLog(licenseFrontURL);
                                                                                            this.setState({
                                                                                                imageViewer: {
                                                                                                    show: true,
                                                                                                    images: licenseFrontURL
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                        <img src={licenseFrontURL} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                                }


                                                            </div>
                                                            <small className="form-text text-muted"><Translate text={'front'} /></small>
                                                        </div>

                                                        <div className="contactleft-licenseboxwrap">
                                                            <div className={`contactleft-licensebox ${(isDisabled && existingContact.licenseBackURL) ? 'btn-disable' : ''} ${this.state.errors["licenseBackURL"]}`}>
                                                                {
                                                                    (licenseBackURL && licenseBackURL.indexOf('base64') > 0)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <label htmlFor="licenseBackURL" className={`${licenseBackURL ? 'licensebox-edit mini-button' : ''}`}>
                                                                    <i className={`ico ${licenseBackURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="licenseBackURL"></i>
                                                                    <input className="fileInput"
                                                                        type="file"
                                                                        name="licBack"
                                                                        id="licenseBackURL"
                                                                        accept="image/*"
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => this.onSelectFile(e, 'license', 'licenseBackURL', 'License Back')} />
                                                                </label>
                                                                {
                                                                    licenseBackURL
                                                                        ?
                                                                        <>
                                                                            <label title="Delete" htmlFor="licenseBackDel"
                                                                                className="licensebox-edit mini-button mini-hover-red"
                                                                                style={{ marginRight: '22px' }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleImageDel('licenseBackURL');
                                                                                }}>
                                                                                <i className="ico icon-delete" htmlFor="licenseBackDel"></i>
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    (licenseBackURL)
                                                                        ?
                                                                        <>
                                                                            {
                                                                                (isPrivacyEnabled && docID)
                                                                                    ?
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                        <img src={_images[`lock${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                    :
                                                                                    <a data-fancy-box={`licenseBackURL`}
                                                                                        href={licenseBackURL}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleSaveAuditLog(licenseBackURL);
                                                                                            this.setState({
                                                                                                imageViewer: {
                                                                                                    show: true,
                                                                                                    images: licenseBackURL
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                        <img src={licenseBackURL} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                                }

                                                            </div>
                                                            <small className="form-text text-muted"><Translate text={'back'} /></small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="clearfix"></div>

                                                <div className={`form-group border-top mt-3 pt-2 ${(isDisabled && existingContact.secondaryIDNumber) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'secondaryIDNumber'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'secondary license #'}
                                                        className={`form-control ${this.state.errors["secondaryIDNumber"]}`}
                                                        name="secondaryIDNumber"
                                                        onChange={this.handleOnChange}
                                                        value={secondaryIDNumber}
                                                    />
                                                </div>
                                                <div className={`form-group ${(isDisabled && existingContact.secondaryIDType) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'secondaryIDType'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'secondary type'}
                                                        className={`form-control ${this.state.errors["secondaryIDType"]}`}
                                                        name="secondaryIDType"
                                                        onChange={this.handleOnChange}
                                                        value={secondaryIDType}
                                                    />
                                                </div>
                                                <div className={`form-group ${(isDisabled && existingContact.secondaryIDExpiry) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'secondaryIDExpiry'} /></label>
                                                    <div className="inner-addon right-addon">
                                                        {
                                                            isFromLayout
                                                                ?
                                                                <>
                                                                    {/* <i className="bg-icons ico icon-date"></i> */}
                                                                    <ReactDatePicker
                                                                        value={secondaryIDExpiry}
                                                                        name={'secondaryIDExpiry'}
                                                                        onChange={this.handleDateChange}
                                                                        format={this.dateFormat}
                                                                        placeholder='DD/MM/YYYY'
                                                                        onValueChange={this.handleDateValueChange.bind(this, 'secondaryIDExpiry')}
                                                                        className={`form-control ${this.state.errors["secondaryIDExpiry"]}`}
                                                                        //readOnly={true}
                                                                        isClearable={true}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <AntDatePicker
                                                                        value={secondaryIDExpiry}
                                                                        name={'secondaryIDExpiry'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'secondaryIDExpiry') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["secondaryIDExpiry"]}`}
                                                                    />
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label ><Translate text={'secondaryID'} /></label>
                                                    <div>
                                                        <div className="contactleft-licenseboxwrap">
                                                            <div className={`contactleft-licensebox ${(isDisabled && existingContact.secondaryLicenseFrontUrl) ? 'btn-disable' : ''} ${this.state.errors["secondaryLicenseFrontUrl"]}`}>
                                                                {
                                                                    (secondaryLicenseFrontUrl && secondaryLicenseFrontUrl.indexOf('base64') > 0)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <label htmlFor="secondaryLicenseFrontUrl" className={`${secondaryLicenseFrontUrl ? 'licensebox-edit mini-button' : ''}`}>
                                                                    <i className={`ico ${secondaryLicenseFrontUrl ? 'icon-edit' : 'icon-upload'}`} htmlFor="secondaryLicenseFrontUrl"></i>
                                                                    <input className="fileInput"
                                                                        type="file"
                                                                        name="licFront"
                                                                        id="secondaryLicenseFrontUrl"
                                                                        accept="image/*"
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => this.onSelectFile(e, 'license', 'secondaryLicenseFrontUrl', 'License Front')} />
                                                                </label>

                                                                {
                                                                    secondaryLicenseFrontUrl
                                                                        ?
                                                                        <>
                                                                            <label title="Delete" htmlFor="secondaryLicenseFrontDel"
                                                                                className="licensebox-edit mini-button mini-hover-red"
                                                                                style={{ marginRight: '22px' }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleImageDel('secondaryLicenseFrontUrl');
                                                                                }}>
                                                                                <i className="ico icon-delete" htmlFor="secondaryLicenseFrontDel"></i>
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    (secondaryLicenseFrontUrl)
                                                                        ?
                                                                        <>
                                                                            {
                                                                                (isPrivacyEnabled && docID)
                                                                                    ?
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                        <img src={_images[`lock${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                    :
                                                                                    <a data-fancy-box={`secondaryLicenseFrontUrl`}
                                                                                        href={secondaryLicenseFrontUrl}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleSaveAuditLog(secondaryLicenseFrontUrl);
                                                                                            this.setState({
                                                                                                imageViewer: {
                                                                                                    show: true,
                                                                                                    images: secondaryLicenseFrontUrl
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                        <img src={secondaryLicenseFrontUrl} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                            }
                                                                        </>

                                                                        :
                                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                                }

                                                            </div>
                                                            <small className="form-text text-muted"><Translate text={'front'} /></small>
                                                        </div>

                                                        <div className="contactleft-licenseboxwrap">
                                                            <div className={`contactleft-licensebox ${(isDisabled && existingContact.secondaryLicenseBackUrl) ? 'btn-disable' : ''} ${this.state.errors["secondaryLicenseBackUrl"]}`}>
                                                                {
                                                                    (secondaryLicenseBackUrl && secondaryLicenseBackUrl.indexOf('base64') > 0)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <label htmlFor="secondaryLicenseBackUrl" className={`${secondaryLicenseBackUrl ? 'licensebox-edit mini-button' : ''}`}>
                                                                    <i className={`ico ${secondaryLicenseBackUrl ? 'icon-edit' : 'icon-upload'}`} htmlFor="secondaryLicenseBackUrl"></i>
                                                                    <input className="fileInput"
                                                                        type="file"
                                                                        name="licBack"
                                                                        id="secondaryLicenseBackUrl"
                                                                        accept="image/*"
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => this.onSelectFile(e, 'license', 'secondaryLicenseBackUrl', 'License Back')} />
                                                                </label>

                                                                {
                                                                    secondaryLicenseBackUrl
                                                                        ?
                                                                        <>
                                                                            <label title="Delete" htmlFor="secondaryLicenseBackDel"
                                                                                className="licensebox-edit mini-button mini-hover-red"
                                                                                style={{ marginRight: '22px' }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleImageDel('secondaryLicenseBackUrl');
                                                                                }}>
                                                                                <i className="ico icon-delete" htmlFor="secondaryLicenseBackDel"></i>
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }

                                                                {
                                                                    (secondaryLicenseBackUrl)
                                                                        ?
                                                                        <>
                                                                            {
                                                                                (isPrivacyEnabled && docID)
                                                                                    ?
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                        <img src={_images[`lock${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                    :
                                                                                    <a data-fancy-box={`secondaryLicenseBackUrl`}
                                                                                        href={secondaryLicenseBackUrl}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleSaveAuditLog(secondaryLicenseBackUrl);
                                                                                            this.setState({
                                                                                                imageViewer: {
                                                                                                    show: true,
                                                                                                    images: secondaryLicenseBackUrl
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                        <img src={secondaryLicenseBackUrl} alt="" className="img-object-fit" />
                                                                                    </a>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                                }
                                                            </div>
                                                            <small className="form-text text-muted"><Translate text={'back'} /></small>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    (userSignature)
                                                        ?
                                                        <>
                                                            <div className="clearfix"></div>
                                                            <div className="form-group border-top mt-3 pt-2">
                                                                <label ><Translate text={'signature'} /></label>
                                                                <div>
                                                                    <div className={`contactleft-licensebox`} style={{ height: '80px' }}>
                                                                        {
                                                                            (isPrivacyEnabled && docID)
                                                                                ?
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                    <img src={_images[`lock${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" className="img-object-fit" style={{ height: '80px' }} />
                                                                                </a>
                                                                                :
                                                                                <a data-fancy-box={`userSignature`}
                                                                                    href={userSignature}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.handleSaveAuditLog(userSignature);
                                                                                        this.setState({
                                                                                            imageViewer: {
                                                                                                show: true,
                                                                                                images: userSignature
                                                                                            }
                                                                                        });
                                                                                    }}>
                                                                                    <img src={userSignature} alt="" className="img-object-fit" style={{ height: '80px' }} />
                                                                                </a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <div className="clearfix"></div>
                                                <div className="form-group border-top mt-3 pt-2">
                                                    <label ><Translate text={'visitingCardURL'} /></label>
                                                    <div>
                                                        <div className="contactleft-licenseboxwrap">
                                                            <div className={`contactleft-licensebox ${(isDisabled && existingContact.visitingCardURL) ? 'btn-disable' : ''} ${this.state.errors["visitingCardURL"]}`}>
                                                                {
                                                                    (visitingCardURL && visitingCardURL.indexOf('base64') > 0)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <label htmlFor="visitingCardURL" className={`${visitingCardURL ? 'licensebox-edit mini-button' : ''}`}>
                                                                    <i className={`ico ${visitingCardURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="visitingCardURL"></i>
                                                                    <input className="fileInput"
                                                                        type="file"
                                                                        name="visitingCard"
                                                                        id="visitingCardURL"
                                                                        accept="image/*"
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => this.onSelectFile(e, 'license', 'visitingCardURL', 'Visiting Card')} />
                                                                </label>

                                                                {
                                                                    visitingCardURL
                                                                        ?
                                                                        <>
                                                                            <label title="Delete" htmlFor="visitingCardDel"
                                                                                className="licensebox-edit mini-button mini-hover-red"
                                                                                style={{ marginRight: '22px' }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleImageDel('visitingCardURL');
                                                                                }}>
                                                                                <i className="ico icon-delete" htmlFor="visitingCardDel"></i>
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    (visitingCardURL)
                                                                        ?
                                                                        <>
                                                                            <a data-fancy-box={`visitingCardURL`}
                                                                                href={visitingCardURL}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleSaveAuditLog(visitingCardURL);
                                                                                    this.setState({
                                                                                        imageViewer: {
                                                                                            show: true,
                                                                                            images: visitingCardURL
                                                                                        }
                                                                                    });
                                                                                }}>
                                                                                <img src={visitingCardURL} alt="" className="img-object-fit" />
                                                                            </a>
                                                                        </>
                                                                        :
                                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                                }

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-md-9 pl-0 form-style">
                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'personalInfo'} />
                                                <div className={`float-right radio-contact ${(isDisabled) ? 'btn-disable' : ''}`} >
                                                    <div className="radio icheck-success float-left">

                                                        <InputRadio
                                                            id="Private"
                                                            type="radio"
                                                            value="Private"
                                                            name="businessContact"
                                                            checked={businessContact === false ? true : false}
                                                            onChange={this.handleTypeChange}
                                                        />
                                                        <label htmlFor="Private"><Translate text={'private'} /></label>
                                                    </div>
                                                    <div className="radio icheck-success float-left ml-3">
                                                        <InputRadio
                                                            id="Business"
                                                            type="radio"
                                                            value="Business"
                                                            name="businessContact"
                                                            checked={businessContact === true ? true : false}
                                                            onChange={this.handleTypeChange}
                                                        />
                                                        <label htmlFor="Business"><Translate text={'business'} /></label>
                                                    </div>
                                                </div>
                                            </h3>
                                            <div className="form-row">
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.title) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'title'} /></label>
                                                    <ReactSelect
                                                        options={titles}
                                                        name={"title"}
                                                        placeholder={'select title'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={title}
                                                        classNamePrefix={`${this.state.errors["title"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.firstName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'firstName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'first name'}
                                                        className={`form-control ${this.state.errors["firstName"]}`}
                                                        name="firstName"
                                                        onChange={this.handleOnNameChange}
                                                        value={firstName}
                                                    />
                                                </div>
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.middleName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'middleName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'middle name'}
                                                        className={`form-control ${this.state.errors["middleName"]}`}
                                                        name="middleName"
                                                        onChange={this.handleOnNameChange}
                                                        value={middleName}
                                                    />
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.lastName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'lastName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'last name'}
                                                        className={`form-control ${this.state.errors["lastName"]}`}
                                                        name="lastName"
                                                        onChange={this.handleOnNameChange}
                                                        value={lastName}
                                                    />
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.preferredName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'preferredName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'preferred name'}
                                                        className={`form-control ${this.state.errors["preferredName"]}`}
                                                        name="preferredName"
                                                        onChange={this.handleOnNameChange}
                                                        value={preferredName}
                                                    />
                                                </div>


                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.phone) ? 'btn-disable' : ''}`}>
                                                    <DropDownMenu
                                                        text='+ add more'
                                                        keyVal="1001"
                                                        tIndex="-1"
                                                        className={`inputlink-addmore ${this.state.errors["phones"] ? 'label-error' : ''}`}
                                                        menu={phoneList}
                                                        handleChange={this.handleAddPhone}
                                                    >
                                                    </DropDownMenu>
                                                    <label ><Translate text={'mobile'} /></label>
                                                    <div className={`input-group country-code ${this.state.errors["phone"] ? 'phone_error' : ''}`}>

                                                        <div className="input-group-prepend">
                                                            <Dropdown className={`btn btn-outline-secondary`} >
                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                    <span id="spn-code-name" data-name={phoneCode}>
                                                                        {phoneCode && phoneCodes.find(item => item.value === phoneCode)
                                                                            ? phoneCodes.find(item => item.value === phoneCode).value + ' ' :
                                                                            'Code'
                                                                        }</span>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu as={CustomSearchMenu}
                                                                    className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                                                    ChildClass="pipeline-dropdown"
                                                                    xplacement="bottom-end"
                                                                >
                                                                    {
                                                                        !_.isEmpty(phoneCodes) && phoneCodes.length > 0 ?
                                                                            phoneCodes.map((rec, index) => {
                                                                                return (<Dropdown.Item key={index} className={`current-pipeline ${(phoneCode && rec.value === phoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleAddPhoneCode('phoneCode', rec.value)
                                                                                }}>
                                                                                    {rec.label}
                                                                                </Dropdown.Item>)
                                                                            })
                                                                            : (<></>)
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder={'phone'}
                                                            pattern="[0-9]*"
                                                            className={`form-control `}
                                                            name="phone"
                                                            onChange={this.onHandleTelephoneChange}
                                                            value={phone}
                                                        />
                                                    </div>
                                                </div>
                                                {!_.isEmpty(phones) && phones.map((phone, idx) => (
                                                    <div className={`form-group col-md-3 ${(isDisabled && existingContact.phones) ? 'btn-disable' : ''}`} key={idx}>
                                                        <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemovePhone(idx) }}>- <Translate text={'remove'} /></a>
                                                        <label >{phone.name} <Translate text={'phone'} /> </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${this.state.errors["phones"]}`}
                                                            placeholder={`${phone.name.toLowerCase()} phone `}
                                                            id={idx}
                                                            value={phone.value}
                                                            onChange={this.handlePhoneChange(idx)}
                                                        />
                                                    </div>
                                                ))}

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.email) ? 'btn-disable' : ''}`}>
                                                    <DropDownMenu
                                                        text='+ add more'
                                                        keyVal="1002"
                                                        tIndex="-1"
                                                        className={`inputlink-addmore ${this.state.errors["emails"] ? 'label-error' : ''}`}
                                                        menu={emailList}
                                                        handleChange={this.handleAddEmail}
                                                    >
                                                    </DropDownMenu>
                                                    <label ><Translate text={'email'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'email'}
                                                        className={`form-control ${this.state.errors["email"]}`}
                                                        name="email"
                                                        onChange={this.handleOnChange}
                                                        value={email}
                                                    />
                                                </div>

                                                {!_.isEmpty(emails) && emails.map((email, idx) => (

                                                    <div className={`form-group col-md-3 ${(isDisabled && existingContact.emails) ? 'btn-disable' : ''}`} key={idx}>
                                                        <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveEmail(idx) }}>- <Translate text={'remove'} /></a>
                                                        <label >{email.name} <Translate text={'email'} /> </label>
                                                        <InputText
                                                            type="text"
                                                            className={`form-control ${this.state.errors["emails"]}`}
                                                            id={idx}
                                                            placeholder={`${email.name.toLowerCase()} email `}
                                                            value={email.value}
                                                            onChange={this.handleEmailChange(idx)}
                                                        />
                                                    </div>
                                                ))}

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.gender) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'gender'} /></label>
                                                    <ReactSelect
                                                        options={genders}
                                                        name={"gender"}
                                                        placeholder={'select gender'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={gender}
                                                        classNamePrefix={`${this.state.errors["gender"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.dob) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'dateOfBirth'} /></label>
                                                    <div className="inner-addon right-addon">
                                                        {
                                                            isFromLayout
                                                                ?
                                                                <>
                                                                    <ReactDatePicker
                                                                        value={dob}
                                                                        name={'dob'}
                                                                        onChange={this.handleDateChange}
                                                                        format={this.dateFormat}
                                                                        placeholder='DD/MM/YYYY'
                                                                        onValueChange={this.handleDateValueChange.bind(this, 'dob')}
                                                                        className={`form-control ${this.state.errors["dob"]}`}
                                                                        //readOnly={true}
                                                                        isClearable={true}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <AntDatePicker
                                                                        value={dob}
                                                                        name={'dob'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'dob') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["dob"]}`}
                                                                    />
                                                                </>
                                                        }

                                                    </div>
                                                </div>




                                            </div>
                                        </div>



                                        <div className="add-popcontact-right ">

                                            <h3><Translate text={'address'} /></h3>


                                            <div className="form-row">
                                                <div className={`form-group col-md-6 ${(isDisabled && existingContact.address) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'address'} /> </label>
                                                    <AutoComplete
                                                        className={`form-control ${this.state.errors["address"]}`}
                                                        bindAddress={this.bindAddress}
                                                        types={['address']}
                                                        value={address}
                                                        placeholder={'search address'}
                                                        onChange={this.handleOnChange}
                                                        componentRestrictions={country ? { country: country.toLowerCase() } : { country: [] }}
                                                        name="address"
                                                    />
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.areaCode) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'areaCode'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'area/zip code'}
                                                        className={`form-control ${this.state.errors["areaCode"]}`}
                                                        value={areaCode}
                                                        name="areaCode"
                                                        onChange={this.handleOnChange}
                                                    />

                                                </div>
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.country) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'country'} /></label>
                                                    <ReactSelect
                                                        options={countries}
                                                        name={"country"}
                                                        placeholder={'select country'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={country}
                                                        classNamePrefix={`${this.state.errors["country"]} basic-select`}
                                                    >
                                                    </ReactSelect>


                                                </div>


                                            </div>

                                            <div className="form-row">
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.state) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'state'} /></label>
                                                    <ReactSelect
                                                        options={states}
                                                        name={"state"}
                                                        placeholder={'select state'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={state}
                                                        classNamePrefix={`${this.state.errors["state"]} basic-select`}
                                                    >
                                                    </ReactSelect>


                                                </div>
                                            </div>

                                        </div>


                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'companyInfo'} /></h3>

                                            <div className="form-row">
                                                <div className={`form-group col-md-6 ${(isDisabled && !_.isEmpty(existingContact.company)) ? 'btn-disable' : ''}`}>
                                                    {
                                                        !_.isEmpty(company) && company.documentID
                                                            ?
                                                            <div role="listbox" aria-expanded="false" className="ui top right pointing dropdown inputlink-addmore " tabIndex="-5" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleEditCompany(company.documentID);
                                                            }}>
                                                                <div className="text" role="alert" aria-live="polite" aria-atomic="true"><Translate text={'Edit Company'} /></div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }

                                                    <label ><Translate text={'companyName'} /></label>
                                                    <ReactAsyncSelect
                                                        options={[]}
                                                        name={"company"}
                                                        placeholder={'search company'}
                                                        search={true}
                                                        onChange={this.handleReactSelectChange}
                                                        value={!_.isEmpty(company) ? [{ value: company.documentID, label: company.name }] : []}
                                                        classNamePrefix={`${this.state.errors["company"]} basic-select`}
                                                        onCreate={this.handleCompanyShow}
                                                        clientID={this.selectedClientID}
                                                        groupID={this.selectedGroupID}
                                                        enableSharedContact={enableSharedContact}
                                                        dealersettings={dealersettings}
                                                    >
                                                    </ReactAsyncSelect>
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.designation) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'designation'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'designation'}
                                                        className={`form-control ${this.state.errors["designation"]}`}
                                                        name="designation"
                                                        onChange={this.handleOnNameChange}
                                                        value={designation}
                                                    />
                                                </div>

                                            </div>
                                        </div>


                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'moreInfo'} /></h3>

                                            <div className="form-row">

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.maritalStatus) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'maritalStatus'} /></label>
                                                    <ReactSelect
                                                        options={marital_status}
                                                        name={"maritalStatus"}
                                                        placeholder={'select marital status'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={maritalStatus}
                                                        classNamePrefix={`${this.state.errors["maritalStatus"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.nationality) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'nationality'} /></label>
                                                    <ReactSelect
                                                        options={nationalities}
                                                        name={"nationality"}
                                                        placeholder={'select nationality'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={nationality}
                                                        classNamePrefix={`${this.state.errors["nationality"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.language) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'preferredLanguage'} /></label>
                                                    <ReactSelect
                                                        options={languages}
                                                        name={"language"}
                                                        placeholder={'select language'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={language}
                                                        classNamePrefix={`${this.state.errors["language"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.contactMethod) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'preferredContactMethod'} /></label>
                                                    <ReactSelect
                                                        options={contact_method}
                                                        name={"contactMethod"}
                                                        placeholder={'select contact method'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={contactMethod}
                                                        classNamePrefix={`${this.state.errors["contactMethod"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className={`form-group col-md-6 ${(isDisabled && !_.isEmpty(existingContact.interests)) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'interests'} /></label>
                                                    <ReactMultiSelect
                                                        options={interested}
                                                        name={"interests"}
                                                        placeholder={'select interests'}
                                                        onChange={this.handleReactMultiSelectChange}
                                                        value={interests}
                                                        classNamePrefix={`${this.state.errors["interests"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.dependents) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'dependents'} /></label>
                                                    <ReactSelect
                                                        options={dependent}
                                                        name={"dependents"}
                                                        placeholder={'select dependents'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={dependents}
                                                        classNamePrefix={`${this.state.errors["dependents"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                    {/* <InputText
                                                        autoComplete="off"
                                                        placeholder={'dependents'}
                                                        className={`form-control ${this.state.errors["dependents"]}`}
                                                        name="dependents"
                                                        onChange={this.handleOnChange}
                                                        value={dependents}
                                                    /> */}
                                                </div>
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.driverName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'driverName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'driver name'}
                                                        className={`form-control ${this.state.errors["driverName"]}`}
                                                        name="driverName"
                                                        onChange={this.handleOnNameChange}
                                                        value={driverName}
                                                    />
                                                </div>
                                                <div className={`form-group col-md-3 mt-1 ${(isDisabled && _.isBoolean(existingContact.isVIP)) ? 'btn-disable' : ''}`}>
                                                    <label><Translate text={'vip'} /></label>
                                                    <div className="form-control">
                                                        {/* <label><Translate text={'vip'} /></label> */}
                                                        <span className={`switch switch-sm float-right ${this.state.errors["isVIP"] ? 'switch-error' : ''}`}>
                                                            <InputCheckBox
                                                                className={`switch ${this.state.errors["isVIP"]}`}
                                                                id="isVIP"
                                                                name="isVIP"
                                                                checked={isVIP ? true : false}
                                                                onChange={(e) => { this.handleCheckChange(e) }} />
                                                            <label htmlFor="isVIP"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={`form-group col-md-3 mt-1 ${(isDisabled && _.isBoolean(existingContact.banLoanVehicle)) ? 'btn-disable' : ''}`}>
                                                    <label><Translate text={'banLoanVehicle'} /></label>
                                                    <div className="form-control">
                                                        {/* <label><Translate text={'banLoanVehicle'} /></label> */}
                                                        <span className={`switch switch-sm float-right ${this.state.errors["banLoanVehicle"] ? 'switch-error' : ''}`}>
                                                            <InputCheckBox
                                                                className={`switch ${this.state.errors["banLoanVehicle"]}`}
                                                                id="banLoanVehicle"
                                                                name="banLoanVehicle"
                                                                checked={banLoanVehicle ? true : false}
                                                                onChange={(e) => { this.handleCheckChange(e) }} />
                                                            <label htmlFor="banLoanVehicle"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    tvModuleEnabled && (<div className={`form-group col-md-6 ${(isDisabled && existingContact.tvDisplayName) ? 'btn-disable' : ''}`}>
                                                        <label ><Translate text={'tvDisplayName'} /></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'tv display name'}
                                                            className={`form-control ${this.state.errors["tvDisplayName"]}`}
                                                            name="tvDisplayName"
                                                            onChange={this.handleOnNameChange}
                                                            value={tvDisplayName}
                                                        />
                                                    </div>)
                                                }

                                                {/* <div className="form-group col-md-1 mt-1">
                                                    <label ><Translate text={'vip'} /></label>
                                                    <span className={`switch switch-sm ml-2 ${this.state.errors["isVIP"] ? 'switch-error' : ''}`}>
                                                        <InputCheckBox
                                                            className={`switch ${this.state.errors["isVIP"]}`}
                                                            id="isVIP"
                                                            name="isVIP"
                                                            checked={isVIP ? true : false}
                                                            onChange={(e) => { this.handleCheckChange(e) }} />
                                                        <label htmlFor="isVIP"></label>
                                                    </span>
                                                </div>

                                                <div className="form-group col-md-3 mt-1">
                                                    <label ><Translate text={'banLoanVehicle'} /></label>
                                                    <span className={`switch switch-sm ml-2 ${this.state.errors["banLoanVehicle"] ? 'switch-error' : ''}`}>
                                                        <InputCheckBox
                                                            className={`switch ${this.state.errors["banLoanVehicle"]}`}
                                                            id="banLoanVehicle"
                                                            name="banLoanVehicle"
                                                            checked={banLoanVehicle ? true : false}
                                                            onChange={(e) => { this.handleCheckChange(e) }} />
                                                        <label htmlFor="banLoanVehicle"></label>
                                                    </span>
                                                </div> */}
                                            </div>

                                        </div>


                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'secondaryInfo'} /></h3>
                                            <div className="form-row">
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryTitle) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'title'} /></label>
                                                    <ReactSelect
                                                        options={titles}
                                                        name={"secondaryTitle"}
                                                        placeholder={'select title'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={secondaryTitle}
                                                        classNamePrefix={`${this.state.errors["secondaryTitle"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryFirstName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'secondaryFirstName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'first name'}
                                                        className={`form-control ${this.state.errors["secondaryFirstName"]}`}
                                                        name="secondaryFirstName"
                                                        onChange={this.handleOnNameChange}
                                                        value={secondaryFirstName}
                                                    />
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryMiddleName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'secondaryMiddleName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'middle name'}
                                                        className={`form-control ${this.state.errors["secondaryMiddleName"]}`}
                                                        name="secondaryMiddleName"
                                                        onChange={this.handleOnNameChange}
                                                        value={secondaryMiddleName}
                                                    />
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryLastName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'secondaryLastName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'last name'}
                                                        className={`form-control ${this.state.errors["secondaryLastName"]}`}
                                                        name="secondaryLastName"
                                                        onChange={this.handleOnNameChange}
                                                        value={secondaryLastName}
                                                    />
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryPreferredName) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'preferredName'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'preferred name'}
                                                        className={`form-control ${this.state.errors["secondaryPreferredName"]}`}
                                                        name="secondaryPreferredName"
                                                        onChange={this.handleOnNameChange}
                                                        value={secondaryPreferredName}
                                                    />
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryPhone) ? 'btn-disable' : ''}`}>
                                                    <DropDownMenu
                                                        text='+ add more'
                                                        keyVal="1003"
                                                        tIndex="-1"
                                                        className={`inputlink-addmore ${this.state.errors["secondaryPhones"] ? 'label-error' : ''}`}
                                                        menu={phoneList}
                                                        handleChange={this.handleAddSecPhone}
                                                    >
                                                    </DropDownMenu>
                                                    <label ><Translate text={'mobile'} /></label>
                                                    <div className={`input-group country-code ${this.state.errors["secondaryPhone"] ? 'phone_error' : ''}`}>

                                                        <div className="input-group-prepend">
                                                            <Dropdown className={`btn btn-outline-secondary`} >
                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                    <span id="spn-code-name" data-name={secondaryPhoneCode}>
                                                                        {secondaryPhoneCode && phoneCodes.find(item => item.value === secondaryPhoneCode)
                                                                            ? phoneCodes.find(item => item.value === secondaryPhoneCode).value + ' ' :
                                                                            'Code'
                                                                        }</span>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu as={CustomSearchMenu}
                                                                    className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                                                    ChildClass="pipeline-dropdown"
                                                                    xplacement="bottom-end"
                                                                >
                                                                    {
                                                                        !_.isEmpty(phoneCodes) && phoneCodes.length > 0 ?
                                                                            phoneCodes.map((rec, index) => {
                                                                                return (<Dropdown.Item key={index} className={`current-pipeline ${(secondaryPhoneCode && rec.value === secondaryPhoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleAddPhoneCode('secondaryPhoneCode', rec.value)
                                                                                }}>
                                                                                    {rec.label}
                                                                                </Dropdown.Item>)
                                                                            })
                                                                            : (<></>)
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>

                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'phone'}
                                                            className={`form-control`}
                                                            name="secondaryPhone"
                                                            onChange={this.onHandleTelephoneChange}
                                                            value={secondaryPhone}
                                                        />
                                                    </div>

                                                </div>
                                                {!_.isEmpty(secondaryPhones) && secondaryPhones.map((phone, idx) => (

                                                    <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryPhones) ? 'btn-disable' : ''}`} key={idx}>
                                                        <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveSecPhone(idx) }}>- <Translate text={'remove'} /></a>
                                                        <label >{phone.name} <Translate text={'phone'} /> </label>
                                                        <InputText
                                                            type="text"
                                                            className={`form-control ${this.state.errors["secondaryPhones"]}`}
                                                            placeholder={`${phone.name.toLowerCase()} phone `}
                                                            id={idx}
                                                            value={phone.value}
                                                            onChange={this.handleSecPhoneChange(idx)}
                                                        />
                                                    </div>
                                                ))}

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryEmail) ? 'btn-disable' : ''}`}>
                                                    <DropDownMenu
                                                        text='+ add more'
                                                        keyVal="1004"
                                                        tIndex="-1"
                                                        className={`inputlink-addmore ${this.state.errors["secondaryEmails"] ? 'label-error' : ''}`}
                                                        menu={emailList}
                                                        handleChange={this.handleAddSecEmail}
                                                    >
                                                    </DropDownMenu>
                                                    <label ><Translate text={'email'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'email'}
                                                        className={`form-control ${this.state.errors["secondaryEmail"]}`}
                                                        name="secondaryEmail"
                                                        onChange={this.handleOnChange}
                                                        value={secondaryEmail}
                                                    />
                                                </div>

                                                {!_.isEmpty(secondaryEmails) && secondaryEmails.map((email, idx) => (

                                                    <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryEmails) ? 'btn-disable' : ''}`} key={idx}>
                                                        <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveSecEmail(idx) }}>- <Translate text={'remove'} /></a>
                                                        <label >{email.name} <Translate text={'email'} /> </label>
                                                        <InputText
                                                            type="text"
                                                            className={`form-control ${this.state.errors["secondaryEmails"]}`}
                                                            id={idx}
                                                            placeholder={`${email.name.toLowerCase()} email `}
                                                            value={email.value}
                                                            onChange={this.handleSecEmailChange(idx)}
                                                        />
                                                    </div>
                                                ))}

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryGender) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'gender'} /></label>
                                                    <ReactSelect
                                                        options={genders}
                                                        name={"secondaryGender"}
                                                        placeholder={'select gender'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={secondaryGender}
                                                        classNamePrefix={`${this.state.errors["secondaryGender"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.secondaryDob) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'dateOfBirth'} /></label>
                                                    <div className="inner-addon right-addon">
                                                        {
                                                            isFromLayout
                                                                ?
                                                                <>
                                                                    <ReactDatePicker
                                                                        value={secondaryDob}
                                                                        name={'secondaryDob'}
                                                                        onChange={this.handleDateChange}
                                                                        format={this.dateFormat}
                                                                        placeholder='DD/MM/YYYY'
                                                                        onValueChange={this.handleDateValueChange.bind(this, 'secondaryDob')}
                                                                        className={`form-control ${this.state.errors["secondaryDob"]}`}
                                                                        //readOnly={true}
                                                                        isClearable={true}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <AntDatePicker
                                                                        value={secondaryDob}
                                                                        name={'secondaryDob'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'secondaryDob') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["secondaryDob"]}`}
                                                                    />
                                                                </>
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="add-popcontact-right ">
                                            <h3><Translate text={'marketing'} /></h3>
                                            <div className="form-row">
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.campaign) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'campaign'} /></label>
                                                    <ReactSelect
                                                        options={campaigns}
                                                        name={"campaign"}
                                                        placeholder={'select campaign'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={campaign}
                                                        classNamePrefix={`${this.state.errors["campaign"]} basic-select`}
                                                        menuPlacement='top'
                                                    >
                                                    </ReactSelect>

                                                </div>
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.origin) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'origin'} /></label>
                                                    <ReactSelect
                                                        options={origins}
                                                        name={"origin"}
                                                        placeholder={'select origin'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={origin}
                                                        classNamePrefix={`${this.state.errors["origin"]} basic-select`}
                                                        menuPlacement='top'
                                                    >
                                                    </ReactSelect>

                                                </div>
                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.optinEmail) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'optinEmail'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinEmail"}
                                                        placeholder={'select opt in email'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinEmail ? 'true' : 'false'}
                                                        classNamePrefix={`${this.state.errors["optinEmail"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.optinPhone) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'optinPhone'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinPhone"}
                                                        placeholder={'select opt in phone'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinPhone ? 'true' : 'false'}
                                                        classNamePrefix={`${this.state.errors["optinPhone"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.optinSMS) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'optinSMS'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinSMS"}
                                                        placeholder={'select opt in sms'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinSMS === false ? 'false' : (optinSMS ? 'true' : null)}
                                                        classNamePrefix={`${this.state.errors["optinSMS"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.optinPost) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'optinPost'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinPost"}
                                                        placeholder={'select opt in post'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={optinPost ? 'true' : 'false'}
                                                        classNamePrefix={`${this.state.errors["optinPost"]} basic-select`}
                                                        menuPlacement='top'
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.marketingOptinEmail) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'marketingOptinEmail'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"marketingOptinEmail"}
                                                        placeholder={'select opt in email'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={marketingOptinEmail === false ? 'false' : (marketingOptinEmail ? 'true' : null)}
                                                        classNamePrefix={`${this.state.errors["marketingOptinEmail"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                                <div className={`form-group col-md-3 ${(isDisabled && existingContact.marketingOptinSMS) ? 'btn-disable' : ''}`}>
                                                    <label ><Translate text={'marketingOptinSMS'} /></label>
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"marketingOptinSMS"}
                                                        placeholder={'select opt in sms'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={marketingOptinSMS === false ? 'false' : (marketingOptinSMS ? 'true' : null)}
                                                        classNamePrefix={`${this.state.errors["marketingOptinSMS"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        {
                            canModifyContact
                                ?
                                <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>

                                    <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null, null)}>
                                        <Translate text={'cancel'} />
                                    </button>
                                    <button type="button"
                                        className={`btn btn-primary float-right ${this.isImageUploading() ? 'form-disable' : ''}`}
                                        onClick={(e) => { e.preventDefault(); this.handleSubmit() }} >
                                        {(this.isLoading || isFinanceRequested) ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={isFinanceRequested ? 'Waiting for response' : 'save'} />
                                    </button>
                                </Modal.Footer>
                                :
                                <></>
                        }

                    </Modal>

                    {/*<ImageCrop
                    cropShow={this.state.cropShow}
                    handleClose={this.handleCropClose}
                    handleSelect={this.handleCropSelect}
                ></ImageCrop>*/}

                    <ImageCropHook
                        cropShow={this.state.cropShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>

                    <PopUpModal show={showTerms.show}>
                        <Terms
                            show={showTerms.show}
                            clsActive='overlay-modal active'
                            imagePath='contactDetails'
                            title={'customerConsent'}
                            isVerbalTerms={showTerms.isVerbal}
                            terms={this.state.fields.terms}
                            termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'customerConsent', language)}
                            isDisable={false}
                            handleClose={this.handleTermsClose}
                            dealersettings={dealersettings}

                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={showFinanceTerms.show}>
                        <Terms
                            show={showFinanceTerms.show}
                            clsActive='overlay-modal active'
                            imagePath='contactDetails'
                            isVerbalTerms={showFinanceTerms.isVerbal}
                            terms={this.state.fields.creditScoreTerms}
                            termsandCondition={enquiryHelper.mapScoreTemplate(CommonHelper.getTermsByField(dealersettings?.client, 'creditScoreTC', language), this.state.fields, dealersettings)}
                            title={'financeCreditScoreConsent'}
                            handleClose={this.handleFinanceTermsClose}
                            isDisable={false}
                            dealersettings={dealersettings}
                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={showContactEnqList.show}>
                        <EnquiryList
                            show={showContactEnqList.show}
                            title={'Existing enquiries for the selected contact'}
                            handleClose={this.handleCloseEnquiryList}
                            dealersettings={dealersettings}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                            contactID={showContactEnqList?.data?.documentID}
                            enquiryID={enquiryID}
                            //primaryEnquiry={showMergeEnqList.enquiry}
                            isMerge={true}
                        />
                    </PopUpModal>

                    <PopUpModal show={showCompany.show}>
                        <AddCompany
                            show={showCompany.show}
                            clsActive={showCompany.clsCompanyActive}
                            companyName={showCompany.companyName}
                            docID={showCompany.id}
                            selectedClientID={selectedClientID}
                            handleClose={this.handleCompanyClose}
                            title={'company'}
                            dealersettings={dealersettings}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                        >
                        </AddCompany>
                    </PopUpModal>

                    <PopUpModal show={showContactInfo.show}>
                        <ContactInfo
                            show={showContactInfo.show}
                            existingContact={showContactInfo.data}
                            newContact={this.state.fields}
                            isNewContact={this.isNewContact}
                            enquiryID={enquiryID}
                            //isNewEnquiry={isNewEnquiry ? isNewEnquiry : (enquiryID && _.isEmpty(docID) ? true : false)}
                            handleClose={this.handleXContactClose}
                            title={'Contact Details'}
                            dealersettings={dealersettings}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                        >
                        </ContactInfo>
                    </PopUpModal>

                    <PopUpModal show={showContacts}>
                        <Contacts
                            show={showContacts}
                            dealersettings={dealersettings}
                            isNewEnquiry={isNewEnquiry ? isNewEnquiry : (enquiryID && _.isEmpty(docID) ? true : false)}
                            handleContactClose={this.handleContactClose}
                            showAdd={false}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                            clientID={selectedClientID}
                            enquiryID={enquiryID}
                            enquiry={this.props.enquiry}
                        />
                    </PopUpModal>
                    <PopUpModal show={this.state.showReAuthentication}>
                        <ReAuthenticate
                            show={this.state.showReAuthentication}
                            handleClose={this.handleReAuthOpenClose}
                            dealersettings={dealersettings}
                        />
                    </PopUpModal>
                    <PopUpModal show={showDLScan}>
                        <DLScan
                            show={showDLScan}
                            dealersettings={dealersettings}
                            handleClose={this.handleDLScanClose}
                            digitalLicence={_digitallicence_nsw}
                        />
                    </PopUpModal>

                    <PopUpModal show={showTermsOption.show}>
                        <SignOptions
                            show={showTermsOption.show}
                            menu={sign_type.filter(e => e.value !== 'send')}
                            handleClose={this.handleTermsChange}
                            title='Customer Consent'
                            hideCancel={true}
                        />
                    </PopUpModal>

                    <PopUpModal show={showFinanceTermsOption.show}>
                        <SignOptions
                            show={showFinanceTermsOption.show}
                            menu={isFinanceRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleFinanceTermsChange}
                            title='Finance Credit Score Consent'
                            hideCancel={false}
                        />
                    </PopUpModal>
                    <PopUpModal show={this.state.imageViewer.show}>
                        <ImageViewer
                            {...this.state.imageViewer}
                            handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                        />
                    </PopUpModal>
                </>
                :
                <></>
        );
    }
}

export default AddContact;
