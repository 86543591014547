import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import { collectionData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ContentOverlay, PopUpModal, SidePanel, TableView, UpgradePlan, PpsrScan } from '../../components'
import Translate from '../../constants/translate';

import FleetHistoryReducer from '../testdrive/historyReducer'
import AddTradeIn from './add'
import AddStock from '../stock/add'
import CommonHelper from '../../services/common';
import ReAuthenticate from '../common/reAuthenticate';

import { enquiryStatus, tipStatus, tradeInStatus } from '../../services/enum'
import FilterPanel from './filter'
import { allTradeinFields } from './viewModel'
import { firestoreDB } from '../../services/helper';

// import { StageProvider, StageConsumer } from '../pipeline/provider'
// import QuickView from "../pipeline/quickview";
import QuickEnquiryInfo from '../pipeline/details/quickInfo'
import enquiryHelper from '../../services/enquiryHelper';
const MySwal = withReactContent(Swal);
const TradeinHistoryListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [fleetHistory, dispatch] = useReducer(FleetHistoryReducer, { TradeIn: [] })
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore, setHasMoreData] = useState([])
    const [tradeInFields, setTradeInFields] = useState(props.dealersettings.tradeInFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [fleetHistoryLoader, setLoader] = useState(true)
    const [tradeinOptionsDF, setTradeinOptionsDF] = useState([])

    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })

    const [alltradeinFields, setAllTradeInFields] = useState(allTradeinFields)
    const [modelShow, setModelShow] = useState({
        show: false,
        tradeinID: '',
        title: '',
        clsActive: ''
    })

    const [stockModal, setStockModal] = useState({
        show: false,
        ID: '',
        prefillData: null,
        title: '',
        clsActive: ''
    })

    const [ppsrModal, setPpsrModal] = useState({
        show: false,
        tradein: null
    })

    const [activeTab, setActiveTab] = useState('TradeIn')
    const [searchText, setSearchText] = useState(localStorage.tradeHistorySearchText ? localStorage.tradeHistorySearchText : '')
    const [intakeloader, setIntakeLoader] = useState(null)
    // const [titles, setTitles] = useState([])
    // const [languages, setLanguages] = useState([])
    // const [nationalities, setNationalities] = useState([])
    // const [campaigns, setCampaigns] = useState([])
    // const [origins, setOrigins] = useState([])

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    //const [testDriveCount, setTestDriveCount] = useState(0)
    const [pageNum, setPageNum] = useState(0)
    const pageLimit = 50

    //const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [dates, setDates] = useState([]);

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    // const _permissions = ((!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    // const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    // const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);

    const tradeinPlusEnabled = ((!_.isEmpty(_moduleSettings) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings.tradeinPlus) &&
        props.dealersettings.client.moduleSettings.tradeinPlus.enabled) ? true : false);
    const clientSettings = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings)) ? props.dealersettings.client.clientSettings : null;
    const liteVersion = (!_.isEmpty(clientSettings) && clientSettings.liteVersion) ? true : false;

    const tradeinProPermissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.tradeinProPermissions)) ? props.dealersettings.rolePermissions.tradeinProPermissions : null);

    const accessToShowIntakeAgreement = ((!_.isEmpty(tradeinProPermissions) && tradeinProPermissions.accessToShowIntakeAgreement) ? true : false);

    const ppsrEnabled = (!_.isEmpty(_moduleSettings?.ppsr) && _moduleSettings.ppsr.enabled && _moduleSettings.ppsr.active) ? true : false;

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        //console.log('localStorage.tradeInFilter', isFilterApplied, localStorage.tradeInFilter)
        if (localStorage.tradeInFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.tradeInFilter])

    useEffect(() => {
        setAllTradeInFields(_.map(alltradeinFields, function (obj) {
            return obj.value === 'settings' ? {
                ...obj,
                width: accessToShowIntakeAgreement ? 200 : 160,
            } : {
                ...obj
            }
        }))
    }, [])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'testDrives')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        let headerFields = tradeInFields;;
        let allHeaderFields = alltradeinFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [tradeInFields])

    useEffect(() => {
        if (_.isEmpty(tradeinOptionsDF))
            return
        tradeinOptionsDF.forEach(rec => {
            if (!alltradeinFields.some(e => e.value === rec.value))
                alltradeinFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        tradeInFields.forEach(rec => {
            if (!alltradeinFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setTradeInFields(_.differenceBy(tradeInFields, deleteids))
    }, [tradeinOptionsDF])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload(false)
            const setttings = props.dealersettings.client.settings;

            let _tradeinOptionsDF = [];
            setttings.tradeinOptionsDF && setttings.tradeinOptionsDF.forEach(doc => {
                _tradeinOptionsDF.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            // let _titles = [];
            // setttings.titles && setttings.titles.forEach((doc) => {
            //     _titles.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name
            //     });
            // });

            // let _languages = [];
            // props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
            //     _languages.push({
            //         value: doc.code,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name
            //     });
            // });

            // let _nationalities = [];
            // props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
            //     _nationalities.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name
            //     });
            // });

            // let _campaigns = [];
            // setttings.campaigns && setttings.campaigns.forEach(doc => {
            //     campaigns.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // let _origins = [];
            // setttings.origins && setttings.origins.forEach(doc => {
            //     origins.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // setCampaigns(_campaigns);
            // setOrigins(_origins);
            // setTitles(_titles);
            // setLanguages(_languages);
            // setNationalities(_nationalities)
            setDataload(true)
            setTradeinOptionsDF(_tradeinOptionsDF.filter(e => e.active === true));
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubTestDriveList && window.unSubTestDriveList();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }

        if (isFilterApplied || localStorage.tradeInFilter) {
            let _tradeInFilter = JSON.parse(localStorage.tradeInFilter);
            let _filter = Object.assign({}, _tradeInFilter.value);
            let _isWhere = false;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }
            _filter.clientID = props.dealersettings.client.id;
            _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
            //_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings);
            let _searchObject = {
                "type": "searchTradeins",
                "clientID": props.dealersettings.client.id,
                "filters": JSON.stringify(_filter),
                "sortOrder": `${_filter.dateType ? `tradein."${_filter.dateType}"` : 'tradein."addedDate"'} desc`,
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }
            //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings);
            // console.log('generic-searchData', isFilterApplied, pageNum, _searchObject, localStorage.tradeInFilter)
            const searchTestdrives = window.firebase.functions().httpsCallable('generic-searchData');
            searchTestdrives(_searchObject).then((response) => {
                //   console.log('generic-searchTestdrives', response);
                if (response.data.success && localStorage.tradeInFilter) {
                    onCollectionStocks(response.data);
                    //setTestDriveCount(response.data.total)
                }
                else {
                    setDataload(false)
                    setLoader(false)
                    setPaging(false)
                }
            });

            window.unSubTestDriveList && window.unSubTestDriveList();
        }
        else {
            let refTestDriveData = firestoreDB(props.dealersettings).firestore().collection('tradeins')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('isDeleted', '==', false)
                .orderBy('modifiedDate', 'desc')

            if (searchText && searchText.trim()) {
                refTestDriveData = refTestDriveData
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }

            if (hasMore.length > 0) {
                refTestDriveData = refTestDriveData
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                refTestDriveData = refTestDriveData
                    .limit(pageLimit)
            }

            window.unSubTestDriveList = refTestDriveData
                .onSnapshot(onCollectionUpdate);
        }


    }, [checkDataLoad])

    const onCollectionStocks = (querySnapshot) => {

        const _stocks = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _stocks.push(dataVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: _stocks,
            activetab: activeTab
        });

        setLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM(change.doc.data());
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        const _tdHistorys = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                _tdHistorys.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: _tdHistorys,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",
                data: actionType ? snapshotDoc : _tdHistorys,
                activetab: activeTab
            });
        }


        setDataload(false)
        setLoader(false)
        setPaging(false)
    }


    const dataMappingVM = (doc) => {
        return CommonHelper.convertTradeinVM(doc, { ...props })
        //return testdriveMappingVM(doc);
    }


    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)

        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ tradeInFields: fields }, { merge: true })
            .then(() => {
                setTradeInFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('tradeInFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }
    const handleEditFleet = (id, obj) => {

        setModelShow({
            show: true,
            tradeinID: obj.documentID,
            title: 'tradeIn',
            clsActive: 'overlay-modal active',
            canModifyEnquiry: obj.canModifyEnquiry,
            isDeivered: obj.isDeivered,
            enquiryID: obj.enquiryID,
            serviceID: obj.serviceID,
            tradeinProID: obj.tradeinProID
        });


    }

    const handleClose = (id) => {
        setModelShow({
            show: false,
            tradeinID: '',
            title: '',
            clsActive: '',
            canModifyEnquiry: false,
            isDeivered: false,
            enquiryID: '',
            serviceID: '',
            tradeinProID: ''
        });
        // if (!_.isEmpty(id)) {
        //     getFleetbyID(id);

        // }
    }

    const handleStockClose = (id) => {
        setStockModal({
            show: false,
            ID: '',
            prefillData: null,
            title: '',
            clsActive: ''
        });
    }

    const handleEditStock = (documentID) => {
        setStockModal({
            show: true,
            ID: documentID,
            prefillData: null,
            title: 'stock',
            clsActive: 'overlay-modal active'
        });
    }

    const handleAddStock = (prefillData) => {
        setStockModal({
            show: true,
            ID: '',
            prefillData: prefillData,
            title: 'addStock',
            clsActive: 'overlay-modal active'
        });
    }


    const getFleetbyID = (id) => {
        window.clistSubscription = collectionData(firestoreDB(props.dealersettings).firestore().collection('tradeins')
            .where('documentID', '==', id))
            .pipe(
                switchMap(dataSnapshot => {
                    const stockIds = _.uniq(dataSnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
                    return combineLatest(
                        of(dataSnapshot),
                        combineLatest(
                            stockIds.length > 0 ? stockIds.map(stockID =>
                                collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                                    map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([dataSnapshot, stockSnapshot]) => {
                    return dataSnapshot.map(snapDoc => {
                        return {
                            ...snapDoc,
                            stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
                        }
                    })
                })
            )
            .subscribe(datas => {
                onDocUpdate(dataMappingVM(datas[0]));
            });
    }

    const onDocUpdate = (_data) => {
        //console.log('onDocUpdate', data)
        if (!_.isEmpty(_data)) {
            const oldLog = fleetHistory[activeTab].find(item => item.documentID === _data.documentID);
            if (!_.isEmpty(oldLog)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: _data,
                    activetab: activeTab
                });
            }
            window.clistSubscription && window.clistSubscription.unsubscribe()
        }
    }

    const handleActionClick = (id, obj) => {

        // return (
        //     <div
        //         style={{ cursor: 'pointer' }}
        //         className="mini-button"
        //         onClick={(e) => {
        //             e.preventDefault();

        //             if (!readOnlyEnquiryView)
        //                 handleEditFleet(id, obj);
        //         }}>

        //         {readOnlyEnquiryView ? <><i className="ico icon-lock mr-1"></i></> : <><i className="ico icon-more"></i></>}
        //     </div>
        // )
        let _tradein = obj.objDoc ? obj.objDoc : obj;
        let _client = _.find(props.dealersettings?.clients, { id: _tradein?.clientID })
        let managerWorkflow = _client?.clientSettings?.managerWorkflow ? true : false;
        const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(_tradein?.status) ? true : false;
        const _isTradeinDone = _tradein?.status === tipStatus.WON ? true : false;
        return (
            <div className="fleet-btn-loan  reassign-fix" style={{ float: 'left' }}>
                <div className="tradein-view mr-2 ml-1">
                    {
                        _tradein.linkStatus === 'sent'
                            ?
                            <a href="#" title="Link sent to customer." className="mini-button" onClick={(e) => {
                                e.preventDefault();
                            }}>
                                <i className="ico icon-multi-check" style={{ fontSize: `10px` }}></i>
                            </a>
                            :
                            <a href="#" title="View Trade-In" className="mini-button" onClick={(e) => {
                                e.preventDefault();
                                handleEditFleet(id, obj);
                            }}>
                                <i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-tradein")} style={{ fontSize: `10px` }}></i>
                            </a>
                    }

                </div>
                {
                    accessToShowIntakeAgreement
                        ?
                        <>
                            <div className="tradein-view mr-2 ml-1">  {
                                (_isTradeinDone && _tradein.tradeinProID) ?
                                    <>
                                        <a href="#" title="Purchase Intake Agreement" className="mini-button btn-green" onClick={(e) => {
                                            e.preventDefault();
                                            handleIntakeAgreement(_tradein)
                                        }}>
                                            {intakeloader === id ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className="ico icon-waiver"></i>}

                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="#" title="Purchase Intake Agreement" className="mini-button" onClick={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <i className="ico icon-lock"></i>
                                        </a>
                                    </>
                            }
                            </div>
                        </>
                        :
                        <>
                        </>
                }
                {
                    managerWorkflow
                        ?
                        <>
                            <div className="tradein-view mr-2 ml-1">
                                {
                                    _tradein?.status === tipStatus.COMPLETED && _tradein.tradeinProID
                                        ?
                                        <>
                                            <a href="#" title="Add Final Price" className="mini-button btn-mini-secondary" onClick={(e) => {
                                                e.preventDefault();
                                                saveTradeinProField(_tradein);
                                            }}>
                                                {!_tradein?.offerPrice ? <i className={"ico icon-finance"}></i> : <i className={"ico icon-edit"}></i>}
                                            </a>
                                        </>
                                        :
                                        <>
                                            <a href="#" title="Final Price" className="mini-button" onClick={(e) => {
                                                e.preventDefault();
                                            }}>
                                                <i className="ico icon-lock"></i>
                                            </a>
                                        </>
                                }
                            </div>

                            <div className="tradein-view mr-2 ml-1">
                                {
                                    _tradein.status === tradeInStatus.WON && _tradein.enquiryID && (_tradein.sent === true || !tradeinPlusEnabled)
                                        ?
                                        <>
                                            {
                                                _tradein.stockID
                                                    ?
                                                    <a href="#" title="'View Stock" className="mini-button" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEditStock(_tradein.stockID);
                                                    }}>
                                                        <i className="ico icon-zoom"></i>
                                                    </a>
                                                    :
                                                    <>
                                                        {
                                                            props.dealersettings?.rolePermissions?.permissions?.addStock
                                                                ?
                                                                <a href="#" title="Add To Stock" className="mini-button btn-mini-primary" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleAddStock(_tradein);
                                                                }}>
                                                                    <i className="ico icon-add"></i>
                                                                </a>
                                                                :
                                                                <a href="#" title="Add stock permission disable" className="mini-button" onClick={(e) => {
                                                                    e.preventDefault();
                                                                }}>
                                                                    <i className="ico icon-lock"></i>
                                                                </a>
                                                        }
                                                    </>


                                            }
                                        </>
                                        :
                                        <>
                                            <a href="#" title="Change status 'Won' to enable" style={{ width: '100px', cursor: 'not-allowed' }} className="mini-button" onClick={(e) => {
                                                e.preventDefault();
                                            }}>
                                                <i className="ico icon-lock"></i>
                                            </a>
                                        </>
                                }
                            </div>

                        </>
                        :
                        <>
                            {
                                _tradein.status === tradeInStatus.WON && _tradein.enquiryID && (_tradein.sent === true || !tradeinPlusEnabled)
                                    ?
                                    <>
                                        {
                                            _tradein.stockID
                                                ?
                                                <button type="submit" className="btn btn-sm btn-secondary float-left" onClick={(e) => {
                                                    e.preventDefault();
                                                    handleEditStock(_tradein.stockID);
                                                }}><Translate text={'View Stock'} /></button>
                                                :
                                                <>
                                                    {
                                                        props.dealersettings?.rolePermissions?.permissions?.addStock
                                                            ?
                                                            <button type="submit" className="btn btn-sm btn-primary float-left" onClick={(e) => {
                                                                e.preventDefault();
                                                                handleAddStock(_tradein);
                                                            }}><Translate text={'Add To Stock'} /></button>
                                                            :
                                                            <div className="filter-item-btn">
                                                                <a href="#" title="Add stock permission disable" style={{ width: '100px', cursor: 'not-allowed' }} onClick={(e) => { e.preventDefault(); }}>
                                                                    <i className="ico icon-lock"></i>
                                                                </a>
                                                            </div>
                                                    }
                                                </>


                                        }
                                    </>
                                    :
                                    <>

                                        <div className="filter-item-btn">
                                            <a href="#" title="Change status 'Won' to enable" style={{ width: '100px', cursor: 'not-allowed' }} onClick={(e) => { e.preventDefault(); }}>
                                                <i className="ico icon-lock"></i>
                                            </a>

                                        </div>
                                    </>
                            }
                        </>
                }

            </div>
        )


    }

    const saveTradeinProField = async (_tradeIN) => {

        let tradeinproid = _tradeIN?.tradeinProID;
        if (tradeinproid) {
            let __promise = []
            __promise.push(firestoreDB(props.dealersettings).firestore().doc(`tradeinPro/${tradeinproid}`).get());
            const __snapsnot = await Promise.all(__promise);
            let _tradePro = null
            //let _tradeproOffers = [];
            __snapsnot.forEach((snap) => {
                if (snap.exists) {
                    _tradePro = snap.data();
                }
            })

            //console.log('_tradePro', _tradePro)
            //console.log('_tradeproOffers', _tradeproOffers)

            //if (!_tradePro.finalPrice) {
            let _finalPrice = null;
            let _finalValue = _tradePro?.finalPrice ? _tradePro.finalPrice : _tradeIN?.offerPrice ? _tradeIN.offerPrice : null;
            //console.log('begin')

            // let result = await Swal.fire({
            //     title: 'Enter Final Price',
            //     text: _tradePro.valuerPrice ? `The valuer price of the selected offer is ${CommonHelper.formatCurrency(currencySymbol, _tradePro.valuerPrice)}` : '',
            //     input: 'number',
            //     inputPlaceholder: 'Enter final price',
            //     inputValue: _finalValue,
            //     showCancelButton: true,
            //     confirmButtonText: 'Submit',
            //     showLoaderOnConfirm: true,
            //     allowOutsideClick: false,
            //     allowEscapeKey: false,
            //     preConfirm: (inputValue) => {
            //         if (inputValue) {
            //             // Process the input value here
            //             return inputValue;
            //         }
            //         else {
            //             Swal.showValidationMessage('Final price cannot be empty');
            //         }
            //     },
            // })

            let result = await MySwal.fire({
                title: 'Enter Final Price',
                html: (
                    <>
                        <label>{_tradePro.valuerPrice ? `The valuer price of the selected offer is ${CommonHelper.formatCurrency(currencySymbol, _tradePro.valuerPrice)}` : ''}</label>
                        <NumberFormat
                            id={'currency-input-list'}
                            decimalScale={2}
                            thousandSeparator={true}
                            allowNegative={true}
                            prefix={currencySymbol}
                            placeholder={currencySymbol}
                            className={`form-control`}
                            onValueChange={(e, name) => { _finalPrice = e.floatValue }}
                            value={_finalValue}
                        />
                    </>
                ),
                showCancelButton: true,
                confirmButtonText: 'Submit',
                preConfirm: () => {
                    const inputValue = document.getElementById('currency-input-list').value;
                    return inputValue ? inputValue : MySwal.showValidationMessage('Final price cannot be empty!');
                }
            })

            //console.log('end', result)
            if (result.isConfirmed) {
                let _msg = '';

                _finalPrice = Number(result.value.replace(/[^0-9.]/g, '')) || _finalPrice
                let objData = {};
                objData.finalPrice = _finalPrice;
                objData.finalizedBy = props.dealersettings ? props.dealersettings.id : '';
                objData.finalizedDate = window.firebase.firestore.Timestamp.now();
                objData.finalizedFrom = 'web';
                if (!_.isEmpty(_tradePro.tradein))
                    objData.tradein = {
                        ..._tradePro.tradein,
                        'offerPrice': _finalPrice
                    }
                if (_tradePro.finalPrice)
                    _msg = `Updated the final price from ${CommonHelper.formatCurrency(currencySymbol, _tradePro.finalPrice)} to ${CommonHelper.formatCurrency(currencySymbol, _finalPrice)}`
                else
                    _msg = `The evaluation has been approved, setting the final price at ${CommonHelper.formatCurrency(currencySymbol, _finalPrice)}`

                objData = handleMoveStageForStatusChange('finalPrice', objData, _tradePro);

                objData.modifiedBy = localStorage.uid;
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';

                const batch = firestoreDB(props.dealersettings).firestore().batch();

                batch.set(firestoreDB(props.dealersettings).firestore().doc(`tradeinPro/${tradeinproid}`), objData, { merge: true });
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`tradeins/${_tradeIN.documentID}`), {
                    offerPrice: _finalPrice,
                    modifiedBy: objData.modifiedBy,
                    modifiedDate: objData.modifiedDate,
                    modifiedFrom: 'web',
                }, { merge: true });

                batch.commit().then(snapshot => {
                    toast.notify('Appraisal updated successfully.', { duration: 2000 });
                    if (_msg) {
                        let _objLogData = {
                            tradeinProID: tradeinproid,
                            offerID: _tradePro.offer?.documentID ? _tradePro?.offer?.documentID : null,
                            projectId: _tradePro?.projectId ? _tradePro?.projectId : null,
                            message: _msg
                        }
                        CommonHelper.saveTradeInProLog(props.dealersettings, _objLogData);
                    }
                }).catch((error) => {
                    console.error("Error updating document: ", error);
                });
            }
            //}
        }
    };

    const handleMoveStageForStatusChange = (_status, state, fields) => {
        let setttings = props.dealersettings.client?.tradeinPro;
        const { stageHistory } = fields;

        let _selPipeline = fields.pipeline && !_.isEmpty(setttings) && setttings.allPipelines.filter(e => e.value === fields.pipeline)[0];
        let statusSettings = _selPipeline && _selPipeline.statusSettings;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], stageHistory);
            let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else
            return state;
    }

    const handleDetailsClick = (e, id, tradein, tblprops) => {
        e.preventDefault();

        if (tblprops.columnKey === 'contactName') {
            let _contactID = tradein.contactID ? tradein.contactID : (!_.isEmpty(tradein?.enquiry?.contactID) ? tradein.enquiry.contactID : (!_.isEmpty(tradein?.enquiry?.contact) ? tradein.enquiry.contact.documentID : null));
            if (tradein.enquiryID) {
                sidepanelOpen(tradein.enquiryID)
            }
            else if (_contactID) {
                props.history.push(`/contacts/details/${_contactID}?back-url=/tradein/history`)
            }
            else if (tradein.linkStatus !== 'sent')
                handleEditFleet(id, tradein);

        }
        else if (tradein.linkStatus !== 'sent')
            handleEditFleet(id, tradein);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
        setDataload(true)
        setLoader(true)
        setHasMoreData([]);
    }

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const excelDownload = async () => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        const { dealersettings } = props;
        let _tradeInFilter = localStorage.tradeInFilter ? JSON.parse(localStorage.tradeInFilter) : {};
        let _filter = Object.assign({}, _tradeInFilter.value);

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }
        _filter.clientID = props.dealersettings.client.id;
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();

        let _searchObject = {
            "type": "searchTradeins",
            "clientID": props.dealersettings.client.id,
            "filters": JSON.stringify(_filter),
            "sortOrder": `${_filter.dateType ? `tradein."${_filter.dateType}"` : 'tradein."addedDate"'} desc`,
            "pageNum": 0,
            "pageLimit": 10000
        }

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )

        //console.log('generic-searchData', isFilterApplied, pageNum, _searchObject, localStorage.tradeInFilter)
        const searchTestdrives = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchTestdrives(_searchObject);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = dataMappingVM(doc);
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from trade-in history',
                type: 'excelExport',
                subType: 'tradeins',
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }

    }


    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const handleSearchText = (val) => {
        setSearchText(val);
        if (!_.isEmpty(_.trim(val))) {
            if (localStorage.tradeInFilter) {
                let _tradeInFilter = JSON.parse(localStorage.tradeInFilter);
                let _filter = Object.assign({}, _tradeInFilter.value);
                _filter['keywords'] = _.trim(val);
                _tradeInFilter.value = _filter;
                handleApplyFilter(_tradeInFilter);
            }
            else {
                let _tradeInFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
                handleApplyFilter(_tradeInFilter);
            }
        }
        else {
            handleClearFilter();
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('tradeInFilter', JSON.stringify(_filter));
            setFilterApplied(true);
            setLoader(true)
            setDataload(true)
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.tradeInFilter)) {
            localStorage.removeItem('tradeInFilter');
            localStorage.tradeHistorySearchText = '';
            setFilterApplied(false);
            setLoader(true)
            setDataload(true)
            setPaging(false);
            setHasMoreData([]);
            setPageNum(0);
            setSearchText('');
        }
    }

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }

    const handleIntakeAgreement = async (_tradein) => {
        if (intakeloader) return;
        const { dealersettings } = props;
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
            text: 'Do you want to view intake agreement.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then(result => {
            if (result.value) {
                viewIntakeAgreement(_tradein);
            }

        });
    }

    const viewIntakeAgreement = async (tradein) => {

        if (ppsrModal.show) setPpsrModal({ show: false, tradein: null })

        if (ppsrEnabled) {
            setIntakeLoader(tradein?.documentID)

            let response = await enquiryHelper.getIntakeURL(tradein, props.dealersettings)
            if (response?.success && response?.data) {
                window.open(response.data, '_blank');
                loaderOff();
                //toast.closeAll();
            }
            else if (response?.success === false && response?.type === 'info') {
                Swal.fire((response?.title || ''), (response?.message || ''), 'info');
                loaderOff();
                //toast.closeAll();
            }
            else {
                console.log('response', response);
                loaderOff();
            }
        }
        else {
            loaderOff(); Swal.fire('PPSR document not found.', 'Generate a PPSR document to proceed', 'info'); return;
        }
    }

    const viewIntakeAgreement_deprecated = async (tradein) => {
        setIntakeLoader(tradein?.documentID)
        if (ppsrModal.show) setPpsrModal({ show: false, tradein: null })

        let response = await enquiryHelper.getIntakeURL(tradein, props.dealersettings)
        if (response?.success && response?.data) {
            window.open(response.data, '_blank');
            loaderOff();
        }
        else {
            if (response?.type === 'ppsr') {
                if (!ppsrEnabled) { loaderOff(); Swal.fire('PPSR document not found.', 'Generate a PPSR document to proceed', 'info'); return; }

                Swal.fire({
                    title: 'PPSR document not found.',
                    text: 'Would you like to generate it to proceed?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                    cancelButtonText: CommonHelper.showLocale(props, 'No'),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then(result => {
                    if (result.value) {
                        setPpsrModal({ show: true, tradein: tradein })
                    }
                    else { loaderOff(); }

                });
            }
            else { loaderOff(); }
        }
    }

    const handleClosePPSR = () => {
        let _id = ppsrModal?.tradein?.documentID
        const oldData = fleetHistory[activeTab].find(item => item.documentID === _id);
        viewIntakeAgreement(!_.isEmpty(oldData) ? oldData : ppsrModal?.tradein)
        setPpsrModal({ show: false, tradein: null })
    }

    const loaderOff = () => {
        setIntakeLoader(null);
    }

    return (
        <>
            {liteVersion ? <UpgradePlan /> : <></>}

            {intakeloader ? <div className="lds-roller-loader text-center add-message-lds ">
                <div className="lds-roller-loader-inner ">
                    <div role="status" className="spinner-border loader-primary"></div>
                    <p className="text-muted mt-3">generating intake agreement...</p>
                </div>
            </div> : <></>}
            <div className={`middle-wrapper ${liteVersion ? 'form-lite' : ''}`}>

                <FilterPanel
                    customFilters={customFilters}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    handleApplyFilter={handleApplyFilter}
                    handleClearFilter={handleClearFilter}
                    csvHeader={csvHeader}
                    datalist={fleetHistory[activeTab]}
                    searchText={searchText}
                    handlesearchText={(val) => {
                        handleSearchText(val);
                        // setSearchText(val)
                        // setDataload(true)
                        // setHasMoreData([]);
                        // setFilterApplied(false);
                        // setLoader(true);
                        // setPaging(false);
                        // setPageNum(0);
                        // if (!_.isEmpty(localStorage.tradeInFilter))
                        //     localStorage.removeItem('tradeInFilter');

                    }}
                    //testDriveCount={testDriveCount}
                    csvData={csvData}
                    excelDownload={excelDownload}
                    csvBtn={csvBtn}
                    dateRange={dateRange}
                    handleDateRangeChange={handleDateRangeChange}
                    handleSetDates={setDates}
                />
                <div className="calendar-panel">
                    {
                        fleetHistoryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                            fleetHistory[activeTab] && fleetHistory[activeTab].length > 0 ? (
                                <div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={fleetHistory[activeTab] ? fleetHistory[activeTab] : []}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={alltradeinFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={
                                            (tradeInFields && tradeInFields.length > 0) ? tradeInFields : alltradeinFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                        }
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailsClick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        activityTab={activeTab}
                                        displayByValue={true}
                                    />
                                </div>
                            ) : (
                                <div className="common-table">
                                    <div className="text-center p-5">
                                        <p><Translate text={'No record found'} /></p>

                                    </div>
                                </div>
                            )
                    }


                </div>


                <PopUpModal show={modelShow.show}>
                    <AddTradeIn
                        docID={modelShow.tradeinID}
                        show={modelShow.show}
                        clsActive={modelShow.clsActive}
                        handleClose={handleClose}
                        title={modelShow.title}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                        enquiryID={modelShow.enquiryID}
                        serviceID={modelShow?.serviceID}
                        tradeinProID={modelShow.tradeinProID}
                        isDeivered={modelShow.canModifyEnquiry ? modelShow.isDeivered : true}
                    >
                    </AddTradeIn>
                </PopUpModal>

                <PopUpModal show={stockModal.show}>
                    <AddStock
                        docID={stockModal.ID}
                        show={stockModal.show}
                        clsActive={stockModal.clsActive}
                        handleClose={handleStockClose}
                        title={stockModal.title}
                        prefillTradeData={stockModal.prefillData}
                        enableSale={true}
                        enableTestDrive={false}
                        enableLoan={false}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                    >
                    </AddStock>
                </PopUpModal>

                <PopUpModal show={showReAuthentication}>
                    <ReAuthenticate
                        show={showReAuthentication}
                        handleClose={handleReAuthOpenClose}
                        dealersettings={props.dealersettings}
                        privacy='file'
                    />
                </PopUpModal>

                <PopUpModal show={ppsrModal.show}>
                    <PpsrScan
                        show={ppsrModal.show}
                        clsActive='overlay-modal active'
                        regNo={ppsrModal?.tradein?.regNo}
                        regState={ppsrModal?.tradein?.regState}
                        tradeinProID={ppsrModal?.tradein?.tradeinProID}
                        clientID={ppsrModal?.tradein?.clientID}
                        tradeinID={ppsrModal?.tradein?.documentID}
                        chassisNo={ppsrModal?.tradein?.chassisNo}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                        handleClose={handleClosePPSR}
                    >
                    </PpsrScan>
                </PopUpModal>

                {/* <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
                    <StageConsumer>
                        {() => (

                            <SidePanel
                                clsActive={showpanel.clsActive}
                                clsName={showpanel.clsName}
                                sidepanelClose={sidepanelClose}
                                title=''
                            >
                                <QuickView {...props}
                                    enquiryid={showpanel.enquiryid}
                                    sidepanelClose={sidepanelClose}
                                />
                            </SidePanel>
                        )}
                    </StageConsumer>
                </StageProvider> */}
                <QuickEnquiryInfo
                        {...props}
                        enquiryid={showpanel.enquiryid}
                        clsActive={showpanel.clsActive}
                        clsName={showpanel.clsName}
                        sidepanelClose={sidepanelClose}
                    />

            </div>
        </>)
}

export default TradeinHistoryListView;