import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import { ContentOverlay, PopUpModal, BarChart, PieChart, LineChart, NoDataPieChart } from "../../components";
import CommonHelper from '../../services/common'
import _images from '../../images'
import { reportGroupBy, reportDynColumns, defaultFilters, filterOptions, reportFilters } from './viewModel'
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import AddReport from './addReport'
import ServiceList from '../dashboardService/serviceList'
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
import ReactExport from 'react-export-excel-xlsx-fix';
import ScheduleReport from "../reports/scheduleReport";
import CustomFilters from '../reports/customFilters';
import uuid from 'react-uuid'
import cronstrue from 'cronstrue';
import NumberFormat from 'react-number-format';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styles = StyleSheet.create({
    page: {
        padding: '10mm'
    },
    header: {
        width: '100%',
        borderBottomColor: '#4466f2',
        borderBottomWidth: 3,
        display: 'inline-block',
        paddingBottom: 10,
        float: 'left',
        paddingTop: 30
    },
    headerwrapLeft: {
        width: '60%',
        float: 'left',
        position: 'absolute',
        left: 0,
        paddingTop: 15
    },
    headerwrapRight: {
        width: '40%',
        position: 'absolute',
        right: 0,
        textAlign: 'right'
    },
    reportImage: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 600
    },
    image: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 500,
        marginTop: 10
    },
    reporHeader: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        paddingTop: 10,
    },
    footer: {
        width: '100%',
        paddingTop: 8,
        marginTop: 0,
        display: 'inline-block'
    },
    footerCpy: {
        width: '100%',
        fontSize: 8,
        borderTopColor: '#d3d9e6',
        borderTopWidth: 1,
        paddingTop: 8,
        marginTop: 8
    }
});

const ViewDynamicReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 695), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 200) })
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [report, setReport] = useState(props.report);
    const [progressReport, setProgressReport] = useState([])
    const [sortprogressReport, setSortProgressReport] = useState([])
    const [refreshData, setRefreshData] = useState(false)
    const [sortConfig, setSortConfig] = useState();

    const [showModel, setShowmodel] = useState(false)
    const [modelShow, setModelShow] = useState({
        service: false,
        sheduleReport: false
    })
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)

    const [csvData, setCSVData] = useState([])
    const [sheetData, setSheetData] = useState([])

    const [chartData, setChartData] = useState()
    const [chartImg, setChartImg] = useState()
    const [filterShow, setShow] = useState(true)
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [counts, setCounts] = useState([])
    const [errorFilters, setErrorFilter] = useState({});
    const [schedueReport, setSchedeReport] = useState({});
    const [dynReportGroupBy, setReportGroupBy] = useState(reportGroupBy);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 200),
                tblWidth: (window.innerWidth - 695)
            })
        }
        //document.addEventListener("mousedown", handleClick);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            //document.removeEventListener("mousedown", handleClick);
        }
    }, [])
    useEffect(() => {
        setReport(props.report);
    }, [props.report])

    useEffect(() => {
        let _leadSource = []
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.services &&
            props.dealersettings.client.services.pipelines
            && props.dealersettings.client.services.pipelines.find(a => a.value === props.report.pipeline[0])) {
            props.dealersettings.client.services.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    _leadSource.push({
                        label: `${rec.name} (Stage)`,
                        searchlabel: `${rec.name} Stage`,
                        value: `stage-${rec.value}`,
                        name: `${rec.name} (Stage)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Stage',
                    })
                }
            })
        }

        setReportColumns([...reportColumns.filter(a => _.isEmpty(a.sourceType)), ..._leadSource]);
        setRefreshData(true)
    }, [])


    useEffect(() => {
        if (props.activeKey === props.report.documentID)
            setRefreshData(true)
    }, [props.report.columns])


    // useEffect(() => {
    //     if (props.activeKey === props.report.documentID)
    //         setRefreshData(true)
    // }, [props.report.objFilters])

    useEffect(() => {
        if (_.isEmpty(report))
            return;

        const refScheduleReport = window.firebase.firestore().doc(`schedular/${report.documentID}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])


    useEffect(() => {
        if (_.isEmpty(sortprogressReport) || props.report.reportType !== 'table')
            return

        let _headers = [];
        let _headerStyle = {
            style:
            {
                font: {
                    sz: 13,
                    bold: true
                },
                fill: { patternType: "solid", fgColor: { rgb: "FFdce6f1" } }
            }
        }
        let _cellStyle = { style: { font: { sz: 11 } } };
        let _numStyle = { style: { font: { sz: 11 } }, numFmt: "0" };
        let _footerStyle = {
            style:
            {
                font: {
                    sz: 13,
                    bold: true
                },
                fill: { patternType: "solid", fgColor: { rgb: "FFdce6f1" } }
            }
        };
        _headers.push(
            {
                title: CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value'),
                dynValue: 'displayName',
                dynWidth: 10,
                ..._headerStyle
            })

        props.report.columns.forEach((val) => {
            var cols = reportColumns.find(item => item.value === val)
            if (cols) {
                _headers.push({ title: cols.name, dynValue: val, dynWidth: cols.name.length, ..._headerStyle })
            }
            else {
                _headers.push({ title: val, dynValue: val, dynWidth: val.toString().length, ..._headerStyle })
            }
        })


        var exportData = []
        var totals = {
            totalJobs: _.sumBy(sortprogressReport, "totalJobs"),
            cancelled: _.sumBy(sortprogressReport, "cancelled"),
            scheduled: _.sumBy(sortprogressReport, "scheduled"),
            checkIn: _.sumBy(sortprogressReport, "checkIn"),
            technicianRequested: _.sumBy(sortprogressReport, "technicianRequested"),
            partsRequested: _.sumBy(sortprogressReport, "partsRequested"),
            workshopRequested: _.sumBy(sortprogressReport, "workshopRequested"),
            advisorRequested: _.sumBy(sortprogressReport, "advisorRequested"),
            ownerRequested: _.sumBy(sortprogressReport, "ownerRequested"),
            pendingjobs: _.sumBy(sortprogressReport, "pendingjobs"),
            ownerApproved: _.sumBy(sortprogressReport, "ownerApproved"),
            ownerDeclined: _.sumBy(sortprogressReport, "ownerDeclined"),
            completed: _.sumBy(sortprogressReport, "completed"),
            jobDuration: (_.sumBy(sortprogressReport, `totaljobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totaljobDuration), props.dealersettings.client) : '00:00:00'),
            overalljobDuration: (_.sumBy(sortprogressReport, `totaloveralljobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totaloveralljobDuration), props.dealersettings.client) : '00:00:00'),
            maxjobDuration: (_.sumBy(sortprogressReport, `totalmaxjobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalmaxjobDuration), props.dealersettings.client) : '00:00:00'),
            minjobDuration: (_.sumBy(sortprogressReport, `totalminjobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalminjobDuration), props.dealersettings.client) : '00:00:00'),
            overallserviceDuration: (_.sumBy(sortprogressReport, `totaloverallserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totaloverallserviceDuration), props.dealersettings.client) : '00:00:00'),
            serviceDuration: (_.sumBy(sortprogressReport, `totalserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalserviceDuration), props.dealersettings.client) : '00:00:00'),
            maxserviceDuration: (_.sumBy(sortprogressReport, `totalmaxserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalmaxserviceDuration), props.dealersettings.client) : '00:00:00'),
            minserviceDuration: (_.sumBy(sortprogressReport, `totalminserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalminserviceDuration), props.dealersettings.client) : '00:00:00'),
            totalLabourCost: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "totalLabourCost") + Number.EPSILON) * 100) / 100}`,
            totalPartsCost: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "totalPartsCost") + Number.EPSILON) * 100) / 100}`,
            totalServicePartsCost: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "totalServicePartsCost") + Number.EPSILON) * 100) / 100}`,
            totalServicePackagesCost: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "totalServicePackagesCost") + Number.EPSILON) * 100) / 100}`,
            totalServiceCost: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "totalServiceCost") + Number.EPSILON) * 100) / 100}`,
            totalRecommCost: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "totalRecommCost") + Number.EPSILON) * 100) / 100}`,
            totalDeclinedCost: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "totalDeclinedCost") + Number.EPSILON) * 100) / 100}`,
            recomApproved: _.sumBy(sortprogressReport, "recomApproved"),
            recomDeclined: _.sumBy(sortprogressReport, "recomDeclined"),
            ...getLeadsByEnquiryTypeTotal(sortprogressReport)
        };

        sortprogressReport.forEach(rec => {
            let obj = rec;
            let arrCols = [{ value: obj.displayName, ..._cellStyle, dynValue: 'displayName', dynWidth: obj.displayName.length }]
            props.report.columns.forEach(col => {
                var cols = reportColumns.find(item => item.value === col)
                arrCols.push({ value: `${cols && cols.currency === true ? currencySymbol : ''}${obj[col]}${cols && cols.percentage === true ? '%' : ''}`, ..._cellStyle, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'displayName', dynWidth: 6 }]
            props.report.columns.forEach(col => {
                arrCols.push({ value: `${totals[col]}`, ..._footerStyle, dynValue: col, dynWidth: totals[col] ? totals[col].toString().length : 0 })
            })
            exportData.push(arrCols)
        }
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
        }])

    }, [sortprogressReport])



    useEffect(() => {
        if (!refreshData)
            return
        setLoader(true)
        let _filters = {};
        if (!_.isEmpty(props.report.objFilters) && props.report.objFilters.length > 0) {
            props.report.objFilters.forEach(rec => {
                _filters = rec.name === 'date' ? {
                    ..._filters,
                    startDate: moment.unix(rec.value.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(rec.value.endDate.seconds).format('YYYY-MM-DD')
                } : {
                    ..._filters,
                    ...rec.value,
                    ...(rec.subList ? rec.subList : {})
                }
            })
        }
        else {
            _filters = {
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d).format('YYYY-MM-DD'),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d).format('YYYY-MM-DD')
            }
        }
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getServiceDynamicReport',
                params: JSON.stringify({
                    clientID: props.dealersettings.client.id,
                    reportJSON: JSON.stringify(props.report),
                    ..._filters
                    // startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    // endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    // ...objFilter
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }

    }, [refreshData])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][props.dealersettings.client.id] : []
        let reports = [];
        _report.forEach(rec => {
            if (Object.keys(rec).filter(r => Boolean(props.report.includeZero) ? true : _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                reports.push({
                    ...rec,
                    profileImage: (props.report.groupBy === 'user'
                        || props.report.groupBy === 'technician'
                        || props.report.groupBy === 'partsInterpretor'
                        || props.report.groupBy === 'workshopManager'
                        || props.report.groupBy === 'serviceAdvisor'
                    ) && _.find(props.clientUsers, { id: rec.id }) ? _.find(props.clientUsers, { id: rec.id }).profileImage : '',
                    totalJobs: rec.arrtotalJobs ? rec.arrtotalJobs.length : 0,
                    cancelled: rec.arrcancelled ? rec.arrcancelled.length : 0,
                    scheduled: rec.arrscheduled ? rec.arrscheduled.length : 0,
                    checkIn: rec.arrcheckIn ? rec.arrcheckIn.length : 0,
                    technicianRequested: rec.arrtechnicianRequested ? rec.arrtechnicianRequested.length : 0,
                    partsRequested: rec.arrpartsRequested ? rec.arrpartsRequested.length : 0,
                    workshopRequested: rec.arrworkshopRequested ? rec.arrworkshopRequested.length : 0,
                    advisorRequested: rec.arradvisorRequested ? rec.arradvisorRequested.length : 0,
                    ownerRequested: rec.arrownerRequested ? rec.arrownerRequested.length : 0,
                    pendingjobs: rec.arrpendingjobs ? rec.arrpendingjobs.length : 0,
                    ownerApproved: rec.arrownerApproved ? rec.arrownerApproved.length : 0,
                    ownerDeclined: rec.arrownerDeclined ? rec.arrownerDeclined.length : 0,
                    completed: rec.arrcompleted ? rec.arrcompleted.length : 0,
                    //total job duration
                    arroveralljobDuration: rec.arrjobDuration ? _.uniq(rec.arrjobDuration.map(r => { return r.documentID })) : [],
                    totaloveralljobDuration: _.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.sumBy(rec.arrjobDuration, "jobDuration") : 0,
                    overalljobDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.sumBy(rec.arrjobDuration, "jobDuration") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //avg response time
                    arrjobDuration: rec.arrjobDuration ? _.uniq(rec.arrjobDuration.map(r => { return r.documentID })) : [],
                    totaljobDuration: _.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.meanBy(rec.arrjobDuration, "jobDuration") : 0,
                    jobDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrjobDuration, 'jobDuration') > 0 ? _.meanBy(rec.arrjobDuration, "jobDuration") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxjobDuration: _.maxBy(rec.arrjobDuration, 'jobDuration') ? [_.maxBy(rec.arrjobDuration, 'jobDuration').documentID] : [],
                    totalmaxjobDuration: _.maxBy(rec.arrjobDuration, 'jobDuration') ? _.maxBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0,
                    maxjobDuration: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrjobDuration, 'jobDuration') ? _.maxBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminjobDuration: _.minBy(rec.arrjobDuration, 'jobDuration') ? [_.minBy(rec.arrjobDuration, 'jobDuration').documentID] : [],
                    totalminjobDuration: _.minBy(rec.arrjobDuration, 'jobDuration') ? _.minBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0,
                    minjobDuration: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrjobDuration, 'jobDuration') ? _.minBy(rec.arrjobDuration, 'jobDuration').jobDuration : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //total service response time
                    arroverallserviceDuration: rec.arrserviceDuration ? _.uniq(rec.arrserviceDuration.map(r => { return r.documentID })) : [],
                    totaloverallserviceDuration: _.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.sumBy(rec.arrserviceDuration, "serviceDuration") : 0,
                    overallserviceDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.sumBy(rec.arrserviceDuration, "serviceDuration") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //avg service response time
                    arrserviceDuration: rec.arrserviceDuration ? _.uniq(rec.arrserviceDuration.map(r => { return r.documentID })) : [],
                    totalserviceDuration: _.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.meanBy(rec.arrserviceDuration, "serviceDuration") : 0,
                    serviceDuration: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrserviceDuration, 'serviceDuration') > 0 ? _.meanBy(rec.arrserviceDuration, "serviceDuration") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxserviceDuration: _.maxBy(rec.arrserviceDuration, 'serviceDuration') ? [_.maxBy(rec.arrserviceDuration, 'serviceDuration').documentID] : [],
                    totalmaxserviceDuration: _.maxBy(rec.arrserviceDuration, 'serviceDuration') ? _.maxBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0,
                    maxserviceDuration: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrserviceDuration, 'serviceDuration') ? _.maxBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminserviceDuration: _.minBy(rec.arrserviceDuration, 'serviceDuration') ? [_.minBy(rec.arrserviceDuration, 'serviceDuration').documentID] : [],
                    totalminserviceDuration: _.minBy(rec.arrserviceDuration, 'serviceDuration') ? _.minBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0,
                    minserviceDuration: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrserviceDuration, 'serviceDuration') ? _.minBy(rec.arrserviceDuration, 'serviceDuration').serviceDuration : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //revenue
                    arrtotalServiceCost: rec.arrtotalServiceCost ? _.uniq(rec.arrtotalServiceCost.map(r => { return r.documentID })) : [],
                    arrtotalDeclinedCost: rec.arrtotalDeclinedCost ? _.uniq(rec.arrtotalDeclinedCost.map(r => { return r.documentID })) : [],
                    arrtotalRecommCost: rec.arrtotalRecommCost ? _.uniq(rec.arrtotalRecommCost.map(r => { return r.documentID })) : [],
                    arrtotalLabourCost: rec.arrtotalLabourCost ? _.uniq(rec.arrtotalLabourCost.map(r => { return r.documentID })) : [],
                    arrtotalPartsCost: rec.arrtotalPartsCost ? _.uniq(rec.arrtotalPartsCost.map(r => { return r.documentID })) : [],
                    arrtotalServicePartsCost: rec.arrtotalServicePartsCost ? _.uniq(rec.arrtotalServicePartsCost.map(r => { return r.documentID })) : [],
                    arrtotalServicePackagesCost: rec.arrtotalServicePackagesCost ? _.uniq(rec.arrtotalServicePackagesCost.map(r => { return r.documentID })) : [],
                    totalServiceCost: rec.arrtotalServiceCost ? Math.round((_.sumBy(rec.arrtotalServiceCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalDeclinedCost: rec.arrtotalDeclinedCost ? Math.round((_.sumBy(rec.arrtotalDeclinedCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalRecommCost: rec.arrtotalRecommCost ? Math.round((_.sumBy(rec.arrtotalRecommCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalLabourCost: rec.arrtotalLabourCost ? Math.round((_.sumBy(rec.arrtotalLabourCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalPartsCost: rec.arrtotalPartsCost ? Math.round((_.sumBy(rec.arrtotalPartsCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalServicePartsCost: rec.arrtotalServicePartsCost ? Math.round((_.sumBy(rec.arrtotalServicePartsCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,
                    totalServicePackagesCost: rec.arrtotalServicePackagesCost ? Math.round((_.sumBy(rec.arrtotalServicePackagesCost, item => Number(item.serviceCost)) + Number.EPSILON) * 100) / 100 : 0,

                    arrrecomApproved: rec.arrrecomApproved ? _.uniq(rec.arrrecomApproved.filter(a => a.serviceCost > 0).map(r => { return r.documentID })) : [],
                    recomApproved: rec.arrrecomApproved ? _.sumBy(rec.arrrecomApproved, "serviceCost") : 0,
                    arrrecomDeclined: rec.arrrecomDeclined ? _.uniq(rec.arrrecomDeclined.filter(a => a.serviceCost > 0).map(r => { return r.documentID })) : [],
                    recomDeclined: rec.arrrecomDeclined ? _.sumBy(rec.arrrecomDeclined, "serviceCost") : 0,
                    ...getDynamicTotalLength(rec)
                })
            }
        });

        // reports = reports.map(rec => {
        //     return {
        //         ...rec,
        //         carryover: rec.arrcarryover.length,
        //         walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
        //         walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
        //         appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
        //         testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
        //         appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
        //         testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
        //         tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
        //         wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
        //         scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
        //         showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
        //         noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
        //         convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
        //         newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
        //         exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
        //         totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
        //     }
        // })

        // reports = reports.map(rec => {
        //     return {
        //         ...rec,
        //         walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
        //         walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
        //         wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
        //         testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
        //     }
        // })
        props.report.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setProgressReport(reports)
        setSortProgressReport(reports)
    }

    const getDynamicTotalLength = (data) => {
        let leadSourceData = {};
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.services &&
            props.dealersettings.client.services.pipelines
            && props.dealersettings.client.services.pipelines.find(a => a.value === props.report.pipeline[0])) {
            let _leadSource = []
            let _sourceFields = []
            props.dealersettings.client.services.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: data[`arrstage-${rec.value}`] ? data[`arrstage-${rec.value}`].length : 0
                    }
                }
            })
        }

        return leadSourceData;
    }
    const getLeadsByEnquiryTypeTotal = (_progressReport) => {
        let leadSourceData = {};
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.services &&
            props.dealersettings.client.services.pipelines
            && props.dealersettings.client.services.pipelines.find(a => a.value === props.report.pipeline[0])) {
            props.dealersettings.client.services.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: _.sumBy(_progressReport, `stage-${rec.value}`)
                    }
                }
            })
        }


        return leadSourceData;
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        setSortProgressReport(sortableItems)
        return sortableItems;
    }, [progressReport, sortConfig]);

    const handleModelClose = (rec, isNew) => {
        if (rec)
            setReport(rec);
        setCounts(0)
        setShowmodel(false)
        setFilter(null)
        setModelShow({
            service: false,
            scheduleActivity: false
        })
        if (isNew) {
            props.setKey(rec.documentID)
        }
    }

    const deleteReport = () => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`serviceReports/${props.report.documentID}`).delete()
                    .then(snapshot => {
                        toast.notify('Report deleted successfully', {
                            duration: 2000
                        })
                        window.firebase.firestore().doc(`schedular/${props.report.documentID}`).delete();
                        props.setKey('salesprogress')
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })
    }

    const cloneReport = () => {
        setReport({
            ...props.report,
            documentID: window.firebase.firestore().collection(`serviceReports`).doc().id,
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid,
            owner: localStorage.uid,
            name: `${props.report.name} - Copy`
        })
        setShowmodel(true)
    }

    useEffect(() => {
        if (_.isEmpty(chartData))
            return
        try {
            chartData.exporting && chartData.exporting.getImage("png").then(rec => {
                setChartImg(rec)
            })
        }
        catch (error) {
            console.log(error)
        }
    }, [chartData])

    const ReportPdf = () => (
        <Document>
            <Page style={styles.page} size="A4" orientation="lanscape">
                <View style={styles.header}>
                    <View style={styles.headerwrapLeft}>
                        <Text style={{ fontSize: 13 }}>{props.dealersettings.client.name}</Text>
                    </View>
                    <View style={styles.headerwrapRight}>
                        <Text style={{ fontSize: 13 }}>{props.report.name}</Text>
                        <Text style={{ fontSize: 10, color: '#6b717f', paddingTop: 5 }}>{moment().format('DD/MM/YYYY hh:mm A')}</Text>
                    </View>
                </View>
                <View style={styles.reportImage}>
                    {
                        chartImg ? (<Image style={styles.image} src={chartImg} />) : (<></>)
                    }

                </View>
                <View style={styles.footer}>
                    <View style={styles.footerCpy}>
                        <View style={styles.footerLeft}>
                            <Text>Powered by <Link src="http://fusionsd.com.au/" style={{ color: '#666', textDecoration: 'none' }}>Fusion SD</Link></Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
    const DownloadPdf = () => {
        return useMemo(
            () => (
                <PDFDownloadLink document={<ReportPdf />} fileName={`${props.report.name.toLowerCase().replace(/ /g, '-')}.pdf`}>
                    {({ loading }) => ((loading || _.isEmpty(chartImg)) ? (<></>) : (<> <i className="ico icon-files-pdf"></i> Export PDF </>))}
                </PDFDownloadLink>
            ),
            [chartImg],
        )
    }

    const handleShowEnquiryList = (val, rec) => {
        var cols = reportColumns.find(item => item.value === val)
        if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
            setCounts(rec[`arr${cols.value}`] ? rec[`arr${cols.value}`].length : 0)
            setFilter({
                documentIDs: rec[`arr${cols.value}`]
            })
            setPopHeader('Service Jobs')
            setModelShow({
                ...modelShow,
                service: true
            })

        }
    }

    const isValidFilters = (isScroll) => {
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';


        let _filterLists = Object.assign([], report.objFilters);

        !_.isEmpty(_filterLists) && _filterLists.forEach(_data => {
            if (_.isEmpty(_data.name)) {
                formIsValid = false;
                errors['name_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;

            }
            else if (_.isEmpty(_data.value)) {
                formIsValid = false;
                errors['value_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;
            }

        });

        setErrorFilter(errors);
        //console.log('isValidFilters,', errors)
        return formIsValid;
    }

    const addFilter = () => {
        let _lists = Object.assign([], report.objFilters);
        let _isValidFilters = isValidFilters();
        if (!_isValidFilters)
            return;

        _lists.push({ name: null, value: null, id: uuid() })
        setReport({
            ...report,
            ['objFilters']: _lists
        });

    }

    const updateFilter = (_data) => {
        if (!_.isEmpty(_data)) {
            const _reLists = Object.assign([], report.objFilters);

            _reLists.filter(function (obj) {
                //Update old data
                if (obj.id === _data.id) {
                    for (let [key, value] of Object.entries(_data)) {
                        obj[key] = value;
                    }
                }
            });
            //console.log('updateRule', _data, _reLists)

            setReport({
                ...report,
                ['objFilters']: _reLists
            });
        }
    }

    const deleteFilter = (_id) => {
        if (!_.isEmpty(_id)) {
            const _reLists = Object.assign([], report.objFilters);
            setReport({
                ...report,
                ['objFilters']: _reLists.filter(m => m.id !== _id)
            });
        }
    }

    const saveFilter = () => {
        setLoader(true)
        window.firebase.firestore().doc(`serviceReports/${report.documentID}`).set(report, { merge: true })
            .then(snapshot => {
                setTimeout(() => {
                    setRefreshData(true)
                }, 1000);
                // toast.notify('Report updated successfully', {
                //     duration: 2000
                // });
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const toggleFilter = (e, show) => {
        e.preventDefault();
        setShow(show);
        const reportWrapper = window.jQuery(`#${report.documentID}-report-collapse`);
        if (reportWrapper) {
            if (show)
                reportWrapper.fadeIn();
            else
                reportWrapper.fadeOut();
        }
    }

    const getFilterDates = () => {
        let dates = report?.objFilters?.filter(a => a.date)?.[0]?.value;
        let datesObj = {};
        if (dates?.startDate) {
            datesObj.startDate = moment.unix(dates?.startDate?.seconds).format('YYYY-MM-DD');
            datesObj.endDate = moment.unix(dates?.endDate?.seconds).format('YYYY-MM-DD');
        }
        return datesObj;
    }

    return (<>
        <div className="report-page-head">
            <h2>{props.report.name}  </h2>


            <Dropdown className='enquiry-view-more float-right mt-0'>
                <Dropdown.Toggle as={CustomToggle} className='common-button'>
                    <i className='ico icon-more'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    as={CustomMenu}
                    ChildClass="more-dropdown dropsub-fix"
                    xplacement="bottom-end"
                >
                    {
                        props.report.addedBy === localStorage.uid || props.dealersettings.roleName === 'Administrator' ? (
                            <Dropdown.Item eventKey="1" onClick={(e) => { setReport(props.report); setShowmodel(true) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                        ) : (<></>)
                    }
                    {
                        props.report.reportType === 'table' ? (<Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                            <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`${props.report.name.toLowerCase().replace(/ /g, '-')}`}>
                                <ExcelSheet dataSet={csvData} name={`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`} />
                                {
                                    Object.keys(sheetData).map((rec, index) => {
                                        return <ExcelSheet key={index} dataSet={sheetData[rec]} name={`${_.find(reportColumns, { value: rec }) ? _.find(reportColumns, { value: rec }).name : rec}`} />
                                    })
                                }
                            </ExcelFile>
                        </Dropdown.Item>) : (<Dropdown.Item eventKey="3" as={'div'} bsPrefix={'li-'}>
                            <DownloadPdf />
                        </Dropdown.Item>)
                    }
                    {
                        props.report.addedBy === localStorage.uid || props.dealersettings.roleName === 'Administrator' ? (<>
                            <Dropdown.Item eventKey="3" onClick={(e) => { deleteReport() }}><i className="ico icon-delete"></i> Delete </Dropdown.Item>
                            <Dropdown.Item eventKey="4" onClick={(e) => {
                                setModelShow({
                                    ...modelShow,
                                    sheduleReport: true
                                })
                            }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                            <Dropdown.Item eventKey="5" onClick={(e) => { cloneReport() }}><i className="ico icon-rearrange"></i> Clone Report </Dropdown.Item>
                        </>) : (<></>)
                    }
                </Dropdown.Menu>
            </Dropdown>
            {
                !_.isEmpty(schedueReport) ? (
                    <div className="reportsuccess-item-btn mr-2 float-right">
                        <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                        <div className="reportsuccess-inner-text">
                            <div className="reportsuccess-inner-text-top"> Scheduled </div>
                            <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                        </div>
                        <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                    </div>
                ) : (<></>)
            }

        </div>
        <div className="divider-line"></div>
        <div className="report-content-head pl-3 pr-3">
            <div className="row">
                <div className="rule-filter-head pl-3 pr-3">
                    {
                        !_.isEmpty(report.objFilters)
                            ?
                            <div className="float-left rule-filter-info cursor-pointer" onClick={(e) => { toggleFilter(e, !filterShow) }}> <i className="ico icon-filter"></i>
                                {Object.keys(report.objFilters).length} {Object.keys(report.objFilters).length > 1 ? ' filters' : ' filter'} applied
                            </div>
                            :
                            <></>
                    }
                    {
                        (report.visibility !== 'sharedView' || props.report.addedBy === localStorage.uid || Boolean(props.dealersettings.superAdmin)) ? (<div className="float-right rule-add-btn">
                            <div className="float-right ml-2 filter-collapse-btn">
                                <div className="filter-collapse-report"> <a href="#" onClick={(e) => { toggleFilter(e, !filterShow) }} className={`mini-button ${!filterShow ? 'collapsed' : ''}`}><i className="arrow-collapse"></i></a> </div>
                            </div>
                            <button type="button" className="btn btn-primary float-right ml-2"
                                onClick={(e) => { toggleFilter(e, true); addFilter(); }}>
                                <i className="ico icon-add mr-2"></i> Add Filter
                            </button>
                        </div>) : (<></>)
                    }
                </div>
                <div className={`rules-group-wrapper query-builder`} id={`${report.documentID}-report-collapse`}>
                    <div className="rules-group-body">
                        <div className="rules-list">
                            {
                                !_.isEmpty(report.objFilters) && report.objFilters.map((_rec, index) => {
                                    return <CustomFilters
                                        {...props}
                                        key={index}
                                        docID={_rec ? _rec.id : ''}
                                        fieldData={_rec}
                                        errors={errorFilters}
                                        filterFields={report.objFilters}
                                        updateFilter={updateFilter}
                                        deleteFilter={deleteFilter}
                                        columns={report.columns}
                                        report={report}
                                        filterOptions={filterOptions}
                                        reportFilters={filterOptions}
                                    ></CustomFilters>
                                })
                            }

                        </div>
                    </div>
                    <div className="filter-collapse-report-footer">
                        <button type="button" className="btn btn-green float-left ml-3" onClick={() => saveFilter()}>Apply</button>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) : progressReport && progressReport.length === 0 ? (
                <div className="common-table">
                    <div className="text-center p-5">
                        <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                        <div className="no-data-txt mt-2"> No records found</div>
                    </div>
                </div>
            ) : props.report.reportType === 'table' ? (<div className="common-table" style={{ overflowX: 'auto' }}>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <td className="head-light" width="16%">
                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                    e.preventDefault()
                                    requestSort('name')
                                }}>
                                    <div className="table-sort"><a href="#">
                                        {
                                            sortConfig && sortConfig.key === 'name' ?
                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                        }
                                    </a>
                                    </div>
                                    {CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value')}
                                </div>
                            </td>
                            {
                                props.report.columns.map((rec, index) => {
                                    return <td key={index} className="head-light text-center" width="7%">
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {
                                                reportColumns.find(item => item.value === rec) ?
                                                    (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                    (<span className="pipeline-inactive-span">(In Active)</span>)
                                            }
                                        </div>
                                    </td>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedItems.length > 0 ?
                                sortedItems.map((rec, index) => {
                                    return <tr key={index}>
                                        <td>
                                            {
                                                (props.report.groupBy === 'user'
                                                    || props.report.groupBy === 'technician'
                                                    || props.report.groupBy === 'partsInterpretor'
                                                    || props.report.groupBy === 'workshopManager'
                                                    || props.report.groupBy === 'serviceAdvisor'
                                                ) ? (<div className="table-user-list">
                                                    <a href="#">
                                                        <div className="table-user-avatar">
                                                            <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                        {rec.displayName}
                                                    </a>
                                                </div>) : (
                                                    <div className="dealership-tbl">
                                                        {rec.displayName}
                                                    </div>
                                                )
                                            }

                                        </td>
                                        {
                                            props.report.columns.map((val, index) => {
                                                return <td key={index} className="text-center cursor-pointer"
                                                    onClick={() => {
                                                        var cols = reportColumns.find(item => item.value === val)
                                                        if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
                                                            setCounts(rec[`arr${cols.value}`] ? rec[`arr${cols.value}`].length : 0)
                                                            setFilter({
                                                                documentIDs: rec[`arr${cols.value}`]
                                                            })
                                                            setPopHeader('Service Jobs')
                                                            setModelShow({
                                                                ...modelShow,
                                                                service: true
                                                            })

                                                        }
                                                    }}>
                                                    {
                                                        reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                displayType={'text'}
                                                                value={rec[val]}
                                                            />
                                                        )
                                                            : `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                    }
                                                </td>
                                            })
                                        }
                                    </tr>
                                }) : (
                                    <tr>
                                        <td colSpan={props.report.columns.length + 1}>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> No data</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                    {
                        sortedItems.length > 0 ?
                            (<tfoot>
                                <tr>
                                    <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                    {
                                        props.report.columns.map((val, index) => {
                                            return <td key={index} className="text-center table-sub-total cursor-pointer"
                                                onClick={() => {
                                                    var cols = reportColumns.find(item => item.value === val)
                                                    if (cols && cols.percentage !== true) {
                                                        var IDs = [];
                                                        progressReport.forEach(rec => {
                                                            IDs = [...IDs, ...rec[`arr${cols.value}`]]
                                                        })
                                                        setCounts(IDs.length)
                                                        setFilter({
                                                            documentIDs: _.uniq(IDs)
                                                        })
                                                        setPopHeader('Service Jobs')
                                                        setModelShow({
                                                            ...modelShow,
                                                            service: true
                                                        })

                                                    }
                                                }}>
                                                {
                                                    (val === 'jobDuration' ? (_.sumBy(progressReport, `totaljobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totaljobDuration), props.dealersettings.client) : '00:00:00') :
                                                        val === 'overalljobDuration' ? (_.sumBy(progressReport, `totaloveralljobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totaloveralljobDuration), props.dealersettings.client) : '00:00:00') :
                                                            val === 'maxjobDuration' ? (_.sumBy(progressReport, `totalmaxjobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxjobDuration), props.dealersettings.client) : '00:00:00') :
                                                                val === 'minjobDuration' ? (_.sumBy(progressReport, `totalminjobDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminjobDuration), props.dealersettings.client) : '00:00:00') :
                                                                    val === 'overallserviceDuration' ? (_.sumBy(progressReport, `totaloverallserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totaloverallserviceDuration), props.dealersettings.client) : '00:00:00') :
                                                                        val === 'serviceDuration' ? (_.sumBy(progressReport, `totalserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalserviceDuration), props.dealersettings.client) : '00:00:00') :
                                                                            val === 'maxserviceDuration' ? (_.sumBy(progressReport, `totalmaxserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxserviceDuration), props.dealersettings.client) : '00:00:00') :
                                                                                val === 'minserviceDuration' ? (_.sumBy(progressReport, `totalminserviceDuration`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminserviceDuration), props.dealersettings.client) : '00:00:00') :
                                                                                    val === 'totalServiceCost' || val === 'totalDeclinedCost' || val === 'totalRecommCost' || val === 'totalLabourCost'|| val === 'totalPartsCost'|| val === 'totalServicePartsCost'|| val === 'totalServicePackagesCost' ? <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        prefix={currencySymbol}
                                                                                        displayType={'text'}
                                                                                        value={Math.round((_.sumBy(progressReport, val) + Number.EPSILON) * 100) / 100}
                                                                                    /> : _.sumBy(progressReport, val))
                                                }
                                                {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                            </td>
                                        })
                                    }
                                </tr>
                            </tfoot>
                            ) : (<></>)
                    }
                </table>
            </div>
            ) : props.report.reportType === 'column' ?
                (
                    <div className="text-center">
                        <BarChart
                            id={`view-report-bar-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={progressReport}
                            columns={props.report.columns ? _.isArray(props.report.columns) ? props.report.columns : [props.report.columns] : []}
                            dynamicColumns={reportColumns}
                            xCategory={'displayName'}
                            setChartData={(data) => { setChartData(data) }}
                            calculationType={props.report.calculationType}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />
                    </div>
                ) : props.report.reportType === 'pie' ? (<>
                    {
                        progressReport.some(item => item[props.report.columns] > 0) ? (<PieChart
                            id={`view-report-pie-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={progressReport}
                            dynamicReport={true}
                            columns={_.isArray(props.report.columns) ? props.report.columns[0] : props.report.columns}
                            setChartData={(data) => { setChartData(data) }}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />) : (
                            <NoDataPieChart
                                id={'no-data-pie-chart'}
                                height={`${windowSize.tblHeight}px`}
                            />)
                    }
                </>) : props.report.reportType === 'target' ? (
                    <LineChart
                        id={`view-report-line-chart-${props.report.documentID}`}
                        height={`${windowSize.tblHeight}px`}
                        data={progressReport.map(rec => { return { ...rec, 'name': rec.displayName } })}
                        dynamicColumns={reportColumns}
                        xCategory={'displayName'}
                        //columns={props.report.target > 0 ? [props.report.columns, 'target'] : [props.report.columns]}
                        columns={props.report.target > 0 ? (_.isArray(props.report.columns) ? [...props.report.columns, 'target'] : [props.report.columns, 'target']) : (_.isArray(props.report.columns) ? props.report.columns : [props.report.columns])}
                        setChartData={(data) => { setChartData(data) }}
                        setChartImg={(img) => { setChartImg(img) }}
                        calculationType={props.report.calculationType}
                        handleShowEnquiryList={(val, rec) => {
                            handleShowEnquiryList(val, rec)
                        }}
                    />
                ) : (<></>)
        }
        <PopUpModal show={showModel}>
            <AddReport
                {...props}
                show={showModel}
                title={'Edit Report'}
                handleClose={handleModelClose}
                report={report}
            />
        </PopUpModal>
        <PopUpModal show={modelShow.service}>
            <ServiceList
                {...props}
                show={modelShow.service}
                handleClose={handleModelClose}
                title={popHeader}
                history={props.history}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></ServiceList>
        </PopUpModal>
        <PopUpModal show={modelShow.sheduleReport}>
            <ScheduleReport
                {...props}
                show={modelShow.sheduleReport}
                handleClose={handleModelClose}
                title={popHeader}
                module={'service'}
                {...getFilterDates()}
            ></ScheduleReport>
        </PopUpModal>
    </>)
}

export default ViewDynamicReport;