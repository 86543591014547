import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { ContentOverlay, AntDateRangePicker, ReactSelect, PopUpModal, ReactMultiSelect } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import EnquiryList from '../../dashboard/enquiryList'
import QuoteList from '../../dashboard/quotesList'
import ReactExport from 'react-export-excel-xlsx-fix';
import { CustomMenu, CustomToggle, CustomFilter } from '../../../components/customdropdown';
import { Dropdown } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import { firestoreDB } from '../../../services/helper';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const leadTypes = [
    { value: 'all', label: 'All Leads' },
    { value: 'new', label: 'New Leads' },
    { value: 'existing', label: 'Existing Leads' },
    { value: 'existingCustomer', label: 'Existing Customers Leads' },
    { value: 'newExistingSold', label: 'New Leads with Existing Sold / Test Drives' }
]

const SalesProgress = (props) => {
    const [loader, setLoader] = useState(true)
    const [allEnquiries, setEnquiries] = useState([]);
    const [allQuotations, setQuotations] = useState([]);
    // const [allTestdrives, setTestdrives] = useState([]);
    // const [allTradeins, setTradeins] = useState([]);
    //const [overdueActivities, setOverdueActivities] = useState([]);
    const [userOptions, setUserOptions] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [olddateRange, setOldDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [progressReport, setProgressReport] = useState([])
    const [dates, setDates] = useState([]);
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        quote: false,
        activity: false
    })
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [selectedUser, setUser] = useState();
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [sortConfig, setSortConfig] = useState();
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [salesTypes, setSalesTypes] = useState([])
    const [servicePkgs, setServicePkgs] = useState([])
    const [selectedMake, setSelectedMake] = useState()
    const [selectedModel, setSelectedModel] = useState()
    const [selectedSaleType, setSelectedSaleType] = useState()
    const [selectedService, setSelectedService] = useState()
    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: [],
        requirements: []
    })
    const [csvData, setCSVData] = useState([])
    const [filterShow, setShow] = useState(false)
    const [showCount, setShowCount] = useState(false)
    const node = useRef();

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false;
    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const [selectedLeadType, setLeadType] = useState();
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const enquiryStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
            window.unSubSalesprogressReport && window.unSubSalesprogressReport.unsubscribe()
        }
    }, [])

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner') || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        let brands = props.dealersettings.client.brands;
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (selectedMake) {
            let _models = !_.isEmpty(makes.filter(m => m.value === selectedMake.value)[0]) ? makes.filter(m => m.value === selectedMake.value)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [selectedMake])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || _.isEmpty(props.dealersettings.client))
            return

        if (!_.isEmpty(props.dealersettings.client.settings['salesType'])) {
            let _saleTypes = [];
            props.dealersettings.client.settings['salesType'].filter(a => a.active === true).map(rec => {
                return _saleTypes.push({
                    value: rec.value,
                    label: rec.name
                });
            })
            setSalesTypes(_saleTypes)
        }

        if (!_.isEmpty(props.dealersettings.client.settings['servicePackages'])) {
            let _services = [];
            props.dealersettings.client.settings['servicePackages'].filter(a => a.active === true).map(rec => {
                return _services.push({
                    value: rec.name,
                    label: rec.name
                });
            })
            setServicePkgs(_services)
        }
    }, [props.dealersettings])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.filter(a => Boolean(a.superAdmin) === false).forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec,
                name: rec.name
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])


    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers.push(
            { title: 'User', dynValue: 'name', dynWidth: 10, ...csvStyle.header },
            { title: 'New', dynValue: 'new', dynWidth: 10, ...csvStyle.header },
            { title: 'Existing', dynValue: 'existing', dynWidth: 10, ...csvStyle.header },
            { title: 'Total', dynValue: 'total', dynWidth: 10, ...csvStyle.header },
            { title: `${_.find(enquiryStatus, { value: 'open' }) ? `${_.find(enquiryStatus, { value: 'open' }).name}` : 'Open'}`, dynValue: 'open', dynWidth: 10, ...csvStyle.header },
            { title: 'Quotes Genarated', dynValue: 'quotes', dynWidth: 10, ...csvStyle.header },
            { title: 'Quotes Amount', dynValue: 'strquoteAmount', dynWidth: 10, ...csvStyle.header },
            { title: `${_.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name}` : 'Won'}`, dynValue: 'won', dynWidth: 10, ...csvStyle.header },
            { title: 'Won Amount', dynValue: 'strwonAmount', dynWidth: 10, ...csvStyle.header },
            { title: `${_.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'}`, dynValue: 'delivered', dynWidth: 10, ...csvStyle.header },
            { title: `${_.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name}` : 'Lost'}`, dynValue: 'lost', dynWidth: 10, ...csvStyle.header },
            { title: 'Conversion Ratio', dynValue: 'wonConv', dynWidth: 20, ...csvStyle.header }
        )
        var exportData = []
        var totals = {
            name: "Totals",
            new: _.sumBy(progressReport, "new"),
            existing: _.sumBy(progressReport, "existing"),
            total: _.sumBy(progressReport, "total"),
            open: _.sumBy(progressReport, "open"),
            won: _.sumBy(progressReport, "won"),
            lost: _.sumBy(progressReport, "lost"),
            walkin: _.sumBy(progressReport, "walkin"),
            delivered: _.sumBy(progressReport, "delivered"),
            quotes: _.sumBy(progressReport, "quotes"),
            strquoteAmount: `${currencySymbol}${_.sumBy(progressReport, "quoteAmount")}`,
            strwonAmount: `${currencySymbol}${_.sumBy(progressReport, "wonAmount")}`,
            wonConv: `${_.sumBy(progressReport, "total") > 0 ? Math.round((100 / _.sumBy(progressReport, "total")) * _.sumBy(progressReport, "won")) : 0}%`,
        };

        progressReport.filter(a => a.total > 0).forEach(rec => {
            let obj = rec;
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${obj[col.dynValue]}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${totals[col.dynValue]}`, ...csvStyle.footer, dynValue: col.dynValue, dynWidth: totals[col.dynValue] ? totals[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        }
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])
    }, [progressReport])

    useEffect(() => {
        if (!refreshData)
            return

        setLoader(true)

        let refPendingEnquiryData = ''
        if (inclInboundLeadsinRpt && props.pipeline.some(item => item.value === 'LeadsBucket')) {
            refPendingEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('pipeline', '==', 'LeadsBucket')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('addedDate', '>=', dateRange.startDate)
                .where('addedDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
            if (ownerDashboard)
                refPendingEnquiryData = refPendingEnquiryData.where('owner', '==', props.dealersettings.id)
        }

        //OPEN ENQUIRIES    
        let refOpenEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refOpenEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('addedDate', '>=', dateRange.startDate)
                .where('addedDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
            if (ownerDashboard)
                refOpenEnquiryData = refOpenEnquiryData.where('owner', '==', props.dealersettings.id)
        }

        //WON ENQUIRIES    
        let refWonEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refWonEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('status', '==', 'won')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('wonDate', '>=', dateRange.startDate)
                .where('wonDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
            if (ownerDashboard)
                refWonEnquiryData = refWonEnquiryData.where('owner', '==', props.dealersettings.id)
        }

        //LOST ENQUIRIES    
        let refLostEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refLostEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('status', '==', 'lost')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('lostDate', '>=', dateRange.startDate)
                .where('lostDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
            if (ownerDashboard)
                refLostEnquiryData = refLostEnquiryData.where('owner', '==', props.dealersettings.id)
        }


        //DELIVERED ENQUIRIES    
        let refDeliveredEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refDeliveredEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('status', '==', 'delivered')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('deliveredDate', '>=', dateRange.startDate)
                .where('deliveredDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
            if (ownerDashboard)
                refDeliveredEnquiryData = refDeliveredEnquiryData.where('owner', '==', props.dealersettings.id)
        }

        //TEST DRIVES    
        // let refTestDriveData = window.firebase.firestore().collection('testdrives')
        //     .where('clientID', '==', props.dealersettings.client.id)
        //     .where('isDeleted', '==', false)
        //     .where('startDate', '>=', dateRange.startDate)
        //     .where('startDate', '<=', dateRange.endDate)
        //     .orderBy('startDate', 'desc')
        // if (ownerDashboard)
        //     refTestDriveData = refTestDriveData.where('addedBy', '==', props.dealersettings.id)
        // //TRADE IN COUNT    
        // let refTradeinData = window.firebase.firestore().collection('tradeins')
        //     .where('clientID', '==', props.dealersettings.client.id)
        //     .where('isDeleted', '==', false)
        //     .where('addedDate', '>=', dateRange.startDate)
        //     .where('addedDate', '<=', dateRange.endDate)
        // if (ownerDashboard)
        //     refTradeinData = refTradeinData.where('addedBy', '==', props.dealersettings.id)

        //MTD OVERDUE ACTIVITIES
        // let refOverdueActivityData = firestoreDB(props.dealersettings).firestore().collection('activities')
        //     .where('clientID', '==', props.dealersettings.client.id)
        //     .where('isDeleted', '==', false)
        //     .where('isDone', '==', false)
        //     .where('type', '==', 'activity')
        //     .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
        //     .orderBy('startDate', 'desc')
        //     .limit(100)
        // if (ownerDashboard)
        //     refOverdueActivityData = refOverdueActivityData.where('owner', '==', props.dealersettings.id)

        //MTD WALKIN LEADS
        let refWalkinEnquiryData = firestoreDB(props.dealersettings).firestore().collectionGroup('walkins')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('walkinDate', '>=', dateRange.startDate)
            .where('walkinDate', '<=', dateRange.endDate)


        window.unSubSalesprogressReport = combineLatest(
            refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
            refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
            refLostEnquiryData ? collection(refLostEnquiryData) : of([]),
            refDeliveredEnquiryData ? collection(refDeliveredEnquiryData) : of([]),
            refPendingEnquiryData ? collection(refPendingEnquiryData) : of([]),
            refWalkinEnquiryData ? collection(refWalkinEnquiryData) : of([])
        ).pipe(
            map(([openLeads, wonLeads, lostLeads, deliveredLeads, pendingLeads, walkins]) => {
                return [
                    _.map(openLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(wonLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(lostLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(deliveredLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(pendingLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(walkins, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                ]
            }))
            .subscribe(allDocs => {
                var _enquiries = [];
                for (let i = 0; i < 5; i++) {
                    allDocs[i].forEach(el => {
                        if (!_enquiries.some(e => e.documentID === el.documentID) && !_.isEmpty(el.contact) && el.stageValue !== 'Archived') {
                            _enquiries.push({
                                ...el,
                                testdrives: allDocs[4].filter(a => a.enquiryID === el.documentID),
                                requirements: el.requirement ? [el.requirement] : [],
                            })
                        }
                    });
                }
                setTempData({
                    enquiries: _enquiries,
                    walkins: allDocs[5]
                })
                if (_enquiries.length === 0 && allDocs[5].length === 0)
                    setLoader(false)
            });

    }, [refreshData])


    useEffect(() => {
        async function getWalkinEnq(enquiries, walkins) {
            const enqpromises = []
            _.uniq(_.map(walkins, function (e) { return e.enquiryID })).forEach(el => {
                if (!enquiries.some(e => e.documentID === el)) {
                    enqpromises.push(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${el}`).get())
                }
            });
            const enqsnapshots = await Promise.all(enqpromises)
            enqsnapshots.forEach(snap => {
                const dataDoc = { ...CommonHelper.convertEnquiryVM({ ...snap.data(), 'documentID': snap.id }, { ...props }) }
                if ((!ownerDashboard || dataDoc.ownerValue === props.dealersettings.id) && dataDoc.isDeleted === false && !_.isEmpty(dataDoc.contact) && dataDoc.stageValue !== 'Archived') {
                    enquiries.push({
                        ...dataDoc,
                        walkins: walkins.filter(a => a.enquiryID === snap.id),
                        requirements: snap.data().requirement ? [snap.data().requirement] : [],
                    })
                }
            })
            const promises = []
            const quotations = []
            _.uniq(_.map(enquiries, function (e) { return e.documentID })).forEach(el => {
                promises.push(firestoreDB(props.dealersettings).firestore().collection(`enquiries/${el}/quotations`)
                    .where('isDeleted', '==', false)
                    .get())
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snaps => {
                snaps.docs.forEach(snap => {
                    quotations.push(CommonHelper.convertQuoteVM({
                        ...snap.data(),
                        documentID: snap.id,
                        payableOnDelivery: snap.data().payableOnDelivery ? Math.round((snap.data().payableOnDelivery + Number.EPSILON) * 100) / 100 : 0,
                        enquiry: _.find(enquiries, { documentID: snap.data().enquiryID })
                    }, { ...props }))
                })
            })
            setEnquiries(enquiries.map(rec => {
                return {
                    ...rec,
                    quotations: quotations.filter(a => a.enquiryID === rec.documentID)
                }
            }))
            setQuotations(quotations)
            setLoader(false)
            setRefreshClientData(true)
            setRefreshData(false)
            window.unSubSalesprogressReport && window.unSubSalesprogressReport.unsubscribe()
        }
        if (tempData.enquiries.length > 0 || tempData.walkins.length > 0)
            getWalkinEnq(tempData.enquiries, tempData.walkins);


    }, [tempData])


    useEffect(() => {
        if (_.isEmpty(userOptions) || !refreshClientData)
            return
        let reports = [];
        _.orderBy(userOptions, ['name'], ['asc']).forEach(rec => {

            var arrnewLeads = _.uniq(_.map(allEnquiries.filter(a =>
                // a.pipelineValue !== 'LeadsBucket'
                !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.startDate.seconds
                && a.addedDate.seconds <= dateRange.endDate.seconds
                && a.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.quotations && a.quotations.some(d => d.services && d.services.some(c => selectedService.some(b => b === c.item)))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.requirements && a.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.requirements && a.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.requirements && a.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.existingCustomer === true : true)
            ), function (e) {
                return e.documentID
            })
            )

            var arrexisitngLeads = _.uniq(_.map(allEnquiries.filter(a =>
                // a.pipelineValue !== 'LeadsBucket'
                // && 
                !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.startDate.seconds
                && (((a.statusValue === 'won' || a.statusValue === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.startDate.seconds
                    && a.wonDate.seconds <= dateRange.endDate.seconds) ||
                    (a.statusValue === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.startDate.seconds
                        && a.lostDate.seconds <= dateRange.endDate.seconds) ||
                    (a.statusValue === 'delivered' && !_.isEmpty(a.deliveredDate) && a.deliveredDate.seconds >= dateRange.startDate.seconds
                        && a.deliveredDate.seconds <= dateRange.endDate.seconds) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.startDate.seconds
                        && item.walkinDate.seconds <= dateRange.endDate.seconds)))
                && a.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.quotations && a.quotations.some(d => d.services && d.services.some(c => selectedService.some(b => b === c.item)))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.requirements && a.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.requirements && a.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.requirements && a.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.existingCustomer === true : true)
            ), function (e) {
                return e.documentID
            })
            )

            var arropenLeads = _.uniq(_.map(allEnquiries.filter(a =>
                !_.isEmpty(a.statusValue)
                && !_.isEmpty(a.addedDate)
                && a.statusValue.toLowerCase() === 'open'
                && a.addedDate.seconds >= dateRange.startDate.seconds
                && a.addedDate.seconds <= dateRange.endDate.seconds
                && a.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.quotations && a.quotations.some(d => d.services && d.services.some(c => selectedService.some(b => b === c.item)))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.requirements && a.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.requirements && a.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.requirements && a.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.existingCustomer === true : true)
            ), function (e) {
                return e.documentID
            })
            )

            var arrwonLeads = _.uniq(_.map(allEnquiries.filter(a =>
                !_.isEmpty(a.statusValue)
                && !_.isEmpty(a.wonDate)
                && (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered')
                && a.wonDate.seconds >= dateRange.startDate.seconds
                && a.wonDate.seconds <= dateRange.endDate.seconds
                && a.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.quotations && a.quotations.some(d => d.services && d.services.some(c => selectedService.some(b => b === c.item)))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.requirements && a.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.requirements && a.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.requirements && a.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.existingCustomer === true : true)
            ), function (e) {
                return {
                    documentID: e.documentID,
                    soldCount: e.soldCount
                }
            })
            )

            var arrlostLeads = _.uniq(_.map(allEnquiries.filter(a =>
                !_.isEmpty(a.statusValue)
                && !_.isEmpty(a.lostDate)
                && (a.statusValue.toLowerCase() === 'lost' || a.statusValue.toLowerCase() === 'prospectlost')
                && a.lostDate.seconds >= dateRange.startDate.seconds
                && a.lostDate.seconds <= dateRange.endDate.seconds
                && a.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.quotations && a.quotations.some(d => d.services && d.services.some(c => selectedService.some(b => b === c.item)))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.requirements && a.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.requirements && a.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.requirements && a.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.existingCustomer === true : true)
            ), function (e) {
                return e.documentID
            })
            )

            var arrdeliveredLeads = _.uniq(_.map(allEnquiries.filter(a =>
                !_.isEmpty(a.statusValue)
                && !_.isEmpty(a.deliveredDate)
                && a.statusValue.toLowerCase() === 'delivered'
                && a.deliveredDate.seconds >= dateRange.startDate.seconds
                && a.deliveredDate.seconds <= dateRange.endDate.seconds
                && a.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.quotations && a.quotations.some(d => d.services && d.services.some(c => selectedService.some(b => b === c.item)))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.requirements && a.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.requirements && a.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.requirements && a.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.existingCustomer === true : true)
            ), function (e) {
                return {
                    documentID: e.documentID,
                    soldCount: e.soldCount
                }
            })
            )

            var arrquotes = _.uniq(_.map(allQuotations.filter(a =>
                a.enquiry
                && a.enquiry.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.services && a.services.some(c => selectedService.some(b => b === c.item))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.enquiry.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.enquiry.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.enquiry.existingCustomer === true : true)

            ), function (e) {
                return e.documentID
            })
            )

            var arrquoteAmount = _.uniq(_.map(allQuotations.filter(a =>
                a.enquiry
                && a.enquiry.ownerValue === rec.value
                && (!_.isEmpty(selectedService) ? (a.services && a.services.some(c => selectedService.some(b => b === c.item))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.enquiry.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.enquiry.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.existingCustomer === true : true)

            ), function (e) {
                return {
                    documentID: e.documentID,
                    amount: e.payableOnDelivery ? Math.round((e.payableOnDelivery + Number.EPSILON) * 100) / 100 : 0
                }
            })
            )

            var arrwonAmount = _.uniq(_.map(allQuotations.filter(a =>
                a.enquiry
                && a.enquiry.ownerValue === rec.value
                && (a.enquiry.statusValue.toLowerCase() === 'won' || a.enquiry.statusValue.toLowerCase() === 'delivered')
                && !_.isEmpty(a.enquiry.wonDate)
                && a.enquiry.wonDate.seconds >= dateRange.startDate.seconds
                && a.enquiry.wonDate.seconds <= dateRange.endDate.seconds
                && (!_.isEmpty(selectedService) ? (a.services && a.services.some(c => selectedService.some(b => b === c.item))) : true)
                && (!_.isEmpty(selectedSaleType) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => selectedSaleType.some(b => b === item.saleType) || (item.stock && selectedSaleType.some(b => b === item.stock.saleType)))) : true)
                && (!_.isEmpty(selectedMake) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => item.make === selectedMake.value || (item.stock && item.stock.make === selectedMake.value))) : true)
                && (!_.isEmpty(selectedModel) ? (a.enquiry.requirements && a.enquiry.requirements.some(item => item.model === selectedModel.value || (item.stock && item.stock.model === selectedModel.value))) : true)
                && ((!_.isEmpty(selectedLeadType) && (selectedLeadType.value === 'new' || selectedLeadType.value === 'newExistingSold')) ? (a.enquiry.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existing') ? (a.enquiry.addedDate.seconds < dateRange.startDate.seconds) : true)
                && ((!_.isEmpty(selectedLeadType) && selectedLeadType.value === 'existingCustomer') ? a.enquiry.existingCustomer === true : true)

            ), function (e) {
                return {
                    documentID: e.documentID,
                    amount: e.payableOnDelivery ? Math.round((e.payableOnDelivery + Number.EPSILON) * 100) / 100 : 0
                }
            })
            )


            let totalLeads = arrnewLeads.length + arrexisitngLeads.length;
            var arrtotal = [...arrnewLeads, ...arrexisitngLeads];
            reports.push({
                id: rec.value,
                name: rec.name,
                profileImage: CommonHelper.showUserAvatar(rec.data.profileImage, rec.name),
                arrnewLeads: arrnewLeads,
                arrexisitngLeads: arrexisitngLeads,
                arrtotal: [...arrnewLeads, ...arrexisitngLeads],
                arropenLeads: arropenLeads,
                arrwonLeads: arrwonLeads,
                arrlostLeads: arrlostLeads,
                arrdeliveredLeads: arrdeliveredLeads,
                arrquotes: arrquotes,
                arrquoteAmount: arrquoteAmount.map(rec => { return rec.documentID }),
                arrwonAmount: arrwonAmount.map(rec => { return rec.documentID }),
                new: arrnewLeads.length,
                existing: arrexisitngLeads.length,
                total: arrtotal.length,
                open: arropenLeads.length,
                won: _.sumBy(arrwonLeads, 'soldCount'),
                lost: arrlostLeads.length,
                delivered: _.sumBy(arrdeliveredLeads, 'soldCount'),
                quotes: arrquotes.length,
                formatquoteAmount: <NumberFormat
                    thousandSeparator={true}
                    prefix={currencySymbol}
                    displayType={'text'}
                    value={Math.round((_.sumBy(arrquoteAmount, "amount") + Number.EPSILON) * 100) / 100}
                />,
                formatwonAmount: <NumberFormat
                    thousandSeparator={true}
                    prefix={currencySymbol}
                    displayType={'text'}
                    value={Math.round((_.sumBy(arrwonAmount, "amount") + Number.EPSILON) * 100) / 100}
                />,
                strquoteAmount: `${currencySymbol}${Math.round((_.sumBy(arrquoteAmount, "amount") + Number.EPSILON) * 100) / 100}`,
                strwonAmount: `${currencySymbol}${Math.round((_.sumBy(arrwonAmount, "amount") + Number.EPSILON) * 100) / 100}`,
                quoteAmount: Math.round((_.sumBy(arrquoteAmount, "amount") + Number.EPSILON) * 100) / 100,
                wonAmount: Math.round((_.sumBy(arrwonAmount, "amount") + Number.EPSILON) * 100) / 100,
                wonPercentage: totalLeads > 0 ? Math.round((100 / totalLeads) * _.sumBy(arrwonLeads, 'soldCount')) : 0,
                // wonConv: totalLeads > 0 ?Math.round((100 / totalLeads) * _.sumBy(arrwonLeads, 'soldCount')) + '%' : '0%',
            })
        })
        reports = reports.map(rec => {
            return {
                ...rec,
                wonPercentage: rec.wonPercentage > 100 ? 100 : rec.wonPercentage,
                wonConv: rec.wonPercentage > 100 ? `100%` : `${rec.wonPercentage}%`
            }
        })
        setProgressReport(reports)
        setRefreshClientData(false)

    }, [refreshClientData])

    const sortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [progressReport, sortConfig]);

    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const handleModelClose = () => {
        setUser(null)
        setFilter(null)
        setModelShow({
            enquiry: false,
            quote: false,
            activity: false
        })
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };



    const filterCounts = () => {
        let objFilter = {
            make: !_.isEmpty(selectedMake) ? selectedMake.value : '',
            model: !_.isEmpty(selectedModel) ? selectedModel.value : '',
            salesType: !_.isEmpty(selectedSaleType) ? selectedSaleType[0] : '',
        }

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    return (
        <>
            <h2>Sales Progress
                <Dropdown className='enquiry-view-more float-right mt-0'>
                    <Dropdown.Toggle as={CustomToggle} className='common-button'>
                        <i className='ico icon-more'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        as={CustomMenu}
                        ChildClass="more-dropdown dropsub-fix"
                        xplacement="bottom-end"
                    >
                        {
                            <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                                <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`salesprogress`}>
                                    <ExcelSheet dataSet={csvData} name="Sheet1" />
                                </ExcelFile>
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </h2>
            <div className="divider-line"></div>
            <div className="report-content-head">
                <div className="row">
                    <div className="col-sm-12 col-md-9">
                        <div className="row">
                            <div className="form-group col-md-3 pr-0 daterange-fix">
                                <AntDateRangePicker
                                    value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                    id={"dashboard-date-range"}
                                    name={'salesprogress-date'}
                                    format='DD/MM/YYYY'
                                    onChange={(e) => { handleDateRangeChange(e, 'salesprogress-date') }}
                                    placeholder='DD/MM/YYYY'
                                    onCalendarChange={(value) => {
                                        if (value) {
                                            const [start, end] = value;
                                            setDates([start, end]);
                                        }
                                        else {
                                            setDates([])
                                        }
                                    }}
                                    disabledDate={disabledDate}
                                />
                            </div>
                            <div className="form-group col-md-3 pr-0">
                                <ReactSelect
                                    options={leadTypes}
                                    name={"leadType"}
                                    placeholder={'select lead type'}
                                    onChange={(e, data) => {
                                        setLeadType(e)
                                        setRefreshClientData(true)
                                    }}
                                    value={!_.isEmpty(selectedLeadType) ? selectedLeadType.value : 'all'}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="form-group col-md-3">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    if (JSON.stringify(olddateRange) !== JSON.stringify(dateRange)) {
                                        setRefreshData(true)
                                        setOldDateRange(dateRange)
                                    }
                                    else {
                                        setRefreshClientData(true)
                                    }
                                }}> <i className="ico icon-search"></i> Search</button>
                                <button type="button" className="btn btn-default ml-3" onClick={() => {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                    })
                                    setOldDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                    })
                                    setSelectedMake(null)
                                    setSelectedModel(null)
                                    setSelectedService(null)
                                    setSelectedSaleType(null)
                                    setLeadType(null)
                                    setRefreshData(true)
                                }}>Clear</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="float-right">
                            <Dropdown ref={node} alignRight show={filterShow}>
                                <div className="advanced-filter-btn mr-2 float-right">
                                    <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                        setShow(!filterShow)
                                    }}>
                                        {filterCounts()}
                                        <i className="ico icon-filter"></i>Filters
                                    </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu as={CustomFilter}
                                    id="table-reports-cols-settings-pipeline"
                                    className="dropdown-menu dropdown-menu-left"
                                    ChildClass="form-style quick-filter-wrap"
                                    xplacement="bottom-end"
                                >
                                    <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                        <div className="report-filter-wraper form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Make</label>
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={(e) => {
                                                            setSelectedMake(e)
                                                            setSelectedModel(null)
                                                        }}
                                                        value={selectedMake ? selectedMake.value : ''}
                                                        classNamePrefix={` basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Model</label>
                                                    <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={(e) => {
                                                            setSelectedModel(e)
                                                        }}
                                                        value={selectedModel ? selectedModel.value : ''}
                                                        classNamePrefix={` basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Sales Type</label>
                                                    <ReactMultiSelect
                                                        options={salesTypes}
                                                        name={"saleType"}
                                                        placeholder={'select sale type'}
                                                        onChange={(selectedOptions) => {
                                                            let value = [];
                                                            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                value.push(data.value)
                                                            })
                                                            setSelectedSaleType(value)
                                                        }}
                                                        value={!_.isEmpty(selectedSaleType) ? selectedSaleType : []}
                                                        classNamePrefix={` basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Service Packages</label>
                                                    <ReactMultiSelect
                                                        options={servicePkgs}
                                                        name={"service"}
                                                        placeholder={'select servivce package'}
                                                        onChange={(selectedOptions) => {
                                                            let value = [];
                                                            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                value.push(data.value)
                                                            })
                                                            setSelectedService(value)
                                                        }}
                                                        value={!_.isEmpty(selectedService) ? selectedService : []}
                                                        classNamePrefix={` basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="quick-filter-footer">
                                        <button type="button" className="btn btn-default float-left" onClick={() => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setOldDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setSelectedMake(null)
                                            setSelectedModel(null)
                                            setSelectedSaleType(null)
                                            setSelectedService(null)
                                            setLeadType(null)
                                            setRefreshData(true)
                                            setShow(false)
                                            setShowCount(false)
                                        }}>Clear</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                                            setRefreshClientData(true)
                                            setShow(false)
                                            setShowCount(true)
                                        }}>Apply</button>

                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className="common-table" style={{ overflowX: 'auto' }}>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                {/* <button onClick={() => {
                                    requestSort('name')
                                }}>User</button> */}
                                <td className="head-light" width="16%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('name')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'name' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        User
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('new')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'new' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        New
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('existing')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'existing' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Existing
                                    </div>
                                </td>
                                <td className="head-light text-center table-middle-total" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('total')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'total' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Total
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('open')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'open' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        {_.find(enquiryStatus, { value: 'open' }) ? `${_.find(enquiryStatus, { value: 'open' }).name}` : 'Open'}
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('quotes')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'quotes' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Quotes Generated
                                    </div>
                                </td>

                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('quoteAmount')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'quoteAmount' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Quotes Amount
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('won')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'won' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        {_.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name}` : 'Won'}
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('wonAmount')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'wonAmount' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Won Amount
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('delivered')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'delivered' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        {_.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'}
                                    </div>
                                </td>
                                <td className="head-light text-center" width="7%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('lost')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'lost' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        {_.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name}` : 'Lost'}
                                    </div>
                                </td>


                                <td className="head-light text-center" width="7%">  <div className="sort-cell cursor-pointer" onClick={(e) => {
                                    e.preventDefault()
                                    requestSort('wonPercentage')
                                }}>
                                    <div className="table-sort"><a href="#">
                                        {
                                            sortConfig && sortConfig.key === 'wonPercentage' ?
                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                        }
                                    </a>
                                    </div>
                                    Conversion Ratio
                                </div></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedItems.length > 0 ?
                                    sortedItems.filter(a => a.total > 0).map((rec, index) => {
                                        return <tr key={index}>
                                            <td>
                                                <div className="table-user-list">
                                                    <a href="#">
                                                        <div className="table-user-avatar">
                                                            <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                        {rec.name}
                                                    </a>
                                                </div>
                                            </td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        enquiryIDs: rec[`arrnewLeads`]
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{rec.new}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        enquiryIDs: rec[`arrexisitngLeads`]
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{rec.existing}</td>
                                            <td className="text-center cursor-pointer table-middle-total"
                                                onClick={() => {
                                                    setFilter({
                                                        enquiryIDs: rec[`arrtotal`]
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{rec.total}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        enquiryIDs: rec[`arropenLeads`]
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{rec.open}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        documentIDs: rec[`arrquotes`]
                                                    })
                                                    setPopHeader('Quotes Generated')
                                                    setModelShow({
                                                        ...modelShow,
                                                        quote: true
                                                    })
                                                }}>{rec.quotes}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        documentIDs: rec[`arrquoteAmount`]
                                                    })
                                                    setPopHeader('Quotes Amount')
                                                    setModelShow({
                                                        ...modelShow,
                                                        quote: true
                                                    })
                                                }}>{rec.formatquoteAmount}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        enquiryIDs: rec[`arrwonLeads`]
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}
                                            >{rec.won}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        documentIDs: rec[`arrwonAmount`]
                                                    })
                                                    setPopHeader('Won Amount')
                                                    setModelShow({
                                                        ...modelShow,
                                                        quote: true
                                                    })
                                                }}>{rec.formatwonAmount}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        enquiryIDs: rec[`arrdeliveredLeads`]
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}
                                            >{rec.delivered}</td>
                                            <td className="text-center cursor-pointer"
                                                onClick={() => {
                                                    setFilter({
                                                        enquiryIDs: rec[`arrlostLeads`]
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{rec.lost}</td>

                                            <td className="text-center cursor-pointer">{rec.wonConv}</td>


                                        </tr>
                                    }) : (
                                        <tr>
                                            <td colSpan="13">
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                        <div className="no-data-txt mt-2"> No data</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                        {
                            progressReport.length > 0 ? (
                                <tfoot>
                                    <tr>
                                        <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrnewLeads`]]
                                            })
                                            setFilter({
                                                enquiryIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(progressReport, "new")}</td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrexisitngLeads`]]
                                            })
                                            setFilter({
                                                enquiryIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(progressReport, "existing")}</td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrtotal`]]
                                            })
                                            setFilter({
                                                enquiryIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(progressReport, "total")}</td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arropenLeads`]]
                                            })
                                            setFilter({
                                                enquiryIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(progressReport, "open")}</td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrquotes`]]
                                            })
                                            setFilter({
                                                documentIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Quotes Generated')
                                            setModelShow({
                                                ...modelShow,
                                                quote: true
                                            })
                                        }}>{_.sumBy(progressReport, "quotes")}</td>

                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrquoteAmount`]]
                                            })
                                            setFilter({
                                                documentIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Quotes Amount')
                                            setModelShow({
                                                ...modelShow,
                                                quote: true
                                            })
                                        }}><NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={Math.round((_.sumBy(progressReport, "quoteAmount") + Number.EPSILON) * 100) / 100}
                                            />
                                        </td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrwonLeads`]]
                                            })
                                            setFilter({
                                                enquiryIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(progressReport, "won")}</td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrwonAmount`]]
                                            })
                                            setFilter({
                                                documentIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Won Amount')
                                            setModelShow({
                                                ...modelShow,
                                                quote: true
                                            })
                                        }}><NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={Math.round((_.sumBy(progressReport, "wonAmount") + Number.EPSILON) * 100) / 100}
                                            />
                                        </td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrdeliveredLeads`]]
                                            })
                                            setFilter({
                                                enquiryIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(progressReport, "delivered")}</td>
                                        <td className="text-center cursor-pointer table-sub-total" onClick={() => {
                                            var IDs = [];
                                            progressReport.forEach(rec => {
                                                IDs = [...IDs, ...rec[`arrlostLeads`]]
                                            })
                                            setFilter({
                                                enquiryIDs: _.uniq(IDs)
                                            })
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(progressReport, "lost")}</td>


                                        <td className="text-center cursor-pointer table-sub-total">{_.sumBy(progressReport, "total") > 0 ? (Math.round((100 / _.sumBy(progressReport, "total")) * _.sumBy(progressReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "total")) * _.sumBy(progressReport, "won"))) + '%' : '0%'}</td>

                                    </tr>
                                </tfoot>
                            ) : (<></>)
                        }

                    </table>
                </div>
                )
            }
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={allEnquiries}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedUser={selectedUser}
                    selectedMake={selectedMake}
                    selectedModel={selectedModel}
                    selectedLeadType={selectedLeadType}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.quote}>
                <QuoteList
                    {...props}
                    show={modelShow.quote}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={allQuotations}
                    filter={filter}

                ></QuoteList>
            </PopUpModal>
        </>
    )
}

export default SalesProgress;