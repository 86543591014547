import React, { useState, useEffect, useReducer } from 'react';
import toast from 'toasted-notes'
import _ from 'lodash'

import { ContentOverlay, PopUpModal } from '../../components'
import Translate from '../../constants/translate';
import { default as _images } from "../../images";
import FrontDeskReducer from '../cafe/history/historyReducer'
import ActiveView from './activeView'
import InActiveView from './inactiveView'
import RejectContactList from './rejectContact'
import ReArrangeQueue from './reArrangeQueue'
import Timer from './timer'
import EngageContact from './engageContact'
import { engageStatus } from '../../services/enum'
import { firestoreDB } from "../../services/helper";
import { objQueue } from './viewModel'

const FrontDeskList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [frontDesk, dispatch] = useReducer(FrontDeskReducer, {})
    const [checkDataLoad, setDataload] = useState({})
    const [frontDeskLoader, setLoader] = useState({})
    const [activeTab, setActiveTab] = useState(localStorage['frontdesk' + props.dealersettings.client.id] ? localStorage['frontdesk' + props.dealersettings.client.id] : '');
    const [departments, setDepartments] = useState([])
    const [activeUsers, setActiveUsers] = useState([])
    const [activeQueueTab, setActiveQueueTab] = useState('Active');
    const [searchText, setSearchText] = useState('')
    const [modelShow, setModelShow] = useState(false)
    const [engageUserID, setEngageUserID] = useState('')
    const [modelRejectShow, setModelRejectShow] = useState(false)
    const [modelReArrageShow, setModelReArrageShow] = useState(false)
    const [rejectCount, setRejectCount] = useState([])
    const [clientUsers, setClientUsers] = useState([]);

    useEffect(() => {

        const clientUserSnapshot = window.firebase.firestore().collection('users')
            .where('arrayClientID', 'array-contains', props.dealersettings.client.id)
            .onSnapshot(querysnapshot => {
                let users = []
                querysnapshot.forEach((doc) => {
                    const _user = {
                        ...doc.data(),
                        documentID: doc.id,
                        id: doc.id
                    }
                    if (_user.isDisabled !== true) {
                        users.push(_user);
                    }
                });
                setClientUsers(users)
            });

        return () => {
            clientUserSnapshot && clientUserSnapshot()
        }
    }, [])

    // useEffect(() => {
    //     getUserSettings();
    // }, [props.clientUsers])

    // async function getUserSettings() {
    //     let users = props.clientUsers;
    //     await asyncForEach(users, async (_user) => {
    //         const userClientSnapshot = await window.firebase.firestore().doc(`users/${_user.id}/userClients/${clientid}`).get()
    //         const settingSnapshot = await window.firebase.firestore().doc(`clientSettings/${settingsid}`).get()
    //         let department = userClientSnapshot.exists ? userClientSnapshot.data().department : '';
    //         let departments = settingSnapshot.exists ? settingSnapshot.data().departments : null;
    //         let departmentName = department;
    //         if (department && departments && typeof departments === 'object' && Object.keys(departments).length > 0) {
    //             var userDepartment = departments.filter(item => item.value === department).length > 0 ? departments.filter(item => item.value === department)[0] : null;
    //             if (userDepartment)
    //                 departmentName = userDepartment.name
    //         }
    //         if (!_.isEmpty(userClientSnapshot.data().roleID)) {
    //             const roleSnapshot = await window.firebase.firestore().doc(`clientSettings/${settingsid}/roles/${userClientSnapshot.data().roleID}`).get()
    //             users.map((item) => {
    //                 if (item.id === _user.id) {
    //                     item.role = roleSnapshot.exists ? roleSnapshot.data().name : '';
    //                     return item;
    //                 }
    //                 return item;
    //             });
    //         }
    //         users.map((item) => {
    //             if (item.id === _user.id) {
    //                 item.roleID = userClientSnapshot.exists ? userClientSnapshot.data().roleID : '';
    //                 //item.role = roleSnapshot.exists ? roleSnapshot.data().name : '';
    //                 item.queueNum = userClientSnapshot.exists ? userClientSnapshot.data().queueNum : '';
    //                 item.department = department;
    //                 item.departmentName = departmentName;
    //                 return item;
    //             }
    //             return item;
    //         });
    //     });
    //     setClientUsers(users)
    // };

    useEffect(() => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings) {
            const setttings = props.dealersettings.client.settings;

            let clientID = props.dealersettings.client.id
            const clientUserSnapshot = window.firebase.firestore().collection(`clients/${clientID}/queues`)

            window.unSubLoadList = clientUserSnapshot
                .onSnapshot(onLoadCollectionUpdate);
        }
    }, [])

    useEffect(() => {
        if (!props.dealersettings)
            return;

        let clientID = props.dealersettings.client.id
        const clientUserSnapshot = firestoreDB(props.dealersettings).firestore().collection('engageRequests')
            .where('clientID', '==', clientID)
            .where('status', '==', engageStatus.REJECT)
            .where('isDeleted', '==', false)

        window.unSubRejectList = clientUserSnapshot
            .onSnapshot(onRejectedCollectionUpdate);

    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubForntDeskList && window.unSubForntDeskList();
            window.unSubRejectList && window.unSubRejectList();
            window.unSubLoadList && window.unSubLoadList();
        }
    }, [])

    useEffect(() => {
        if (checkDataLoad && !checkDataLoad[activeTab]) {
            //console.log('CHECK-DATA-LOAD- RETURN', activeTab, checkDataLoad, departments)
            return;
        }

        let clientID = props.dealersettings.client.id
        let _department = activeTab;
        //console.log('check-Data-Load-', checkDataLoad, activeTab, departments)

        window.unSubForntDeskList = window.firebase.firestore()
            .doc(`clients/${clientID}/queues/${_department}`)
            .onSnapshot(onCollectionUpdate);

    }, [checkDataLoad, activeTab])


    const onCollectionUpdate = (docSnapshot) => {
        if (!docSnapshot.exists) {
            return;
        }

        const queue = Object.assign({}, objQueue);
        const _obj = Object.assign({}, docSnapshot.data());
        //console.log('onCollectionUpdate', docSnapshot.id, _obj)
        for (let [key, value] of Object.entries(_obj)) {
            queue[key] = value;
        }
        queue.documentID = docSnapshot.id;
        //console.log('onCollectionUpdate', activeTab, queue)

        dispatch({
            type: "SUCCESS_LIST",
            data: queue,
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...frontDeskLoader,
            [activeTab]: false
        })
    }

    const onRejectedCollectionUpdate = (querySnapshot) => {
        let rejectCount = [];
        querySnapshot.forEach((docSnapshot) => {
            rejectCount.push(docSnapshot.id);
        });
        setRejectCount(rejectCount)
    }

    const onLoadCollectionUpdate = (querySnapshot) => {
        let departments = [];
        let _departmentsort = [];
        let dataload = {}
        let activeUsers = [];
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings) {
            const setttings = props.dealersettings.client.settings;
            let _departments = setttings.departments;

            let actionType;
            querySnapshot.docChanges().forEach(change => {
                if (change.oldIndex >= 0 && (change.type === 'added' || change.type === 'modified' || change.type === 'removed'))
                    actionType = change.doc.id;
            })

            querySnapshot.forEach((docSnapshot) => {
                dataload[docSnapshot.id] = true;
                const _objDep = Object.assign({}, _departments.filter(item => item.value === docSnapshot.id)[0]);
                departments.push(_objDep);

                const _obj = Object.assign({}, docSnapshot.data());
                if (!_.isEmpty(_obj) && !_.isEmpty(_obj.users)) {
                    _obj.users.forEach((userid) => {
                        activeUsers.push(userid);
                    });
                }
            });

            _departments && _departments.forEach((_objDep) => {
                if (departments.filter(item => item.value === _objDep.value)[0])
                    _departmentsort.push(_objDep);
            });

            setDataload(dataload)
            setLoader(dataload)
            setDepartments(_departmentsort)
            setActiveUsers(activeUsers)
            //console.log('front-desk', !_.isEmpty(_departmentsort) && activeTab && _.isEmpty(_departmentsort.filter(m => m.value === activeTab)))
            if (!_.isEmpty(_departmentsort) && activeTab && _.isEmpty(_departmentsort.filter(m => m.value === activeTab))) {
                setActiveTab(actionType ? actionType : (!_.isEmpty(_departmentsort[0]) ? _departmentsort[0].value : ''))
            }

            if (!activeTab)
                setActiveTab(actionType ? actionType : (!_.isEmpty(_departmentsort[0]) ? _departmentsort[0].value : ''))


        }

    }

    const handleClose = (id) => {
        setModelShow(false);
        setEngageUserID('');
    }

    const handleShow = (id) => {
        setModelShow(true);
        setEngageUserID(id);
    }

    const handleRejectClose = (id) => {
        setModelRejectShow(false);
        setEngageUserID('');
    }

    const handleRejectShow = (id) => {
        setModelRejectShow(true);
        setEngageUserID(id);
    }

    const handleReArraneClose = (_dep, userIds) => {
        setModelReArrageShow(false);
        //console.log('handleReArraneClose', activeTab, _dep, userIds);
        if (_dep && (!_.isEmpty(userIds))) {
            const { dealersettings } = props;

            let clientID = dealersettings.client.id
            let _department = _dep;
            let modifiedBy = dealersettings ? dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();

            const updateRef = window.firebase.firestore().doc(`clients/${clientID}/queues/${_department}`)
            updateRef.update({
                'users': userIds,
                modifiedBy,
                modifiedDate
            }).then((docRef) => {
                toast.notify('Queue re-arranged successfully', {
                    duration: 2000
                })
                //setActiveTab(_dep);
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }

    }


    const handleActiveUsers = () => {
        const _activeUsers = [];

        modelRejectShow && activeUsers && activeUsers.map((userid) => {
            var user = clientUsers.filter(item => item.id === userid).length > 0 ? clientUsers.filter(item => item.id === userid)[0] : null;
            if (user)
                _activeUsers.push({ ...user, active: (user.active === true ? true : (user.isDeleted === false ? true : false)) });
        })

        return _activeUsers;
    }

    const enableUserQueue = (userID) => {
        handleUserQueue(userID, true)
    }

    const disableUserQueue = (userID) => {
        handleUserQueue(userID, false)
    }

    const handleUserQueue = (userID, checked) => {
        const { dealersettings } = props;
        let clientID = dealersettings.client.id;
        //console.log(userID, checked, clientID)
        // window.firebase.firestore()
        //     .doc(`users/${userID}/userClients/${clientID}`)
        //     .update({ isQueue: checked }).catch((error) => {
        //         console.error("Error updating contacts: ", error);
        //     });

        window.firebase.firestore().doc(`users/${userID}/userClients/${clientID}`).get().then(doc => {
            if (doc.exists) {
                //console.log(doc.data());
                if (doc.data().isQueue === checked)
                    doc.ref.update({ isQueue: !checked })

                doc.ref.update({ isQueue: checked })
            }
        })
    }

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const queueReorder = ((!_.isEmpty(_permissions) && _permissions.queueReorder) ? true : false);
    const frontdeskQueueActivation = ((!_.isEmpty(_permissions) && _permissions.frontdeskQueueActivation) ? true : false);

    // console.log('Frontdesk==>', activeTab, '==>', frontDesk[activeTab], props.clientUsers)
    return (
        <div className="middle-wrapper">

            <div className="filter-panel">
                <div className="tabber-nav float-right">
                    <ul>
                        {
                            departments && departments.map(data => {
                                return (<li key={data.value}><a href="#" className={`${activeTab === data.value ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab(data.value);
                                        localStorage['frontdesk' + props.dealersettings.client.id] = data.value;
                                    }}><Translate text={data.name} /> </a></li>)
                            })
                        }
                    </ul>
                </div>
            </div>

            <div className="frontdesk-panel">

                <div className="frontdesk-column-head mt-2 tabs">
                    <div className="d-flex justify-content-between ">
                        <div className="">
                            <div className="nav nav-tabs nav-fill">
                                <a href="#" className={`nav-item nav-link ${activeQueueTab === 'Active' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveQueueTab('Active');
                                    }}><Translate text={'active'} /> </a>

                                <a href="#" className={`nav-item nav-link ${activeQueueTab === 'Inactive' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveQueueTab('Inactive');
                                    }}><Translate text={'inactive'} /> </a>

                                <a href="#" className={`nav-item nav-link ${activeQueueTab === 'Offline' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveQueueTab('Offline');
                                    }}><Translate text={'offline'} /> </a>
                            </div>
                        </div>


                        {
                            frontDesk && frontDesk[activeTab] && frontDesk[activeTab].qTimer === true && activeQueueTab === 'Active' && frontDesk[activeTab].users.length > 0
                                ?
                                <>
                                    <Timer
                                        data={frontDesk[activeTab]}
                                    >
                                    </Timer>
                                </>
                                :
                                <></>
                        }

                        <div className="">
                            <div className="rejected-btn-wrap">
                                {
                                    queueReorder
                                        ?
                                        <>
                                            <button type="button" className="btn btn-sm btn-rearrange ml-2" onClick={() => {

                                                if (frontDesk && frontDesk[activeTab] && frontDesk[activeTab].users && frontDesk[activeTab].users.length > 0)
                                                    setModelReArrageShow(true);
                                            }}>
                                                <i className="ico icon-rearrange"></i>
                                            </button>
                                        </>
                                        :
                                        <></>
                                }

                                <button type="button" className="btn btn-sm btn-rejected" onClick={() => {
                                    if (rejectCount.length > 0)
                                        handleRejectShow();
                                }}>
                                    <i className="ico icon-rejected"></i> <Translate text={'Rejected'} />
                                    <span className="badge badge-pill badge-rejected-count ml-1">{rejectCount.length}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="frontdesk-column-middle mt-3">
                    {
                        frontDeskLoader && frontDeskLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                            frontDesk[activeTab] ? (
                                <>
                                    {
                                        activeQueueTab === 'Active'
                                            ?
                                            <>
                                                {
                                                    <ActiveView
                                                        users={frontDesk[activeTab].users}
                                                        activeTab={activeTab}
                                                        activeQueueTab={activeQueueTab}
                                                        clientUsers={clientUsers}
                                                        groupUsers={props.groupUsers}
                                                        handleShow={handleShow}
                                                        handleUserQueue={disableUserQueue}
                                                        frontdeskQueueActivation={frontdeskQueueActivation}
                                                    >
                                                    </ActiveView>
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    activeQueueTab === 'Inactive'
                                                        ?
                                                        <>
                                                            {
                                                                <InActiveView
                                                                    users={clientUsers.filter(item => item.department === activeTab && frontDesk[activeTab].users.indexOf(item.id) < 0 && !_.isEmpty(item.tokkens))}
                                                                    activeTab={activeTab}
                                                                    activeQueueTab={activeQueueTab}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={props.groupUsers}
                                                                    handleUserQueue={enableUserQueue}
                                                                    frontdeskQueueActivation={frontdeskQueueActivation}
                                                                >
                                                                </InActiveView>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                <InActiveView
                                                                    users={clientUsers.filter(item => item.department === activeTab && frontDesk[activeTab].users.indexOf(item.id) < 0 && _.isEmpty(item.tokkens))}
                                                                    activeTab={activeTab}
                                                                    activeQueueTab={activeQueueTab}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={props.groupUsers}
                                                                    frontdeskQueueActivation={false}
                                                                >
                                                                </InActiveView>
                                                            }

                                                        </>



                                                }

                                            </>
                                    }
                                </>
                            ) : (
                                <div className="common-table">
                                    <div className="text-center p-5">
                                        <p><Translate text={'No record found'} /></p>

                                    </div>
                                </div>
                            )
                    }
                </div>


            </div>

            <PopUpModal show={modelShow}>
                <EngageContact
                    show={modelShow}
                    userID={engageUserID}
                    handleClose={handleClose}
                    dealersettings={props.dealersettings}
                    clientUsers={clientUsers.filter(item => item.department === activeTab)}
                >
                </EngageContact>
            </PopUpModal>

            <PopUpModal show={modelRejectShow}>
                <RejectContactList
                    show={modelRejectShow}
                    userID={engageUserID}
                    handleClose={handleRejectClose}
                    dealersettings={props.dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={props.groupUsers}
                    activeUsers={handleActiveUsers()}
                    rejectCount={rejectCount.length}
                >
                </RejectContactList>
            </PopUpModal>

            <PopUpModal show={modelReArrageShow}>
                <ReArrangeQueue
                    show={modelReArrageShow}
                    handleClose={handleReArraneClose}
                    dealersettings={props.dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={props.groupUsers}
                    activeUsers={frontDesk[activeTab] ? frontDesk[activeTab].users : []}
                    activeTab={activeTab}
                    title='rearrangeQueue'
                >
                </ReArrangeQueue>
            </PopUpModal>
        </div >
    )
}

export default FrontDeskList;


