export const vehicleModelFields = [
    {
        name: 'Name',
        value: 'name',
        sortValue: 'name',
        flex: 1,
        default: true
    },
    {
        name: 'Group',
        value: 'group',
        sortValue: 'group',
        flex: 1,
        default: true
    },
    {
        name: 'Type',
        value: 'type',
        sortValue: 'type',
        flex: 1,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        sortValue: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch',
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 200,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
    // {
    //     name: 'Active',
    //     value: 'active',
    //     sortValue: 'active',
    //     flex: 0,
    //     width: 120,
    //     default: true,
    //     type: 'switch', 
    //     fixedRight: true
    // }
]

export const vehicleModelPorscheFields = [
    {
        name: 'Model',
        value: 'groupmodel',
        sortValue: 'groupmodel',
        flex: 1,
        default: true
    },{
        name: 'Model Variant',
        value: 'modelvariant',
        sortValue: 'modelvariant',
        flex: 1,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        sortValue: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch',
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 200,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
    // {
    //     name: 'Active',
    //     value: 'active',
    //     sortValue: 'active',
    //     flex: 0,
    //     width: 120,
    //     default: true,
    //     type: 'switch', 
    //     fixedRight: true
    // }
]

export const modelVM = { 
    name: '',
    value: '',
    group: '',
    type: ''
}

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]