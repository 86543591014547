export const formDataModel = {
	documentID: '',
	name: '',
	email: '',
	phone: '',
	phoneCode: '',
	profileImage: '',
	state: '',
	country: '',
	areaCode: '',
	address: '',
	workPhone: '',
	personelEmail: '',
	isCafeOrderNotifySound: '',
	isNotifyWithSound: '',
	ownDashboard: '',
	notifyInboundlead: '',
	notifyInboundleadOEM: '',
	licenseBackURL: '',
	licenseFrontURL: '',
	licenseExpiry: null,
	licenseNo: '',
	enableMFA: false,
	designation: '',
	isWholesaler: false,
	notifyNewtradeinPro: null,
	notifyServiceBooking: null,
	language: null,
	notifyKitchen: null,
	notifyEmail: null,
	emailInboundLead: null,
	emailLostApprovalRequest: null,
	emailScheduleActivity: null,
	emailMentionNotes: null,
	emailTeammates: null,
	emailTransferLeads: null,
	notifyInboundleadTIP: null,
	currentDealerNorification: null
};

export const cropShowModel = {
	show: false,
	clsActive: '',
	src: null,
	id: '',
	type: '',
	ext: '',
	title: '',
};

export const objectPasswordModel = {
	password: '',
	cpassword: '',
	currentpassword: '',
};
