import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

import { StageProvider, StageConsumer } from './provider'
import { LayoutConsumer } from '../layout/provider';
import Translate from '../../constants/translate';
import { ContentOverlay, SidePanel, TableView, PopUpModal } from '../../components'
import QuickView from "./quickview";
import FilterPanel from './filterOEMPanel'
import CommonHelper from '../../services/common';
import { objEnquiryListVM, allenquiryFields } from './viewModel'
//import { allAllocatedFields } from '../oemAllocation/viewModel'
import images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import { firestoreDB } from '../../services/helper';

const EnquiryListReducer = (state, action) => {
    function updateEnquiry() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_ENQUIRY_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_ENQUIRY_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_ENQUIRY_LIST": {
            return updateEnquiry()
        }
        default:
            return state;
    }
};


const PipelineOEMListView = (props) => {
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [enquiryFields, setEnquiryFields] = useState(props.dealersettings.enquiryFields)
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.enquiryOEMSearchText ? localStorage.enquiryOEMSearchText : '')
    const [enquiryLoader, setEnquiryLoader] = useState(true)
    //const [defaultPipeline, setDefaultPipeline] = useState(props.pipeline.find(item => item.default === true) ? props.pipeline.find(item => item.default === true).value : '')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})

    const [pipelineCount, setPipelineCount] = useState(0)

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [enquiryOptions, setEnquiryOptions] = useState([])
    const [financeStatuses, setFinanceStatuses] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();
    const [enquiryAllStatus, setAllStatus] = useState([]);

    const [showReAuthentication, setReAuthentication] = useState(false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);

    const financeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.finance) &&
        _moduleSettings.finance.enabled) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    useEffect(() => {
        let headerFields = enquiryFields;
        let allHeaderFields = allenquiryFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [enquiryFields])

    useEffect(() => {
        if (_.isEmpty(enquiryOptions))
            return
        enquiryOptions.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec.value))
                allenquiryFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        enquiryFields.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setEnquiryFields(_.differenceBy(enquiryFields, deleteids))
    }, [enquiryOptions])


    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _enquiryOptions = [];
            setttings.enquiryOptionsDF && setttings.enquiryOptionsDF.forEach(doc => {
                _enquiryOptions.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                _financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });



            let _pipelines = [];
            setttings.pipelines && setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _lostReasons = [];
            const _lostSubReasons = [];
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });

                !_.isEmpty(doc.subList) && doc.subList.forEach(docsub => {
                    _lostSubReasons.push({
                        value: docsub.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: docsub.name
                    });
                });
            });

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            allenquiryFields.forEach((obj) => {
                if (obj.value === 'wonOn') {
                    obj.name = CommonHelper.getNameByValue(_enquiryAllStatus, 'won', 'Won') + ' Date';
                }
                else if (obj.value === 'deliveredOn') {
                    obj.name = CommonHelper.getNameByValue(_enquiryAllStatus, 'delivered', 'Delivered') + ' Date';
                }
                else if (obj.value === 'lostOn') {
                    obj.name = CommonHelper.getNameByValue(_enquiryAllStatus, 'lost', 'Lost') + ' Date';
                }
            });

            setAllStatus(_enquiryAllStatus);
            setTitles(_titles);
            setEnquiryOptions(_enquiryOptions.filter(e => e.active === true));
            setFinanceStatuses(_financeStatuses)
            setLanguages(_languages);
            setNationalities(_nationalities)
            //setLostReasons(_lostReasons);
            setLostSubReasons(_lostSubReasons);
            setDataload(true);
        }
    }, [])

    useEffect(() => {
        if (isFilterApplied || props.pipeline.length === 0)
            return
        const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
        const dpl = props.pipeline.find(item => item.default === true).value
        var querySet = `clients/${props.dealersettings.client.id}`
        if (!viewOtherEnquiry || (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0)) {
            querySet = `users/${localStorage.uid}/userClients/${props.dealersettings.client.id}`
        }
        window.unSubPipelineLstCount = window.firebase.firestore().doc(querySet)
            .onSnapshot(docSnapshot => {
                if (docSnapshot.exists && docSnapshot.data().counts && docSnapshot.data().counts.pipeline && docSnapshot.data().counts.pipeline[dpl] && docSnapshot.data().counts.pipeline[dpl].stages) {
                    var stageCount = {};
                    props.pipeline.find(item => item.default === true).stages.forEach(stage => {
                        stageCount = dpl === 'LeadsBucket' && (stage.value === 'Converted' || stage.value === 'InboundLead') ? {
                            ...stageCount, [stage.value]: { ['total']: docSnapshot.data().counts.pipeline["LeadsBucket"].stages[stage.value] }
                        }
                            : {
                                ...stageCount,
                                [stage.value]: {
                                    ...docSnapshot.data().counts.pipeline[dpl].stages[stage.value],
                                    ['total']: sumObj(docSnapshot.data().counts.pipeline[dpl].stages[stage.value])
                                }
                            }
                    })
                    setPipelineCount(stageCount)
                }

            })
    }, [props.pipeline, isFilterApplied])

    const sumObj = (obj) => {
        var sum = 0;
        for (var el in obj) {
            if (_.isNumber(obj[el])) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'enquiry')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
    }, [])

    useEffect(() => {

        if (localStorage.pipelineOEMFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.pipelineOEMFilter])



    useEffect(() => {
        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (props.pipeline.length === 0) {
            return
        }

        if (!checkDataLoad) {
            return;
        }

        if (isFilterApplied && pageNum === 0)
            setEnquiryLoader(true)


        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        let _pipelineOEMFilter = localStorage.pipelineOEMFilter ? JSON.parse(localStorage.pipelineOEMFilter) : {};
        let _filter = Object.assign({}, _pipelineOEMFilter.value);

        if (_.isEmpty(_filter.pipeline))
            _filter.pipeline = props.pipeline.map(item => item.value).join(',')

        if (_.isEmpty(_filter.clientID) && !_.isEmpty(dealersettings.group.clients))
            _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')

        if (!viewOtherEnquiry)
            _filter.owner = dealersettings.id;

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
            delete _filter['date'];
        }

        // CONTACT AGE FILTER
        if (!_.isEmpty(_filter.contactAge)) {
            _filter.dateType = 'dob';
            _filter.startDate = moment().add(parseInt(`-${_filter.contactAge[1]}`), 'years').format('YYYY-MM-DD');
            _filter.endDate = moment().add(parseInt(`-${_filter.contactAge[0]}`), 'years').format('YYYY-MM-DD');
            delete _filter['contactAge'];
        }

        /* MAKE & MODEL FILTER */
        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

        if (_filter.rottenDays &&
            !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.settings) &&
            !_.isEmpty(dealersettings.client.settings.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
        }

        if (_.isEmpty(_filter.saleType) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
            _filter.saleType = dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        else if (!_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = excludeOrigins.includes(_filter.origin) ? null : _filter.origin

        _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
        let _searchObject = {
            "type": "searchEnquiries",
            "filters": JSON.stringify(_filter),
            "sortOrder": "stageDate desc",
            "pageNum": pageNum,
            "pageLimit": pageLimit,
            "clientID": null
        }
        //console.log('filter-searchEnquiries', pageNum, _filter);
        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        searchEnquiries(_searchObject).then((response) => {
            //console.log('generic-searchData',  response.data);
            if (response.data.success) {
                onCollectionEnquiry(response.data);
                setPipelineCount({
                    advance: {
                        total: response.data.total
                    }
                })
            }
            else {
                setEnquiryLoader(false)
                setDataload(false)
                setPaging(false)
            }
        });


        window.unSubPipelineLstCount && window.unSubPipelineLstCount();



    }, [checkDataLoad, props.pipeline, props.dealersettings])



    const onCollectionEnquiry = async (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }

        let _pipelineOEMFilter = !_.isEmpty(localStorage.pipelineOEMFilter) ? JSON.parse(localStorage.pipelineOEMFilter) : {};
        let _filter = Object.assign({}, _pipelineOEMFilter.value);
        let __dealersettings = null;
        if (!_.isEmpty(localStorage.pipelineOEMFilter) && !_.isEmpty(_filter.clientID) && !_filter.clientID.includes(',')) {
            const { dealersettings } = props;
            __dealersettings = await CommonHelper.updateClientSettings(_filter.clientID, Object.assign({}, dealersettings));
        }
        querySnapshot.data.forEach(function (doc) {
            var logVM = convertEnquiryVM(doc, __dealersettings);
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS",
            data: _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        //setDefaultPipeline(props.pipeline.find(item => item.default === true).value)
    }

    const convertEnquiryVM = (doc, clientSettings) => {

        doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;
        doc.convertedDate = doc.convertedDate && doc.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDate._seconds)._d) : doc.convertedDate;
        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
        doc.wonDate = doc.wonDate && doc.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.wonDate._seconds)._d) : doc.wonDate;
        doc.lostDate = doc.lostDate && doc.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lostDate._seconds)._d) : doc.lostDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;

        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
        doc.confirmedDate = doc.confirmedDate && doc.confirmedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.confirmedDate._seconds)._d) : doc.confirmedDate;
        doc.verifiedDate = doc.verifiedDate && doc.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.verifiedDate._seconds)._d) : doc.verifiedDate;
        doc.receivedDate = doc.receivedDate && doc.receivedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.receivedDate._seconds)._d) : doc.receivedDate;
        doc.appointmentDate = doc.appointmentDate && doc.appointmentDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.appointmentDate._seconds)._d) : doc.appointmentDate;
        doc.convertedDateOEM = doc.convertedDateOEM && doc.convertedDateOEM._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateOEM._seconds)._d) : doc.convertedDateOEM;
        doc.convertedDateRegion = doc.convertedDateRegion && doc.convertedDateRegion._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateRegion._seconds)._d) : doc.convertedDateRegion;
        doc.convertedDateGroup = doc.convertedDateGroup && doc.convertedDateGroup._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateGroup._seconds)._d) : doc.convertedDateGroup;
        if (!_.isEmpty(doc.lastNote)) {
            doc.lastNote.modifiedDate = doc.lastNote.modifiedDate && doc.lastNote.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lastNote.modifiedDate._seconds)._d) : doc.lastNote.modifiedDate;
        }
        doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
            return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]
        if (!_.isEmpty(doc.systemType)) {
            doc.tags = [{ type: doc.systemType }];
        }

        const objenquiryData = Object.assign({}, doc);
        const listVM = Object.assign({}, objEnquiryListVM);
        for (let [key, value] of Object.entries(objenquiryData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        listVM.objDoc = Object.assign({}, objenquiryData);
        listVM.displayID = listVM.displayID ? listVM.displayID : '--';
        listVM.originValue = doc.origin;
        listVM.leadSourceValue = doc.leadSource;
        listVM.enquiryTypeValue = doc.enquiryType;
        listVM.leadSourceValue = doc.leadSource;
        listVM.campaignValue = doc.campaign;
        listVM.statusValue = doc.status;
        listVM.labelValue = doc.label;
        listVM.pipelineValue = doc.pipeline;
        listVM.stageValue = doc.stage;
        listVM.ownerValue = doc.owner;

        listVM.financeStatusName = doc.financeStatus;
        listVM.labelName = doc.label;

        let dealersettings = props.dealersettings;

        if (doc.clientID && (doc.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group)
            listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.clientID);
        else
            listVM.dealerName = '';


        listVM.appointmentDateOn = doc.appointmentDate ? moment.unix(doc.appointmentDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.bdcStatusName = doc.bdcStatus === 'show' ? 'Show' : doc.bdcStatus === 'noshow' ? 'No Show' : doc.bdcStatus === 'allocated' ? 'Allocated' : '';
        listVM.strResponseTime = doc.responseTime > 0 ? CommonHelper.timeformatfromSeconds(doc.responseTime, props.dealersettings.client) : '';
        listVM.strAllocatedTime = doc.allocatedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.allocatedTime, props.dealersettings.client) : '';
        listVM.strContactedTime = doc.contactedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.contactedTime, props.dealersettings.client) : '';
        listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.wonOn = doc.wonDate && doc.status && (doc.status.toLowerCase() === 'won' || doc.status.toLowerCase() === 'delivered') ? moment.unix(doc.wonDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.lostOn = doc.lostDate && doc.status && (doc.status.toLowerCase() === 'lost' || doc.status.toLowerCase() === 'prospectLost') ? moment.unix(doc.lostDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.deliveredOn = doc.deliveredDate && doc.status && doc.status.toLowerCase() === 'delivered' ? moment.unix(doc.deliveredDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.deliveryOn = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.warrantyExpiry = doc.warrantyExpiry ? moment(doc.warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.warrantyStartDate = doc.warrantyStartDate ? moment(doc.warrantyStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.deliveryDate = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.confirmedBy = doc.confirmedBy ? CommonHelper.getUserNamebyId(allUsers, doc.confirmedBy) : '';
        listVM.confirmedDate = doc.confirmedDate ? moment.unix(doc.confirmedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.verifiedBy = doc.verifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.verifiedBy) : '';
        listVM.verifiedDate = doc.verifiedDate ? moment.unix(doc.verifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.receivedDate = doc.receivedDate ? moment.unix(doc.receivedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwner = doc.convertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.convertedBy) : '';

        listVM.convertedOnOEM = doc.convertedDateOEM ? moment.unix(doc.convertedDateOEM.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnRegion = doc.convertedDateRegion ? moment.unix(doc.convertedDateRegion.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnGroup = doc.convertedDateGroup ? moment.unix(doc.convertedDateGroup.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwnerOEM = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.convertedOwnerRegion = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.convertedOwnerGroup = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.nextActivityDate = doc?.nextActivity?.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc?.nextActivity?.startDate?._seconds)._d) : doc?.nextActivity?.startDate;

        let _enquiryAllStatus = [];
        let _dlSettings = clientSettings ? clientSettings : dealersettings;
        let _setttings = doc.clientID && _dlSettings && _dlSettings.group &&
            _dlSettings.group.clients && _dlSettings.group.clients[doc.clientID] && _dlSettings.group.clients[doc.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = _dlSettings && _dlSettings.client && _dlSettings.client.settings;

        if (_setttings) {
            listVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.campaign, '');
            listVM.origin = CommonHelper.getNameByValue(_setttings.origins, doc.origin, '');
            listVM.leadSource = CommonHelper.getNameByValue(_setttings.leadSource, doc.leadSource, '');
            listVM.enquiryType = CommonHelper.getNameByValue(_setttings.enquiryTypes, doc.enquiryType, '');
            //listVM.leadSource = CommonHelper.getNameByValue(_setttings.leadSource, doc.leadSource, '');
            listVM.lostReason = CommonHelper.getNameByValue(_setttings.lostReasons, doc.lostReason, '');
            listVM.labelName = CommonHelper.getNameByValue(_setttings.enquiryLabels, doc.label, '');
            listVM.label = CommonHelper.bindEnquiryLabel(_setttings.enquiryLabels, doc.label, '');

            listVM.pipeline = CommonHelper.getNameByValue(_setttings.pipelines, doc.pipeline, '');
            if (_.find(_setttings.pipelines, { value: doc.pipeline })) {
                listVM.stage = CommonHelper.getNameByValue(_.find(_setttings.pipelines, { value: doc.pipeline }).stages, doc.stage, '');
            }

            _setttings.enquiryStatus && _setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });
        }

        listVM.statusName = CommonHelper.getNameByValue(_enquiryAllStatus, doc.status);
        listVM.lostSubReason = CommonHelper.getLabelByValue(lostSubReasons, doc.lostSubReason, '');

        if (financeEnabled)
            listVM.financeStatus = doc.financeStatus ? (<div className={`badge badge-pill badge-${doc.financeStatus.toLowerCase()} ml-1`}>{doc.financeStatus.toUpperCase()}</div>) : '';
        else
            listVM.financeStatus = CommonHelper.getLabelByValue(financeStatuses, doc.financeStatus, '');

        let _objCurrentStatus = doc.status && _enquiryAllStatus.filter(e => e.value === doc.status)[0];
        listVM.status = doc.status ? (
            _objCurrentStatus
                ?
                <>
                    <span
                        className={`status-${doc.status === 'open' ? 'open' : 'empty'}`}
                        style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                    >
                        {_objCurrentStatus.name}
                    </span>
                </>
                :
                <></>
        ) : '';
        listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.owner = CommonHelper.getUserNamebyId(allUsers, doc.owner);
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.addedBy = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.modifiedBy = doc.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.modifiedBy) : '';
        listVM.overdueActivity = 0;
        listVM.todayActivity = 0;
        listVM.upcomingActivity = 0;
        listVM.doneActivity = 0;
        listVM.totalActivity = 0;
        listVM.depositAmount = doc.depositAmount ? currencySymbol + doc.depositAmount : '';
        listVM.grossAmount = doc.grossAmount ? currencySymbol + doc.grossAmount : '';
        listVM.csvQuoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? currencySymbol + doc.quotation.amount : '';

        listVM.quoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? <NumberFormat
            decimalScale={2}
            thousandSeparator={true}
            prefix={currencySymbol}
            displayType={'text'}
            value={doc.quotation.amount}
        /> : '';

        listVM.enquiryAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
        props.pipeline.filter(e => e.default)[0].stages.forEach(col => {
            if (listVM.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                    listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
                }
            }
        })

        if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
            listVM.vehicleModel = !_.isEmpty(doc.requirement.stock) && !_.isEmpty(doc.requirement.stock.make) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : doc.requirement.make + ' ' + doc.requirement.model
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;

            listVM.vehicleSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.requirement.saleType, '');
            listVM.vehicleType = <>
                <>{listVM.vehicleSaletype ? <>{listVM.vehicleSaletype}</> : <></>}</>
                <>{listVM.soldCount > 1 ? <div className="badge badge-pill badge-available badge-mini ml-1">{listVM.soldCount} WON</div> : <></>}</>
            </>
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings);
            listVM.displayPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings, true);
            listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
            listVM.email = doc.contact.email;
            listVM.licenseNo = doc.contact.licenseNo;
            listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            listVM.contactType = doc.contact.contactType;
            listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            listVM.gender = doc.contact.gender;
            listVM.maritalStatus = doc.contact.maritalStatus;
            listVM.contactMethod = doc.contact.contactMethod;
            listVM.licenseType = doc.contact.licenseType;
            listVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            listVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');

            // let _setttings = doc.clientID && dealersettings && dealersettings.group &&
            //     dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
            // if (_.isEmpty(_setttings))
            //     _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                listVM.gender = CommonHelper.getNameByValue(_setttings.genders, doc.contact.gender, '');
                listVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
                listVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
                listVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
                listVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');

                listVM.contactcampaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
                listVM.contactorigin = CommonHelper.getNameByValue(_setttings.origins, doc.contact.origin, '');
            }

            if (dealersettings.group && doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                listVM.contactdealerName = CommonHelper.getOtherDealerName(dealersettings, doc.contact.clientID);
            }
            else
                listVM.contactdealerName = '';

            listVM.address = doc.contact.address;
            listVM.companyName = doc.contact.companyName;
            listVM.designation = doc.contact.designation;

            listVM.licenseState = doc.contact.licenseState;
            listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            listVM.secondaryPhone = CommonHelper.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, props.dealersettings);
            listVM.secondaryEmail = doc.contact.secondaryEmail;
            listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            listVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
            listVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
            listVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
            listVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
            listVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
            listVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
        }
        else if (!_.isEmpty(doc.engageNewContact)) {
            let _contact = Object.assign({}, doc.engageNewContact);
            listVM.contactName = CommonHelper.displayFullContact([], _contact, '', true);
            listVM.phone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
            listVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings, true);
            listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
            listVM.email = _contact.email ? _contact.email : '';
            listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
        }

        if (!_.isEmpty(doc.dynamicFields)) {

            enquiryOptions.forEach(rec => {
                if (rec.type === 'toggle') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<></>)
                }
                else {
                    listVM[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        if (!_.isEmpty(doc.td)) {
            listVM.testdriveModel = !_.isEmpty(doc.td) && !_.isEmpty(doc.td.make) ? doc.td.make + ' ' + doc.td.model : ''
            listVM.testdriveSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.td.saleType, '');
        }

        if (!_.isEmpty(doc.lastNote)) {
            listVM.lastNotes = doc.lastNote.notes ? doc.lastNote.notes : ''
            listVM.actActionOn = doc.lastNote.modifiedDate ? moment.unix(doc.lastNote.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            listVM.actActionBy = doc.lastNote.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.lastNote.modifiedBy) : '';
            if (doc.lastNote.type === 'activity')
                listVM.lastActivityType = _setttings && _setttings.activityTypes && _.find(_setttings.activityTypes, { value: doc.lastNote.subType }) ? _.find(_setttings.activityTypes, { value: doc.lastNote.subType }).name : doc.lastNote.subType;
            if (doc.lastNote.type === 'log')
                listVM.lastActivityType = `${CommonHelper.autoCaps(doc.lastNote.subType)} ${doc.lastNote.subType === 'call' ? 'Log' : ''}`;
        }
        if (!_.isEmpty(doc.nextActivity)) {
            listVM.nextActivityStr = listVM.nextActivityDate ? moment.unix(listVM.nextActivityDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            listVM.nextActivityOn = listVM.nextActivityDate ? <span className={`${moment.unix(listVM.nextActivityDate.seconds) < moment().startOf('day') ? 'activity-due' : ''}`}>{moment.unix(listVM.nextActivityDate.seconds).format('DD/MM/YYYY hh:mm A')}</span> : '';
            listVM.nextActivityBy = doc.nextActivity.owner ? CommonHelper.getUserNamebyId(allUsers, doc.nextActivity.owner) : '';
        }

        if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
            listVM.domleadSource = (<div className="Vehicle-availability">
                {
                    doc.tags.map((rec, index) => {
                        return <div key={index}><span>{CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)}</span></div>
                    })
                }
            </div>);
            listVM.strleadSource = doc.tags.map((rec, index) => {
                return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)
            }).join(',');
        }

        return listVM
    }


    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ enquiryFields: fields }, { merge: true })
            .then(() => {
                setEnquiryFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('enquiryFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            sidepanelOpen(id)
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id) => {
        sidepanelOpen(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_ENQUIRY_LIST",
                data: enq
            });
            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${id}`)
                .set({
                    isFav: val
                }, { merge: true })
                .then(snapshot => {
                    // toast.notify('Enquiry updated successfully', {
                    //     duration: 2000
                    // })

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            let enq = convertEnquiryVM(objEnquiry);

            if (!_.isEmpty(enq)) {
                dispatch({
                    type: "UPDATE_ENQUIRY_LIST",
                    data: enq
                });
            }
        }

    }

    const handleStatusFilter = (_status) => {
        if (_status) {
            if (!_.isEmpty(localStorage.pipelineOEMFilter)) {
                let _pipelineOEMFilter = JSON.parse(localStorage.pipelineOEMFilter);
                if (!_.isEmpty(_pipelineOEMFilter.value)) {
                    // update with the existing keyword
                    _pipelineOEMFilter.value['status'] = _status
                }
                else {
                    // update with the new keyword
                    _pipelineOEMFilter.value = { 'status': _status }
                }
                handleApplyFilter(_pipelineOEMFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineOEMFilter = Object.assign({}, { name: 'Adv Filter', value: { 'status': _status }, type: 'filter' })
                handleApplyFilter(_pipelineOEMFilter);
            }
        }
        else if (!_.isEmpty(localStorage.pipelineOEMFilter)) {
            let _pipelineOEMFilter = JSON.parse(localStorage.pipelineOEMFilter);
            let _filter = Object.assign({}, _pipelineOEMFilter.value);
            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('status'))
                handleClearFilter();
            else {
                delete _pipelineOEMFilter.value['status'];
                handleApplyFilter(_pipelineOEMFilter);
            }
        }
    }

    const handleSearchText = (val) => {
        setSearchText(val);
        if (!_.isEmpty(_.trim(val))) {
            if (localStorage.pipelineOEMFilter) {
                let _pipelineOEMFilter = JSON.parse(localStorage.pipelineOEMFilter);
                let _filter = Object.assign({}, _pipelineOEMFilter.value);
                _filter['keywords'] = _.trim(val);
                _pipelineOEMFilter.value = _filter;
                handleApplyFilter(_pipelineOEMFilter);
            }
            else {
                let _pipelineOEMFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
                handleApplyFilter(_pipelineOEMFilter);
            }
        }
        else {
            handleClearFilter();
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('pipelineOEMFilter', JSON.stringify(_filter));
            localStorage.enquiryOEMSearchText = '';
            setFilterApplied(true);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.pipelineOEMFilter)) {
            localStorage.removeItem('pipelineOEMFilter');
            localStorage.enquiryOEMSearchText = '';
            setFilterApplied(false);
            setDataload(true);
            setEnquiryLoader(true)
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const excelDownload = async (total) => {
        //console.log('excelDownload', total);
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _pipelineOEMFilter = localStorage.pipelineOEMFilter ? JSON.parse(localStorage.pipelineOEMFilter) : {};
        let _filter = Object.assign({}, _pipelineOEMFilter.value);
        //_filter.pipeline = props.pipeline.find(item => item.default === true).value;
        const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if (_.isEmpty(_filter.pipeline))
            _filter.pipeline = props.pipeline.map(item => item.value).join(',')

        if (_.isEmpty(_filter.clientID) && !_.isEmpty(props.dealersettings.group.clients))
            _filter.clientID = Object.keys(props.dealersettings.group.clients).map(cid => cid).join(',')

        if (!viewOtherEnquiry)
            _filter.owner = props.dealersettings.id;

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        // CONTACT AGE FILTER
        if (!_.isEmpty(_filter.contactAge)) {
            _filter.dateType = 'dob';
            _filter.startDate = moment().add(parseInt(`-${_filter.contactAge[1]}`), 'years').format('YYYY-MM-DD');
            _filter.endDate = moment().add(parseInt(`-${_filter.contactAge[0]}`), 'years').format('YYYY-MM-DD');
            delete _filter['contactAge'];
        }

        /* MAKE & MODEL FILTER */
        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

        if (_filter.rottenDays &&
            !_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.settings) &&
            !_.isEmpty(props.dealersettings.client.settings.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.settings.pipelines)
        }
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
            {
                position: 'top',
                duration: null
            },
        )
        if (_.isEmpty(_filter.saleType) && _.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        else if (!_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = excludeOrigins.includes(_filter.origin) ? null : _filter.origin

        //_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings, null, null, true);
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        let _searchObject = {
            "type": "searchEnquiries",
            "filters": JSON.stringify(_filter),
            "sortOrder": "stageDate desc",
            "clientID": null,
            "pageNum": 0,
            "pageLimit": total > 4999 ? 9999 : (total ? total : 9999)
        }
        //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, null, true);

        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _clientSettings = props.dealersettings.client.settings;
            try {
                const _clientids = _.uniq(resp.data.data.map(r => { return r.clientID }));
                if (_clientids.length > 0) {
                    for (let i = 0; i < _clientids.length; i++) {
                        const _currentSettings = await window.firebase.firestore().doc(`clients/${_clientids[i]}/currentSettings/${_clientids[i]}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });
                        if (!_.isEmpty(_currentSettings)) {
                            Object.keys(_currentSettings.settings).forEach(setting => {
                                _clientSettings = {
                                    ..._clientSettings,
                                    [setting]: _.isArray(_currentSettings.settings[setting]) ? _.uniqBy(_.union(_clientSettings[setting], _currentSettings.settings[setting]), 'value') : _clientSettings[setting]
                                }
                            })
                        }
                    }
                }
            } catch (err) { }
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                //console.log('generic-searchData-enquiry', doc);
                var logVM = CommonHelper.convertEnquiryVM(doc, {
                    ...props, dealersettings: { ...props.dealersettings, client: { ...props.dealersettings.client, settings: _clientSettings } }
                });
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props));
            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'enquiries',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
        }
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion


    return (
        <LayoutConsumer>
            {({ pipeline, handlePipelineChange, dealersettings, clientUsers, groupUsers }) => dealersettings ? (


                <div className={`middle-wrapper ${pipeline.length === 0 ? 'h-100' : ''}`}>

                    {
                        pipeline.length === 0 ? (<></>) : (<FilterPanel
                            pipeline={pipeline}
                            customFilters={customFilters}
                            handlePipelineChange={(val) => {
                                handlePipelineChange(val);
                                //setFilterApplied(false);
                                //setDataload(true);
                                //setPaging(false);
                                //setHasMoreData([])
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.pipelineOEMFilter))
                                    localStorage.removeItem('pipelineOEMFilter');
                            }}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleApplyFilter={handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            handleStatusFilter={handleStatusFilter}

                            showMore={true}
                            stageCounts={pipelineCount}
                            csvHeader={csvHeader}
                            csvData={csvData}
                            datalist={enquiries}
                            view="list"
                            isFilterApplied={isFilterApplied}
                            searchText={searchText}
                            handlesearchText={(val) => {
                                handleSearchText(val);
                                // setSearchText(val);
                                // setFilterApplied(false);
                                // setDataload(true);
                                // setPaging(false);
                                // setHasMoreData([])
                                // setPageNum(0);
                                // if (!_.isEmpty(localStorage.pipelineOEMFilter))
                                //     localStorage.removeItem('pipelineOEMFilter');

                            }}
                            selectedIds={selectedIds}
                            excelDownload={excelDownload}
                            csvBtn={csvBtn}

                        />)
                    }
                    <div className={`${pipeline.length === 0 ? 'h-100' : 'mt-2'}`}>
                        {
                            pipeline.length === 0 ? (
                                <div className="spinner-loader h-100">
                                    <div className="no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                                        <div className="no-data-img-wrap"><img src={images[`nopipeline${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="350" alt="" /></div>
                                        <div className="no-data-txt-wrap">
                                            <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                                            <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                                        </div>
                                    </div>
                                </div>
                            ) : enquiryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (<div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={enquiries}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allenquiryFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(enquiryFields && enquiryFields.length > 0) ? [...enquiryFields.filter(item => item !== 'checkbox' && item !== 'documentID')] : allenquiryFields.filter(e => e.default === true && e.type !== 'checkbox' && e.type !== 'favorite').map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={enquiryDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        handleFavorite={handleFavorite}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        displayByValue={true}
                                    />
                                </div>
                                )
                        }


                    </div>


                    <PopUpModal show={showReAuthentication}>
                        <ReAuthenticate
                            show={showReAuthentication}
                            handleClose={handleReAuthOpenClose}
                            dealersettings={props.dealersettings}
                            privacy='file'
                        />
                    </PopUpModal>

                    <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
                        <StageConsumer>
                            {() => (

                                <SidePanel
                                    clsActive={showpanel.clsActive}
                                    clsName={showpanel.clsName}
                                    sidepanelClose={sidepanelClose}
                                    title=''
                                >
                                    <QuickView {...props}
                                        enquiryid={showpanel.enquiryid}
                                        isFilterApplied={isFilterApplied}
                                        updateListEnquiry={updateListEnquiry}
                                        sidepanelClose={sidepanelClose}
                                    //isReadOnlyView={true}
                                    />
                                </SidePanel>
                            )}
                        </StageConsumer>
                    </StageProvider>
                </div>

            ) : (<></>)}
        </LayoutConsumer>
    )
}

export default PipelineOEMListView;