/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import { default as _images } from '../../images';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common'
import { tipStatus } from '../../services/enum'

const EnquiryInfo = ({ fields, clientUsers, sidepanelOpen, history, showMore, dealersettings, companyView, groupUsers, isList, hideLogo }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();

    const { documentID, status, tradein, isTradein, addedBy, displayID, owner, offer, addedDate, contact, finalPrice } = fields;

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const _jobStatus = status;
    let _badgeColor = _jobStatus === tipStatus.PENDING ? 'blue' : _jobStatus === tipStatus.LOST ? 'orange' : 'green'

    const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
    let _objCurrentStatus = fields.status && tradeinProAllStatus.filter(e => e.value === fields.status)[0]

    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);
    const lockFinalPrice = ((!_.isEmpty(_permissions) && _permissions.lockFinalPrice) ? true : false);

    let _allClients = Object.assign([], !_.isEmpty(dealersettings.allClients) ? dealersettings.allClients : dealersettings.clients)
    let clientObj = _.find(_allClients, { id: fields.clientID });

    let managerWorkflow = clientObj?.clientSettings?.managerWorkflow ? true : false;
    const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(_jobStatus) ? true : false;

    let _tags = _.uniqBy(_.union((fields?.tags || []), (fields?.tradeIn?.tags || [])), 'type')

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };
    return (
        <div className={isList ? '' : `vehicle-item`} id={'info_tip_' + documentID} onClick={(e) => {
            e.preventDefault();
            if (!isList)
                sidepanelOpen(documentID, fields);
        }}>
            <div className="flex-vehicle">
                {
                    showMore
                        ?
                        <div className="quickview-activity-more">
                            <a href="#" onClick={(e) => {
                                e.stopPropagation();
                                setEditshow(!editshow)
                                if (editshow)
                                    document.addEventListener('click', setEditshow(false));
                            }}><i className="ico icon-more"></i></a>
                            <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                <ul className="more-dropdown">
                                    <li> <a href="#" onClick={(e) => {
                                        history.push("/tradeinpro/details/" + documentID);
                                    }}> <i className="ico icon-expand"></i>  <Translate text={'view'} /></a> </li>
                                </ul>
                            </div>
                        </div>
                        :
                        <></>
                }


                {!hideLogo && (<div className="vehicle-item-image">
                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                        <img src={CommonHelper.showBrandLogo(dealersettings, tradein?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                    </a>
                </div>)}

                <div className="vehicle-data">

                    <div className='tradein-valuation-num text-uppercase'>Valuation No: {displayID ? displayID : (documentID.substring(0, 8))}</div>
                    {
                        companyView && !_.isEmpty(contact)
                            ?
                            <>
                                <div className="vehicle-item-title mini-badge">
                                    <i className='ico icon-Contacts mr-1'></i>
                                    {CommonHelper.displayContactName(null, contact)}
                                </div>
                            </>
                            :
                            <></>
                    }
                    {fields?.clientID ? <div className="vehicle-item-title blue-color">{CommonHelper.getUserNamebyId(dealersettings.clients, fields.clientID)}</div> : <></>}
                    {
                        (isTradein && !_.isEmpty(tradein))
                            ?
                            <>
                                <div className="vehicle-item-title mini-badge">
                                    {(tradein.make ? tradein.make : '') + ' ' + (tradein.model ? tradein.model : '')}
                                    {tradein.saleType ? (<div className="badge badge-pill badge-white ml-1">
                                        {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                            tradein.saleType, '')}
                                    </div>) : ''}
                                </div>                                
                                <div className="dashboard-testdrive-activity-column">
                                    VIN#: <div title={CommonHelper.showLocale({dealersettings}, 'vinNo')}><strong>{tradein?.chassisNo || '--'}</strong></div>
                                    {tradein?.extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({dealersettings}, 'exteriorColor')}><strong>{tradein?.extColor}</strong></div></>)}
                                    {tradein?.year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({dealersettings}, 'year')}><strong>{tradein?.year}</strong></div></>)}
                                    {tradein?.regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale({dealersettings}, 'registrationNumber')}><strong>{tradein?.regNo}</strong></div></>)}
                                </div>                                
                            </>
                            :
                            <><div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div></>
                    }

                    <div className="pipeline-item-div w-100 ">
                        {
                            owner ? (<OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>{CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)}</Tooltip>
                                }
                            >
                                <div className="float-right">
                                    <div className="pipeline-users pl-0">
                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                            <img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                        </a>
                                    </div>
                                </div>
                            </OverlayTrigger>) : (<></>)
                        }
                        <div className="float-left">

                            {
                                addedDate
                                    ?
                                    <div className="pipeline-item-div">
                                        <i className="ico icon-timer"></i>
                                        {moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A')}
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                _tags && _tags.length > 0
                                    ?
                                    <>
                                        {
                                            _tags.map((r, i) => {
                                                return r.subType ? (<div key={i} className="float-left"><div className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)} - {r.subType}</div>
                                                    {/* <div className="badge badge-pill badge-white ml-1 text-uppercase">{r.subType}</div>*/}
                                                </div>
                                                ) :
                                                    (<div key={i} className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                            })
                                        }
                                    </>
                                    :
                                    <></>
                            }

                        </div>


                    </div>

                    {/* {
                        addedDate
                            ?
                            <div className="vehicle-item-owner">

                                <i className="ico icon-timer mr-1 ml-1"></i>
                                {moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A')}
                            </div>
                            :
                            <></>
                    } */}

                </div>
            </div>
            <div className="vehicle-current-status pl-2 pr-2">
                {/* {
                    _jobStatus ? (<div className="float-left">

                        <div className={`status-${_jobStatus.toLowerCase()} mt-1`}><Translate text={(_jobStatus.split(/(?=[A-Z])/).join(' ').toLowerCase())} upperCase={true} /></div>


                    </div>) : (<></>)
                } */}

                {
                    fields.status && _objCurrentStatus
                        ?
                        <>
                            <div className="float-left">
                                <div
                                    className={`status-${fields.status === tipStatus.NEW ? 'open' : 'empty'} mt-1`}
                                    style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                >
                                    <Translate
                                        text={_objCurrentStatus.name}
                                        upperCase={true}
                                    />
                                </div>
                            </div>
                        </>
                        :
                        <></>
                }

                {
                    fields && !_.isEmpty(fields.offer) && fields.offer.dealerPrice && !lockFinalPrice && _isTradeinCompleted
                        ?
                        <>
                            {
                                managerWorkflow
                                    ?
                                    <div className="float-right">

                                        {
                                            fields.finalPrice
                                                ?
                                                <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                    <i className="ico icon-sent"></i>
                                                    <NumberFormat value={fields.finalPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                </div>
                                                :
                                                <>
                                                    {fields.valuerPrice ? (<div className={`badge-slot-tradein badge-slot-tradein-blue`}>
                                                        <i className="ico icon-sent"></i>
                                                        <NumberFormat value={fields.valuerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                    </div>)
                                                        :
                                                        <>
                                                            {
                                                                (<div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                                    <i className="ico icon-sent"></i>
                                                                    <NumberFormat value={fields.offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                                </div>)
                                                            }
                                                        </>
                                                    }
                                                </>
                                        }


                                    </div>
                                    :
                                    <>
                                        <div className="float-right">

                                            <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                <i className="ico icon-sent"></i>
                                                <NumberFormat value={fields.offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                            </div>

                                        </div>
                                    </>
                            }

                        </>
                        :
                        <></>
                }
                {/* {
                    tradein && tradein.offerPrice && !lockFinalPrice
                        ?
                        <>
                            <div className="float-right">
                                <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                    <i className="ico icon-sent"></i>
                                    <NumberFormat value={tradein.offerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                !_.isEmpty(offer) && finalPrice && !lockFinalPrice
                                    ?
                                    <>
                                        <div className="float-right">
                                            <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                <i className="ico icon-sent"></i>
                                                <NumberFormat value={finalPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            !_.isEmpty(offer) && offer.dealerPrice && !lockFinalPrice
                                                ?
                                                <>
                                                    <div className="float-right">
                                                        <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                            <i className="ico icon-sent"></i>
                                                            <NumberFormat value={offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </>
                            }
                        </>
                } */}


                {/* <div className="float-right">
                    <div className="owner-view">
                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                            <img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)} alt="" className="img-object-fit" /></a>
                    </div>
                    {owner ? CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner) : '--'}
                </div> */}
            </div>

        </div >

    )
};

export default EnquiryInfo;
