/** LIBRARIES */
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
/** PROVIDERS */
import { LayoutConsumer } from '../layout/provider';
import { StageProvider, StageConsumer } from './provider'
/** COMPONENTS */
import { SidePanel } from '../../components'
import EnquiryList from './enquirylist';
//import QuickView from "./quickview";
import StageLoader from "./stageloader";
import FilterPanel from './filterPanel'
import images from '../../images';
import Translate from '../../constants/translate';
import QuickEnquiryInfo from '../pipeline/details/quickInfo'

const Pipeline = (props) => {
  const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
  const [customFilters, setCustomFilters] = useState([])

  useEffect(() => {
    document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.remove('fixed-top')
    return () => {
      document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
    }
  }, [])

  useEffect(() => {
    let settingsid = props.dealersettings.client.settings.id;
    let userID = props.dealersettings.id;
    const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
      .where('module', '==', 'enquiry')
      .onSnapshot((querySnapshot) => {
        let _customFilters = [];
        querySnapshot.forEach(doc => {
          const _filt = {
            ...doc.data(),
            documentID: doc.id,
            clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
          }
          _customFilters.push(_filt);
        });
        _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
          (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
        setCustomFilters(_customFilters)

      });
    return () => {
      userRefDoc && userRefDoc();
    }
  }, [])

  const sidepanelClose = useCallback(() => {
    setShowPanel({
      ...showpanel,
      clsActive: '',
      enquiryid: 0
    })
  }, [])

  const sidepanelOpen = useCallback((val) => {
    setShowPanel({
      ...showpanel,
      clsActive: 'active',
      enquiryid: val
    })
  }, [])

  return (
    <LayoutConsumer>
      {({ pipeline, handlePipelineChange, dealersettings, clientUsers, groupUsers }) => dealersettings ? (

        <StageProvider dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={props.history} sidepanelClose={sidepanelClose}>
          <StageConsumer>
            {({ enquiries, isLoading, columns, handleEnquiryFav, loadMoreData, hasMore,
              stageLoading, updateEnquiry, sectionloading, handleStageAndStatusFilter,
              handleApplyFilter, handleClearFilter, handleStatusFilter, isFilterApplied, handleShowInboundLead, handleOpenAISummary,
              stageCounts, searchText, handlesearchText, handlePageClear }) => (
              <div className={`middle-wrapper middle-wrapper-pipeline mt-0 ${pipeline.length === 0 ? 'h-100' : ''}`}>
                {
                  pipeline.filter(e => e.value !== 'LeadsBucket').length === 0 ? (<></>) : (
                    <FilterPanel
                      pipeline={pipeline}
                      customFilters={customFilters}
                      handlePipelineChange={(val) => {
                        handlePipelineChange(val);
                        handlePageClear();
                      }}
                      dealersettings={dealersettings}
                      clientUsers={clientUsers}
                      groupUsers={groupUsers}
                      handleApplyFilter={handleApplyFilter}
                      handleClearFilter={handleClearFilter}
                      handleStatusFilter={handleStatusFilter}
                      stageCounts={stageCounts}
                      isFilterApplied={isFilterApplied}
                      view="pipeline"
                      searchText={searchText}
                      handlesearchText={handlesearchText}
                    />
                  )
                }
                {

                  pipeline.filter(e => e.value !== 'LeadsBucket').length === 0 ? (
                    <div className="spinner-loader h-100">
                      <div className="no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                        <div className="no-data-img-wrap"><img src={images[`nopipeline${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="350" alt="" /></div>
                        <div className="no-data-txt-wrap">
                          <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                          <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                        </div>
                      </div>
                    </div>
                  ) : (isLoading || Object.keys(enquiries).length === 0 || JSON.stringify(_.map(enquiries.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true && item.value !== 'LeadsBucket').value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)) ? (<StageLoader columns={columns} stages={pipeline.find(item => item.default === true && item.value !== 'LeadsBucket') ? pipeline.find(item => item.default === true && item.value !== 'LeadsBucket').stages : []}></StageLoader>) :
                    (
                      <EnquiryList
                        sidepanelOpen={sidepanelOpen}
                        enquiries={enquiries}
                        columns={columns}
                        pipeline={pipeline}
                        loadMoreData={loadMoreData}
                        stageLoading={stageLoading}
                        sectionloading={sectionloading}
                        stageCounts={stageCounts}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isFilterApplied={isFilterApplied}
                        searchText={searchText}
                        handleEnquiryFav={handleEnquiryFav}
                        dealersettings={dealersettings}
                        handleShowInboundLead={handleShowInboundLead}
                        handleOpenAISummary={handleOpenAISummary}
                        hasMore={hasMore}
                        handleStageAndStatusFilter={handleStageAndStatusFilter}
                      >
                      </EnquiryList>
                    )
                }
                {/* <SidePanel
                  clsActive={showpanel.clsActive}
                  clsName={showpanel.clsName}
                  sidepanelClose={sidepanelClose}
                  title=''
                >
                  <QuickView {...props} enquiryid={showpanel.enquiryid} isFilterApplied={isFilterApplied} updateEnquiry={updateEnquiry} sidepanelClose={sidepanelClose} />
                </SidePanel> */}

                <QuickEnquiryInfo
                  {...props}
                  enquiryid={showpanel.enquiryid}
                  clsActive={showpanel.clsActive}
                  clsName={showpanel.clsName}
                  disableRoutes={true}
                  //isReadOnlyView={true}
                  isFilterApplied={isFilterApplied}
                  updateEnquiry={updateEnquiry}
                  sidepanelClose={sidepanelClose}
                />

                {/* <Notifier show={showNotifier} msg={notifierMsg} header={notifierInfo}/>  */}
              </div>
            )}
          </StageConsumer>
        </StageProvider>
      ) : (<></>)}
    </LayoutConsumer>
  );
}


export default Pipeline;