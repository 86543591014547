/** LIBRARIES */
import React, { PureComponent } from "react";
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import {
    InputText, InputTextArea,
    AntMonthPicker,
    ReactSelect,
    AntDatePicker,
    InputCheckBox,
    DynamicInput
} from '../../../components';
import _ from 'lodash'
import CommonHelper from '../../../services/common';

class TipInfoInputs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        const {
            make, model, year, specifications, group,
            notes, regNo, chassisNo, status,
            tradeinProID, evalexpertID, dynamicFields
        } = this.props.fields;

        const { fields, errors, currencySymbol, type, isComplete, isTradeinDone, limitedAcces,
            years, engineTypes, transmissions, vCondtions, vDoors, wheelConditions, wheelConditionsNA,
            tradeinOptionsDF, tradeinPlusEnabled, tinStatus,

            handleOnChange, handleVehicleScan, handleOnCapChange, handleDateChange, onValueChange,
            handleCustomOption, handleReactSelectChange, onCurrencyChange, handleCheckChange,
            handleOnAreaChange,

            handleDynamicCheckChange, handleDynamicOnChange, saveDynamicField, cancelDynamicField, onDynamicNumberChange,
            handleDynamicDateChange, handleDynamicSelectSave, handleDynamicMultiSelectChange,

            makes, addMake, handleAddMakeEnable, handleAddMakeDisable, handleMakeChange,
            groups, addGroup, handleAddGroupEnable, handleAddGroupDisable, handleGroupChange,
            types, addType, handleAddTypeEnable, handleAddTypeDisable, handleTypeChange,
            models, addModel, handleAddModelEnable, handleAddModelDisable,
            dealersettings,
            formClassName

        } = this.props;

        let _formClass = formClassName ? formClassName : 'form-group col-md-6'

        let license_state = [];
        const client = dealersettings.client;
        let defaultCountry = client.countryCode ? client.countryCode.toUpperCase() : 'AU';

        dealersettings.states && dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
            license_state.push({
                value: doc.code,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });
        const _evalexpert = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "evalexpert")[0]);
        const evalexpertEnabled = fields.clientID && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;
        const _autograb = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const _autoGrabEnabled = _autograb && _autograb.active && _autograb.enabled ? true : false;
        const autograbEnabled = ((!_.isEmpty(dealersettings.client.moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.autograb) &&
            dealersettings.client.moduleSettings.autograb.enabled &&
            dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : _autoGrabEnabled);

        const _regoScanEnabled = ((!_.isEmpty(dealersettings.client.moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.regoScan) &&
            dealersettings.client.moduleSettings.regoScan.enabled) ? true : false);
        let regoScanEnabled = (evalexpertEnabled || autograbEnabled || _regoScanEnabled) ? true : false;

        return (
            <>

                {(() => {
                    if (type === 'make') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {isComplete || isTradeinDone || limitedAcces
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addMake ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddMakeEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddMakeDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>}

                                    <label> <Translate text={'make'} /></label>
                                    {addMake || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'make'}
                                            className={`form-control ${errors["make"]}`}
                                            name="make"
                                            onChange={handleCustomOption}
                                            value={make}
                                            id='make'
                                        /> :
                                        <ReactSelect
                                            options={makes}
                                            name={"make"}
                                            placeholder={'select make'}
                                            onChange={handleMakeChange}
                                            value={make}
                                            classNamePrefix={`${errors["make"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete || isTradeinDone}
                                            isDisabled={isTradeinDone || (limitedAcces && model)}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'group') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {isComplete || isTradeinDone || limitedAcces
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addGroup ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddGroupEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddGroupDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>}

                                    <label><Translate text={'group'} /></label>
                                    {addGroup || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'group'}
                                            className={`form-control ${errors["group"]}`}
                                            name="group"
                                            onChange={handleCustomOption}
                                            value={group}
                                            id="group"
                                        /> :
                                        <ReactSelect
                                            options={groups}
                                            name={"group"}
                                            placeholder={'select group'}
                                            onChange={handleGroupChange}
                                            value={group}
                                            classNamePrefix={`${errors["group"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete || isTradeinDone}
                                            isDisabled={isTradeinDone || (limitedAcces && model)}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'type') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {isComplete || isTradeinDone || limitedAcces
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addType ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddTypeEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddTypeDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>}

                                    <label><Translate text={'type'} /></label>
                                    {addType || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'type'}
                                            className={`form-control $errors["type"]}`}
                                            name="type"
                                            onChange={handleCustomOption}
                                            value={fields.type}
                                            id='type'
                                        /> :
                                        <ReactSelect
                                            options={types}
                                            name={"type"}
                                            placeholder={'select body type'}
                                            onChange={handleTypeChange}
                                            value={fields.type}
                                            classNamePrefix={`${errors["type"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete || isTradeinDone}
                                            isDisabled={isTradeinDone || (limitedAcces && model)}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'model') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {isComplete || isTradeinDone || limitedAcces
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addModel ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddModelEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddModelDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>}

                                    <label> <Translate text={'model'} /></label>
                                    {addModel || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'model'}
                                            className={`form-control ${errors["model"]}`}
                                            name="model"
                                            onChange={handleCustomOption}
                                            value={model}
                                            id='model'
                                        /> :
                                        <ReactSelect
                                            options={models}
                                            name={"model"}
                                            placeholder={'select model'}
                                            onChange={handleReactSelectChange}
                                            value={model}
                                            classNamePrefix={`${errors["model"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete || isTradeinDone}
                                            isDisabled={isTradeinDone || (limitedAcces && model)}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'year') {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={'year'} /></label>
                                    {isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'year'}
                                            className={`form-control ${errors["year"]}`}
                                            name="year"
                                            onChange={() => { }}
                                            value={year}
                                        /> :
                                        <ReactSelect
                                            options={years}
                                            name={"year"}
                                            placeholder={'select year'}
                                            onChange={handleReactSelectChange}
                                            value={year}
                                            classNamePrefix={`${errors["year"]} basic-select`}
                                            removeClearable={isComplete}
                                        >
                                        </ReactSelect>}

                                </div>
                            </>
                        )
                    }
                    else if (type === 'regNo') {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={'registrationNumber'} /></label>
                                    <div className="inner-addon right-addon">
                                        {isComplete || isTradeinDone || (limitedAcces) ? <></> :
                                            <>
                                                {
                                                    (regoScanEnabled)
                                                        ?
                                                        <>
                                                            <div className="bg-icons tradein-icons" onClick={(e) => {
                                                                e.preventDefault();
                                                                handleVehicleScan('reg')
                                                            }}>
                                                                <i className="ico icon-reg-no "></i>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </>
                                        }
                                        {
                                            !isComplete && !isTradeinDone && regoScanEnabled && !limitedAcces
                                                ?
                                                <>
                                                    <InputText
                                                        name="regNo"
                                                        value={regNo}
                                                        //onChange={() => { handleVehicleScan('reg') }} @SaMeeR 13-01-25 as per client requirements to add mandatory
                                                        onChange={handleOnCapChange}
                                                        autoComplete="off"
                                                        placeholder={'registration number'}
                                                        className={`form-control ${errors["regNo"]}`}
                                                        readOnly={isComplete || (limitedAcces)}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <InputText
                                                        name="regNo"
                                                        value={regNo}
                                                        onChange={handleOnCapChange}
                                                        autoComplete="off"
                                                        placeholder={'registration number'}
                                                        className={`form-control ${errors["regNo"]}`}
                                                        readOnly={isComplete || (limitedAcces)}
                                                    />
                                                </>
                                        }

                                    </div>

                                </div>

                            </>
                        )
                    } else if (type === 'chassisNo') {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={'chassisNo'} /></label>
                                    <div className="inner-addon right-addon">
                                        {isComplete || isTradeinDone || (limitedAcces) ? <></> :
                                            <>
                                                {
                                                    (regoScanEnabled)
                                                        ?
                                                        <>
                                                            <div className="bg-icons tradein-icons" onClick={(e) => {
                                                                e.preventDefault();
                                                                handleVehicleScan('vin')
                                                            }}>
                                                                <i className="ico icon-vin-no"></i>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </>
                                        }
                                        {
                                            !isComplete && !isTradeinDone && regoScanEnabled && !limitedAcces
                                                ?
                                                <>
                                                    <InputText
                                                        name="chassisNo"
                                                        value={chassisNo}
                                                        //onChange={() => { handleVehicleScan('vin') }} @SaMeeR 13-01-25 as per client requirements to add mandatory
                                                        onChange={handleOnCapChange}
                                                        autoComplete="off"
                                                        placeholder={'chassis/vin number'}
                                                        className={`form-control ${errors["chassisNo"]}`}
                                                        readOnly={isComplete || (limitedAcces)}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <InputText
                                                        name="chassisNo"
                                                        value={chassisNo}
                                                        onChange={handleOnCapChange}
                                                        autoComplete="off"
                                                        placeholder={'chassis/vin number'}
                                                        className={`form-control ${errors["chassisNo"]}`}
                                                        readOnly={isComplete || (limitedAcces)}
                                                    />
                                                </>
                                        }

                                    </div>

                                </div>

                            </>
                        )

                    } else if (type === 'status' && !tradeinPlusEnabled && !tradeinProID && !evalexpertID) {
                        return (
                            <>
                                <div className={`${_formClass} ${(isComplete) ? 'form-disable' : ''}`}>
                                    <label><Translate text={'status'} /></label>
                                    <ReactSelect
                                        options={tinStatus}
                                        name={"status"}
                                        placeholder={'select status'}
                                        onChange={handleReactSelectChange}
                                        value={status}
                                        classNamePrefix={`${errors["status"]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    } else if (['mileage', 'cylinders', 'gears', 'seats', 'powerKw', 'range'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={type} /></label>
                                    <NumberFormat
                                        id={type}
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        placeholder={CommonHelper.showLocale(this.props, type).toLowerCase()}
                                        className={`form-control ${errors[type]}`}
                                        onValueChange={onValueChange.bind(this, type)}
                                        value={fields[type] ? fields[type] : null}
                                        displayType={isComplete ? 'text' : 'input'}
                                    />
                                </div>
                            </>
                        )

                    } else if (['complianceDate', 'buildDate'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={type} /></label>
                                    <AntMonthPicker
                                        value={fields[type]}
                                        name={type}
                                        onChange={(e) => { handleDateChange(e, type) }}
                                        placeholder='MMM YYYY'
                                        className={`form-control ${errors[type]}`}
                                        format={'MMM YYYY'}
                                        picker={'month'}
                                        disabled={isComplete}
                                    />
                                </div>
                            </>
                        )
                    } else if (['modelDescription', 'exteriorColor', 'interiorColor', 'trim', 'engineNo', 'engineSize', 'spareKey',
                        'logBooks', 'serviceHistory', 'cargoBlind', 'headRests', 'sdCard', 'owners', 'financeCo', 'tyreAge',
                        'driveType', 'fuelType', 'series', 'torqueNm', 'transmissionType', 'wheelbase', 'regStatus', 'badge', 'region'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={type} /></label>
                                    <InputText
                                        name={type}
                                        value={fields[type]}
                                        onChange={handleOnChange}
                                        autoComplete="off"
                                        placeholder={CommonHelper.showLocale(this.props, type).toLowerCase()}
                                        className={`form-control ${errors[type]}`}
                                        readOnly={isComplete}
                                    />
                                </div>
                            </>
                        )
                    } else if (['regState', 'engineType', 'transmission', 'vehicleCondition', 'doors', 'spareTyre', 'frontRightWheel', 'frontLeftWheel', 'rearRightWheel', 'rearLeftWheel'].includes(type)) {
                        return (
                            <>
                                <div className={`${_formClass} ${(isComplete) ? 'form-disable' : ''}`}>
                                    <label><Translate text={type} /></label>
                                    <ReactSelect
                                        options={
                                            type === 'regState' ? license_state :
                                                type === 'engineType' ? engineTypes :
                                                    type === 'transmission' ? transmissions :
                                                        type === 'vehicleCondition' ? vCondtions :
                                                            type === 'doors' ? vDoors :
                                                                type === 'spareTyre' ? wheelConditionsNA :
                                                                    ['frontRightWheel', 'frontLeftWheel', 'rearRightWheel', 'rearLeftWheel'].includes(type) ? wheelConditions :
                                                                        []}
                                        name={type}
                                        placeholder={CommonHelper.showLocale(this.props, type).toLowerCase()}
                                        onChange={handleReactSelectChange}
                                        value={fields[type]}
                                        classNamePrefix={`${errors[type]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    } else if (['serviceContFrom', 'serviceContTo', 'lastService', 'warrantyExpiry', 'regDate'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={type === 'regDate' ? 'registrationExpiry' : type} /> </label>
                                    <AntDatePicker
                                        value={fields[type] ? fields[type] : null}
                                        name={type}
                                        onChange={(e) => { handleDateChange(e, type) }}
                                        format='DD/MM/YYYY'
                                        placeholder='DD/MM/YYYY'
                                        className={`form-control ${errors[type]}`}
                                        readOnly={isComplete}
                                    />
                                </div>
                            </>
                        )
                    } else if (['reconditioningCost'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={type} /></label>
                                    <NumberFormat
                                        id={type}
                                        decimalScale={2}
                                        allowNegative={true}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        placeholder={currencySymbol}
                                        className={`form-control ${errors[type]}`}
                                        onValueChange={onCurrencyChange.bind(this, type)}
                                        value={fields[type] ? fields[type] : null}
                                    />
                                </div>
                            </>
                        )
                    } else if (['contrastStiching', 'sunroof', 'camera', 'woodSteering', 'warranty', 'reconditioning', 'financed', 'outstandingPayments'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={type} /></label>
                                    <div className="form-control">

                                        <span className="switch switch-sm float-right">
                                            <InputCheckBox
                                                className="switch"
                                                id={type}
                                                name={type}
                                                checked={fields[type] ? true : false}
                                                onChange={(e) => { handleCheckChange(e) }} />
                                            <label htmlFor={type}></label>
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                    } else if (type === 'specifications') {
                        return (
                            <>
                                <div className="form-group col-md-12">
                                    <label><Translate text={'specifications'} /></label>
                                    {
                                        isComplete
                                            ?
                                            <>
                                                <div className={'notes-item notes-item-scroll text-area-space'}>
                                                    {specifications ? specifications : '--'}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <InputTextArea
                                                    className={`form-control notes ${errors["specifications"]}`}
                                                    name="specifications"
                                                    onChange={handleOnAreaChange}
                                                    value={specifications}
                                                    maxLength="5000"
                                                    rows="5">
                                                </InputTextArea>
                                            </>
                                    }

                                </div>
                            </>
                        )
                    } else if (type === 'notes') {
                        return (
                            <>
                                {/* <div className="form-group col-md-12">
                                    <label><Translate text={'notes'} /></label>
                                    {
                                        isComplete
                                            ?
                                            <>
                                                <div className={'notes-item notes-item-scroll text-area-space'}>
                                                    {notes ? notes : '--'}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <InputTextArea
                                                    className={`form-control notes ${errors["notes"]}`}
                                                    name="notes"
                                                    onChange={handleOnAreaChange}
                                                    value={notes}
                                                    maxLength="280"
                                                    rows="3">
                                                </InputTextArea>
                                            </>
                                    }

                                </div> */}
                            </>
                        )
                    }
                    else {
                        return (
                            <>
                                {!_.isEmpty(tradeinOptionsDF) && tradeinOptionsDF.filter(m => m.active === true).map((info, index) => {
                                    if (type === info.value) {
                                        return (<div className={_formClass} key={info.value}>
                                            <label>{info.name}</label>

                                            <DynamicInput
                                                index={'0' + index}
                                                data={info}
                                                errors={errors ? errors : {}}
                                                id={info.value}
                                                value={dynamicFields ? dynamicFields[info.value] : null}
                                                handleCheckChange={handleDynamicCheckChange}
                                                handleOnChange={handleDynamicOnChange}
                                                saveEnquiryField={saveDynamicField}
                                                cancelEnquiryField={cancelDynamicField}
                                                onCurrencyChange={onDynamicNumberChange}
                                                handleDateChange={handleDynamicDateChange}
                                                handleSelectSave={handleDynamicSelectSave}
                                                handleMultiSelectChange={handleDynamicMultiSelectChange}
                                                currencySymbol={currencySymbol}
                                                inputAutoFormat={false}
                                            >
                                            </DynamicInput>

                                        </div>)
                                    }

                                })}
                            </>
                        )
                    }

                })()}

            </>

        );
    }
}

export default TipInfoInputs;