/** LIBRARIES */
import React, {
    useState,
    useEffect,
    useReducer,
    useRef,
    useMemo
} from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import CustomerlogDetails from '../pipeline/quickview/customerlogDetails';
import { ContentOverlay, PopUpModal } from '../../components';
import { enquiryActivities } from '../pipeline/viewModel';
import { default as _images } from '../../images';
import Translate from '../../constants/translate';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import EmailPreview from "../mails/emailPreview";
import ActivityView from '../pipeline/quickview/activityView'
import CommonHelper from '../../services/common'
import { firestoreDB } from '../../services/helper';
//import CustomerlogReducer from './customerlogReducer';

const CustomerlogsReducer = (state, action) => {
    function cancelEditable() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.notes = action.data.oldnotes
                item.callDuration = action.data.oldcallDuration
                item.callType = action.data.callType
                item.callStatus = action.data.oldcallStatus
                item.addedDate = action.data.oldaddedDate
                return item;
            }
            return item;
        });
    }
    function updatechangelogs() {
        return state.map((item) => {
            if (item.documentID === action.id) {
                item[action.field] = action.value;
                return item;
            }
            return item;
        });
    }

    function updateCustomerlog() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.isNewlog = false;
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function editCustomerlog() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = true;
                item.oldnotes = item.notes
                item.oldcallDuration = item.callDuration
                item.oldcallStatus = item.callStatus
                item.oldcallType = item.callType
                item.oldaddedDate = item.addedDate
                return item;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data;
        }
        case "ADDNEWLOG": {
            return [action.data, ...state];
        }
        case "REMOVELOG": {
            return state.filter(item => item.documentID !== action.data.documentID);
        }
        case "CANCELLOG": {
            return cancelEditable(action.data);
        }
        case "CHANGELOG": {
            return updatechangelogs();
        }
        case "SAVELOG": {
            return [...state, action.data];
        }
        case "EDITLOG": {
            return editCustomerlog();
        }
        case "UPDATELOG": {
            return updateCustomerlog();
        }
        default:
            return state;
    }
};

function ViewActivityLog(props) {
    //const[ customerlogs,setCustomerlog ] = useState([])
    const [customerlogs, dispatch] = useReducer(CustomerlogsReducer, []);
    const [loading, setLoading] = useState(false);
    //const [activeKey, setActiveKey] = useState()
    const textareaRef = useRef(null);

    const [showPreviewModel, setPreviewModel] = useState(false)
    const [emailLog, setEmaillog] = useState(null)
    const [popheader, setPopheader] = useState();

    const [tempData, setTempData] = useState([])
    const [customerlogLoader, setCustomerlogLoader] = useState(true);
    const customerlogTags = ['Activities', 'Notes', 'Messages', 'Calls', 'Emails'];
    const [activeTab, setActivetab] = useState('Activities');


    useEffect(() => {

        let refEnquiryLogs = firestoreDB(props.dealersettings).firestore().collection('activities')
            .where('isDeleted', '==', false);

        if (props.enquiry)
            refEnquiryLogs = refEnquiryLogs.where('enquiryID', '==', props.enquiry.documentID).where('type', 'in', [CommonHelper.getActivityType('activity', props.originType), CommonHelper.getActivityType('log', props.originType)])
        else if (props.tradeinPro)
            refEnquiryLogs = refEnquiryLogs.where('tradeinProID', '==', props.tradeinPro.documentID)
        else if (props.service)
            refEnquiryLogs = refEnquiryLogs.where('serviceID', '==', props.service.documentID)
        else
            refEnquiryLogs = refEnquiryLogs.where('contactID', '==', props.contact.documentID).where('type', 'in', [CommonHelper.getActivityType('activity', props.originType), CommonHelper.getActivityType('log', props.originType)])


        let linkEmailsData = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails')
        if (props.enquiry)
            linkEmailsData = linkEmailsData.where('enquiryID', '==', props.enquiry.documentID)
        else if (props.tradeinPro)
            linkEmailsData = linkEmailsData.where('tradeinProID', '==', props.tradeinPro.documentID)
        else if (props.service)
            linkEmailsData = linkEmailsData.where('serviceID', '==', props.service.documentID)
        else
            linkEmailsData = linkEmailsData.where('contactID', '==', props.contact.documentID)


        var activitiesCollectionzz = combineLatest(
            collection(refEnquiryLogs),
            collection(linkEmailsData)
        ).pipe(
            map(([activities, emails]) => {
                return [
                    _.map(activities, function (obj) { return { ...obj.data(), documentID: obj.id, startDate: _.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate } }),
                    _.map(emails, function (obj) { return { ...obj.data(), documentID: obj.id, type: CommonHelper.getActivityType('log', props.originType), subType: 'email', isDone: true, startDate: obj.data().linkedDate, addedDate: obj.data().linkedDate, owner: obj.data().linkedBy, threadID: (obj.data().thread ? obj.data().thread.id : null) } }),
                ]
            }))
            .subscribe(async ([activities, emails]) => {

                if (emails.length === 0) {
                    setCustomerlogLoader(false)
                    dispatch({
                        type: "SUCCESS",
                        data: activities.concat(emails)
                    });
                }
                else {
                    setTempData(activities.concat(emails))
                }
            });
        return () => {
            activitiesCollectionzz && activitiesCollectionzz.unsubscribe()
        }

    }, [])

    useEffect(() => {
        if (tempData.length === 0)
            return
        async function getTheardEmails(activities) {
            const promises = []
            activities.filter(e => e.type === CommonHelper.getActivityType('log', props.originType) && e.subType === 'email' && !_.isEmpty(e.thread)).forEach(el => {
                promises.push(window.firebase.firestore().doc(`nylas-accounts/${el.account_id ? el.account_id : el.thread.account_id}/nylas-threads/${el.threadID}`).get())
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                if (snap.exists) {
                    activities = [...activities.filter(e => e.threadID !== snap.id), {
                        ..._.find(activities, { 'threadID': snap.id }),
                        thread: snap.data()
                    }]
                }
            })
            setCustomerlogLoader(false)
            dispatch({
                type: "SUCCESS",
                data: activities
            });
        }

        getTheardEmails(tempData);

    }, [tempData])


    const handleModelClose = () => {
        setEmaillog(null)
        setPreviewModel(false)
    }


    const noteLogs = useMemo(() => customerlogs
        .filter(
            ({ subType, type }) => (subType === 'note' && type === CommonHelper.getActivityType('log', props.originType))
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const messageLogs = useMemo(() => customerlogs
        .filter(
            ({ subType, type }) => (subType === 'message' && type === CommonHelper.getActivityType('log', props.originType))
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const callLogs = useMemo(() => customerlogs
        .filter(
            ({ subType, type }) => (subType === 'call' && type === CommonHelper.getActivityType('log', props.originType))
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const emailLogs = useMemo(() => customerlogs
        .filter(
            ({ subType, type }) => (subType === 'email' && type === CommonHelper.getActivityType('log', props.originType))
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const activityLogs = useMemo(() => customerlogs
        .filter(
            ({ type }) => (type === CommonHelper.getActivityType('activity', props.originType))
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])

    const allLogs = {
        Notes: noteLogs,
        Messages: messageLogs,
        Calls: callLogs,
        Emails: emailLogs,
        Activities: activityLogs
    }

    console.log()
    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-log-activity"
            >
                <Modal.Header closeButton className="modal-header-border">
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className=" pop-layout form-style" style={{ minHeight: '350px' }}>
                                {/* <div className='mid-column-wrap'>
                                    <div className='activity-nav' id="activity-nav-new">
                                        <Tab.Container activeKey={activeTab}>
                                            <Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
                                                {enquiryActivities
                                                    .filter(item => item.value !== 'activity')
                                                    .map((rec, index) => {
                                                        return (
                                                            <Nav.Item as='li' bsPrefix='li-nav' key={index}>
                                                                <Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
                                                                    {
                                                                        <>
                                                                            <i className={rec.ico}></i>
                                                                            {rec.name}
                                                                        </>
                                                                    }
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        );
                                                    })}
                                            </Nav>
                                        </Tab.Container>
                                    </div>

                                </div> */}

                                <div className='quickview-activity-wraper mt-0'>
                                    {customerlogLoader ? (
                                        <div className='mt-5'>
                                            <ContentOverlay active={true} />
                                        </div>
                                    ) : (<>
                                        <div className='enquiry-flow-nav pl-0 pt-0'>
                                            <ul>
                                                {customerlogTags.map((rec, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a
                                                                href='#'
                                                                id={rec}
                                                                className={`nav-activity-tabs nav-li-${rec} ${activeTab === rec ? 'active' : ''
                                                                    }`}
                                                                onClick={event => {
                                                                    if (event.target.id) {
                                                                        setActivetab(event.target.id);
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            >

                                                                <Translate text={rec} />
                                                                {
                                                                    allLogs[rec].length > 0 ? (
                                                                        <span className="count">({allLogs[rec].length})</span>
                                                                    ) : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        {allLogs[activeTab].length > 0 ? (
                                            <>
                                                <div className='quickview-activity-item'>
                                                    {
                                                        activeTab === 'Activities'
                                                            ?
                                                            <>
                                                                {
                                                                    allLogs[activeTab].map((log, index) => {
                                                                        return <ActivityView
                                                                            key={index}
                                                                            title={log.title}
                                                                            activityDate={log.startDate}
                                                                            id={log.documentID}
                                                                            type={log.subType}
                                                                            addedBy={CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.owner)}
                                                                            handleEditactivity={(e) => { e.preventDefault(); }}
                                                                            handleComplete={(e) => { e.preventDefault(); }}
                                                                            completed={log.isDone}
                                                                            activitytag={activeTab}
                                                                            dealersettings={props.dealersettings}
                                                                            handleDeleteactivity={(e) => { e.preventDefault(); }}
                                                                            activitylog={log}
                                                                            readOnlyView={true}
                                                                            originType={props.originType}
                                                                        />
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    allLogs[activeTab]
                                                                        .sort(function (x, y) {
                                                                            return x.startDate.seconds > y.startDate.seconds ? -1 : 1;
                                                                        })
                                                                        .map((obj, index) => {
                                                                            return (
                                                                                <CustomerlogDetails
                                                                                    key={index}
                                                                                    type={obj.subType}
                                                                                    clientUsers={props.clientUsers}
                                                                                    customerlog={obj}
                                                                                    handlecancellog={(e) => { e.preventDefault(); }}
                                                                                    handlechangelog={(e) => { e.preventDefault(); }}
                                                                                    handlesavelog={(e) => { e.preventDefault(); }}
                                                                                    handleEditlog={(e) => { e.preventDefault(); }}
                                                                                    isNewlog={obj.isNewlog}
                                                                                    isEditable={obj.isEditable}
                                                                                    id={obj.documentID}
                                                                                    notes={obj.notes}
                                                                                    addedDate={obj.addedDate}
                                                                                    callDuration={obj.callDuration}
                                                                                    callStatus={obj.callStatus}
                                                                                    textareaRef={textareaRef}
                                                                                    handleDeletelog={(e) => { e.preventDefault(); }}
                                                                                    handleDateChangelog={(e) => { e.preventDefault(); }}
                                                                                    loading={loading}
                                                                                    completedTab={enquiryActivities.find(
                                                                                        item => item.name === activeTab,
                                                                                    )}
                                                                                    dealersettings={props.dealersettings}
                                                                                    handleUnlickEmail={(e) => { e.preventDefault(); }}
                                                                                    handlePreviewEmail={(e) => { e.preventDefault(); }}
                                                                                    handleReplylog={(e) => { e.preventDefault(); }}
                                                                                    sendLoading={false}
                                                                                    hideMoreOption={true}
                                                                                    originType={props.originType}
                                                                                    handleFileChange={(e) => { e.preventDefault(); }}
                                                                                ></CustomerlogDetails>
                                                                            );
                                                                        })
                                                                }
                                                            </>
                                                    }

                                                </div>
                                            </>
                                        ) : (
                                            <div className='spinner-loader h-100 p-5'>
                                                <div className='no-data-flex h-100 justify-content-center align-items-center '>
                                                    <div className='no-data-img'>
                                                        {' '}
                                                        <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
                                                    </div>
                                                    <div className='no-data-txt mt-2'>  <Translate text={'No Logs'} /></div>
                                                </div>
                                            </div>
                                        )}

                                    </>
                                    )}
                                </div>



                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={showPreviewModel}>
                <EmailPreview
                    show={showPreviewModel}
                    handleClose={handleModelClose}
                    title={popheader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    emailLog={emailLog}
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.contactBasicVM)
                                ? props.contactBasicVM
                                : null
                    }
                    history={props.history}
                    enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
                />
            </PopUpModal>

        </>
    );
}

export default ViewActivityLog;
