import React, { useState, useEffect, useReducer, useRef, Fragment } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash'
import CommonHelper from '../../services/common';
import Files from "./index";

const MultipleFiles = (props) => {

    //const [activeTab, setActiveTab] = useState('sales')
    const [showAddFile, setshowAddFile] = useState(false)

    let _filesCategory = !_.isEmpty(props.filesCategory) ? _.chain(props.filesCategory)
        .groupBy("module")
        .map((value, key) => ({ value: key, name: key, categories: value }))
        .value() : []

    const fileCounts = (_module) => {
        if (!_.isEmpty(props.files)) {
            return `(${props.files.filter(e => !e.isDeleted &&
                (_module === 'sales' ? (e.module === _module || _.isEmpty(e.module)) : e.module === _module)
            ).length})`
        }
        else {
            return ''
        }
    }
    //console.log(_filesCategory)
    return (
        <>

            {
                _filesCategory.length > 1
                    ?
                    <>
                        {
                            props.showAdd && (<>
                                <div className="add-files-button">
                                    <a href="#" className="mini-button" onClick={(e) => {
                                        e.preventDefault();
                                        setshowAddFile(true);
                                    }}>
                                        <i className="ico icon-add"></i>
                                    </a>
                                </div>
                                <div className="file-manager-nav pl-0 file-manager-tooltipfix"> <h5>{'Files'}</h5> </div></>)
                        }

                        <div className={`settings-tab-wrap tabs`} >
                            <div className='tabs-wraper'>

                                <Tabs defaultActiveKey={_filesCategory[0].value}
                                    className={`nav-fill nav-file-paddingfix ${props.showAdd ? 'mb-3' : ''}`}
                                    mountOnEnter={true}
                                    unmountOnExit={true}
                                >
                                    {
                                        _filesCategory.map((rec, index) => {
                                            return <Tab
                                                key={index}
                                                eventKey={rec.value}
                                                tabClassName='mr-0'
                                                title={`${CommonHelper.showLocale(props, rec.name)} ${fileCounts(rec.value)}`}
                                            >
                                                {
                                                    props.showAdd ?
                                                        <Files
                                                            {...props}
                                                            module={rec.value === 'sales' ? null : rec.value}
                                                            dynamicCategories={rec.categories}
                                                            showAdd={false}
                                                            showAddOpen={showAddFile}
                                                            handleFileClose={() => { setshowAddFile(false); }}
                                                        ></Files>
                                                        :
                                                        <Files
                                                            {...props}
                                                            module={rec.value === 'sales' ? null : rec.value}
                                                            dynamicCategories={rec.categories}
                                                        ></Files>
                                                }
                                            </Tab>
                                        })
                                    }
                                </Tabs>

                            </div>
                        </div>
                        {/* <div className="file-manager-nav pl-0">
                            <ul>
                                {
                                    _filesCategory.map((info, index) => {
                                        return <li key={index}><a href="#" className={`nav-activity-tabs  ${activeTab === info.value ? 'active' : ''}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setcategories(info.categories)
                                                setActiveTab(info.value);
                                            }}><Translate text={info.value} /> </a></li>

                                    })
                                }
                            </ul>
                        </div>

                        <Files
                            {...props}
                            module={activeTab === 'sales' ? null : activeTab}
                            dynamicCategories={categories}
                        ></Files> */}
                    </>
                    :
                    <>
                        <Files
                            {...props}
                            dynamicCategories={_filesCategory.length === 1 ? _filesCategory[0]?.categories : null}
                        ></Files>
                    </>
            }




        </>

    )
}

export default MultipleFiles;