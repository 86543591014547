import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import { AntDateRangePicker, AntDatePicker } from '../../../components';
import moment from 'moment';
import images from '../../../images';
import CommonHelper from '../../../services/common';


const AddScheduler = (props) => {
    const [fleetdata, setFleetdata] = useState(props.fleetdata || {})
    const [loader, setLoader] = useState(false)

    const handleDateRangeChange = (val, name) => {
        if (val) {
            setFleetdata({
                ...fleetdata,
                [name]: fleetdata[name] ? {
                    ...fleetdata[name],
                    ['startDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[0]).toDate()),
                    ['endDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[1]).toDate())
                } : {
                    ['startDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[0]).toDate()),
                    ['endDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[1]).toDate())
                }
            });
        } else {
            setFleetdata({
                ...fleetdata,
                [name]: null
            })
        }

    }

    const handleDateChange = (val, name) => {
        if (val) {
            setFleetdata({
                ...fleetdata,
                [name]: fleetdata[name] ? {
                    ...fleetdata[name],
                    ['startDate']: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate()),
                    ['endDate']: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate())
                } : {
                    ['startDate']: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate()),
                    ['endDate']: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate())
                }
            });
        }
        else {
            setFleetdata({
                ...fleetdata,
                [name]: null
            })
        }
    };

    const handleSave = () => {
        setLoader(true);
        window.firebase.firestore().doc(`/fleetPro/${props.fleetProID}`)
            .set({ statusDaterange: fleetdata }, { merge: true })
            .then(() => {
                setLoader(false);
                props.handleClose();
            });
    }

    const disabledDate = (current, name) => {
        const disabledDates = [];
        props.fleetProStatus.filter(a => a.value !== name).forEach(rec => {
            if (fleetdata?.[rec.value]?.startDate && fleetdata?.[rec.value]?.endDate) {
                for (var m = moment.unix(fleetdata[rec.value].startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(fleetdata[rec.value].endDate.seconds).startOf('day')); m.add(1, 'days')) {
                    disabledDates.push(m.format('YYYY-MM-DD'))
                }
            }
        });

        if (!_.isEmpty(props.fleetPro?.statusBooking)) {
            props.fleetPro.statusBooking.forEach(rec => {
                if (rec?.startDate && rec?.endDate) {
                    for (var m = moment.unix(rec.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(rec.endDate.seconds).startOf('day')); m.add(1, 'days')) {
                        disabledDates.push(m.format('YYYY-MM-DD'))
                    }
                }
            });
        }

        return disabledDates.find(date => date === moment(current).format("YYYY-MM-DD")) || (props?.fleetPro?.defleetDate?.seconds ? current >= moment.unix(props.fleetPro.defleetDate.seconds).startOf('day') : false);
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-fleetpro-scheduler"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                {
                                    props?.fleetProStatus?.length ? props.fleetProStatus.map((rec, index) => {
                                        return <div key={index} className="fleetpro-scheduler-loop">
                                            <div className="fleetpro-scheduler-title">{rec.name}</div>
                                            <div>
                                                <div className="form-row ">
                                                    <div className="form-group col-md-12">
                                                        <label>From </label>
                                                        {/* {
                                                            ["onfleeting", "operational"].includes(rec.value)
                                                                ?
                                                                <>
                                                                    <div className='scheduler-picker-single'>
                                                                        <AntDatePicker
                                                                            value={fleetdata?.[rec.value] ? moment.unix(fleetdata[rec.value].startDate.seconds).format('YYYY-MM-DD') : null}
                                                                            id={"dashboard-date-range"}
                                                                            name={`${rec.value}-date`}
                                                                            onChange={e => { handleDateChange(e, rec.value) }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={'ant-picker-billing'}
                                                                            disabledDate={(val) => disabledDate(val, rec.value)}
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <> */}
                                                        <AntDateRangePicker
                                                            value={fleetdata?.[rec.value] ? [moment.unix(fleetdata[rec.value].startDate.seconds), moment.unix(fleetdata[rec.value].endDate.seconds)] : null}
                                                            id={"dashboard-date-range"}
                                                            name={`${rec.value}-date`}
                                                            format='DD/MM/YYYY'
                                                            onChange={(e) => { handleDateRangeChange(e, rec.value) }}
                                                            placeholder='DD/MM/YYYY'
                                                            className={'ant-picker-billing'}
                                                            disabledDate={(val) => disabledDate(val, rec.value)}
                                                        />
                                                        {/* </>
                                                        } */}

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }) : (
                                        <div className="common-table" >
                                            <div className='h-100  w-100'>
                                                <div className='spinner-loader h-100'>
                                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
                                                        <div className="no-data-img"> <img src={images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                        <div className="no-data-txt mt-2">
                                                            <p>{`No data found`}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => { handleSave() }}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default AddScheduler;