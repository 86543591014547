export const oemDataVM = {
  name: '',
  documentID: null,
  brandIDs: [],
  addedBy: '',
  addedDate: null,
  modifiedBy: '',
  modifiedDate: null,
  campaigns: [],
  callStatus: [],
  enquiryTypes: [],
  enquiryOptions: [],
  enquiryOptionsDF: [],
  financeStatus: [],
  origins: [],
  lostReasons: [],
  reqOtherInfo: [],
  testdriveFeedback: [],
  titles: [],
  tradeinStatus: [],
  genders: [],
  maritalStatus: [],
  contactMethod: [],
  interests: [],
  licenseType: [],
  companyType: [],
  stockLocation: [],
  salesType: [],
  pipelines: [],
  pipelineModules: [],
  enquiryStatus: [],
  activityTypes: [],
  liteVersion: null,
  isMclaren: null,
  theme: null,
  projectId: null,
  isPorsche: null,
  mvwReport: null,
}

export const pipelineModules = [
  { label: 'Contact', value: 'contact', isFixed: true, isDisabled: true },
  { label: 'Requirement', value: 'requirement', isFixed: true, isDisabled: true },
  { label: 'Test Drive', value: 'testDrive' },
  { label: 'Trade-In', value: 'tradeIn' },
  { label: 'Cafe', value: 'cafe' },
  { label: 'Files', value: 'files' }
];
export const mandatoryFields = [
  'name',
  'brandIDs'
];

export const brandThemes = [
  { label: 'Car Expert', value: 'carExpert' },
  { label: 'Porsche', value: 'porsche' }
];

export const oemlistFields = [
  {
    name: 'Name',
    value: 'name',
    flex: 1,
    default: true
  },
  {
    name: 'Brands',
    value: 'strBrands',
    flex: 2,
    default: true,
  },
  {
    name: 'Settings',
    value: 'settings',
    subText: 'documentID',
    width: 40,
    default: true,
    type: 'settings',
    flex: 0,
    fixedRight: true
  }
]

export const oemSwitchlistFields = [
  {
    name: 'Name',
    value: '_name',
    flex: 1,
    default: true
  },
  {
    name: 'Dealers',
    value: 'strDealers',
    flex: 3,
    default: true,
  },
  {
    name: 'Brands',
    value: 'strBrands',
    flex: 1,
    default: true,
  },
  {
    name: 'Settings',
    value: 'settings',
    subText: 'documentID',
    width: 40,
    default: true,
    type: 'settings',
    flex: 0,
    fixedRight: true
  }
]

export const defaultroleVM = {
  addedDate: null,
  addedBy: '',
  modifiedDate: null,
  modifiedBy: '',
  admin: {
    clientSettings: true,
    workflowSetup: true,
    productsData: true,
    modules: true,
    integration: true,
    locationMgnt: true,
    securityMgnt: true,
    billing: true,
    oemDashboard: true
  },
  documentID: null,
  level: 'oem',
  modules: {
    enquiry: true,
    frontdesk: true,
    kitchen: true,
    loanVehicle: true,
    stock: true,
    service: true
  },
  name: 'Administrator',
  permissions: {
    deleteActivity: true,
    deleteEnquiry: true,
    exportData: true,
    exportContactData: true,
    lostApproval: true,
    mergeContact: true,
    modelPrice: true,
    modifyActivityOwner: true,
    modifyCampaign: true,
    modifyEnquiryOrigin: true,
    modifyEnquiryOwner: true,
    modifyEnquiryType: true,
    modifyLabel: true,
    modifyLostReason: true,
    modifyOtherActivity: true,
    modifyOtherEnquiry: true,
    modifyPurhaseInt: true,
    modifyRequirements: true,
    modifyStatusDate: true,
    modifyVehicleInfo: true,
    movePipeline: true,
    queueReorder: true,
    reopenEnquiry: true,
    returnVehicle: true,
    testdriveHandover: true,
    transferEnquiry: true,
    verifyEnquiry: true,
    viewOtherEnquiry: true,
    viewOtherContact: true,
    modifyContactOwner: true,
    modifyOtherContact: true,
    transferContact: true,
    deleteContact: true,
    deleteFiles: true,
    modifyNotes: true,
    ownerDashboard: false,
    unlinkEnquiry: true,
    accessOEMStock: true,
    accessDealertarget: true,
    viewOtherActivity: true,
    modifyStageAfterDeliveredLost: true,
    roNumRequired: false,
    dealerLeadTransfer: true,
    readOnlyEnquiryView: false,
    bdcDashboard: true,
    dealerFileUpload: true,
    addQuotation: true,
    deleteQuotation: true,
    createLeadOnLost: true,
    deleteStock: true,
    accessStaticReport: true,
    previewEnquiry: true,
    editDynamicReports: true,
    modifyLeadSource: true,
    scheduleContactList: true,
    frontdeskQueueActivation: true,
    accessToShowTradeInPrice: true,
    deleteTradeIn: true,
    showFormFeedback: true,
    addDealer: true,
    accessToPipelineStock: true,
    accessToStockVBM: true,
    accessToModifyVBM: true,
    accessToPerformanceReview: true,
    addStock: true,
    editStock: true
  },
  pipeline: ["LeadsBucket", "Sales"],
  servicePermissions: {
    scheduleAccess: true,
    checkInAccess: true,
    confirmQuotationAccess: true,
    sendToCustomerAccess: true,
    workInProgressAccess: true,
    completedAccess: true,
    deleteService: true,
    modifyService: true,
    previewService: true,
    technician: true,
    partsInterpretor: true,
    workshopManager: true,
    serviceAdvisor: true,
    exportData: true,
    exportContactData: true,
    movePipeline: true,
    transferService: true,
    checklistAccess: true,
    checklistBBTAccess: true,
    packagesAccess: true,
    inspectionAccess: true,
    recommendationAccess: true,
    partsAccess: true,
    viewOnlyAccess: true,
    accessServiceStock: true,
    deleteServiceStock: true
  },
  servicePipeline: ['Service'],
  tradeinProPermissions: {
    selfEvaluationAccess: true,
    approveOfferAccess: true,
    resendOfferAccess: true,
    requestOfferAccess: true,
    modifyStatusAccess: true,
    wholeSaler: true,
    movePipeline: true,
    deleteTradein: true,
    modifyTradein: true,
    priceLogAccess: true,
    modifyOfferAccess: true
  },
  tradeinProPipeline: ['Appraisal'],
}

export const managerroleVM = {
  addedDate: null,
  addedBy: '',
  modifiedDate: null,
  modifiedBy: '',
  admin: {
    clientSettings: true,
    workflowSetup: true,
    productsData: true,
    modules: true,
    integration: false,
    locationMgnt: false,
    securityMgnt: false,
    billing: false
  },
  documentID: null,
  level: 'individual',
  modules: {
    enquiry: true,
    frontdesk: true,
    kitchen: true,
    loanVehicle: true,
    stock: true
  },
  name: 'Manager',
  permissions: {
    deleteActivity: true,
    deleteEnquiry: true,
    exportData: true,
    exportContactData: true,
    lostApproval: true,
    mergeContact: true,
    modelPrice: true,
    modifyActivityOwner: true,
    modifyCampaign: true,
    modifyEnquiryOrigin: true,
    modifyEnquiryOwner: true,
    modifyEnquiryType: true,
    modifyLabel: true,
    modifyLostReason: true,
    modifyOtherActivity: true,
    modifyOtherEnquiry: true,
    modifyPurhaseInt: true,
    modifyRequirements: true,
    modifyStatusDate: true,
    modifyVehicleInfo: true,
    movePipeline: true,
    queueReorder: true,
    reopenEnquiry: true,
    returnVehicle: true,
    testdriveHandover: true,
    transferEnquiry: true,
    verifyEnquiry: true,
    viewOtherEnquiry: true,
    viewOtherContact: true,
    modifyContactOwner: true,
    modifyOtherContact: true,
    transferContact: true,
    deleteContact: true,
    deleteFiles: true,
    modifyNotes: true,
    ownerDashboard: false,
    unlinkEnquiry: false,
    accessOEMStock: true,
    accessDealertarget: true,
    viewOtherActivity: true,
    modifyStageAfterDeliveredLost: true,
    roNumRequired: false,
    dealerLeadTransfer: true,
    readOnlyEnquiryView: false,
    bdcDashboard: true,
    dealerFileUpload: false,
    addQuotation: false,
    deleteQuotation: false,
    createLeadOnLost: false,
    deleteStock: false,
    accessStaticReport: false,
    previewEnquiry: true,
    editDynamicReports: false,
    modifyLeadSource: true,
    scheduleContactList: true
  },
  pipeline: ["LeadsBucket", "Sales"]
}

export const salesroleVM = {
  addedDate: null,
  addedBy: '',
  modifiedDate: null,
  modifiedBy: '',
  admin: {
    clientSettings: false,
    workflowSetup: false,
    productsData: false,
    modules: false,
    integration: false,
    locationMgnt: false,
    securityMgnt: false,
    billing: false
  },
  documentID: null,
  level: 'individual',
  modules: {
    enquiry: true,
    frontdesk: false,
    kitchen: false,
    loanVehicle: true,
    stock: true
  },
  name: 'Sales',
  permissions: {
    deleteActivity: false,
    deleteEnquiry: false,
    exportData: false,
    lostApproval: false,
    mergeContact: false,
    modelPrice: false,
    modifyActivityOwner: false,
    modifyCampaign: false,
    modifyEnquiryOrigin: false,
    modifyEnquiryOwner: false,
    modifyEnquiryType: false,
    modifyLabel: false,
    modifyLostReason: false,
    modifyOtherActivity: false,
    modifyOtherEnquiry: false,
    modifyPurhaseInt: false,
    modifyRequirements: false,
    modifyStatusDate: false,
    modifyVehicleInfo: false,
    movePipeline: false,
    queueReorder: false,
    reopenEnquiry: false,
    returnVehicle: false,
    testdriveHandover: false,
    transferEnquiry: false,
    verifyEnquiry: false,
    viewOtherEnquiry: false,
    viewOtherContact: false,
    modifyContactOwner: false,
    modifyOtherContact: false,
    transferContact: false,
    deleteContact: false,
    deleteFiles: false,
    modifyNotes: false,
    ownerDashboard: false,
    unlinkEnquiry: false,
    accessOEMStock: false,
    accessDealertarget: false,
    viewOtherActivity: false,
    modifyStageAfterDeliveredLost: false,
    roNumRequired: false,
    dealerLeadTransfer: false,
    readOnlyEnquiryView: false,
    bdcDashboard: false,
    dealerFileUpload: false,
    addQuotation: false,
    deleteQuotation: false,
    createLeadOnLost: false,
    deleteStock: false,
    accessStaticReport: false,
    previewEnquiry: false,
    editDynamicReports: false,
    modifyLeadSource: false,
    scheduleContactList: false
  },
  pipeline: ["Sales"]
}


export const defaultpipelineVM = [
  {
    name: 'Inbound Leads',
    value: 'LeadsBucket',
    active: true,
    stages: [
      // {
      //     name: 'Inbound Lead',
      //     value: 'InboundLead',
      //     rottenDays: 0,
      // },
      {
        name: 'New',
        value: 'New',
        rottenDays: 0,
      },
      {
        name: 'Contacted',
        value: 'Contacted',
        rottenDays: 0,
      },
      {
        name: 'Archived',
        value: 'Archived',
        rottenDays: 0,
      },
      {
        name: 'Converted',
        value: 'Converted',
        rottenDays: 0,
      }],
    modules: {
      contact: true,
      requirement: true,
      testDrive: true,
      tradeIn: true,
      cafe: true,
      files: true,
      finance: false,
      amsVideo: false
    }
  }, {
    name: 'Sales',
    value: 'Sales',
    active: true,
    stages: [{
      name: 'Lead In',
      value: 'LeadIn',
      rottenDays: 0,
    },
    {
      name: 'Contacted',
      value: 'Contacted',
      rottenDays: 0,
    },
    {
      name: 'Appointment Scheduled',
      value: 'AppointmentScheduled',
      rottenDays: 0,
    },
    {
      name: 'Test Drive Completed',
      value: 'TestDriveCompleted',
      rottenDays: 0,
    },
    {
      name: 'Proposal',
      value: 'Proposal',
      rottenDays: 0,
    },
    {
      name: 'Won',
      value: 'won',
      rottenDays: 0,
    },
    {
      name: 'Lost',
      value: 'Lost',
      rottenDays: 0,
    }],
    modules: {
      contact: true,
      requirement: true,
      testDrive: true,
      tradeIn: true,
      cafe: true,
      files: true,
      finance: false,
      amsVideo: false
    }
  }]

export const defaultCallStatus = [
  {
    name: 'Contacted',
    value: 'Contacted',
    level: 'oem',
    active: true
  },
  {
    name: 'Rescheduled',
    value: 'Rescheduled',
    level: 'oem',
    active: true
  },
  {
    name: 'Left Voice Mail',
    value: 'Left-Voice-Mail',
    level: 'oem',
    active: true
  },
  {
    name: 'Not Answering',
    value: 'Not-Answering',
    level: 'oem',
    active: true
  },
  {
    name: 'Wrong Number',
    value: 'Wrong-Number',
    level: 'oem',
    active: true
  }
]

export const defaultEnquiryTypes = [
  {
    name: 'Walk In',
    value: 'Walk-In',
    level: 'oem',
    active: true
  },
  {
    name: 'Phone',
    value: 'Phone',
    level: 'oem',
    active: true
  },
  {
    name: 'Internet',
    value: 'Web',
    level: 'oem',
    active: true
  }
]

export const defaultEnquiryOptions = [
  {
    name: 'Quotation Offered',
    value: 'Quotation-Offered',
    level: 'oem',
    type: 'select',
    active: true,
    subList: ['Yes', 'No']
  },
  {
    name: 'Manager Introduction',
    value: 'Manager-Introduction',
    level: 'oem',
    type: 'select',
    active: true,
    subList: ['Yes', 'No']
  },
  {
    name: 'Payment Method',
    value: 'Payment-Method',
    level: 'oem',
    type: 'select',
    active: true,
    subList: ['EFT', 'Cash', 'Credit Card', 'Cheque', 'Finance']
  },
]

export const defaultFinanceStatus = [
  {
    name: 'Pending',
    value: 'Pending',
    level: 'oem',
    active: true,
    color: '#F68412'
  },
  {
    name: 'In Progress',
    value: 'InProgress',
    level: 'oem',
    color: '#F05A24',
    active: true,
  },
  {
    name: 'Won',
    value: 'Won',
    level: 'oem',
    active: true,
    color: '#00A75F',
  },
  {
    name: 'Lost',
    value: 'Lost',
    level: 'oem',
    active: true,
    color: '#EF4136',
  }
]

export const defaultOrigins = [
  {
    name: 'Referral',
    value: 'Referral',
    level: 'oem',
    active: true
  },
  {
    name: 'Existing Customer',
    value: 'Existing-Customer',
    level: 'oem',
    active: true
  },
  {
    name: 'Driving Past',
    value: 'Driving-Past',
    level: 'oem',
    active: true
  },
  {
    name: 'Manufacturer Lead',
    value: 'Manufacturer-Lead',
    level: 'oem',
    active: true
  },
  {
    name: 'Website',
    value: 'Website',
    level: 'oem',
    active: true
  },
  {
    name: 'Facebook',
    value: 'Facebook',
    level: 'oem',
    active: true
  },
  {
    name: 'Google',
    value: 'Google',
    level: 'oem',
    active: true
  },
  {
    name: 'Radio',
    value: 'Radio',
    level: 'oem',
    active: true
  },
  {
    name: 'TV',
    value: 'TV',
    level: 'oem',
    active: true
  }
]
export const defaultTestdrivefeedback = [
  {
    name: 'Did The Car Meet Expectations?',
    value: 'Did-The-Car-Meet-Expectations',
    level: 'oem',
    active: true
  },
  {
    name: 'Did You Like The Car?',
    value: 'Did-You-Like-The-Car',
    level: 'oem',
    active: true
  }
]

export const defaultTitles = [
  {
    name: 'Mr.',
    value: 'Mr.',
    level: 'oem',
    active: true
  },
  {
    name: 'Mrs.',
    value: 'Mrs.',
    level: 'oem',
    active: true
  },
  {
    name: 'Ms.',
    value: 'Ms.',
    level: 'oem',
    active: true
  },
  {
    name: 'Dr.',
    value: 'Dr.',
    level: 'oem',
    active: true
  },
  {
    name: 'Mr. & Mrs.',
    value: 'Mr. & Mrs.',
    level: 'oem',
    active: true
  },
  {
    name: 'Sheikh',
    value: 'Sheikh',
    level: 'oem',
    active: true
  },
  {
    name: 'Sheikha',
    value: 'Sheikha',
    level: 'oem',
    active: true
  }
]

export const defaultReqInfo = [
  {
    name: 'Safety',
    value: 'Safety',
    level: 'oem',
    active: true
  },
  {
    name: 'Appearance',
    value: 'Appearance',
    level: 'oem',
    active: true
  },
  {
    name: 'Comfort',
    value: 'Comfort',
    level: 'oem',
    active: true
  },
  {
    name: 'Warranty',
    value: 'Warranty',
    level: 'oem',
    active: true
  }
]

export const defaultLostReasons = [
  {
    active: true,
    level: "oem",
    name: "Bought Another Brand",
    subList: [
      {
        name: "Volvo",
        value: "Volvo"
      },
      {
        name: "Volkswagen",
        value: "Volkswagen"
      },
      {
        name: "Toyota",
        value: "Toyota"
      },
      {
        name: "Tesla",
        value: "Tesla"
      },
      {
        name: "Suzuki",
        value: "Suzuki"
      },
      {
        name: "Subaru",
        value: "Subaru"
      },
      {
        name: "Saab",
        value: "Saab"
      },
      {
        name: "Rolls-Royce",
        value: "Rolls-Royce"
      },
      {
        name: "RAM",
        value: "Ram"
      },
      {
        name: "Porsche",
        value: "Porsche"
      },
      {
        name: "Nissan",
        value: "Nissan"
      },
      {
        name: "Mitsubishi",
        value: "Mitsubishi"
      },
      {
        name: "Mercury",
        value: "Mercury"
      },
      {
        name: "Mercedes-Benz",
        value: "Mercedes-Benz"
      },
      {
        name: "Mazda",
        value: "Mazda"
      },
      {
        name: "Maserati",
        value: "Maserati"
      },
      {
        name: "Lotus",
        value: "Lotus"
      },
      {
        name: "Lincoln",
        value: "Lincoln"
      },
      {
        name: "Lexus",
        value: "Lexus"
      },
      {
        name: "Land Rover",
        value: "Land Rover"
      },
      {
        name: "Kia",
        value: "Kia"
      },
      {
        name: "Jeep",
        value: "Jeep"
      },
      {
        name: "Jaguar",
        value: "Jaguar"
      },
      {
        name: "Infiniti",
        value: "Infiniti"
      },
      {
        name: "Hyundai",
        value: "Hyundai"
      },
      {
        name: "Honda",
        value: "Honda"
      },
      {
        name: "Genesis",
        value: "Genesis"
      },
      {
        name: "GMC",
        value: "GMC"
      },
      {
        name: "Ford",
        value: "Ford"
      },
      {
        name: "Fiat",
        value: "Fiat"
      },
      {
        name: "Dodge",
        value: "Dodge"
      },
      {
        name: "Chrysler",
        value: "Chrysler"
      },
      {
        name: "Chevrolet",
        value: "Chevrolet"
      },
      {
        name: "Cadillac",
        value: "Cadillac"
      },
      {
        name: "Bentley",
        value: "Bentley"
      },
      {
        name: "BMW",
        value: "BMW"
      },
      {
        name: "Audi",
        value: "Audi"
      },
      {
        name: "Alfa Romeo",
        value: "Alfa Romeo"
      },
      {
        name: "Acura",
        value: "Acura"
      }
    ],
    value: "Bought-Another-Brand"
  },
  {
    active: true,
    level: "oem",
    name: "Bought From Another Dealer",
    subList: [],
    value: "Bought-From-Another-Dealer"
  },
  {
    active: true,
    level: "oem",
    name: "Bought Private",
    subList: [],
    value: "Bought-Private"
  },
  {
    active: true,
    level: "oem",
    name: "Not In The Market",
    subList: [],
    value: "Not-In-The-Market"
  },
  {
    active: true,
    level: "oem",
    name: "Cancelled Sale",
    subList: [],
    value: "Cancelled-Sale"
  },
  {
    active: true,
    level: "oem",
    name: "Trade Value",
    subList: [],
    value: "Trade-Value"
  },
  {
    active: true,
    level: "oem",
    name: "Not A Sales Enquiry",
    subList: [],
    value: "Not-A-Sales-Enquiry"
  },
  {
    active: true,
    level: "oem",
    name: "Unable To Contact",
    subList: [],
    value: "Unable-To-Contact"
  },
  {
    active: true,
    level: "oem",
    name: "Duplicate Lead",
    subList: [],
    value: "Duplicate-Lead"
  }
]

export const defaultGenders = [
  {
    name: 'Male',
    value: 'Male',
    level: 'oem',
    active: true
  },
  {
    name: 'Female',
    value: 'Female',
    level: 'oem',
    active: true
  }
]

export const defaultMaritalStatus = [
  {
    name: 'Married',
    value: 'Married',
    level: 'oem',
    active: true
  },
  {
    name: 'Single',
    value: 'Single',
    level: 'oem',
    active: true
  },
  {
    name: 'Divorced',
    value: 'Divorced',
    level: 'oem',
    active: true
  },
  {
    name: 'Widow',
    value: 'Widow',
    level: 'oem',
    active: true
  }
]

export const defaultContactMethod = [
  {
    name: 'Mobile',
    value: 'Mobile',
    level: 'oem',
    active: true
  },
  {
    name: 'Email',
    value: 'Email',
    level: 'oem',
    active: true
  },
  {
    name: 'Post',
    value: 'Post',
    level: 'oem',
    active: true
  }
]
export const defaultInterests = [
  {
    name: 'Art',
    value: 'Art',
    level: 'oem',
    active: true
  },
  {
    name: 'Books',
    value: 'Books',
    level: 'oem',
    active: true
  },
  {
    name: 'Cars',
    value: 'Cars',
    level: 'oem',
    active: true
  },
  {
    name: 'Sports',
    value: 'Sports',
    level: 'oem',
    active: true
  },
  {
    name: 'Paintings',
    value: 'Paintings',
    level: 'oem',
    active: true
  }
]

export const defaultLicenseType = [
  {
    name: 'Full',
    value: 'Full',
    level: 'oem',
    active: true
  },
  {
    name: 'Provisional',
    value: 'Provisional',
    level: 'oem',
    active: true
  },
  {
    name: 'Learner',
    value: 'Learner',
    level: 'oem',
    active: true
  }
]

export const defaultCompanyType = [
  {
    name: 'Public',
    value: 'Public',
    level: 'oem',
    active: true
  },
  {
    name: 'Private',
    value: 'Private',
    level: 'oem',
    active: true
  }
]

export const defaultSalesTypes = [
  {
    active: true,
    level: "oem",
    name: "New",
    value: "New"
  },
  {
    active: true,
    level: "oem",
    name: "Demo",
    value: "Demo"
  },
  {
    active: true,
    level: "oem",
    name: "Preowned",
    value: "Preowned"
  }
]

//default serive lookups
export const servicepipelineVM = [
  {
    name: 'Service',
    value: 'Service',
    active: true,
    stages: [{
      name: 'Appointment Scheduled',
      value: 'AppointmentScheduled',
      rottenDays: 0,
    },
    {
      name: 'Checked In',
      value: 'CheckedIn',
      rottenDays: 0,
    },
    {
      name: 'Preparing Quote',
      value: 'PreparingQuote',
      rottenDays: 0,
    },
    {
      name: 'Awaiting Approval',
      value: 'AwaitingApproval',
      rottenDays: 0,
    },
    {
      name: 'Work In Progress',
      value: 'WorkInProgress',
      rottenDays: 0,
    },
    {
      name: 'Completed',
      value: 'Completed',
      rottenDays: 0,
    },
    {
      name: 'Declined',
      value: 'Declined',
      rottenDays: 0,
    }],
    modules: {}
  }]

export const defaultAppointmentTypes = [
  {
    name: 'Walk In',
    value: 'Walk-In',
    level: 'oem',
    active: true
  },
  {
    name: 'Phone',
    value: 'Phone',
    level: 'oem',
    active: true
  },
  {
    name: 'Internet',
    value: 'Web',
    level: 'oem',
    active: true
  }
]

export const defaultSlots = [
  {
    name: 'Morning Slot',
    value: 'MorningSlot',
    level: 'oem',
    active: true,
    from: "08:00",
    to: "12:00",
    isExpress: false,
    color: "#00A75F",
    total: 5,
  },
  {
    name: 'Evening Slot',
    value: 'EveningSlot',
    level: 'oem',
    active: true,
    from: "12:00",
    to: "16:00",
    isExpress: false,
    color: "#F7931E",
    total: 5,
  }
]
export const defaultserviceTypes = [
  {
    name: '5000KM',
    value: '5k',
    level: 'oem',
    active: true
  },
  {
    name: '10000KM',
    value: '10K',
    level: 'oem',
    active: true
  },
  {
    name: '20000KM',
    value: '20K',
    level: 'oem',
    active: true
  },
  {
    name: '30000KM',
    value: '30K',
    level: 'oem',
    active: true
  },
  {
    name: '40000KM',
    value: '40K',
    level: 'oem',
    active: true
  },
  {
    name: '50000KM',
    value: '50K',
    level: 'oem',
    active: true
  },
  {
    name: '60000KM',
    value: '60K',
    level: 'oem',
    active: true
  }
]

export const defaultReasons = [
  {
    name: 'Not required',
    value: 'Not-required',
    level: 'oem',
    active: true
  },
  {
    name: 'Will do next time',
    value: 'Will-do-next-time',
    level: 'oem',
    active: true
  },
  {
    name: 'Expensive',
    value: 'Expensive',
    level: 'oem',
    active: true
  }
]

export const defaultPartStatus = [
  {
    name: 'Available',
    value: 'Available',
    level: 'oem',
    active: true
  },
  {
    name: 'Not Available',
    value: 'Not-Available',
    level: 'oem',
    active: true
  }
]

export const defaultEnquiryStatus = [
  {
    "active": true,
    "name": "Open",
    "default": true,
    "color": "#ffffff",
    "value": "open",
    "level": "oem"
  },
  {
    "name": "Won",
    "default": true,
    "active": true,
    "value": "won",
    "color": "#2bb673",
    "level": "oem"
  },
  {
    "color": "#049b8c",
    "active": true,
    "value": "delivered",
    "default": true,
    "name": "Delivered",
    "level": "oem"
  },
  {
    "value": "lost",
    "color": "#ef4136",
    "active": true,
    "default": true,
    "level": "oem",
    "name": "Lost"
  },
  {
    "default": true,
    "name": "Prospect Lost",
    "color": "#ef4136",
    "level": "oem",
    "value": "prospectLost",
    "active": true
  }
]

export const defaultActivityTypes = [
  {
    "default": true,
    "active": true,
    "icon": "activity-appointment",
    "name": "Appointment",
    "level": "oem",
    "value": "appointment"
  },
  {
    "value": "testdrive",
    "active": true,
    "level": "oem",
    "default": true,
    "icoVehicle": true,
    "name": "Test Drive",
    "icon": "activity-testdrive"
  },
  {
    "value": "call",
    "level": "oem",
    "icon": "call",
    "active": true,
    "default": true,
    "name": "Call"
  },
  {
    "level": "oem",
    "value": "email",
    "active": true,
    "default": true,
    "name": "Mail",
    "icon": "Mail"
  },
  {
    "default": true,
    "active": true,
    "name": "Delivery",
    "icon": "activity-delivery",
    "icoVehicle": true,
    "value": "delivery",
    "level": "oem"
  },
  {
    "active": true,
    "default": true,
    "name": "Task",
    "value": "task",
    "level": "oem",
    "icon": "activity"
  }
]

export const defaultCafecategory = [
  {
    name: 'Drinks',
    value: 'drinks',
    level: 'oem',
    active: true
  }
]

//default tradein lookups
export const tradeinpropipelineVM = [
  {
    name: 'Appraisal',
    value: 'Appraisal',
    active: true,
    stages: [{
      name: 'New',
      value: 'New',
      rottenDays: 0,
    },
    {
      name: 'Awaiting Offer',
      value: 'AwaitingOffer',
      rottenDays: 0,
    },
    {
      name: 'Won',
      value: 'Won',
      rottenDays: 0,
    },
    {
      name: 'Lost',
      value: 'Lost',
      rottenDays: 0,
    }],
    modules: {}
  }]


  //default events lookups
export const eventspropipelineVM = [
  {
    name: 'Events',
    value: 'Events',
    active: true,
    stages: [{
      name: 'Draft',
      value: 'Draft',
      rottenDays: 0,
    },
    {
      name: 'Publish',
      value: 'Publish',
      rottenDays: 0,
    },
    {
      name: 'Live',
      value: 'Live',
      rottenDays: 0,
    },
    {
      name: 'Closed',
      value: 'Closed',
      rottenDays: 0,
    }],
    modules: {}
  }]

  export const defaultEventCategories = [
    {
      name: 'Invitation',
      value: 'invitation',
      level: 'oem',
      active: true,
      default: true,
    },
    {
      name: 'Confirmation',
      value: 'accepted',
      level: 'oem',
      active: true,
      default: true,
    },
    {
      name: 'Tentative',
      value: 'tentative',
      level: 'oem',
      active: true,
      default: true,
    },
    {
      name: 'Declined',
      value: 'declined',
      level: 'oem',
      active: true,
      default: true,
    },
    {
      name: 'Reminder',
      value: 'reminder',
      level: 'oem',
      active: true,
      default: true,
    },
    {
      name: 'Post Event Show',
      value: 'post-event-show',
      level: 'oem',
      active: true,
      default: true,
    },
    {
      name: 'Post Event No Show',
      value: 'post-event-no-show',
      level: 'oem',
      active: true,
      default: true,
    }
  ]