import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
// import ReactToPrint from 'react-to-print';
import { default as _images } from '../../images';
// import Print from './print';
import EmailListItem from './emailListItem';
import { MainContext } from '../layout/provider';
import { PopUpModal } from '../../components';
import SelectContact from '../contacts/list/index';
import AddContact from '../contacts/add/index';
import SelectEnquiry from '../pipeline/popoverlist/index';
import { enquiryBasicDetailsVM } from '../pipeline/viewModel';
import { contactBasicDetailsVM } from '../contacts/viewModel';
import CommonHelper from '../../services/common'
import Translate from '../../constants/translate';
import toast from 'toasted-notes';
import Swal from 'sweetalert2';
import { firestoreDB } from '../../services/helper';

const EmailListBody = ({
	selectedThread,
	token,
	handleOpenComposeModal,
	updateThread,
	threadsLoader,
	type,
	disableLinkedinfo,
	refreshLoader,
	setSelectedThread,
	userData,
	isPreview
}) => {
	const node = useRef();
	const [loading, setLoading] = useState(disableLinkedinfo ? false : true);
	const [linkLoading, setLinkLoading] = useState(true);
	const [messages, setMessages] = useState([]);
	const [showContacts, setShowContacts] = useState(false);
	const [showAddContact, setShowAddContact] = useState(false);
	const [showAddEnquiry, setShowAddEnquiry] = useState(false);
	const [selectedCustomer, setCustomer] = useState(null);
	const [selectedEnquiry, setEnquiry] = useState(null);
	const [emailEnquiryDocID, setEmailEnquiryDocID] = useState(null);
	const [editshow, setEditshow] = useState(false);
	let isArchive = false;
	if (selectedThread && !isEmpty(selectedThread.folders)) {
		selectedThread.folders.forEach(folder => {
			if (folder.name === 'archive') {
				isArchive = true
			}
		})
	}
	if (selectedThread && !isEmpty(selectedThread.labels)) {
		selectedThread.labels.forEach(label => {
			if (label.name === 'archive') {
				isArchive = true
			}
		})
	}

	const { dealersettings, clientUsers, groupUsers } = useContext(MainContext);
	const history = useHistory();
	const nylasEmail = dealersettings.nylas_v3 && dealersettings.nylas_v3.email_address ? dealersettings.nylas_v3.email_address : dealersettings.email
	const important_id = CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Important', 'id', true)
	useEffect(() => {

		//console.log('EmailListBody', type)
		function handleResize() {
			document.documentElement.style.setProperty('--emailListBodyHeight', window.innerHeight - 55 + 'px');
			document.documentElement.style.setProperty('overflow', 'hidden');
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty('--emailListBodyHeight', window.innerHeight - 55 + 'px');
		document.documentElement.style.setProperty('overflow', 'hidden');
		return () => {
			window.removeEventListener('resize', handleResize);
			///window.emailsnapshots && window.emailsnapshots()
		};

	}, []);


	useEffect(() => {
		/// if (disableLinkedinfo === true) {
		/// 	setLoading(true);
		/// 	const messageIdList = selectedThread.messageIds;
		/// 	const messageIdListLength = selectedThread.messageIds.length;
		/// 	// console.log('THIS IS SELECTED MESSAGE IDS messageIdList', messageIdList); 
		/// 	setMessages([]);
		/// 	const check = [];

		/// 	window.firebase.firestore().doc(`nylasv3-accounts/${selectedThread.grantId}/nylas-threads/${selectedThread.id}`).get()
		/// 		.then(snapshot => {
		/// 			let query = `enquiryEmails/${selectedThread.documentID}/messages`
		/// 			if (snapshot.exists) {
		/// 				query = `nylasv3-accounts/${selectedThread.grantId}/nylas-threads/${selectedThread.id}/nylas-msgs`
		/// 			}
		/// 			const linkedMessageList = firestoreDB(snapshot.exists ? null : dealersettings).firestore().collection(query)
		/// 				.get()
		/// 				.then(snapshot => {
		/// 					snapshot.docs.forEach(doc => {
		/// 						check.push(doc.data());
		/// 					})
		/// 					setMessages(_.orderBy(check, ['date'], ['asc']));
		/// 					setLoading(false);
		/// 				})
		/// 		})
		/// }
		/// else {
		if (!_.isEmpty(selectedThread)) {
			console.log('selectedThread - Email Body', type, selectedThread)

			var nylasToken = dealersettings.nylasv3Config?.apiKey;// || 'nyk_v0_DCFJja0CV8TWUjZomlLrdXWQ6m741RoHAqVrJFzAcx6mlCFw3EssyJS2V7BJAlok'
			var nylasURL = dealersettings.nylasv3Config?.apiUri;// || 'https://api.eu.nylas.com'

			let nylasAccountID = null;
			if (selectedThread?.grantId) nylasAccountID = selectedThread.grantId
			else if (dealersettings && !isEmpty(dealersettings.nylas_v3)) nylasAccountID = dealersettings.nylas_v3.grantId
			else if (userData && !isEmpty(userData.nylas_v3)) nylasAccountID = userData.nylas_v3.grantId

			// else if (_.isEmpty(nylasAccountID) && _.isEmpty(selectedThread.latestDraftOrMessage) && !_.isEmpty(selectedThread.from)) {
			// 	setMessages([{ ...selectedThread }]);
			// }

			if (!_.isEmpty(selectedThread.latestDraftOrMessage)) {
				setMessages([{ ...selectedThread.latestDraftOrMessage }]);
			}
			else if (disableLinkedinfo === true && (_.isEmpty(selectedThread.from) || _.isEmpty(nylasAccountID))) {
				setLoading(true);
				setMessages([]);
				const check = [];
				firestoreDB(dealersettings).firestore().collection(`enquiryEmails/${selectedThread.documentID}/messages`)
					.get()
					.then(snapshot => {
						if (snapshot && snapshot.size > 0) {
							snapshot.docs.forEach(doc => {
								check.push(doc.data());
							})
							setMessages(_.orderBy(check, ['date'], ['asc']));
						}
						setLoading(false);
					});
			}
			else
				setMessages([]);

			if (_.isEmpty(nylasToken) || _.isEmpty(nylasToken) || _.isEmpty(nylasAccountID)) return;

			setLoading(true);
			const requestOptions = {
				method: "GET",
				//headers: myHeaders,
				headers: {
					Authorization: `Bearer ${nylasToken}`,
					'Content-Type': 'application/json',
				},
				redirect: "follow"
			};

			fetch(`${nylasURL}/v3/grants/${nylasAccountID}/messages?thread_id=${selectedThread.id}`, requestOptions)
				.then((response) => response.text())
				.then((result) => {
					let respo = JSON.parse(result)
					//console.log('fetchUserData', respo?.data)
					if (!_.isEmpty(respo?.error) || (_.isEmpty(respo?.data) && disableLinkedinfo === true)) {
						//setLoading(false);
						const check = [];
						firestoreDB(dealersettings).firestore().collection(`enquiryEmails/${selectedThread.documentID}/messages`)
							.get()
							.then(snapshot => {
								if (snapshot && snapshot.size > 0) {
									snapshot.docs.forEach(doc => {
										check.push(doc.data());
									})
									setMessages(_.orderBy(check, ['date'], ['asc']));
								}
								setLoading(false);
							});
						console.error(respo?.error)
					}
					else {
						let _mapData = Object.assign([], respo?.data).map((doc) => {
							return CommonHelper.convertObjectKeys(doc)
						})

						setMessages(
							_.orderBy(
								_.unionBy([], _mapData, 'id'),
								['date'],
								['asc'],
							),
						);
					}
					setLoading(false);
					//console.log('_mapData', _mapData)
				})
				.catch((error) => console.error(error));

			// //  var nylasMessages = window.firebase.functions().httpsCallable('nylasv3-messages');
			// //  nylasMessages({
			// //  	grantId: userData.nylas_v3.grantId,
			// //  	options: { threadId: selectedThread.id }
			// //  }).then(res => {
			// //  	console.log('nylasMessages', res)
			// //  	if (res?.data?.success === true) {

			// //  		setMessages(
			// //  			_.orderBy(
			// //  				_.unionBy([], res?.data?.list?.data, 'id'),
			// //  				['date'],
			// //  				['asc'],
			// //  			),
			// //  		);
			// //  		setLoading(false);
			// //  	}
			// //  }).catch(err => console.log(err))	


			if (selectedThread.unread === true) {
				updateThread(selectedThread, 'read', {});
			}
		}
		///}

		/// return () => {
		/// 	window.emailsnapshots && window.emailsnapshots()
		/// };
	}, [selectedThread, token]);


	useEffect(() => {
		const fetchLinkDoc = async () => {
			if (isEmpty(selectedThread) || disableLinkedinfo) {
				return
			}
			try {
				setLinkLoading(true);
				setCustomer(null);
				setEnquiry(null);
				setEmailEnquiryDocID(null);
				let data;
				const ref = firestoreDB(dealersettings)
					.firestore()
					.collection('enquiryEmails')
					.where('threadID', '==', selectedThread.id);
				const snap = await ref.get();
				if (snap.empty) {
					// console.log('No matching documents.');
					return;
				}
				snap.forEach(doc => {
					// console.log(doc.id, '=>', doc.data());
					data = doc.data();
					setEmailEnquiryDocID(data.id);
				});

				if (!isEmpty(data.contact)) {
					setCustomer(data.contact);
				}
				else if (data.contactID) {
					firestoreDB(dealersettings).firestore().doc(`contactDetails/${data.contactID}`).get().then(doc => {
						if (doc.exists) {
							setCustomer(doc.data());
						}
					});
				}

				if (!isEmpty(data.enquiry)) {
					setEnquiry(data.enquiry);
				}
				else if (data.enquiryID) {
					firestoreDB(dealersettings).firestore().doc(`enquiries/${data.enquiryID}`).get().then(doc => {
						if (doc.exists) {
							setEnquiry(doc.data());
						}
					});
				}
				setLinkLoading(false);
			} catch (error) {
				setLinkLoading(false);
				console.log(error);
			}
		};
		fetchLinkDoc();
	}, [selectedThread, token]);

	const handleReply = isReplyToAll => {
		if (_.isEmpty(dealersettings.nylas_v3?.grantId)) {
			Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Please configure email settings'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Add Settings'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
			}).then(result => {
				if (result.value) {
					history.push('/settings/profile?key=emailsettings');
				}
			});
			return
		}
		let lastMessage = messages[messages.length - 1];

		let replyEmailDate = lastMessage.date;
		let replyEmailName = lastMessage.from[0].name;
		let replyEmailAddress = lastMessage.from[0].email;
		let replyToMessageId = lastMessage.id;
		let replyToEmailContent = lastMessage.body;
		let replyTo;
		let replyToCC;
		let replyToAll;
		let subject = lastMessage.subject;

		if (isReplyToAll) {
			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				if (messages[messages.length - 2]) {
					lastMessage = messages[messages.length - 2];
				}
			}

			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				lastMessage = messages[0];
			}

			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];



			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				replyTo = [
					{ name: lastMessage.to[0].name, email: lastMessage.to[0].email },
				];
			}

			// added this for reply to fix
			if (lastMessage.replyTo.length > 0) {
				// if (lastMessage.from[0].email === currentUserEmail)
				replyTo = [
					{ name: lastMessage.replyTo[0].name, email: lastMessage.replyTo[0].email },
				];
			}

			replyToCC = selectedThread.participants.filter((participant, i) => {
				if (participant.email === nylasEmail) {
					return false;
				}

				if (participant.email === replyTo[0].email) {
					return false;
				}

				return true;
			});

			// console.log('replyToCC', replyToCC);
			replyToAll = true;
		} else {
			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				if (messages[messages.length - 2]) {
					lastMessage = messages[messages.length - 2];
				}
			}

			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				lastMessage = messages[0];
			}

			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];

			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				replyTo = [
					{ name: lastMessage.to[0].name, email: lastMessage.to[0].email },
				];
			}
			replyToAll = false;
		}

		handleOpenComposeModal({
			type: 'reply',
			title: 'Reply',
			emailData: {
				replyTo,
				replyToMessageId,
				replyToEmailContent,
				replyEmailDate,
				replyEmailName,
				replyEmailAddress,
				replyToAll,
				replyToCC,
				subject
			},
			contact: selectedCustomer,
			enquiry: selectedEnquiry
		});
	};

	const handleForward = () => {
		if (_.isEmpty(dealersettings.nylas_v3?.grantId)) {
			Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Please configure email settings'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Add Settings'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
			}).then(result => {
				if (result.value) {
					history.push('/settings/profile?key=emailsettings');
				}
			});
			return
		}
		let lastMessage = messages[messages.length - 1];
		let emailContent = lastMessage.body;

		handleOpenComposeModal({
			type: 'forward',
			title: 'Forward Message',
			emailData: { replyToEmailContent: emailContent, subject: lastMessage.subject },
			contact: selectedCustomer,
			enquiry: selectedEnquiry
		});
	};

	const handleContactsOpen = () => {
		setShowContacts(true);
	};

	const handleAddContactOpen = () => {
		setShowAddContact(true);
	};

	const handleAddEnquiryOpen = () => {
		setShowAddEnquiry(true);
	};

	const handleContactsClose = (id, objData) => {
		if (!objData) {
			setShowContacts(false);
			return;
		}
		setCustomer(objData);
		setShowContacts(false);
		linkEnquiryEmail('contactOnly', objData);
	};

	const handleAddContactClose = async id => {
		if (!id) {
			setShowAddContact(false);
			return;
		}

		const refData = firestoreDB(dealersettings)
			.firestore()
			.collection('contactDetails')
			.doc(id);
		const snapshot = await refData.get();
		if (!snapshot.exists) {
			return;
		}

		const data = snapshot.data();

		// console.log(data);

		linkEnquiryEmail('contactOnly', data);
		setCustomer(data);
		setShowAddContact(false);
	};

	const handleAddEnquiryClose = (id, objData) => {
		// console.log(objData);
		if (!objData) {
			setShowAddEnquiry(false);
			return;
		}
		if (!selectedCustomer) {
			setCustomer(objData.contact);
			setEnquiry(objData);
			setShowAddEnquiry(false);
			linkEnquiryEmail('enquiry', objData.contact, objData);
		} else {
			setEnquiry(objData);
			setShowAddEnquiry(false);
			linkEnquiryEmail('enquiryOnly', selectedCustomer, objData);
		}
	};

	const linkEnquiryEmail = async (_type, contact, enquiry) => {
		let id;
		let isEdit = false;

		if (selectedThread?.id) id = selectedThread.id
		else if (emailEnquiryDocID) {
			id = emailEnquiryDocID;
			isEdit = true;
		} else {
			id = firestoreDB(dealersettings).firestore().collection('enquiryEmails').doc().id;
		}
		// const id = moment()._d.getTime().toString();
		let _enquiryBasicVM = null;
		let _contactBasicVM = null;

		if (!isEmpty(contact)) {
			_contactBasicVM = Object.assign({}, contactBasicDetailsVM);
			for (let [key, value] of Object.entries(contact)) {
				if (_contactBasicVM.hasOwnProperty(key)) _contactBasicVM[key] = value;
			}
		}

		if (!isEmpty(enquiry)) {
			_enquiryBasicVM = Object.assign({}, enquiryBasicDetailsVM);
			for (let [key, value] of Object.entries(enquiry)) {
				if (_enquiryBasicVM.hasOwnProperty(key)) _enquiryBasicVM[key] = value;
			}
		}

		const ref = firestoreDB(dealersettings).firestore().collection('enquiryEmails').doc(id);

		const model = {
			documentID: (selectedThread.id || id),
			clientID: dealersettings.client.documentID,
			threadID: selectedThread.id,
			grantId: selectedThread.grantId,
			token: token,
			thread: selectedThread,
			projectId: dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null
		};

		if (isEdit) {
			model.modifiedBy = localStorage.uid;
			model.modifiedDate = window.firebase.firestore.Timestamp.now();
			model.modifiedFrom = 'web'
		} else {
			model.linkedBy = localStorage.uid;
			model.linkedDate = window.firebase.firestore.Timestamp.now();
			model.linkedFrom = 'web'
		}

		if (_type === 'contactOnly') {
			model.contactID = contact.documentID ? contact.documentID : null;
			model.contact = _contactBasicVM;
			model.enquiryID = null;
			model.enquiry = null;
		} else if (_type === 'enquiryOnly') {
			model.contactID = contact.documentID;
			model.contact = _contactBasicVM;
			model.enquiryID = enquiry.documentID;
			model.enquiry = _enquiryBasicVM;
		} else if (_type === 'enquiry') {
			model.contactID = enquiry.contact.documentID;
			model.contact = _contactBasicVM;
			model.enquiryID = enquiry.documentID;
			model.enquiry = _enquiryBasicVM;
		}

		setEmailEnquiryDocID(id);
		try {
			await ref.set(model, {
				merge: true,
			});
			toast.notify('Conversation linked successfully', {
				duration: 2000,
			});

			if (messages.length > 0) {
				messages.forEach(message => {
					let ref = firestoreDB(dealersettings).firestore().collection('enquiryEmails').doc(id).collection('messages').doc(message.id)
					ref.set({
						...message,
						projectId: dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null
					})
				})
			}
		} catch (error) {
			console.log(error);
		}
	};

	const unlinkEnquiryEmail = async id => {
		try {
			Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
				text: CommonHelper.showLocale({ dealersettings }, 'Do you want to unlink enquiry from this conversation.'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
			}).then(result => {
				if (result.value) {
					var jobskill_query = firestoreDB(dealersettings)
						.firestore()
						.collection('enquiryEmails')
						.where('threadID', '==', id);
					jobskill_query.get().then(function (querySnapshot) {
						querySnapshot.forEach(function (doc) {
							doc.ref.delete();
						});
						setCustomer(null);
						setEnquiry(null);
						setEmailEnquiryDocID(null);
					});
					toast.notify('Conversation unlinked successfully', {
						duration: 2000,
					});
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	return isEmpty(selectedThread) ? (
		<div className='email-list-body'>
			<div className='h-100  w-100'>
				<div className='spinner-loader h-100'>
					<div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
						<div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
						<div className="no-data-txt mt-2">  <Translate text={'No message selected'} /></div>
					</div>
				</div>
			</div>
		</div>

	) : loading || threadsLoader ? (
		<div className='email-list-body'>
			<div className='h-100  w-100'>
				{/* {CommonHelper.emailPlaceholder(4)} */}
				{/* <div className='spinner-loader h-100'>
					<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
						<div role='status' className='spinner-border loader-primary'></div>
						<p className='text-muted mt-3'></p>
					</div>
				</div> */}
				<p className="placeholder-glow">
					<span className="placeholder col-7"></span>
					<span className="placeholder col-4"></span>
					<span className="placeholder col-4"></span>
					<span className="placeholder col-6"></span>
					<span className="placeholder col-8"></span>
				</p>

				<p className="placeholder-glow">
					<span className="placeholder col-7"></span>
					<span className="placeholder col-4"></span>
					<span className="placeholder col-4"></span>
					<span className="placeholder col-6"></span>
					<span className="placeholder col-8"></span>
				</p>

				<p className="placeholder-glow">
					<span className="placeholder col-7"></span>
					<span className="placeholder col-4"></span>
					<span className="placeholder col-4"></span>
					<span className="placeholder col-6"></span>
					<span className="placeholder col-8"></span>
				</p>
			</div>
		</div>
	) : (
		<>
			<div className='email-list-body-scroll'>
				<div className='email-list-body'>
					<div className='email-details'>
						<div className='email-details-header'>
							<div className='float-left'>
								{
									disableLinkedinfo ? (<></>) : (
										<>
											{type === CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Trash', 'id') ? (
												<div title="Move to inbox"
													className='action-email-btn'
													onClick={() =>
														updateThread(selectedThread, 'moveToInbox', {})
													}
												>
													<a href='#' onClick={(e) => { e.preventDefault(); }}>
														{' '}
														<i className='ico icon-mail-inbox'></i>{' '}
													</a>
												</div>
											) : (
												<div title="Move to trash"
													className='action-email-btn'
													onClick={() => {
														Swal.fire({
															title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
															text: CommonHelper.showLocale({ dealersettings }, 'Do you want to move this email to trash.'),
															icon: 'info',
															showCancelButton: true,
															confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
															cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No')
														}).then((result) => {
															if (result.value) {
																updateThread(selectedThread, 'moveToTrash', {})
															}
														})
													}}
												>
													<a href='#' onClick={(e) => { e.preventDefault(); }}>
														{' '}
														<i className='ico icon-mail-delete'></i>{' '}
													</a>
												</div>
											)}
											{/* {!isArchive ? (
													<div title="Move to archive"
														className='action-email-btn'
														onClick={() =>
															updateThread(selectedThread, 'moveToArchive', {})
														}
													>
														<a href='#'>
															{' '}
															<i className='ico icon-mail-archive'></i>{' '}
														</a>
													</div>
												) : type !== 'trash' ? (
														<div title="Move to inbox"
															className='action-email-btn'
															onClick={() =>
																updateThread(selectedThread, 'moveToInbox', {})
															}>
															<a href='#'>
																{' '}
																<i className='ico icon-mail-inbox'></i>{' '}
															</a>
														</div>
													) : <></>} */}
										</>
									)
								}
								{/* <div className='action-email-btn' title="Print">
										<ReactToPrint
											trigger={() => (
												<a href='#'>
													{' '}
													<i className='ico icon-mail-print'></i>{' '}
												</a>
											)}
											content={() => componentRef.current}
										/>
									</div> */}
							</div>
							<div className='float-right'>
								<div className='action-email-btn' title="Reply">
									<a href='#' onClick={(e) => { e.preventDefault(); handleReply(false) }}>
										{' '}
										<i className='ico icon-mail-reply'></i>{' '}
									</a>
								</div>
								<div className='action-email-btn' title="Reply all">
									<a href='#' onClick={(e) => { e.preventDefault(); handleReply(true) }}>
										{' '}
										<i className='ico icon-mail-replyall'></i>{' '}
									</a>
								</div>
								<div className='action-email-btn' onClick={handleForward} title="Forward">
									<a href='#' onClick={(e) => { e.preventDefault(); }}>
										{' '}
										<i className='ico icon-mail-forward'></i>{' '}
									</a>
								</div>
							</div>
						</div>
						<div className='email-details-innerwrapper'>
							<h4 className='email-details-title'>
								{selectedThread.subject}

								{
									!_.isEmpty(selectedThread.folders) && selectedThread.folders.some(item => item.startsWith("CATEGORY_PROMOTIONS")) && (<span className="mail-external-btn">External</span>)
								}

								{
									important_id && (<span className="mail-important-ico-middle">
										<a href="#" onClick={(e) => { e.preventDefault(); }}>
											{
												selectedThread.folders.includes(important_id)
													?
													<i className="ico icon-mail-important-active"> </i>
													:
													<i className="ico icon-mail-important-linear"> </i>
											}
										</a>
									</span>)
								}


							</h4>

							{messages.length > 0 &&
								messages.map((message, index) => (
									<EmailListItem
										message={message}
										key={message.id}
										isMsgLatest={messages.length === index + 1}
										updateThread={updateThread}
										selectedThread={selectedThread}
										handleReply={handleReply}
										handleOpenComposeModal={handleOpenComposeModal}
										token={token}
										disableLinkedinfo={disableLinkedinfo}
										dealersettings={dealersettings}
										userData={userData}
										history={history}
										selectedCustomer={selectedCustomer}
										selectedEnquiry={selectedEnquiry}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									/>
								))}


							{
								disableLinkedinfo ? (<></>) : (
									<>
										<div className='email-details-message-footer'>
											<div className='float-right'>
												<button
													type='button'
													className='btn btn-default'
													onClick={() => handleReply(false)}
												>
													<i className='ico icon-mail-reply'></i> Reply
												</button>
												<button
													type='button'
													className='btn btn-default ml-2'
													onClick={handleForward}
												>
													<i className='ico icon-mail-forward'></i> Forward
												</button>
											</div>

											{type === CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Trash', 'id') ? (
												<button
													type='button'
													className='btn btn-default'
													onClick={() =>
														updateThread(selectedThread, 'moveToInbox', {})
													}
												>
													<i className='ico icon-mail-inbox'></i> Move to Inbox
												</button>
											) : (
												<button
													type='button'
													className='btn btn-default'
													onClick={() => {
														Swal.fire({
															title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
															text: CommonHelper.showLocale({ dealersettings }, 'Do you want to move this email to trash.'),
															icon: 'info',
															showCancelButton: true,
															confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
															cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No')
														}).then((result) => {
															if (result.value) {
																updateThread(selectedThread, 'moveToTrash', {})
															}
														})
													}}
												>
													<i className='ico icon-mail-delete'></i> Delete
												</button>
											)}
										</div>
									</>
								)
							}
							{/* <ReactToPrint
				trigger={() => (
					<button type='button' className='btn btn-default ml-2'>
						<i className='ico icon-mail-print'></i> Print
					</button>
				)}
				content={() => componentRef.current}
			/> */}
							{/*  */}



						</div>

					</div>
				</div>
				{
					disableLinkedinfo ? (<></>) : (<div className='email-list-right'>
						<div className='email-link-wraper'>
							{selectedCustomer === null ? (
								<div className='email-link-head'><span>Link to a Contact</span></div>
							) : (
								<>
									<div className='email-link-head'>
										<span>
											Linked Contact
										</span>
										<i
											className='ico icon-remove'
											onClick={() => unlinkEnquiryEmail(selectedThread.id)}
										></i>
									</div>
									<div className='email-link-info'>
										<div className='email-link-info-avatar'>
											<img
												className='bdrs-50p w-3r h-3r'
												alt=''
												src={_images.nouser}
											/>
										</div>
										{CommonHelper.displayContactName([], selectedCustomer)}
									</div>

									<div className='email-link-id'>
										<i className='ico icon-Mail'></i>{' '}
										{selectedCustomer && selectedCustomer.email ? selectedCustomer.email : '(no email)'}{' '}
									</div>
								</>
							)}

							{selectedCustomer === null && (
								<div className='email-link-buttons'>
									<button
										className='btn btn-sm btn-default'
										onClick={handleContactsOpen}
									>
										Add to existing contact
									</button>
									<button
										className='btn btn-sm btn-primary'
										onClick={handleAddContactOpen}
									>
										Create new contact
									</button>
								</div>
							)}
						</div>

						<div className='email-link-wraper w-100'>
							{selectedEnquiry ? (
								<>
									<div className='email-link-head'>
										<span>
											Linked Enquiry
										</span>
										<i
											className='ico icon-remove'
											onClick={() => unlinkEnquiryEmail(selectedThread.id)}
										>
											{' '}
										</i>
									</div>
									<div className='link-enquiry-item'>
										{/* <div className='link-enquiry-more'>
						<a href='#'>
							<i className='ico icon-more'></i>
						</a>
					</div> */}
										<div className='quickview-activity-more'>
											<a
												href='#'
												onClick={e => {
													e.preventDefault();
													setEditshow(!editshow);
													if (editshow)
														document.addEventListener('click', setEditshow(false));
												}}
											>
												<i className='ico icon-more'></i>
											</a>
											<div
												ref={node}
												className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'
													}`}
											>
												<ul className='more-dropdown'>
													<li>
														{' '}
														<a
															href='#'
															onClick={e => {
																e.preventDefault();
																history.push(
																	'/enquiry/details/' + selectedEnquiry.documentID,
																);
															}}
														>
															{' '}
															<i className='ico icon-expand'></i>{' '}
															<Translate text={'view'} />
														</a>{' '}
													</li>
												</ul>
											</div>
										</div>
										<div className='link-enquiry-title'>
											{/* {!isEmpty(selectedEnquiry.requirement)
							? `${
									selectedEnquiry.requirement.make &&
									selectedEnquiry.requirement.make
							  }${
									selectedEnquiry.requirement.model &&
									` ${selectedEnquiry.requirement.model}`
							  }`
							: '(vehicle missing)'}
							{' '} */}
											{!_.isEmpty(selectedEnquiry.requirement) ? (
												<>
													{!_.isEmpty(selectedEnquiry.requirement.stock) && !_.isEmpty(selectedEnquiry.requirement.stock.make) ? selectedEnquiry.requirement.stock.make + ' ' + selectedEnquiry.requirement.stock.model : !_.isEmpty(selectedEnquiry.requirement.make) ? selectedEnquiry.requirement.make + ' ' + selectedEnquiry.requirement.model : ''}
													{/* {selectedEnquiry.requirement.stock
													? selectedEnquiry.requirement.stock.make +
													' ' +
													selectedEnquiry.requirement.stock.model
													: selectedEnquiry.requirement.make +
													' ' +
													selectedEnquiry.requirement.model} */}
												</>
											) : (
												<div className='no-data-red'>Vehicle missing</div>
											)}
										</div>
										<div className='link-enquiry-vehicle-status'>
											{' '}
											{selectedEnquiry.requirement &&
												selectedEnquiry.requirement.saleType && (
													<div className='badge badge-pill badge-white ml-1'>
														{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
															selectedEnquiry.requirement.saleType, '')}
													</div>
												)}
										</div>

										<div className='link-enquiry-owner'>
											<div className='link-enquiry-owner-view'>
												<a href='#' onClick={(e) => e.preventDefault()}>
													<img src={_images.nouser} alt='' />
												</a>
											</div>
											{CommonHelper.displayContactName([], selectedCustomer)}
										</div>
										{selectedEnquiry.status && _.isString(selectedEnquiry.status) ? <>
											{(() => {
												const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
												let _objCurrentStatus = enquiryAllStatus.filter(e => e.value === selectedEnquiry.status)[0]

												return <div className='link-enquiry-status'>
													{
														_objCurrentStatus
															?
															<>
																<span
																	className={`status-${selectedEnquiry.status === 'open' ? 'open' : 'empty'}`}
																	style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																>
																	{_objCurrentStatus.name}
																</span>
															</>
															:
															<></>
													}
												</div>
											})()}

										</> : <></>}

									</div>
								</>
							) : (
								<>
									<div className='email-link-head'>Link to an Enquiry</div>

									<div className='email-link-buttons'>
										<button
											className='btn btn-sm btn-default'
											onClick={handleAddEnquiryOpen}
										>
											Find an Enquiry
										</button>
									</div>
								</>
							)}
						</div>
					</div >
					)
				}
			</div>

			{/* <Print
						ref={componentRef}
						dealersettings={dealersettings}
						messages={messages}
						selectedThread={selectedThread}
					/> */}

			<PopUpModal show={showContacts}>
				<SelectContact
					show={showContacts}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					handleContactClose={handleContactsClose}
					showAdd={false}
					title={'Link contact to conversation'}
				/>
			</PopUpModal>

			<PopUpModal show={showAddContact}>
				<AddContact
					show={showAddContact}
					handleClose={handleAddContactClose}
					title={'addContact'}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
				/>
			</PopUpModal>

			<PopUpModal show={showAddEnquiry}>
				<SelectEnquiry
					show={showAddEnquiry}
					handleEnquiryClose={handleAddEnquiryClose}
					title={'Link enquiry to your conversation'}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					contactID={selectedCustomer ? selectedCustomer.documentID : 'none'}
					isInEmailEnq={true}
				/>
			</PopUpModal>
		</>
	);
};

export default EmailListBody;
