/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
/** PROVIDERS */
import Translate from '../../../constants/translate';
import { defaultCreditScore } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';
import { ContentOverlay, PopoverListLoader } from '../../../components';
import images, { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';


const CreditScoreHistory = (props) => {
    const [creditScores, setcreditScores] = useState([]);
    const [loading, setLoading] = useState(true);
    const { contact, dealersettings, clientUsers, cardView } = props

    useEffect(() => {
        const fetchData = async () => {

            firestoreDB(dealersettings).firestore().collection(`contactDetails/${contact.documentID}/creditScore`)
                .get()
                .then((querySnapshot) => {
                    let _creditScores = [];
                    querySnapshot.docs.forEach(function (doc) {
                        // for (var i = 0; i < 20; i++) {
                        _creditScores.push(doc.data());
                        // }
                    });
                    setcreditScores(_creditScores)
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                });
        }
        fetchData()
    }, [])

    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative",
        overflowX: 'hidden'
    };

    return (
        <>
            <Modal
                id="credit_score_history"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-creditscore-history">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'Credit Score History'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <div className='w-100' style={divStyle}>
                                    {
                                        loading ? (<PopoverListLoader />) : !_.isEmpty(creditScores)
                                            ?
                                            (<div className='mr-2'>
                                                {
                                                    _.orderBy(creditScores, ["dt"], ["desc"]).map((score, index) => {
                                                        return <div key={index} className="creditscore-history-container-loop">
                                                            {(() => {
                                                                let masterScale = score?.resp?.masterscale
                                                                let _data = masterScale ? defaultCreditScore.filter(e => masterScale >= e.lowScore && masterScale <= e.highScore)[0] : null
                                                                return <div className="creditscore-history-panel-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-3">
                                                                            <div className={`creditscore-hst-loop creditscore-hst-flex creditscore-hst-blue h-100`}>
                                                                                {/* style={{ backgroundColor: _data?.color ? _data.color : '#4466f2' }} */}
                                                                                <div className="creditscore-hst-head">Credit Score</div>
                                                                                <div className="creditscore-hst-value">{score?.resp?.masterscale}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-9 pl-0">
                                                                            <div className="creditscore-info-hst-loop creditscore-info-hst-flex h-100">
                                                                                <div className="row">
                                                                                    <div className="col-lg-2 creditscore-hst-cell">
                                                                                        <div className="creditscore-info-hst-title">Risk Odds </div>
                                                                                        <div className="creditscore-info-hst-value">{score?.resp?.['risk_odds'] || 'n/a'}</div>
                                                                                    </div>
                                                                                    <div className="col-lg-2 creditscore-hst-cell">
                                                                                        <div className="creditscore-info-hst-title">Status </div>
                                                                                        <div className="creditscore-info-hst-value">{_data?.title || 'n/a'} </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 creditscore-hst-cell">
                                                                                        <div className="creditscore-info-hst-title">Date </div>
                                                                                        <div className="creditscore-info-hst-value">{score?.['dt'] ? moment.unix(score['dt'].seconds).format('DD MMM YYYY hh:mm A') : 'n/a'}</div>
                                                                                    </div>

                                                                                    <div className="col-lg-4 creditscore-hst-cell">
                                                                                        <div className="creditscore-info-hst-title">User </div>
                                                                                        <div className="creditscore-info-hst-value"> {score?.['userID'] ? CommonHelper.getUserNamebyId(clientUsers, score?.['userID']) : 'n/a'}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            })()}

                                                        </div>

                                                    })

                                                }
                                            </div>)
                                            :
                                            (<>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <span><img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" width="50" /></span> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'noHistoryFound'} /> </div>
                                                    </div>

                                                </div>
                                            </>)
                                    }

                                </div>
                            </div>
                        </div>



                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreditScoreHistory