/** LIBRARIES */
import React from 'react';
import { Spinner } from 'react-bootstrap'

function LoaderOverlay(props) {
 
    return (

        <div className={`lds-roller-loader text-center ${props.active ? '' : 'hide'}`}>
            <div className="lds-roller-loader-inner loader-primary">
                <Spinner animation="border" role="status">
                </Spinner>
                <p className="text-muted mt-3">{props.text}</p>
            </div>
        </div>
    )
}

export default LoaderOverlay