import React from 'react';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import { widgetSettingsNames } from './viewModel';
import { default as _images } from '../../images';
import Translate from '../../constants/translate';


const WidgetSettings = ({ localSortedWidgets, localHiddenWidgets, removeUnAllowedWidgets, handleAdd, handleRemove, onDragEnd, widgetSettingsNames }) => {
    return (
        <div className='dashsettings-column-wraper'>
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                {
                    <div className='dashsettings-columns'>
                        <Droppable droppableId="add-widget">
                            {(provided, snapshot) => (
                                <div  {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className='dashsettings-column-left' style={{ width: '50%' }}>
                                    <div className='dashsettings-column-title'>
                                        <h3><Translate text={'Add Widgets'} /></h3>
                                    </div>

                                    <div className='dash-add-widget'>
                                        {!_.isEmpty(localHiddenWidgets.filter(a => removeUnAllowedWidgets(a))) ? localHiddenWidgets.filter(a => removeUnAllowedWidgets(a)).map((a, i) =>
                                            <Draggable key={a} draggableId={a} index={i}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='widget-rearrange-box widget-flex-queue widget-inactive'>
                                                        <div className='widget-rearrange-detail'>
                                                            <h3>{widgetSettingsNames.filter(rec => rec.value === a).length ? widgetSettingsNames.filter(rec => rec.value === a)[0].name : a}</h3>
                                                        </div>
                                                        <button
                                                            type='button'
                                                            className='btn btn-primary float-right'
                                                            onClick={() => handleAdd(a)}
                                                        >
                                                            {' '}
                                                            <Translate text={'add'} /><div></div>
                                                            <div></div>
                                                        </button>
                                                        <span
                                                            style={{
                                                                display: "none"
                                                            }}
                                                        >
                                                            {provided.placeholder}
                                                        </span>
                                                    </div>
                                                )}
                                            </Draggable>) : (
                                            <>
                                                <div
                                                    className='spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente'
                                                    style={{ minHeight: '360px' }}
                                                >
                                                    <div className='no-data-flex h-100 justify-content-center align-items-center '>
                                                        <div className='no-data-img'>
                                                            {' '}
                                                            <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
                                                        </div>
                                                        <div className='no-data-txt mt-2'>
                                                            {' '}
                                                            <Translate
                                                                text={'No hidden widgets'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <span
                                        style={{
                                            display: "none"
                                        }}
                                    >
                                        {provided.placeholder}
                                    </span>
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="active-widget">
                            {(provided, snapshot) => (
                                <div  {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className='dashsettings-column-right-large' style={{ width: '50%' }}>
                                    <div className='dashsettings-column-title'>
                                        <h3><Translate text={'Active Widgets'} /></h3>
                                    </div>
                                    <div className='dash-add-widget'>
                                        {!_.isEmpty(localSortedWidgets.filter(a => removeUnAllowedWidgets(a))) ? localSortedWidgets.filter(a => removeUnAllowedWidgets(a)).map((a, i) =>
                                            <Draggable key={a} draggableId={a} index={i}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='widget-rearrange-box widget-flex-queue widget-active'>
                                                        <div className='widget-rearrange-detail'>
                                                            <h3>{widgetSettingsNames.filter(rec => rec.value === a).length ? widgetSettingsNames.filter(rec => rec.value === a)[0].name : a}</h3>
                                                        </div>
                                                        <button
                                                            type='button'
                                                            className='btn btn-common float-right'
                                                            onClick={() => handleRemove(a)}
                                                        >
                                                            {' '}
                                                            <Translate text={'remove'} /><div></div>
                                                            <div></div>
                                                        </button>
                                                        <span
                                                            style={{
                                                                display: "none"
                                                            }}
                                                        >
                                                            {provided.placeholder}
                                                        </span>
                                                    </div>
                                                )}
                                            </Draggable>) : (
                                            <>
                                                <div
                                                    className='spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente'
                                                    style={{ minHeight: '360px' }}
                                                >
                                                    <div className='no-data-flex h-100 justify-content-center align-items-center '>
                                                        <div className='no-data-img'>
                                                            {' '}
                                                            <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
                                                        </div>
                                                        <div className='no-data-txt mt-2'>
                                                            {' '}
                                                            <Translate
                                                                text={'No visible widgets, please add widgets from adjacent list.'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <span
                                        style={{
                                            display: "none"
                                        }}
                                    >
                                        {provided.placeholder}
                                    </span>
                                </div>)}
                        </Droppable>
                    </div>
                }
            </DragDropContext>
        </div>
    )
};

export default WidgetSettings;
