import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { Tabs, Tab, Dropdown } from 'react-bootstrap'
import { ContentOverlay, ImageCropHook, PopUpModal, ReactSelect } from '../../../../components'
import DealerInfo from "./dealerInfo";
import PrivacyTerms from "./privacyTerms";
import AppSettings from "./appSettings";
import EmailSettings from './emailSettings'
import { ClientContext } from '../clientContext'
import { storageBucket, validateEmail } from "../../../../services/helper";
import Translate from '../../../../constants/translate';
import AddLanguage from './addLanguage'
import { CustomMenu, CustomToggle } from '../../../../components/customdropdown';
import InvoiceSettings from "./invoiceSettings";
import CommonHelper from '../../../../services/common';
import AutoEnqUpdate from './autoEnqUpdate';
import StockSettings from './stockSettings';

const DealerSettings = (props) => {
    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            key = "dealerInfo"
        }
        return key
    }
    const [activeTab, setActiveTab] = useState(`${getQueryparam()}`);
    const [dealer, setDealer] = useState(null);
    const [loader, setLoader] = useState(true)
    const [btnLoader, setBtnLoader] = useState(false)
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [errorFields, setErrorFields] = useState({});
    const [imageLoader, setImageLoader] = useState(false)
    const [currencies, setCurrencies] = useState([])
    const [oldDealerdata, setOldDealer] = useState(null);
    const [salesModule, setSalesModule] = useState(null);
    const [serviceModule, setServiceModule] = useState(null);
    const [servicefleetproModule, setServiceFleetProModule] = useState(null);
    const [serviceInvoiceModule, setServiceInvoiceModule] = useState(null);
    const [tradeinProModule, setTradeinProModule] = useState(null);
    const [showAddLanguageModal, setShowAddLanguageModel] = useState({ show: false })
    const [localizeData, setLocalizeData] = useState([])
    const [selectedLocalize, setSelectedLocalize] = useState({})
    const [languageLoader, setLanguageLoader] = useState(false)
    const [selectedClient, setSelectedClient] = useState(props.dealersettings.client.id)
    const [clientLoader, setClientLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.dealersettings))
            return

        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
            .get()
            .then(doc => {
                let _dealer = {};
                if (doc.exists) {
                    _dealer = { ...doc.data(), id: doc.id, documentID: doc.id };
                }
                setSalesModule((_.isEmpty(_dealer)
                    || _.isEmpty(_dealer.moduleSettings)
                    || _.isEmpty(_dealer.moduleSettings.sales)
                    || (_dealer.moduleSettings.sales.enabled === true && _dealer.moduleSettings.sales.active === true)) ? true : false)
                setServiceModule((!_.isEmpty(_dealer)
                    && !_.isEmpty(_dealer.moduleSettings)
                    && !_.isEmpty(_dealer.moduleSettings.service)
                    && _dealer.moduleSettings.service.enabled === true
                    && _dealer.moduleSettings.service.active === true) ? true : false)
                setServiceFleetProModule((!_.isEmpty(_dealer)
                    && !_.isEmpty(_dealer.moduleSettings)
                    && !_.isEmpty(_dealer.moduleSettings.serviceFleetPro)
                    && _dealer.moduleSettings.serviceFleetPro.enabled === true
                    && _dealer.moduleSettings.serviceFleetPro.active === true) ? true : false)
                setServiceInvoiceModule((_dealer?.moduleSettings?.serviceInvoice?.enabled === true
                    && _dealer?.moduleSettings?.serviceInvoice?.active === true) ? true : false)
                setTradeinProModule((!_.isEmpty(_dealer)
                    && !_.isEmpty(_dealer.moduleSettings)
                    && !_.isEmpty(_dealer.moduleSettings.tradeinPro)
                    && _dealer.moduleSettings.tradeinPro.enabled === true
                    && _dealer.moduleSettings.tradeinPro.active === true) ? true : false)
                setDealer({
                    ..._dealer,
                    ['startworkingHours']: _dealer.workingHours && _dealer.workingHours.indexOf(';') > 0 ? window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + _dealer.workingHours.split(';')[0]))._d) : null,
                    ['endworkingHours']: _dealer.workingHours && _dealer.workingHours.indexOf(';') > 0 ? window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + _dealer.workingHours.split(';')[1]))._d) : null
                })
                setLanguageLoader(true);
                window.firebase.firestore().collection(`clients/${props.dealersettings.client.id}/localizeData`).get()
                    .then(data => {
                        setLanguageLoader(false);
                        const _defaultData = {
                            ..._dealer,
                            name: 'Default',
                            code: 'Default',
                            active: true
                        };
                        const _data = []
                        if (data.docs.length > 0) {
                            data.docs.forEach(lang => {
                                _data.push(lang.data())
                            })
                        }
                        setSelectedLocalize(_defaultData)
                        setLocalizeData([
                            _defaultData,
                            ..._.orderBy(_data, 'code', 'asc')
                        ])
                    })
                setOldDealer(_dealer)
                setLoader(false)
            })
    }, [])

    useEffect(() => {
        if (!clientLoader)
            return

        window.firebase.firestore().doc(`clients/${selectedClient}`)
            .get()
            .then(doc => {
                let _dealer = {};
                if (doc.exists) {
                    _dealer = { ...doc.data(), id: doc.id, documentID: doc.id };
                }
                setSalesModule((_.isEmpty(_dealer)
                    || _.isEmpty(_dealer.moduleSettings)
                    || _.isEmpty(_dealer.moduleSettings.sales)
                    || (_dealer.moduleSettings.sales.enabled === true && _dealer.moduleSettings.sales.active === true)) ? true : false)
                setServiceModule((!_.isEmpty(_dealer)
                    && !_.isEmpty(_dealer.moduleSettings)
                    && !_.isEmpty(_dealer.moduleSettings.service)
                    && _dealer.moduleSettings.service.enabled === true
                    && _dealer.moduleSettings.service.active === true) ? true : false)
                setServiceFleetProModule((!_.isEmpty(_dealer)
                    && !_.isEmpty(_dealer.moduleSettings)
                    && !_.isEmpty(_dealer.moduleSettings.serviceFleetPro)
                    && _dealer.moduleSettings.serviceFleetPro.enabled === true
                    && _dealer.moduleSettings.serviceFleetPro.active === true) ? true : false)
                setServiceInvoiceModule((_dealer?.moduleSettings?.serviceInvoice?.enabled === true
                    && _dealer?.moduleSettings?.serviceInvoice?.active === true) ? true : false)
                setTradeinProModule((!_.isEmpty(_dealer)
                    && !_.isEmpty(_dealer.moduleSettings)
                    && !_.isEmpty(_dealer.moduleSettings.tradeinPro)
                    && _dealer.moduleSettings.tradeinPro.enabled === true
                    && _dealer.moduleSettings.tradeinPro.active === true) ? true : false)
                setDealer({
                    ..._dealer,
                    ['startworkingHours']: _dealer.workingHours && _dealer.workingHours.indexOf(';') > 0 ? window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + _dealer.workingHours.split(';')[0]))._d) : null,
                    ['endworkingHours']: _dealer.workingHours && _dealer.workingHours.indexOf(';') > 0 ? window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + _dealer.workingHours.split(';')[1]))._d) : null
                })
                setLanguageLoader(true);
                window.firebase.firestore().collection(`clients/${selectedClient}/localizeData`).get()
                    .then(data => {
                        setLanguageLoader(false);
                        const _defaultData = {
                            ..._dealer,
                            name: 'Default',
                            code: 'Default',
                            active: true
                        };
                        const _data = []
                        if (data.docs.length > 0) {
                            data.docs.forEach(lang => {
                                _data.push(lang.data())
                            })
                        }
                        setSelectedLocalize(_defaultData)
                        setLocalizeData([
                            _defaultData,
                            ..._.orderBy(_data, 'code', 'asc')
                        ])
                    })
                setOldDealer(_dealer)
                setClientLoader(false)
            })
    }, [clientLoader])

    useEffect(() => {
        getCurrencies();
    }, [])

    async function getCurrencies() {
        var baseURL = props.dealersettings.baseURL;
        if (process.env.NODE_ENV === 'development')
            baseURL = 'http://localhost:3000/';
        const response = await fetch(`${baseURL}/lookups/currencies.json`);
        const respJson = await response.json();
        setCurrencies(respJson);
    }

    // useEffect(() => {
    //     if (!_.isEmpty(dealer) && dealer.workingHours.indexOf(';') > 0) {
    //         setDealer({
    //             ...dealer,
    //             ['startworkingHours']: window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + dealer.workingHours.split(';')[0]))._d),
    //             ['endworkingHours']: window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + dealer.workingHours.split(';')[1]))._d)
    //         });

    //     }
    // }, [dealer])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'website' || name === 'facebookURL' || name === 'xURL' || name === 'instagramURL' || name === 'youtubeURL') {
            setDealer({
                ...dealer,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setDealer({
                    ...dealer,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setDealer({
                    ...dealer,
                    [name]: str
                });
            }
        }
    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setDealer({
                ...dealer,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleReactSelectChange = (e, data, type) => {
        if (data.name === 'currency' || data.name === 'vbm') {
            setDealer({
                ...dealer,
                [data.name]: e ? { code: e.data.code, symbol: e.data.symbol } : ''
            });
        }
        else if (data.name === 'taxType') {
            setDealer({
                ...dealer,
                ['clientSettings']: dealer.clientSettings ? {
                    ...dealer.clientSettings,
                    [type]: {
                        ...dealer.clientSettings[type],
                        [data.name]: e ? e.value : ''
                    }
                } : {
                    [type]: {
                        [data.name]: e ? e.value : ''
                    }
                }
            });
        }
        else if (data.name === 'dailyRptType') {
            setDealer({
                ...dealer,
                ['clientSettings']: dealer.clientSettings ? {
                    ...dealer.clientSettings,
                    [data.name]: e ? e.value : ''
                } : {
                    [data.name]: e ? e.value : ''
                }
            });
        }
        else {
            setDealer({
                ...dealer,
                [data.name]: e ? e.value : '',
                ['countryCode']: (data.name === 'country' && e) ? e.value : dealer.countryCode
            });
        }
    }

    const handleReactMultiSelectChange = (selectedOptions, type, name) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        if (name) {
            if (type)
                setDealer({
                    ...dealer,
                    ['clientSettings']: dealer.clientSettings ? {
                        ...dealer.clientSettings,
                        [type]: {
                            ...dealer.clientSettings[type],
                            [name]: value
                        }
                    } : {
                        [type]: {
                            [name]: value
                        }
                    }
                });
            else
                setDealer({
                    ...dealer,
                    ['clientSettings']: dealer.clientSettings ? {
                        ...dealer.clientSettings,
                        [name]: value
                    } : {
                        [name]: value
                    }
                })
        }
        else {
            setDealer({
                ...dealer,
                ['clientBrandIDs']: value
            });
        }

    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        });
    }

    const handleCropSelect = (_cropShow) => {

        const { src, id, type, ext } = _cropShow;
        setDealer({
            ...dealer,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select image file.'), '', 'info');
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire(CommonHelper.showLocale(props, 'Maximum file size exceeded.'), '', 'info');
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })

            }
            reader.readAsDataURL(file)
        }
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {
        let fileName = moment()._d.getTime().toString() + '.' + ext;
        var storageRef = storageBucket(props.dealersettings).ref(`${dealer.documentID}/client/${fileName}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            if (percentage > 0 && percentage < 99) {
                setImageLoader(true)
            }

        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(props.dealersettings).ref(`${dealer.documentID}/client/`).child(fileName).getDownloadURL()
                    .then(dataURL => {
                        setDealer({
                            ...dealer,
                            [id]: dataURL
                        });
                        setImageLoader(false)
                    })
            })
    };

    const bindAddress = (place) => {
        var objDealer = dealer;
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };
        if (place.formatted_address !== null)
            objDealer = {
                ...objDealer,
                ['address']: place.formatted_address
            };
        if (place.geometry && place.geometry.location && place.geometry.location.lng()) {
            objDealer = {
                ...objDealer,
                ['latitude']: place.geometry.location.lat(),
                ['longitude']: place.geometry.location.lng()
            };
        }

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                if (addressType === "administrative_area_level_1")
                    objDealer = {
                        ...objDealer,
                        ['state']: val
                    };

                if (addressType === "country")
                    objDealer = {
                        ...objDealer,
                        ['country']: val,
                        ['countryCode']: val
                    };
            }
        }
        setDealer(objDealer);
    };

    const onEditorStateChange = (obj) => {
        let text = '';
        if (!_.isEmpty(obj) && !_.isEmpty(obj.rawData.blocks)) {
            obj.rawData.blocks.forEach(ele => {
                text += ele.text + ' ';
            });
        }
        if (selectedLocalize?.code === 'Default') {
            setDealer({
                ...dealer,
                [obj.name]: _.isEmpty(text.trim()) ? null : obj.value
            });
        }
        setSelectedLocalize({
            ...selectedLocalize,
            [obj.name]: _.isEmpty(text.trim()) ? null : obj.value
        })
        setLocalizeData(localizeData.map(rec => {
            return rec.code === selectedLocalize.code ? {
                ...rec,
                [obj.name]: _.isEmpty(text.trim()) ? null : obj.value
            } : { ...rec }
        }))
    }

    const saveDealer = (smtpVerified, awsVerified, resetSMTP, resetAWSSES) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(dealer['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (!_.isEmpty(dealer['email']) && !validateEmail(dealer['email'])) {
            formIsValid = false;
            errors['email'] = errorClass;
        }
        if (!_.isEmpty(dealer.clientSettings)
            && !_.isEmpty(dealer.clientSettings.quotation)
            && Boolean(dealer.clientSettings.quotation.enabled)
            && (dealer.clientSettings.quotation.tax === null ||
                dealer.clientSettings.quotation.tax === undefined ||
                dealer.clientSettings.quotation.tax === '')) {
            formIsValid = false;
            errors['quotation-tax'] = errorClass;
        }
        if (!_.isEmpty(dealer.clientSettings)
            && !_.isEmpty(dealer.clientSettings.quotation)
            && Boolean(dealer.clientSettings.quotation.enabled)
            && (dealer.clientSettings.quotation.taxType === null ||
                dealer.clientSettings.quotation.taxType === undefined ||
                dealer.clientSettings.quotation.taxType === '')) {
            formIsValid = false;
            errors['quotation-taxType'] = errorClass;
        }
        if (!_.isEmpty(dealer.clientSettings)
            && !_.isEmpty(dealer.clientSettings.smtpFrom)
            && !validateEmail(dealer.clientSettings.smtpFrom)) {
            formIsValid = false;
            errors['smtpFrom'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setBtnLoader(true)
        if (!_.isEmpty(dealer.startworkingHours) && !_.isEmpty(dealer.endworkingHours))
            dealer.workingHours = moment.unix(dealer.startworkingHours.seconds).format('HH:mm:ss') + ';' + moment.unix(dealer.endworkingHours.seconds).format('HH:mm:ss')
        delete dealer.startworkingHours;
        delete dealer.endworkingHours;
        dealer.modifiedDate = window.firebase.firestore.Timestamp.now();
        dealer.modifiedBy = localStorage.uid

        if (!_.isEmpty(oldDealerdata.clientSettings) && (oldDealerdata.clientSettings.smtpFrom !== dealer.clientSettings.smtpFrom || oldDealerdata.clientSettings.smtpUri !== dealer.clientSettings.smtpUri)) {
            dealer.clientSettings.smtpVerified = false
        }


        if (!_.isEmpty(oldDealerdata.clientSettings) && !_.isEmpty(oldDealerdata.clientSettings.awsses) && (oldDealerdata.clientSettings.awsses.email !== dealer.clientSettings.awsses.email)) {
            dealer.clientSettings.awsses.pending = false
            dealer.clientSettings.awsses.verified = false
        }

        if (smtpVerified) {
            dealer.clientSettings.smtpVerified = true
        }

        if (awsVerified) {
            dealer.clientSettings.awsses.pending = true
        }

        if (resetSMTP) {
            dealer.clientSettings.smtpVerified = false
            dealer.clientSettings.smtpFrom = ''
            dealer.clientSettings.smtpUri = ''
        }

        if (resetAWSSES) {
            dealer.clientSettings.awsses = {}
        }

        if (!_.isEmpty(dealer.clientSettings)
            && !_.isEmpty(dealer.clientSettings.tradeinPro)
            && dealer.clientSettings.tradeinPro.mandImages > 0
            && dealer.clientSettings.tradeinPro.mandSidePhotos
            && dealer.clientSettings.tradeinPro.mandImages < dealer.clientSettings.tradeinPro.mandSidePhotos.length) {
            dealer.clientSettings.tradeinPro.mandImages = dealer.clientSettings.tradeinPro.mandSidePhotos.length
        }

        if (!_.isEmpty(dealer.clientSettings)
            && !_.isEmpty(dealer.clientSettings.salesTradein)
            && dealer.clientSettings.salesTradein.mandImages > 0
            && dealer.clientSettings.salesTradein.mandSidePhotos
            && dealer.clientSettings.salesTradein.mandImages < dealer.clientSettings.salesTradein.mandSidePhotos.length) {
            dealer.clientSettings.salesTradein.mandImages = dealer.clientSettings.salesTradein.mandSidePhotos.length
        }

        window.firebase.firestore().doc(`clients/${dealer.documentID}`).set(dealer, { merge: true })
            .then(snapshot => {
                setBtnLoader(false)
                toast.notify('Client updated successfully', {
                    duration: 2000
                })
                setDealer({
                    ...dealer,
                    ['startworkingHours']: dealer.workingHours && dealer.workingHours.indexOf(';') > 0 ? window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + dealer.workingHours.split(';')[0]))._d) : null,
                    ['endworkingHours']: dealer.workingHours && dealer.workingHours.indexOf(';') > 0 ? window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + dealer.workingHours.split(';')[1]))._d) : null
                })
                UpdateClienttoFinance(dealer)
                //if (JSON.stringify(oldDealerdata.clientSettings) !== JSON.stringify(dealer.clientSettings)) {
                if (activeTab !== 'stocksettings' && activeTab !== 'autoupdatesettings') {
                    window.firebase.firestore().doc(`clients/${selectedClient}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Client Settings has been updated',
                        }, { merge: true })
                }
                //}
                if (localizeData.filter(a => a.code !== 'Default').length > 0) {
                    localizeData.filter(a => a.code !== 'Default').forEach(localize => {
                        window.firebase.firestore().doc(`clients/${dealer.documentID}/localizeData/${localize.code}`).set({
                            ...localize,
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                            active: window.firebase.firestore.FieldValue.delete(),
                        }, { merge: true });
                    })
                }
            })
            .catch(error => {
                setBtnLoader(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const UpdateClienttoFinance = (client) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    DealerId: client.documentID,
                    Name: client.name,
                    Url: props.dealersettings.apiURL,
                    Phone: client.phone,
                    Email: client.email,
                    WebSite: client.website,
                    Address: client.address
                })
            };
            fetch(props.dealersettings.financeURL + 'api/AMSProFinance/Action/SaveDealerInfo', requestOptions)
                .catch(error => {
                    console.log(error)
                })
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleHoursChange = (val, name) => {
        setDealer({
            ...dealer,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });
    }
    const handleSettingsHoursChange = (val, name) => {
        setDealer({
            ...dealer,
            ['clientSettings']: {
                ...dealer.clientSettings,
                [name]: moment(val).format('HH:mm:ss')
            }
        });
    }
    const handleOnChangeMultipleText = (val, name) => {
        setDealer({
            ...dealer,
            ['clientSettings']: {
                ...dealer.clientSettings,
                [name]: !_.isEmpty(val) ? val.join(',') : ''
            }
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeEmail = (index, name) => {
        let arr = dealer.clientSettings[name] ? dealer.clientSettings[name].split(',') : []
        arr.splice(index, 1)
        setDealer({
            ...dealer,
            ['clientSettings']: {
                ...dealer.clientSettings,
                [name]: arr.join(',')
            }
        })
    }


    const handleCheckChange = (e) => {
        setDealer({
            ...dealer,
            ['clientSettings']: {
                ...dealer.clientSettings,
                [e.target.name]: e.target.checked
            }
        })
    }

    const handleQuoteCheckChange = (e, type) => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                [type]: {
                    ...dealer.clientSettings[type],
                    [e.target.name]: e.target.checked
                }
            } : {
                [type]: {
                    [e.target.name]: e.target.checked
                }
            }
        })
    }

    const handleQuoteNumberChange = (e, type) => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setDealer({
                ...dealer,
                ['clientSettings']: dealer.clientSettings ? {
                    ...dealer.clientSettings,
                    [type]: {
                        ...dealer.clientSettings[type],
                        [e.target.name]: number !== '' ? parseInt(number) : number
                    }
                } : {
                    [type]: {
                        [e.target.name]: number !== '' ? parseInt(number) : number
                    }
                }
            });
        }
    };

    const handleCurrencyChange = (e, name, type) => {
        let number = e.floatValue;
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                [type]: {
                    ...dealer.clientSettings[type],
                    [name]: number
                }
            } : {
                [type]: {
                    [name]: number
                }
            }
        });

    };

    const handleTextChange = (e, type) => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                [type]: {
                    ...dealer.clientSettings[type],
                    [e.target.name]: e.target.value
                }
            } : {
                [type]: {
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    const handleServiceNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setDealer({
                ...dealer,
                ['clientSettings']: dealer.clientSettings ? {
                    ...dealer.clientSettings,
                    'service': {
                        ...dealer.clientSettings.service,
                        [e.target.name]: number !== '' ? parseInt(number) : number
                    }
                } : {
                    'service': {
                        [e.target.name]: number !== '' ? parseInt(number) : number
                    }
                }
            });
        }
    };
    const handleSMTPOnChange = (e) => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                [e.target.name]: e.target.value
            } : {
                [e.target.name]: e.target.value
            }
        });
    }

    const handleAWSSESOnChange = e => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                'awsses': {
                    ...dealer.clientSettings.awsses,
                    [e.target.name]: e.target.value
                }
            } : {
                'awsses': {
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    const handleMailgunOnChange = e => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                'mailgunconfig': {
                    ...dealer.clientSettings.mailgunconfig,
                    [e.target.name]: e.target.value
                }
            } : {
                'mailgunconfig': {
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    const handleLanguageChange = (_data) => {
        if (!_.isEmpty(_data)) {
            setLocalizeData([..._.forEach(localizeData, function (obj) {
                if (obj.code === _data.code)
                    _.set(obj, 'active', true);
                else
                    _.set(obj, 'active', false);
            })]);
            setSelectedLocalize(_data);
        }
    }
    const handledeleteLanguage = (_data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`clients/${dealer.id}/localizeData/${_data.code}`).delete()
                    .then(snapshot => {
                        setLocalizeData(localizeData.filter(a => a.code !== _data.code).map(rec => {
                            return {
                                ...rec,
                                active: rec.code === 'Default'
                            }
                        }))
                        toast.notify('Language deleted successfully', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const handleDynChange = (e, _name) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'website' || name === 'remittanceTo') {
            setDealer({
                ...dealer,
                [_name]: dealer[_name] ? {
                    ...dealer[_name],
                    [name]: value
                } : {
                    [_name]: {
                        [name]: value
                    }
                }
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setDealer({
                    ...dealer,
                    [_name]: dealer[_name] ? {
                        ...dealer[_name],
                        [name]: newarray1.join(' ')
                    } : {
                        [_name]: {
                            [name]: newarray1.join(' ')
                        }
                    }
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setDealer({
                    ...dealer,
                    [_name]: dealer[_name] ? {
                        ...dealer[_name],
                        [name]: str
                    } : {
                        [_name]: {
                            [name]: str
                        }
                    }
                });
            }
        }
    }

    const handleDynSelectChange = (name, value) => {
        setDealer({
            ...dealer,
            [name]: value
        })
    }

    const handleDynObjChange = (obj, type, name) => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                [type]: {
                    ...dealer.clientSettings[type],
                    [name]: obj
                }
            } : {
                [type]: {
                    [name]: obj
                }
            }
        });
    };

    return (
        <>
            <h2><Translate text={'Dealer Settings'} /></h2>
            <div className="divider-line"></div>
            {
                Boolean(props.dealersettings.superAdmin) ? (
                    <div className='settings-head form-style mt-3'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <ReactSelect
                                            options={props?.dealersettings?.clients?.map(r => {
                                                return {
                                                    label: r.name,
                                                    value: r.id
                                                }
                                            }) || []}
                                            name={"level"}
                                            placeholder={"select level"}
                                            onChange={(e, data) => {
                                                setClientLoader(true);
                                                setSelectedClient(e?.value || null);
                                            }}
                                            value={selectedClient}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        ></ReactSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (<></>)
            }
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    {
                        (loader || clientLoader || _.isEmpty(dealer)) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                            (<>
                                <nav>
                                    <Tabs defaultActiveKey={`${getQueryparam()}`} transition={false} className="nav-fill" onSelect={(key) => {
                                        //console.log('onSelect', key)
                                        setActiveTab(key)
                                    }}>
                                        <Tab eventKey="dealerInfo" title={CommonHelper.showLocale(props, "dealerInfo")}>
                                            <DealerInfo
                                                dealer={dealer}
                                                onSelectFile={onSelectFile}
                                                handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                handleReactSelectChange={handleReactSelectChange}
                                                handleNumberChange={handleNumberChange}
                                                handleOnChange={handleOnChange}
                                                bindAddress={bindAddress}
                                                deleteLogo={() => {
                                                    setDealer({
                                                        ...dealer,
                                                        'logoURL': ''
                                                    })
                                                }}
                                                countries={props.dealersettings.countries}
                                                states={props.dealersettings.states}
                                                errorFields={errorFields}
                                                imageLoader={imageLoader}
                                                handleHoursChange={handleHoursChange}
                                                handleDynSelectChange={handleDynSelectChange}
                                            />
                                        </Tab>
                                        <Tab eventKey="privacyTerms" title={CommonHelper.showLocale(props, "Privacy Terms")}>
                                            <button type="button" className="btn btn-primary float-right mb-3" onClick={() => saveDealer()}>
                                                {
                                                    btnLoader ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                <Translate text={'save'} />
                                            </button>
                                            <div className="settings-column-wraper w-100">
                                                <div className="settings-columns">
                                                    <div className="settings-column-left" style={{ width: `300px` }}>
                                                        <div className="settings-column-title">
                                                            <div className="column-add-button"><a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setShowAddLanguageModel({ show: true, title: 'Add Language' }); }}> <i className="ico icon-add"></i></a></div>
                                                            <h3><Translate text={'Languages'} /></h3>
                                                        </div>
                                                        <div className="settings-column-nav">
                                                            {
                                                                languageLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                                                    <ul>
                                                                        {
                                                                            localizeData.map((rec, index) => {
                                                                                return <li key={index} className={`${rec.active === true ? 'active' : ''}`}>
                                                                                    {
                                                                                        rec.code === 'Default' ? (
                                                                                            <div className="role-list-items-lock"> <i className="ico icon-lock"></i> </div>
                                                                                        ) : (

                                                                                            <Dropdown className="role-list-items-more" alignRight>
                                                                                                <Dropdown.Toggle as={CustomToggle} className="dropdown role-list-items-more-fix">
                                                                                                    <i className="ico icon-more"></i>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                                                    <Dropdown.Item eventKey="delete" onClick={(e) => { e.preventDefault(); setShowAddLanguageModel({ show: true, title: 'Edit Language', language: rec }); }}><i className="ico icon-edit"></i>  <Translate text={'edit'} /></Dropdown.Item>
                                                                                                    <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteLanguage(rec) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        )
                                                                                    }
                                                                                    <a href="#" className="column-list-left"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleLanguageChange(rec);
                                                                                        }}
                                                                                    ><Translate text={rec.name} />
                                                                                    </a>
                                                                                </li>
                                                                            })
                                                                        }


                                                                    </ul>)
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="settings-column-right-large">
                                                        <PrivacyTerms
                                                            dealer={selectedLocalize}
                                                            onEditorStateChange={onEditorStateChange}
                                                            sales={salesModule}
                                                            service={serviceModule}
                                                            servicefleetproModule={servicefleetproModule}
                                                            dealersettings={props.dealersettings}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="email" title={CommonHelper.showLocale(props, "Email SMTP")}>
                                            <EmailSettings
                                                dealer={dealer}
                                                dealersettings={props.dealersettings}
                                                setDealer={setDealer}
                                                errorFields={errorFields}
                                                handleSMTPOnChange={handleSMTPOnChange}
                                                handleAWSSESOnChange={handleAWSSESOnChange}
                                                handleMailgunOnChange={handleMailgunOnChange}
                                                saveDealer={saveDealer}
                                                validateEmail={validateEmail}
                                            />
                                        </Tab>
                                        {
                                            salesModule ? (
                                                <Tab eventKey="settings" title={CommonHelper.showLocale(props, "settings")}>
                                                    <AppSettings
                                                        tabKey={'settings'}
                                                        dealer={dealer}
                                                        currencies={currencies}
                                                        handleReactSelectChange={handleReactSelectChange}
                                                        handleNumberChange={handleNumberChange}
                                                        handleOnChange={handleOnChange}
                                                        handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                        removeEmail={removeEmail}
                                                        handleCheckChange={handleCheckChange}
                                                        handleSettingsHoursChange={handleSettingsHoursChange}
                                                        errorFields={errorFields}
                                                        handleQuoteNumberChange={handleQuoteNumberChange}
                                                        handleCurrencyChange={handleCurrencyChange}
                                                        sales={true}
                                                        service={false}
                                                        tradeinPro={false}
                                                        handleQuoteCheckChange={handleQuoteCheckChange}
                                                        saveDealer={saveDealer}
                                                        btnLoader={btnLoader}
                                                        handleTextChange={handleTextChange}
                                                        handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                        dealersettings={props.dealersettings}
                                                        handleDynSelectChange={handleDynSelectChange}
                                                    />
                                                </Tab>
                                            ) : (<></>)
                                        }
                                        {
                                            serviceModule ? (
                                                <Tab eventKey="servicesettings" title={CommonHelper.showLocale(props, "Service Settings")}>
                                                    <AppSettings
                                                        tabKey={'servicesettings'}
                                                        dealer={dealer}
                                                        currencies={currencies}
                                                        handleReactSelectChange={handleReactSelectChange}
                                                        handleNumberChange={handleNumberChange}
                                                        handleOnChange={handleOnChange}
                                                        handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                        removeEmail={removeEmail}
                                                        handleCheckChange={handleCheckChange}
                                                        handleSettingsHoursChange={handleSettingsHoursChange}
                                                        errorFields={errorFields}
                                                        handleQuoteNumberChange={handleQuoteNumberChange}
                                                        handleCurrencyChange={handleCurrencyChange}
                                                        sales={false}
                                                        service={true}
                                                        tradeinPro={false}
                                                        handleQuoteCheckChange={handleQuoteCheckChange}
                                                        saveDealer={saveDealer}
                                                        btnLoader={btnLoader}
                                                        handleTextChange={handleTextChange}
                                                        handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                        dealersettings={props.dealersettings}
                                                    />
                                                </Tab>
                                            ) : (<></>)
                                        }
                                        {
                                            serviceModule && serviceInvoiceModule ? (
                                                <Tab eventKey="invoicesettings" title={CommonHelper.showLocale(props, "Service Invoice")}>
                                                    <InvoiceSettings
                                                        tabKey={'invoicesettings'}
                                                        dealer={dealer}
                                                        currencies={currencies}
                                                        handleReactSelectChange={handleReactSelectChange}
                                                        handleNumberChange={handleNumberChange}
                                                        handleDynChange={handleDynChange}
                                                        handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                        removeEmail={removeEmail}
                                                        handleCheckChange={handleCheckChange}
                                                        handleSettingsHoursChange={handleSettingsHoursChange}
                                                        errorFields={errorFields}
                                                        handleQuoteNumberChange={handleQuoteNumberChange}
                                                        handleCurrencyChange={handleCurrencyChange}
                                                        sales={false}
                                                        service={true}
                                                        tradeinPro={false}
                                                        handleQuoteCheckChange={handleQuoteCheckChange}
                                                        saveDealer={saveDealer}
                                                        btnLoader={btnLoader}
                                                        handleTextChange={handleTextChange}
                                                        handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                        dealersettings={props.dealersettings}
                                                        onSelectFile={onSelectFile}
                                                        deleteLogo={() => {
                                                            setDealer({
                                                                ...dealer,
                                                                'servicelogoURL': ''
                                                            })
                                                        }}
                                                        imageLoader={imageLoader}
                                                    />
                                                </Tab>
                                            ) : (<></>)
                                        }
                                        {
                                            tradeinProModule ? (
                                                <Tab eventKey="tradeinProsettings" title={CommonHelper.showLocale(props, "Tradein Pro Settings")}>
                                                    <AppSettings
                                                        tabKey={'tradeinProsettings'}
                                                        dealer={dealer}
                                                        currencies={currencies}
                                                        handleReactSelectChange={handleReactSelectChange}
                                                        handleNumberChange={handleNumberChange}
                                                        handleOnChange={handleOnChange}
                                                        handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                        removeEmail={removeEmail}
                                                        handleCheckChange={handleCheckChange}
                                                        handleSettingsHoursChange={handleSettingsHoursChange}
                                                        errorFields={errorFields}
                                                        handleQuoteNumberChange={handleQuoteNumberChange}
                                                        handleCurrencyChange={handleCurrencyChange}
                                                        sales={false}
                                                        service={false}
                                                        tradeinPro={true}
                                                        handleQuoteCheckChange={handleQuoteCheckChange}
                                                        saveDealer={saveDealer}
                                                        btnLoader={btnLoader}
                                                        handleTextChange={handleTextChange}
                                                        handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                        dealersettings={props.dealersettings}
                                                    />
                                                </Tab>
                                            ) : (<></>)
                                        }
                                        {
                                            Boolean(props.dealersettings.superAdmin) ? (
                                                <Tab eventKey="autoupdatesettings" title={CommonHelper.showLocale(props, "Auto Update Settings")}>
                                                    <AutoEnqUpdate
                                                        tabKey={'autoupdatesettings'}
                                                        dealer={dealer}
                                                        currencies={currencies}
                                                        handleReactSelectChange={handleReactSelectChange}
                                                        handleNumberChange={handleNumberChange}
                                                        handleOnChange={handleOnChange}
                                                        handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                        removeEmail={removeEmail}
                                                        handleCheckChange={handleCheckChange}
                                                        handleSettingsHoursChange={handleSettingsHoursChange}
                                                        errorFields={errorFields}
                                                        handleQuoteNumberChange={handleQuoteNumberChange}
                                                        handleCurrencyChange={handleCurrencyChange}
                                                        sales={false}
                                                        service={false}
                                                        tradeinPro={true}
                                                        handleQuoteCheckChange={handleQuoteCheckChange}
                                                        saveDealer={saveDealer}
                                                        btnLoader={btnLoader}
                                                        handleTextChange={handleTextChange}
                                                        handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                        dealersettings={props.dealersettings}
                                                        handleDynObjChange={handleDynObjChange}
                                                    />
                                                </Tab>

                                            ) : (<></>)
                                        }
                                        {
                                            Boolean(props.dealersettings.superAdmin) ? (
                                                <Tab eventKey="stocksettings" title={CommonHelper.showLocale(props, "Stock Settings")}>
                                                    <StockSettings
                                                        tabKey={'stocksettings'}
                                                        dealer={dealer}
                                                        currencies={currencies}
                                                        handleReactSelectChange={handleReactSelectChange}
                                                        handleNumberChange={handleNumberChange}
                                                        handleOnChange={handleOnChange}
                                                        handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                        removeEmail={removeEmail}
                                                        handleCheckChange={handleCheckChange}
                                                        handleSettingsHoursChange={handleSettingsHoursChange}
                                                        errorFields={errorFields}
                                                        handleQuoteNumberChange={handleQuoteNumberChange}
                                                        handleCurrencyChange={handleCurrencyChange}
                                                        sales={false}
                                                        service={false}
                                                        tradeinPro={true}
                                                        handleQuoteCheckChange={handleQuoteCheckChange}
                                                        saveDealer={saveDealer}
                                                        btnLoader={btnLoader}
                                                        handleTextChange={handleTextChange}
                                                        handleReactMultiSelectChange={handleReactMultiSelectChange}
                                                        dealersettings={props.dealersettings}
                                                        handleDynObjChange={handleDynObjChange}
                                                    />
                                                </Tab>

                                            ) : (<></>)
                                        }
                                    </Tabs>
                                </nav>
                                {(activeTab === 'email' || activeTab === 'servicesettings' || activeTab === 'tradeinProsettings' || activeTab === 'privacyTerms') ? (<></>) : (<>
                                    <div className="settings-footer mt-3">
                                        {/* <button type="button" className="btn btn-default float-left" onClick={sendPushNotification}>
                                            {
                                                notifyloader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            <Translate text={'Push notification'}/>
                                        </button> */}
                                        <button type="button" className={`btn btn-primary float-${activeTab === 'settings' ? 'left' : 'right'}`} onClick={() => saveDealer()}>
                                            {
                                                btnLoader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            <Translate text={'save'} />
                                        </button>
                                    </div>
                                </>)}
                            </>)
                    }
                </div>
            </div>
            <ImageCropHook
                cropShow={cropShow}
                handleClose={handleCropClose}
                handleSelect={handleCropSelect}
            ></ImageCropHook>
            <PopUpModal show={showAddLanguageModal.show}>
                <AddLanguage
                    {...props}
                    {...showAddLanguageModal}
                    handleClose={(data, deleteData) => {
                        setShowAddLanguageModel({
                            show: false
                        })
                        if (data) {
                            setLocalizeData(localizeData.some(a => a.code === data.code) ? [
                                ...localizeData.filter(a => deleteData ? a.code !== deleteData : true).map(rec => { return rec.code === data.code ? { ...rec, active: true } : { ...rec, active: false } }),
                            ] : [
                                ...localizeData.filter(a => (deleteData ? a.code !== deleteData : true) && a.code !== data.code).map(rec => { return { ...rec, active: false } }),
                                { ...data, active: true }
                            ])
                            setSelectedLocalize(data);
                        }
                    }}
                    dealersettings={props.dealersettings}
                    dealer={dealer}
                    path={`clients/${selectedClient}/localizeData`}
                />
            </PopUpModal>
        </>
    )
};
export default DealerSettings;