/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash';
/** PROVIDERS */
import { LayoutConsumer } from '../../layout/provider';
import { RouteConsumer } from '../../layout/RouteProvider';
import EnquiryInfo from './index';
/** COMPONENTS */
import { SidePanelDetailView } from '../../../components'

class QuickInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }



    render() {
        //console.log('RE RENDER QUICK VIEW')
        const { isReadOnlyView, isHideExpandView, isFromLayout, parentClose,
            enquiryid, clsActive, sidepanelClose, disableRoutes,
            isFilterApplied, updateEnquiry, reloadData
        } = this.props;
        return (<>

            <SidePanelDetailView
                clsActive={clsActive}
                clsName={'sidebar-quickview-detail'}
                sidepanelClose={sidepanelClose}
                title=''
            >
                {
                    enquiryid
                        ?
                        <>
                            <LayoutConsumer>
                                {({ dealersettings, clientUsers, groupUsers, clientModule, handleClientChange, handleModuleChange, dialNumber, dialRingcentral, updateDealerSettings, history }) => (
                                    <RouteConsumer>
                                        {({ handleRouteChange, resetnavbar, updatenavbar, handleRouteRemove }) => (
                                            <>
                                                <EnquiryInfo
                                                    enquiryid={enquiryid}
                                                    dealersettings={dealersettings}
                                                    clientUsers={clientUsers}
                                                    groupUsers={groupUsers}
                                                    resetnavbar={resetnavbar}
                                                    history={history}
                                                    updatenavbar={updatenavbar}
                                                    handleRouteRemove={handleRouteRemove}
                                                    updateDealerSettings={updateDealerSettings}
                                                    clientModule={clientModule}
                                                    dialNumber={dialNumber}
                                                    dialRingcentral={dialRingcentral}
                                                    handleRouteChange={handleRouteChange}
                                                    //isFromLayout={disableRoutes}
                                                    disableRoutes={true}
                                                    modalClose={sidepanelClose}
                                                    isQuickLayout={true}
                                                    showExpand={isHideExpandView ? false : true}
                                                    isReadonlyLayout={isReadOnlyView}
                                                    isFilterApplied={isFilterApplied}
                                                    updateEnquiry={updateEnquiry}
                                                    reloadData={reloadData}
                                                />
                                            </>
                                        )}
                                    </RouteConsumer>
                                )}
                            </LayoutConsumer>
                        </>
                        :
                        <></>
                }
            </SidePanelDetailView>


        </>);
    }
}

export default QuickInfo;