/** LIBRARIES */
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import _ from 'lodash'
/** COMPONENTS */
import { SectionOverlay } from '../../components'
import Translate from '../../constants/translate';
import EnquiryStages from './enquirystages'

function EnquiryList(props) {

    const [state, setState] = useState(props.enquiries);
    const [columns, setColumns] = useState(props.columns);
    const [pipeline, setPipeline] = useState(props.pipeline);
    const [panelWidth, setPanelWidth] = useState('100%');
    const [windowWidth, setWindowwidth] = useState(window.outerWidth);
    const [origins, setOrigins] = useState([])
    const [financeStatuses, setFinanceStatuses] = useState([])
    useEffect(() => {
        setState(props.enquiries);
        if (props.columns.length > 20 && window.outerWidth > 1500) {
            document.documentElement.style.setProperty('--boardwidth', (((window.innerWidth - 30) / 6) - 15) + 'px')
            setPanelWidth((((((window.innerWidth - 30) / 6)) * props.columns.length) + 120) + 'px')
        } else if (props.columns.length > 6 && window.outerWidth > 1500) {
            document.documentElement.style.setProperty('--boardwidth', (((window.innerWidth - 30) / 6) - 15) + 'px')
            setPanelWidth((((((window.innerWidth - 30) / 6)) * props.columns.length) + 80) + 'px')
        }
        else if (window.outerWidth < 1500 && props.columns.length > 5) {
            if (window.outerWidth < 1100) {
                document.documentElement.style.setProperty('--boardwidth', '250px')
                setPanelWidth((270 * props.columns.length) + 'px')
            }
            else {
                document.documentElement.style.setProperty('--boardwidth', (((window.innerWidth - 30) / 5) - 15) + 'px')
                setPanelWidth((((((window.innerWidth - 30) / 5)) * props.columns.length) + 80) + 'px')
            }
        }
        else if (window.outerWidth < 1100) {
            document.documentElement.style.setProperty('--boardwidth', '250px')
            setPanelWidth((270 * props.columns.length) + 'px')
        }
        else {
            document.documentElement.style.setProperty('--boardwidth', ((100 / props.columns.length) - 1.5).toFixed(2) + '%')
            setPanelWidth('100%')
        }

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            let _origins = [];
            const setttings = props.dealersettings.client.settings;
            setttings.origins && setttings.origins.forEach(doc => {
                _origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                _financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty ml-1`} style={{ background: (doc.color ? doc.color : '#6b717f') }}><i className='ico icon-finance mr-1'></i>{doc.name}</div>,
                });
            });
            setOrigins(_origins);
            setFinanceStatuses(_financeStatuses)
        }
    }, [props, windowWidth])

    useEffect(() => {
        function handleResize() {
            handlePanleheight();
            setWindowwidth(window.outerWidth)
        }
        window.addEventListener('resize', handleResize);
        handlePanleheight();
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    function handlePanleheight() {
        var panelHeight = (window.innerHeight - 125)
        var pipelineItems = []
        try {
            if (document?.getElementsByClassName('pipeline-board')?.length) {
                document.getElementsByClassName('pipeline-board').forEach(rec => {
                    pipelineItems.push(rec?.children?.[1]?.children?.length || 0)
                })
            } else {
                props.columns.forEach((r) => {
                    if (state.columns[pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value + r])
                        pipelineItems.push(state.columns[pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value + r].taskIds.length)
                })
            }
        } catch (err) {
            props.columns.forEach((r) => {
                if (state.columns[pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value + r])
                    pipelineItems.push(state.columns[pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value + r].taskIds.length)
            })
        }


        const enquiryOptionsDF = props.dealersettings.client.settings.enquiryOptionsDF;
        const enquiryOptionsStatic = props.dealersettings.client.settings.enquiryOptionsStatic;
        let _pipeline = _.map(state.tasks, function (item) { return item; })[0]?.pipeline;
        let enqLengthDF = (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active && m.showinPipeline && _pipeline && m.showinPipeline.includes(_pipeline)))) ?
            enquiryOptionsDF.filter(m => m.active && m.showinPipeline && _pipeline && m.showinPipeline.includes(_pipeline)).length : 0;
        let enqLengthStatic = (!_.isEmpty(enquiryOptionsStatic) && !_.isEmpty(enquiryOptionsStatic.filter(m => m.active && m.showinPipeline && _pipeline && m.showinPipeline.includes(_pipeline)))) ?
            enquiryOptionsStatic.filter(m => m.active && m.showinPipeline && _pipeline && m.showinPipeline.includes(_pipeline)).length : 0;
        var itemHeight = (220 + (enqLengthDF > 1 ? enqLengthDF * 9 : 0) + (enqLengthStatic > 1 ? enqLengthStatic * 9 : 0))

        var _h = (Math.max(...pipelineItems) * itemHeight) + 30;
        //console.log(pipelineItems)
        _h = _h > (panelHeight - 72) ? (_h + 72) : panelHeight;
        var boardHeight = _h;
        var pipelineHeight = (_h - 72);

        document.documentElement.style.setProperty('--panelHeight', panelHeight + 'px');
        document.documentElement.style.setProperty('--boardheight', boardHeight + 'px');
        document.documentElement.style.setProperty('--pipelineHeight', pipelineHeight + 'px');
    }

    const handleDragStart = useCallback(
        start => {

            setState({
                ...state,
                homeIndex: columns.indexOf(start.source.droppableId)
            });
        },
        [state]
    );

    const handleDragUpdate = useCallback(
        update => {
            const opacity = update.destination
                ? update.destination.index / Object.keys(state.tasks).length
                : 0;


        },
        [state]
    );

    const handleDragEnd = useCallback(
        result => {


            setState({
                ...state,
                homeIndex: null
            });

            if (!result.destination) {
                return;
            }

            if (
                result.destination.droppableId === result.source.droppableId
            ) {
                return;
            }
            const _dpl = pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value;
            const start = state.columns[_dpl + result.source.droppableId];
            const finish = state.columns[_dpl + result.destination.droppableId];

            if (start === finish) {
                const newTaskIds = Array.from(start.taskIds);
                newTaskIds.splice(result.source.index, 1);
                newTaskIds.splice(result.destination.index, 0, result.draggableId);

                const newColumn = {
                    ...start,
                    taskIds: newTaskIds
                };

                setState({
                    ...state,
                    columns: {
                        ...state.columns,
                        [_dpl + newColumn.id]: newColumn
                    }
                });

                return;
            }

            const startTaskIds = Array.from(start.taskIds);
            startTaskIds.splice(result.source.index, 1);

            const newStart = {
                ...start,
                taskIds: startTaskIds
            };

            const finishTaskIds = Array.from(finish.taskIds);
            finishTaskIds.splice(0, 0, result.draggableId);
            const newFinish = {
                ...finish,
                taskIds: finishTaskIds
            };

            setState({
                ...state,
                columns: {
                    ...state.columns,
                    [_dpl + newStart.id]: newStart,
                    [_dpl + newFinish.id]: newFinish
                }
            });

            props.handleEnquiryFav(result.draggableId, state.tasks[result.draggableId].isFav, result.destination.droppableId, result.source.droppableId, state.tasks[result.draggableId])
        },
        [state]
    );
    return (
        <div style={{ position: "relative" }}>
            <SectionOverlay active={props.sectionloading} />
            <div className="pipeline-panel" >{/*onScroll={props.listenScrollEvent}> */}
                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragUpdate={handleDragUpdate}
                    onDragEnd={handleDragEnd}
                >
                    {
                        <div style={{ width: `${panelWidth}` }}>
                            {pipeline.filter(a => (props.pipelineView && props.pipelineView === 'INBOUND') ? a.value === 'LeadsBucket' : (a.value !== 'LeadsBucket' && a.default === true))[0].stages.map((columnId, index) => {
                                const _dpl = pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value;
                                const column = state.columns[_dpl + columnId.value];
                                const tasks = column.taskIds.map(taskId => state.tasks[taskId]);

                                // THAHA CODE FOR OEM
                                return <EnquiryStages
                                    key={index}
                                    column={column}
                                    tasks={localStorage.defaultModule === 'oem' && columnId.value === 'Converted' ?
                                        (_.map(state.tasks, function (item) { return item; }).filter(item =>
                                            (props.dealersettings.level === 'oem' && item.oemStatus === 'converted' && item.clientID) ||
                                            (props.dealersettings.level === 'region' && item.oemStatus === 'converted' && item.clientID) ||
                                            (props.dealersettings.level === 'group' && item.oemStatus === 'converted' && item.clientID)
                                        ).sort(function (x, y) {
                                            return props.dealersettings.level === 'oem' ? (x.stageDate.seconds > y.stageDate.seconds ? -1 : 1) : (x.modifiedDate.seconds > y.modifiedDate.seconds ? -1 : 1);
                                        }))
                                        : (columnId.value === 'Converted' && localStorage.defaultModule !== 'oem' && props.pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value === 'LeadsBucket') ?
                                            _.map(state.tasks, function (item) { return item; }).filter(item => item.isConverted === true).sort(function (x, y) {
                                                return (x.convertedDate.seconds > y.convertedDate.seconds ? -1 : 1);
                                            })
                                            : _.map(state.tasks, function (item) { return item; }).filter(item => item.stage === columnId.value && item.pipeline === props.pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value).sort(function (x, y) {
                                                return (x.stageDate.seconds > y.stageDate.seconds ? -1 : 1);
                                            })}
                                    sidepanelOpen={props.sidepanelOpen}
                                    stageLoading={props.stageLoading}
                                    stageCounts={props.stageCounts}
                                    clientUsers={props.clientUsers}
                                    groupUsers={props.groupUsers}
                                    origins={origins}
                                    financeStatuses={financeStatuses}
                                    pipeline={pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value}
                                    isFilterApplied={props.isFilterApplied}
                                    searchText={props.searchText}
                                    handleEnquiryFav={props.handleEnquiryFav}
                                    dealersettings={props.dealersettings}
                                    handleShowInboundLead={props.handleShowInboundLead}
                                    handleOpenAISummary={props.handleOpenAISummary}
                                    handleStageAndStatusFilter={props.handleStageAndStatusFilter}
                                    selectedpipeline={pipeline.find(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))}
                                />

                            })}
                        </div>

                    }
                </DragDropContext>
            </div>
            {
                props.hasMore && props.hasMore.length > 0 ? (
                    <div className="loadmore-middle-btn">
                        <button
                            className={`btn btn-sm btn-loadmore  ${props.stageLoading ? 'form-disable' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                props.loadMoreData();
                            }}><Translate text={'Load More'} />
                            {
                                !_.isEmpty(props.stageLoading) ? (
                                    <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                ) : (<i className="fas fa-chevron-down"></i>)
                            }
                        </button>
                    </div>
                ) : (<></>)
            }

        </div>

    )
}

export default EnquiryList
