import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'
import htmlToText from 'html-to-text';
import Translate from '../constants/translate';
import toast from 'toasted-notes';
import { PDFDocument } from 'pdf-lib';
import { Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import { default as _images } from '../images';
import { defaultFinanceTerms, enquiryModule, enquiryStatus, enqUnActionStatus, eventAllocationStatus, stockStatus, testDriveStatus, tipOfferStatus, tipStatus } from './enum'
import { objEnquiry, objNewSubEnquiryVM } from '../views/pipeline/viewModel'
import parser from "cron-parser";
import CommonHelper from './common';
import { firestoreDB, storageBucket } from './helper';
class EmailHelper {

    handleSend = async (emailObj, props, history, _type) => {

        console.log('nylasv3-sendEmail', emailObj);
        const nylasSendEmail = window.firebase
            .functions()
            .httpsCallable('nylasv3-sendEmail');

        let resp = await nylasSendEmail(emailObj)
        let thread = resp?.data?.thread
        let message = resp.data?.data
        console.log('res of email that i sent', resp);
        //console.log('res message', message);
        //console.log('res thread', thread);


        if (resp.data.success) {
            if (props.handleEmailSent) props.handleEmailSent(_type, message, thread)
            this.linkContactEnquiry(resp.data, props);
            toast.closeAll();
            toast.notify('Email has been sent.', { duration: 2000 });
            return;
        } else {
            toast.closeAll();
            if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
                Swal.fire({
                    title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
                    cancelButtonText: CommonHelper.showLocale(props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
                            nylas: null,
                            nylas_v3: null,
                        }, { merge: true });
                        history.push('/settings/profile?key=emailsettings');
                        return;
                    }
                });
                return;
            }
            else {
                Swal.fire('There was an error in sending email.', '', 'error');
                return;
            }
        }
    };

    linkContactEnquiry = async (data, props) => {
        console.log('linkContactEnquiry', data)
        let enquiryID = props?.enquiryID ? props.enquiryID : (props?.enquiry ? props.enquiry?.documentID : '');
        let contactID = props?.contactID ? props.contactID : (props?.contact ? props.contact?.documentID : '');
        let clientID = props?.clientID ? props.clientID : props?.dealersettings?.client.id
        if (!_.isEmpty(enquiryID) && !_.isEmpty(contactID)) {
            this.linkEnquiry(data, { ...props });
        }
        else if ((data?.data.to.length === 1 && _.isEmpty(contactID)) || (_.isEmpty(enquiryID) && !_.isEmpty(contactID))) {
            let email = data?.data.to[0].email;

            let selectedContact = props.contact?.documentID ? Object.assign({}, props?.contact) : null
            let selectedEnquiry = props.enquiry?.documentID ? Object.assign({}, props?.enquiry) : null

            if (_.isEmpty(contactID) || _.isEmpty(selectedContact)) {

                if (!_.isEmpty(contactID)) {
                    await firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${contactID}`).get().then(doc => {
                        if (doc.exists) {
                            selectedContact = doc.data();
                        }
                    });
                } else {
                    let contactDataRef = firestoreDB(props.dealersettings).firestore().collection('contactDetails')
                        .where('isDeleted', '==', false)
                        .where('keywords', 'array-contains', email.trim().toLowerCase())
                    contactDataRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(contactDataRef, props.dealersettings);
                    let contDoc = await contactDataRef.limit(1).get();

                    if (contDoc.size > 0) {
                        selectedContact = { ...contDoc.docs[0].data(), documentID: contDoc.docs[0].id };
                    }
                }

            }

            if (selectedContact?.documentID && _.isEmpty(selectedEnquiry)) {
                let enqDoc = await firestoreDB(props.dealersettings).firestore().collection('enquiries')
                    .where('isContact', '==', true)
                    .where('contact.documentID', '==', selectedContact.documentID)
                    .where('isDeleted', '==', false)
                    .limit(10)
                    .get();

                if (enqDoc && enqDoc.size > 0) {
                    const enqs = enqDoc.docs.map((r) => { return { ...r.data(), id: r.id } });
                    let _excludedStatus = [enquiryStatus.LOST, enquiryStatus.PROSPECTLOST, enquiryStatus.PENDINGLOST]
                    const validEnqs = Object.assign([], enqs.filter(e => !_excludedStatus.includes(e.status)));
                    if (clientID && _.find(validEnqs, { clientID: clientID }))
                        selectedEnquiry = _.orderBy(validEnqs.filter(e => e.clientID === clientID), 'addedDate', 'desc')[0]
                    else
                        selectedEnquiry = _.orderBy(validEnqs, 'addedDate', 'desc')[0]
                }
            }
            this.linkEnquiry(data, {
                ...props,
                'enquiry': !_.isEmpty(selectedEnquiry) ? selectedEnquiry : null,
                'contact': !_.isEmpty(selectedContact) ? selectedContact : null,
            });
        }
        else {
            this.linkEnquiry(data, { ...props });
        }
    }

    linkEnquiry = (data, props) => {
        console.log('res linkEnquiry', data, props);
        try {
            let _data = CommonHelper.convertObjectKeys(data)
            var id = (_data?.thread?.id || _data?.data?.threadId || firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc().id);
            const linkref = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id);

            let model = {
                documentID: id,
                clientID: props.dealersettings.client.documentID,
                threadID: id,
                thread: (_data.thread || {
                    ..._data.data,
                    id: id,
                    snippet: _data?.data?.body ? htmlToText.fromString(_data.data.body.replace(/<img[^>]*>/g, "")) : ''
                }),
                events: [{
                    id: ('sent' || ''),
                    email: '',
                    name: 'sent',
                    date: window.firebase.firestore.Timestamp.now()
                }],
                token: props.dealersettings.nylasAccessToken,
                linkedBy: localStorage.uid,
                linkedDate: window.firebase.firestore.Timestamp.now(),
                type: CommonHelper.getActivityType('log', props.originType),
                projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null,
                linkedFrom: 'web'
            };

            if (!_.isEmpty(props.enquiry)) {
                model.enquiryID = props.enquiry.documentID;
                model.enquiry = CommonHelper.getMinifiedData(props.enquiry, 'enquiry');;
                if (!_.isEmpty(props.enquiry.contact?.documentID)) {
                    model.contactID = props.enquiry.contact.documentID;
                    model.contact = CommonHelper.getMinifiedData(props.enquiry.contact, 'contact');
                }
            }
            else if (!_.isEmpty(props.tradeinPro)) {
                model.tradeinProID = props.tradeinPro.documentID;
                model.tradeinPro = CommonHelper.getMinifiedData(props.tradeinPro, 'tradeinPro');
                if (!_.isEmpty(props.tradeinPro.contact?.documentID)) {
                    model.contactID = props.tradeinPro.contact.documentID;
                    model.contact = CommonHelper.getMinifiedData(props.tradeinPro.contact, 'contact');
                }
            }
            else if (!_.isEmpty(props.eventsPro)) {
                model.eventsProID = props.eventsPro.documentID;
                model.eventsPro = CommonHelper.getMinifiedData(props.eventsPro, 'eventsPro');
                if (!_.isEmpty(props.eventsPro.contact?.documentID)) {
                    model.contactID = props.eventsPro.contact.documentID;
                    model.contact = CommonHelper.getMinifiedData(props.eventsPro.contact, 'contact');
                }
            }
            else if (!_.isEmpty(props.service)) {
                model.serviceID = props.service.documentID;
                model.service = CommonHelper.getMinifiedData(props.service, 'service');
                if (!_.isEmpty(props.service.contact?.documentID)) {
                    model.contactID = props.service.contact.documentID;
                    model.contact = CommonHelper.getMinifiedData(props.service.contact, 'contact');
                }
            }
            else if (!_.isEmpty(props.contact?.documentID)) {
                model.contactID = props.contact.documentID;
                model.contact = CommonHelper.getMinifiedData(props.contact, 'contact');
            }
            if (props?.enquiryID) model.enquiryID = props.enquiryID;
            if (props?.tradeinProID) model.tradeinProID = props.tradeinProID;
            if (props?.eventsProID) model.eventsProID = props.eventsProID;
            if (props?.serviceID) model.serviceID = props.serviceID;
            if (props?.fleetProID) model.fleetProID = props.fleetProID;
            if (props?.contactID) model.contactID = props.contactID;

            if (model.enquiryID || model.tradeinProID || model.eventsProID || model.serviceID || model.fleetProID || model.contactID) {
                //console.log('res linkref', model);
                Object.keys(model).forEach(key => (model[key] === undefined || _.isEmpty(model[key])) ? delete model[key] : {});

                linkref.set(model, { merge: true });

                if (!_.isEmpty(model.enquiryID)) {
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${model.enquiryID}`)
                        .set({
                            modifiedBy: localStorage.uid,
                            modifiedFrom: 'web',
                            unactionedNotify: enqUnActionStatus.COMPLETED,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                        }, { merge: true })
                }

                if (!_.isEmpty(_data.data)) {
                    let messageRef = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id).collection('messages').doc(_data.data.id)
                    messageRef.set({
                        ..._data.data,
                        projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
                    })
                }
            }



        } catch (error) {
            console.error('linkEnqruiy', error);

        }
    }
}

export default new EmailHelper;