import React, { useCallback, useContext, useEffect, useState } from 'react';
import { firestoreDB } from '../../services/helper';
import _ from 'lodash';
import moment from 'moment';
import { allenquiryFields, tagColors, revenueTargets } from './viewModel';
import common from '../../services/common';
import NumberFormat from 'react-number-format';
import images from '../../images';
import CommonHelper from '../../services/common';
import { AntMonthPicker, DropDownMenu, GridView, PopUpModal, SidePanelDetailView } from '../../components';
import ChangeLogs from './changeLogs';
import FieldComments from './fieldComments';
import { StageProvider, StageConsumer } from '../pipeline/provider'
//import QuickView from "../pipeline/quickview";
import { MainContext } from '../../views/layout/provider';
import EnquiryInfo from '../pipeline/details';
import { RouteConsumer } from '../layout/RouteProvider';
import CellColorSettings from './cellColor';
import RevenueTarget from './revenueTarget';
import { tipStatuses } from '../../services/enum';
import AddDynamicOptions from '../settings/workflow/lookups/addDynamicOptions';
import cellRenderer from '../../components/gridView/cellRenderer';
import CustomDatePicker from '../../components/gridView/datePicker';
import CustomSelect from '../../components/gridView/customSelect';
import NumberEditor from '../../components/gridView/numberEditor';

const DealsLog = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 310) })
    const [wonLeads, setWonLeads] = useState([])
    const [carryoverLeads, setCarryoverLeads] = useState([])
    const [coveredLeads, setCoveredLeads] = useState([])
    const [deliveredLeads, setDeliveredLeads] = useState([])
    const [reportedLeads, setReportedLeads] = useState([])
    const [reportableLeads, setReportableLeads] = useState([])
    const [remainingLeads, setRemainingLeads] = useState([])
    const [enquiries, setEnquiries] = useState([])
    const [allEnquiries, setAllEnquiries] = useState([])
    const [loader, setLoader] = useState(true)
    const [dLOrderFields, setdLOrderFields] = useState();
    const [dLSortFields, setdLSortFields] = useState();
    const [afterSoldStatuses, setafterSoldStatuses] = useState(props?.dealersettings?.client?.settings?.enquiryStatus?.filter(e => e.afterSold === true && e.active === true)?.map(r => { return r.value }) || [])
    const [gridRefresh, setGridRefresh] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [selecteddepartment, setSelectedDepartments] = useState({});
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').toDate()),
        filterStartDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').toDate()),
        filterEndDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').toDate()),
        startMonth: moment().toDate(),
        date: moment().toDate(),
        year: moment().format('YYYY'),
        month: moment().format('MMM')
    })
    const [dynamicCols, setDynamicCols] = useState()
    const [currentSettings, setCurrentSettings] = useState()
    const [deallogSettings, setdeallogSettings] = useState()
    const [changelogmodal, setchangelogmodal] = useState({ show: false })
    const [notesmodal, setnotesmodal] = useState({ show: false })
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview-detail' })
    const [dataRefresh, setDataRefresh] = useState(false)
    const { dialRingcentral, dialNumber, updateDealerSettings, clientModule, dealersettings, clientUsers, groupUsers, currentClient, history } = useContext(MainContext);
    const [colormodal, setcolormodal] = useState({ show: false })
    const [gridRef, setgridRef] = useState({})
    const [docChanges, setDocChanges] = useState({})
    const [searchText, setSearchText] = useState('');
    const [showDynamicOptionModel, setshowDynamicOptionModel] = useState({ show: false })

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 310)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            unSubAll();
        }
    }, [])

    const unSubAll = () => {
        window?.unsubrefSoldData && window.unsubrefSoldData();
        window?.unsubrefCarryoverData && window.unsubrefCarryoverData();
        window?.unsubrefDeliveredData && window.unsubrefDeliveredData();
    }

    const sidepanelClose = async () => {
        if (showpanel.enquiryid) {
            const enq = await firestoreDB(props.dealersettings).firestore().doc(`enquiries/${showpanel.enquiryid}`).get();
            if (enq.exists) {
                updateListEnquiry({
                    ...enq.data(),
                    documentID: enq.id,
                    sno: showpanel?.colParams?.data?.sno || null
                })
            }
        }
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0,
            colParams: null
        })
    }

    const sidepanelOpen = (val, params) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val,
            colParams: params
        })
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            const updateData = common.convertDealLogVM({ ...objEnquiry, id: objEnquiry.documentID }, props, deallogSettings);
            if (showpanel?.colParams) {
                showpanel.colParams.api.getDisplayedRowAtIndex(showpanel.colParams.node.rowIndex).updateData(updateData)
                // showpanel.colParams.api.redrawRows({
                //     rowNodes: [props.colParams.node],
                //     columns: [props.colParams.column.colId],
                // });
            }

        }

    }

    useEffect(() => {
        const saletypes = props?.dealersettings?.client?.settings?.salesType?.filter(e => e.active === true) || [];
        const dealLogRef = window.firebase.firestore().doc(`/clientSettings/${props.dealersettings.oemID}/dealLog/${props.dealersettings.oemID}`).get();
        const userRef = window.firebase.firestore().doc(`/users/${localStorage.uid}/dealLog/${localStorage.uid}`).get();
        Promise.all([dealLogRef, userRef])
            .then(snaps => {
                let _dept = [];
                const deptDoc = snaps[0]?.data() || {};
                const userDoc = snaps[1]?.data() || {};
                setdeallogSettings(deptDoc);
                if (deptDoc.departments?.length) {
                    _dept = deptDoc.departments.map(r => {
                        return {
                            ...r,
                            label: r.name,
                            value: r.id
                        }
                    });
                } else {
                    const dealerList = CommonHelper.getDealLogDealers(props.dealersettings, true, true);
                    dealerList.forEach(rec => {
                        saletypes.forEach(st => {
                            _dept.push({
                                label: `${rec.name} - ${st.name}`,
                                clientID: rec.id,
                                saleTypes: [st.value],
                                value: `${rec.id}-${st.value}`
                            })
                        })
                    })
                }

                if (userDoc?.dLSortFields?.length) {
                    setdLSortFields(userDoc?.dLSortFields)
                }
                setDepartments(_dept);
                setSelectedDepartments(_.find(_dept, { value: userDoc?.department }) || _dept[0]);
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(selecteddepartment?.clientID))
            return
        const currentRole = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/roles/${props.dealersettings.roleID}`).get()
        const currentSettings = window.firebase.firestore().doc(`clients/${selecteddepartment?.clientID}/currentSettings/${selecteddepartment?.clientID}`).get()
        const userRef = window.firebase.firestore().doc(`/users/${localStorage.uid}/dealLog/${localStorage.uid}`).get();

        Promise.all([currentRole, currentSettings, userRef])
            .then(snaps => {
                let _settings = props?.dealersettings?.client?.settings;
                let _role = props?.dealersettings?.rolePermissions;
                let _dLOrderFields = [];
                if (snaps[1]?.data()?.settings) {
                    _settings = snaps[1].data().settings;
                }
                if (snaps[0]?.data()) {
                    _role = snaps[0]?.data();
                }
                if (snaps[2].data()?.[selecteddepartment?.clientID]?.length) {
                    _dLOrderFields = snaps[2].data()[selecteddepartment?.clientID].map(r => { return { ...r, default: true } });
                } else if (snaps[2].data()?.dLOrderFields?.length) {
                    _dLOrderFields = snaps[2].data().dLOrderFields.map(r => { return { ...r, default: true } });
                } else {
                    _dLOrderFields = allenquiryFields.filter(a => a.default === true).map(r => { return { name: r.name, value: r.value, default: true } });
                }
                setdLOrderFields(_dLOrderFields);
                console.log(_dLOrderFields)
                const enquiryFields = allenquiryFields
                    .filter(a => !Boolean(a.dynamic))
                    .map(a => {
                        return {
                            ...a,
                            name: _.find(_settings?.enquiryOptionsStatic, { value: a.fieldDB })?.name || a.name,
                            format: a.picker === 'month' ? a.format : deallogSettings?.dateFormat
                        }
                    });
                const settings = loadSettings(_settings);
                settings?.enquiryOptionsDF?.filter(a => a.active === true)?.forEach(rec => {
                    enquiryFields.push({
                        name: rec.name,
                        value: rec.type === 'price' || rec.type === 'number' ? rec.value : `str${rec.value}`,
                        editable: _.isEmpty(rec?.formula) && rec.type !== 'toggle',
                        dynamic: true,
                        dataType: rec.type,
                        fieldDB: rec.value,
                        settingsField: rec.value,
                        isMulti: rec.type === 'multiselect',
                        nestedObj: 'dynamicFields',
                        format: deallogSettings?.dateFormat,
                        formula: rec.formula,
                        collections: [
                            { name: 'enquiries', nestedObj: 'dynamicFields' }
                        ]
                    })
                })
                setCurrentSettings(settings);
                let _dynCols = [
                    ..._dLOrderFields.filter(a => enquiryFields.some(b => b.value === a.value)).map(r => { return { ...r, ..._.find(enquiryFields, { value: r.value }) } }),
                    ...enquiryFields.filter(a => !_dLOrderFields.some(b => b.value === a.value)).map(r => { return { ...r, default: false } })
                ];
                _dynCols = _dynCols.filter(a => _role?.deallogPermissions?.[a.value] !== 'hide').map(r => {
                    return {
                        ...r,
                        editable: r.editable ? (_.isEmpty(_role?.deallogPermissions?.[r.value]) || _role?.deallogPermissions?.[r.value] === 'edit') : false,
                        cellStyle: params => { return loadCellColor(params) },
                    }
                })
                setDynamicCols(_dynCols);
            });

    }, [selecteddepartment?.clientID])

    const loadCellColor = (params) => {
        let __color = null;
        if (params?.column?.colDef?.colors && params?.data?.[params?.column?.colDef?.fieldDB]) {
            if ((params?.data?.[params?.column?.colDef?.timeDB] && moment.unix(params?.data?.[params?.column?.colDef?.fieldDB]?.seconds).isAfter(moment().startOf('month'))) || params?.data?.status === 'delivered') {
                __color = { color: 'green' }
            } else if (moment.unix(params?.data?.[params?.column?.colDef?.fieldDB]?.seconds).isAfter(moment().endOf('day'))) {
                __color = { color: 'blue' }
            } else if (moment.unix(params?.data?.[params?.column?.colDef?.fieldDB]?.seconds).isBefore(moment().startOf('month'))) {
                __color = { color: 'red' }
            }
        }
        return __color || {};
    }

    const loadSettings = (_settings) => {
        const settings = _settings;
        const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
        settings.allUsers = _.uniqBy(allUsers.filter(a => a.clientID === selecteddepartment?.clientID), 'id');
        settings?.enquiryOptionsDF?.filter(a => a.type === 'select' || a.type === 'multiselect')?.forEach(rec => {
            settings[rec.value] = rec?.subList?.map(r => { return { name: r, value: r, active: true } })
        })
        settings.makes = props?.dealersettings?.allMakeModels || [];
        props?.dealersettings?.allMakeModels?.forEach(make => {
            settings[`${make.value}models`] = make?.models?.map(r => { return { name: r.value, value: r.value } }) || []
            settings[`${make.value}types`] = make?.models?.filter(a => a.type)?.map(r => { return { name: r.type, value: r.type } }) || []
            settings[`${make.value}groups`] = make?.models?.filter(a => a.group)?.map(r => { return { name: r.group, value: r.group } }) || []
        })
        const years = [];
        for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
            years.push({
                value: i.toString(),
                name: i.toString(),
                active: true
            });
        }
        settings.years = years;
        const financeroleIds = props?.dealersettings?.client?.roles?.filter(e => e.permissions && e.permissions.accessToBusinessManager === true)?.map(rec => { return rec.id }) || [];
        const aftermeketroleIds = props?.dealersettings?.client?.roles?.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true)?.map(rec => { return rec.id }) || [];
        settings.financeManagers = settings.allUsers?.filter(a => Boolean(a.superAdmin) === false && Boolean(a.active) && (financeroleIds.length > 0 ? _.includes(financeroleIds, a.roleID) : false))?.map(rec => {
            return {
                value: rec.id,
                searchlabel: rec.name,
                name: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>
            }
        })
        settings.afterMarketManagers = settings.allUsers?.filter(a => Boolean(a.superAdmin) === false && Boolean(a.active) && (aftermeketroleIds.length > 0 ? _.includes(aftermeketroleIds, a.roleID) : false))?.map(rec => {
            return {
                value: rec.id,
                searchlabel: rec.name,
                name: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>
            }
        })
        settings.clientUsers = settings.allUsers?.filter(a => Boolean(a.superAdmin) === false && Boolean(a.active))?.map(rec => {
            return {
                value: rec.id,
                searchlabel: rec.name,
                name: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>
            }
        })
        settings.enqLabels = settings?.enquiryLabels?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: CommonHelper.bindEnquiryLabel(settings?.enquiryLabels, rec.value, rec.name)
            }
        })
        settings.financeStatuses = settings?.financeStatus?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.financeStatus}
                    className={`status-${rec.financeStatus === 'open' ? 'open' : 'empty'} mt-2`}
                    style={{ background: (rec.color ? rec.color : '#333') }}
                >
                    {rec.name}
                </span>
            }
        })
        settings.afterMarketStatuses = settings?.afterMarketStatus?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.afterMarketStatus}
                    className={`status-${rec.afterMarketStatus === 'open' ? 'open' : 'empty'} mt-2`}
                    style={{ background: (rec.color ? rec.color : '#333') }}
                >
                    {rec.name}
                </span>
            }
        })
        settings.enquiryStatuses = settings?.enquiryStatus?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.status}
                    className={`status-${rec.status === 'open' ? 'open' : 'empty'} mt-2`}
                    style={{ background: (rec.color ? rec.color : '#333') }}
                >
                    {rec.name}
                </span>
            }
        })
        settings.stockStatuses = settings?.stockStatus?.filter(a => Boolean(a.active))?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.value}
                    className={`status-${rec.value === 'open' ? 'open' : 'empty'} mt-2`}
                    style={{ background: (rec.color ? rec.color : '#333') }}
                >
                    {rec.name}
                </span>
            }
        })
        settings.nationalities = props?.dealersettings?.nationalities || [];
        settings.languages = props?.dealersettings?.languages?.map(r => { return { ...r, value: r.code } }) || [];
        settings.countries = props?.dealersettings?.countries?.map(r => { return { ...r, value: r.code } }) || [];
        settings.countries?.forEach(country => {
            settings[`${country.code}states`] = props?.dealersettings?.states?.filter(a => a.country_code === country.code)?.map(r => { return { ...r, value: r.code } }) || []
        })
        settings.opts = [
            { active: true, value: true, name: 'Opt In' },
            { active: true, value: false, name: 'Opt Out' }
        ]
        const _tdStatus = ['pending', 'complete'];
        settings.tdStatuses = _tdStatus?.map(rec => {
            return {
                value: rec,
                searchlabel: rec,
                name: <span key={rec} className={`badge badge-pill badge-${rec} mt-2`} >{rec}</span>
            }
        })
        settings.trdStatuses = tipStatuses?.map(rec => {
            return {
                value: rec.value,
                searchlabel: rec.name,
                name: <span key={rec.value} className={`badge badge-pill status-${rec.value.toLowerCase()} mt-2`} >{rec.name}</span>
            }
        })
        return settings;
    }

    useEffect(() => {
        if (!selecteddepartment?.clientID || !_.isEmpty(searchText)) {
            return
        }

        window.unsubrefSoldData = collQuerys().refSoldData.onSnapshot(querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
                if (querySnapshot.docChanges().length === 1 && change.type !== 'removed') {
                    setDocChanges({
                        data: common.convertDealLogVM({ ...change.doc.data(), documentID: change.doc.id, id: change.doc.id }, props, deallogSettings),
                        type: change.type,
                        status: 'won'
                    })
                }
            })
        });

        window.unsubrefCarryoverData = collQuerys().refCarryoverData.onSnapshot(querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
                if (querySnapshot.docChanges().length === 1 && change.type !== 'removed') {
                    setDocChanges({
                        data: common.convertDealLogVM({ ...change.doc.data(), documentID: change.doc.id, id: change.doc.id }, props, deallogSettings),
                        type: change.type,
                        status: 'carryover'
                    })
                }
            })
        });

        window.unsubrefDeliveredData = collQuerys().refDeliveredData.onSnapshot(querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
                if (querySnapshot.docChanges().length === 1 && change.type !== 'removed') {
                    setDocChanges({
                        data: common.convertDealLogVM({ ...change.doc.data(), documentID: change.doc.id, id: change.doc.id }, props, deallogSettings),
                        type: change.type,
                        status: 'delivered'
                    })
                }
            })
        });

    }, [selecteddepartment?.clientID, dateRange])

    useEffect(() => {
        if (loader || _.isEmpty(docChanges?.data))
            return

        setAllEnquiries([...allEnquiries.filter(a => a.id !== docChanges.data.id), docChanges.data]);
        setDataRefresh(true);
        //setGridRefresh(true)
        const sortConfig = gridRef.getColumnState()
            .filter(function (s) {
                return s.sort != null;
            })
        const _updatedRow = _.find(gridRef.getRenderedNodes(), obj => _.get(obj, 'data.id') === docChanges.data.id);
        if (_updatedRow)
            gridRef.getDisplayedRowAtIndex(_updatedRow.rowIndex).updateData(docChanges?.data)
        else if (_.isEmpty(selecteddepartment?.filterStatus)
            && _.isEmpty(searchText)
            && _.isEmpty(gridRef.getFilterModel())
            && (selecteddepartment?.saleTypes?.length ? selecteddepartment.saleTypes.includes(docChanges?.data?.saleType) : true) &&
            (selecteddepartment?.makes?.length ? selecteddepartment.makes.includes(docChanges?.data?.make) : true)
        ) {
            const sortDesc = _.find(sortConfig, { colId: 'wonOn' })?.sort || 'desc';
            const index = _.orderBy(gridRef.getRenderedNodes().map(r => { return r.data }).map((a, i) => { return a?.wonDate?.seconds < docChanges?.data?.wonDate?.seconds ? -1 : i }).filter(a => a >= 0), [], sortDesc)[0] || 1
            gridRef.applyTransaction({
                add: [docChanges?.data],
                addIndex: index,
            });
            setTimeout(function () {
                var rowNode = gridRef.getDisplayedRowAtIndex(index + 1);
                gridRef.flashCells({ rowNodes: [rowNode] });
            }, 1000)
        }


    }, [docChanges])

    const collQuerys = () => {
        const refSoldData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('clientID', '==', selecteddepartment.clientID)
            .where('status', 'in', ['won', ...afterSoldStatuses])
            .where('isDeleted', '==', false)
            .where('wonDate', '>=', dateRange.startDate)
            .where('wonDate', '<=', dateRange.endDate)

        //anything marked as won previous month and not delivered
        const refCarryoverData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('clientID', '==', selecteddepartment.clientID)
            .where('status', 'in', ['won', ...afterSoldStatuses])
            .where('isDeleted', '==', false)
            .where('wonDate', '<', dateRange.startDate)

        //current month delivered
        const refDeliveredData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('clientID', '==', selecteddepartment.clientID)
            .where('status', 'in', ['delivered'])
            .where('isDeleted', '==', false)
            .where('deliveredDate', '>=', dateRange.startDate)
            .where('deliveredDate', '<=', dateRange.endDate)

        return {
            refSoldData,
            refCarryoverData,
            refDeliveredData
        }
    }

    useEffect(() => {
        if (!selecteddepartment?.clientID) {
            return
        }
        //current month sold
        setLoader(true)
        Promise.all([collQuerys().refSoldData.get(), collQuerys().refCarryoverData.get(), collQuerys().refDeliveredData.get()])
            .then(snaps => {
                const _wonLeads = _.map(snaps[0].docs, function (obj) { return common.convertDealLogVM({ ...obj.data(), documentID: obj.id, id: obj.id }, props, deallogSettings) })
                const _carryoverLeads = _.map(snaps[1].docs, function (obj) { return common.convertDealLogVM({ ...obj.data(), documentID: obj.id, id: obj.id }, props, deallogSettings) })
                const _deliveredLeads = _.map(snaps[2].docs, function (obj) { return common.convertDealLogVM({ ...obj.data(), documentID: obj.id, id: obj.id }, props, deallogSettings) })

                setGridRefresh(true)
                setAllEnquiries((values) => [
                    ...values,
                    ..._wonLeads.filter(a => a.pipeline !== 'LeadsBucket'),
                    ..._carryoverLeads.filter(a => a.pipeline !== 'LeadsBucket'),
                    ..._deliveredLeads.filter(a => a.pipeline !== 'LeadsBucket')
                ])
                setLoader(false)
                setDataRefresh(true)
            })
    }, [selecteddepartment?.clientID, dateRange])

    useEffect(() => {
        if (!dataRefresh)
            return

        const status = selecteddepartment?.filterStatus || 'other';
        const wonStatus = ['won', ...afterSoldStatuses];
        const filterEnquiries = allEnquiries.filter(({ saleType, make }) =>
            (selecteddepartment?.saleTypes?.length ? selecteddepartment.saleTypes.includes(saleType) : true) &&
            (selecteddepartment?.makes?.length ? selecteddepartment.makes.includes(make) : true)
        ).map(rec => {
            return {
                ...rec,
                revenueAmount: revenueCalc(rec)
            }
        });
        const allLeads = {
            wonLeads: filterEnquiries
                .filter(a => (wonStatus.includes(a.status) || a.status === 'delivered') && a.wonDate?.seconds >= dateRange.filterStartDate.seconds && a.wonDate?.seconds <= dateRange.filterEndDate.seconds),
            carryoverLeads: filterEnquiries.filter(a => wonStatus.includes(a.status) && a.wonDate?.seconds < dateRange.filterStartDate.seconds),
            deliveredLeads: filterEnquiries.filter(a => a.status === 'delivered' && a.deliveredDate?.seconds >= dateRange.filterStartDate.seconds && a.deliveredDate?.seconds <= dateRange.filterEndDate.seconds),
            coveredLeads: filterEnquiries.filter(a => (wonStatus.includes(a.status) || a.status === 'delivered') && a.deliveryDate?.seconds >= dateRange.filterStartDate.seconds && a.deliveryDate?.seconds <= dateRange.filterEndDate.seconds)
        }
        //allLeads.wonLeads = _.orderBy(allLeads.wonLeads, 'wonDate.seconds', 'desc').map((r, i) => { return { ...r, sno: (allLeads.wonLeads.length - i) } })
        const _otherLeads = allLeads.wonLeads.concat(allLeads.carryoverLeads).concat(allLeads.deliveredLeads)
        const _searchLeads = _.differenceBy(filterEnquiries, _otherLeads, 'id');
        allLeads.otherLeads = _.uniqBy(_otherLeads.concat(_searchLeads), 'id');

        if (deallogSettings?.reportedCalc?.field) {
            allLeads.reportableLeads = filterEnquiries.filter(a => (wonStatus.includes(a.status) || a.status === 'delivered')
                && a.deliveryDate?.seconds >= dateRange.filterStartDate.seconds
                && a.deliveryDate?.seconds <= dateRange.filterEndDate.seconds
                && (deallogSettings?.reportedCalc?.excludeSaleType?.length ? !deallogSettings.reportedCalc.excludeSaleType.includes(a.saleType) : true)
            );
            allLeads.reportedLeads = allLeads.reportableLeads.filter(a => !_.isEmpty(a[`str${deallogSettings?.reportedCalc?.field}`]));
            allLeads.remainingLeads = allLeads.reportableLeads.filter(a => !allLeads.reportedLeads.some(b => b.id === a.id));
        }


        setWonLeads(_.uniqBy(allLeads.wonLeads, 'id'));
        setCarryoverLeads(_.uniqBy(allLeads.carryoverLeads, 'id'));
        setDeliveredLeads(_.uniqBy(allLeads.deliveredLeads, 'id'));
        setCoveredLeads(_.uniqBy(allLeads.coveredLeads, 'id'));
        setReportableLeads(_.uniqBy(allLeads.reportableLeads, 'id'));
        setReportedLeads(_.uniqBy(allLeads.reportedLeads, 'id'));
        setRemainingLeads(_.uniqBy(allLeads.remainingLeads, 'id'))
        if (gridRefresh) {
            setEnquiries(_.orderBy(allLeads[`${status}Leads`], 'wonDate.seconds', 'desc').map((r, i) => {
                return {
                    ...r,
                    sno: (allLeads.wonLeads.concat(allLeads.deliveredLeads).length - i) > 0 && moment(dateRange.date)?.month() === moment.unix(r?.wonDate?.seconds)?.month() ? (allLeads.wonLeads.concat(allLeads.deliveredLeads).length - i) : null
                }
            }));
            setGridRefresh(false)
        }
        //setAllWonLeads(allLeads.wonLeads.filter(a => moment.unix(a?.wonDate?.seconds)?.month() === moment(dateRange.date).month()))
        setDataRefresh(false)

    }, [dataRefresh]);

    useEffect(() => {
        if (wonLeads.length === 0 || _.isEmpty(gridRef?.getRenderedNodes()))
            return
        gridRef.getRenderedNodes().forEach(row => {
            const sno = _.findIndex(_.orderBy(wonLeads, 'wonDate.seconds', 'asc').filter(a => moment.unix(a?.wonDate?.seconds)?.month() === moment(dateRange.date).month()), { id: row.data.id }) + 1;
            if (gridRef.getDisplayedRowAtIndex(row.rowIndex)) {
                try {
                    row.data.sno = sno;
                    gridRef.getDisplayedRowAtIndex(row.rowIndex).setData(row.data);
                } catch (e) { console.log(e) }
            }
        })

    }, [wonLeads])

    const revenueCalc = (data) => {
        //revenue calculation
        let _amount = 0;
        if (deallogSettings?.revenueCalc?.filter(a => a.value)?.length) {
            deallogSettings?.revenueCalc.filter(a => a.value).forEach(r => {
                if (r.action === 'add')
                    _amount += data[r.value] || 0
                else
                    _amount -= data[r.value] || 0
            })
        } else {
            _amount = data.grossAmount || 0;
        }
        return _amount;
    }

    const saveSettings = useCallback((params, type) => {
        let fields = params.api.getColumnDefs().filter(a => !Boolean(a.hide) && a.value !== "#").map(r => { return _.pick(r, ['name', 'value', 'width', 'pinned']) });
        let fieldName = params?.context?.settings?.selecteddepartment?.clientID || 'dLOrderFields';
        if (type === 'sort') {
            fieldName = 'dLSortFields';
            fields = params.api.getColumnState()
                .filter(function (s) {
                    return s.sort != null;
                })
                .map(function (s) {
                    return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
                })
        }
        saveDept(fields, fieldName);
    }, [])

    const saveDept = useCallback((data, fieldName) => {
        window.firebase.firestore().doc(`/users/${localStorage.uid}/dealLog/${localStorage.uid}`)
            .set({ [fieldName]: data }, { merge: true })
            .catch(error => {
                console.error(error)
            });
    }, [])

    const onGridValueChange = useCallback(async (params) => {
        if (params?.colDef?.collections) {
            let i = 0;
            const batch = firestoreDB(props.dealersettings).firestore().batch();
            for (const rec of params?.colDef?.collections) {
                const docID = rec.docID ? (params?.data?.[rec.docID] || params?.data?.documentID) : params?.data?.documentID;
                let collectionDoc = rec.name && docID ? `${rec.name}/${docID}` : null;
                if (rec.subcollection) {
                    collectionDoc = params?.data?.[rec.collectionID] ? `${rec.collection}/${params.data[rec.collectionID]}/${collectionDoc}` : null;
                }
                let value = _.isUndefined(params?.data?.[params?.colDef?.fieldDB]) ? null : params?.data?.[params?.colDef?.fieldDB];
                const objUpdate = {};
                let field = rec?.fieldDB || params?.colDef?.fieldDB || null;
                if (rec?.convertType === 'date' && value) {
                    value = window.firebase.firestore.Timestamp.fromDate(moment(moment(value).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm')).toDate())
                }
                if (field) {
                    if (params?.data?.isStock && rec?.nestedObj && rec.req) {
                        objUpdate[`${rec.nestedObj}.stock.${field}`] = value;
                    } else if (params?.data?.isStock && rec.subcollection && rec.req) {
                        objUpdate[`stock.${field}`] = value;
                    } else if (rec?.nestedObj) {
                        objUpdate[`${rec.nestedObj}.${field}`] = value;
                    } else {
                        objUpdate[field] = value;
                    }
                }
                if (params?.colDef?.timeDB) {
                    objUpdate[params?.colDef?.timeDB] = params?.data?.[params?.colDef?.timeDB] || null;
                }
                console.log(collectionDoc, objUpdate)
                if (objUpdate && collectionDoc) {
                    i++;
                    batch.update(firestoreDB(props.dealersettings).firestore().doc(collectionDoc), {
                        ...objUpdate
                    });
                    //await firestoreDB(props.dealersettings).firestore().doc(collectionDoc).update(objUpdate);
                }
            }
            if (i > 0) {
                await batch.commit();
            }
        }
        setAllEnquiries((values) => values.map(rec => { return rec.documentID === params?.data?.documentID ? params.data : rec }));
        setGridRefresh(false)
        setDataRefresh(true)
    }, [])

    const onContextMenuClick = useCallback((params, type) => {
        if (type === 'comments') {
            const cellPosition = getcellPosition(params?.eGridCell?.getBoundingClientRect())
            setnotesmodal({
                show: true,
                title: 'Comments',
                position: cellPosition > 100 ? 'right' : 'left',
                icon: "ico icon-message mailnotifiy-pop-title",
                data: params.node.data,
                params: params
            })
        } else if (type === 'changeLogs') {
            setchangelogmodal({
                show: true,
                title: 'Change Logs',
                recordIDs: [params?.node?.data?.contactID, params?.node?.data?.documentID],
                params: params
            })
        } else if (type === 'colorSettings') {
            const cellPosition = getcellPosition(params?.eGridCell?.getBoundingClientRect())
            setcolormodal({
                show: true,
                title: `Tag Colours (${params.column.colDef.name})`,
                params: params,
                position: cellPosition > 100 ? 'right' : 'left',
                data: params.node.data,
            })
        } else if (type === 'cellTag') {
            tagCellColor(params);
        }
    }, [])

    const tagCellColor = useCallback((params) => {
        if (params?.node?.data?.documentID && params?.column?.colId) {
            const _tagColor = params.fieldColor || null;
            window.firebase.firestore().doc(`/enquiries/${params.node.data.documentID}`)
                .update({ [`fieldTags.${params.column.colId}`]: _tagColor })
                .then(() => {
                    params.api.getDisplayedRowAtIndex(params.node.rowIndex).updateData({
                        ...params.node.data,
                        fieldTags: params.node.data.fieldTags ? {
                            ...params.node.data.fieldTags,
                            [params.column.colId]: _tagColor
                        } : { [params.column.colId]: _tagColor }
                    })
                    params.api.redrawRows({
                        rowNodes: [params.node],
                        columns: [params.column.colId],
                    });
                })
                .catch(error => {
                    console.error(error)
                });
        }
    }, [])

    const getcellPosition = (rec) => {
        const windowWidth = window.innerWidth;
        const cellRight = rec?.right || 0;
        const popoverWidth = 450;

        return windowWidth - cellRight - popoverWidth;
    }

    const onCellClicked = useCallback((params) => {
        if (params?.column.colDef.value === 'sno' && params.data.documentID) {
            sidepanelOpen(params.data.documentID, params)
        }
    }, [])

    const onMsgIconClicked = useCallback((params) => {
        if (params?.data?.fieldComments?.[params?.column?.colId]) {
            const cellPosition = getcellPosition(params?.eGridCell?.getBoundingClientRect())
            setnotesmodal({
                show: true,
                position: cellPosition > 100 ? 'right' : 'left',
                title: 'Comments',
                icon: "ico icon-message mailnotifiy-pop-title",
                data: params.data,
                params
            })
        }
    }, [])

    const onDeptChange = (val) => {
        const _sDept = _.find(departments, { value: val });
        if (_sDept?.clientID !== selecteddepartment?.clientID) {
            setAllEnquiries([]);
        }
        setSelectedDepartments(_sDept);
        setGridRefresh(true)
        setDataRefresh(true)
        saveDept(val, 'department');
    }

    const addDynColumns = useCallback((params) => {
        setshowDynamicOptionModel({
            show: true,
            title: 'Add Column',
            columnProps: params
        })
    }, [])


    const saveCurrentSettings = useCallback(async (data, params) => {
        const allCols = params.api.getColumnDefs();
        const index = _.findIndex(params.api.getColumnDefs(), { colId: params.column.colId });
        const newCol = {
            ...data,
            hide: false,
            headerName: data.name,
            cellRenderer: cellRenderer,
            default: true,
            value: data.type === 'price' || data.type === 'number' ? data.value : `str${data.value}`,
            field: data.type === 'price' || data.type === 'number' ? data.value : `str${data.value}`,
            editable: _.isEmpty(data?.formula),
            dynamic: true,
            dataType: data.type,
            fieldDB: data.value,
            isMulti: data.type === 'multiselect',
            nestedObj: 'dynamicFields',
            format: deallogSettings?.dateFormat,
            formula: data.formula,
            settingsField: data.value,
            collections: [
                { name: 'enquiries', nestedObj: 'dynamicFields' }
            ],
            cellEditor: data.type === 'date' ? CustomDatePicker :
                data.type === 'select' || data.type === 'multiselect' ? CustomSelect : NumberEditor,
            cellEditorPopup: true,
            cellEditorParams: {
                dealerSettings: {
                    ...currentSettings,
                    [data.value]: data?.subList?.map(r => { return { name: r, value: r, active: true } }) || []

                },
                onKeyDown: (event) => {
                    console.log('Key pressed: ', event.key);
                }
            },
            cellClass: params => {
                return `${params?.data?.id ? `${params.column.colId}-${params?.data.id}` : ''} ${params?.colDef?.className || ''}`;
            },
        }
        allCols.splice((index + 1), 0, newCol);
        params.api.setGridOption("columnDefs", [...allCols]);
        saveSettings(params);
        updateDealerSettings('enquiryOptionsDF', [
            ...(props.dealersettings?.client?.settings?.enquiryOptionsDF?.filter(a => a.value !== data.value) || []),
            CommonHelper.removeEmptyOrNull(data)
        ], 'salesSettings')
        const dealerList = CommonHelper.getDealLogDealers(props.dealersettings, true, true);
        for (const dealer of dealerList) {
            const settingsRef = window.firebase.firestore().doc(`clients/${dealer.id}/currentSettings/${dealer.id}`);
            const settingsDoc = await settingsRef.get()
            const enqOptions = settingsDoc?.data()?.settings?.enquiryOptionsDF?.filter(a => a.value !== data.value) || [];
            enqOptions.push(CommonHelper.removeEmptyOrNull(data));
            await settingsRef.update({ [`settings.enquiryOptionsDF`]: enqOptions })
        }
    }, [])

    return (<div className={`middle-wrapper h-100`}>
        <div className="dynamic-module-container">
            <div className="deallog-header-filterbar">
                <div className="float-left">
                    <div className="deallog-filter-search search-icon">
                        {searchText?.length > 0 ? (
                            <div className="deallog-filter-search-close" onClick={() => {
                                setSearchText('');
                                gridRef.setGridOption("quickFilterText", '');
                            }}><span>×</span></div>
                        ) : (<></>)}
                        <input placeholder="Search" aria-label="Search" value={searchText || ''} onChange={(e) => {
                            setSearchText(e.target.value)
                            setGridRefresh(true)
                            gridRef.setGridOption("quickFilterText", e.target.value);
                            setSelectedDepartments({
                                ...selecteddepartment,
                                filterStatus: null,
                            })
                            setDataRefresh(true)
                        }} />
                    </div>
                    <div className="deallog-filter-loop">
                        Departments
                        <div className={`badge badge-pill badge-open ml-2`} style={{ background: 'rgb(255, 255, 255)' }}>
                            <DropDownMenu
                                text={selecteddepartment?.label}
                                keyVal="100898"
                                tIndex="-1"
                                menu={departments}
                                handleChange={onDeptChange}
                            >
                            </DropDownMenu>
                        </div>
                    </div>
                </div>
                <div className="dash-widget-settings-wrap float-right ml-2"><a href="#" className="dash-mini-button" onClick={() => {
                    setAllEnquiries([]);
                    setSelectedDepartments({
                        ...selecteddepartment,
                        filterStatus: null
                    })
                    gridRef.setGridOption("quickFilterText", '');
                    setDateRange(
                        {
                            startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').toDate()),
                            endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').toDate()),
                            filterStartDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').toDate()),
                            filterEndDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').toDate()),
                            startMonth: moment().toDate(),
                            date: moment().toDate(),
                            year: moment().format('YYYY'),
                            month: moment().format('MMM')
                        }
                    )
                }}> <i className="ico icon-refresh"></i></a></div>
                <div className='float-right ant-border-fix'>
                    <AntMonthPicker
                        value={dateRange.date}
                        name={'deallog-month'}
                        onChange={(val) => {
                            if (val) {
                                setAllEnquiries([])
                                setDateRange({
                                    year: moment(val).format('YYYY'),
                                    month: moment(val).format('MMM'),
                                    date: val,
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('month').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('month').toDate()),
                                    filterStartDate: window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('month').toDate()),
                                    filterEndDate: window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('month').toDate())
                                })
                                setSelectedDepartments({
                                    ...selecteddepartment,
                                    filterStatus: null
                                })
                            }

                        }}
                        placeholder={'select month'}
                        className={`form-control cursor-pointer`}
                        format={'MMM YYYY'}
                        picker={'month'}
                        removeClear={true}
                    />
                </div>

            </div>
            <div className="dynamic-module-count-wrapper mt-2">
                {
                    revenueTargets.map((rec, index) => {
                        return <RevenueTarget
                            key={index}
                            data={rec}
                            carryoverLeads={carryoverLeads}
                            wonLeads={wonLeads}
                            deliveredLeads={deliveredLeads}
                            coveredLeads={coveredLeads}
                            currencySymbol={currencySymbol}
                            remainingLeads={remainingLeads}
                            reportableLeads={reportableLeads}
                            reportedLeads={reportedLeads}
                            targetClick={(sts) => {
                                gridRef.setFilterModel(null);
                                setGridRefresh(true)
                                setSelectedDepartments({
                                    ...selecteddepartment,
                                    filterStatus: sts
                                })
                                setDataRefresh(true)
                            }}
                            target={selecteddepartment?.['targets']?.[dateRange.year]?.[dateRange.month]?.[rec.value]}
                        />
                    })
                }

            </div>
            <div className="dynamic-module-list-table deallog-main-list">
                <div className="common-table">
                    <GridView
                        {...windowSize}
                        dealersettings={props.dealersettings}
                        dynamicCols={dynamicCols}
                        onGridValueChange={onGridValueChange}
                        saveSettings={saveSettings}
                        currencySymbol={currencySymbol}
                        currentSettings={{
                            ...currentSettings,
                            ...deallogSettings,
                            dLSortFields: dLSortFields,
                            propsSettings: props,
                            selecteddepartment: selecteddepartment
                        }}
                        rowData={enquiries}
                        loader={loader}
                        sideBar={true}
                        dateFormat={deallogSettings?.dateFormat}
                        onContextMenuClick={onContextMenuClick}
                        onCellClicked={onCellClicked}
                        onMsgIconClicked={onMsgIconClicked}
                        excelfileName={'Orders'}
                        tagColors={tagColors}
                        minCellWidth={120}
                        fieldComments={true}
                        changeLogs={true}
                        setgridRef={(api) => setgridRef(api)}
                        addDynColumns={addDynColumns}
                    />
                </div>
            </div>
        </div>
        {
            selecteddepartment?.filterStatus || searchText ? (
                <>
                    {
                        selecteddepartment?.filterStatus ? (
                            <span className="custom-deallog-toaster-top">
                                <div className="custom-deallog-toaster-message" style={{ opacity: 1 }}>
                                    <div className="custom-deallog-toaster-alert">
                                        <div className="flex-deallog-toast-custom">
                                            <div className="custom-deallog-toast-icon">
                                                <img src={images.filtergreen} height="25" /> </div><div className="custom-deallog-toast-data">
                                                <div className="custom-deallog-toast-item-title">Filter Applied</div>
                                            </div>
                                            <button className="custom-deallog-toaster-alert-close" onClick={() => {
                                                setGridRefresh(true)
                                                setSelectedDepartments({
                                                    ...selecteddepartment,
                                                    filterStatus: null,
                                                })
                                                setDataRefresh(true)
                                            }}><span aria-hidden="true">×</span></button>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        ) : (
                            <span className="custom-deallog-extended-top">
                                <div className="custom-deallog-extended-message" style={{ opacity: 1 }}>
                                    <div className="custom-deallog-extended-alert">
                                        <div className="flex-deallog-extended-custom">
                                            <div className="custom-deallog-extended-icon">
                                                <img src={images.searchwhite} height="25" />
                                            </div>
                                            <div className="custom-deallog-extended-data" onClick={() => {
                                                unSubAll();
                                                const newDate = moment(dateRange.startMonth).toDate();
                                                setDateRange(
                                                    {
                                                        ...dateRange,
                                                        startMonth: moment(newDate).add(-3, 'month').toDate(),
                                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(newDate).add(-3, 'month').startOf('month').toDate()),
                                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(newDate).add(-1, 'month').endOf('month').toDate())
                                                    }
                                                )
                                            }}>
                                                <div className="custom-deallog-extended-item-title"> <b>Data Not Found?</b> Extend Search</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </span>
                        )
                    }
                </>) : (<></>)
        }
        <PopUpModal show={changelogmodal.show}>
            <ChangeLogs
                {...props}
                {...changelogmodal}
                handleClose={() => {
                    setchangelogmodal({ show: false })
                }}
                dealersettings={currentSettings}
                deallogSettings={deallogSettings}
                currencySymbol={currencySymbol}
            />
        </PopUpModal>
        <PopUpModal show={notesmodal.show}>
            <FieldComments
                {...notesmodal}
                dealersettings={currentSettings}
                handleClose={() => {
                    setnotesmodal({ show: false })
                }}

            />
        </PopUpModal>
        <PopUpModal show={colormodal.show}>
            <CellColorSettings
                {...colormodal}
                dealersettings={props.dealersettings}
                deallogSettings={deallogSettings}
                handleClose={(data) => {
                    setcolormodal({ show: false })
                    if (data) {
                        setdeallogSettings({
                            ...deallogSettings,
                            fieldColors: {
                                ...(deallogSettings?.fieldColors || {}),
                                ...data
                            }
                        })
                    }
                }}

            />
        </PopUpModal>
        <PopUpModal show={showDynamicOptionModel.show}>
            <AddDynamicOptions
                {...showDynamicOptionModel}
                dealersettings={props.dealersettings}
                handleClose={(data) => {
                    if (data) {
                        saveCurrentSettings(data, showDynamicOptionModel?.columnProps)
                    }
                    setshowDynamicOptionModel({ show: false })
                }}
                activeLookup={'enquiryOptionsDF'}
                allLookups={currentSettings}
                selectedOEM={{ id: props.dealersettings.oemID, name: props.dealersettings.oemName }}
                selectedLevel={{ level: 'oem', levelID: props.dealersettings.oemID }}
                module={'salesHub'}
                dynamicCols={dynamicCols?.map(r => { return { label: r.name, value: r.value, dataType: r.dataType, formula: r.formula } })}
            />
        </PopUpModal>
        {/* <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
            <StageConsumer>
                {() => ( */}
        <RouteConsumer>
            {({ handleRouteRemove, resetnavbar, handleRouteChange, updatenavbar }) => (
                <SidePanelDetailView
                    clsActive={showpanel.clsActive}
                    clsName={showpanel.clsName}
                    sidepanelClose={sidepanelClose}
                    title=''
                >
                    <EnquiryInfo
                        enquiryid={showpanel.enquiryid}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        currentClient={currentClient}
                        resetnavbar={resetnavbar}
                        history={history}
                        updatenavbar={updatenavbar}
                        handleRouteRemove={handleRouteRemove}
                        updateDealerSettings={updateDealerSettings}
                        clientModule={clientModule}
                        dialNumber={dialNumber}
                        dialRingcentral={dialRingcentral}
                        handleRouteChange={handleRouteChange}
                    />
                    {/* <QuickView {...props}
                            enquiryid={showpanel.enquiryid}
                            isFilterApplied={true}
                            updateListEnquiry={updateListEnquiry}
                            sidepanelClose={sidepanelClose}
                        /> */}
                </SidePanelDetailView>
            )}
        </RouteConsumer>
        {/* )}
            </StageConsumer>
        </StageProvider> */}
    </div >
    )
}

export default DealsLog;