import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'toasted-notes'
import _ from 'lodash'
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { widgetSettingsNames } from './viewModel';

const WidgetSettings = ({ show, handleClose, title, sortedWidgets, hiddenWidgets, removeUnAllowedWidgets, layout, setLayout, onLayoutChange }) => {
	const [localSortedWidgets, setLocalSortedWidgets] = useState(sortedWidgets);
	const [localHiddenWidgets, setLocalHiddenWidgets] = useState(hiddenWidgets);
	const [saveLoader, setSaveLoader] = useState(false);


	const handleAdd = (value) => {
		setLocalHiddenWidgets(localHiddenWidgets.filter(a => a !== value))
		setLocalSortedWidgets([value, ...localSortedWidgets])
	}

	const handleRemove = (value) => {
		setLocalSortedWidgets(localSortedWidgets.filter(a => a !== value))
		setLocalHiddenWidgets([value, ...localHiddenWidgets])
	}

	const onSave = async () => {
		setSaveLoader(true)
		const dashboardSettingsRef = window.firebase.firestore().doc(`users/${localStorage.uid}/bdcDashboardSettings/${localStorage.uid}`)
		handleClose()
		await dashboardSettingsRef.set({
			hiddenWidgets: localHiddenWidgets,
			sortedWidgets: localSortedWidgets,
		}, { merge: true })

		onLayoutChange(layout, localSortedWidgets, localHiddenWidgets)

		toast.notify('Dashboard Settings updated successfully', {
			duration: 2000
		})
		setSaveLoader(false)
	}

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (
			result.destination.droppableId === result.source.droppableId
		) {
			return;
		}
		if (result.destination.droppableId === 'active-widget') {
			setLocalHiddenWidgets(localHiddenWidgets.filter(a => a !== result.draggableId))
			setLocalSortedWidgets([result.draggableId, ...localSortedWidgets])
		}
		else {
			setLocalSortedWidgets(localSortedWidgets.filter(a => a !== result.draggableId))
			setLocalHiddenWidgets([result.draggableId, ...localHiddenWidgets])
		}

	}

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				enforceFocus={false}
				dialogClassName='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dash-widget-setings'
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='modal-title'>
							<Translate text={title} />
						</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<div className='dashsetings-wrap'>
								<div className='dashsettings-column-wraper'>
									<DragDropContext
										onDragEnd={onDragEnd}
									>
										{
											<div className='dashsettings-columns'>
												<Droppable droppableId="add-widget">
													{(provided, snapshot) => (
														<div  {...provided.droppableProps}
															ref={provided.innerRef}
															className='dashsettings-column-left' style={{ width: '50%' }}>
															<div className='dashsettings-column-title'>
																<h3>Add Widgets</h3>
															</div>

															<div className='dash-add-widget'>
																{!_.isEmpty(localHiddenWidgets.filter(a => removeUnAllowedWidgets(a))) ? localHiddenWidgets.filter(a => removeUnAllowedWidgets(a)).map((a, i) =>
																	<Draggable key={a} draggableId={a} index={i}>
																		{(provided, snapshot) => (
																			<div ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				className='widget-rearrange-box widget-flex-queue widget-inactive'>
																				<div className='widget-rearrange-detail'>
																					<h3>{widgetSettingsNames.filter(rec => rec.value === a).length ? widgetSettingsNames.filter(rec => rec.value === a)[0].name : a}</h3>
																				</div>
																				<button
																					type='button'
																					className='btn btn-primary float-right'
																					onClick={() => handleAdd(a)}
																				>
																					{' '}
																					Add<div></div>
																					<div></div>
																				</button>
																				<span
																					style={{
																						display: "none"
																					}}
																				>
																					{provided.placeholder}
																				</span>
																			</div>
																		)}
																	</Draggable>) : (
																	<>
																		<div
																			className='spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente'
																			style={{ minHeight: '360px' }}
																		>
																			<div className='no-data-flex h-100 justify-content-center align-items-center '>
																				<div className='no-data-img'>
																					{' '}
																					<img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
																				</div>
																				<div className='no-data-txt mt-2'>
																					{' '}
																					<Translate
																						text={'No hidden widgets.'}
																					/>
																				</div>
																			</div>
																		</div>
																	</>
																)}
															</div>
															<span
																style={{
																	display: "none"
																}}
															>
																{provided.placeholder}
															</span>
														</div>
													)}
												</Droppable>
												<Droppable droppableId="active-widget">
													{(provided, snapshot) => (
														<div  {...provided.droppableProps}
															ref={provided.innerRef}
															className='dashsettings-column-right-large' style={{ width: '50%' }}>
															<div className='dashsettings-column-title'>
																<h3>Active Widgets</h3>
															</div>
															<div className='dash-add-widget'>
																{!_.isEmpty(localSortedWidgets.filter(a => removeUnAllowedWidgets(a))) ? localSortedWidgets.filter(a => removeUnAllowedWidgets(a)).map((a, i) =>
																	<Draggable key={a} draggableId={a} index={i}>
																		{(provided, snapshot) => (
																			<div
																				ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				className='widget-rearrange-box widget-flex-queue widget-active'>
																				<div className='widget-rearrange-detail'>
																					<h3>{widgetSettingsNames.filter(rec => rec.value === a).length ? widgetSettingsNames.filter(rec => rec.value === a)[0].name : a}</h3>
																				</div>
																				<button
																					type='button'
																					className='btn btn-common float-right'
																					onClick={() => handleRemove(a)}
																				>
																					{' '}
																					Remove<div></div>
																					<div></div>
																				</button>
																				<span
																					style={{
																						display: "none"
																					}}
																				>
																					{provided.placeholder}
																				</span>
																			</div>
																		)}
																	</Draggable>) : (
																	<>
																		<div
																			className='spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente'
																			style={{ minHeight: '360px' }}
																		>
																			<div className='no-data-flex h-100 justify-content-center align-items-center '>
																				<div className='no-data-img'>
																					{' '}
																					<img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
																				</div>
																				<div className='no-data-txt mt-2'>
																					{' '}
																					<Translate
																						text={'No visible widgets, please add widgets from adjacent list.'}
																					/>
																				</div>
																			</div>
																		</div>
																	</>
																)}
															</div>
															<span
																style={{
																	display: "none"
																}}
															>
																{provided.placeholder}
															</span>
														</div>)}
												</Droppable>
											</div>
										}
									</DragDropContext>
								</div>
							</div>
						</div>

					</div>
				</Modal.Body>
				<Modal.Footer className='modal-footer'>
					<button
						type='button'
						className='btn btn-primary float-right ml-2 '
						aria-label='Save'
						onClick={onSave}
					>
						{
							saveLoader ?
								(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
								: (<></>)
						}
						<Translate text={'save'} />
					</button>
					<button
						type='button'
						className='btn btn-default float-right ml-2 '
						aria-label='Close'
						onClick={handleClose}
					>
						{' '}
						<Translate text={'Close'} />
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default WidgetSettings;
