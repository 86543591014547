import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment'
import _images from '../../../../images'
import commonHelper from '../../../../services/common'

const OEMUserReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [roles, setRoles] = useState({});
    const [regions, setRegions] = useState({});
    const [clients, setClients] = useState({});
    const [users, setUsers] = useState({});
    const [oemSettings, setOEMSettings] = useState({});
    const [reportData, setReportData] = useState([]);
    const [timezone, setTimezone] = useState();
    const [brands, setBrands] = useState();

    useEffect(() => {
        window.firebase.firestore().doc(`clientSettings/${props.oemid}`)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let _timezone = doc.data().timezone ? doc.data().timezone : 'Australia/Sydney';
                    setTimezone(_timezone);
                    setOEMSettings({
                        ...doc.data(),
                        id: doc.id
                    });
                }
            })
    }, [])

    useEffect(() => {
        window.firebase.firestore().collection(`clientSettings/${props.oemid}/regions`)
            .get()
            .then((doc) => {
                let regions = [];
                doc.forEach((rec) => {
                    regions.push({
                        ...rec.data(),
                        id: rec.id
                    })
                })
                setRegions(regions)
            })
    }, [])

    useEffect(() => {
        window.firebase.firestore().collection(`clientSettings/${props.oemid}/roles`)
            .get()
            .then((doc) => {
                let regions = [];
                doc.forEach((rec) => {
                    regions.push({
                        ...rec.data(),
                        id: rec.id
                    })
                })
                setRoles(regions)
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(regions) || _.isEmpty(roles))
            return;
        window.firebase.firestore().collection(`clients`)
            .where('settingsID', '==', oemSettings.id)
            .where('isActive', '==', true)
            .get()
            .then(async (snap) => {
                let _clients = [];
                snap.docs.forEach((doc, index) => {
                    _clients.push({
                        ...doc.data(),
                        id: doc.id,
                        regionName: _.find(regions, { id: doc.region })?.name,
                        sortOrder: doc.data().sortOrder && doc.data().sortOrder > 0 ? doc.data().sortOrder : (index + 1)
                    })
                })
                setClients(_.orderBy(_clients, ['sortOrder'], ['asc']))
            })
    }, [regions, roles])

    useEffect(() => {
        if (_.isEmpty(clients))
            return

        window.firebase.firestore().collection('users')
            .where('settingsID', '==', oemSettings.id)
            .get()
            .then((docSnapshot) => {
                let _users = [];
                docSnapshot.docs.forEach(doc => {
                    const _userdoc = doc.data();
                    if (!_.isEmpty(_userdoc.settingsID) && !Boolean(_userdoc.superAdmin)
                        && (_.isEmpty(_.find(roles, { id: _userdoc.roleID })) || !Boolean(_.find(roles, { id: _userdoc.roleID })?.admin?.oemDashboard)))// && _userdoc.isHidden !== true)
                        _users.push({
                            ..._userdoc,
                            'userName': <div className="table-user-list">
                                <div className="table-user-avatar">
                                    <img className="rounded-circle img-object-fit" alt="" src={commonHelper.showUserAvatar(_userdoc.profileImage, _userdoc.name)} /></div>
                                {_userdoc.name}
                            </div>,
                            'documentID': doc.id,
                            'id': doc.id,
                            'active': !Boolean(_userdoc.isDisabled),
                            'addedOn': _userdoc.addedDate ? moment.unix(_userdoc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--',
                            'updatedOn': _userdoc.modifedDate ? moment.unix(_userdoc.modifedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--'
                        })
                });
                _users = _.orderBy(_users, 'name', 'asc')
                setUsers(_users)
                let _reportData = []
                clients.forEach(clinet => {
                    _reportData.push({
                        clientName: clinet.name,
                        clientID: clinet.id,
                        regionName: clinet.regionName,
                        regionID: clinet.region,
                        activeUsers: _users.filter(a => a.arrayClientID.includes(clinet.id) && a.active).map(rec => {
                            return {
                                ...rec,
                                status: <div className="badge badge-pill badge-active">ACTIVE</div>
                            }
                        }),
                        inactiveUsers: _users.filter(a => a.arrayClientID.includes(clinet.id) && !a.active).map(rec => {
                            return {
                                ...rec,
                                status: <div className="badge badge-pill badge-inactive">IN-ACTIVE</div>
                            }
                        }),
                    })
                });
                setReportData(_.chain(_reportData)
                    .groupBy("regionID")
                    .map((value, key) => ({
                        key: key,
                        regionName: _.find(regions, { id: key })?.name,
                        clients: _.chain(value).groupBy("clientID").map((val) => val).value()
                    }))
                    .value())
                setLoader(false)
            });

    }, [clients])

    return loader ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="report-loader spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        oemSettings && oemSettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={oemSettings.logoURL ? oemSettings.logoURL : ''} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(oemSettings) ? oemSettings.name : '--'}</div>
                                            </>
                                    }
                                </div></td>

                                <td width="40%"><div className="header-right"> {`USER MANAGEMENT`} <br></br>
                                    <span> {moment().tz(timezone).format('DD MMMM YYYY')}</span> </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="summary-table-wraper">
                    <div className="clear"></div>
                    <div className="user-management-outer">
                        <div className="user-management-left">
                            <span className="overview-left-count">Active Users</span>
                            <span className="overview-right-count">{users.filter(a => a.active).length}</span>
                        </div>
                        <div className="user-management-right">
                            <span className="overview-left-count">Inactive Users</span>
                            <span className="overview-right-count">{users.filter(a => !a.active).length}</span>
                        </div>
                    </div>
                    {
                        reportData.map((region, i) => {
                            return (<Fragment key={i}>
                                <h2 className="user-management-table-sub-title">{region.regionName}</h2>
                                {
                                    region.clients.map((client, j) => {
                                        return <Fragment key={j}>
                                            <div className="user-management-table-wrapper mb-4">
                                                <h3 className="tableHead">{client[0].clientName}</h3>
                                                <div className="user-management-counts-table">
                                                    <h3 className="table-head mb-2 mt-2 pt-0">Active Users {client[0].activeUsers.length > 0 ? `(${client[0].activeUsers.length})` : ''}</h3>
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <th className="cell-head td-head" width="25%">User</th>
                                                                <th className="cell-head td-head" width="25%">Email</th>
                                                                <th className="cell-head td-head" width="20%">Role</th>
                                                                <th className="cell-head td-head" width="15%">Status</th>
                                                                <th className="cell-head td-head" width="15%">Added On</th>
                                                            </tr>
                                                            {
                                                                client[0].activeUsers.length > 0 ? (<>
                                                                    {
                                                                        client[0].activeUsers.map((user, k) => {
                                                                            return <tr key={k}>
                                                                                <td align={'left'}>{user.userName}</td>
                                                                                <td align={'left'}>{user.email}</td>
                                                                                <td align={'left'}>{user.role}</td>
                                                                                <td align={'left'}>{user.status}</td>
                                                                                <td align={'left'}>{user.addedOn}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </>) : (
                                                                    <tr>
                                                                        <td colSpan="13">
                                                                            <div className="spinner-loader h-100 p-5">
                                                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                                                    <div className="no-data-txt mt-2"> No data</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <h3 className="table-head mb-2 mt-2">In-Active Users {client[0].inactiveUsers.length > 0 ? `(${client[0].inactiveUsers.length})` : ''}</h3>
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <th className="cell-head td-head" width="25%">User</th>
                                                                <th className="cell-head td-head" width="25%">Email</th>
                                                                <th className="cell-head td-head" width="20%">Role</th>
                                                                <th className="cell-head td-head" width="15%">Status</th>
                                                                <th className="cell-head td-head" width="15%">Added On</th>
                                                            </tr>
                                                            {
                                                                client[0].inactiveUsers.length > 0 ? (<>
                                                                    {
                                                                        client[0].inactiveUsers.map((user, k) => {
                                                                            return <tr key={k}>
                                                                                <td align={'left'}>{user.userName}</td>
                                                                                <td align={'left'}>{user.email}</td>
                                                                                <td align={'left'}>{user.role}</td>
                                                                                <td align={'left'}>{user.status}</td>
                                                                                <td align={'left'}>{user.addedOn}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </>) : (
                                                                    <tr>
                                                                        <td colSpan="5">
                                                                            <div className="spinner-loader h-100 p-5">
                                                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                                                    <div className="no-data-txt mt-2"> No data</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div >
                                        </Fragment >
                                    })
                                }
                            </Fragment >)
                        })
                    }

                </div >
                <div className="footer">
                    <div className="footer-bottom">
                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {oemSettings.address ? <>{oemSettings.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {oemSettings.phone ? <>Tel: <span style={{ paddingLeft: 0 }} >{oemSettings.phone}</span></> : <></>}
                                {oemSettings.website ? <>Web: <span style={{ paddingLeft: 0 }} >{oemSettings.website}</span></> : <></>}
                            </span>
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>
                        <div className="footer-right"></div>
                    </div>
                    <div className="clear"></div>
                </div>
            </section >
        </div >
    )
}

export default OEMUserReport;