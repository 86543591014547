/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment'
import _ from 'lodash'
import { default as _images } from '../../../images';
import { ContentOverlay, PopUpModal } from '../../../components'
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';
import { Modal } from "react-bootstrap";

const ImpelReducer = (state, action) => {
    function updateClient() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateClient()
        }
        default:
            return state;
    }
};

const ImpelLogs = (props) => {
    const [impelLogs, dispatch] = useReducer(ImpelReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [logLoader, setLoader] = useState(true)

    const [showLink, setShowLink] = useState({ show: false, link: null, title: null })
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;

    useEffect(() => {
        return () => {
            window.unSubscribeImpelLogs && window.unSubscribeImpelLogs();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        let refCollectionData;

        if (props.enquiry?.inboundLeadID) {
            if (props.tag === 'impel') {
                refCollectionData = window.firebase.firestore().collection(`impelLogs`)
                    .where('inboundID', '==', props.enquiry.inboundLeadID)
                    .where('type', '==', 'createnote')
            } else {
                refCollectionData = window.firebase.firestore().collection(`aiLogs`)
                    .where('leadID', '==', props.enquiry.documentID)
                    .where('systemType', '==', props.tag)
            }

            //.orderBy('modifiedDate', 'desc')

            window.unSubscribeImpelLogs = refCollectionData
                .onSnapshot(onCollectionUpdate);
        }
    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                if (change.data()?.messageDetail || change.data()?.notes) {
                    snapshotDoc = dataMappingVM({
                        ...change.doc.data(),
                        documentID: change.doc.id
                    });
                    if (change.type === 'added') {
                        actionType = "ADD_LIST";
                    }
                    else if (change.type === 'modified') {
                        actionType = "UPDATE_LIST"
                    }
                    else if (change.type === 'removed') {
                        actionType = "REMOVE_LIST"
                    }
                }
            }
        })
        let _list = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                if (doc.data()?.messageDetail || doc.data()?.notes) {
                    var logVM = dataMappingVM({
                        ...doc.data(),
                        documentID: doc.id
                    });
                    _list.push(logVM)
                }
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : _.orderBy(_list, ["sentDate"], ["asc"])
        });
        setDataload(false)
        setLoader(false)
    }


    const dataMappingVM = (doc) => {
        const logVM = Object.assign({}, doc);

        if (!_.isEmpty(logVM.messageDetail)) {
            let messageDetail = logVM.messageDetail
            logVM.contactType = messageDetail.contactType;
            logVM.messageBody = messageDetail.messageBody;
            logVM.sentBy = messageDetail.sentBy;
            logVM.sentDate = messageDetail.sentDate;

            logVM.linkStopCommunication = messageDetail.linkStopCommunication;
            logVM.linkReplyAsAssistant = messageDetail.linkReplyAsAssistant;

        } else {
            logVM.contactType = 'SMS';
            logVM.messageBody = doc.notes;
            logVM.sentBy = doc.source === 'customer' ? 'client' : doc.source;
            logVM.sentDate = doc.sentDate || moment(doc.logDate.seconds).utc().toISOString();

            logVM.linkStopCommunication = doc.linkStop;
            logVM.linkReplyAsAssistant = doc.linkReply;

        }

        if (logVM.sentBy === 'client') {
            logVM.name = CommonHelper.displayFullContact(null, props.enquiry?.contact ? props.enquiry?.contact : props.enquiry?.engageNewContact, 'Customer');
        }
        else if (logVM.sentBy === 'salesperson') logVM.name = 'Dealer Representative'
        else logVM.name = 'Assistant'

        return logVM
    }


    const dateFormat = (_date) => {
        // if (moment(_date).year() === moment().year())
        //     return moment(_date).format('DD MMM, hh:mm A')
        // else
        return moment(_date).format('DD MMM YYYY, hh:mm A')
    }

    let linkStopCommunication = !_.isEmpty(impelLogs) ? _.orderBy(impelLogs.filter(e => e.linkStopCommunication), ["sentDate"], ["desc"])[0]?.linkStopCommunication : null
    let linkReplyAsAssistant = !_.isEmpty(impelLogs) ? _.orderBy(impelLogs.filter(e => e.linkReplyAsAssistant), ["sentDate"], ["desc"])[0]?.linkReplyAsAssistant : null

    const divStyle = {
        height: '700px',
        overflow: 'auto',
        position: "relative",
        paddingRight: '10px',
        marginTop: '10px'
    };

    return (
        <>
            <div className={`ai-conversation-wrap ${props.paddingSet ? 'pt-0 pl-0' : ''}`}>
                {
                    (!_.isEmpty(linkStopCommunication) || !_.isEmpty(linkReplyAsAssistant)) && (<div className={`ai-conversation-action-btn ${props.paddingSet ? 'pb-2' : ''}`}>

                        {!_.isEmpty(linkReplyAsAssistant) && (<><button className="btn btn-sm btn-secondary" onClick={() => {

                            setShowLink({ show: true, link: linkReplyAsAssistant, title: 'Reply as Assistant' });
                        }}><i className="ico icon-chat mr-1"></i> Reply as Assistant </button></>)}

                        {!_.isEmpty(linkStopCommunication) && (<button className="btn btn-sm btn-red ml-2" onClick={() => {

                            setShowLink({ show: true, link: linkStopCommunication, title: 'Stop Assistant' });
                        }}> Stop Assistant </button>)}
                    </div>)
                }

                {/* <div className="ai-conversation-info">
    <div className="ai-conversation-sub">

        <div>
            <div className="ai-conversation-user">Nelson Holter</div>
            <div className="ai-conversation-number">JF2AC53B3GE202643</div>
        </div>

    </div>
    <div className="ai-conversation-select form-style">
        <div className="label-channel">Channel</div>
        <div><input type="text" className="form-control" id=""/></div>
    </div>
</div> */}

                <div style={props.paddingSet ? {} : divStyle}>

                    <div className={`ai-conversation-chat ${props.paddingSet ? 'mt-0' : ''}`}>

                        {
                            logLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                !_.isEmpty(impelLogs) && impelLogs.length > 0 ?
                                    (
                                        <>
                                            {
                                                impelLogs.map((log, index) => {
                                                    return (<div key={index} className={log.sentBy === 'client' ? 'ai-chat-user-wrap' : 'ai-chat-bot-wrap'}>
                                                        {
                                                            log.sentBy === 'client'
                                                                ?
                                                                <div className="ai-chat-user-item">
                                                                    <div>
                                                                        <div className="ai-chat-user-icon ai-chat-green-icon">
                                                                            {_.lowerCase(log.contactType) === 'email' ? <i className="ico icon-Mail"></i> : <i className="ico icon-sms"></i>}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p className="ai-chat-user-status">
                                                                            <span className="ai-chat-green-text">{log.name}</span>
                                                                            {log.sentDate ? <span>{dateFormat(log.sentDate)}</span> : <></>}
                                                                        </p>
                                                                        <div className="ai-chat-user-block ai-chat-green-bg">
                                                                            <p>{log.messageBody}</p>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                                :
                                                                <div className="ai-chat-bot-item">

                                                                    <div>
                                                                        <p className="ai-chat-bot-status">
                                                                            <span className="ai-chat-blue-text">
                                                                                {log.name === 'Assistant' && (<img src={_images.a_i} height="18" className="mr-1" />)}
                                                                                {log.name}</span>
                                                                            {log.sentDate ? <span>Sent on {dateFormat(log.sentDate)}</span> : <></>}

                                                                        </p>
                                                                        <div className="ai-chat-bot-block ai-chat-blue-bg">
                                                                            <p>{log.messageBody}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div className="ai-chat-bot-icon ai-chat-blue-icon">
                                                                            {_.lowerCase(log.contactType) === 'email' ? <i className="ico icon-Mail"></i> : <i className="ico icon-sms"></i>}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                        }
                                                    </div>)
                                                })
                                            }

                                        </>

                                    )
                                    : (
                                        <div className="common-table">
                                            <div className="text-center p-5">
                                                <p><Translate text={'No Logs'} /></p>
                                                {/* {CommonHelper.showLocale(props, 'No record found.')} */}
                                            </div>
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </div>

            <PopUpModal show={showLink.show}>
                {
                    showLink.show && (<Modal
                        show={showLink.show}
                        onHide={() => { setShowLink({ show: false, link: null, title: null }) }}
                        backdrop="static"
                        backdropClassName={
                            showLink.show ? "overlay-modal active" : ""
                        }
                        dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-add-requirement"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{showLink.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0 pt-0">
                            <iframe width="100%" height="700px" src={`${showLink.link}`} frameBorder="0" />
                        </Modal.Body>
                    </Modal>)
                }

            </PopUpModal>

        </>
    );
}

export default ImpelLogs;
