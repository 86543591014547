import React from 'react';
import { default as _images } from "../images";
import Translate from '../constants/translate';

class ErrorBoundary extends React.Component {
	constructor() {
		super();

		this.state = {
			hasErrored: false,
		};
	}

	static getDerivedStateFromError(error) {
		// process the error
		return { hasErrored: true };
	}

	componentDidCatch(error, info) {
		console.error(error.message);
		console.error(info);

		const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
		const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
		ref.set({
			addedDate: window.firebase.firestore.Timestamp.now(),
			addedBy: localStorage.uid,
			clientID: this.props.dealersettings.client.id,
			oemID: this.props.dealersettings.client.settingsID,
			error: error.message ? error.message : '',
			description: info.componentStack ? info.componentStack : '',
			currentPath: window.location.pathname,
		}).then(() => {
			console.log('Error has been uploaded')
		}).catch((error) => {
			console.error("Error adding log: ", error);
		});
	}

	render() {
		if (this.state.hasErrored) {
			return (
				<div
					className='middle-wrapper h-100 mt-0'
					style={{
						minHeight: '90vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div className='spinner-loader h-100'>
						<div className='no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary'>
							<div className='no-data-img-wrap'>
								<img src={_images[`error${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='350' alt='' />
							</div>
							<div className='no-data-txt-wrap'>
								<div className='no-data-content-head'>
									<Translate text={"Somethings wrong here"} />
								</div>
								<div className='no-data-content'>
									<Translate text={"Dont worry, click the button below and continue using AMS Pro"} />
								</div>
								<div className='no-data-content-head'>
									{
										localStorage.defaultModule === 'oem' ? (
											<a href='/oem/dashboard' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/oem/dashboard'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										) : localStorage.defaultModule === 'service' ? (
											<a href='/dashboard/service' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/dashboard/service'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										) : localStorage.defaultModule === 'afterSales' ? (
											<a href='/dashboard/afterSales' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/dashboard/afterSales'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										) : localStorage.defaultModule === 'tradeinPro' ? (
											<a href='/dashboard/appraisal' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/dashboard/appraisal'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										) : localStorage.defaultModule === 'wholesaler' ? (
											<a href='/wholesaler' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/wholesaler'
											}}>
												<Translate text={'Back to Appraisal'} />
											</a>
										) : localStorage.defaultModule?.includes('eventsPro') ? (
											<a href='/dashboard/events' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/dashboard/events'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										) : localStorage.defaultModule?.includes('fleetPro') ? (
											<a href='/dashboard/fleetpro' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/dashboard/fleetpro'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										) : localStorage.defaultModule?.includes('dealLog') ? (
											<a href='/deallog/orders' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/deallog/orders'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										) : (
											<a href='/dashboard' className='btn btn-primary' onClick={() => {
												window.location.pathname = '/dashboard'
											}}>
												<Translate text={'Back to Dashboard'} />
											</a>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
