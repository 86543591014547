/** LIBRARIES */
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../constants/translate';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import InputText from './inputText';
import AntDatePicker from './antDatePicker';
import ReactSelect from './reactSelect';
import ReactMultiSelect from './reactMultiSelect';
import NumberFormat from 'react-number-format'
import uuid from 'react-uuid';
import images from '../images';
import { SketchPicker } from 'react-color';
import CreatableReactSelect from './creatableSelect';

const CustomTable = props => {
    const state = props.subList || [];
    const [addSubList, setAddSubList] = useState({})
    const [editSubList, setEditSubList] = useState({})
    const [textEditor, setTexteditor] = useState({})
    const [errorFields, setErrorFields] = useState({})
    const [colorPicker, setColorPicker] = useState()
    const [customOptions, setCustomOptions] = useState([])

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = state.splice(result.source.index, 1);
        state.splice(result.destination.index, 0, removed);
        props.setSubList(state)
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const styles = {
        colorPicker: {
            width: '100%',
            height: '20px',
            borderRadius: '2px',
        },
        swatch: {
            padding: '5px',
            background: '#FFFFFF',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            width: '95%',
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    };

    const handleSublistSave = (rec, newlist, index) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        props.cols.filter(a => a.mandatory).map(r => { return r.value }).forEach(key => {
            if (_.isEmpty(rec?.[key]?.toString())) {
                formIsValid = false;
                errors[`${index >= 0 ? 'edit' : ''}${key}`] = errorClass;
            }
        });
        setErrorFields(errors)
        // if (newlist && state.some(item => item === rec)) {
        //     Swal.fire('Options with same name already exists', '', 'error')
        //     formIsValid = false;
        // }
        if (!formIsValid) {
            return;
        }
        if (newlist && props.createUUID) {
            rec.id = uuid()
        }
        let addList = newlist ? [...state, rec] : [
            ..._.map(state, function (obj, i) {
                return i === index ? rec : obj;
            })];
        props.setSubList(addList)
        setAddSubList({})
        setEditSubList(null)
        setTexteditor({})

    }

    const handleOnSubChange = (e, edit) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            if (edit) {
                setEditSubList({
                    ...editSubList,
                    [name]: newarray1.join(' ')
                });

            } else {
                setAddSubList({
                    ...addSubList,
                    [name]: newarray1.join(' ')
                });
            }
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            if (edit) {
                setEditSubList({
                    ...editSubList,
                    [name]: str
                });

            } else {
                setAddSubList({
                    ...addSubList,
                    [name]: str
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : ''}${name}`]: ''
        });
    }

    const handleOnChange = (e, edit) => {
        e.preventDefault()
        const { name, value } = e.target;

        if (edit) {
            setEditSubList({
                ...editSubList,
                [name]: value
            });

        } else {
            setAddSubList({
                ...addSubList,
                [name]: value
            });
        }
        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : ''}${name}`]: ''
        });
    }

    const onCurrencyChange = (e, name, edit, formulaCols, destCol) => {
        let _value = e.floatValue
        // console.log('onAmountChange value ', _value)
        const rec = edit ? editSubList : addSubList;
        if (formulaCols && destCol) {
            let val = _value;
            formulaCols.split('+').forEach(r => {
                val += rec?.[r] || 0
            })
            rec[destCol] = val;
        }

        if (_value === undefined) {
            _value = ''
        }
        if (edit) {
            setEditSubList({
                ...rec,
                [name]: _value
            });

        } else {
            setAddSubList({
                ...rec,
                [name]: _value
            });
        }

        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : ''}${name}`]: ''
        });
    }

    const handleReactSelectChange = (e, data, edit) => {
        if (edit) {
            setEditSubList({
                ...editSubList,
                [data.name]: e?.value || ''
            });

        } else {
            setAddSubList({
                ...addSubList,
                [data.name]: e?.value || ''
            });
        }

        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : ''}${data.name}`]: ''
        });
    }

    const handleCreate = (inputValue, name, edit) => {
        setCustomOptions([
            ...customOptions,
            { label: inputValue, value: inputValue }
        ])
        if (edit) {
            setEditSubList({
                ...editSubList,
                [name]: inputValue
            });

        } else {
            setAddSubList({
                ...addSubList,
                [name]: inputValue
            });
        }

        setErrorFields({
            ...errorFields,
            ['model']: ''
        });
    };

    const handleReactMultiSelectChange = (selectedOptions, name, edit) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        if (edit) {
            setEditSubList({
                ...editSubList,
                [name]: value
            });

        } else {
            setAddSubList({
                ...addSubList,
                [name]: value
            });
        }

        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : name}`]: ''
        });
    }

    const handleColorPickerChange = (color, name, edit) => {

        if (edit) {
            setEditSubList({
                ...editSubList,
                [name]: color.hex
            });

        } else {
            setAddSubList({
                ...addSubList,
                [name]: color.hex
            });
        }

        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : name}`]: ''
        });
    }

    const handleNumberChange = (e, edit, formulaCols, destCol) => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;
        const rec = edit ? editSubList : addSubList;
        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            if (formulaCols && destCol) {
                let val = Number(number);
                formulaCols.split('+').forEach(r => {
                    val += Number(rec?.[r] || 0)
                })
                rec[destCol] = val;
            }

            if (edit) {
                setEditSubList({
                    ...rec,
                    [name]: Number(number)
                });

            } else {
                setAddSubList({
                    ...rec,
                    [name]: Number(number)
                });
            }

            setErrorFields({
                ...errorFields,
                [`${edit ? 'edit' : name}`]: ''
            });
        }


    };


    const handleDateChange = (val, name, edit) => {
        if (edit) {
            setEditSubList({
                ...editSubList,
                [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d) : null
            });

        } else {
            setAddSubList({
                ...addSubList,
                [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d) : null
            });
        }
        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : name}`]: ''
        });

    };

    const handleCancelSubList = () => {
        setEditSubList({})
        setTexteditor({})
    }

    const handleEditSubList = (rec, index) => {
        setEditSubList(rec)
        setTexteditor({
            [index]: true
        })
    }
    const handleDeleteSubList = (rec, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                let deleteList = [];
                _.map(state, (e, i) => {
                    return index !== i ? deleteList.push(e) : false
                })
                props.setSubList(deleteList)
            }
        })
    }


    const getColValue = (data, col) => {
        let val = '';
        if (!data?.[col.value]) {
            return val;
        }
        if (col.type === 'text' || col.type === 'number') {
            val = data?.[col.value] || ''
        } else if (col.type === 'date') {
            val = data?.[col.value]?.seconds ? moment.unix(data?.[col.value]?.seconds).format('DD/MM/YYYY') : ''
        } else if (col.type === 'price') {
            val = data?.[col.value] ? <NumberFormat value={data?.[col.value]} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : ''
        } else if (col.type === 'select' || col.type === 'createselect') {
            val = _.find(props[col.fromFieldValue ? data[col.fromFieldValue] : col.value], { value: data[col.value] })?.label || data?.[col.value] || ''
        } else if (col.type === 'multi') {
            val = (<div className={'Vehicle-availability'}>{
                props[col.fromFieldValue ? data[col.fromFieldValue] : col.value]?.filter(e => data?.[col.value]?.includes(e.value)).map((rec, index) => {
                    return <div key={index}><span>{rec.label}</span></div>;
                })
            }</div>)
        } else if (col.type === 'colorpicker') {
            val = (<div
                style={styles.swatch}>
                <div
                    //className='custom-color-picker-form-template'
                    style={{
                        ...styles.colorPicker,
                        background: data?.[col.value]
                    }}
                />
            </div>)
        }
        return val;
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>

                <div className="common-table col-md-12 pl-0 pr-0">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                {
                                    props.cols.map((r, i) => {
                                        return <th key={i} scope="col" className="head-light" width={`${r.width}%`}> <Translate text={r.name} /></th>
                                    })
                                }
                                <th scope="col" className="head-light border-right-0" width="15%"> <Translate text={'Actions'} /></th>
                            </tr>
                            {

                                <tr>
                                    {
                                        props.cols.map((r, i) => {
                                            return <td key={i}>
                                                <div className="form-group mb-0">
                                                    {
                                                        r.type === 'text' ? (
                                                            <InputText
                                                                id={`${r.type}-${r.value}`}
                                                                autoComplete="off"
                                                                placeholder={r.placeholder || r.value}
                                                                className={`form-control ${errorFields[r.value]}`}
                                                                name={r.value}
                                                                onChange={r.numtxt ? handleOnChange : handleOnSubChange}
                                                                value={addSubList[r.value]}
                                                            />

                                                        ) : r.type === 'number' ? (
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={r.placeholder || r.value}
                                                                className={`form-control ${errorFields[r.value]}`}
                                                                name={r.value}
                                                                onChange={(e) => handleNumberChange(e, false, r.formulaCols, r.destCol)}
                                                                value={addSubList[r.value]}
                                                                id={`${r.type}-${r.value}`}
                                                                readOnly={r.readOnly}
                                                            />

                                                        ) : r.type === 'date' ? (
                                                            <AntDatePicker
                                                                value={addSubList[r.value] ? moment.unix(addSubList[r.value].seconds).format('YYYY-MM-DD') : null}
                                                                name={r.value}
                                                                onChange={(e) => { handleDateChange(e, r.value) }}
                                                                format='DD/MM/YYYY'
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${errorFields[r.value]}`}
                                                            />

                                                        ) : r.type === 'price' ? (
                                                            <NumberFormat
                                                                allowNegative={Boolean(r.allowNegative)}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                placeholder={currencySymbol}
                                                                className={`form-control ${errorFields[r.value]}`}
                                                                onValueChange={e => onCurrencyChange(e, r.value, false, r.formulaCols, r.destCol)}
                                                                value={addSubList[r.value] || ''}
                                                                readOnly={r.readOnly}
                                                            />
                                                        ) : r.type === 'select' ? (
                                                            <ReactSelect
                                                                options={_.isArray(props[r.fromFieldValue && addSubList[r.fromFieldValue] ? addSubList[r.fromFieldValue] : r.value]) ?
                                                                    props[r.fromFieldValue && addSubList[r.fromFieldValue] ? addSubList[r.fromFieldValue] : r.value].filter(a => r.hideAddedField ? !state.some(b => b[r.value] === a.value) : true) : []}
                                                                name={r.value}
                                                                placeholder={`select ${r.placeholder || r.value}`}
                                                                onChange={handleReactSelectChange}
                                                                value={addSubList[r.value]}
                                                                classNamePrefix={`${errorFields[r.value]} cursor-pointer basic-select`}
                                                                removeClearable={false}
                                                            >
                                                            </ReactSelect>
                                                        ) : r.type === 'multi' ? (
                                                            <ReactMultiSelect
                                                                options={props[r.fromFieldValue && addSubList[r.fromFieldValue] ? addSubList[r.fromFieldValue] : r.value] || []}
                                                                name={r.value}
                                                                placeholder={`select ${r.placeholder || r.value}`}
                                                                onChange={(option) => handleReactMultiSelectChange(option, r.value)}
                                                                value={addSubList[r.value] || []}
                                                                classNamePrefix={`${errorFields[r.value]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        ) : r.type === 'createselect' ? (
                                                            <CreatableReactSelect
                                                                options={[
                                                                    ...(_.isArray(props[r.fromFieldValue && addSubList[r.fromFieldValue] ? addSubList[r.fromFieldValue] : r.value]) ?
                                                                        props[r.fromFieldValue && addSubList[r.fromFieldValue] ? addSubList[r.fromFieldValue] : r.value].filter(a => r.hideAddedField ? !state.some(b => b[r.value] === a.value) : true) : []),
                                                                    ...customOptions
                                                                ]}
                                                                name={r.value}
                                                                placeholder={`select ${r.placeholder || r.value}`}
                                                                onChange={handleReactSelectChange}
                                                                value={addSubList[r.value]}
                                                                classNamePrefix={`${errorFields[r.value]} cursor-pointer basic-select`}
                                                                handleCreate={(txt) => handleCreate(txt, r.value)}
                                                            >
                                                            </CreatableReactSelect>
                                                        ) : r.type === 'colorpicker' ? (<>
                                                            <div
                                                                style={styles.swatch}
                                                                onClick={() => {
                                                                    setColorPicker({ new: true });
                                                                }}
                                                            >
                                                                <div
                                                                    //className='custom-color-picker-form-template'
                                                                    style={{
                                                                        ...styles.colorPicker,
                                                                        background: addSubList[r.value] || '#CCCCCC'
                                                                    }}
                                                                />
                                                            </div>
                                                            {colorPicker?.new ? (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            setColorPicker({});
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        color={addSubList[r.value] || '#000000'}
                                                                        onChange={color => handleColorPickerChange(color, r.value)}
                                                                    />
                                                                </div>
                                                            ) : null}

                                                        </>) : (<></>)
                                                    }
                                                </div>
                                            </td>
                                        })
                                    }
                                    <td className="border-right-0">
                                        <div className="button-height-fix">
                                            <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </thead>
                        <Droppable droppableId="droppableAdminOption">
                            {(provided, snapshot) => (
                                <tbody  {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {
                                        state.length > 0 ?
                                            state.map((rec, index) => {
                                                return <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={props.dragDisabled === true}>
                                                    {(provided1, snapshot1) => (
                                                        <tr
                                                            ref={provided1.innerRef}
                                                            {...provided1.draggableProps}
                                                            {...provided1.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot1.isDragging,
                                                                provided1.draggableProps.style
                                                            )}
                                                        //onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec, index) }}
                                                        >
                                                            {
                                                                props.cols.map((r, i) => {
                                                                    return <td key={i} style={{ width: `${r.dWidth}px` }}>
                                                                        {textEditor[index] ? (
                                                                            <>
                                                                                {
                                                                                    r.type === 'text' ? (
                                                                                        <InputText
                                                                                            id={`${r.type}-${r.value}`}
                                                                                            autoComplete="off"
                                                                                            placeholder={r.placeholder || r.value}
                                                                                            className={`form-control ${errorFields[r.value]}`}
                                                                                            name={r.value}
                                                                                            onChange={(e) => r.numtxt ? handleOnChange(e, true) : handleOnSubChange(e, true)}
                                                                                            value={editSubList[r.value]}
                                                                                        />

                                                                                    ) : r.type === 'number' ? (
                                                                                        <InputText
                                                                                            autoComplete="off"
                                                                                            placeholder={r.placeholder || r.value}
                                                                                            className={`form-control ${errorFields[r.value]}`}
                                                                                            name={r.value}
                                                                                            onChange={(e) => handleNumberChange(e, true, r.formulaCols, r.destCol)}
                                                                                            value={editSubList[r.value]}
                                                                                            id={`${r.type}-${r.value}`}
                                                                                            readOnly={r.readOnly}
                                                                                        />

                                                                                    ) : r.type === 'date' ? (
                                                                                        <AntDatePicker
                                                                                            value={editSubList[r.value] ? moment.unix(editSubList[r.value].seconds).format('YYYY-MM-DD') : null}
                                                                                            name={r.value}
                                                                                            onChange={(e) => { handleDateChange(e, r.value, true) }}
                                                                                            format='DD/MM/YYYY'
                                                                                            placeholder='DD/MM/YYYY'
                                                                                            className={`form-control ${errorFields[r.value]}`}
                                                                                        />

                                                                                    ) : r.type === 'price' ? (
                                                                                        <NumberFormat
                                                                                            allowNegative={Boolean(r.allowNegative)}
                                                                                            thousandSeparator={true}
                                                                                            decimalScale={2}
                                                                                            prefix={currencySymbol}
                                                                                            placeholder={currencySymbol}
                                                                                            className={`form-control ${errorFields[r.value]}`}
                                                                                            onValueChange={e => onCurrencyChange(e, r.value, true, r.formulaCols, r.destCol)}
                                                                                            value={editSubList[r.value] || ''}
                                                                                            readOnly={r.readOnly}
                                                                                        />
                                                                                    ) : r.type === 'select' ? (
                                                                                        <ReactSelect
                                                                                            options={props[r.fromFieldValue && editSubList[r.fromFieldValue] ? editSubList[r.fromFieldValue] : r.value] || []}
                                                                                            name={r.value}
                                                                                            placeholder={`select ${r.placeholder || r.value}`}
                                                                                            onChange={(e, data) => handleReactSelectChange(e, data, true)}
                                                                                            value={editSubList[r.value]}
                                                                                            classNamePrefix={`${errorFields[r.value]} cursor-pointer basic-select`}
                                                                                            removeClearable={false}
                                                                                        >
                                                                                        </ReactSelect>
                                                                                    ) : r.type === 'multi' ? (
                                                                                        <ReactMultiSelect
                                                                                            options={props[r.fromFieldValue && editSubList[r.fromFieldValue] ? editSubList[r.fromFieldValue] : r.value] || []}
                                                                                            name={r.value}
                                                                                            placeholder={`select ${r.placeholder || r.value}`}
                                                                                            onChange={(option) => handleReactMultiSelectChange(option, r.value, true)}
                                                                                            value={editSubList[r.value] || []}
                                                                                            classNamePrefix={`${errorFields[r.value]} basic-select`}
                                                                                            isMulti={true}
                                                                                            isSearchable={true}
                                                                                        >
                                                                                        </ReactMultiSelect>
                                                                                    ) : r.type === 'createselect' ? (
                                                                                        <CreatableReactSelect
                                                                                            options={[
                                                                                                ...(props[r.fromFieldValue && editSubList[r.fromFieldValue] ? editSubList[r.fromFieldValue] : r.value] || []),
                                                                                                ...customOptions
                                                                                            ]}
                                                                                            name={r.value}
                                                                                            placeholder={`select ${r.placeholder || r.value}`}
                                                                                            onChange={(e, data) => handleReactSelectChange(e, data, true)}
                                                                                            value={editSubList[r.value]}
                                                                                            classNamePrefix={`${errorFields[r.value]} cursor-pointer basic-select`}
                                                                                            handleCreate={(txt) => handleCreate(txt, r.value, true)}
                                                                                        >
                                                                                        </CreatableReactSelect>
                                                                                    ) : r.type === 'colorpicker' ? (
                                                                                        <>
                                                                                            <div
                                                                                                style={styles.swatch}
                                                                                                onClick={() => {
                                                                                                    setColorPicker({ [`${i}`]: true });
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    //className='custom-color-picker-form-template'
                                                                                                    style={{
                                                                                                        ...styles.colorPicker,
                                                                                                        background: editSubList[r.value] || '#CCCCCC'
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            {colorPicker?.[i] ? (
                                                                                                <div style={styles.popover}>
                                                                                                    <div
                                                                                                        style={styles.cover}
                                                                                                        onClick={() => {
                                                                                                            setColorPicker({});
                                                                                                        }}
                                                                                                    />
                                                                                                    <SketchPicker
                                                                                                        color={editSubList[r.value] || '#000000'}
                                                                                                        onChange={color => handleColorPickerChange(color, r.value, true)}
                                                                                                    />
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </>
                                                                                    ) : (<></>)
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <>{getColValue(rec, r)}</>
                                                                        )}
                                                                    </td>
                                                                })
                                                            }
                                                            <td className="border-right-0">
                                                                <div className="action-fix" style={props.actionWidth ? { width: props.actionWidth } : {}}>
                                                                    {
                                                                        textEditor[index] ?
                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false, index) }}> <i className="ico icon-check"></i></a></div>
                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec, index) }}> <i className="ico icon-remove"></i></a></div>
                                                                            </>) :
                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec, index) }}> <i className="ico icon-edit"></i></a></div>
                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec, index) }}> <i className="ico icon-delete"></i></a></div>
                                                                                {
                                                                                    props.targetClick ? (
                                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); props.targetClick(rec, index) }}> <i className="ico icon-line-chart"></i></a></div>
                                                                                    ) : (<></>)
                                                                                }
                                                                            </>)
                                                                    }
                                                                </div>
                                                                {
                                                                    props.dragDisabled === true ? (<></>) : (<div className="table-edit float-right"> <i className="ico icon-drag"></i></div>)
                                                                }
                                                            </td>
                                                            {provided1.placeholder}
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            })
                                            : (<tr>
                                                <td colSpan={props.cols.length + 1}>
                                                    <div className="common-table" style={props.windowHeight ? { height: props.windowHeight + 'px' } : {}}>
                                                        <div className='h-100  w-100'>
                                                            <div className='spinner-loader h-100'>
                                                                <div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
                                                                    <div className="no-data-img"> <img src={images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                                    <div className="no-data-txt mt-2">
                                                                        <p>{`No data found`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>)
                                    }
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </table>
                </div>

            </DragDropContext >
        </>


    )
}

export default CustomTable