import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { leadBucketCountVM } from './viewModel'
import Translate from '../../constants/translate';
import CommonHelper from "../../services/common";
const InboundActivitiesWidget = (props) => {
    const [counts, setCounts] = useState(leadBucketCountVM)
    const [loader, setLoader] = useState(true);
    const [roleIDs, setRoleIDs] = useState();
    const [todayStart, setTodayStart] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
    const [todayEnd, setTodayEnd] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
    const showInboundActivities = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.separateInbdUserAct) ? true : false);

    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-inboundActivities`);
    // const accessToFinanceActivity = (!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //     props.dealersettings.rolePermissions.permissions.accessToFinanceActivity) ? true : false


    useEffect(() => {
        return () => {
            window.unsubinboundactivitiesSnap && window.unsubinboundactivitiesSnap();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return

        setLoader(true)
        window.unsubinboundactivitiesSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setCounts(snap.data().counts);
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    const fetchData = async (hideLoader) => {
        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        }

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.vehicleGroups?.length > 0) _filter.model = CommonHelper.handlePorscheModelByGroup(props.dashboardFilter, props);
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        //if (accessToFinanceActivity)
        _filter.type = "activity";
        if (showInboundActivities && !ownerDashboard && props.dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true)) {
            const _roleIDs = props.dealersettings.client.roles
                .filter(e => e.permissions && e.permissions.separateInbdUserAct === true)
                .map(r => { return r.documentID })
                .join(',');
            _filter.roleIDs = _roleIDs
            setRoleIDs(_roleIDs);
        }

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getActivitesCountV2" : "getActivitesCount",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            // console.log('getActivitesCount',resp)
            const data = resp.data.data[0]
            setCounts(data)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
        } else {
            // toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
        }
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            window.unsubinboundactivitiesSnap && window.unsubinboundactivitiesSnap();
            fetchData();
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])

    return (<>
        <div className="dashboard-box draggable-section">
            <div className="row h-100" style={{ minHeight: `120px` }}>
                <div className="col-md-6 border-left pr-0">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="dashboard-allocated-wrap " style={{ height: '120px' }}>
                                <h3><Translate text={'Inbound Activities'} /></h3>
                                <div className="dashboard-module-count-wraper dashboard-cell-bg" style={{ marginTop: '12.5px', borderBottomRightRadius: '11px' }}>
                                    <div className="row">
                                        <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                            if (counts.overdue) {
                                                props.handleShowActivityList({
                                                    isDone: 'false',
                                                    endDate: todayStart,
                                                    dateType: 'startDate',
                                                    roleIDs: roleIDs,
                                                    type: "activity"
                                                }, 'Inbound Overdue Activities', counts.overdue)
                                            }
                                        }}>
                                            <div className="dashboard-count-title"> Overdue</div>
                                            <div className="dashboard-count-value"> <a href="#" style={{ color: '#ef4136' }}>{counts.overdue ? counts.overdue : '0'}</a></div>
                                        </div>
                                        <div className='col-lg-6 dashboard-count-border-left' style={{ cursor: 'pointer' }} onClick={() => {
                                            if (counts.today) {
                                                props.handleShowActivityList({
                                                    startDate: todayStart,
                                                    endDate: todayEnd,
                                                    isDone: 'false',
                                                    dateType: 'startDate',
                                                    roleIDs: roleIDs,
                                                    type: "activity"
                                                }, 'Today Inbound Activities', counts.today)

                                            }
                                        }}>
                                            <div className="dashboard-count-title"> Today</div>
                                            <div className={`dashboard-count-value`}> <a href="#" className={`${counts.today > 0 ? 'activity-upcoming' : 'blue-link'}`}>{counts.today ? counts.today : '0'}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 border-left pl-0">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="dashboard-allocated-wrap " style={{ height: '120px' }}>
                                <h3><Translate text={'Completed Activities'} /></h3>
                                <div className="dashboard-module-count-wraper dashboard-cell-bg" style={{ marginTop: '12.5px', borderBottomRightRadius: '11px' }}>
                                    <div className="row">
                                        {
                                            props.applydateRange === false ? (
                                                <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (counts.todayactivity) {
                                                        props.handleShowActivityList({
                                                            startDate: todayStart,
                                                            endDate: todayEnd,
                                                            isDone: true,
                                                            dateType: 'modifiedDate',
                                                            roleIDs: roleIDs,
                                                            type: "activity"
                                                        }, 'Today Activities', counts.todayactivity)
                                                    }
                                                }}>
                                                    <div className="dashboard-count-title"> Today</div>
                                                    <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.todayactivity ? counts.todayactivity : '0'}</a></div>
                                                </div>) : (<></>)
                                        }

                                        <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                            if (counts.monthactivity) {
                                                props.handleShowActivityList({
                                                    startDate: monthStart,
                                                    endDate: monthEnd,
                                                    isDone: true,
                                                    dateType: 'modifiedDate',
                                                    roleIDs: roleIDs,
                                                    type: "activity"
                                                }, (props.applydateRange === false ? 'MTD Activities' : 'Activities'), counts.monthactivity)

                                            }
                                        }}>
                                            {
                                                props.applydateRange === false ?
                                                    (
                                                        <div className="dashboard-count-title">MTD</div>
                                                    ) : (<></>)
                                            }
                                            <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link">{counts.monthactivity ? counts.monthactivity : '0'}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>);
}

export default InboundActivitiesWidget;