import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, Redirect, Link } from 'react-router-dom';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { ContentOverlay, AntDateRangePicker, PopUpModal } from "../../components";
import CommonHelper from '../../services/common'
import _images from '../../images'
import { reportColumns, salesConversions } from './viewModel'
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import AddReport from './addReport'
import EnquiryList from '../dashboard/enquiryList'
import TestDriveList from '../dashboard/testdriveList'
import ActivityList from '../dashboard/activitiesList'
import { firestoreDB } from '../../services/helper';

const DynamicReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [allEnquiries, setEnquiries] = useState([]);
    const [allTestdrives, setTestdrives] = useState([]);
    const [allTradeins, setTradeins] = useState([]);
    const [doneActivities, setDoneActivities] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [progressReport, setProgressReport] = useState([])
    const [dates, setDates] = useState([]);
    const [refreshData, setRefreshData] = useState(true)
    const [sortConfig, setSortConfig] = useState();
    const [userOptions, setUserOptions] = useState([])
    const [showModel, setShowmodel] = useState(false)
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false
    })
    const [popHeader, setPopHeader] = useState()
    const [selectedUser, setUser] = useState()
    const [filter, setFilter] = useState(null)
    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: []
    })


    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec,
                name: rec.name
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])

    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    useEffect(() => {
        if (!refreshData)
            return

        setLoader(true)


        //OPEN ENQUIRIES    
        let refOpenEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refOpenEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('addedDate', '>=', dateRange.startDate)
                .where('addedDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
        }

        //WON ENQUIRIES    
        let refWonEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refWonEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('status', '==', 'won')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('wonDate', '>=', dateRange.startDate)
                .where('wonDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
        }

        //LOST ENQUIRIES    
        let refLostEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refLostEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('status', '==', 'lost')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('lostDate', '>=', dateRange.startDate)
                .where('lostDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
        }


        //DELIVERED ENQUIRIES    
        let refDeliveredEnquiryData = ''
        if (props.pipeline.filter(item => item.value !== 'LeadsBucket').length > 0) {
            refDeliveredEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('status', '==', 'delivered')
                .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                .where('clientID', '==', props.dealersettings.client.id)
                .where('deliveredDate', '>=', dateRange.startDate)
                .where('deliveredDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
        }

        //TEST DRIVES    
        let refTestDriveData = firestoreDB(props.dealersettings).firestore().collection('testdrives')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('startDate', '>=', dateRange.startDate)
            .where('startDate', '<=', dateRange.endDate)
            .orderBy('startDate','desc')

        //TRADE IN COUNT    
        let refTradeinData = firestoreDB(props.dealersettings).firestore().collection('tradeins')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('addedDate', '>=', dateRange.startDate)
            .where('addedDate', '<=', dateRange.endDate)


        //MTD DONE ACTIVITIES
        let refDoneActivityData = firestoreDB(props.dealersettings).firestore().collection('activities')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('isDone', '==', true)
            .where('type', '==', 'activity')
            .where('modifiedDate', '>=', dateRange.startDate)
            .where('modifiedDate', '<=', dateRange.endDate)


        //MTD WALKIN LEADS
        let refWalkinEnquiryData = firestoreDB(props.dealersettings).firestore().collectionGroup('walkins')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('walkinDate', '>=', dateRange.startDate)
            .where('walkinDate', '<=', dateRange.endDate)

        //MTD WALKIN LEADS
        let refRequirementsData = firestoreDB(props.dealersettings).firestore().collectionGroup('requirements')
            .where('clientID', '==', props.dealersettings.client.id)



        var dashboardPipelines = combineLatest(
            refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
            refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
            refLostEnquiryData ? collection(refLostEnquiryData) : of([]),
            refDeliveredEnquiryData ? collection(refDeliveredEnquiryData) : of([]),
            collection(refTestDriveData),
            collection(refTradeinData),
            collection(refDoneActivityData),
            collection(refWalkinEnquiryData),
            collection(refRequirementsData)
        ).pipe(
            map(([openLeads, wonLeads, lostLeads, deliveredLeads, testdriveLeads, tradeinLeads, doneActivity, walkins, requirements]) => {
                return [
                    _.map(openLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(wonLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(lostLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(deliveredLeads, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(testdriveLeads, function (obj) { return { ...CommonHelper.convertTestdriveVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(tradeinLeads, function (obj) { return { ...CommonHelper.convertTradeinVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(doneActivity, function (obj) { return { ...CommonHelper.convertActivityLogVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(walkins, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(requirements, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                ]
            }))
            .subscribe(allDocs => {
                var _enquiries = [];
                for (let i = 0; i < 4; i++) {
                    allDocs[i].forEach(el => {
                        if (!_enquiries.some(e => e.documentID === el.documentID)) {
                            _enquiries.push({
                                ...el,
                                testdrives: allDocs[4].filter(a => a.enquiryID === el.documentID),
                                walkins: allDocs[7].filter(a => a.enquiryID === el.documentID),
                                requirements: el.requirement ? [el.requirement, ...allDocs[8].filter(a => a.enquiryID === el.documentID)] : [],
                            })
                        }
                    });
                }
                allDocs[4].forEach(el => {
                    if (!_enquiries.some(e => e.documentID === el.enquiryID) && !_.isEmpty(el.enquiry) && !_.isEmpty(el.enquiry.addedDate)) {
                        _enquiries.push({
                            ...CommonHelper.convertEnquiryVM({ ...el.enquiry, 'documentID': el.enquiryID }, { ...props }),
                            testdrives: [el],
                            walkins: allDocs[7].filter(a => a.enquiryID === el.enquiryID),
                            requirements: el.enquiry.requirement ? [el.enquiry.requirement, ...allDocs[8].filter(a => a.enquiryID === el.enquiryID)] : [],
                        })
                    }
                });
                setTestdrives(allDocs[4])
                setTradeins(allDocs[5])
                setDoneActivities(allDocs[6].filter(item => !_.isEmpty(item.activityOwner)))
                //setEnquiries(_enquiries)
                setTempData({
                    enquiries: _enquiries,
                    walkins: allDocs[7]
                })
                if (allDocs[7].length === 0)
                    setLoader(false)
                setRefreshData(false)
            });
        return () => {
            dashboardPipelines && dashboardPipelines.unsubscribe()
        }

    }, [refreshData])

    useEffect(() => {
        async function getWalkinEnq(walkins, enquiries) {
            const promises = []
            walkins.forEach(el => {
                if (!enquiries.some(e => e.documentID === el.enquiryID)) {
                    promises.push(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${el.enquiryID}`).get())
                }
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id }
                enquiries.push({
                    ...dataDoc,
                    walkins: walkins.filter(a => a.enquiryID === snap.id)
                })
            })
            setEnquiries(enquiries)
            setLoader(false)
        }
        if (tempData.walkins.length > 0)
            getWalkinEnq(tempData.walkins, tempData.enquiries);
        else
            setEnquiries(tempData.enquiries)

    }, [tempData])

    useEffect(() => {
        if (_.isEmpty(userOptions) || _.isEmpty(props.report))
            return
        let reports = [];
        _.orderBy(userOptions.filter(item => props.report.userIDs.some(a => a === 'all') ? true : props.report.userIDs.some(a => a === item.value)), ['name'], ['asc']).forEach(rec => {

            var newLeads = allEnquiries.filter(a =>
                !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.startDate.seconds
                && a.addedDate.seconds <= dateRange.endDate.seconds
                && a.ownerValue === rec.value
                && ((!_.isEmpty(props.report.pipeline) && props.report.pipeline !== 'all') ? a.pipelineValue === props.report.pipeline : a.pipelineValue !== 'LeadsBucket')
            )

            var exisitngLeads = allEnquiries.filter(a =>
                !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.startDate.seconds
                && (((a.statusValue === 'won' || a.statusValue === 'delivered') && a.wonDate && a.wonDate.seconds >= dateRange.startDate.seconds
                    && a.wonDate.seconds <= dateRange.endDate.seconds) ||
                    (a.statusValue === 'lost' && a.lostDate && a.lostDate.seconds >= dateRange.startDate.seconds
                        && a.lostDate.seconds <= dateRange.endDate.seconds) ||
                    a.testdrives && a.testdrives.some(item => item.startDateValue.seconds >= dateRange.startDate.seconds
                        && item.startDateValue.seconds <= dateRange.endDate.seconds) ||
                    a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.startDate.seconds
                        && item.walkinDate.seconds <= dateRange.endDate.seconds))
                && a.ownerValue === rec.value
                && ((!_.isEmpty(props.report.pipeline) && props.report.pipeline !== 'all') ? a.pipelineValue === props.report.pipeline : a.pipelineValue !== 'LeadsBucket')
            )

            var wonLeads = allEnquiries.filter(a =>
                !_.isEmpty(a.statusValue)
                && !_.isEmpty(a.wonDate)
                && (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered')
                && a.wonDate.seconds >= dateRange.startDate.seconds
                && a.wonDate.seconds <= dateRange.endDate.seconds
                && a.ownerValue === rec.value
                && ((!_.isEmpty(props.report.pipeline) || props.report.pipeline !== 'all') ? a.pipelineValue === props.report.pipeline : a.pipelineValue !== 'LeadsBucket')
            )

            var testdriveLeads = _.uniq(_.map(allTestdrives.filter(a =>
                !_.isEmpty(a.startDateValue)
                && a.startDateValue.seconds >= dateRange.startDate.seconds
                && a.startDateValue.seconds <= dateRange.endDate.seconds
                && a.addedByValue === rec.value
                && ((!_.isEmpty(props.report.pipeline) && props.report.pipeline !== 'all') ? a.pipelineValue === props.report.pipeline : a.pipelineValue !== 'LeadsBucket')
            ), function (e) {
                return e.enquiryID
            }))

            var walkinLeads = allEnquiries.filter(a =>
                a.walkins.some(item => item.walkinDate.seconds >= dateRange.startDate.seconds
                    && item.walkinDate.seconds <= dateRange.endDate.seconds)
                && a.ownerValue === rec.value
                && ((!_.isEmpty(props.report.pipeline) && props.report.pipeline !== 'all') ? a.pipelineValue === props.report.pipeline : a.pipelineValue !== 'LeadsBucket')

            )

            let totalLeads = newLeads.length + exisitngLeads.length;
            let _report = {
                id: rec.value,
                name: rec.name,
                profileImage: rec.data.profileImage ? rec.data.profileImage : _images.nouser,
                new: newLeads.length,
                existing: exisitngLeads.length,
                total: totalLeads
            }
            if (props.report.type === 'walkintestdrive') {
                _report = {
                    ..._report,
                    walkin: walkinLeads.length,
                    testdrive: testdriveLeads.length,
                    walkintestdrive: walkinLeads.length > 0 ? Math.round((100 / walkinLeads.length) * testdriveLeads.length) : 0,
                }
            }
            else if (props.report.type === 'salesConversion') {
                let subtypes = props.report.subType ? props.report.subType.split(',') : []
                let total = 0;
                let totalEnq = [];
                subtypes.filter(item => item !== 'walkin').forEach(type => {
                    totalEnq = [...totalEnq, ..._.map(doneActivities.filter(a => a.isDone === true
                        && !_.isEmpty(a.enquiryID)
                        && a.subType === type
                        && a.modifiedDate.seconds >= dateRange.startDate.seconds
                        && a.modifiedDate.seconds <= dateRange.endDate.seconds
                        && a.owner === rec.value
                        && ((!_.isEmpty(props.report.pipeline) && props.report.pipeline !== 'all') ? a.pipelineValue === props.report.pipeline : a.pipelineValue !== 'LeadsBucket')), function (e) { return e.enquiryID })]
                    _report = {
                        ..._report,
                        [type]: doneActivities.filter(a => a.isDone === true
                            && !_.isEmpty(a.enquiryID)
                            && a.subType === type
                            && a.modifiedDate.seconds >= dateRange.startDate.seconds
                            && a.modifiedDate.seconds <= dateRange.endDate.seconds
                            && a.owner === rec.value
                            && ((!_.isEmpty(props.report.pipeline) && props.report.pipeline !== 'all') ? a.pipelineValue === props.report.pipeline : a.pipelineValue !== 'LeadsBucket')).length
                    }
                })
                subtypes.filter(item => item === 'walkin').forEach(type => {
                    totalEnq = [...totalEnq, ..._.map(walkinLeads, function (e) { return e.documentID })]
                    _report = {
                        ..._report,
                        [type]: walkinLeads.length
                    }
                })
                _report = {
                    ..._report,
                    ['totalLeads']: _.uniq(totalEnq).length
                }
                _report = {
                    ..._report,
                    ['won']: wonLeads.length,
                    ['wonConv']: _report.totalLeads > 0 ? Math.round((100 / _report.totalLeads) * wonLeads.length) : 0,
                }
            }
            else if (props.report.type === 'enquiryOptionsDF') {
                let subtypes = props.report.subType ? props.report.subType.split(',') : []
                subtypes.forEach(type => {
                    _report = {
                        ..._report,
                        [type]: newLeads.filter(a => a.dynamicFields && a.dynamicFields[type] === true).length
                    }
                })
            }
            reports.push(_report)
        })
        setProgressReport(reports)

    }, [allEnquiries, allTestdrives, allTradeins, doneActivities, props.report])

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [progressReport, sortConfig]);

    const handleModelClose = () => {
        setShowmodel(false)
        setUser(null)
        setFilter(null)
        setModelShow({
            enquiry: false,
            testdrive: false,
            activity: false
        })
    }

    const deleteReport = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`reports/${props.report.documentID}`).delete()
                    .then(snapshot => {
                        toast.notify('Report deleted successfully', {
                            duration: 2000
                        })
                        window.firebase.firestore().doc(`schedular/${props.report.documentID}`).delete();
                        props.setKey('salesprogress')
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })

    }

    return (<>
        <h2>{props.report.name}
            <Dropdown className='enquiry-view-more float-right mt-0'>
                <Dropdown.Toggle as={CustomToggle} className='common-button'>
                    <i className='ico icon-more'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    as={CustomMenu}
                    ChildClass="more-dropdown dropsub-fix"
                    xplacement="bottom-end"
                >
                    <Dropdown.Item eventKey="1" onClick={(e) => { setShowmodel(true) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                    <Dropdown.Item eventKey="1" onClick={(e) => { deleteReport() }}><i className="ico icon-delete"></i> Delete </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </h2>
        <div className="divider-line"></div>
        <div className="report-content-head">
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="form-group col-md-3 pr-0 daterange-fix">
                            <AntDateRangePicker
                                value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                id={"dashboard-date-range"}
                                name={'date'}
                                format='DD/MM/YYYY'
                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        setDates([start, end]);
                                    }
                                    else {
                                        setDates([])
                                    }
                                }}
                                disabledDate={disabledDate}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <button type="button" className="btn btn-primary" onClick={() => { setRefreshData(true) }}> <i className="ico icon-search"></i> Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className="common-table" style={{overflowX:'auto'}}>

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <td className="head-light" width="16%">
                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                    e.preventDefault()
                                    requestSort('name')
                                }}>
                                    <div className="table-sort"><a href="#">
                                        {
                                            sortConfig && sortConfig.key === 'name' ?
                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                        }
                                    </a>
                                    </div>
                                            User
                                        </div>
                            </td>
                            {
                                props.report.type !== 'salesConversion' ? (
                                    <>
                                        <td className="head-light text-center" width="7%">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                requestSort('new')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'new' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>
                                            New
                                        </div>
                                        </td>
                                        <td className="head-light text-center" width="7%">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                requestSort('existing')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'existing' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>
                                        Existing
                                        </div>
                                        </td>
                                        <td className="head-light text-center table-middle-total" width="7%">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                requestSort('total')
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === 'total' ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>
                                        Total
                                        </div>
                                        </td>
                                    </>
                                ) : (<></>)
                            }
                            {
                                props.report.type === 'walkintestdrive' ? reportColumns.filter(item => item.type === 'walkintestdrive').map((rec, index) => {
                                    return <td key={index} className={`head-light text-center ${rec.cls}`} width="7%">
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec.value)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec.value ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {rec.name}
                                        </div>
                                    </td>
                                }) : props.report.type === 'salesConversion' && !_.isEmpty(props.report.subType) ? _.filter(reportColumns.filter(item => item.type === 'salesConversion' && item.dynamic === true), (v) => _.indexOf(props.report.subType.split(','), v.value) >= 0).concat(reportColumns.filter(item => item.type === 'salesConversion' && item.dynamic === false)).map((rec, index) => {
                                    return <td key={index} className={`head-light text-center ${rec.cls}`} width="7%">
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec.value)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec.value ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {rec.name}
                                        </div>
                                    </td>
                                }) : (!_.isEmpty(props.dealersettings.client.settings[props.report.type]) && !_.isEmpty(props.report.subType)) ? _.filter(props.dealersettings.client.settings[props.report.type], (v) => _.indexOf(props.report.subType.split(','), v.value) >= 0).map((rec, index) => {
                                    return <td key={index} className={`head-light text-center ${rec.cls}`} width="7%">
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec.value)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec.value ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {rec.name}
                                        </div>
                                    </td>
                                }) : (<></>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedItems.length > 0 ?
                                sortedItems.map((rec, index) => {
                                    return <tr key={index}>
                                        <td>
                                            <div className="table-user-list">
                                                <a href="#">
                                                    <div className="table-user-avatar">
                                                        <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                    {rec.name}
                                                </a>
                                            </div>
                                        </td>
                                        {
                                            props.report.type !== 'salesConversion' ? (<>   <td className="text-center ">{rec.new}</td>
                                                <td className="text-center ">{rec.existing}</td>
                                                <td className="text-center  table-middle-total">{rec.total}</td>
                                            </>) : (<></>)
                                        }
                                        {
                                            props.report.type === 'walkintestdrive' ? reportColumns.filter(item => item.type === 'walkintestdrive').map((col, index) => {
                                                return <td key={index} className={`text-center ${col.cls}`}>{`${rec[col.value]}${col.percentage ? '%' : ''}`}</td>
                                            }) : props.report.type === 'salesConversion' && !_.isEmpty(props.report.subType) ? _.filter(reportColumns.filter(item => item.type === 'salesConversion' && item.dynamic === true), (v) => _.indexOf(props.report.subType.split(','), v.value) >= 0).concat(reportColumns.filter(item => item.type === 'salesConversion' && item.dynamic === false)).map((col, index) => {
                                                return <td key={index} className={`text-center ${col.cls}`}>{`${rec[col.value]}${col.percentage ? '%' : ''}`}</td>
                                            }) : (!_.isEmpty(props.dealersettings.client.settings[props.report.type]) && !_.isEmpty(props.report.subType)) ? _.filter(props.dealersettings.client.settings[props.report.type], (v) => _.indexOf(props.report.subType.split(','), v.value) >= 0).map((col, index) => {
                                                return <td key={index} className={`text-center ${col.cls}`}>{props.report.calculationType === 'percentage' ? (rec.total > 0 ? Math.round(100 / rec.total * rec[col.value]) : 0) + '%' : rec[col.value]}</td>
                                            }) : (<></>)
                                        }
                                    </tr>
                                }) : (
                                    <tr>
                                        <td colSpan="8">
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> No report</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                    {
                        sortedItems.length > 0 ?
                            (<tfoot>
                                <tr>
                                    <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                    {
                                        props.report.type !== 'salesConversion' ? (<>
                                            <td className="text-center cursor-pointer table-sub-total">{_.sumBy(progressReport, "new")}</td>
                                            <td className="text-center cursor-pointer table-sub-total">{_.sumBy(progressReport, "existing")}</td>
                                            <td className="text-center cursor-pointer table-sub-total">{_.sumBy(progressReport, "total")}</td>
                                        </>) : (<></>)
                                    }
                                    {
                                        props.report.type === 'walkintestdrive' ? reportColumns.filter(item => item.type === 'walkintestdrive').map((col, index) => {
                                            return col.value === 'walkintestdrive' ? (<td key={index} className="text-center cursor-pointer table-sub-total">{`${_.sumBy(progressReport, 'walkin') > 0 ? Math.round((100 / _.sumBy(progressReport, 'walkin')) * _.sumBy(progressReport, 'testdrive')) : 0}%`}</td>) :
                                                (<td key={index} className="text-center cursor-pointer table-sub-total">{`${_.sumBy(progressReport, col.value)}`}</td>)
                                        }) : props.report.type === 'salesConversion' && !_.isEmpty(props.report.subType) ? _.filter(reportColumns.filter(item => item.type === 'salesConversion' && item.dynamic === true), (v) => _.indexOf(props.report.subType.split(','), v.value) >= 0).concat(reportColumns.filter(item => item.type === 'salesConversion' && item.dynamic === false)).map((col, index) => {
                                            return col.value === 'wonConv' ? (<td key={index} className="text-center cursor-pointer table-sub-total">{`${_.sumBy(progressReport, 'totalLeads') > 0 ? Math.round((100 / _.sumBy(progressReport, 'totalLeads')) * _.sumBy(progressReport, 'won')) : 0}%`}</td>) :
                                                (<td key={index} className="text-center cursor-pointer table-sub-total">{`${_.sumBy(progressReport, col.value)}`}</td>)
                                        }) : (!_.isEmpty(props.dealersettings.client.settings[props.report.type]) && !_.isEmpty(props.report.subType)) ? _.filter(props.dealersettings.client.settings[props.report.type], (v) => _.indexOf(props.report.subType.split(','), v.value) >= 0).map((col, index) => {
                                            return <td key={index} className="text-center cursor-pointer table-sub-total">{props.report.calculationType === 'percentage' ? (_.sumBy(progressReport, 'total') > 0 ? Math.round(100 / _.sumBy(progressReport, 'total') * _.sumBy(progressReport, col.value)) : 0) + '%' : _.sumBy(progressReport, col.value)}</td>
                                        }) : (<></>)
                                    }
                                </tr>
                            </tfoot>
                            ) : (<></>)
                    }
                </table>
            </div>
            )
        }
        <PopUpModal show={showModel}>
            <AddReport
                {...props}
                show={showModel}
                title={'Edit Report'}
                handleClose={handleModelClose}
                report={props.report}
            />
        </PopUpModal>
        <PopUpModal show={modelShow.enquiry}>
            <EnquiryList
                {...props}
                show={modelShow.enquiry}
                handleClose={handleModelClose}
                title={popHeader}
                dataList={allEnquiries}
                history={props.history}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                filter={filter}
                selectedUser={selectedUser}
            ></EnquiryList>
        </PopUpModal>
        <PopUpModal show={modelShow.testdrive}>
            <TestDriveList
                {...props}
                show={modelShow.testdrive}
                handleClose={handleModelClose}
                title={popHeader}
                dataList={allTestdrives}
                filter={filter}
                selectedUser={selectedUser}
            ></TestDriveList>
        </PopUpModal>
        <PopUpModal show={modelShow.activity}>
            <ActivityList
                {...props}
                show={modelShow.activity}
                handleClose={handleModelClose}
                title={popHeader}
                dataList={doneActivities}
                filter={filter}
                selectedUser={selectedUser}
            ></ActivityList>
        </PopUpModal>
    </>);
}

export default DynamicReport;