/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import moment from 'moment';
/** COMPONENTS */
import {
    AntDatePicker, InputText, InputTextArea,
    ReactSelect, DynamicInput, ReactTimePicker,
    AntMonthPicker, InputCheckBox
} from '../../components';
import Translate from '../../constants/translate';
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import { default as _images } from '../../images';
/** VIEW-MODELS */
import { defaultFinanceTerms, enquiryStatus } from '../../services/enum';
import CommonHelper from '../../services/common';
import { objEnquiry, objWalkIn, objModules, objActivitylog } from './viewModel'
import Requirements from '../requirement/list'
import { firestoreDB } from '../../services/helper';

let origins = [];
let enquiryTypes = [];
let leadSources = [];
let campaigns = [];
let enquiryOptions = [];
let enquiryOptionsDF = [];
let enquiryOptionsStatic = [];
let financeStatuses = [];
let afterMarketStatuses = [];
let labels = [];
let allFinanceUsers = [];
let allAfterMarketUsers = [];
let pipelineUsers = [];
let allInboundUsers = [];
let financeProviders = [];
// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]
const __financestatus = [
    { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}><Translate text={'InProgress'} upperCase={true} /></div> },
    { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}><Translate text={'Pending'} upperCase={true} /></div> },
    { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'won'} upperCase={true} /></div> },
    { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> }
]
export default class EnquiryFields extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.state = {
            fields: Object.assign({}, objEnquiry),
            mandatoryFields: {},
            errors: {},
            requirementErrors: {},
            requirementErrorIds: [],
            lostReasons: [],
            lostSubReasons: [],
            newStatus: null,
            mentions: [],
            teamMentions: null,
            rosterMsg: null,
            financeRequired: false
        }
        this.mentionsData = props.clientUsers.map(clientUser => {
            return {
                name: `@${clientUser.name}`,
                id: clientUser.id,
                avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
            };
        });
        props.dealersettings.client.teams.forEach(team => {
            this.mentionsData.push({
                name: `@${team.name}`,
                id: team.user_ids.join(','),
                avatar: _images.group
            })
        })
    }

    //#region MentionEditor
    onMentionChange = (notesText, id) => {
        const e = {
            target: {
                name: 'notes',
                value: notesText,
            },
        };

        // this.handleOnAreaChange(e, true);
        const fields = Object.assign({}, this.state.fields);
        fields[id] = notesText
        this.setState({ fields: fields });

    };

    onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            let idToPass = id
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            this.setState({
                teamMentions: idToPass
            })
        }

        // Check to avoid adding duplicate entries in mention
        const check = this.state.mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            this.setState({
                mentions: [...this.state.mentions, { name, id: idToPass }]
            })
        }
    };

    clearTeamMentions = () => {
        this.setState({
            teamMentions: null
        })
    }
    //#endregion

    //#region PAGELOAD
    loadEnquiry = () => {

        let { enquiry, dealersettings, errors, newStatus, groupUsers, clientUsers, financeRequired } = this.props;
        let state = Object.assign({}, enquiry);
        const accessToSelectAllKPIs = dealersettings?.rolePermissions?.permissions?.accessToSelectAllKPIs;
        //console.log('EnquiryFields', '--', state);
        if (!_.isEmpty(state) && state.isVehicle && !_.isEmpty(state.requirement) && !_.isEmpty(errors)) {
            let stock = state.requirement;

            if (!_.isEmpty(state.requirement.stock))
                stock = state.requirement.stock;

            let _vinNo = stock && stock.vinNo ? stock.vinNo : (state.requirement.vinNo ? state.requirement.vinNo : null);
            let _regNo = stock && stock.regNo ? stock.regNo : (state.requirement.regNo ? state.requirement.regNo : null);
            //console.log('EnquiryFields-stock', _vinNo, '--', _regNo);
            if (errors['chassisNo'] || newStatus === enquiryStatus.DELIEVERD)
                state.chassisNo = !state.chassisNo && _vinNo ? _vinNo : state.chassisNo;
            if (errors['regNo'] || newStatus === enquiryStatus.DELIEVERD)
                state.regNo = !state.regNo && _regNo ? _regNo : state.regNo;

            if (errors['stockNo'] || newStatus === enquiryStatus.DELIEVERD)
                state.stockNo = !state.stockNo && stock && stock.stockNo ? stock.stockNo : state.stockNo;
            if (errors['warrantyExpiry'] || newStatus === enquiryStatus.DELIEVERD)
                state.warrantyExpiry = !state.warrantyExpiry && stock && stock.warrantyExp ? moment.unix(stock.warrantyExp.seconds).format('YYYY-MM-DD') : state.warrantyExpiry;
            if (errors['warrantyStartDate'] || newStatus === enquiryStatus.DELIEVERD)
                state.warrantyStartDate = !state.warrantyStartDate && stock && stock.warrantyStartDate ? moment.unix(stock.warrantyStartDate.seconds).format('YYYY-MM-DD') : state.warrantyStartDate;


            //console.log('EnquiryFields-stock', stock, '--', state);
        }

        this.setState({
            newStatus: this.props.newStatus,
            mandatoryFields: Object.assign({}, this.props.errors),
            financeRequired: (financeRequired || false),
            fields: state
        });

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {

            const lostReasons = [];
            const lostSubReasons = [];

            const setttings = dealersettings.client.settings;

            financeProviders = [];
            setttings.financeProviders && setttings.financeProviders.forEach(doc => {
                financeProviders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });
            origins = [];
            let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? false : _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? true : accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            labels = [];
            setttings.enquiryLabels && setttings.enquiryLabels.forEach(doc => {
                labels.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill`} style={{
                        color: `${doc.color ? doc.color : '#f47815 '}`,
                        backgroundColor: `${doc.color ? doc.color + '1f' : '#f478151f'}`,
                        boxShadow: `inset 0 0 0 1px ${doc.color ? doc.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                    }}>{doc.name.toUpperCase()}</div>,
                });
            });

            leadSources = [];
            setttings.leadSource && setttings.leadSource.forEach(doc => {
                leadSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            enquiryOptions = [];
            setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
                enquiryOptions.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            enquiryOptionsDF = [];
            if (setttings.enquiryOptionsDF)
                enquiryOptionsDF = setttings.enquiryOptionsDF;

            enquiryOptionsStatic = [];
            if (setttings.enquiryOptionsStatic)
                enquiryOptionsStatic = setttings.enquiryOptionsStatic;

            financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            afterMarketStatuses = [];
            setttings.afterMarketStatus && setttings.afterMarketStatus.forEach(doc => {
                afterMarketStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            setttings.lostReasons.forEach(doc => {
                lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (state.lostReason && !_.isEmpty(setttings.lostReasons) && !_.isEmpty(setttings.lostReasons.filter(e => e.value === state.lostReason)[0])) {
                let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === state.lostReason)[0].subList) ?
                    setttings.lostReasons.filter(e => e.value === state.lostReason)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            }

            pipelineUsers = [];
            allInboundUsers = [];
            allFinanceUsers = [];
            allAfterMarketUsers = [];
            if (!_.isEmpty(dealersettings.client.roles) && state.clientID) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === state.pipeline)), function (obj) {
                    return obj.documentID;
                })];

                let roleFMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToBusinessManager === true), function (obj) {
                    return obj.documentID;
                })];

                let roleAMMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true), function (obj) {
                    return obj.documentID;
                })];

                let roleInboundIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === 'LeadsBucket')), function (obj) {
                    return obj.documentID;
                })];

                let _selectedUsers = [];
                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === state.clientID), (v) => _.includes(roleIds, v.roleID));

                if (_.isEmpty(_selectedUsers) && _.isEmpty(groupUsers) && state.clientID !== dealersettings.client.id && localStorage.defaultModule !== 'oem')
                    _selectedUsers = clientUsers;

                _selectedUsers && _selectedUsers.map(rec => {
                    return pipelineUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                let _selectedFMUsers = [];
                if (!_.isEmpty(roleFMIds))
                    _selectedFMUsers = _.filter(allDealerUsers.filter(v => v.clientID === state.clientID), (v) => _.includes(roleFMIds, v.roleID));

                _selectedFMUsers && _selectedFMUsers.map(rec => {
                    return allFinanceUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                let _selectedAMMUsers = [];
                if (!_.isEmpty(roleAMMIds))
                    _selectedAMMUsers = _.filter(allDealerUsers.filter(v => v.clientID === state.clientID), (v) => _.includes(roleAMMIds, v.roleID));

                _selectedAMMUsers && _selectedAMMUsers.map(rec => {
                    return allAfterMarketUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                let _selectedInboundUsers = [];
                if (!_.isEmpty(roleInboundIds))
                    _selectedInboundUsers = _.filter(allDealerUsers.filter(v => v.clientID === state.clientID), (v) => _.includes(roleInboundIds, v.roleID));

                _selectedInboundUsers && _selectedInboundUsers.map(rec => {
                    return allInboundUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

            }
            else {
                clientUsers.map(rec => {
                    return pipelineUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
            }

            this.setState({
                lostReasons: lostReasons,
                lostSubReasons: lostSubReasons,
                // newStatus: this.props.newStatus,
                // mandatoryFields: Object.assign({}, this.props.errors),
                // fields: state
            });
        }

        this.handleValidRequirements(false, true);
        //console.log('enquiry fields', this.props.newStatus, Object.assign({}, this.props.errors))
    }

    // loadEnquiry = () => {
    //     const { stock } = this.props.requirement;
    //     if (!_.isEmpty(stock)) {
    //         let state = Object.assign({}, this.state.fields);
    //         state.chassisNo = stock.vinNo ? stock.vinNo : '';
    //         state.regNo = stock.regNo ? stock.regNo : '';
    //         state.stockNo = stock.stockNo ? stock.stockNo : '';
    //         state.warrantyExpiry = stock.warrantyExp ? stock.warrantyExp : null;
    //         this.setState({ fields: state });
    //     }
    // }

    componentDidMount() {
        this.loadEnquiry();
    }

    componentDidUpdate() {
        if (!_.isEmpty(this.props.mandatoryFields)) {
            this.handleValidRequirements();
        }
    }

    handleValidRequirements = (isScroll, isLoad) => {

        let errors = {};
        let formIsValid = true;
        let _key;
        let errorIds = [];
        const { requirements, mandatoryFields } = this.props;

        if (!_.isEmpty(mandatoryFields)) {

            let _requiredFields = _.map(mandatoryFields, function (a) {
                return a === 'chassisNo' ? 'vinNo' : a === 'warrantyExpiry' ? 'warrantyExp' : a
            });

            let _isSingleReq = requirements && requirements.length === 1 ? true : false;
            requirements && requirements.filter(e => _isSingleReq ? true : e.isSold === true).forEach(fieldsDetail => {
                let _recommID = fieldsDetail.documentID;
                if (!_.isEmpty(fieldsDetail.stock) && fieldsDetail.stock.make)
                    fieldsDetail = Object.assign({}, fieldsDetail.stock);

                let errorKey = [];
                _requiredFields && _requiredFields.forEach((key) => {
                    if ((_.isObject(fieldsDetail[key]) && _.isEmpty(fieldsDetail[key])) || (!_.isObject(fieldsDetail[key]) && !_.isBoolean(fieldsDetail[key]) && !_.isNumber(fieldsDetail[key]) && !fieldsDetail[key])) {
                        formIsValid = false;
                        errorKey.push(key);
                    }
                });

                if (!_.isEmpty(errorKey)) {
                    if (!_key) _key = _recommID;
                    errors[_recommID] = errorKey;
                    if (isLoad) errorIds.push(_recommID);
                }

            });

            if (JSON.stringify(this.state.requirementErrors) !== JSON.stringify(errors)) {
                this.setState({ requirementErrors: errors });
                if (isLoad)
                    this.setState({ requirementErrorIds: errorIds });

                //console.log('errorIds', errorIds)
            }


            if (isScroll && _key && document.getElementById('ReqField_' + _key) && !_.isEmpty(errors[_key])) {
                document.getElementById('ReqField_' + _key).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
        }

        return formIsValid;
    }
    //#endregion


    //#region ON CHANGE EVENT
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let mandatoryFields = Object.assign({}, this.state.mandatoryFields);
        let errorClass = 'input_error';
        if (_.isObject(errors) && !_.isEmpty(errors)) {
            if (mandatoryFields[key] && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                if (fields.hasOwnProperty(key)) {
                    errors[key] = errorClass;
                }
                else {
                    if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                        (_.isEmpty(fields.dynamicFields) ||
                            (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                        )) {
                        errors[key] = errorClass;
                    }
                    // else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === key && m.active === true)[0])) &&
                    //     (_.isEmpty(fields.options) || (!_.isEmpty(fields.options) && fields.options.indexOf(key) < 0))) {
                    //     errors[key] = errorClass;
                    // }
                    else {
                        errors[key] = '';
                    }
                }
            }
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = value;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleDateChange = (value, name) => {
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = moment(value).format('YYYY-MM-DD');
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    };

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856} 
        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;

        const { dealersettings } = this.props;
        if (name === 'isFinanceRequired' && dealersettings?.rolePermissions?.permissions?.accessToFinanceManager === true) {
            this.setState({ fields: state, financeRequired: checked }, () => { this.errorChange(name); });
        }
        else {
            this.setState({ fields: state }, () => { this.errorChange(name); });
        }

    }

    handleOptionsChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _reqOtherInfo = state.options ? state.options : [];
        if (checked)
            _reqOtherInfo.push(name)
        else
            _reqOtherInfo = _reqOtherInfo.filter(m => m !== name);

        state['options'] = _reqOtherInfo;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDeliveryDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });

        if (name === 'deliveryDate')
            this.checkUserRoster(state.owner, val);
    };

    handleTimeChange = (val, name) => {
        //console.log(val)
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(val)._d)

        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    handlePIDateSave = (val, name) => {
        if (val)
            val = moment(val).format('MMM YYYY');

        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    handleSelectSave = (e, data) => {
        //this.saveEnquiryField(data.name, e.value);
        let state = Object.assign({}, this.state.fields);
        state[data.name] = e.value;

        if (data.name === 'financeBM' && !state.isFinanceRequired)
            state.isFinanceRequired = true;

        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleLostReasonSave = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        const { lostReasons } = this.state;
        const lostSubReasons = [];
        if (!_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
            lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                lostSubReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
        }

        state[data.name] = e.value;

        if (state.lostSubReason)
            state['lostSubReason'] = null;

        this.setState({ fields: state, lostSubReasons: lostSubReasons }, () => { this.errorChange(data.name); });

    };

    isPIValid = (date) => {
        let _date = moment(date, "MMM YYYY")
        if (moment().isBefore(_date) || moment().isSame(_date, 'month'))
            return ''
        else
            return 'form-alert '
    }


    checkUserRoster = (userID, _date) => {
        if (userID) {
            const { dealersettings, groupUsers, clientUsers } = this.props;
            CommonHelper.checkUserRoster(dealersettings,
                {
                    owner: userID,
                    startDate: _date ? _date : window.firebase.firestore.Timestamp.now()
                }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers),
                (_date ? true : false))
                .then(msg => {
                    this.setState({ rosterMsg: msg });
                })
        }
    }
    //#endregion


    //#region DYNAMIC FIELDS

    handleDynamicCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = checked;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        if (value)
            _dynamicFields[name] = _.trim(value.toUpperCase());
        else
            _dynamicFields[name] = null;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    onDynamicNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        _dynamicFields[name] = e.floatValue;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleDynamicSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[data.name] = e.value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    };

    handleDynamicMultiSelectChange = (selectedOptions, _data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[_data.name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(_data.name); });
    }

    handleDynamicDateChange = (val, name) => {

        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = val;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    saveDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    cancelDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }
    //#endregion


    //#region SUBMIT

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let mandatoryFields = Object.assign({}, this.state.mandatoryFields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(mandatoryFields)) {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                if (fields.hasOwnProperty(key)) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
                else if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
                // else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === key && m.active === true)[0])) &&
                //     (_.isEmpty(fields.options) || (!_.isEmpty(fields.options) && fields.options.indexOf(key) < 0))) {
                //     formIsValid = false;
                //     errors[key] = errorClass;
                // }
            }
        }
        console.log('errors', errors)
        this.setState({ errors: errors });

        if (formIsValid === true) {
            if (this.props.isDontSave === true)
                this.props.handleClose(fields);
            else if (this.handleValidRequirements(true) === true)
                this.handleSave();

        }
        else
            return formIsValid;

    };

    handleSave = () => {
        this.isLoading = true;
        const { enquiryID, dealersettings, newStatus, enquiry, ismodifyStatusDate, requirements } = this.props;

        const objData = Object.assign({}, this.state.fields);

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        for (var propName in objData) {
            if (
                objData[propName] === null ||
                objData[propName] === undefined ||
                objData[propName] === '' ||
                (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
            ) {
                delete objData[propName];
            }
        }

        if ((objData['enquiryType'] && objData['enquiryType'] !== enquiry['enquiryType'])) {
            let __name = CommonHelper.getNameByValue(dealersettings.client?.settings?.enquiryTypes, objData.enquiryType)
            if (__name && (__name?.toLowerCase()?.indexOf('walk') >= 0 || __name?.toLowerCase()?.indexOf('drive') >= 0))
                this.handleSaveWalkin(enquiryID, objData.clientID)
        }
        if ((objData['origin'] && objData['origin'] !== enquiry['origin'])) {
            let __name = CommonHelper.getNameByValue(dealersettings.client?.settings?.origins, objData.origin)
            if (__name && (__name?.toLowerCase()?.indexOf('walk') >= 0 || __name?.toLowerCase()?.indexOf('drive') >= 0))
                this.handleSaveWalkin(enquiryID, objData.clientID)
        }

        let notes = null;
        if (objData.lostNotes) {
            notes = objData.lostNotes;
            //objData.lostNotes = notes;
        }

        //   console.log('handleSave', enquiryID, objData, newStatus)
        const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(enquiryID);
        updateRef
            .set(objData, { merge: true })
            .then(docRef => {
                this.isLoading = false;
                const { mentions, mandatoryFields } = this.state;
                if (notes) this.saveCustomerlog(notes, mentions);

                if ((ismodifyStatusDate || mandatoryFields['deliveryDate']) && !_.isEmpty(requirements)) {
                    requirements.filter(e => e.isSold === true).forEach(_requirement => {
                        if (!_.isEmpty(_requirement.stock) && _requirement.stock.documentID)
                            this.handleChangeStockDate(_requirement.stock.documentID, objData);
                    });
                }

                this.saveContactOriginAndCampaign();
                this.props.handleClose(newStatus ? newStatus : null)
            })
            .catch(error => {
                this.isLoading = false;
                console.error('Error updating enquiries: ', error);
            });
    }

    handleChangeStockDate = (stockID, _enquiry) => {
        const { ismodifyStatusDate } = this.props;
        const { mandatoryFields } = this.state;
        let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();

        let objData = {
            modifiedBy: modifiedBy,
            modifiedDate: modifiedDate,
            modifiedFrom: 'web'
        }

        if (mandatoryFields['deliveryDate'] && _enquiry.deliveryDate)
            objData.deliveryDate = _enquiry.deliveryDate;

        if (ismodifyStatusDate && _enquiry && _enquiry.wonDate)
            objData.soldDate = _enquiry.wonDate;

        if (ismodifyStatusDate && _enquiry && _enquiry.deliveredDate) {
            objData.deliveredDate = _enquiry.deliveredDate;
            objData.warrantyStartDate = _enquiry.deliveredDate;
        }

        const refStockData = window.firebase.firestore().collection('stock').doc(stockID);
        refStockData.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
        }).catch((error) => {
            console.error("Error updating handleChangeStockStatus: ", error);
        });
    }

    saveCustomerlog = (logNotes, mentions) => {
        // debugger;
        let newlogVM = Object.assign({}, objActivitylog);
        const { dealersettings, clientUsers, groupUsers, enquiryID, enquiry, contact } = this.props;

        let _contactID = enquiry.contactID ? enquiry.contactID : (!_.isEmpty(enquiry.contact) ? enquiry.contact.documentID : (!_.isEmpty(contact) ? contact.documentID : null));
        let clientID = enquiry.clientID ? enquiry.clientID : (dealersettings ? dealersettings.client.id : '');

        newlogVM.type = 'log';
        newlogVM.subType = 'note';
        newlogVM.isDone = true;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = enquiryID ? enquiryID : this.state.enquiryID;
        newlogVM.contactID = _contactID ? _contactID : null;
        newlogVM['enquiry'] = CommonHelper.getMinifiedData(enquiry, 'enquiry');
        newlogVM['contact'] = CommonHelper.getMinifiedData(enquiry?.contact, 'contact');
        newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), localStorage.uid);
        newlogVM.modifiedByName = newlogVM.addedByName;
        newlogVM.notes = logNotes ? logNotes : '';
        newlogVM.tags = ['lost notes']
        newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, clientID);
        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
        newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;


        if (!_.isEmpty(mentions)) {
            if (mentions.length > 0) {
                let convertedIDs = []
                let rawIDs = mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })

                if (newlogVM.mentions && newlogVM.mentions.length > 0) {
                    newlogVM.mentions = _.uniq([...newlogVM.mentions, ...convertedIDs])
                } else {
                    newlogVM.mentions = _.uniq(convertedIDs)
                }
            }
        }

        firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });
    };

    saveContactOriginAndCampaign = () => {
        const { enquiry, contact } = this.props;
        const { campaign, origin } = this.state.fields;

        let _contact = !_.isEmpty(contact) ? contact : enquiry.contact;
        let _objData = {};

        if (campaign && _contact && !_contact.campaign) _objData.campaign = campaign;
        if (origin && _contact && !_contact.origin) _objData.origin = origin;

        let _contactID = enquiry.contactID ? enquiry.contactID : (!_.isEmpty(enquiry.contact) ? enquiry.contact.documentID : (!_.isEmpty(contact) ? contact.documentID : null));

        CommonHelper.saveContactOriginAndCampaign(_contactID, _objData.campaign, _objData.origin, this.props);
    }

    handleSaveWalkin = (enquiryID, _clientID) => {
        this.isLoading = true;
        const { dealersettings } = this.props;

        let userID = dealersettings ? dealersettings.id : '';
        let clientID = dealersettings ? dealersettings.client.id : '';
        let currenDate = window.firebase.firestore.Timestamp.now();

        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = userID;
        objData.addedDate = currenDate;
        objData.modifiedBy = userID;
        objData.modifiedDate = currenDate;
        objData.clientID = _clientID ? _clientID : clientID;
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = currenDate;
        objData.modifiedFrom = 'web';

        const refOnjData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData).then((docRef) => {
            if (this.state.fields && (this.state.fields.bdcStatus === 'allocated' || this.state.fields.bdcStatus === 'noshow')) {
                firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`).set({
                    bdcStatus: 'show'
                }, { merge: true })
            }
            CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, dealersettings);
        }).catch((error) => {
            console.error("Error updating walkin_details: ", error);
        });

    }

    handleCancel = (e) => {
        e.preventDefault();
        this.loadEnquiry();
    }

    handleEditRequirement = (_req, isClone) => {
        this.props.handleEditRequirement(_req, isClone, this.state.requirementErrors[_req.documentID])
    }

    minDate = (current) => {
        let state = Object.assign({}, this.state.fields);
        var minDate = state.wonDate ? moment.unix(state.wonDate.seconds).startOf('day') :
            state.addedDate ? moment.unix(state.addedDate.seconds).startOf('day') :
                moment().startOf('day')
        return current && current < minDate;
    }
    //#endregion


    render() {
        const { status, enquiryType, clientID, isAfterSales,
            label, purchaseIntention, contractNumber,
            depositAmount, depositReciptNumber, grossAmount, origin, campaign,
            lostReason, leadSource, pipeline, ownerInbound,
            lostSubReason, lostNotes, financeBM, owner, afterMarketBM, afterMarketStatus,
            deliveryDate, wonDate, lostDate, deliveredDate,
            chassisNo, regNo, stockNo, warrantyExpiry, warrantyStartDate,
            isFinanceRequired, financeStatus, stageNotes, dynamicFields,
            financeAmount, financeProvider, financeRate, financeStartDate, financeTerms,
        } = this.state.fields;

        const { lostReasons, lostSubReasons, mandatoryFields, requirementErrors, requirementErrorIds, rosterMsg, financeRequired } = this.state;

        const { dealersettings, ismodifyStatusDate, enquiryAllStatus, isModifyInboundOwner, newStatus, isDontSave, isFromLayout } = this.props;

        const isGroupDealer = localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id ? true : false;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const _moduleSettings = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);

        const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? (isFromLayout ? false : true) : false);

        const canModifyEnquiry = readOnlyEnquiryView ? false : (((!_.isEmpty(this.state.fields) && this.state.fields.owner === dealersettings.id) ||
            (!_.isEmpty(_permissions) && _permissions.modifyOtherEnquiry)) ? (isGroupDealer ? false : true) : false);

        const modifyPurhaseInt = ((!_.isEmpty(_permissions) && _permissions.modifyPurhaseInt) ? true : false);

        const modifyCampaign = ((!_.isEmpty(_permissions) && _permissions.modifyCampaign) ? true : false);

        const modifyEnquiryOrigin = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryOrigin) ? true : false);

        const modifyEnquiryType = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryType) ? true : false);

        const modifyLabel = ((!_.isEmpty(_permissions) && _permissions.modifyLabel) ? true : false);

        const modifyLostReason = ((!_.isEmpty(_permissions) && _permissions.modifyLostReason) ? true : false);

        let _modules = pipeline && !_.isEmpty(dealersettings.allPipelines.filter(e => e.value === pipeline)[0]) ? dealersettings.allPipelines.filter(e => e.value === pipeline)[0].modules : objModules;
        let modules = !_.isEmpty(_modules) ? _modules : objModules;

        const financeEnabled = ((!_.isEmpty(_moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.finance) &&
            dealersettings.client.moduleSettings.finance.enabled) ? (!_.isEmpty(modules) && modules.finance ? true : false) : false);

        const drivaEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
        ) ? (!_.isEmpty(modules) && modules.finance ? true : false) : false);

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, status)

        let fieldsLength = !_.isEmpty(mandatoryFields) ? Object.keys(mandatoryFields).length : 0;

        const accessToFinanceTab = ((!_.isEmpty(_permissions) && _permissions.accessToFinanceManager) ? true : false);

        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                    dialogClassName={`${fieldsLength > 8 ? 'modal-dialog-scrollable ' : ''}modal-dialog-centered modal-add-regno`} >
                    <Modal.Header className="border-bottom" closeButton>
                        <Modal.Title><Translate text={this.props.financeRequired ? 'financeInfo' : this.props.title} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="search-pop form-style">
                                    {
                                        (newStatus === enquiryStatus.WON && !_.isEmpty(this.props.requirements) && this.props.requirements.length > 1 && !isDontSave && _.isEmpty(requirementErrorIds))
                                            ?
                                            <>
                                                <div className="form-row ">
                                                    <div className="form-group col-md-12">
                                                        <div className={`vehicle-item`}>
                                                            <Requirements
                                                                requirement={this.props.requirement}
                                                                handleEdit={() => { }}
                                                                enquiryID={this.props.enquiryID}
                                                                dealersettings={dealersettings}
                                                                hideMore={true}
                                                                isMultiple={true}
                                                                isPrimary={true}
                                                                enquiry={this.props.enquiry}
                                                            >
                                                            </Requirements>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                    <div className="form-row ">

                                        {
                                            (!_.isEmpty(mandatoryFields["owner"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'owner')} /></label>
                                                        <ReactSelect
                                                            options={pipelineUsers}
                                                            name={"owner"}
                                                            placeholder={'select owner'}
                                                            onChange={this.handleSelectSave}
                                                            value={owner}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["owner"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={false}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (isModifyInboundOwner)
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'ownerInbound')} /></label>
                                                        <ReactSelect
                                                            options={allInboundUsers}
                                                            name={"ownerInbound"}
                                                            placeholder={'select inbound owner'}
                                                            onChange={this.handleSelectSave}
                                                            value={ownerInbound}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["ownerInbound"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={true}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["enquiryType"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'enquiryType')} /></label>
                                                        <ReactSelect
                                                            options={enquiryTypes}
                                                            name={"enquiryType"}
                                                            placeholder={'select enquiry type'}
                                                            onChange={this.handleSelectSave}
                                                            value={enquiryType}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["enquiryType"]} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["leadSource"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'leadSource')} /></label>
                                                        <ReactSelect
                                                            options={leadSources}
                                                            name={"leadSource"}
                                                            placeholder={'select lead source'}
                                                            onChange={this.handleSelectSave}
                                                            value={leadSource}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["leadSource"]} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (!_.isEmpty(mandatoryFields["origin"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'origin')} /></label>
                                                        <ReactSelect
                                                            options={origins}
                                                            name={"origin"}
                                                            placeholder={'select origin'}
                                                            onChange={this.handleSelectSave}
                                                            value={origin}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["origin"]} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }



                                        {
                                            (!_.isEmpty(mandatoryFields["campaign"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'campaign')} /></label>
                                                        <ReactSelect
                                                            options={campaigns}
                                                            name={"campaign"}
                                                            placeholder={'select campaign'}
                                                            onChange={this.handleSelectSave}
                                                            value={campaign}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["campaign"]} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }



                                        {
                                            (!_.isEmpty(mandatoryFields["label"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'label')} /></label>
                                                        <ReactSelect
                                                            options={labels}
                                                            name={"label"}
                                                            placeholder={'select label'}
                                                            onChange={this.handleSelectSave}
                                                            value={label}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["label"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={false}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }



                                        {
                                            (!_.isEmpty(mandatoryFields["purchaseIntention"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'purchaseIntention')} /></label>
                                                        <AntMonthPicker
                                                            value={purchaseIntention ? moment('01 ' + purchaseIntention)._d : null}
                                                            name={'purchaseIntention'}
                                                            onChange={e => {
                                                                if (canModifyEnquiry)
                                                                    this.handlePIDateSave(e, 'purchaseIntention');
                                                            }}
                                                            placeholder='set purchase intention'
                                                            className={`form-control cursor-pointer ${purchaseIntention ? this.isPIValid(purchaseIntention) : ''} ${this.state.errors["purchaseIntention"]}`}
                                                            disabled={canModifyEnquiry ? false : true}
                                                            disabledDate={(current) => { return current && current < moment().startOf('month'); }}
                                                            format={'MMM YYYY'}
                                                            picker={'month'}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }



                                        {
                                            (!_.isEmpty(mandatoryFields["deliveryDate"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'deliveryDate')} /></label>
                                                        <div className="splite-input-half">
                                                            <AntDatePicker
                                                                value={deliveryDate ? moment.unix(deliveryDate.seconds).format('YYYY-MM-DD') : null}
                                                                name={'deliveryDate'}
                                                                onChange={(e) => { this.handleDeliveryDateChange(e, 'deliveryDate') }}
                                                                format='DD/MM/YYYY'
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control mr-1 ${this.state.errors["deliveryDate"]}`}
                                                                disabledDate={this.minDate}
                                                            />
                                                            <div className='inner-addon right-addon ml-1'>
                                                                <i className='bg-icons ico icon-time'></i>
                                                                <ReactTimePicker
                                                                    value={deliveryDate ? moment.unix(deliveryDate.seconds)._d : null}
                                                                    name={'deliveryDate'}
                                                                    onChange={this.handleTimeChange}
                                                                    timeIntervals={15}
                                                                    placeholder='h:mm aa'
                                                                    className={`form-control ${this.state.errors["deliveryDate"]}`}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>


                                                    {
                                                        !_.isEmpty(rosterMsg) ? (
                                                            <div className="activity-pop-info alert-danger mb-3">
                                                                <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }




                                        {
                                            (!_.isEmpty(mandatoryFields["chassisNo"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'chassisNo')} /> </label>
                                                        <InputText
                                                            name="chassisNo"
                                                            value={chassisNo}
                                                            onChange={this.handleOnCapChange}
                                                            autoComplete="off"
                                                            placeholder={'chassis/vin number'}
                                                            className={`form-control ${this.state.errors["chassisNo"]}`}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }


                                        {
                                            (!_.isEmpty(mandatoryFields["regNo"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'regNo')} /> </label>
                                                        <InputText
                                                            name="regNo"
                                                            value={regNo}
                                                            onChange={this.handleOnCapChange}
                                                            autoComplete="off"
                                                            placeholder={'registration number'}
                                                            className={`form-control ${this.state.errors["regNo"]}`}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["stockNo"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'stockNo')} /> </label>
                                                        <InputText
                                                            name="stockNo"
                                                            value={stockNo}
                                                            onChange={this.handleOnCapChange}
                                                            autoComplete="off"
                                                            placeholder={'stock number'}
                                                            className={`form-control ${this.state.errors["stockNo"]}`}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["warrantyStartDate"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'warrantyStartDate')} /> </label>
                                                        <AntDatePicker
                                                            value={warrantyStartDate}
                                                            name={'warrantyStartDate'}
                                                            onChange={(e) => { this.handleDateChange(e, 'warrantyStartDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["warrantyStartDate"]}`}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["warrantyExpiry"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'warrantyExpiry')} /> </label>
                                                        <AntDatePicker
                                                            value={warrantyExpiry}
                                                            name={'warrantyExpiry'}
                                                            onChange={(e) => { this.handleDateChange(e, 'warrantyExpiry') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["warrantyExpiry"]}`}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }


                                        {
                                            (!_.isEmpty(mandatoryFields["lostReason"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'lostReason')} /></label>
                                                        <ReactSelect
                                                            options={lostReasons}
                                                            name={'lostReason'}
                                                            placeholder={'select lost reason'}
                                                            onChange={this.handleLostReasonSave}
                                                            value={lostReason}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors['lostReason']} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={(canModifyEnquiry) ? false : true}
                                                        ></ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            !_.isEmpty(mandatoryFields["lostSubReason"]) || (!_.isEmpty(mandatoryFields["lostReason"]) && !_.isEmpty(lostSubReasons) && lostReason)
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'lostSubReason')} /></label>
                                                        <ReactSelect
                                                            options={lostSubReasons}
                                                            name={'lostSubReason'}
                                                            placeholder={'select lost sub reason'}
                                                            onChange={this.handleSelectSave}
                                                            value={lostSubReason}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors['lostSubReason']} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={(canModifyEnquiry) ? false : true}
                                                        ></ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["financeBM"]) || financeRequired)
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeBM')} /></label>
                                                        <ReactSelect
                                                            options={allFinanceUsers}
                                                            name={"financeBM"}
                                                            placeholder={'select finance business manager'}
                                                            onChange={this.handleSelectSave}
                                                            value={financeBM}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["financeBM"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={false}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["financeProvider"]) || (financeRequired && accessToFinanceTab))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeProvider')} /></label>
                                                        <ReactSelect
                                                            options={financeProviders}
                                                            name={"financeProvider"}
                                                            placeholder={'select finance provider'}
                                                            onChange={this.handleSelectSave}
                                                            value={financeProvider ? financeProvider : null}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["financeProvider"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={false}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (!_.isEmpty(mandatoryFields["financeStartDate"]) || (financeRequired && accessToFinanceTab))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeStartDate')} /></label>
                                                        <AntDatePicker
                                                            value={financeStartDate ? moment.unix(financeStartDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'financeStartDate'}
                                                            onChange={(e) => { this.handleDeliveryDateChange(e, 'financeStartDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["financeStartDate"]}`}
                                                            allowClear={false}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (!_.isEmpty(mandatoryFields["financeTerms"]) || (financeRequired && accessToFinanceTab))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeTerms')} /></label>
                                                        <ReactSelect
                                                            options={defaultFinanceTerms}
                                                            name={"financeTerms"}
                                                            placeholder={"finance terms"}
                                                            onChange={this.handleSelectSave}
                                                            value={financeTerms ? financeTerms : null}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["financeTerms"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={false}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["financeAmount"]) || (financeRequired && accessToFinanceTab))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeAmount')} /></label>
                                                        <NumberFormat
                                                            id="financeAmount_fields"
                                                            decimalScale={2}
                                                            thousandSeparator={true}
                                                            allowNegative={true}
                                                            prefix={currencySymbol}
                                                            placeholder={currencySymbol}
                                                            className={`form-control ${this.state.errors["financeAmount"]}`}
                                                            onValueChange={this.onCurrencyChange.bind(this, 'financeAmount')}
                                                            value={financeAmount ? financeAmount : null}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["financeRate"]) || (financeRequired && accessToFinanceTab))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeRate')} /></label>
                                                        <NumberFormat
                                                            id="financeRate_fields"
                                                            decimalScale={2}
                                                            allowNegative={false}
                                                            thousandSeparator={true}
                                                            allowLeadingZeros={false}
                                                            suffix={'%'}
                                                            placeholder={'finance interest rate'}
                                                            className={`form-control ${this.state.errors["financeRate"]}`}
                                                            onValueChange={this.onCurrencyChange.bind(this, 'financeRate')}
                                                            value={financeRate ? financeRate : null}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["financeStatus"]) || this.props.financeRequired)
                                                ?
                                                <>
                                                    {
                                                        (!financeEnabled && !drivaEnabled)
                                                            ?
                                                            <>
                                                                <div className="form-group col-md-12">
                                                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'finance')} /></label>
                                                                    <div className="form-control">
                                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'isFinanceRequired')} /></label>
                                                                        <span className="switch switch-sm float-right">
                                                                            <InputCheckBox
                                                                                className="switch"
                                                                                id={'is-FinanceRequired'}
                                                                                name={'isFinanceRequired'}
                                                                                checked={isFinanceRequired ? true : false}
                                                                                onChange={(e) => { this.handleCheckChange(e) }} />
                                                                            <label htmlFor={'is-FinanceRequired'}></label>
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                                {
                                                                    (isFinanceRequired)
                                                                        ?
                                                                        <>
                                                                            <div className="form-group col-md-12">
                                                                                <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeStatus')} /></label>
                                                                                <ReactSelect
                                                                                    options={financeStatuses}
                                                                                    name={'financeStatus'}
                                                                                    placeholder={'select finance status'}
                                                                                    onChange={this.handleSelectSave}
                                                                                    value={financeStatus}
                                                                                    classNamePrefix={`cursor-pointer ${this.state.errors['financeStatus']} basic-select`}
                                                                                    removeClearable={true}
                                                                                    isDisabled={(isFinanceRequired) ? false : true}
                                                                                ></ReactSelect>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    (financeStatus)
                                                                        ?
                                                                        <>
                                                                            <tr>
                                                                                <td className='text-left label-text'><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeStatus')} /></td>
                                                                                <td className='text-left'>
                                                                                    <ReactSelect
                                                                                        options={__financestatus}
                                                                                        name={'financeStatus'}
                                                                                        placeholder={'select finance status'}
                                                                                        onChange={this.handleSelectSave}
                                                                                        value={financeStatus}
                                                                                        classNamePrefix={`cursor-pointer ${this.state.errors['financeStatus']} basic-select`}
                                                                                        removeClearable={true}
                                                                                        isDisabled={true}
                                                                                    ></ReactSelect>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["afterMarketBM"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'afterMarketBM')} /></label>
                                                        <ReactSelect
                                                            options={_.uniqBy(allAfterMarketUsers, 'value')}
                                                            name={"afterMarketBM"}
                                                            placeholder={'select aftermarket manager'}
                                                            onChange={this.handleSelectSave}
                                                            value={afterMarketBM}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["afterMarketBM"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={false}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (!_.isEmpty(mandatoryFields["afterMarketStatus"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'afterMarketStatus')} /></label>
                                                        <ReactSelect
                                                            options={afterMarketStatuses}
                                                            name={'afterMarketStatus'}
                                                            placeholder={'select after market status'}
                                                            onChange={this.handleSelectSave}
                                                            value={afterMarketStatus}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors['afterMarketStatus']} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={canModifyEnquiry ? false : true}
                                                        ></ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }


                                        {
                                            (!_.isEmpty(mandatoryFields["depositAmount"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'depositAmount')} /></label>
                                                        <NumberFormat
                                                            id={'depositAmount'}
                                                            decimalScale={2}
                                                            thousandSeparator={true}
                                                            allowNegative={true}
                                                            prefix={currencySymbol}
                                                            placeholder={currencySymbol}
                                                            className={`form-control ${this.state.errors["depositAmount"]}`}
                                                            onValueChange={this.onCurrencyChange.bind(this, 'depositAmount')}
                                                            value={depositAmount ? depositAmount : null}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["depositReciptNumber"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'depositReciptNumber')} /></label>
                                                        <InputText
                                                            name="depositReciptNumber"
                                                            value={depositReciptNumber}
                                                            onChange={this.handleOnChange}
                                                            autoComplete="off"
                                                            placeholder={'deposit receipt number'}
                                                            className={`form-control ${this.state.errors["depositReciptNumber"]}`}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["grossAmount"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'grossAmount')} /></label>
                                                        <NumberFormat
                                                            id={'grossAmount'}
                                                            decimalScale={2}
                                                            thousandSeparator={true}
                                                            allowNegative={true}
                                                            prefix={currencySymbol}
                                                            placeholder={currencySymbol}
                                                            className={`form-control ${this.state.errors["grossAmount"]}`}
                                                            onValueChange={this.onCurrencyChange.bind(this, 'grossAmount')}
                                                            value={grossAmount ? grossAmount : null}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["contractNumber"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'contractNumber')} /></label>
                                                        <InputText
                                                            name="contractNumber"
                                                            value={contractNumber}
                                                            onChange={this.handleOnChange}
                                                            autoComplete="off"
                                                            placeholder={'sales contract number'}
                                                            className={`form-control ${this.state.errors["contractNumber"]}`}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (ismodifyStatusDate && (status && (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD)) && !_.isEmpty(wonDate))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={isAfterSales ? 'closeWonDate' : (CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won') + ' Date')} /></label>
                                                        <AntDatePicker
                                                            value={wonDate ? moment.unix(wonDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'wonDate'}
                                                            onChange={(e) => { this.handleDeliveryDateChange(e, 'wonDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["wonDate"]}`}
                                                            allowClear={false}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (ismodifyStatusDate && (status && status === enquiryStatus.DELIEVERD) && !_.isEmpty(deliveredDate))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered') + ' Date'} /></label>
                                                        <AntDatePicker
                                                            value={deliveredDate ? moment.unix(deliveredDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'deliveredDate'}
                                                            onChange={(e) => { this.handleDeliveryDateChange(e, 'deliveredDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["deliveredDate"]}`}
                                                            allowClear={false}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (ismodifyStatusDate && (status && (status === enquiryStatus.LOST || status === enquiryStatus.PENDINGLOST || status === enquiryStatus.PROSPECTLOST)) && !_.isEmpty(lostDate))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'lost', 'Lost') + ' Date'} /></label>
                                                        <AntDatePicker
                                                            value={lostDate ? moment.unix(lostDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'lostDate'}
                                                            onChange={(e) => { this.handleDeliveryDateChange(e, 'lostDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["lostDate"]}`}
                                                            allowClear={false}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>

                                    {/* {!_.isEmpty(enquiryOptions) && enquiryOptions.map((info, index) => (
                                        <div className="form-row " key={info.value}>
                                            {
                                                (!_.isEmpty(mandatoryFields[info.value]))
                                                    ?
                                                    <>
                                                        <div key={info.value} className="form-group col-md-12">
                                                            <div className="mt-2">
                                                                <label>{info.label}</label>
                                                                <span className={`switch switch-sm float-right pt-1 pb-2 ${this.state.errors[info.value] ? 'switch-error' : ''}`}>
                                                                    <InputCheckBox
                                                                        className="switch"
                                                                        id={index + '_' + info.value}
                                                                        name={info.value}
                                                                        checked={(!_.isEmpty(options) && options.indexOf(info.value) >= 0) ? true : false}
                                                                        onChange={(e) => { this.handleOptionsChange(e) }} />
                                                                    <label htmlFor={index + '_' + info.value}></label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    ))} */}

                                    {!_.isEmpty(enquiryOptionsDF) && enquiryOptionsDF.filter(m => m.active === true).map((info, index) => (
                                        <div className="form-row " key={info.value}>
                                            {
                                                (!_.isEmpty(mandatoryFields[info.value]))
                                                    ?
                                                    <>
                                                        <div key={info.value} className="form-group col-md-12">
                                                            <label>{info.name}</label>
                                                            <DynamicInput
                                                                index={index}
                                                                data={info}
                                                                errors={this.state.errors ? this.state.errors : {}}
                                                                id={info.value}
                                                                value={dynamicFields ? dynamicFields[info.value] : null}
                                                                handleCheckChange={this.handleDynamicCheckChange}
                                                                handleOnChange={this.handleDynamicOnChange}
                                                                saveEnquiryField={this.saveDynamicField}
                                                                cancelEnquiryField={this.cancelDynamicField}
                                                                onCurrencyChange={this.onDynamicNumberChange}
                                                                handleDateChange={this.handleDynamicDateChange}
                                                                handleSelectSave={this.handleDynamicSelectSave}
                                                                handleMultiSelectChange={this.handleDynamicMultiSelectChange}
                                                                currencySymbol={currencySymbol}
                                                                inputAutoFormat={false}
                                                            >
                                                            </DynamicInput>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    ))}

                                    {
                                        !_.isEmpty(this.props.mandatoryFields) && !_.isEmpty(this.props.requirements) && !_.isEmpty(requirementErrorIds)
                                            ?
                                            <>
                                                <div className="form-row ">
                                                    <label className="form-group col-md-12"><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'Requirements')} /></label>
                                                    {
                                                        this.props.requirements.filter(m => requirementErrorIds.find(e => e === m.documentID)).map((_requirement, index) => {

                                                            return <div className={`vehicle-item`} key={index} id={`ReqField_${_requirement.documentID}`}>
                                                                <Requirements
                                                                    key={index}
                                                                    requirement={_requirement}
                                                                    handleEdit={this.handleEditRequirement}
                                                                    enquiryID={_requirement.enquiryID}
                                                                    dealersettings={dealersettings}
                                                                    hideMore={true}
                                                                    isMultiple={true}
                                                                    isPrimary={true}
                                                                    enquiry={this.props.enquiry}
                                                                >
                                                                </Requirements>
                                                                {
                                                                    requirementErrors && !_.isEmpty(requirementErrors[_requirement.documentID])
                                                                        ?
                                                                        <div className="multi-vehicle-validate" onClick={(e) => { e.preventDefault(); this.handleEditRequirement(_requirement) }}>
                                                                            <div className="multi-vehicle-validate-alert multi-vehicle-alert-red">
                                                                                <b className="alert-link">Required:</b>
                                                                                {
                                                                                    requirementErrors[_requirement.documentID].map((_err, index) => {
                                                                                        return <><>{index > 0 ? ', ' : ''}</> <Translate key={index} text={_err} /></>
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </div>

                                                        })
                                                    }
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        (!_.isEmpty(mandatoryFields["lostNotes"]))
                                            ?
                                            <>
                                                <div className="form-row ">
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'lostNotes')} /></label>
                                                        <MentionEditor
                                                            mentionsData={this.mentionsData}
                                                            onMentionChange={(text) => { this.onMentionChange(text, 'lostNotes') }}
                                                            onMentionSelect={this.onMentionSelect}
                                                            notes={lostNotes ? lostNotes : ''}
                                                            focusOnMount={false}
                                                            isNoteEmpty={this.state.errors['lostNotes'] ? true : false}
                                                            teamMentions={this.state.teamMentions}
                                                            clearTeamMentions={this.clearTeamMentions}
                                                            clientUsers={this.props.clientUsers}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                    {
                                        (!_.isEmpty(mandatoryFields["stageNotes"]))
                                            ?
                                            <>
                                                <div className="form-row ">
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'notes'} /></label>
                                                        <MentionEditor
                                                            mentionsData={[]}
                                                            onMentionChange={(text) => { this.onMentionChange(text, 'stageNotes') }}
                                                            //onMentionSelect={this.onMentionSelect}
                                                            notes={stageNotes ? stageNotes : ''}
                                                            focusOnMount={false}
                                                            isNoteEmpty={this.state.errors['stageNotes'] ? true : false}
                                                            teamMentions={this.state.teamMentions}
                                                            clearTeamMentions={this.clearTeamMentions}
                                                            clientUsers={this.props.clientUsers}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'done'} />
                        </button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}
