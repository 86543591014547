import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import toast from 'toasted-notes'
import { ReactSelect, ContentOverlay, AntDatePicker, PopUpModal } from '../../components';
import { checkoutFields, dynColVM, dateTypes } from './viewmodel';
import common from '../../services/common';
import _images from '../../images'
import { Dropdown, ProgressBar } from 'react-bootstrap'
import { CustomTableFields, CustomToggle, CustomMenu } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import { reportDynColumns } from '../reports/viewModel'
import NumberFormat from 'react-number-format';
import ReactExport from 'react-export-excel-xlsx-fix';
import ScheduleReport from "../reports/scheduleReport";
import cronstrue from 'cronstrue';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { recommendationVM } from '../settings/tradeinProworkflow/lookups/viewModel';
import { DatePicker, Spin } from "antd";
import "antd/dist/antd.css";
import EnquiryList from '../dashboard/enquiryList'
import TestDriveList from '../dashboard/testdriveList'
import ActivityList from '../dashboard/activitiesList'
import TradeInList from '../dashboard/tradeinList'
import QuoteList from '../dashboard/quotesList'
import LogListModel from "../reports/logsList";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const DailyCheckout = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 60), windowHeight: (window.innerHeight - 170) })
    const [performance, setPerformance] = useState([])
    const [checkoutData, setCheckoutData] = useState([])
    const [tableFields, setTableFields] = useState(checkoutFields)
    const [loader, setLoader] = useState(true)
    const [filter, setFilter] = useState({ type: 'date', format: 'DD/MM/YYYY', date: moment().format('YYYY-MM-DD'), month: moment().format('MMMM') })
    const [checkDataload, setDataLoad] = useState(true)
    const [sortConfig, setSortConfig] = useState();
    const [searchField, setSearchField] = useState('')
    const [show, setShow] = useState(false)
    const [columnFields, setColumnFields] = useState(reportDynColumns.filter(a => a.reportType && a.reportType.includes('salesPerformance')))
    const [dynamicCols, setDynamicCols] = useState(!_.isEmpty(props.dealersettings.client.salesPerformance) ? props.dealersettings.client.salesPerformance : reportDynColumns.filter(a => a.checkoutDefault === true).map(r => { return r.value }));
    const [dynamicFields, setDynamicFields] = useState(!_.isEmpty(props.dealersettings.client.salesPerformance) ? props.dealersettings.client.salesPerformance : reportDynColumns.filter(a => a.checkoutDefault === true).map(r => { return r.value }));
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [csvData, setCSVData] = useState([])
    const [schedulerShow, setSchedulerShow] = useState({
        show: false
    })
    const [schedueReport, setSchedeReport] = useState({});
    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState()
    const [monthData, setMonthData] = useState([])
    const [monthDataCheck, setMonthDataCheck] = useState([])
    const [monthLoader, setMonthLoader] = useState(true)
    const [monthDate, setMonthDate] = useState({ type: 'date', format: 'DD/MM/YYYY', date: moment().format('YYYY-MM-DD'), month: moment().format('MMMM') })
    const node = useRef();
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false,
        logs: false,
        quotes: false,
        sheduleReport: false,
        scheduleActivity: false,
    })
    const [popHeader, setPopHeader] = useState()
    const [reportfilter, setReportFilter] = useState(null)
    const [counts, setCounts] = useState([])
    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');
    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const multiTDperLead = props?.dealersettings?.client?.clientSettings?.multiTDperLead === true;
    const disableUserSettings = props?.dealersettings?.rolePermissions?.permissions?.disableUserSettings ? true : false;

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 60),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.addEventListener("mousedown", handleClick);
        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener("mousedown", handleClick);
        }
    }, [])
    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        const refScheduleReport = window.firebase.firestore().doc(`schedular/${props.dealersettings.client.id}-performance`)
            .onSnapshot(doc => {
                if (doc.exists) {
                    setSchedeReport(
                        {
                            ...doc.data(),
                            documentID: doc.id
                        })
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
            window.refcheckoutSnapshot && window.refcheckoutSnapshot();
            window.refcheckoutSnapshot1 && window.refcheckoutSnapshot1();

        }
    }, [])

    useEffect(() => {
        window.firebase.firestore().collection(`clientSettings/${props.dealersettings.oemID}/teams`)
            .where('clientID', '==', props.dealersettings.client.id)
            .get()
            .then(clientTeamsSnapshot => {
                var _clientteams = []
                if (clientTeamsSnapshot.docs.length > 0) {
                    clientTeamsSnapshot.docs.forEach(rec => {
                        if (rec.data().user_ids && rec.data().user_ids.length > 0) {
                            _clientteams.push({
                                ...rec.data(),
                                id: rec.id,
                                label: rec.data().name,
                                value: rec.id,
                                user_ids: props.clientUsers.filter(a => !Boolean(a.isDisabled)
                                    && rec.data().user_ids.includes(a.documentID)).map(r => { return r.documentID })
                            })
                        }
                    })
                }
                setTeams(_clientteams);
                if (_clientteams.length === 0) {
                    setLoader(false)
                    setSelectedTeams(null)
                }
                else {
                    setSelectedTeams(_clientteams[0])
                }
            });

    }, [])

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/salesPerformance/${props.dealersettings.client.id}`)
            .get()
            .then(doc => {
                if (!doc.exists || _.isEmpty(doc.data().columns)) {
                    window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/salesPerformance/${props.dealersettings.client.id}`)
                        .set({
                            columns: dynamicCols.map(rec => {
                                return {
                                    ..._.pick(_.find(reportDynColumns, { value: rec }), _.keys(dynColVM))
                                }
                            })
                        }, { merge: true })
                }
            })
    }, [])

    useEffect(() => {
        if (!monthLoader)
            return;

        window.refcheckoutSnapshot1 = window.firebase.firestore().collection('checkout')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('logDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment(monthDate.date).startOf('month')._d))
            .where('logDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment(monthDate.date).endOf('month')._d))
            .onSnapshot(querySnapshot => {
                let snapshotData = [];
                querySnapshot.forEach((rec) => {
                    snapshotData.push({
                        ...rec.data(),
                        'id': rec.id,
                        'date': moment.unix(rec.data().logDate.seconds).format('YYYY-MM-DD')
                    })
                })
                setMonthDataCheck(snapshotData);
                setMonthLoader(false)
            })
    }, [monthLoader])

    useEffect(() => {
        if (_.isEmpty(performance))
            return

        window.refcheckoutSnapshot = window.firebase.firestore().collection('checkout')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('logDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment(filter.date).startOf('month')._d))
            .where('logDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment(filter.date).endOf('month')._d))
            .onSnapshot(querySnapshot => {

                let snapshotData = [];
                querySnapshot.forEach((rec) => {
                    snapshotData.push({ ...rec.data(), 'id': rec.id })
                })

                let _data = []
                props.clientUsers.forEach(rec => {
                    _data.push({
                        documentID: rec.documentID,
                        ...getPerformanceData(rec.documentID, performance, snapshotData),
                    })
                })
                setMonthData(_data)
            })
    }, [filter.month, performance])

    useEffect(() => {
        if (_.isEmpty(checkoutData))
            return

        let _headers = [];
        let _headerStyle = {
            style:
            {
                font: {
                    sz: 13,
                    bold: true
                },
                fill: { patternType: "solid", fgColor: { rgb: "FFdce6f1" } }
            }
        }
        let _cellStyle = { style: { font: { sz: 11 } } };
        let _numStyle = { style: { font: { sz: 11 } }, numFmt: "0" };
        let _footerStyle = {
            style:
            {
                font: {
                    sz: 13,
                    bold: true
                },
                fill: { patternType: "solid", fgColor: { rgb: "FFdce6f1" } }
            }
        };
        tableFields.filter(a => a.value !== 'performance').forEach((val) => {
            _headers.push({ title: val.label, dynValue: val.value, dynWidth: val.label.toString().length, ..._headerStyle })
        })


        var exportData = []
        checkoutData.forEach(rec => {
            let obj = rec;
            let arrCols = []
            tableFields.filter(a => a.value !== 'performance').forEach(col => {
                var cols = reportDynColumns.find(item => item.value === col.value)
                if (cols)
                    arrCols.push({ value: `${cols && cols.currency === true ? currencySymbol : ''}${obj[col.value]}${cols && cols.percentage === true ? '%' : ''}`, ..._cellStyle, dynValue: col.value, dynWidth: obj[col.value] ? obj[col.value].toString().length : 0 })
                else
                    arrCols.push({ value: `${obj[col.sortValue]}`, ..._cellStyle, dynValue: col.value, dynWidth: obj[col.sortValue] ? obj[col.sortValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])
    }, [checkoutData])

    useEffect(() => {
        if (!checkDataload || _.isEmpty(selectedTeams))
            return
        let _performance = [];
        const users = props.clientUsers.filter(a =>
            selectedTeams.user_ids.includes(a.documentID) &&
            !Boolean(a.isDisabled))
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.checkoutPerformance &&
            props.dealersettings.client.settings.checkoutPerformance.filter(a => a.active === true)
                .map(rec => {
                    return _performance.push({
                        label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: rec.color, width: '15px', height: '15px', marginTop: '3px' }}></div>{rec.name}</div>),
                        value: rec.value,
                        color: rec.color,
                        name: rec.name,
                    })
                })
        setPerformance(_performance);
        setTableFields([
            ...checkoutFields.filter(a => filter.type === 'month' ? a.value !== 'performance' : true),
            ..._performance.map(rec => {
                return {
                    name: <div style={{ color: rec.color }}>{rec.name}</div>,
                    label: rec.name,
                    value: rec.value,
                    flex: 1,
                    default: true,
                    sortValue: `sort-${rec.value}`,
                }
            }),
            ...dynamicFields.map(rec => {
                return {
                    ..._.find(reportDynColumns.map(r => {
                        return (r.value === 'preApproved' || r.value === 'notpreApproved') && props?.dealersettings?.client?.clientSettings?.creditScore?.approved ?
                            {
                                ...r,
                                name: `${r.name} ${r.value === 'preApproved' ? `( >= ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})` : `( < ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})`}`,
                                label: `${r.label} ${r.value === 'preApproved' ? `( >= ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})` : `( < ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})`}`,
                            }
                            : { ...r }
                    }), { value: rec }),
                    sortValue: rec,
                    default: true,
                    reOrder: true
                }
            })
        ])
        async function getReportData() {
            let _reportData = [];
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            const resp = await reportData({
                type: props?.dealersettings?.mvwReport ? "getDynamicReportV2" : "getDynamicReport",
                params: JSON.stringify({
                    clientID: props.dealersettings.client.id,
                    reportJSON: JSON.stringify({
                        clientID: props.dealersettings.client.id,
                        groupBy: 'user',
                        groupSubtype: users.map(rec => { return rec.id }),
                        columns: dynamicFields,
                        pipeline: props.dealersettings &&
                            props.dealersettings.client &&
                            props.dealersettings.client.settings &&
                            props.dealersettings.client.settings.allPipelines &&
                            _.map(props.dealersettings.client.settings.allPipelines.filter(item => (inclInboundLeadsinRpt ? true : item.value !== 'LeadsBucket') && !Boolean(item.disableReport)), function (e) { return e.value; })
                    }),
                    startDate: moment(filter.date).startOf(filter.type).format('YYYY-MM-DD'),
                    endDate: moment(filter.date).endOf(filter.type).format('YYYY-MM-DD'),
                    timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                    creditScore: props?.dealersettings?.client?.clientSettings?.creditScore?.approved,
                    calcCurrentStatus,
                    multiTDperLead
                })
            });
            if (resp.data.success) {
                _reportData = convertReportData(resp.data.data)
            }

            window.firebase.firestore().collection('checkout')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('logDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment(filter.date).startOf(filter.type)._d))
                .where('logDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment(filter.date).endOf(filter.type)._d))
                .get()
                .then(querySnapshot => {

                    let snapshotData = [];
                    querySnapshot.forEach((rec) => {
                        snapshotData.push({ ...rec.data(), 'id': rec.id })
                    })

                    let _data = []
                    _.orderBy(props.clientUsers, ['name'], ['asc']).filter(a =>
                        selectedTeams.user_ids.includes(a.documentID) &&
                        !Boolean(a.isDisabled)).forEach(rec => {
                            _data.push({
                                documentID: rec.documentID,
                                name: rec.name,
                                profileImage: rec.profileImage,
                                userName: rec.name,
                                department: rec.department,
                                ...getPerformanceData(rec.documentID, _performance, snapshotData),
                                ..._.find(_reportData, { id: rec.documentID })
                            })
                        })
                    setCheckoutData(_data)
                    setLoader(false)
                    setDataLoad(false)
                })
        }
        getReportData()
    }, [checkDataload, selectedTeams])

    const convertReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][props.dealersettings.client.id] : []
        let reports = [];
        _report.forEach(rec => {
            reports.push({
                ...rec,
                arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                show: rec.arrshow ? rec.arrshow.length : 0,
                showCount: rec.arrshow ? rec.arrshow.length : 0,
                noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                //avg response time
                arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                responseTime: common.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, props.dealersettings.client, ''),
                //max reposnse time
                arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                maxresponseTime: common.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, ''),
                //min response time
                arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                minresponseTime: common.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, ''),
                open: rec.arropen ? rec.arropen.length : 0,
                statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                //won: rec.arrwon ? rec.arrwon.length : 0,
                arrwon: rec.arrwon ? _.uniq(rec.arrwon.map(r => { return r.documentID })) : [],
                won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,

                arrwonTD: rec.arrwonTD ? _.uniq(rec.arrwonTD.map(r => { return r.documentID })) : [],
                wonTD: rec.arrwonTD ? _.sumBy(rec.arrwonTD, "soldCount") : 0,

                arrdelivered: rec.arrdelivered ? _.uniq(rec.arrdelivered.map(r => { return r.documentID })) : [],
                delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                lost: rec.arrlost ? rec.arrlost.length : 0,
                prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                //delivered: rec.arrdelivered ? rec.arrdelivered.length : 0,
                walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                total: rec.arrtotal ? rec.arrtotal.length : 0,
                tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                allocated: rec.arrallocated ? rec.arrallocated.length : 0,
                newCAP: rec.arrnewCAP ? rec.arrnewCAP.length : 0,
                lostEnq: rec.arrlostEnq ? rec.arrlostEnq.length : 0,
                outdated: rec.arroutdated ? rec.arroutdated.length : 0,
                noActivity: rec.arrnoActivity ? rec.arrnoActivity.length : 0,
                unactionedLeads: rec.arrunactionedLeads ? rec.arrunactionedLeads.length : 0,
                noNotes: rec.arrnoNotes ? rec.arrnoNotes.length : 0,
                noCalls: rec.arrnoCalls ? rec.arrnoCalls.length : 0,
                noMessage: rec.arrnoMessage ? rec.arrnoMessage.length : 0,
                totalStock: rec.arrtotalStock ? rec.arrtotalStock.length : 0,
                stockwithEnq: rec.arrstockwithEnq ? rec.arrstockwithEnq.length : 0,
                stockwithoutEnq: rec.arrstockwithoutEnq ? rec.arrstockwithoutEnq.length : 0,
                overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                quotes: rec.arrquotes ? rec.arrquotes.length : 0,
                arrquotes: rec.arrquotes ? _.uniq(rec.arrquotes.map(r => { return r.documentID })) : [],
                arrwonAmount: rec.arrwonAmount ? _.uniq(rec.arrwonAmount.map(r => { return r.documentID })) : [],
                arrgrossAmount: rec.arrgrossAmount ? _.uniq(rec.arrgrossAmount.map(r => { return r.documentID })) : [],
                arrdepositAmount: rec.arrdepositAmount ? _.uniq(rec.arrdepositAmount.map(r => { return r.documentID })) : [],
                arrquoteAmount: rec.arrquoteAmount ? _.uniq(rec.arrquoteAmount.map(r => { return r.documentID })) : [],
                wonAmount: rec.arrwonAmount ? Math.round((_.sumBy(rec.arrwonAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                grossAmount: rec.arrgrossAmount ? Math.round((_.sumBy(rec.arrgrossAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                depositAmount: rec.arrdepositAmount ? Math.round((_.sumBy(rec.arrdepositAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                quoteAmount: rec.arrquoteAmount ? Math.round((_.sumBy(rec.arrquoteAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                creditScore: rec.arrcreditScore ? rec.arrcreditScore.length : 0,
                preApproved: rec.arrpreApproved ? rec.arrpreApproved.length : 0,
                notpreApproved: rec.arrnotpreApproved ? rec.arrnotpreApproved.length : 0,
            })
        });

        reports = reports.map(rec => {
            return {
                ...rec,
                carryover: rec.arrcarryover.length,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: rec.total > 0 ? Math.round((100 / rec.total) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: rec.total > 0 ? Math.round((100 / rec.total) * rec.won) : 0,
                scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
                showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
                noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })

        return reports;
    }

    const getPerformanceData = (id, _performance, _snapshotData) => {
        let _data = {};
        let sum = 0;
        _performance.forEach(rec => {
            _data = {
                ..._data,
                [rec.value]: <div style={{ color: rec.color, fontSize: '16px', fontWeight: 800 }}>{_snapshotData.filter(a => a[id] === rec.value).length}</div>,
                [`sort-${rec.value}`]: _snapshotData.filter(a => a[id] === rec.value).length,
                performance: _.find(_snapshotData, { id: `${props.dealersettings.client.id}-${moment(filter.date).format('YYYY-MM-DD')}` }) ?
                    _.find(_snapshotData, { id: `${props.dealersettings.client.id}-${moment(filter.date).format('YYYY-MM-DD')}` })[id] : null
            }
            sum += _data[`sort-${rec.value}`];
        })
        _performance.forEach(rec => {
            _data = {
                ..._data,
                [`percentage-${rec.value}`]: sum > 0 ? Math.round((100 / sum) * _data[`sort-${rec.value}`]) : 0,
            }
        })
        return _data;
    }

    const setPerformanceData = (value) => {
        let _data = {};
        performance.forEach(rec => {
            _data = {
                ..._data,
                [rec.value]: <div style={{ color: rec.color, fontSize: '16px', fontWeight: 800 }}>{rec.value === value ? 1 : 0}</div>,
                [`sort-${rec.value}`]: rec.value === value ? 1 : 0,
                performance: value
            }
        })
        return _data;
    }

    const handleDropdownChange = (id, value) => {
        window.firebase.firestore().doc(`checkout/${props.dealersettings.client.id}-${moment(filter.date).format('YYYY-MM-DD')}`)
            .set({
                [id]: value,
                logDate: window.firebase.firestore.Timestamp.fromDate(moment(`${filter.date} 09:00:00`).toDate()),
                clientID: props.dealersettings.client.id,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now()
            }, { merge: true })
            .then(snap => {
                setCheckoutData(checkoutData.map(rec => {
                    return rec.documentID === id ? {
                        ...rec,
                        ...setPerformanceData(value)
                    } : {
                        ...rec
                    }
                }))
                toast.notify('Performance logged successfully', {
                    duration: 2000
                })
            })
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...checkoutData.filter(a =>
            (filter.searchText ? a.userName.toLowerCase().includes(filter.searchText.toLowerCase()) : true) &&
            (filter.department ? a.department === filter.department : true)
        )];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [checkoutData, sortConfig, filter.searchText, filter.department, filter.reOrderCol]);

    const handleCancel = () => {
        setShow(false)
    }

    const handleSavesettings = () => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/salesPerformance/${props.dealersettings.client.id}`)
            .set({
                columns: dynamicCols.map(rec => {
                    return {
                        ..._.pick(_.find(reportDynColumns, { value: rec }), _.keys(dynColVM))
                    }
                })
            }, { merge: true })
            .then(() => {
                if (schedueReport)
                    window.firebase.firestore().doc(`schedular/${schedueReport.documentID}`)
                        .set({
                            columns: dynamicCols
                        }, { merge: true })
                setDynamicFields(dynamicCols)
                setSettingsLoader(false)
                setShow(false)
                setLoader(true)
                setDataLoad(true)
                props.updateDealerSettings('salesPerformance', dynamicCols)
                toast.notify('Settings updated successfully', {
                    duration: 2000
                })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });
    }

    const handleTextchange = (e) => {
        setSearchField(e.target.value);
        if (e.target.value) {
            setColumnFields([...reportDynColumns.filter(item => item.reportType && item.reportType.includes('salesPerformance') && item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
        }
        else {
            setColumnFields(reportDynColumns.filter(a => a.reportType && a.reportType.includes('salesPerformance')))
        }

    }

    const handleColsChecked = (e) => {
        setDynamicCols(e.target.checked ?
            [...dynamicCols.filter(v => v !== e.target.value), e.target.value] :
            [...dynamicCols.filter(v => v !== e.target.value)]
        )
        e.stopPropagation();
    }

    const unCheckFields = _.filter(columnFields, (v) => _.indexOf(dynamicFields, v.value) < 0);

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const startIndex = tableFields.filter(a => !Boolean(a.reOrder)).length;
        if (result.destination.index < startIndex) {
            result.destination.index = startIndex
        }
        const [removed] = tableFields.splice(result.source.index, 1);
        tableFields.splice(result.destination.index, 0, removed);
        setTableFields(tableFields)
        setFilter({
            ...filter,
            reOrderCol: !Boolean(filter.reOrderCol)
        })
        const dynFields = tableFields.filter(a => Boolean(a.reOrder)).map(r => { return r.value });
        setDynamicFields(dynFields)
        setDynamicCols(dynFields)
        props.updateDealerSettings('salesPerformance', dynFields)
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/salesPerformance/${props.dealersettings.client.id}`)
            .set({
                columns: tableFields.filter(a => Boolean(a.reOrder)).map(rec => {
                    return {
                        ..._.pick(_.find(reportDynColumns, { value: rec.value }), _.keys(dynColVM))
                    }
                })
            }, { merge: true })
        if (schedueReport)
            window.firebase.firestore().doc(`schedular/${schedueReport.documentID}`)
                .set({
                    columns: dynFields
                }, { merge: true })
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const handleModelClose = () => {
        setCounts(0)
        setModelShow({
            enquiry: false,
            testdrive: false,
            activity: false,
            logs: false,
            sheduleReport: false,
            scheduleActivity: false
        })
    }

    return (<>
        <div className='middle-wrapper'>
            <div className='filter-panel'>
                <div className='float-left fleet-head'>
                    <h3><Translate text={'Daily Performance Review'} /></h3>
                </div>
                <div className='float-right'>
                    <Dropdown className='enquiry-view-more float-right mt-0'>
                        <Dropdown.Toggle as={CustomToggle} className='common-button'>
                            <i className='ico icon-more'></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={CustomMenu}
                            ChildClass="more-dropdown dropsub-fix"
                            xplacement="bottom-end"
                        >
                            {
                                !disableUserSettings && !_.isEmpty(props.dealersettings)
                                    && !_.isEmpty(props.dealersettings.rolePermissions)
                                    && !_.isEmpty(props.dealersettings.rolePermissions.admin)
                                    && Object.keys(props.dealersettings.rolePermissions.admin).filter(function (id) {
                                        return props.dealersettings.rolePermissions.admin[id]
                                    }).some(e => e === 'clientSettings') ? (
                                    <Dropdown.Item eventKey="1" onClick={(e) => {
                                        props.history.push('/settings/company?key=users&item=teams');
                                    }}><i className="ico icon-activity-meeting"></i> <Translate text={'Manage Teams'} /> </Dropdown.Item>
                                ) : (<></>)
                            }
                            <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                                <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i><Translate text={'Export Excel'} /> </a>} filename={`sales-performance-review-${moment(filter.date).format(filter.format).replace(/\//g, '-')}`}>
                                    <ExcelSheet dataSet={csvData} name={`${moment(filter.date).format(filter.format).replace(/\//g, '-')}`} />
                                </ExcelFile>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="4" onClick={(e) => {
                                setSchedulerShow({
                                    show: true,
                                    title: 'Schedule Report',
                                    report: {
                                        documentID: `${props.dealersettings.client.id}-performance`,
                                        columns: dynamicFields
                                    }
                                })
                            }}><i className="ico icon-email"></i><Translate text={'Schedule Report'} />  </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        !_.isEmpty(schedueReport) && schedueReport.cron && schedueReport.enabled ? (
                            <div className="reportsuccess-item-btn mr-2 float-right">
                                <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                                <div className="reportsuccess-inner-text">
                                    <div className="reportsuccess-inner-text-top">  <Translate text={'scheduled'} /></div>
                                    <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                                </div>
                                <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                            </div>
                        ) : (<></>)
                    }
                </div>
            </div>
            <div className="filter-sub-panel">
                <div className='float-left'>
                    <div className="filter-search search-icon">
                        <div className='input-group template-search'>
                            <input placeholder="Search" aria-label="Search" value={filter.searchText}
                                onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        searchText: e.target.value
                                    })
                                }} />
                            <div className='input-group-append input-search-clear'>
                                {!_.isEmpty(filter.searchText) && (
                                    <a href='#' className='input-search-clear-icon' style={{ top: '9px' }}
                                        onClick={() =>
                                            setFilter({
                                                ...filter,
                                                searchText: ''
                                            })
                                        }>
                                        <i className='ico icon-remove'></i>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='float-left ml-2' style={{ width: '200px' }}>
                        <ReactSelect
                            options={teams.map(rec => {
                                return {
                                    label: rec.name,
                                    value: rec.value,
                                    data: rec
                                }
                            })}
                            name={`checkout-teams`}
                            placeholder={'select team'}
                            onChange={(e, data) => {
                                setSelectedTeams(e ? e.data : null)
                                setLoader(true)
                                setDataLoad(true)
                            }}
                            value={selectedTeams ? selectedTeams.value : null}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
                <div className='float-right'>
                    <div className="common-button dropdown-toggle float-right">
                        <div id="table-checkout-cols" className="table-field-settings" style={{ top: '4px', right: '7px' }}>
                            <Dropdown ref={node} drop='left' show={show} >
                                <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                    setShow(!show)
                                }}>
                                    <i className="ico icon-settings"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomTableFields} id="table-dynamic-cols-settings" className="dropdown-menu dropdown-menu-right" ChildClass="table-field-listing" xplacement="bottom-end"
                                    handleCancel={handleCancel}
                                    handleSave={handleSavesettings}
                                    settingsLoader={settingsLoader}
                                    handleTextchange={handleTextchange}
                                    searchField={searchField}
                                >
                                    <Dropdown.Header className="table-field-subheader"><Translate text={'show'} /></Dropdown.Header>
                                    {
                                        _.filter(columnFields, (v) => _.indexOf(dynamicFields, v.value) >= 0).map((col, index) => {
                                            return <div
                                                className="check-list"
                                                key={index}
                                                htmlFor={`field-checkbox-${col.value}`}>
                                                <div className="checkbox icheck-success w-100">
                                                    <input type="checkbox" id={`field-checkbox-${col.value}`}
                                                        checked={dynamicCols.some(e => e === col.value) ? true : false}
                                                        value={col.value}
                                                        onChange={(e) => {
                                                            handleColsChecked(e);
                                                        }}
                                                    />
                                                    <label htmlFor={`field-checkbox-${col.value}`}>{col.name}</label>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {
                                        unCheckFields && unCheckFields.length > 0
                                            ?
                                            <>
                                                <Dropdown.Header className="table-field-subheader"><Translate text={'hidden'} /></Dropdown.Header>
                                                {
                                                    unCheckFields.map((fields, index) => {
                                                        return <div key={index}>
                                                            <div
                                                                className="check-list"
                                                                key={index}>
                                                                <div className="checkbox icheck-success w-100">
                                                                    <input type="checkbox" id={`field-checkbox-${fields.value}`}
                                                                        value={fields.value}
                                                                        checked={dynamicCols.some(e => e === fields.value) ? true : false}
                                                                        onChange={(e) => {
                                                                            handleColsChecked(e);
                                                                        }}
                                                                    />
                                                                    <label htmlFor={`field-checkbox-${fields.value}`}>{fields.name}</label>
                                                                </div>
                                                            </div>
                                                        </div>;

                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                            </>

                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className={`float-right mr-2 ${loader ? 'btn-disable' : ''}`}>
                        <DatePicker
                            value={moment(filter.date)}
                            id={'checkout-date'}
                            onChange={(val) => {
                                setFilter({
                                    ...filter,
                                    date: moment(val).format('YYYY-MM-DD'),
                                    month: moment(val).format('MMMM')
                                })
                                setLoader(true)
                                setDataLoad(true)
                            }}
                            format={filter.format}
                            allowClear={false}
                            placeholder='DD/MM/YYYY'
                            className={`form-control`}
                            disabledDate={(current) => { return current && current > moment().endOf('day') }}
                            picker={filter.type}
                            dateRender={(current) => {
                                const style = {};
                                if (filter.type === 'date'
                                    && monthDataCheck.some(a =>
                                        selectedTeams
                                        && _.some(selectedTeams.user_ids, (v) => _.indexOf(Object.keys(a), v) >= 0)
                                        && a.date === moment(current).format('YYYY-MM-DD'))) {
                                    style.border = '1px solid #4466f2';
                                    style.color = '#4466f2';
                                    style.backgroundColor = '#d5e2f3'
                                }
                                else if (moment(current).format('YYYY-MM-DD') === filter.date) {
                                    style.border = '1px solid #4466f2';
                                }
                                return (
                                    <div className="ant-calendar-date" style={style}>
                                        {current.date()}
                                    </div>
                                );
                            }}
                            onPanelChange={(value, mode) => {
                                if (!value || mode !== 'date') {
                                    return;
                                }
                                setMonthDate({
                                    ...monthDate,
                                    date: value.format('YYYY-MM-DD'),
                                    month: value.format('MMMM')
                                })
                                setFilter({
                                    ...filter,
                                    date: moment(value).format('YYYY-MM-DD')
                                })
                                setMonthLoader(true);
                                // setTimeout(() => {
                                //     setMonthLoader(false);
                                // }, 1000);
                            }}
                            panelRender={(originalPanel) => {
                                return <Spin spinning={monthLoader}>{originalPanel}</Spin>;
                            }}
                        />
                    </div>
                    <div className="float-right mr-2" style={{ width: '110px' }}>
                        <ReactSelect
                            options={dateTypes}
                            name={`checkout-date-types`}
                            placeholder={'select'}
                            onChange={(e, data) => {
                                setFilter({
                                    ...filter,
                                    type: e.value,
                                    format: e.format,
                                    date: moment().format('YYYY-MM-DD'),
                                    month: moment().format('MMMM')
                                })
                                setLoader(true)
                                setDataLoad(true)
                            }}
                            value={filter.type}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
            </div>
            <div className="common-table">
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : teams.length === 0 ? (
                        (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                    <p><Translate text={'No teams found'} /></p>
                                    {
                                        !disableUserSettings && !_.isEmpty(props.dealersettings)
                                            && !_.isEmpty(props.dealersettings.rolePermissions)
                                            && !_.isEmpty(props.dealersettings.rolePermissions.admin)
                                            && Object.keys(props.dealersettings.rolePermissions.admin).filter(function (id) {
                                                return props.dealersettings.rolePermissions.admin[id]
                                            }).some(e => e === 'clientSettings') ? (
                                            <button className="btn btn-sm btn-default" onClick={() => {
                                                props.history.push('/settings/company?key=users&item=teams');
                                            }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                                <Translate text={'Create Team'} />
                                            </button>
                                        ) : (<></>)
                                    }

                                </div>  </div>
                        )
                    ) : (

                        <table className="table table-bordered" >
                            <thead>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="table" direction="horizontal">
                                        {(provided, snapshot) => (<tr  {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {
                                                tableFields.map((rec, index) => {
                                                    return <Draggable key={rec.value} draggableId={rec.value} index={index} isDragDisabled={!Boolean(rec.reOrder)}>
                                                        {(drgprovided, drgsnapshot) => (<td
                                                            ref={drgprovided.innerRef}
                                                            {...drgprovided.draggableProps}
                                                            {...drgprovided.dragHandleProps}
                                                            style={getItemStyle(
                                                                drgsnapshot.isDragging,
                                                                drgprovided.draggableProps.style
                                                            )}
                                                            key={index} className="head-light" width={`${rec.width ? `${rec.width}%` : `${Math.round((100 - _.sumBy(tableFields, 'width')) / _.countBy(tableFields, 'width').undefined)}%`}`}>
                                                            {
                                                                rec.sortValue ? (
                                                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        requestSort(`${rec.sortValue}`)
                                                                    }}>
                                                                        <div className="table-sort"><a href="#">
                                                                            {
                                                                                sortConfig && sortConfig.key === rec.sortValue ?
                                                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                            }
                                                                        </a>
                                                                        </div>
                                                                        <div className={`${Boolean(rec.reOrder) ? 'dragger-head' : ''}`}>{rec.name}</div>
                                                                    </div>
                                                                ) : (<div className={`${Boolean(rec.reOrder) ? 'dragger-head' : ''}`}>{rec.name}</div>)
                                                            }
                                                            {drgprovided.placeholder}
                                                        </td>)}
                                                    </Draggable>
                                                })
                                            }
                                            {provided.placeholder}
                                        </tr>)}
                                    </Droppable>
                                </DragDropContext>
                            </thead>

                            <tbody>
                                {
                                    sortedItems.length > 0 ?
                                        sortedItems.map((rec, index) => {
                                            return <tr key={index}>
                                                {
                                                    tableFields.map((val, index) => {
                                                        return <td key={index} width={`${val.width ? `${val.width}%` : `${Math.round((100 - _.sumBy(tableFields, 'width')) / _.countBy(tableFields, 'width').undefined)}%`}`}>
                                                            {
                                                                val.type === 'dropdown' ? (
                                                                    <ReactSelect
                                                                        options={performance}
                                                                        name={`dd-${rec.documentID}`}
                                                                        placeholder={'please select'}
                                                                        onChange={(e, data) => {
                                                                            setCheckoutData(checkoutData.map(r => {
                                                                                return r.documentID === rec.documentID ? {
                                                                                    ...r,
                                                                                    performance: e ? e.value : null
                                                                                } : {
                                                                                    ...r
                                                                                }
                                                                            }))
                                                                            handleDropdownChange(rec.documentID, e ? e.value : null)
                                                                        }}
                                                                        value={rec[val.value]}
                                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                                        removeClearable={false}
                                                                    >
                                                                    </ReactSelect>
                                                                ) : reportDynColumns.find(item => item.value === val.value) ?
                                                                    (<div className={`text-center cursor-pointer ${reportDynColumns.find(item => item.value === val.value) && reportDynColumns.find(item => item.value === val.value).cls ? reportDynColumns.find(item => item.value === val.value).cls : ''}`}
                                                                        onClick={() => {
                                                                            var cols = reportDynColumns.find(item => item.value === val.value)
                                                                            if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
                                                                                setCounts(rec[`arr${cols.value}`] ? rec[`arr${cols.value}`].length : 0)
                                                                                if (cols.type === 'activity') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                                    })
                                                                                    setPopHeader('Activity List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        activity: true
                                                                                    })
                                                                                }
                                                                                else if (cols.type === 'scheduleActivity') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                                    })
                                                                                    setPopHeader('Activity List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        scheduleActivity: true
                                                                                    })
                                                                                }
                                                                                else if (cols.type === 'log') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arr${cols.value}`],
                                                                                        columnValue: cols.value
                                                                                    })
                                                                                    setPopHeader('Customer Logs')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        logs: true
                                                                                    })
                                                                                }
                                                                                else if (cols.type === 'tradein') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                                    })
                                                                                    setPopHeader('Trade-In List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        tradein: true
                                                                                    })
                                                                                }
                                                                                else if (cols.type === 'testdrive' && multiTDperLead) {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                                    })
                                                                                    setPopHeader('Test Drives List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        testdrive: true
                                                                                    })
                                                                                }
                                                                                else if (cols.value === 'responseTime') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arrresponseLeads`]
                                                                                    })
                                                                                    setCounts(rec[`arrresponseLeads`] ? rec[`arrresponseLeads`].length : 0)
                                                                                    setPopHeader('Enquiry List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        enquiry: true
                                                                                    })
                                                                                }
                                                                                else if (cols.value === 'maxresponseTime') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arrmaxresponseLeads`]
                                                                                    })
                                                                                    setCounts(rec[`arrmaxresponseLeads`] ? rec[`arrmaxresponseLeads`].length : 0)
                                                                                    setPopHeader('Enquiry List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        enquiry: true
                                                                                    })
                                                                                }
                                                                                else if (cols.value === 'minresponseTime') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arrminresponseLeads`]
                                                                                    })
                                                                                    setCounts(rec[`arrminresponseLeads`] ? rec[`arrminresponseLeads`].length : 0)
                                                                                    setPopHeader('Enquiry List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        enquiry: true
                                                                                    })
                                                                                }
                                                                                else if (cols.columnType === 'service') {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                                    })
                                                                                    setPopHeader('Quotes')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        quotes: true
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    setReportFilter({
                                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                                    })
                                                                                    setPopHeader('Enquiry List')
                                                                                    setModelShow({
                                                                                        ...modelShow,
                                                                                        enquiry: true
                                                                                    })
                                                                                }

                                                                            }
                                                                        }}>
                                                                        {
                                                                            reportDynColumns.find(item => item.value === val.value).currency ? (
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    prefix={currencySymbol}
                                                                                    displayType={'text'}
                                                                                    value={rec[val.value]}
                                                                                />
                                                                            ) : `${rec[val.value]}${reportDynColumns.find(item => item.value === val.value).percentage ? '%' : ''}`

                                                                        }
                                                                    </div>)
                                                                    : val.value === 'user' ?
                                                                        (
                                                                            <div className="user-td-bar-wrap user-td-bar-flex">
                                                                                <div className="user-td-bar-avatar">
                                                                                    <img className="rounded-circle img-object-fit" alt="" src={common.showUserAvatar(rec.profileImage, rec.name)} />
                                                                                </div>
                                                                                <div className="user-td-bar-detail">
                                                                                    <h3>{rec.name}</h3>
                                                                                    <ProgressBar>
                                                                                        {
                                                                                            performance.map((r, i) => {
                                                                                                return <ProgressBar key={i}
                                                                                                    style={{ backgroundColor: r.color }}
                                                                                                    now={_.find(monthData, { documentID: rec.documentID }) ? _.find(monthData, { documentID: rec.documentID })[`percentage-${r.value}`] : 0}
                                                                                                    label={_.find(monthData, { documentID: rec.documentID })
                                                                                                        && _.find(monthData, { documentID: rec.documentID })[`percentage-${r.value}`] > 9 ? `${_.find(monthData, { documentID: rec.documentID })[`percentage-${r.value}`]}%` : ''}
                                                                                                />
                                                                                            })
                                                                                        }
                                                                                    </ProgressBar>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : (<>{rec[val.value]}</>)

                                                            }
                                                        </td>
                                                    })
                                                }

                                            </tr>
                                        }) : (
                                            <tr>
                                                <td colSpan={tableFields.length}>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2"> <Translate text={'No Data'} /></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                }
                            </tbody>

                        </table>


                    )}
            </div>
        </div>
        <PopUpModal show={schedulerShow.show}>
            <ScheduleReport
                {...props}
                {...schedulerShow}
                handleClose={() => setSchedulerShow({ show: false })}
                reportPrefix={'performance'}
            ></ScheduleReport>
        </PopUpModal>
        <PopUpModal show={modelShow.enquiry}>
            <EnquiryList
                {...props}
                show={modelShow.enquiry}
                handleClose={handleModelClose}
                title={popHeader}
                history={props.history}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                filter={reportfilter}
                isFromDashboard={true}
                counts={counts}
            ></EnquiryList>
        </PopUpModal>
        <PopUpModal show={modelShow.testdrive}>
            <TestDriveList
                {...props}
                show={modelShow.testdrive}
                handleClose={handleModelClose}
                title={popHeader}
                filter={reportfilter}
                isFromDashboard={true}
                counts={counts}
            ></TestDriveList>
        </PopUpModal>
        <PopUpModal show={modelShow.activity}>
            <ActivityList
                {...props}
                show={modelShow.activity}
                handleClose={handleModelClose}
                title={popHeader}
                filter={reportfilter}
                isFromDashboard={true}
                counts={counts}
            ></ActivityList>
        </PopUpModal>
        <PopUpModal show={modelShow.scheduleActivity}>
            <ActivityList
                {...props}
                show={modelShow.scheduleActivity}
                handleClose={handleModelClose}
                title={popHeader}
                filter={reportfilter}
                isFromDashboard={true}
                counts={counts}
            ></ActivityList>
        </PopUpModal>
        <PopUpModal show={modelShow.tradein}>
            <TradeInList
                {...props}
                show={modelShow.tradein}
                handleClose={handleModelClose}
                title={popHeader}
                filter={reportfilter}
                isFromDashboard={true}
                counts={counts}
            ></TradeInList>
        </PopUpModal>
        <PopUpModal show={modelShow.logs}>
            <LogListModel
                {...props}
                show={modelShow.logs}
                handleClose={handleModelClose}
                title={popHeader}
                filter={reportfilter}
                isFromDashboard={true}
                counts={counts}
            ></LogListModel>
        </PopUpModal>
        <PopUpModal show={modelShow.quotes}>
            <QuoteList
                {...props}
                show={modelShow.quotes}
                handleClose={handleModelClose}
                title={popHeader}
                filter={reportfilter}
                isFromDashboard={true}
                counts={counts}
            ></QuoteList>
        </PopUpModal>
    </>)
}

export default DailyCheckout