/** LIBRARIES */
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import moment from 'moment'
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import { PopoverListLoader, PopUpModal, ReactSelect } from '../../../components';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common'
import { firestoreDB } from '../../../services/helper';

const AddOns = (props) => {
    const [searchText, setSearchText] = useState('')
    const [salesContracts, setAddOns] = useState(props.salesContracts ? props.salesContracts : [])


    useEffect(() => {
        setAddOns(props.salesContracts ? props.salesContracts : [])
    }, [props.salesContracts])


    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };
    const vehDescription = (_contact, alternate) => {

        if (!_.isEmpty(_contact) && !_.isEmpty(_contact['vehicle-description'])) {
            return _contact['vehicle-description'];
        }
        else if (!_.isEmpty(_contact)) {
            return ((_contact['vehicle-make'] ? _contact['vehicle-make'] + ' ' : '') +
                (_contact['vehicle-model-code'] ? _contact['vehicle-model-code'] + ' ' : '') +
                (_contact['vehicle-model'] ? _contact['vehicle-model'] + ' ' : '') +
                (_contact['vehicle-year'] ? _contact['vehicle-year'] + ' ' : '') +
                (_contact['vehicle-colour'] ? _contact['vehicle-colour'] + ' ' : ''));
        }
        return alternate ? alternate : '';
    }
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');


    const filterData = searchText ? _.map(salesContracts.filter((a) =>
        a?.request ? (
            (a.request['first-name'] ? a.request['first-name'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['last-name'] ? a.request['last-name'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['work-telephone'] ? a.request['work-telephone'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['mobile-telephone'] ? a.request['mobile-telephone'].toString().startsWith(searchText) : false) ||
            (a.request['email'] ? a.request['email'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['business-name'] ? a.request['business-name'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['drivers-licence-number'] ? a.request['drivers-licence-number'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['vehicle-make'] ? a.request['vehicle-make'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['stock-number'] ? a.request['stock-number'].toString().startsWith(searchText) : false) ||
            (a.request['rego'] ? a.request['rego'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['vin'] ? a.request['vin'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['vehicle-colour'] ? a.request['vehicle-colour'].toLowerCase().startsWith(searchText) : false) ||
            (a.request['vehicle-description'] ? a.request['vehicle-description'].toLowerCase().startsWith(searchText) : false)
        ) : false
    )) : salesContracts;

    const handleUnlick = useCallback((data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to unlink.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                firestoreDB(props.dealersettings).firestore().doc(`enquiries/${props.enquiry.documentID}`).set({ autoitQuoteID: null }, { merge: true })
                    .then(snapshot => {
                        toast.notify('Order unlink successfully.', {
                            duration: 2000
                        })
                        let _logNote = `Order Number: ${data?.request['purchase-order-number']} un-linked.`
                        CommonHelper.saveChangeLog(props.enquiry.clientID, null, props.enquiry.documentID, _logNote, "autoit", "enquiry");
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })

    }, [])

    return (
        <>

            <Modal
                id="purchase_Contract"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-select-stock">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Purchase Orders'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="package-item-head pr-0">
                                    <input
                                        type="text"
                                        aria-label="Search"
                                        placeholder="Search..."
                                        value={searchText ? searchText : ''}
                                        autoComplete="off"
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                        }}
                                    />
                                </div>

                                <div style={divStyle}>
                                    <div className="package-item-loop">
                                        {
                                            !_.isEmpty(filterData)
                                                ?
                                                <>
                                                    {
                                                        filterData && _.orderBy(filterData, ['addedDate'], ['asc']).map((contract, index) => {
                                                            return <div key={index} className="service-packages-box">
                                                                {(() => {
                                                                    let _request = contract?.request || {}

                                                                    return (
                                                                        <>
                                                                            <div className="flex-package">

                                                                                <div className="package-data">
                                                                                    <div className="package-item-title">
                                                                                        {vehDescription(_request)}
                                                                                    </div>

                                                                                    <div className="dashboard-testdrive-activity-column">
                                                                                        Stock#: <div title={CommonHelper.showLocale(props, 'stockNo')}>{_request['stock-number'] || '--'}</div>
                                                                                        {_request['vehicle-colour'] && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'exteriorColor')}>{_request['vehicle-colour']}</div></>)}
                                                                                        {_request['vehicle-year'] && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{_request['vehicle-year']}</div></>)}
                                                                                        {_request['rego'] && (<><span className="veh-details-seperator">|</span><strong>REG#:</strong>&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{_request['rego']}</div></>)}
                                                                                        {_request['vin'] && (<><span className="veh-details-seperator">|</span><strong>VIN#:</strong>&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{_request['vin']}</div></>)}
                                                                                        {_request['sale-price'] && (<><span className="veh-details-seperator">|</span><strong><Translate text={'price'} />:</strong>&nbsp;
                                                                                            <NumberFormat value={_request['sale-price']} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)}
                                                                                    </div>

                                                                                    <div className="dashboard-testdrive-activity-column">
                                                                                        Deal No: <div title={''}>{_request['client-purchase-id'] || '--'}</div>
                                                                                        {_request['purchase-order-number'] && (<><span className="veh-details-seperator">|</span><strong>Order No:</strong>&nbsp;<div>{_request['purchase-order-number']}</div></>)}

                                                                                        {_request['date-of-sale'] && (<><span className="veh-details-seperator">|</span><strong>Order Date:</strong>&nbsp;<div>{_request['date-of-sale'] ? moment(_request['date-of-sale']).format('DD/MM/YYYY') : '--'}</div></>)}
                                                                                        {_request['estimated-delivery-date'] && (<><span className="veh-details-seperator">|</span><strong>Est. Delivery:</strong>&nbsp;<div>{_request['estimated-delivery-date'] ? moment(_request['estimated-delivery-date']).format('DD/MM/YYYY') : '--'}</div></>)}
                                                                                        {_request['salesperson'] && (<><span className="veh-details-seperator">|</span><strong>Lead Owner:</strong>&nbsp;<div>{_request['salesperson']}</div></>)}
                                                                                        {_request['sales-Status'] && (<><span className="veh-details-seperator">|</span><strong>Lead Status:</strong>&nbsp;<div>{_request['sales-Status']}</div></>)}

                                                                                    </div>

                                                                                </div>

                                                                                <div className="package-add-button">
                                                                                    {
                                                                                        contract.documentID === props.enquiry?.autoitQuoteID
                                                                                            ?
                                                                                            <button className="btn btn-sm selected-package-btn"
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    handleUnlick(contract);

                                                                                                }}><i className="ico icon-remove mr-1"> </i><Translate text={'unlink'} /></button>
                                                                                            :
                                                                                            <button className="btn btn-sm btn-primary"
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    props.handleClose(contract.documentID)

                                                                                                }}><i className="ico icon-add mr-1"></i> <Translate text={'Link'} /></button>

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })()}

                                                            </div>
                                                        })
                                                    }


                                                </>
                                                :
                                                <>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-cafe-img">
                                                                <i className="ico icon-contract-fill"></i> </div>
                                                            <div className="no-data-txt mt-2"> <Translate text={'No Contracts available'} /></div>
                                                        </div>

                                                    </div>
                                                </>
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                </Modal.Body>

            </Modal>



        </>
    )
}

export default AddOns