/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import moment from 'moment';
import toast from 'toasted-notes'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _, { isEmpty } from 'lodash'
import { default as _images } from '../../images';
/** COMPONENTS */
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';
import { PopUpModal, PopoverListLoader, SidePanel } from '../../components';
import CommonHelper from '../../services/common';
import { objEnquiry } from '../pipeline/viewModel'
import { enquiryStatus } from '../../services/enum'

// import { StageProvider, StageConsumer } from '../pipeline/provider'
// import QuickView from "../pipeline/quickview";
import QuickEnquiryInfo from '../pipeline/details/quickInfo'

import MergeEnquiry from '../pipeline/merge'

class ContactEnquiryList extends Component {
    constructor(props) {
        super(props);
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.state = {
            enquiries: [],
            filter: {
                iSearch: ''
            },
            loading: true,
            modalMergeShow: { show: false, mergeEnqIDs: [] },
            showpanel: { clsActive: '', clsName: 'sidebar-quickview-pop' }
        };
    }

    static applyFilter = (listings, filter) => {
        const { iSearch } = filter
        let result = listings
        const filterForEnq = (item) => {
            let result = false;
            if (iSearch && item.keywords.includes(iSearch.toLowerCase())) {
                result = true
            }
            if (!_.isEmpty(item.contact)) {
                if (item.contact.firstName.toLowerCase().includes(iSearch.toLowerCase()) || item.contact.lastName.toLowerCase().includes(iSearch.toLowerCase())) {
                    result = true
                }
            }
            if (!_.isEmpty(item.requirement) && (!_.isEmpty(item.requirement.make) || !_.isEmpty(item.requirement.stock))) {
                if (item.requirement.stock === null) {
                    if (item.requirement.make.toLowerCase().includes(iSearch.toLowerCase()) || item.requirement.model.toLowerCase().includes(iSearch.toLowerCase())) {
                        result = true
                    }
                } else {
                    if (item.requirement.stock) {
                        if (item.requirement.stock.make.toLowerCase().includes(iSearch.toLowerCase()) || item.requirement.stock.model.toLowerCase().includes(iSearch.toLowerCase())) {
                            result = true
                        }
                    }
                }
            }
            return result

        }
        if (iSearch) {
            result = result.filter(item => filterForEnq(item))
        }
        return result
    }


    componentDidMount() {
        const { dealersettings, contactID, enquiryID } = this.props;

        if (dealersettings && contactID) {
            const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
            let refEnquiryData = firestoreDB(dealersettings).firestore().collection('enquiries')
                .where('isContact', '==', true)
                .where('contact.documentID', '==', contactID)
                .where('isDeleted', '==', false)

            refEnquiryData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refEnquiryData, dealersettings, 'INBOUND');
            if (localStorage.defaultModule === 'oem')
                refEnquiryData = refEnquiryData.where('oemStatus', '==', 'pending')


            refEnquiryData.get().then(response => {
                const _enquiries = [];

                response.docs.forEach((doc) => {
                    let _enq = Object.assign({}, objEnquiry);

                    for (let [key, value] of Object.entries(doc.data())) {
                        if (_enq.hasOwnProperty(key) || key === 'keywords')
                            _enq[key] = value;
                    }

                    _enq.documentID = doc.id;
                    const isStatusOpen = CommonHelper.isEnqStatusOpen(enquiryAllStatus, _enq.status)
                    if ((isStatusOpen || _enq.status === enquiryStatus.OPEN || _.isEmpty(_enq.status)) && enquiryID !== _enq.documentID)
                        _enquiries.push(_enq);

                });

                if (_.isEmpty(_enquiries)) {
                    this.handleSelectNewEnquiry();
                }
                else {
                    this.setState({
                        enquiries: _.orderBy(_enquiries, ["addedDate"], ["desc"]),
                        loading: false
                    });
                }

            });

        }
        else {
            this.setState({
                loading: false
            });
        }
    }


    handleChange = (prop, value) => {
        let state = this.state.filter;
        state[prop] = value;
        this.setState({ filter: state });
    }


    handleSelectExistingEnquiry = (ID, objData) => {
        //const { } = this.props;
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to continue with selected enquiry.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                this.props.handleClose(ID);
            }
        })

    }

    handleSelectNewEnquiry = () => {
        this.props.handleClose('new');
    }


    //#region  QUICK VIEW
    sidepanelOpen = (id) => {
        this.setState({
            showpanel:
            {
                clsActive: 'active',
                clsName: 'sidebar-quickview-pop',
                enquiryid: id
            }
        })
    }

    sidepanelClose = () => {
        this.setState({
            showpanel:
            {
                clsActive: '',
                clsName: 'sidebar-quickview-pop',
                enquiryid: 0
            }
        })
    }

    handleCloseEnquiry = () => {
        const { showpanel } = this.state;
        if (showpanel.clsActive === 'active')
            this.sidepanelClose();
    }
    //#endregion

    //#region MERGE ENQUIRY
    handleSelectMergeEnquiry = (ID, objData) => {
        const { enquiryID, primaryEnquiry } = this.props;
        const _mergeEnqIDs = [];

        if (primaryEnquiry && primaryEnquiry.clientID !== objData.clientID) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Only enquiries belonging to the same dealer can be merged.'), '', 'info');
            return;
        }

        _mergeEnqIDs.push(enquiryID);
        _mergeEnqIDs.push(ID);
        this.setState({ modalMergeShow: { show: true, mergeEnqIDs: _mergeEnqIDs } });
    }

    handleCloseMergeEnquiries = (objUpdatedEnq, removeEnqID, _enquiryID) => {
        if (!_.isEmpty(objUpdatedEnq) && removeEnqID) {

            toast.notify(`Enquiry merged successfully`, {
                duration: 2000
            })

            this.props.handleClose(_enquiryID);
        }

        this.setState({ modalMergeShow: { show: false, mergeEnqIDs: [] } });

    }
    //#endregion

    render() {
        const { iSearch } = this.state.filter
        const { enquiries, filter, loading, showpanel, modalMergeShow } = this.state
        const { show, title, showAdd, dealersettings, groupUsers, clientUsers, isMerge } = this.props;
        const filteredList = ContactEnquiryList.applyFilter(enquiries, filter)
        const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
        const divStyle = {
            height: '440px',
            overflow: 'auto'
        };

        const liteVersion = dealersettings?.client?.clientSettings?.liteVersion ? true : false;
        const hideContinueWithNewEnquiry = dealersettings?.client?.clientSettings?.hideContinueWithNewEnquiry ? true : false;

        return (
            !_.isEmpty(enquiries)
                ?
                <>
                    <Modal
                        id="contact_Enquiries_List"
                        show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-centered modal-add-terms">

                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5 className="modal-title" ><Translate text={title ? title : 'Merge Contacts'} /></h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <>
                                <div className={`vehicle-popover-head pr-0 ${!hideContinueWithNewEnquiry ? 'multilead-head' : ''}`}>
                                    {
                                        !hideContinueWithNewEnquiry && (<div className="multilead-addbutton">
                                            <button type="button" className="btn btn-secondary float-right"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleSelectNewEnquiry();

                                                }}>
                                                <i className="ico icon-close"></i> <Translate text={'Continue with current enquiry'} /></button>
                                        </div>)
                                    }

                                    <input
                                        type="text"
                                        id="isearch"
                                        aria-label="Search"
                                        placeholder="Search..."
                                        value={iSearch}
                                        autoComplete="off"
                                        onChange={event =>
                                            this.handleChange('iSearch', event.target.value)
                                        }
                                    />
                                </div>


                                <div style={divStyle} >
                                    {
                                        loading ? (<PopoverListLoader />) :
                                            (filteredList && filteredList.length > 0)
                                                ?
                                                <>
                                                    {filteredList.map((enquiry, index) =>
                                                        <div key={index} className="vehicle-popover-loop" >
                                                            <div className="divTable" >
                                                                <div className="divTableBody">
                                                                    <div className="divTableRow">
                                                                        <div className="divTableCell width70">
                                                                            <div className="customer-avatar-content">
                                                                                <div className="customer-avatar-image">
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={CommonHelper.showBrandLogo(dealersettings, (!_.isEmpty(enquiry?.requirement?.stock) ? enquiry.requirement.stock?.make : enquiry.requirement?.make))} className="img-object-fit-contain" alt="" width="70" height="70" /> </a>
                                                                                </div>
                                                                                {
                                                                                    enquiry.displayID ?
                                                                                        (<div className="input-readonly-sub"><i className="ico icon-enquiry mr-1"></i>{enquiry.displayID}</div>) :
                                                                                        (<></>)
                                                                                }

                                                                                {
                                                                                    isMerge && !_.isEmpty(enquiry.contact) ? (

                                                                                        <div className="link-enquiry-owner" style={{ borderBottom: '0px' }}>
                                                                                            <div className="link-enquiry-owner-view">
                                                                                                {CommonHelper.displayContactIcon(enquiry.contact, 'mr-1')}
                                                                                            </div>{CommonHelper.displayContact([], enquiry.contact, '--')}</div>
                                                                                    ) : (<></>)
                                                                                }

                                                                                <div className="customer-avatar-title">

                                                                                    {
                                                                                        (!_.isEmpty(enquiry.requirement) && (enquiry.requirement.make || enquiry.requirement.stock))
                                                                                            ?
                                                                                            <>
                                                                                                {
                                                                                                    (!_.isEmpty(enquiry.requirement.stock) && !_.isEmpty(enquiry.requirement.stock.make))
                                                                                                        ?
                                                                                                        <span>
                                                                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                            {(enquiry.requirement.stock.make ? enquiry.requirement.stock.make : '') + ' ' + (enquiry.requirement.stock.model ? enquiry.requirement.stock.model : '')}
                                                                                                            {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                    enquiry.requirement.saleType, '')}
                                                                                                            </div>) : ''}

                                                                                                        </span>
                                                                                                        :
                                                                                                        <span>
                                                                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                            {(enquiry.requirement.make ? enquiry.requirement.make : '') + ' ' + (enquiry.requirement.model ? enquiry.requirement.model : '')}
                                                                                                            {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                    enquiry.requirement.saleType, '')}
                                                                                                            </div>) : ''}

                                                                                                        </span>
                                                                                                }

                                                                                                {
                                                                                                    enquiry.requirementCount && enquiry.requirementCount > 1
                                                                                                        ?
                                                                                                        <OverlayTrigger
                                                                                                            placement='bottom'
                                                                                                            overlay={
                                                                                                                <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="badge badge-pill badge-blue badge-mini ml-1">
                                                                                                                +{enquiry.requirementCount - 1} <Translate text={'more'} lowerCase={true} /></div>
                                                                                                        </OverlayTrigger>

                                                                                                        :
                                                                                                        <></>
                                                                                                }


                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    liteVersion && !_.isEmpty(enquiry.td) && !_.isEmpty(enquiry.td.make)
                                                                                                        ?
                                                                                                        <>
                                                                                                            <span >
                                                                                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                                                                                                {enquiry.td.make + ' ' + enquiry.td.model}
                                                                                                                {
                                                                                                                    enquiry.td.saletype
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                                    enquiry.td.saletype, '')}
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                        </>
                                                                                                                }
                                                                                                            </span>

                                                                                                        </>
                                                                                                        :
                                                                                                        <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                                                                                                }

                                                                                            </>

                                                                                    }

                                                                                </div>

                                                                                {
                                                                                    !_.isEmpty(enquiry.tags) ? (
                                                                                        <div className="input-readonly-sub">
                                                                                            {
                                                                                                enquiry.tags.map((r, i) => {
                                                                                                    return r.subType ? (<div key={i} className="float-left"><div className={`badge badge-pill badge-white text-uppercase ${i > 0 ? 'ml-1' : ''}`}>{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
                                                                                                        <div className="badge badge-pill badge-white ml-1 text-uppercase">{r.subType}</div></div>) :
                                                                                                        (<div key={i} className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    ) : (<></>)
                                                                                }

                                                                                {
                                                                                    !_.isEmpty(enquiry.addedBy) && !_.isEmpty(enquiry.addedDate) ? (
                                                                                        // <OverlayTrigger
                                                                                        //     placement='bottom'
                                                                                        //     overlay={
                                                                                        //         <Tooltip>{'Created'}</Tooltip>
                                                                                        //     }
                                                                                        // >
                                                                                        <div className="input-readonly-sub">
                                                                                            <i className="ico icon-date mr-1"></i>
                                                                                            {moment.unix(enquiry.addedDate.seconds).format('DD MMM YYYY h:mm A')}
                                                                                            <strong>{' by '}</strong>
                                                                                            {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), enquiry.addedBy)}
                                                                                        </div>
                                                                                        //</OverlayTrigger>

                                                                                    ) : (<></>)
                                                                                }

                                                                                <div className="vehicle-item-status">
                                                                                    {/* <OverlayTrigger
                                                                                        placement='bottom'
                                                                                        overlay={
                                                                                            <Tooltip>{'Owner'}</Tooltip>
                                                                                        }
                                                                                    > */}
                                                                                    <div className="calllogs-vehicle-owner">
                                                                                        <div className="owner-view">
                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                <img src={CommonHelper.getUserImagebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), enquiry.owner)} className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>
                                                                                        {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), enquiry.owner)}
                                                                                    </div>
                                                                                    {/* </OverlayTrigger> */}

                                                                                    {
                                                                                        enquiry.status ? <>
                                                                                            {(() => {
                                                                                                let __status = (enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : enquiry.status;
                                                                                                let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

                                                                                                return <>
                                                                                                    {
                                                                                                        _objCurrentStatus
                                                                                                            ?
                                                                                                            <>
                                                                                                                <span
                                                                                                                    className={`status-${enquiry.status === 'open' ? 'open' : 'empty'} float-right`}
                                                                                                                    style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                                                >
                                                                                                                    <Translate
                                                                                                                        text={_objCurrentStatus.name}
                                                                                                                        upperCase={true}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>


                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                            })()}

                                                                                        </>
                                                                                            : (<></>)
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="multilead-minibuttons pl-2 pr-2 mt-0">
                                                                <div className="border-multilead">

                                                                    {
                                                                        isMerge
                                                                            ?
                                                                            <button type="button" className="btn btn-primary float-right" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handleSelectMergeEnquiry(enquiry.documentID, enquiry)

                                                                            }}><Translate text={'merge'} /></button>
                                                                            :
                                                                            <button type="button" className="btn btn-primary float-right" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handleSelectExistingEnquiry(enquiry.documentID, enquiry)

                                                                            }}><Translate text={'select'} /></button>
                                                                    }


                                                                    <button type="button" className="btn btn-default float-right mr-2" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.sidepanelOpen(enquiry.documentID)

                                                                    }}><Translate text={'view'} /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}</>
                                                :
                                                <>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-cafe-img">
                                                                <i className="ico icon-enquiry"></i> </div>
                                                            <div className="no-data-txt mt-2"> <Translate text={'No Enquiry Available'} /> </div>
                                                        </div>

                                                    </div>
                                                </>
                                    }
                                </div>

                                {
                                    showpanel.enquiryid
                                        ?
                                        <>
                                            {/* <StageProvider dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers}>
                                                <StageConsumer>
                                                    {() => (

                                                        <SidePanel
                                                            clsActive={showpanel.clsActive}
                                                            clsName={showpanel.clsName}
                                                            sidepanelClose={this.sidepanelClose}
                                                            title=''
                                                        >
                                                            <QuickView {...this.props}
                                                                enquiryid={showpanel.enquiryid}
                                                                isHideExpandView={true}
                                                                isReadOnlyView={true}
                                                                sidepanelClose={this.sidepanelClose}
                                                            />
                                                        </SidePanel>
                                                    )}
                                                </StageConsumer>
                                            </StageProvider> */}
                                            <QuickEnquiryInfo
                                                {...this.props}
                                                enquiryid={showpanel.enquiryid}
                                                clsActive={showpanel.clsActive}
                                                clsName={showpanel.clsName}
                                                isHideExpandView={true}
                                                isReadOnlyView={true}
                                                sidepanelClose={this.sidepanelClose}
                                            />

                                        </>
                                        :
                                        <></>
                                }



                            </>
                        </Modal.Body>
                    </Modal>



                    <PopUpModal show={modalMergeShow.show}>
                        <MergeEnquiry
                            {...this.props}
                            show={modalMergeShow.show}
                            enqIDA={modalMergeShow.mergeEnqIDs[0]}
                            enqIDB={modalMergeShow.mergeEnqIDs[1]}
                            isNewContact={false}
                            handleClose={this.handleCloseMergeEnquiries}
                            title={'Merge Enquiries'}
                        >
                        </MergeEnquiry>
                    </PopUpModal>
                </>
                :
                <></>
        );
    }

}

export default ContactEnquiryList;