import React, { useEffect, useState } from 'react';
import moment from "moment";
import Translate from '../../../../constants/translate';
import { ReactSelect } from '../../../../components';
import { mapProps } from 'recompose';
import _ from 'lodash'
const CustomToolbar = (toolbar) => {

    const [searchText, setSearchText] = useState(toolbar.searchText)
    const [saleTypes, setSaleTypes] = useState([])
    useEffect(() => {
        setSearchText(toolbar.searchText);
    }, [toolbar.searchText])

    useEffect(() => {
        if (toolbar?.events?.length > 0) {
            const _st = [{
                name: 'Total',
                count: toolbar?.events?.length
            }]
            _st.push(..._.chain(toolbar.events).groupBy('saleTypeName').map((value, key) => ({ name: key, count: value.length })).value());
            setSaleTypes(_st)
        } else {
            setSaleTypes([])
        }
    }, [toolbar?.events?.length])


    const goToDayView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'day')

        toolbar.onView("day");
        toolbar.handleChangeviewstate("day");
    };
    const goToWeekView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'week')

        toolbar.onView("week");
        toolbar.handleChangeviewstate("week");
    };
    const goToMonthView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'month')

        toolbar.onView("month");
        toolbar.handleChangeviewstate("month");
    };
    const goToAgendaView = () => {
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), 1);
        setDateRange(newDate, 'agenda')

        toolbar.onView("agenda");
        toolbar.onNavigate("next", newDate);
        toolbar.handleChangeviewstate("agenda");
    };
    const goToBack = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 1,
                1
            );
        }
        if (toolbar.monthData !== newDate.getMonth()) {
            toolbar.handlechangemonth(newDate.getMonth())
        }
        setDateRange(newDate, toolbar.viewstate)

        toolbar.onNavigate("prev", newDate);
    };
    const goToNext = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 1,
                1
            );
        }
        if (toolbar.monthData !== newDate.getMonth()) {
            toolbar.handlechangemonth(newDate.getMonth())
        }
        setDateRange(newDate, toolbar.viewstate)
        toolbar.onNavigate("next", newDate);
    };

    const goToToday = () => {
        const now = moment()._d;
        toolbar.date.setDate(now.getDate());
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        if (toolbar.monthData !== now.getMonth()) {
            toolbar.handlechangemonth(now.getMonth())
        }
        setDateRange(now, toolbar.viewstate)
        toolbar.onNavigate("current");
    };

    const setDateRange = (date, view) => {
        if (view === 'month')
            toolbar.setDateRange({
                startDate: moment(date).startOf(view).startOf('day').add(-7, 'day'),
                endDate: moment(date).endOf(view).endOf('day').add(7, 'day')
            })
        else if (view === 'agenda')
            toolbar.setDateRange({
                startDate: moment(date).startOf('month').startOf('day').add(-1, 'day'),
                endDate: moment(date).endOf('month').endOf('day').add(1, 'day')
            })
        else
            toolbar.setDateRange({
                startDate: moment(date).startOf(view).startOf('day'),
                endDate: moment(date).endOf(view).endOf('day')
            })
    }

    return (
        <div className="filter-sub-panel">
            <div className="d-flex justify-content-between ">
                <div className='float-left'>
                    {
                        _.isEmpty(toolbar.device) ? (
                            <div className='float-left mr-2' style={{ width: '200px' }}>
                                <ReactSelect
                                    options={toolbar.allDevice.filter(a => !Boolean(a.showScheduler)).map(rec => {
                                        return {
                                            label: rec.name,
                                            value: rec.documentID
                                        }
                                    })}
                                    placeholder={'select device'}
                                    name={'deviceID'}
                                    onChange={toolbar.handleReactSelectChange}
                                    value={toolbar.selectedDevice ? toolbar.selectedDevice : null}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={false}
                                    isSearchable={true}
                                ></ReactSelect>
                            </div>
                        ) : (<></>)
                    }
                    {
                        Boolean(toolbar.hideSearch) ? saleTypes.length ? (<></>) : (<div style={{ width: '200px' }}>&nbsp;</div>) : (
                            <div className="filter-search search-icon">
                                <div className="input-group ">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="isearch"
                                        placeholder="search..."
                                        value={searchText ? searchText : ''}
                                        onChange={(e) => {
                                            let _val = e.target.value;

                                            setSearchText(_val);

                                            if (!_val)
                                                toolbar.handleSearchKeywords('')

                                            if (_val && _val.length > 0)
                                                toolbar.handleSearchKeywords(_val)
                                        }}
                                        autoComplete="off"
                                        autoFocus
                                    />
                                    <div className="input-group-append input-search-clear">
                                        {
                                            searchText && searchText.length > 0
                                                ?
                                                <a
                                                    className="input-search-clear-icon"
                                                    title="Clear"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSearchText('');
                                                        toolbar.handleSearchKeywords('')
                                                    }}
                                                >
                                                    <i className="ico icon-remove"></i>
                                                </a>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        saleTypes?.length ? (
                            <div className="pipeline-counter mr-3">
                                <div className="counter-nav">
                                    <ul>
                                        {
                                            saleTypes.map((rec, index) => {
                                                return rec.name === 'Total' ? (
                                                    <li key={index}><a href="#" className='counter-total'>
                                                        {rec.name} <span className="badge badge-pill badge-counter ml-1">{rec.count}</span></a></li>
                                                ) : <li key={index}><a href="#" >
                                                    {rec.name} <span className="badge badge-pill badge-counter ml-1" style={{ background: `#6b717f` }}>{rec.count}</span></a></li>

                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                </div>
                <div className="calendar-year">{toolbar.label}</div>
                <div className="">
                    <div className="calendar-switch-buttons">
                        <div className="btn-group">
                            <button className="btn btn-default" onClick={goToToday}><Translate text={'today'} /></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToBack}> <i className="fa fa-chevron-left"></i></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToNext}> <i className="fa fa-chevron-right"></i></button>
                        </div>

                    </div>
                    <div className="tabber-nav float-left">
                        <ul>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); goToDayView(); }} className={`${toolbar.viewstate === 'day' ? 'active' : ''}`}><Translate text={'day'} /></a></li>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); goToWeekView(); }} className={`${toolbar.viewstate === 'week' ? 'active' : ''}`}> <Translate text={'week'} /></a></li>
                        </ul>
                    </div>
                    <div className="filter-add-button ml-3">
                        {
                            !Boolean(toolbar.popupModel) && Boolean(toolbar?.dealersettings?.rolePermissions?.admin?.workflowSetup) ? <button type="button" className="btn btn-secondary mr-2" onClick={() => {
                                toolbar.history.push('/settings/workflow?key=tvDevices');
                            }}> <i className="ico icon-monitor mr-1"></i> <Translate text={'View Devices'} /></button> : (<></>)
                        }
                        {
                            Boolean(toolbar.hideAddSchedule) ? (<></>) : (
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    toolbar.handleSchedule()
                                }}> <i className="ico icon-add"></i> <Translate text={'Schedule'} /></button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomToolbar;