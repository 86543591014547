import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import { ContentOverlay, AntDateRangePicker, PopUpModal, BarChart, PieChart, LineChart, NoDataPieChart } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import { reportGroupBy, reportDynColumns, defaultFilters, filterOptions, reportFilters, labels } from '../../reports/viewModel'
import { CustomMenu, CustomToggle, CustomFilter } from '../../../components/customdropdown';
import AddReport from './addReport'
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
import ReactExport from 'react-export-excel-xlsx-fix';
import DynamicFilters from "../../reports/dynFilters";
import DealerReport from './dealerReport';
import ScheduleReport from "../../reports/scheduleReport";
import EnquiryList from '../../dashboard/enquiryList';
import ActivityList from '../../dashboard/activitiesList'
import LogListModel from "../../reports/logsList";
import TradeInList from '../../dashboard/tradeinList'
import CustomFilters from '../../reports/customFilters';
import uuid from 'react-uuid'
import cronstrue from 'cronstrue';
import NumberFormat from 'react-number-format';
import QuoteList from '../../dashboard/quotesList'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import TestDriveList from '../../dashboard/testdriveList';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styles = StyleSheet.create({
    page: {
        padding: '10mm'
    },
    header: {
        width: '100%',
        borderBottomColor: '#4466f2',
        borderBottomWidth: 3,
        display: 'inline-block',
        paddingBottom: 10,
        float: 'left',
        paddingTop: 30
    },
    headerwrapLeft: {
        width: '60%',
        float: 'left',
        position: 'absolute',
        left: 0,
        paddingTop: 15
    },
    headerwrapRight: {
        width: '40%',
        position: 'absolute',
        right: 0,
        textAlign: 'right'
    },
    reportImage: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 600
    },
    image: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 500,
        marginTop: 10
    },
    reporHeader: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        paddingTop: 10,
    },
    footer: {
        width: '100%',
        paddingTop: 8,
        marginTop: 0,
        display: 'inline-block'
    },
    footerCpy: {
        width: '100%',
        fontSize: 8,
        borderTopColor: '#d3d9e6',
        borderTopWidth: 1,
        paddingTop: 8,
        marginTop: 8
    }
});

const defaultModals = {
    enquiry: false,
    testdrive: false,
    activity: false,
    logs: false,
    sheduleReport: false,
    scheduleActivity: false,
    quote: false
}

const DynamicReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 695), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 200) })
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [report, setReport] = useState(props.report);
    const [progressReport, setProgressReport] = useState([])
    const [clientReport, setClientReport] = useState([])
    const [sortReport, setSortReport] = useState([])
    const [dates, setDates] = useState([]);
    const [refreshData, setRefreshData] = useState(false)
    const [sortConfig, setSortConfig] = useState();
    const [nestedsortConfig, setNestedSortConfig] = useState();

    const [showModel, setShowmodel] = useState(false)
    const [dealerModel, setDealermodel] = useState(false)

    const [dealerData, setDealerData] = useState()
    const [popHeader, setPopHeader] = useState()
    const [csvData, setCSVData] = useState([])
    const [sheetData, setSheetData] = useState([])

    const [chartData, setChartData] = useState()
    const [chartImg, setChartImg] = useState()
    const [filterShow, setShow] = useState(true)
    const [showCount, setShowCount] = useState(false)
    const node = useRef();
    const [objFilter, setObjFilter] = useState(defaultFilters)
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [nestedTbl, setNestedTbl] = useState({});
    const [subListTbl, setSubListTbl] = useState({});
    const [integrations, setIntegrations] = useState([]);
    const [dymamicCols, setDymamicColumns] = useState([]);

    const [modelShow, setModelShow] = useState(defaultModals)
    const [filter, setFilter] = useState(null)
    const [counts, setListCounts] = useState(null)
    const [errorFilters, setErrorFilter] = useState({});
    const [schedueReport, setSchedeReport] = useState({});
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [dynReportGroupBy, setReportGroupBy] = useState(reportGroupBy);
    const [enqDynamicOptions, setEnqDynamicOptions] = useState([]);

    const _editReport = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions.editDynamicReports : null);
    const enqStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];
    const isInbound = (props.report.pipeline
        && props.report.pipeline.some(b => b === 'LeadsBucket')
        && props.report.pipeline.length === 1) ? true : false;
    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');
    const multiTDperLead = props?.dealersettings?.client?.clientSettings?.multiTDperLead === true;

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 200),
                tblWidth: (window.innerWidth - 695)
            })
        }
        // document.addEventListener("mousedown", handleClick);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.persistencyChart && window.persistencyChart.dispose()
            //  document.removeEventListener("mousedown", handleClick);
        }
    }, [])

    useEffect(() => {
        setReport(props.report);
    }, [props.report])

    useEffect(() => {
        if (_.isEmpty(report))
            return;

        const refScheduleReport = window.firebase.firestore().doc(`schedular/${report.documentID}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])

    useEffect(() => {
        let _leadSource = []
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    const _sold = _.find(enqStatus, { value: 'won' })?.name || 'Won';
                    _leadSource.push({
                        label: rec.name,
                        value: `enqtype-${rec.value}`,
                        name: `${rec.name} (Enquiry Type)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                    _leadSource.push({
                        label: `${rec.name} ${_sold}`,
                        value: `enqtypesold-${rec.value}`,
                        name: `${rec.name} ${_sold} (Enquiry Type)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    const _sold = _.find(enqStatus, { value: 'won' })?.name || 'Won';
                    _leadSource.push({
                        label: rec.name,
                        value: `origin-${rec.value}`,
                        name: `${rec.name} (Origin)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                    _leadSource.push({
                        label: `${rec.name} ${_sold}`,
                        value: `originsold-${rec.value}`,
                        name: `${rec.name} ${_sold} (Origin)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `leadSource-${rec.value}`,
                        name: `${rec.name} (Lead Source)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lead Source',
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `lostReason-${rec.value}`,
                        name: `${rec.name} (Lost Reason)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lost Reason',
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `outcome-${rec.value}`,
                        name: `${rec.name} (Activity Outcome)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Activity Outcome',
                        type: 'activity'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `callstatus-${rec.value}`,
                        name: `${rec.name} (Call Status)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Call Status',
                        type: 'log'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `enqstatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                    _leadSource.push({
                        label: `${rec.name} Conv. Ratio`,
                        value: `enqstatus-${rec.value}Conv`,
                        name: `${rec.name} Conv. Ratio`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['percentage'],
                        percentage: true,
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && !Boolean(a.default))
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Activity Type</span></>,
                        searchlabel: `${rec.name} Activity Type`,
                        value: `acttype-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Activity Type',
                        type: 'activity'
                    })
                    _leadSource.push({
                        label: <>{rec.name} Done<span className="user-role-span">Activity Type</span></>,
                        searchlabel: `${rec.name} Done Activity Type`,
                        value: `acttypedone-${rec.value}`,
                        name: `${rec.name} Done`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Activity Type',
                        type: 'activity'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `financestatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Finance Status'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        _leadSource.push({
                            label: rec,
                            value: `${item.type};${item.value};${rec}`,
                            name: item.name,
                            view: ['table', 'column', 'pie', 'target'],
                            calculationType: ['count'],
                            color: '#4967CC',
                            sourceType: item.name
                        })
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            _leadSource.push({
                searchlabel: rec,
                value: rec,
                name: rec === '15' ? '15+' : rec,
                label: rec === '15' ? '15+' : rec,
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#4967CC',
                sourceType: 'Persistency'
            })
            if (rec === '4' || rec === '15') {
                _leadSource.push({
                    searchlabel: 'Total',
                    value: `${rec}plus`,
                    name: rec === '4' ? '0-4 Total' : '5-15+ Total',
                    label: rec === '4' ? (<>0-4 <br /> Total</>) : (<>5-15+ <br /> Total</>),
                    view: ['table', 'column', 'pie', 'target'],
                    calculationType: ['count'],
                    color: '#4967CC',
                    sourceType: 'Persistency',
                    cls: 'table-grey-sub-total'
                })
            }
        })
        if (props.dealersettings.isPorsche === true) {
            _leadSource.push({
                label: 'Leads Created in C@P',
                searchlabel: 'Leads Created in C@P',
                value: 'newCAP',
                name: 'Leads Created in C@P',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Lost from Enquiries',
                searchlabel: 'Lost from Enquiries',
                value: 'lostEnq',
                name: 'Lost from Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Assigned Enquiries',
                searchlabel: 'Assigned Enquiries',
                value: 'allocated',
                name: 'Assigned Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Outdated',
                searchlabel: 'Outdated',
                value: 'outdated',
                name: 'Outdated',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
        }
        setReportColumns([...reportColumns.filter(a => _.isEmpty(a.sourceType)).map(rec => {
            return rec.status === true && _.find(enqStatus, { value: rec.value }) ? {
                ...rec,
                name: rec.value === 'won' && props.report.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: rec.value === 'won' && props.report.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                cls: rec.value === 'won' && props.report.columnType === 'persistency' ? 'table-sub-total' : null
            } : rec.status === true && props.dealersettings.isPorsche === true ? {
                ...rec,
                name: `${rec.name} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
                label: `${rec.label} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
            } : { ...rec }
        }), ..._leadSource]);
        const enquiryOptions = [];
        const enquiryDynFilters = [];
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && (a.type === "select" || a.type === "multiselect" || a.type === "text" || a.type === "number"))
                .forEach(rec => {
                    enquiryOptions.push({
                        label: rec.name,
                        value: `${rec.type}-${rec.value}`,
                        displayName: rec.name,
                        displayValue: rec.name.toLowerCase(),
                        tableHeader: rec.name,
                    })
                    enquiryDynFilters.push({ label: rec.name, value: `${rec.type}-${rec.value}`, displayValue: rec.name.toLowerCase(), id: rec.value })
                })
        if (enquiryOptions.length > 0) {
            setReportGroupBy([...reportGroupBy, ...enquiryOptions]);
            setEnqDynamicOptions(enquiryDynFilters)
        }
        setRefreshClientData(true)
    }, [])

    useEffect(() => {
        async function getAllClientIntegrations() {
            let promise = [];
            let _options = [];
            props.dealersettings.clients.forEach(client => {
                promise.push(window.firebase.firestore().collection(`clients/${client.id}/integrations`)
                    .where('inbound', '==', true)
                    .where('active', '==', true)
                    .where('enabled', '==', true)
                    .get());
            })
            const snapshot = await Promise.all(promise);
            snapshot.forEach((snap) => {
                snap.docs.forEach(doc => {
                    if (!_options.some(e => e.value === doc.data().type))
                        _options.push({
                            label: CommonHelper.getNameByValue(props.dealersettings.integrations, doc.data().type, doc.data().type),
                            value: doc.data().type,
                        })
                });
            });
            setIntegrations(_options);
        }
        getAllClientIntegrations();
    }, [])

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner') || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    // useEffect(() => {
    //     if (props.activeKey === props.report.documentID)
    //         setTimeout(() => {
    //             setRefreshData(true)
    //         }, 2000);
    // }, [props.report.columns])


    useEffect(() => {
        if (_.isEmpty(sortReport) || props.report.reportType !== 'table')
            return

        let _headers = [];
        let _borderStyle = {
            border: {
                top: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                bottom: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                left: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                right: {
                    style: "thin",
                    color: { rgb: "000000" }
                }
            }
        }
        let _headerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false,
                    color: { rgb: "FFFFFF" }
                },
                fill: { patternType: "solid", fgColor: { rgb: "000000" } },
                ..._borderStyle

            }
        }
        let _headerStyleCenter = {
            style: {
                ..._headerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        }
        let _subheaderStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false
                },
                fill: { patternType: "solid", fgColor: { rgb: "dddddd" } },
                ..._borderStyle
            }
        }
        let _subheaderStyleCenter = {
            style: {
                ..._subheaderStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        }
        let _cellStyle = {
            style: {
                font: { sz: 10 },
                ..._borderStyle
            }
        };
        let _cellStyleCenter = {
            style: {
                ..._cellStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };
        let _numStyle = { style: { font: { sz: 10 } }, numFmt: "0" };
        let _footerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false
                },
                fill: { patternType: "solid", fgColor: { rgb: "ffeede" } },
                ..._borderStyle
            }
        };
        let _footerStyleCenter = {
            style: {
                ..._footerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };
        _headers.push(
            {
                title: (_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'DEALER' : `${props.report.level.toUpperCase()}`,
                dynValue: 'displayName',
                dynWidth: 10,
                ..._headerStyle
            })

        props.report.columns.forEach((val) => {
            var cols = reportColumns.find(item => item.value === val)
            if (cols) {
                _headers.push({ title: cols.name.toUpperCase(), dynValue: val, dynWidth: cols.name.length, ..._headerStyleCenter })
            }
            else {
                _headers.push({ title: val.toUpperCase(), dynValue: val, dynWidth: val.toString().length, ..._headerStyleCenter })
            }
            if (props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length) {
                props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                    return _headers.push({ title: dynCol.name, dynValue: dynCol.id, dynWidth: dynCol.name.toString().length, ..._headerStyleCenter })
                })
            }
        })
        if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
            props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((val) => {
                _headers.push({ title: val.name, dynValue: val.id, dynWidth: val.name.toString().length, ..._headerStyleCenter })
            })
        }

        var exportData = []
        var totals = getTotalRow(sortReport);

        sortReport.forEach(rec => {
            let obj = rec;
            let arrCols = [{ value: obj.clientName, ..._cellStyle, dynValue: 'clientName', dynWidth: obj.clientName.length }]
            props.report.columns.forEach(col => {
                var cols = reportColumns.find(item => item.value === col)
                arrCols.push({ value: cols && cols.percentage === true ? `${obj[col]}%` : obj[col], ..._cellStyleCenter, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
                if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                    props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                        arrCols.push({ value: `${obj[dyncol.id]}${dyncol.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: dyncol.id, dynWidth: obj[dyncol.id] ? obj[dyncol.id].toString().length : 0 })
                    })
                }
            })
            if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                    arrCols.push({ value: `${obj[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: col.id, dynWidth: obj[col.id] ? obj[col.id].toString().length : 0 })
                })
            }
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'clientName', dynWidth: 6 }]
            props.report.columns.forEach(col => {
                arrCols.push({ value: totals[col], ..._footerStyleCenter, dynValue: col, dynWidth: totals[col] ? totals[col].toString().length : 0 })
                if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                    props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                        arrCols.push({ value: `${totals[dyncol.id]}${dyncol.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: dyncol.id, dynWidth: totals[dyncol.id] ? totals[dyncol.id].toString().length : 0 })
                    })
                }
            })
            if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                    arrCols.push({ value: `${totals[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: col.id, dynWidth: totals[col.id] ? totals[col.id].toString().length : 0 })
                })
            }
            exportData.push(arrCols)
        }
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })

        let allSheetData = {};
        sortReport.forEach(rec => {
            var sheetData = []
            var clienttotals = getTotalRow(progressReport.filter(e => e[`${((_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`)}`] === rec.id));

            progressReport.filter(e => e[`${((_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`)}`] === rec.id).forEach(rec => {
                let obj = rec;
                let arrCols = [{ value: obj.displayName, ..._cellStyle, dynValue: 'displayName', dynWidth: obj.displayName.length }]
                props.report.columns.forEach(col => {
                    var cols = reportColumns.find(item => item.value === col)
                    arrCols.push({ value: cols && cols.percentage === true ? `${obj[col]}%` : obj[col], ..._cellStyleCenter, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
                    if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                        props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                            arrCols.push({ value: `${obj[dyncol.id]}${dyncol.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: dyncol.id, dynWidth: obj[dyncol.id] ? obj[dyncol.id].toString().length : 0 })
                        })
                    }
                })
                if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                    props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                        arrCols.push({ value: `${obj[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: col.id, dynWidth: obj[col.id] ? obj[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            })
            if (sheetData.length > 0) {
                let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'displayName', dynWidth: 6 }]
                props.report.columns.forEach(col => {
                    arrCols.push({ value: clienttotals[col], ..._footerStyleCenter, dynValue: col, dynWidth: clienttotals[col] ? clienttotals[col].toString().length : 0 })
                    if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                        props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                            arrCols.push({ value: `${clienttotals[dyncol.id]}${dyncol.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: dyncol.id, dynWidth: clienttotals[dyncol.id] ? clienttotals[dyncol.id].toString().length : 0 })
                        })
                    }
                })
                if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                    props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                        arrCols.push({ value: `${clienttotals[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: col.id, dynWidth: clienttotals[col.id] ? clienttotals[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            }
            _headers.forEach(rec => {
                var w = _.maxBy(sheetData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
                _headers = _headers.map(h => {
                    return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                        ...h,
                        'width': { wch: w.dynWidth + 2 }
                    } : {
                        ...h,
                        'width': { wch: h.dynWidth + 2 }
                    }
                })
            })

            allSheetData[rec.id] = [{
                columns: [..._headers.map((rec, i) => {
                    let r = i === 0 ? {
                        ...rec,
                        title: CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value')
                    } : rec
                    return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 }
                })],
                data: [...sheetData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
            }]
        })

        let allCSVData = {};
        sortReport.forEach(rec => {
            var sheetData = []
            var _subHeader = []
            var clienttotals = getTotalRow(progressReport.filter(e => e[`${((_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`)}`] === rec.id));



            _subHeader = [..._headers.map((h, i) => {
                return i === 0 ? {
                    ...h,
                    ..._subheaderStyle,
                    title: `${_.find(clientReport, { id: rec.id }) ? _.find(clientReport, { id: rec.id }).clientName : 'Name'}`
                } : {
                    ...h,
                    ..._subheaderStyleCenter,
                }
            })]

            progressReport.filter(e => e[`${((_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`)}`] === rec.id).forEach(rec => {
                let obj = rec;
                let arrCols = [{ value: obj.displayName, ..._cellStyle, dynValue: 'displayName', dynWidth: obj.displayName.length }]
                props.report.columns.forEach(col => {
                    var cols = reportColumns.find(item => item.value === col)
                    arrCols.push({ value: cols && cols.percentage === true ? `${obj[col]}%` : obj[col], ..._cellStyleCenter, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
                    if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                        props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                            arrCols.push({ value: `${obj[dyncol.id]}${dyncol.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: dyncol.id, dynWidth: obj[dyncol.id] ? obj[dyncol.id].toString().length : 0 })
                        })
                    }
                })
                if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                    props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                        arrCols.push({ value: `${obj[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: col.id, dynWidth: obj[col.id] ? obj[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            })

            if (sheetData.length > 0) {
                let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'displayName', dynWidth: 6 }]
                props.report.columns.forEach(col => {
                    arrCols.push({ value: clienttotals[col], ..._footerStyleCenter, dynValue: col, dynWidth: clienttotals[col] ? clienttotals[col].toString().length : 0 })
                    if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                        props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                            arrCols.push({ value: `${clienttotals[dyncol.id]}${dyncol.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: dyncol.id, dynWidth: clienttotals[dyncol.id] ? clienttotals[dyncol.id].toString().length : 0 })
                        })
                    }
                })
                if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                    props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                        arrCols.push({ value: `${clienttotals[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: col.id, dynWidth: clienttotals[col.id] ? clienttotals[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            }

            _subHeader.forEach(_rec => {
                var w = _.maxBy(sheetData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === _rec.dynValue) } }), 'dynWidth')
                _subHeader = _subHeader.map(h => {
                    return h.dynValue === _rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                        ...h,
                        'width': { wch: w.dynWidth + 2 }
                    } : _.isEmpty(h.width) ? {
                        ...h,
                        'width': { wch: h.dynWidth + 2 }
                    } : {
                        ...h
                    }
                })
            })

            allCSVData[rec.id] = [{
                columns: [..._subHeader.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
                data: [...sheetData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
            }]
        })

        const _csvData = [{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
        }];
        if (props.dealersettings.isMclaren === true) {
            Object.keys(allCSVData).map((rec, index) => {
                _csvData.push({
                    ySteps: 1,
                    ...allCSVData[rec][0]
                });
            })
        }
        setCSVData(_csvData)
        setSheetData(allSheetData)
    }, [sortReport])

    useEffect(() => {
        if (_.isEmpty(sortReport) || props.report.reportType !== 'table')
            return

        const _score = _.sumBy(sortReport, '4plus') > 0 ? Math.round((_.sumBy(sortReport, '15plus') / _.sumBy(sortReport, '4plus')) * 100) : 0;
        var chart = am4core.create(`progressive-${props.report.documentID}`, am4charts.PieChart);
        chart.logo.disabled = true;
        chart.data = [{
            "category": "",
            "value": _score,
            "color": "#4466f2"

        }, {
            "category": "",
            "value": 100 - _score,
            "color": '#d3d9e6'
        }];

        chart.innerRadius = am4core.percent(90)
        //chart.radius = am4core.percent(90)

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.tooltip.disabled = true;
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        var label = pieSeries.createChild(am4core.Label)
        label.text = `${_score}%`;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = "15px";
        //chart.validateNow();

        pieSeries.slices.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.dataContext.color)
                return am4core.color(target.dataItem.dataContext.color);
            return color
        });

        window.persistencyChart = chart;
    }, [sortReport])

    const getTotalRow = (reportData) => {
        return {
            inboundCall: _.sumBy(reportData, "inboundCall"),
            outboundCall: _.sumBy(reportData, "outboundCall"),
            totalCall: _.sumBy(reportData, "totalCall"),
            messageLog: _.sumBy(reportData, "messageLog"),
            emailLog: _.sumBy(reportData, "emailLog"),
            noteLog: _.sumBy(reportData, "noteLog"),
            appointmentScheduled: _.sumBy(reportData, "appointmentScheduled"),
            testdriveScheduled: _.sumBy(reportData, "testdriveScheduled"),
            callScheduled: _.sumBy(reportData, "callScheduled"),
            mailScheduled: _.sumBy(reportData, "mailScheduled"),
            deliveryScheduled: _.sumBy(reportData, "deliveryScheduled"),
            appointment: _.sumBy(reportData, "appointment"),
            testdriveActivity: _.sumBy(reportData, "testdriveActivity"),
            callActivity: _.sumBy(reportData, "callActivity"),
            mailActivity: _.sumBy(reportData, "mailActivity"),
            deliveryActivity: _.sumBy(reportData, "deliveryActivity"),
            appointmentsold: `${(_.sumBy(reportData, "apptsold") > 0 ? Math.round((100 / _.sumBy(reportData, "apptsold")) * _.sumBy(reportData, "won")) : 0)}%`,
            appointmenttestdrive: `${(_.sumBy(reportData, "appointment") > 0 ? Math.round((100 / _.sumBy(reportData, "appointment")) * _.sumBy(reportData, "tdappt")) : 0)}%`,
            testdrivetotestdrive: `${(_.sumBy(reportData, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(reportData, "testdriveActivity")) * _.sumBy(reportData, "tdtestdrive")) : 0)}%`,
            apptsold: _.sumBy(reportData, "apptsold"),
            delivered: _.sumBy(reportData, "delivered"),
            displayName: "Totals",
            exisitngLeads: _.sumBy(reportData, "exisitngLeads"),
            id: "totals",
            lost: _.sumBy(reportData, "lost"),
            prospectLost: _.sumBy(reportData, "prospectLost"),
            leadLost: _.sumBy(reportData, "leadLost"),
            totalLost: _.sumBy(reportData, "totalLost"),
            name: "Totals",
            totalInbound: _.sumBy(reportData, "totalInbound"),
            overallPending: _.sumBy(reportData, "overallPending"),
            pendingLeads: _.sumBy(reportData, "pendingLeads"),
            convertedLeads: _.sumBy(reportData, "convertedLeads"),
            show: _.sumBy(reportData, "show"),
            noshow: _.sumBy(reportData, "noshow"),
            responseTime: (_.sumBy(reportData, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00'),
            maxresponseTime: (_.sumBy(reportData, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00'),
            minresponseTime: (_.sumBy(reportData, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00'),
            allocatedTime: (_.sumBy(reportData, `totalallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalallocatedTime), props.dealersettings.client) : '00:00:00'),
            maxallocatedTime: (_.sumBy(reportData, `totalmaxallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalmaxallocatedTime), props.dealersettings.client) : '00:00:00'),
            minallocatedTime: (_.sumBy(reportData, `totalminallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalminallocatedTime), props.dealersettings.client) : '00:00:00'),
            contactedTime: (_.sumBy(reportData, `totalcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalcontactedTime), props.dealersettings.client) : '00:00:00'),
            maxcontactedTime: (_.sumBy(reportData, `totalmaxcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalmaxcontactedTime), props.dealersettings.client) : '00:00:00'),
            mincontactedTime: (_.sumBy(reportData, `totalmincontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(reportData, (p) => p.totalmincontactedTime), props.dealersettings.client) : '00:00:00'),
            newLeads: _.sumBy(reportData, "newLeads"),
            qualifiedLeads: _.sumBy(reportData, "qualifiedLeads"),
            open: _.sumBy(reportData, "open"),
            tdappt: _.sumBy(reportData, "tdappt"),
            tdtestdrive: _.sumBy(reportData, "tdtestdrive"),
            testdrive: _.sumBy(reportData, "testdrive"),
            testdriveConv: `${(_.sumBy(reportData, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(reportData, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(reportData, "testdrive")) : 0)}%`,
            total: _.sumBy(reportData, "total"),
            tradein: _.sumBy(reportData, "tradein"),
            tradeincompleted: _.sumBy(reportData, "tradeincompleted"),
            tradeininprogress: _.sumBy(reportData, "tradeininprogress"),
            tradeinlost: _.sumBy(reportData, "tradeinlost"),
            tradeinwon: _.sumBy(reportData, "tradeinwon"),
            tradeinwonConv: `${(_.sumBy(reportData, "tradein") > 0 ? Math.round((100 / _.sumBy(reportData, "tradein")) * _.sumBy(reportData, "tradeinwon")) : 0)}%`,
            walkin: _.sumBy(reportData, "walkin"),
            walkinsold: `${(_.sumBy(reportData, "walkin") > 0 ? Math.round((100 / _.sumBy(reportData, "walkin")) * _.sumBy(reportData, "won")) : 0)}%`,
            walkintestdrive: `${(_.sumBy(reportData, "walkin") > 0 ? Math.round((100 / _.sumBy(reportData, "walkin")) * _.sumBy(reportData, "testdrive")) : 0)}%`,
            won: _.sumBy(reportData, "won"),
            wonTD: _.sumBy(reportData, "wonTD"),
            wonConv: `${(_.sumBy(reportData, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(reportData, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(reportData, "won")) : 0)}%`,
            scheduledConv: `${(_.sumBy(reportData, "convertedLeads") > 0 ? Math.round((100 / _.sumBy(reportData, "convertedLeads")) * _.sumBy(reportData, "appointmentScheduled")) : 0)}%`,
            showConv: `${(_.sumBy(reportData, "convertedLeads") > 0 ? Math.round((100 / _.sumBy(reportData, "convertedLeads")) * _.sumBy(reportData, "show")) : 0)}%`,
            noshowConv: `${(_.sumBy(reportData, "convertedLeads") > 0 ? Math.round((100 / _.sumBy(reportData, "convertedLeads")) * _.sumBy(reportData, "noshow")) : 0)}%`,
            convertedConv: `${(_.sumBy(reportData, "totalInbound") > 0 ? Math.round((100 / _.sumBy(reportData, "totalInbound")) * _.sumBy(reportData, "convertedLeads")) : 0)}%`,
            carryover: _.sumBy(reportData, "carryover"),
            newwalkin: _.sumBy(reportData, "newwalkin"),
            repeatwalkin: _.sumBy(reportData, "repeatwalkin"),
            noActivity: _.sumBy(reportData, "noActivity"),
            unactionedLeads: _.sumBy(reportData, "unactionedLeads"),
            noNotes: _.sumBy(reportData, "noNotes"),
            noCalls: _.sumBy(reportData, "noCalls"),
            noMessage: _.sumBy(reportData, "noMessage"),
            doneActivity: _.sumBy(reportData, "doneActivity"),
            allocated: _.sumBy(reportData, "allocated"),
            newCAP: _.sumBy(reportData, "newCAP"),
            lostEnq: _.sumBy(reportData, "lostEnq"),
            outdated: _.sumBy(reportData, "outdated"),
            overdueActivity: _.sumBy(reportData, "overdueActivity"),
            totalnewLeads: _.sumBy(reportData, "totalnewLeads"),
            totalexisitngLeads: _.sumBy(reportData, "totalexisitngLeads"),
            totalLeads: _.sumBy(reportData, "totalLeads"),
            quotes: _.sumBy(reportData, "quotes"),
            statusModified: _.sumBy(reportData, "statusModified"),
            quoteAmount: `${currencySymbol}${Math.round((_.sumBy(reportData, "quoteAmount") + Number.EPSILON) * 100) / 100}`,
            wonAmount: `${currencySymbol}${Math.round((_.sumBy(reportData, "wonAmount") + Number.EPSILON) * 100) / 100}`,
            ...getLeadsByEnquiryTypeTotal(reportData)
        };
    }


    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    useEffect(() => {
        if (_.isEmpty(props.report) || !refreshClientData)
            return

        setLoader(true)
        try {
            let _filters = {};
            if (!_.isEmpty(props.report.objFilters) && props.report.objFilters.length > 0) {
                props.report.objFilters.forEach(rec => {
                    _filters = rec.name === 'date' ? {
                        ..._filters,
                        startDate: moment.unix(rec.value.startDate.seconds).format('YYYY-MM-DD'),
                        endDate: moment.unix(rec.value.endDate.seconds).format('YYYY-MM-DD')
                    } : rec?.name?.includes('select-') || rec?.name?.includes('multiselect-') || rec?.name?.includes('text-') || rec?.name?.includes('number-') ? {
                        ..._filters,
                        dynamicFilter: _filters?.dynamicFilter ? [
                            ..._filters?.dynamicFilter, {
                                name: rec.name,
                                value: rec.value[rec.name],
                                values: rec.value['values'] || null
                            }
                        ] : [{
                            name: rec.name,
                            value: rec.value[rec.name],
                            values: rec.value['values'] || null
                        }]
                    } : {
                        ..._filters,
                        ...rec.value,
                        ...(rec.subList ? rec.subList : {})
                    }
                })
            }
            else {
                _filters = {
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d).format('YYYY-MM-DD'),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d).format('YYYY-MM-DD')
                }
            }
            if (_filters.dynamicFilter) {
                _filters = {
                    ..._filters,
                    dynamicFilter: JSON.stringify(_filters.dynamicFilter)
                }
            }
            if (props.dealersettings.isPorsche === true) {
                _filters.isPorsche = true;
            }
            _filters.inclInboundBuckeinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundBuckeinRpt;
            _filters.inclInboundLeadsinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundLeadsinRpt;

            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: props?.dealersettings?.mvwReport ? "getDynamicReportV2" : "getDynamicReport",
                params: JSON.stringify({
                    timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                    reportJSON: JSON.stringify(props.report),
                    calcCurrentStatus,
                    multiTDperLead,
                    ..._filters
                    // startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    // endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    // ...objFilter
                })
            }).then((response) => {
                setLoader(false)
                //setRefreshData(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }

    }, [refreshData, refreshClientData])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'] : []
        let reports = [];
        let _dynCols = [];
        _report.forEach(el => {
            props.dealersettings.clients.forEach(client => {
                if (el[client.id]) {
                    el[client.id].filter(a => props.report.groupBy === 'client' ? a.id === client.id : true).forEach(rec => {
                        if (Object.keys(rec).filter(r => Boolean(props.report.includeZero) ? true : _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                            reports.push({
                                ...rec,
                                arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                                profileImage: (props.report.groupBy === 'user' || props.report.groupBy === 'enqAddedBy') && _.find(props.groupUsers, { id: rec.id }) ? _.find(props.groupUsers, { id: rec.id }).profileImage : '',
                                clientID: client.id,
                                regionID: client.region,
                                groupID: client.group,
                                clientName: client.name,
                                groupName: _.find(props.dealersettings.groups, { id: client.group }) ? _.find(props.dealersettings.groups, { id: client.group }).name : 'No Group',
                                regionName: _.find(props.dealersettings.regions, { id: client.region }) ? _.find(props.dealersettings.regions, { id: client.region }).name : 'No Region',
                                newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                                qualifiedLeads: rec.arrqualifiedLeads ? rec.arrqualifiedLeads.length : 0,
                                exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                                pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                                totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                                overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                                convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                                show: rec.arrshow ? rec.arrshow.length : 0,
                                showCount: rec.arrshow ? rec.arrshow.length : 0,
                                noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                                //avg response time
                                arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                                totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                                responseTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                                //max reposnse time
                                arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                                arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                                totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                                maxresponseTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                                //min response time
                                arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                                arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                                totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                                minresponseTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),


                                //avg allocated time
                                arrallocatedLeads: rec.arrallocatedTime ? _.uniq(rec.arrallocatedTime.map(r => { return r.documentID })) : [],
                                totalallocatedTime: _.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0,
                                allocatedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                                //max allocated time
                                arrmaxallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                                arrmaxallocatedLeads: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? [_.maxBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                                totalmaxallocatedTime: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                                maxallocatedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                                //min allocated time
                                arrminallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                                arrminallocatedLeads: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? [_.minBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                                totalminallocatedTime: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                                minallocatedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),

                                //avg contacted time
                                arrcontactedLeads: rec.arrcontactedTime ? _.uniq(rec.arrcontactedTime.map(r => { return r.documentID })) : [],
                                totalcontactedTime: _.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0,
                                contactedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                                //max contacted time
                                arrmaxcontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                                arrmaxcontactedLeads: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? [_.maxBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                                totalmaxcontactedTime: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                                maxcontactedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                                //min contacted time
                                arrmincontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                                arrmincontactedLeads: _.minBy(rec.arrcontactedTime, 'contactedTime') ? [_.minBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                                totalmincontactedTime: _.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                                mincontactedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),


                                open: rec.arropen ? rec.arropen.length : 0,
                                statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                                //won: rec.arrwon ? rec.arrwon.length : 0,
                                arrwon: rec.arrwon ? _.uniq(rec.arrwon.map(r => { return r.documentID })) : [],
                                won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,
                                arrwonTD: rec.arrwonTD ? _.uniq(rec.arrwonTD.map(r => { return r.documentID })) : [],
                                wonTD: rec.arrwonTD ? _.sumBy(rec.arrwonTD, "soldCount") : 0,
                                arrdelivered: rec.arrdelivered ? _.uniq(rec.arrdelivered.map(r => { return r.documentID })) : [],
                                delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                                lost: rec.arrlost ? rec.arrlost.length : 0,
                                prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                                leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                                totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                                //delivered: rec.arrdelivered ? rec.arrdelivered.length : 0,
                                walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                                newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                                repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                                testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                                tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                                tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                                tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                                tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                                tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                                inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                                outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                                totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                                messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                                emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                                noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                                appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                                testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                                callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                                mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                                deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                                appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                                testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                                callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                                mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                                deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                                total: rec.arrtotal ? rec.arrtotal.length : 0,
                                tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                                tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                                apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                                target: props.report.target ? props.report.target : 0,
                                totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                                totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                                totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                                noActivity: rec.arrnoActivity ? rec.arrnoActivity.length : 0,
                                unactionedLeads: rec.arrunactionedLeads ? rec.arrunactionedLeads.length : 0,
                                noNotes: rec.arrnoNotes ? rec.arrnoNotes.length : 0,
                                noCalls: rec.arrnoCalls ? rec.arrnoCalls.length : 0,
                                noMessage: rec.arrnoMessage ? rec.arrnoMessage.length : 0,
                                doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                                allocated: rec.arrallocated ? rec.arrallocated.length : 0,
                                newCAP: rec.arrnewCAP ? rec.arrnewCAP.length : 0,
                                lostEnq: rec.arrlostEnq ? rec.arrlostEnq.length : 0,
                                outdated: rec.arroutdated ? rec.arroutdated.length : 0,
                                overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                                quotes: rec.arrquotes ? rec.arrquotes.length : 0,
                                arrquotes: rec.arrquotes ? _.uniq(rec.arrquotes.map(r => { return r.documentID })) : [],
                                arrwonAmount: rec.arrwonAmount ? _.uniq(rec.arrwonAmount.map(r => { return r.documentID })) : [],
                                arrquoteAmount: rec.arrquoteAmount ? _.uniq(rec.arrquoteAmount.map(r => { return r.documentID })) : [],
                                wonAmount: rec.arrwonAmount ? Math.round((_.sumBy(rec.arrwonAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                                quoteAmount: rec.arrquoteAmount ? Math.round((_.sumBy(rec.arrquoteAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                                ...getDynamicColumnLength(rec)
                            })
                            _dynCols.push({
                                name: rec.name,
                                value: `${props.report.groupBy}-${rec.id}`
                            })
                        }
                    })
                }
            })
        });
        if (props.report.level && props.report.level !== 'individual') {
            let _groupBy = _.chain(reports)
                .groupBy(`${props.report.level}ID`)
                .map((value, key) => ({ [key]: value }))
                .value();
            reports = [];
            props.dealersettings[`${props.report.level}s`].forEach(doc => {
                _groupBy.forEach(rec => {
                    if (rec[doc.id]) {
                        reports = [
                            ...reports,
                            ..._.chain(rec[doc.id])
                                .groupBy('id')
                                .map((value, key) => (
                                    {
                                        ...getDocumentIDs(value),
                                        id: key,
                                        clientID: value[0].clientID,
                                        name: value[0].name,
                                        displayName: value[0].displayName,
                                        clientName: value[0].clientName,
                                        regionID: value[0].regionID,
                                        groupID: value[0].groupID,
                                        groupName: value[0].groupName,
                                        regionName: value[0].regionName,
                                        ...sumByValues(value, value[0].clientID)
                                    }
                                )).value()]
                    }
                })

            })
        }

        reports = reports.map(rec => {
            return {
                ...rec,
                carryover: rec.arrcarryover.length,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.appointmentScheduled) : 0,
                showConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.show) : 0,
                noshowConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })
        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })
        props.report.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setDymamicColumns(_dynCols)
        if (reports.length === 0) {
            setClientReport([]);
            setSortReport([])
        }
        setProgressReport(reports)
    }

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return
        let reports = _.chain(progressReport)
            .groupBy(`${(_.isEmpty(props.report.displayBy) || props.report.displayBy === 'client') ? ((_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`) : 'id'}`)
            .map((value, key) => (
                {
                    ...getDocumentIDs(value),
                    id: key,
                    clientID: (_.isEmpty(props.report.level) || props.report.level === 'individual') ? value[0].clientID : key,
                    name: `${(_.isEmpty(props.report.displayBy) || props.report.displayBy === 'client') ? ((_.isEmpty(props.report.level) || props.report.level === 'individual') ? value[0].clientName : value[0][`${props.report.level}Name`]) : value[0].name}`,
                    displayName: `${(_.isEmpty(props.report.displayBy) || props.report.displayBy === 'client') ? ((_.isEmpty(props.report.level) || props.report.level === 'individual') ? value[0].clientName : value[0][`${props.report.level}Name`]) : value[0].displayName}`,
                    clientName: (_.isEmpty(props.report.level) || props.report.level === 'individual') ? value[0].clientName : value[0][`${props.report.level}Name`],
                    ...sumByValues(value, value[0].clientID)
                }
            )).value();

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.appointmentScheduled) : 0,
                showConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.show) : 0,
                noshowConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })
        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })
        props.report.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setClientReport(reports);
        setSortReport(reports);
        if (reports.length > 0) {
            setNestedTbl({
                ...nestedTbl,
                [reports[0].clientID]: true
            })
        }
    }, [progressReport])

    const getDocumentIDs = (data) => {
        let _result = {};
        Object.keys(data[0]).filter(r => r.startsWith('arr')).forEach(val => {
            _result = {
                ..._result,
                [val]: data.map(r => { return _.join(r[val]) })
                    .join(',')
                    .split(',')
                    .filter(r => !_.isEmpty(r))
            }
        })
        return _result;
    }

    const sumByValues = (value, clientID) => {
        return {
            newLeads: _.some(value, 'arrnewLeads') ? _.sum(value.map(r => r.arrnewLeads.length)) : 0,
            qualifiedLeads: _.some(value, 'arrqualifiedLeads') ? _.sum(value.map(r => r.arrqualifiedLeads.length)) : 0,
            exisitngLeads: _.some(value, 'arrexisitngLeads') ? _.sum(value.map(r => r.arrexisitngLeads.length)) : 0,
            pendingLeads: _.some(value, 'arrpendingLeads') ? _.sum(value.map(r => r.arrpendingLeads.length)) : 0,
            totalInbound: _.some(value, 'arrtotalInbound') ? _.sum(value.map(r => r.arrtotalInbound.length)) : 0,
            overallPending: _.some(value, 'arroverallPending') ? _.sum(value.map(r => r.arroverallPending.length)) : 0,
            convertedLeads: _.some(value, 'arrconvertedLeads') ? _.sum(value.map(r => r.arrconvertedLeads.length)) : 0,
            show: _.some(value, 'arrshow') ? _.sum(value.map(r => r.arrshow.length)) : 0,
            showCount: _.some(value, 'arrshow') ? _.sum(value.map(r => r.arrshow.length)) : 0,
            noshow: _.some(value, 'arrnoshow') ? _.sum(value.map(r => r.arrnoshow.length)) : 0,
            totalresponseTime: _.some(value, 'totalresponseTime') > 0 ? _.meanBy(value, 'totalresponseTime') : 0,
            totalmaxresponseTime: _.some(value, 'totalmaxresponseTime') > 0 ? _.meanBy(value, 'totalmaxresponseTime') : 0,
            totalminresponseTime: _.some(value, 'totalminresponseTime') > 0 ? _.meanBy(value, 'totalminresponseTime') : 0,
            responseTime: (_.some(value, 'totalresponseTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalresponseTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),
            maxresponseTime: (_.some(value, 'totalmaxresponseTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmaxresponseTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),
            minresponseTime: (_.some(value, 'totalminresponseTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalminresponseTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),

            totalallocatedTime: _.some(value, 'totalallocatedTime') > 0 ? _.meanBy(value, 'totalallocatedTime') : 0,
            totalmaxallocatedTime: _.some(value, 'totalmaxallocatedTime') > 0 ? _.meanBy(value, 'totalmaxallocatedTime') : 0,
            totalminallocatedTime: _.some(value, 'totalminallocatedTime') > 0 ? _.meanBy(value, 'totalminallocatedTime') : 0,
            allocatedTime: (_.some(value, 'totalallocatedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalallocatedTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),
            maxallocatedTime: (_.some(value, 'totalmaxallocatedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmaxallocatedTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),
            minallocatedTime: (_.some(value, 'totalminallocatedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalminallocatedTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),


            totalcontactedTime: _.some(value, 'totalcontactedTime') > 0 ? _.meanBy(value, 'totalcontactedTime') : 0,
            totalmaxcontactedTime: _.some(value, 'totalmaxcontactedTime') > 0 ? _.meanBy(value, 'totalmaxcontactedTime') : 0,
            totalmincontactedTime: _.some(value, 'totalmincontactedTime') > 0 ? _.meanBy(value, 'totalmincontactedTime') : 0,
            contactedTime: (_.some(value, 'totalcontactedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalcontactedTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),
            maxcontactedTime: (_.some(value, 'totalmaxcontactedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmaxcontactedTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),
            mincontactedTime: (_.some(value, 'totalmincontactedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmincontactedTime), _.find(props.dealersettings.clients, { id: clientID })) : '00:00:00'),


            open: _.some(value, 'arropen') ? _.sum(value.map(r => r.arropen.length)) : 0,
            statusModified: _.some(value, 'arrstatusModified') ? _.sum(value.map(r => r.arrstatusModified.length)) : 0,
            won: _.some(value, 'arrwon') ? _.sum(value.map(r => r.won)) : 0,
            wonTD: _.some(value, 'arrwonTD') ? _.sum(value.map(r => r.wonTD)) : 0,
            lost: _.some(value, 'arrlost') ? _.sum(value.map(r => r.arrlost.length)) : 0,
            prospectLost: _.some(value, 'arrprospectLost') ? _.sum(value.map(r => r.arrprospectLost.length)) : 0,
            leadLost: _.some(value, 'arrleadLost') ? _.sum(value.map(r => r.arrleadLost.length)) : 0,
            totalLost: _.some(value, 'arrtotalLost') ? _.sum(value.map(r => r.arrtotalLost.length)) : 0,
            delivered: _.some(value, 'arrdelivered') ? _.sum(value.map(r => r.delivered)) : 0,
            walkin: _.some(value, 'arrwalkin') ? _.sum(value.map(r => r.arrwalkin.length)) : 0,
            newwalkin: _.some(value, 'arrnewwalkin') ? _.sum(value.map(r => r.arrnewwalkin.length)) : 0,
            repeatwalkin: _.some(value, 'arrrepeatwalkin') ? _.sum(value.map(r => r.arrrepeatwalkin.length)) : 0,
            testdrive: _.some(value, 'arrtestdrive') ? _.sum(value.map(r => r.arrtestdrive.length)) : 0,
            tradein: _.some(value, 'arrtradein') ? _.sum(value.map(r => r.arrtradein.length)) : 0,
            tradeinwon: _.some(value, 'arrtradeinwon') ? _.sum(value.map(r => r.arrtradeinwon.length)) : 0,
            tradeininprogress: _.some(value, 'arrtradeininprogress') ? _.sum(value.map(r => r.arrtradeininprogress.length)) : 0,
            tradeincompleted: _.some(value, 'arrtradeincompleted') ? _.sum(value.map(r => r.arrtradeincompleted.length)) : 0,
            tradeinlost: _.some(value, 'arrtradeinlost') ? _.sum(value.map(r => r.arrtradeinlost.length)) : 0,
            inboundCall: _.some(value, 'arrinboundCall') ? _.sum(value.map(r => r.arrinboundCall.length)) : 0,
            outboundCall: _.some(value, 'arroutboundCall') ? _.sum(value.map(r => r.arroutboundCall.length)) : 0,
            totalCall: _.some(value, 'arrtotalCall') ? _.sum(value.map(r => r.arrtotalCall.length)) : 0,
            messageLog: _.some(value, 'arrmessageLog') ? _.sum(value.map(r => r.arrmessageLog.length)) : 0,
            emailLog: _.some(value, 'arremailLog') ? _.sum(value.map(r => r.arremailLog.length)) : 0,
            noteLog: _.some(value, 'arrnoteLog') ? _.sum(value.map(r => r.arrnoteLog.length)) : 0,
            appointmentScheduled: _.some(value, 'arrappointmentScheduled') ? _.sum(value.map(r => r.arrappointmentScheduled.length)) : 0,
            testdriveScheduled: _.some(value, 'arrtestdriveScheduled') ? _.sum(value.map(r => r.arrtestdriveScheduled.length)) : 0,
            callScheduled: _.some(value, 'arrcallScheduled') ? _.sum(value.map(r => r.arrcallScheduled.length)) : 0,
            mailScheduled: _.some(value, 'arrmailScheduled') ? _.sum(value.map(r => r.arrmailScheduled.length)) : 0,
            deliveryScheduled: _.some(value, 'arrdeliveryScheduled') ? _.sum(value.map(r => r.arrdeliveryScheduled.length)) : 0,
            appointment: _.some(value, 'arrappointment') ? _.sum(value.map(r => r.arrappointment.length)) : 0,
            testdriveActivity: _.some(value, 'arrtestdriveActivity') ? _.sum(value.map(r => r.arrtestdriveActivity.length)) : 0,
            callActivity: _.some(value, 'arrcallActivity') ? _.sum(value.map(r => r.arrcallActivity.length)) : 0,
            mailActivity: _.some(value, 'arrmailActivity') ? _.sum(value.map(r => r.arrmailActivity.length)) : 0,
            deliveryActivity: _.some(value, 'arrdeliveryActivity') ? _.sum(value.map(r => r.arrdeliveryActivity.length)) : 0,
            total: _.some(value, 'arrtotal') ? _.sum(value.map(r => r.arrtotal.length)) : 0,
            tdappt: _.some(value, 'arrtdappt') ? _.sum(value.map(r => r.arrtdappt.length)) : 0,
            tdtestdrive: _.some(value, 'arrtdtestdrive') ? _.sum(value.map(r => r.arrtdtestdrive.length)) : 0,
            apptsold: _.some(value, 'arrapptsold') ? _.sum(value.map(r => r.arrapptsold.length)) : 0,
            target: props.report.target ? props.report.target : 0,
            dealerData: value,
            totalnewLeads: _.some(value, 'arrdataQualitynewLeads') ? _.sum(value.map(r => r.arrdataQualitynewLeads.length)) : 0,
            totalexisitngLeads: _.some(value, 'arrdataQualityexisitngLeads') ? _.sum(value.map(r => r.arrdataQualityexisitngLeads.length)) : 0,
            totalLeads: _.some(value, 'arrdataQualitytotal') ? _.sum(value.map(r => r.arrdataQualitytotal.length)) : 0,
            carryover: _.some(value, 'arrcarryover') ? _.sum(value.map(r => r.arrcarryover.length)) : 0,
            noActivity: _.some(value, 'arrnoActivity') ? _.sum(value.map(r => r.arrnoActivity.length)) : 0,
            unactionedLeads: _.some(value, 'arrunactionedLeads') ? _.sum(value.map(r => r.arrunactionedLeads.length)) : 0,
            noNotes: _.some(value, 'arrnoNotes') ? _.sum(value.map(r => r.arrnoNotes.length)) : 0,
            noCalls: _.some(value, 'arrnoCalls') ? _.sum(value.map(r => r.arrnoCalls.length)) : 0,
            noMessage: _.some(value, 'arrnoMessage') ? _.sum(value.map(r => r.arrnoMessage.length)) : 0,
            doneActivity: _.some(value, 'arrdoneActivity') ? _.sum(value.map(r => r.arrdoneActivity.length)) : 0,
            allocated: _.some(value, 'arrallocated') ? _.sum(value.map(r => r.arrallocated.length)) : 0,
            newCAP: _.some(value, 'arrnewCAP') ? _.sum(value.map(r => r.arrnewCAP.length)) : 0,
            outdated: _.some(value, 'arroutdated') ? _.sum(value.map(r => r.arroutdated.length)) : 0,
            overdueActivity: _.some(value, 'arroverdueActivity') ? _.sum(value.map(r => r.arroverdueActivity.length)) : 0,
            quotes: _.some(value, 'arrquotes') ? _.sum(value.map(r => r.arrquotes.length)) : 0,
            wonAmount: _.some(value, 'arrwonAmount') ? Math.round((_.sum(value.map(r => r.wonAmount)) + Number.EPSILON) * 100) / 100 : 0,
            quoteAmount: _.some(value, 'arrquoteAmount') ? Math.round((_.sum(value.map(r => r.quoteAmount)) + Number.EPSILON) * 100) / 100 : 0,
            ...getDynamicTotalLength(value)
        }
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setNestedTbl({});
        setSubListTbl({});
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...clientReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        setSortReport(sortableItems)
        return sortableItems;
    }, [clientReport, sortConfig]);

    const requestNestedSort = (key) => {
        let direction = 'ascending';
        if (
            nestedsortConfig &&
            nestedsortConfig.key === key &&
            nestedsortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setNestedSortConfig({ key, direction });
    };

    const nestedsortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(nestedsortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[nestedsortConfig.key] < b[nestedsortConfig.key]) {
                    return nestedsortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[nestedsortConfig.key] > b[nestedsortConfig.key]) {
                    return nestedsortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [progressReport, nestedsortConfig]);

    const handleModelClose = () => {
        setShowmodel(false)
        setDealermodel(false)
        setModelShow(defaultModals)
        setListCounts(0);
    }

    const deleteReport = () => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`reports/${props.report.documentID}`).delete()
                    .then(snapshot => {
                        toast.notify('Report deleted successfully', {
                            duration: 2000
                        })
                        window.firebase.firestore().doc(`schedular/${props.report.documentID}`).delete();

                        props.setKey(_.isArray(props.reportIDs) ? props.reportIDs.filter(a => a !== props.report.documentID)[0] : '')
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })
    }

    const cloneReport = () => {
        setReport({
            ...props.report,
            documentID: window.firebase.firestore().collection(`reports`).doc().id,
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid,
            owner: localStorage.uid,
            name: `${props.report.name} - Copy`
        })
        setShowmodel(true)
    }

    useEffect(() => {
        if (_.isEmpty(chartData))
            return
        try {
            chartData.exporting && chartData.exporting.getImage("png").then(rec => {
                setChartImg(rec)
            })
        }
        catch (error) {
            console.log(error)
        }
    }, [chartData])

    const getDynamicColumnLength = (data) => {
        let leadSourceData = {};
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: data[`arrenqtype-${rec.value}`] ? data[`arrenqtype-${rec.value}`].length : 0,
                        [`enqtypesold-${rec.value}`]: data[`arrenqtypesold-${rec.value}`] ? data[`arrenqtypesold-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: data[`arrorigin-${rec.value}`] ? data[`arrorigin-${rec.value}`].length : 0,
                        [`originsold-${rec.value}`]: data[`arroriginsold-${rec.value}`] ? data[`arroriginsold-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: data[`arrleadSource-${rec.value}`] ? data[`arrleadSource-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: data[`arrlostReason-${rec.value}`] ? data[`arrlostReason-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: data[`arroutcome-${rec.value}`] ? data[`arroutcome-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: data[`arrcallstatus-${rec.value}`] ? data[`arrcallstatus-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = data[`arrenqstatus-${rec.value}`] ? _.sumBy(data[`arrenqstatus-${rec.value}`], "soldCount") : 0;
                    let _totalCount = data[`arrtotal`] ? data[`arrtotal`].length : 0;
                    let _convertedCount = data[`arrconvertedLeads`] ? data[`arrconvertedLeads`].length : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrenqstatus-${rec.value}`]: data[`arrenqstatus-${rec.value}`] ? _.uniq(data[`arrenqstatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = data[`arrfinancestatus-${rec.value}`] ? _.sumBy(data[`arrfinancestatus-${rec.value}`], "soldCount") : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrfinancestatus-${rec.value}`]: data[`arrfinancestatus-${rec.value}`] ? _.uniq(data[`arrfinancestatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`financestatus-${rec.value}`]: _stsCount,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: data[`arr${item.type};${item.value};${rec}`] ? data[`arr${item.type};${item.value};${rec}`].length : 0
                        }
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && Boolean(a.default) === false)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`acttype-${rec.value}`]: data[`arracttype-${rec.value}`] ? data[`arracttype-${rec.value}`].length : 0,
                        [`acttypedone-${rec.value}`]: data[`arracttypedone-${rec.value}`] ? data[`arracttypedone-${rec.value}`].length : 0,
                    }
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = data[`arr${rec}`] ? _.sumBy(data[`arr${rec}`], "soldCount") : 0;
            leadSourceData = {
                ...leadSourceData,
                [`arr${rec}`]: data[`arr${rec}`] ? _.uniq(data[`arr${rec}`].map(r => { return r.documentID })) : [],
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = data[`arr${rec}plus`] ? _.sumBy(data[`arr${rec}plus`], "soldCount") : 0;
                leadSourceData = {
                    ...leadSourceData,
                    [`arr${rec}plus`]: data[`arr${rec}plus`] ? _.uniq(data[`arr${rec}plus`].map(r => { return r.documentID })) : [],
                    [`${rec}plus`]: __stsCount,
                }
            }
        })
        if (report?.dynColumns?.length > 0) {
            report.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let total = _.sumBy(rec.columns1.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`arr${rec.id}`]: [],
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    var _arrData = [];
                    rec.columns.forEach(r => {
                        _arrData = [
                            ..._arrData,
                            ...(r === 'won' || r === 'delivered' ? _.uniq(data[`arr${r}`].map(r => { return r.documentID })) : data[`arr${r}`] ? data[`arr${r}`] : [])
                        ]
                    })
                    leadSourceData = {
                        ...leadSourceData,
                        [`arr${rec.id}`]: _arrData,
                        [`${rec.id}`]: _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }))
                    }
                }
            })
        }
        return leadSourceData;
    }

    const getDynamicTotalLength = (data) => {
        let leadSourceData = {};
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: _.some(data, `arrenqtype-${rec.value}`) ? _.sum(data.map(r => r[`arrenqtype-${rec.value}`].length)) : 0,
                        [`enqtypesold-${rec.value}`]: _.some(data, `arrenqtypesold-${rec.value}`) ? _.sum(data.map(r => r[`arrenqtypesold-${rec.value}`].length)) : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: _.some(data, `arrorigin-${rec.value}`) ? _.sum(data.map(r => r[`arrorigin-${rec.value}`].length)) : 0,
                        [`originsold-${rec.value}`]: _.some(data, `arroriginsold-${rec.value}`) ? _.sum(data.map(r => r[`arroriginsold-${rec.value}`].length)) : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: _.some(data, `arrleadSource-${rec.value}`) ? _.sum(data.map(r => r[`arrleadSource-${rec.value}`].length)) : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: _.some(data, `arrlostReason-${rec.value}`) ? _.sum(data.map(r => r[`arrlostReason-${rec.value}`].length)) : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: _.some(data, `arroutcome-${rec.value}`) ? _.sum(data.map(r => r[`arroutcome-${rec.value}`].length)) : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: _.some(data, `arrcallstatus-${rec.value}`) ? _.sum(data.map(r => r[`arrcallstatus-${rec.value}`].length)) : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = _.some(data, `enqstatus-${rec.value}`) ? _.sum(data.map(r => r[`enqstatus-${rec.value}`])) : 0;
                    let _totalCount = _.some(data, `arrtotal`) ? _.sum(data.map(r => r[`arrtotal`].length)) : 0;
                    let _convertedCount = _.some(data, `arrconvertedLeads`) ? _.sum(data.map(r => r[`arrconvertedLeads`].length)) : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && Boolean(a.default) === false)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`acttype-${rec.value}`]: _.some(data, `arracttype-${rec.value}`) ? _.sum(data.map(r => r[`arracttype-${rec.value}`].length)) : 0,
                        [`acttypedone-${rec.value}`]: _.some(data, `arracttypedone-${rec.value}`) ? _.sum(data.map(r => r[`arracttypedone-${rec.value}`].length)) : 0,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`financestatus-${rec.value}`]: _.some(data, `arrfinancestatus-${rec.value}`) ? _.sum(data.map(r => r[`arrfinancestatus-${rec.value}`].length)) : 0,
                    }
                })
        props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: _.some(data, `arr${item.type};${item.value};${rec}`) ? _.sum(data.map(r => r[`arr${item.type};${item.value};${rec}`].length)) : 0,
                        }
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = _.some(data, `arr${rec}`) ? _.sum(data.map(r => r[`arr${rec}`].length)) : 0;
            leadSourceData = {
                ...leadSourceData,
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = _.some(data, `arr${rec}plus`) ? _.sum(data.map(r => r[`arr${rec}plus`].length)) : 0;
                leadSourceData = {
                    ...leadSourceData,
                    [`${rec}plus`]: __stsCount,
                }
            }
        })
        if (report?.dynColumns?.length > 0) {
            report.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return _.sum(data.map(d => d[`${r}`])) }));
                    let total = _.sumBy(rec.columns1.map(r => { return _.sum(data.map(d => d[`${r}`])) }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: _.some(data, `${rec.id}`) ? _.sum(data.map(r => r[`${rec.id}`])) : 0
                    }
                }
            })
        }
        if (data) {
            let cols = _.isArray(props.report.columns) ? props.report.columns : [props.report.columns];
            data.forEach(rec => {
                let arr = [];
                cols.forEach(col => {
                    if (rec[`arr${col}`])
                        arr = [...arr, ...rec[`arr${col}`]]
                })
                leadSourceData = {
                    ...leadSourceData,
                    [`${props.report.groupBy}-${rec.id}`]: arr.length,
                    [`arr${props.report.groupBy}-${rec.id}`]: arr,
                }
            })

        }
        return leadSourceData;
    }

    const getLeadsByEnquiryTypeTotal = (_progressReport) => {
        let leadSourceData = {};
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: _.sumBy(_progressReport, `enqtype-${rec.value}`),
                        [`enqtypesold-${rec.value}`]: _.sumBy(_progressReport, `enqtypesold-${rec.value}`)
                    }
                })

        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: _.sumBy(_progressReport, `origin-${rec.value}`),
                        [`originsold-${rec.value}`]: _.sumBy(_progressReport, `originsold-${rec.value}`)
                    }
                })

        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: _.sumBy(_progressReport, `leadSource-${rec.value}`)
                    }
                })

        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: _.sumBy(_progressReport, `lostReason-${rec.value}`)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: _.sumBy(_progressReport, `outcome-${rec.value}`)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: _.sumBy(_progressReport, `callstatus-${rec.value}`)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = _.sumBy(_progressReport, `enqstatus-${rec.value}`);
                    let _totalCount = _.sumBy(_progressReport, `total`);
                    let _convertedCount = _.sumBy(_progressReport, `convertedLeads`);
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: `${(isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0}%`,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = _.sumBy(_progressReport, `financestatus-${rec.value}`);
                    leadSourceData = {
                        ...leadSourceData,
                        [`financestatus-${rec.value}`]: _stsCount,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        let _stsCount = _.sumBy(_progressReport, `${item.type};${item.value};${rec}`);
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: _stsCount
                        }
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = _.sumBy(_progressReport, `${rec}`);
            leadSourceData = {
                ...leadSourceData,
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = _.sumBy(_progressReport, `${rec}plus`);
                leadSourceData = {
                    ...leadSourceData,
                    [`${rec}plus`]: __stsCount,
                }
            }
        })
        if (report?.dynColumns?.length > 0) {
            report.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return _.sumBy(_progressReport, `${r}`) }));
                    let total = _.sumBy(rec.columns1.map(r => { return _.sumBy(_progressReport, `${r}`) }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: _.sumBy(rec.columns.map(r => { return _.sumBy(_progressReport, `${r}`) }))
                    }
                }
            })
        }
        return leadSourceData;
    }

    const ReportPdf = () => (
        <Document>
            <Page style={styles.page} size="A4" orientation="lanscape">
                <View style={styles.header}>
                    <View style={styles.headerwrapLeft}>
                        <Text style={{ fontSize: 13 }}>{props.dealersettings.client.name}</Text>
                    </View>
                    <View style={styles.headerwrapRight}>
                        <Text style={{ fontSize: 13 }}>{props.report.name}</Text>
                        <Text style={{ fontSize: 10, color: '#6b717f', paddingTop: 5 }}>{moment().format('DD/MM/YYYY hh:mm A')}</Text>
                    </View>
                </View>
                <View style={styles.reportImage}>
                    {
                        chartImg ? (<Image style={styles.image} src={chartImg} />) : (<></>)
                    }

                </View>
                <View style={styles.footer}>
                    <View style={styles.footerCpy}>
                        <View style={styles.footerLeft}>
                            <Text>Powered by <Link src="http://fusionsd.com.au/" style={{ color: '#666', textDecoration: 'none' }}>Fusion SD</Link></Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
    const DownloadPdf = () => {
        return useMemo(
            () => (
                <PDFDownloadLink document={<ReportPdf />} fileName={`${props.report.name.toLowerCase().replace(/ /g, '-')}.pdf`}>
                    {({ loading }) => ((loading || _.isEmpty(chartImg)) ? (<></>) : (<> <i className="ico icon-files-pdf"></i> Export PDF </>))}
                </PDFDownloadLink>
            ),
            [chartImg],
        )
    }

    const handleShowEnquiryList = (val, rec) => {

        // if (props.report.displayBy === 'client') {
        //     setDealerData(rec.dealerData);
        //     setPopHeader(rec.clientName);
        //     setDealermodel(true);
        // }
        var cols = reportColumns.find(item => item.value === val)
        if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
            if (cols.type === 'activity' || cols.type === 'scheduleActivity') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setListCounts(rec[`arr${cols.value}`].length)
                setPopHeader('Activity List')
                setModelShow({
                    ...modelShow,
                    activity: true
                })
            }
            else if (cols.type === 'log') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setListCounts(rec[`arr${cols.value}`].length)
                setPopHeader('Customer logs')
                setModelShow({
                    ...modelShow,
                    logs: true
                })
            }
            else if (cols.type === 'tradein') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setListCounts(rec[`arr${cols.value}`].length)
                setPopHeader('Trade-In List')
                setModelShow({
                    ...modelShow,
                    tradein: true
                })
            }
            else if (cols.type === 'testdrive' && multiTDperLead) {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setListCounts(rec[`arr${cols.value}`].length)
                setPopHeader('Test Drives List')
                setModelShow({
                    ...modelShow,
                    testdrive: true
                })
            }
            else if (cols.value === 'responseTime') {
                setFilter({
                    documentIDs: rec[`arrresponseLeads`]
                })
                setListCounts(rec[`arrresponseLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'maxresponseTime') {
                setFilter({
                    documentIDs: rec[`arrmaxresponseLeads`]
                })
                setListCounts(rec[`arrmaxresponseLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'minresponseTime') {
                setFilter({
                    documentIDs: rec[`arrminresponseLeads`]
                })
                setListCounts(rec[`arrminresponseLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'allocatedTime') {
                setFilter({
                    documentIDs: rec[`arrallocatedLeads`]
                })
                setListCounts(rec[`arrallocatedLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'maxallocatedTime') {
                setFilter({
                    documentIDs: rec[`arrmaxallocatedLeads`]
                })
                setListCounts(rec[`arrmaxallocatedLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'minallocatedTime') {
                setFilter({
                    documentIDs: rec[`arrminallocatedLeads`]
                })
                setListCounts(rec[`arrminallocatedLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'contactedTime') {
                setFilter({
                    documentIDs: rec[`arrcontactedLeads`]
                })
                setListCounts(rec[`arrcontactedLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'maxcontactedTime') {
                setFilter({
                    documentIDs: rec[`arrmaxcontactedLeads`]
                })
                setListCounts(rec[`arrmaxcontactedLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'mincontactedTime') {
                setFilter({
                    documentIDs: rec[`arrmincontactedLeads`]
                })
                setListCounts(rec[`arrmincontactedLeads`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.columnType === 'service') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setListCounts(rec[`arr${cols.value}`].length)
                setPopHeader('Quotes')
                setModelShow({
                    ...modelShow,
                    quote: true
                })
            }
            else {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setListCounts(rec[`arr${cols.value}`].length)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }

        }
        else if (cols && cols.dqConv === true && rec[`arr${cols.fieldValue}`] && rec[`arr${cols.fieldValue}`].length > 0) {
            setFilter({
                documentIDs: rec[`arr${cols.fieldValue}`]
            })
            setListCounts(rec[`arr${cols.fieldValue}`].length)
            setPopHeader('Enquiry List')
            setModelShow({
                ...modelShow,
                enquiry: true
            })
        }
        else if (rec[`arr${val}`]) {
            setFilter({
                documentIDs: rec[`arr${val}`]
            })
            setListCounts(rec[`arr${val}`].length)
            setPopHeader('Enquiry List')
            setModelShow({
                ...modelShow,
                enquiry: true
            })
        }
    }

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const isValidFilters = (isScroll) => {
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';


        let _filterLists = Object.assign([], report.objFilters);

        !_.isEmpty(_filterLists) && _filterLists.forEach(_data => {
            if (_.isEmpty(_data.name)) {
                formIsValid = false;
                errors['name_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;

            }
            else if (_.isEmpty(_data.value)) {
                formIsValid = false;
                errors['value_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;
            }

        });

        setErrorFilter(errors);
        //console.log('isValidFilters,', errors)
        return formIsValid;
    }

    const addFilter = () => {
        let _lists = Object.assign([], report.objFilters);
        let _isValidFilters = isValidFilters();
        if (!_isValidFilters)
            return;

        _lists.push({ name: null, value: null, id: uuid() })
        setReport({
            ...report,
            ['objFilters']: _lists
        });

    }

    const updateFilter = (_data) => {
        if (!_.isEmpty(_data)) {
            const _reLists = Object.assign([], report.objFilters);

            _reLists.filter(function (obj) {
                //Update old data
                if (obj.id === _data.id) {
                    for (let [key, value] of Object.entries(_data)) {
                        obj[key] = value;
                    }
                }
            });
            //console.log('updateRule', _data, _reLists)

            setReport({
                ...report,
                ['objFilters']: _reLists
            });
        }
    }

    const deleteFilter = (_id) => {
        if (!_.isEmpty(_id)) {
            const _reLists = Object.assign([], report.objFilters);
            setReport({
                ...report,
                ['objFilters']: _reLists.filter(m => m.id !== _id)
            });
        }
    }

    const saveFilter = () => {
        props.setKey(report.documentID);
        setLoader(true)
        window.firebase.firestore().doc(`reports/${report.documentID}`).set(report, { merge: true })
            .then(snapshot => {
                setRefreshData(!refreshData);

                // setTimeout(() => {
                //     setRefreshData(true)
                // }, 1000);
                // toast.notify('Report updated successfully', {
                //     duration: 2000
                // });
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const toggleFilter = (e, show) => {
        e.preventDefault();
        setShow(show);
        const reportWrapper = window.jQuery(`#${report.documentID}-report-collapse`);
        if (reportWrapper) {
            if (show)
                reportWrapper.fadeIn();
            else
                reportWrapper.fadeOut();
        }
    }

    const getDynamicCOls = (data) => {
        let cols = [];
        data.forEach(rec => {
            let vals = Object.keys(rec).filter(r => r.includes(`${props.report.groupBy}-`) && !r.includes(`arr${props.report.groupBy}-`))
            cols = [...cols, ...vals];
        })
        return _.uniq([...cols.filter(a => a !== `${props.report.groupBy}-others`), `${props.report.groupBy}-others`]);
    }

    const getFilterDates = () => {
        let dates = report?.objFilters?.filter(a => a.date)?.[0]?.value;
        let datesObj = {};
        if (dates?.startDate) {
            datesObj.startDate = moment.unix(dates?.startDate?.seconds).format('YYYY-MM-DD');
            datesObj.endDate = moment.unix(dates?.endDate?.seconds).format('YYYY-MM-DD');
        }
        return datesObj;
    }

    return (<>
        <div className="report-page-head">

            <h2>{props.report.name} </h2>
            <Dropdown className='enquiry-view-more float-right mt-0'>
                <Dropdown.Toggle as={CustomToggle} className='common-button'>
                    <i className='ico icon-more'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    as={CustomMenu}
                    ChildClass="more-dropdown dropsub-fix"
                    xplacement="bottom-end"
                >
                    {
                        props.report.addedBy === localStorage.uid || props.dealersettings.roleName === 'Administrator' || _editReport ? (
                            <Dropdown.Item eventKey="1" onClick={(e) => { setShowmodel(true) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                        ) : (<></>)
                    }
                    {
                        props.report.reportType === 'table' ? (<Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                            <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`${props.report.name.toLowerCase().replace(/ /g, '-')}`}>
                                <ExcelSheet dataSet={csvData} name={`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`} />
                                {
                                    Object.keys(sheetData).map((rec, index) => {
                                        return <ExcelSheet key={index} dataSet={sheetData[rec]} name={`${_.find(clientReport, { id: rec }) ? CommonHelper.removeUnicodeChars(_.find(clientReport, { id: rec }).clientName) : rec}`} />
                                    })
                                }
                            </ExcelFile>
                        </Dropdown.Item>) : (<Dropdown.Item eventKey="3" as={'div'} bsPrefix={'li-'}>
                            <DownloadPdf />
                        </Dropdown.Item>)
                    }
                    {
                        props.report.addedBy === localStorage.uid || props.dealersettings.roleName === 'Administrator' || _editReport ? (<>
                            <Dropdown.Item eventKey="3" onClick={(e) => { deleteReport() }}><i className="ico icon-delete"></i> Delete </Dropdown.Item>
                            <Dropdown.Item eventKey="4" onClick={(e) => {
                                setModelShow({
                                    ...modelShow,
                                    sheduleReport: true
                                })
                            }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                            <Dropdown.Item eventKey="5" onClick={(e) => { cloneReport() }}><i className="ico icon-rearrange"></i> Clone Report </Dropdown.Item>
                        </>) : (<></>)
                    }
                </Dropdown.Menu>
            </Dropdown>
            {
                !_.isEmpty(schedueReport) ? (
                    <div className="reportsuccess-item-btn mr-2 float-right">
                        <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                        <div className="reportsuccess-inner-text">
                            <div className="reportsuccess-inner-text-top"> Scheduled </div>
                            <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                        </div>
                        <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                    </div>
                ) : (<></>)
            }

        </div>
        <div className="divider-line"></div>
        <div className="report-content-head pl-3 pr-3">
            <div className="row">
                {/* <div className="col-md-9">
                    <div className="row">
                        <div className="form-group col-md-4 pr-0 daterange-fix">
                            <AntDateRangePicker
                                value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                id={"dashboard-date-range"}
                                name={`${props.report.documentID}-date`}
                                format='DD/MM/YYYY'
                                onChange={(e) => { handleDateRangeChange(e, `${props.report.documentID}-date`) }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        setDates([start, end]);
                                    }
                                    else {
                                        setDates([])
                                    }
                                }}
                            //disabledDate={disabledDate}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <button type="button" className="btn btn-primary" onClick={() => { setRefreshData(!refreshData) }}> <i className="ico icon-search"></i> Search</button>
                        </div>
                    </div>
                </div> */}
                <div className="rule-filter-head">
                    {
                        !_.isEmpty(report.objFilters)
                            ?
                            <div className="float-left rule-filter-info cursor-pointer" onClick={(e) => { toggleFilter(e, !filterShow) }}> <i className="ico icon-filter"></i>
                                {Object.keys(report.objFilters).length} {Object.keys(report.objFilters).length > 1 ? ' filters' : ' filter'} applied
                            </div>
                            :
                            <></>
                    }
                    {
                        (report.visibility !== 'sharedView' || props.report.addedBy === localStorage.uid || Boolean(props.dealersettings.superAdmin)) ? (<div className="float-right rule-add-btn">
                            <div className="float-right ml-2 filter-collapse-btn">
                                <div className="filter-collapse-report"> <a href="#" onClick={(e) => { toggleFilter(e, !filterShow) }} className={`mini-button ${!filterShow ? 'collapsed' : ''}`}><i className="arrow-collapse"></i></a> </div>
                            </div>
                            <button type="button" className="btn btn-primary float-right ml-2"
                                onClick={(e) => { toggleFilter(e, true); addFilter(); }}>
                                <i className="ico icon-add mr-2"></i> Add Filter
                            </button>
                        </div>) : (<></>)
                    }
                </div>
                <div className={`rules-group-wrapper query-builder`} id={`${report.documentID}-report-collapse`}>
                    <div className="rules-group-body">
                        <div className="rules-list">
                            {
                                !_.isEmpty(report.objFilters) && report.objFilters.map((_rec, index) => {
                                    return <CustomFilters
                                        {...props}
                                        key={index}
                                        docID={_rec ? _rec.id : ''}
                                        fieldData={_rec}
                                        errors={errorFilters}
                                        filterFields={report.objFilters}
                                        updateFilter={updateFilter}
                                        deleteFilter={deleteFilter}
                                        columns={report.columns}
                                        fromOEM={true}
                                        integrations={integrations}
                                        report={report}
                                        filterOptions={[...filterOptions, ...enqDynamicOptions]}
                                        reportFilters={[...reportFilters, ...enqDynamicOptions]}
                                        labels={labels}
                                    ></CustomFilters>
                                })
                            }

                        </div>
                    </div>
                    <div className="filter-collapse-report-footer">
                        <button type="button" className="btn btn-green float-left ml-3" onClick={() => saveFilter()}>Apply</button>
                    </div>
                </div>
                {/* {
                    !_.isEmpty(props.report.filters) ? (
                        <div className="col-md-3">
                            <div className="float-right">
                                <Dropdown ref={node} alignRight show={filterShow}>
                                    <div className="advanced-filter-btn mr-2 float-right">
                                        <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                            setShow(!filterShow)
                                        }}>
                                            {filterCounts()}
                                            <i className="ico icon-filter"></i>Filters
                                        </Dropdown.Toggle>
                                    </div>
                                    <Dropdown.Menu as={CustomFilter}
                                        id="table-reports-cols-settings-pipeline"
                                        className="dropdown-menu dropdown-menu-left"
                                        ChildClass="form-style quick-filter-wrap"
                                        xplacement="bottom-end"
                                    >
                                        <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                            <div className={`report-filter-wraper form-style ${props.report.filters.length > 5 ? 'report-filter-height-fix' : ''}`}>
                                                {
                                                    props.report.filters.map((rec, i) => {
                                                        return <DynamicFilters
                                                            key={i}
                                                            {...props}
                                                            filter={rec}
                                                            objFilter={objFilter}
                                                            columns={props.report.columns}
                                                            fromOEM={true}
                                                            integrations={integrations}
                                                            setObjFilter={(e, name) => {
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: e ? e.value : ''
                                                                })
                                                            }}
                                                            setMultibjFilter={(selectedOptions, name) => {
                                                                let value = [];
                                                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: value
                                                                });
                                                            }}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="quick-filter-footer">
                                            <button type="button" className="btn btn-default float-left" onClick={() => {
                                                // setDateRange({
                                                //     startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                //     endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                                // })
                                                // setOldDateRange({
                                                //     startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                //     endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                                // })
                                                setRefreshData(!refreshData)
                                                setObjFilter(defaultFilters)
                                                setShow(false)
                                                setShowCount(false)
                                            }}>Clear</button>
                                            <button type="button" className="btn btn-primary float-right" onClick={() => {
                                                setRefreshData(!refreshData)
                                                setShow(false)
                                                setShowCount(true)
                                            }}>Apply</button>

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    ) : (<></>)
                } */}
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : clientReport && clientReport.length === 0 ? (
                <div className="common-table">
                    <div className="text-center p-5">
                        <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                        <div className="no-data-txt mt-2"> No records found</div>
                    </div>
                </div>
            ) : props.report.reportType === 'table' ? (<div className="common-table" style={{ overflowX: 'auto' }}>
                {
                    props.report.columnType === 'persistency' ? (

                        <div className="table-progressive-wrapper mb-2">
                            <div className="table-progressive-graph">
                                <div className="table-progressive-graph-image">
                                    <div id={`progressive-${props.report.documentID}`} style={{ width: "60px", height: '55px', marginLeft: '5px', marginTop: '2px' }}></div>
                                </div>
                                <div className="table-progressive-graph-title">Progressive Sales <br /> ({_.sumBy(clientReport, '15plus')}/{_.sumBy(clientReport, '4plus')}) x 100</div>

                            </div>
                            <div className="table-progressive-table">

                                <div className="common-table">
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="25%">Sales at first contact</th>
                                                <th scope="col" className="head-light" width="25%">Sales within 4 contacts</th>
                                                <th scope="col" className="head-light" width="25%">Sales after 5 contacts</th>
                                                <th scope="col" className="head-light table-sub-total" width="25%">Total</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{_.sumBy(clientReport, '0')}</td>
                                                <td>{_.sumBy(clientReport, '4plus')}</td>
                                                <td>{_.sumBy(clientReport, '15plus')}</td>
                                                <td className='table-sub-total'>{_.sumBy(clientReport, 'won')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)
                }
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <td className="head-light" width="10%">
                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                    e.preventDefault()
                                    requestSort('clientName')
                                }}>
                                    <div className="table-sort"><a href="#">
                                        {
                                            sortConfig && sortConfig.key === 'clientName' ?
                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                        }
                                    </a>
                                    </div>
                                    {(_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'Dealer' : `${CommonHelper.autoCaps(props.report.level)}`}
                                </div>
                            </td>
                            {
                                props.report.columns.map((rec, index) => {
                                    return <Fragment key={index}><td className={`head-light text-center ${reportColumns.find(item => item.value === rec) && reportColumns.find(item => item.value === rec).cls ? reportColumns.find(item => item.value === rec).cls : ''}`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {
                                                reportColumns.find(item => item.value === rec) ?
                                                    (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                    (<span className="pipeline-inactive-span">(In Active)</span>)
                                            }
                                            {
                                                rec.includes('enqtype-') || rec.includes('enqtypesold-') ? (
                                                    <span className="th-subhead">(Enquiry Type)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('origin-') || rec.includes('originsold-') ? (
                                                    <span className="th-subhead">(Origin)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('leadSource-') ? (
                                                    <span className="th-subhead">(Lead Source)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('lostReason-') ? (
                                                    <span className="th-subhead">(Lost Reason)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('outcome-') ? (
                                                    <span className="th-subhead">(Activity Outcome)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('callstatus-') ? (
                                                    <span className="th-subhead">(Call Status)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('enqstatus-') ? (
                                                    <span className="th-subhead">(Enquiry Status)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('financestatus-') ? (
                                                    <span className="th-subhead">(Finance Status)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('select;') ? (
                                                    <span className="th-subhead">({reportColumns.find(item => item.value === rec)?.name || 'Dynamic Field'})</span>
                                                ) : (<></>)
                                            }
                                        </div>
                                    </td>
                                        {
                                            props?.report?.dynColumns?.filter(a => a.moveAfter === rec)?.length ? props.report.dynColumns.filter(a => a.moveAfter === rec).map((dynCol) => {
                                                return <td key={index} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                        e.preventDefault()
                                                        requestSort(dynCol.id)
                                                    }}>
                                                        <div className="table-sort"><a href="#">
                                                            {
                                                                sortConfig && sortConfig.key === dynCol.id ?
                                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                            }
                                                        </a>
                                                        </div>
                                                        {dynCol.name}
                                                    </div>
                                                </td>
                                            }) : (<></>)
                                        }
                                    </Fragment>
                                })
                            }
                            {
                                props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((rec, index) => {
                                    return <td key={index} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec.id)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec.id ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {rec.name}
                                        </div>
                                    </td>
                                }) : (<></>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedItems.length > 0 ?
                                sortedItems.map((rec, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <tr key={index}>
                                                <td>
                                                    <div className="dealership-tbl cursor-pointer position-relative"
                                                        onClick={() => {
                                                            setNestedTbl({
                                                                ...nestedTbl,
                                                                [rec.id]: nestedTbl[rec.id] && !_.isEmpty(nestedTbl[rec.id].toString()) ? !nestedTbl[rec.id] : true
                                                            })
                                                        }}>
                                                        {rec.clientName}
                                                        <div className="table-sort"><a href="#"><i className={`ico icon-sort-${nestedTbl[rec.id] === true ? 'asc' : 'desc'}`}></i></a></div>
                                                    </div>
                                                </td>
                                                {
                                                    props.report.columns.map((val, index) => {
                                                        return <Fragment key={index}><td key={index} className={`text-center cursor-pointer ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}
                                                            onClick={(e) => {
                                                                handleShowEnquiryList(val, rec);
                                                            }}>{
                                                                reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={rec[val]}
                                                                    />
                                                                )
                                                                    : `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                            }
                                                        </td>
                                                            {
                                                                props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length ? props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                    return <td key={index} className={`text-center cursor-pointer`}
                                                                        onClick={() => {
                                                                            handleShowEnquiryList(dynCol.id, rec);
                                                                        }}>
                                                                        {rec[dynCol.id]}{dynCol.action === 'percentage' ? '%' : ''}
                                                                    </td>
                                                                }) : (<></>)
                                                            }
                                                        </Fragment>
                                                    })
                                                }
                                                {
                                                    props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                        return <td key={index} className={`text-center cursor-pointer`}
                                                            onClick={() => {
                                                                handleShowEnquiryList(val.id, rec);
                                                            }}>
                                                            {rec[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                        </td>
                                                    }) : (<></>)
                                                }
                                            </tr>
                                            <tr className={`tr-hover-fix ${nestedTbl[rec.id] === true ? '' : 'hide'}`}>
                                                <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)} style={{ padding: ` 0.5rem !important` }}>
                                                    <div className="table-treeview">
                                                        <table className="table table-bordered mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <td className="head-light" width="9.7%">
                                                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                            e.preventDefault()
                                                                            requestNestedSort('displayName')
                                                                        }}>
                                                                            <div className="table-sort"><a href="#">
                                                                                {
                                                                                    nestedsortConfig && nestedsortConfig.key === 'displayName' ?
                                                                                        (<i className={`ico icon-sort-${nestedsortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                                }
                                                                            </a>
                                                                            </div>
                                                                            {CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value')}
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        props.report.columns.map((rec, index) => {
                                                                            return <Fragment key={index}><td className={`text-center cursor-pointer ${reportColumns.find(item => item.value === rec) && reportColumns.find(item => item.value === rec).cls ? reportColumns.find(item => item.value === rec).cls : ''}`}  width={`${(90.3 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                                                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    requestNestedSort(rec)
                                                                                }}>
                                                                                    <div className="table-sort"><a href="#">
                                                                                        {
                                                                                            nestedsortConfig && nestedsortConfig.key === rec ?
                                                                                                (<i className={`ico icon-sort-${nestedsortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                                        }
                                                                                    </a>
                                                                                    </div>
                                                                                    {
                                                                                        reportColumns.find(item => item.value === rec) ?
                                                                                            (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                                                            (<span className="pipeline-inactive-span">(In Active)</span>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('enqtype-') || rec.includes('enqtypesold-') ? (
                                                                                            <span className="th-subhead">(Enquiry Type)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('origin-') || rec.includes('originsold-') ? (
                                                                                            <span className="th-subhead">(Origin)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('leadSource-') ? (
                                                                                            <span className="th-subhead">(Lead Source)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('lostReason-') ? (
                                                                                            <span className="th-subhead">(Lost Reason)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('outcome-') ? (
                                                                                            <span className="th-subhead">(Activity Outcome)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('callstatus-') ? (
                                                                                            <span className="th-subhead">(Call Status)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('enqstatus-') ? (
                                                                                            <span className="th-subhead">(Enquiry Status)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('financestatus-') ? (
                                                                                            <span className="th-subhead">(Finance Status)</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        rec.includes('select;') ? (
                                                                                            <span className="th-subhead">({reportColumns.find(item => item.value === rec)?.name || 'Dynamic Field'})</span>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                                {
                                                                                    props?.report?.dynColumns?.filter(a => a.moveAfter === rec)?.length ? props.report.dynColumns.filter(a => a.moveAfter === rec).map((dynCol) => {
                                                                                        return <td key={dynCol.id} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                                                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                                                e.preventDefault()
                                                                                                requestSort(dynCol.id)
                                                                                            }}>
                                                                                                <div className="table-sort"><a href="#">
                                                                                                    {
                                                                                                        sortConfig && sortConfig.key === dynCol.id ?
                                                                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                                                    }
                                                                                                </a>
                                                                                                </div>
                                                                                                {dynCol.name}
                                                                                            </div>
                                                                                        </td>
                                                                                    }) : (<></>)
                                                                                }
                                                                            </Fragment>
                                                                        })
                                                                    }
                                                                    {
                                                                        props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((rec, index) => {
                                                                            return <td key={index} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                                                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    requestSort(rec.id)
                                                                                }}>
                                                                                    <div className="table-sort"><a href="#">
                                                                                        {
                                                                                            sortConfig && sortConfig.key === rec.id ?
                                                                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                                        }
                                                                                    </a>
                                                                                    </div>
                                                                                    {rec.name}
                                                                                </div>
                                                                            </td>
                                                                        }) : (<></>)
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    nestedsortedItems.filter(e => e[(_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`] === rec.id).length > 0 ?
                                                                        nestedsortedItems.filter(e => e[(_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`] === rec.id).map((data, index) => {
                                                                            return (<Fragment key={index}>
                                                                                <tr>
                                                                                    <td>
                                                                                        {
                                                                                            (props.report.groupBy === 'user' || props.report.groupBy === 'enqAddedBy') ? (<div className="table-user-list">
                                                                                                <a href="#">
                                                                                                    <div className="table-user-avatar">
                                                                                                        <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(data.profileImage, data.displayName)} /></div>
                                                                                                    {data.displayName}
                                                                                                </a>
                                                                                            </div>) : props.report.groupBy === 'lostReasons' ? (
                                                                                                <div className="dealership-tbl cursor-pointer position-relative" onClick={() => {
                                                                                                    setSubListTbl({
                                                                                                        ...subListTbl,
                                                                                                        [data.id]: subListTbl[data.id] && !_.isEmpty(subListTbl[data.id].toString()) ? !subListTbl[data.id] : true
                                                                                                    })
                                                                                                }}>
                                                                                                    {data.displayName}
                                                                                                    <div className="table-sort"><a href="#"><i className={`ico icon-sort-${subListTbl[data.id] === true ? 'asc' : 'desc'}`}></i></a></div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="dealership-tbl">
                                                                                                    {data.displayName}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    {
                                                                                        props.report.columns.map((val, index) => {
                                                                                            return <Fragment key={index}><td className={`text-center cursor-pointer ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}
                                                                                                onClick={(e) => {
                                                                                                    handleShowEnquiryList(val, data)
                                                                                                }}>
                                                                                                {
                                                                                                    reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                                                                        <NumberFormat
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={currencySymbol}
                                                                                                            displayType={'text'}
                                                                                                            value={data[val]}
                                                                                                        />
                                                                                                    )
                                                                                                        : `${data[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                                                                }
                                                                                            </td>
                                                                                                {
                                                                                                    props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length ? props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol1) => {
                                                                                                        return <td key={dynCol1.id} className={`text-center cursor-pointer`}
                                                                                                            onClick={(e) => {
                                                                                                                handleShowEnquiryList(dynCol1.id, data)
                                                                                                            }}>
                                                                                                            {data[dynCol1.id]}{dynCol1.action === 'percentage' ? '%' : ''}
                                                                                                        </td>
                                                                                                    }) : (<></>)
                                                                                                }
                                                                                            </Fragment>
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                                                            return <td key={index} className={`text-center cursor-pointer`}
                                                                                                onClick={(e) => {
                                                                                                    handleShowEnquiryList(val.id, data)
                                                                                                }}>
                                                                                                {data[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                                                            </td>
                                                                                        }) : (<></>)
                                                                                    }
                                                                                </tr>
                                                                                {
                                                                                    props.report.groupBy === 'lostReasons' && data.subList && data.subList.length > 0 ? (
                                                                                        <tr className={`tr-hover-fix ${subListTbl[data.id] === true ? '' : 'hide'}`}>
                                                                                            <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)} style={{ padding: ` 0.5rem !important` }}>
                                                                                                <div className="table-treeview">
                                                                                                    <table className="table table-bordered mb-0">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <td className="head-light" width="16%">
                                                                                                                    <div className="sort-cell cursor-pointer">
                                                                                                                        <div className="table-sort">
                                                                                                                        </div>
                                                                                                                        Lost Sub Reasons
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                {
                                                                                                                    props.report.columns.map((subrec, index) => {
                                                                                                                        return <Fragment key={index} ><td className="head-light text-center" width="7%">
                                                                                                                            <div className="sort-cell cursor-pointer">
                                                                                                                                <div className="table-sort">
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    reportColumns.find(item => item.value === subrec) ?
                                                                                                                                        (<>{CommonHelper.getLabelByValue(reportColumns, subrec)}</>) :
                                                                                                                                        (<span className="pipeline-inactive-span">(In Active)</span>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    subrec.includes('enqtype-') || subrec.includes('enqtypesold-') ? (
                                                                                                                                        <span className="th-subhead">(Enquiry Type)</span>
                                                                                                                                    ) : (<></>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    subrec.includes('origin-') || subrec.includes('originsold-') ? (
                                                                                                                                        <span className="th-subhead">(Origin)</span>
                                                                                                                                    ) : (<></>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    subrec.includes('leadSource-') ? (
                                                                                                                                        <span className="th-subhead">(Lead Source)</span>
                                                                                                                                    ) : (<></>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    subrec.includes('lostReason-') ? (
                                                                                                                                        <span className="th-subhead">(Lost Reason)</span>
                                                                                                                                    ) : (<></>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    subrec.includes('outcome-') ? (
                                                                                                                                        <span className="th-subhead">(Activity Outcome)</span>
                                                                                                                                    ) : (<></>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    subrec.includes('callstatus-') ? (
                                                                                                                                        <span className="th-subhead">(Call Status)</span>
                                                                                                                                    ) : (<></>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    subrec.includes('enqstatus-') ? (
                                                                                                                                        <span className="th-subhead">(Enquiry Status)</span>
                                                                                                                                    ) : (<></>)
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                            {
                                                                                                                                props?.report?.dynColumns?.filter(a => a.moveAfter === subrec)?.length ? props.report.dynColumns.filter(a => a.moveAfter === subrec).map((dynCol) => {
                                                                                                                                    return <td key={index} className="head-light text-center" width="7%">
                                                                                                                                        <div className="sort-cell cursor-pointer">
                                                                                                                                            <div className="table-sort">
                                                                                                                                            </div>
                                                                                                                                            {dynCol.name}
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                }) : (<></>)
                                                                                                                            }
                                                                                                                        </Fragment>
                                                                                                                    })
                                                                                                                }
                                                                                                                {
                                                                                                                    props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((subrec, index) => {
                                                                                                                        return <td key={index} className="head-light text-center" width="7%">
                                                                                                                            <div className="sort-cell cursor-pointer">
                                                                                                                                <div className="table-sort">
                                                                                                                                </div>
                                                                                                                                {subrec.name}
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    }) : (<></>)
                                                                                                                }
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                data.subList.map((subdata, index) => {
                                                                                                                    return <tr key={index}>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                (props.report.groupBy === 'user' || props.report.groupBy === 'enqAddedBy') ? (<div className="table-user-list">
                                                                                                                                    <a href="#">
                                                                                                                                        <div className="table-user-avatar">
                                                                                                                                            <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(subdata.profileImage, subdata.displayName)} /></div>
                                                                                                                                        {subdata.displayName}
                                                                                                                                    </a>
                                                                                                                                </div>) : (
                                                                                                                                    <div className="dealership-tbl">
                                                                                                                                        {subdata.displayName}
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                        {
                                                                                                                            props.report.columns.map((val, index) => {
                                                                                                                                return <Fragment key={index}><td className={`text-center cursor-pointer ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}
                                                                                                                                    onClick={(e) => {
                                                                                                                                        handleShowEnquiryList(val, subdata)
                                                                                                                                    }}>{`${subdata[`arr${val}`].length}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`}</td>
                                                                                                                                    {
                                                                                                                                        props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length ? props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                                                                                            return <td key={index} className={`text-center cursor-pointer`}
                                                                                                                                                onClick={(e) => {
                                                                                                                                                    handleShowEnquiryList(dynCol.id, subdata)
                                                                                                                                                }}>{`${subdata[`arr${dynCol.id}`].length}${dynCol.action === 'percentage' ? '%' : ''}`}</td>
                                                                                                                                        }) : (<></>)
                                                                                                                                    }
                                                                                                                                </Fragment>
                                                                                                                            })
                                                                                                                        }
                                                                                                                        {
                                                                                                                            props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                                                                                                return <td key={index} className={`text-center cursor-pointer`}
                                                                                                                                    onClick={(e) => {
                                                                                                                                        handleShowEnquiryList(val.id, subdata)
                                                                                                                                    }}>{`${subdata[`arr${val.id}`].length}${val.action === 'percentage' ? '%' : ''}`}</td>
                                                                                                                            }) : (<></>)
                                                                                                                        }
                                                                                                                    </tr>
                                                                                                                })
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ) : (
                                                                                        <tr className={`tr-hover-fix ${subListTbl[data.id] === true ? '' : 'hide'}`}>
                                                                                            <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)}>
                                                                                                <div className="spinner-loader h-100 p-5">
                                                                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                                                        <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                                                                        <div className="no-data-txt mt-2"> No data</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </Fragment>)
                                                                        }) : (
                                                                            <tr>
                                                                                <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)}>
                                                                                    <div className="spinner-loader h-100 p-5">
                                                                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                                            <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                                                            <div className="no-data-txt mt-2"> No data</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>)
                                }) : (
                                    <tr>
                                        <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)}>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> No data</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                    {
                        sortedItems.length > 0 ?
                            (<tfoot>
                                <tr>
                                    <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                    {
                                        props.report.columns.map((val, index) => {
                                            return <Fragment key={index}><td className="text-center table-sub-total cursor-pointer"
                                                onClick={(e) => {
                                                    var cols = reportColumns.find(item => item.value === val)
                                                    if (cols && cols.percentage !== true) {
                                                        var IDs = [];
                                                        var name = `arr${cols.value}`;
                                                        if (cols.value === 'responseTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrresponseLeads`]]
                                                            })
                                                            name = 'arrresponseLeads'
                                                        }
                                                        else if (cols.value === 'maxresponseTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmaxresponseLeads`]]
                                                            })
                                                            name = 'arrmaxresponseLeads'
                                                        }
                                                        else if (cols.value === 'minresponseTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrminresponseLeads`]]
                                                            })
                                                            name = 'arrminresponseLeads'
                                                        }
                                                        else if (cols.value === 'allocatedTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrallocatedLeads`]]
                                                            })
                                                            name = 'arrallocatedLeads'
                                                        }
                                                        else if (cols.value === 'maxallocatedTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmaxallocatedLeads`]]
                                                            })
                                                            name = 'arrmaxallocatedLeads'
                                                        }
                                                        else if (cols.value === 'minallocatedTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrminallocatedLeads`]]
                                                            })
                                                            name = 'arrminallocatedLeads'
                                                        }
                                                        else if (cols.value === 'contactedTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrcontactedLeads`]]
                                                            })
                                                            name = 'arrcontactedLeads'
                                                        }
                                                        else if (cols.value === 'maxcontactedTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmaxcontactedLeads`]]
                                                            })
                                                            name = 'arrmaxcontactedLeads'
                                                        }
                                                        else if (cols.value === 'mincontactedTime') {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmincontactedLeads`]]
                                                            })
                                                            name = 'arrmincontactedLeads'
                                                        }
                                                        else {
                                                            clientReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arr${cols.value}`]]
                                                            })
                                                        }
                                                        handleShowEnquiryList(val, { [name]: IDs, [`arr${cols.value}`]: IDs })
                                                    }
                                                }}>
                                                {
                                                    (val === 'walkintestdrive' ? (_.sumBy(clientReport, "walkin") > 0 ? Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "testdrive")) : 0) :
                                                        val === 'walkinsold' ? (_.sumBy(clientReport, "walkin") > 0 ? Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "won")) : 0) :
                                                            val === 'appointmenttestdrive' ? (_.sumBy(clientReport, "appointment") > 0 ? Math.round((100 / _.sumBy(clientReport, "appointment")) * _.sumBy(clientReport, "tdappt")) : 0) :
                                                                val === 'testdrivetotestdrive' ? (_.sumBy(clientReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(clientReport, "testdriveActivity")) * _.sumBy(clientReport, "tdtestdrive")) : 0) :
                                                                    val === 'appointmentsold' ? (_.sumBy(clientReport, "appointment") > 0 ? Math.round((100 / _.sumBy(clientReport, "appointment")) * _.sumBy(clientReport, "apptsold")) : 0) :
                                                                        val === 'testdriveConv' ? (_.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "testdrive")) : 0) :
                                                                            val === 'tradeinwonConv' ? (_.sumBy(clientReport, "tradein") > 0 ? Math.round((100 / _.sumBy(clientReport, "tradein")) * _.sumBy(clientReport, "tradeinwon")) : 0) :
                                                                                val === 'wonConv' ? (_.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "won")) : 0) :
                                                                                    val === 'responseTime' ? (_.sumBy(clientReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                        val === 'maxresponseTime' ? (_.sumBy(clientReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                            val === 'minresponseTime' ? (_.sumBy(clientReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                val === 'allocatedTime' ? (_.sumBy(clientReport, `totalallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalallocatedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                    val === 'maxallocatedTime' ? (_.sumBy(clientReport, `totalmaxallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmaxallocatedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                        val === 'minallocatedTime' ? (_.sumBy(clientReport, `totalminallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalminallocatedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                            val === 'contactedTime' ? (_.sumBy(clientReport, `totalcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalcontactedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                val === 'maxcontactedTime' ? (_.sumBy(clientReport, `totalmaxcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmaxcontactedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                    val === 'mincontactedTime' ? (_.sumBy(clientReport, `totalmincontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmincontactedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                        val === 'scheduledConv' ? (_.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "appointmentScheduled")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "appointmentScheduled")) : 0) :
                                                                                                                            val === 'showConv' ? (_.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "show")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "show")) : 0) :
                                                                                                                                val === 'noshowConv' ? (_.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "noshow")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "noshow")) : 0) :
                                                                                                                                    val === 'convertedConv' ? (_.sumBy(clientReport, `totalInbound`) > 0 ? Math.round((100 / _.sumBy(clientReport, `totalInbound`)) * _.sumBy(clientReport, "convertedLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `totalInbound`)) * _.sumBy(clientReport, "convertedLeads")) : 0) :
                                                                                                                                        val === 'wonAmount' || val === 'quoteAmount' ? <NumberFormat
                                                                                                                                            thousandSeparator={true}
                                                                                                                                            prefix={currencySymbol}
                                                                                                                                            displayType={'text'}
                                                                                                                                            value={Math.round((_.sumBy(clientReport, val) + Number.EPSILON) * 100) / 100}
                                                                                                                                        /> : reportColumns.some(item => item.value === val && val.includes('enqstatus-') && item.percentage === true) ?
                                                                                                                                            reportColumns.filter(item => item.value === val && val.includes('enqstatus-') && item.percentage === true).map(col => {
                                                                                                                                                return col.value === val ?
                                                                                                                                                    (isInbound ? _.sumBy(clientReport, `convertedLeads`) : _.sumBy(clientReport, `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(clientReport, `convertedLeads`) : _.sumBy(clientReport, `total`))) * _.sumBy(clientReport, `${val.replace('Conv', '')}`)) : 0
                                                                                                                                                    : 0
                                                                                                                                            }) : _.sumBy(clientReport, val))
                                                }
                                                {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                            </td>
                                                {
                                                    props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length ? props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                        let ptg = dynCol.columns ? _.sumBy(dynCol.columns.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                                        let total = dynCol.columns1 ? _.sumBy(dynCol.columns1.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                                        let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                        return <td key={index} className="text-center table-sub-total cursor-pointer"
                                                            onClick={() => {
                                                                var IDs = [];
                                                                var name = `arr${dynCol.id}`;
                                                                clientReport.forEach(rec => {
                                                                    IDs = [...IDs, ...rec[`arr${dynCol.id}`]]
                                                                })
                                                                handleShowEnquiryList(dynCol.id, { [name]: IDs, [`arr${dynCol.id}`]: IDs })
                                                            }}>
                                                            {
                                                                dynCol.action === 'percentage' ? (
                                                                    <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                                ) : (<>{_.sumBy(clientReport, dynCol.id)}</>)
                                                            }
                                                        </td>
                                                    }) : (<></>)
                                                }
                                            </Fragment>
                                        })
                                    }
                                    {
                                        props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                            let ptg = val.columns ? _.sumBy(val.columns.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                            let total = val.columns1 ? _.sumBy(val.columns1.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                            let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                            return <td key={index} className="text-center table-sub-total cursor-pointer"
                                                onClick={() => {
                                                    var IDs = [];
                                                    var name = `arr${val.id}`;
                                                    clientReport.forEach(rec => {
                                                        IDs = [...IDs, ...rec[`arr${val.id}`]]
                                                    })
                                                    handleShowEnquiryList(val.id, { [name]: IDs, [`arr${val.id}`]: IDs })
                                                }}>
                                                {
                                                    val.action === 'percentage' ? (
                                                        <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                    ) : (<>{_.sumBy(clientReport, val.id)}</>)
                                                }
                                            </td>
                                        }) : (<></>)
                                    }
                                </tr>
                            </tfoot>
                            ) : (<></>)
                    }
                </table>
            </div >
            ) : props.report.reportType === 'column' ?
                (
                    <div className="text-center">
                        <BarChart
                            id={`view-report-bar-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={clientReport}
                            columns={props.report.displayBy === 'client' ? getDynamicCOls(clientReport) : props.report.columns ? _.isArray(props.report.columns) ? props.report.columns : [props.report.columns] : []}
                            dynamicColumns={props.report.displayBy === 'client' ? dymamicCols : reportColumns}
                            xCategory={'displayName'}
                            setChartData={(data) => { setChartData(data) }}
                            calculationType={props.report.calculationType}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />
                    </div>
                ) : props.report.reportType === 'pie' ? (<>
                    {
                        clientReport.some(item => item[props.report.columns] > 0) ? (<PieChart
                            id={`view-report-pie-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={clientReport}
                            dynamicReport={true}
                            columns={_.isArray(props.report.columns) ? props.report.columns[0] : props.report.columns}
                            setChartData={(data) => { setChartData(data) }}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />) : (
                            <NoDataPieChart
                                id={'no-data-pie-chart'}
                                height={`${windowSize.tblHeight}px`}
                            />)
                    }
                </>) : props.report.reportType === 'target' ? (
                    <LineChart
                        id={`view-report-line-chart-${props.report.documentID}`}
                        height={`${windowSize.tblHeight}px`}
                        data={clientReport}
                        dynamicColumns={reportColumns}
                        xCategory={'displayName'}
                        //columns={props.report.target > 0 ? [props.report.columns, 'target'] : [props.report.columns]}
                        columns={props.report.target > 0 ? (_.isArray(props.report.columns) ? [...props.report.columns, 'target'] : [props.report.columns, 'target']) : (_.isArray(props.report.columns) ? props.report.columns : [props.report.columns])}
                        setChartData={(data) => { setChartData(data) }}
                        setChartImg={(img) => { setChartImg(img) }}
                        calculationType={props.report.calculationType}
                        handleShowEnquiryList={(val, rec) => {
                            handleShowEnquiryList(val, rec)
                        }}
                    />
                ) : (<></>)
        }
        <PopUpModal show={showModel}>
            <AddReport
                {...props}
                show={showModel}
                title={'Add Report'}
                handleClose={handleModelClose}
                report={report}
            />
        </PopUpModal>
        <PopUpModal show={dealerModel}>
            <DealerReport
                {...props}
                show={dealerModel}
                title={popHeader}
                handleClose={handleModelClose}
                report={props.report}
                dateRange={dateRange}
                defaultFilters={defaultFilters}
                dealerData={dealerData}
                reportDynColumns={reportColumns}
            />
        </PopUpModal>
        <PopUpModal show={modelShow.sheduleReport}>
            <ScheduleReport
                {...props}
                show={modelShow.sheduleReport}
                handleClose={handleModelClose}
                title={popHeader}
                oemReport={true}
                reportType={'oem'}
                oemID={props.dealersettings.oemID}
                timezone={!_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()}
                {...getFilterDates()}
            ></ScheduleReport>
        </PopUpModal>
        <PopUpModal show={modelShow.enquiry}>
            <EnquiryList
                {...props}
                show={modelShow.enquiry}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
                counts={counts}
            ></EnquiryList>
        </PopUpModal>
        <PopUpModal show={modelShow.activity}>
            <ActivityList
                {...props}
                show={modelShow.activity}
                handleClose={handleModelClose}
                counts={counts}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></ActivityList>
        </PopUpModal>
        <PopUpModal show={modelShow.logs}>
            <LogListModel
                {...props}
                show={modelShow.logs}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></LogListModel>
        </PopUpModal>
        <PopUpModal show={modelShow.tradein}>
            <TradeInList
                {...props}
                show={modelShow.tradein}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></TradeInList>
        </PopUpModal>
        <PopUpModal show={modelShow.testdrive}>
            <TestDriveList
                {...props}
                show={modelShow.testdrive}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></TestDriveList>
        </PopUpModal>
        <PopUpModal show={modelShow.quote}>
            <QuoteList
                {...props}
                show={modelShow.quote}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></QuoteList>
        </PopUpModal>
    </>)
}

export default DynamicReport;