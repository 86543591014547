
/** LIBRARIES */
import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { Progress } from 'antd';
import Swal from 'sweetalert2'
import _ from 'lodash';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
/** COMPONENTS */
import { DropDownMenu, PopUpModal, ReactSelect } from '../../../components';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';

import { objFiles, mandatoryFields } from '../viewModel'
import { firestoreDB, storageBucket } from '../../../services/helper';
import { defaultFileCategories } from '../../../services/enum';
import PandaDocEditor from '../../pandadoc';
import ViewRecipient from '../../pandadoc/viewRecipient';

const saveList = [
    { active: true, value: 'save', label: <><i className="ico icon-files-upload mr-2"></i><Translate text={'save'} /></> },
    { active: true, value: 'requestSignature', label: <><i className="ico icon-sign mr-2"></i><Translate text={'requestSignature'} /></> }
]
const AddFile = props => {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: true,
        accept: `.doc, .csv, .docx, .xls, .xlsx, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*, video/mp4, video/x-m4v, video/*`
    });
    let ref = firestoreDB(props.dealersettings).firestore().collection('enquiries');

    const [isLoading, setIsLoading] = useState(false)
    const [signLoading, setSignLoading] = useState(false)
    const [signModel, setSignModel] = useState({ show: false })
    const [recpModel, setRecpModel] = useState({ show: false })

    const pandadocEnabled = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
        props.dealersettings.client.integrations.filter(e => e.type === "pandadoc")[0] &&
        props.dealersettings.client.integrations.filter(e => e.type === "pandadoc")[0].active === true &&
        props.dealersettings.client.integrations.filter(e => e.type === "pandadoc")[0].enabled === true
    ) && props.contact ? true : false);

    const [selectedFiles, setSelectedFiles] = useState([])
    const [uploadProgress, setUploadProgress] = useState({});
    const [category, setCategory] = useState(props.activeTab ? props.activeTab : 'quotation')
    const [errorURL, setErrorURL] = useState(null)

    let _contract = localStorage.defaultModule === 'service' ? 'serviceContract' :
        localStorage.defaultModule === 'fleetPro' ? 'fleetContract' :
            (localStorage.defaultModule === 'tradeinPro' ? 'tradeinContract' :
                'contract')
    let _fileCategories = _.map(defaultFileCategories, function (e) {
        return {
            ...e,
            label: CommonHelper.showLocale(props, (e.value === 'contract' ? _contract : e.value), e.label),
            name: CommonHelper.showLocale(props, (e.value === 'contract' ? _contract : e.value), e.name),
        }
    })
    if (!_.isEmpty(props.dynamicCategories)) _fileCategories = _.map(Object.assign([], props.dynamicCategories), function (e) {
        return {
            ...e,
            label: e.name ? e.name : e.label,
        }
    })

    useEffect(() => {
        if (_.isEmpty(props.activeTab))
            return

        setCategory(props.activeTab)
    }, [props.activeTab])

    useEffect(() => {
        if (_.isEmpty(acceptedFiles))
            return

        const validateFiles = files => {
            let filteredFiles = Object.assign([], selectedFiles);

            files.forEach(file => {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 200) {
                    Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 200MB.'), '', 'info')
                } else {
                    const fileVM = Object.assign({});
                    fileVM.documentID = ref.doc().id
                    fileVM.name = file.name;
                    fileVM.size = file.size;
                    fileVM.type = file.name.substr((file.name.lastIndexOf('.') + 1));
                    fileVM.fileData = file;
                    filteredFiles.push(fileVM)
                }
            })

            if (filteredFiles.length > 0) {
                setSelectedFiles(filteredFiles)
                setErrorURL('')
            }
        }

        validateFiles(acceptedFiles)
    }, [acceptedFiles])

    //#region CHANGE EVENT
    const handleReactSelectChange = (e, data) => {
        setCategory(e?.value || '')
    }
    //#endregion

    //#region FILE UPLOAD
    const handleFileRemove = (id) => {
        // document.getElementById("fileUpload").value = "";
        const fileVM = Object.assign([], selectedFiles.filter(e => e.documentID !== id));
        setSelectedFiles(fileVM)
    }

    const showFileIcon = (type) => {
        if (type) {
            switch (type.toLowerCase()) {
                case 'pdf':
                    type = <i className="ico icon-files-pdf"></i>;
                    break;
                case 'docx':
                case 'doc':
                    type = <i className="ico icon-files-word"></i>;
                    break;
                case 'xls':
                case 'xlsx':
                    type = <i className="ico icon-files-excel"></i>;
                    break;
                case 'zip':
                case '7z':
                case 'rar':
                    type = <i className="ico icon-files-zip"></i>;
                    break;
                case 'mp3':
                case 'ogg':
                case 'wav':
                    type = <i className="ico icon-files-audio"></i>;
                    break;
                case 'mp4':
                case 'avi':
                case 'wmv':
                case 'mpeg':
                case 'mov':
                    type = <i className="ico icon-files-video"></i>;
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'webp':
                    type = <i className="ico icon-files-image"></i>;
                    break;
                default:
                    type = <i className="ico icon-files-document"></i>;
            }

            return type;
        }
        else {
            return <i className="ico icon-files-document"></i>
        }


    }

    //#endregion


    //#region SAVE & SUBMIT 
    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        let errors = {};
        let formIsValid = true;

        if (_.isEmpty(selectedFiles)) {
            formIsValid = false;
            setErrorURL('dashed_error')
        }
        // let fields = Object.assign({}, state.fields);
        // let errorClass = 'dashed_error';

        // for (let [key] of Object.entries(fields)) {
        //     if (mandatoryFields.indexOf(key) >= 0 && !fields[key]) {
        //         formIsValid = false;
        //         errors[key] = errorClass;
        //     }
        // }

        // setState({ ...state, errors: errors });

        if (formIsValid === true) {
            addFile();
        }
        else {

            return formIsValid;
        }

    };

    const addFile = async (data) => {
        if (selectedFiles && selectedFiles.length > 0) {

            if (data) {
                setSignLoading(true)
            } else {
                setIsLoading(true)
            }

            const objData = Object.assign({}, objFiles);
            let _objLogData = {
                notes: 'File added',
                type: 'files',
            }
            let uid = moment()._d.getTime().toString();
            let clientID = props.dealersettings ? props.dealersettings.client.id : '';
            let enquiryID = props.enquiryID ? props.enquiryID : '';
            let contactID = props.contactID ? props.contactID : '';
            let companyID = props.companyID ? props.companyID : '';
            let serviceID = props.serviceID ? props.serviceID : '';
            let stockID = props.stockID ? props.stockID : '';
            let tradeinProID = props.tradeinProID ? props.tradeinProID : ''
            let fleetProID = props.fleetProID ? props.fleetProID : ''

            if ((enquiryID || serviceID || stockID || tradeinProID || fleetProID || companyID || contactID) && clientID) {

                if (_.isEmpty(objData.addedBy)) {
                    objData.addedBy = props.dealersettings ? props.dealersettings.id : '';
                    objData.addedDate = window.firebase.firestore.Timestamp.now();
                    objData.addedFrom = 'web';
                }
                objData.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.category = (category || 'quotation');
                objData.projectId = props?.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

                if (stockID) {
                    objData.stockID = stockID;
                    objData.category = 'specifications';
                    _objLogData.stockID = stockID
                }

                if (enquiryID) { objData.enquiryID = enquiryID; _objLogData.enquiryID = enquiryID; }
                if (serviceID) { objData.serviceID = serviceID; _objLogData.serviceID = serviceID; }
                if (tradeinProID) { objData.tradeinProID = tradeinProID; _objLogData.tradeinProID = tradeinProID; }
                if (fleetProID) { objData.fleetProID = fleetProID; _objLogData.fleetProID = fleetProID; }
                if (contactID) { objData.contactID = contactID; _objLogData.contactID = contactID }
                if (companyID) { objData.companyID = companyID; _objLogData.companyID = companyID }

                if (props.module) objData.module = props.module;

                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);

                var _fileCollection = serviceID ? `serviceJobs/${serviceID}` :
                    (tradeinProID ? `tradeinPro/${tradeinProID}` :
                        (fleetProID ? `fleetPro/${fleetProID}` :
                            (enquiryID ? `enquiries/${enquiryID}` :
                                (stockID ? `stock/${stockID}` :
                                    (companyID ? `companies/${companyID}` :
                                        `contactDetails/${contactID}`)))));


                const uploadPromises = Array.from(selectedFiles).map((file) => {
                    let storageRef = storageBucket(props.dealersettings).ref(`${clientID}/${_fileCollection}/${uid}/${file.name}`);


                    return new Promise((resolve, reject) => {
                        const uploadTask = storageRef.put(file.fileData);

                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                                if (progress > 0) {
                                    setUploadProgress((prevProgress) => ({
                                        ...prevProgress,
                                        [file.documentID]: progress,
                                    }));
                                }

                            },
                            (error) => {
                                console.error('Error uploading file:', error);
                                reject(error);
                            },
                            async () => {

                                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                                resolve({ ...file, url: downloadURL, fileData: null });
                            }
                        );
                    });
                });

                try {
                    // Await all file uploads to complete
                    const files = await Promise.all(uploadPromises);

                    console.log('All files uploaded:', files);
                    const batch = firestoreDB(props.dealersettings).firestore().batch();
                    const batchPrimary = window.firebase.firestore().batch();
                    files.forEach(_data => {
                        let _fileVM = Object.assign({}, objData);
                        for (let [key, value] of Object.entries(_data)) {
                            _fileVM[key] = value;
                        }
                        _objLogData.notes = `File: ${_fileVM.name} added on ${CommonHelper.getLabelByValue(_fileCategories, objData.category)}`
                        //Remove empty value from object
                        Object.keys(_fileVM).forEach(key => (_fileVM[key] === undefined || _fileVM[key] === null) ? delete _fileVM[key] : {});


                        if (stockID)
                            batchPrimary.set(firestoreDB(null).firestore().collection(`${_fileCollection}/files`).doc(_fileVM.documentID), _fileVM, { merge: true });
                        else
                            batch.set(firestoreDB(props.dealersettings).firestore().collection(`${_fileCollection}/files`).doc(_fileVM.documentID), _fileVM, { merge: true });

                        CommonHelper.saveFileChangeLog(_objLogData, 'created', batchPrimary);

                    })

                    batch.commit()
                        .then(async () => {
                            batchPrimary.commit();
                            if (data) {
                                await saveDocument(data, files[0], _fileCollection)
                            } else {
                                props.handleClose(files[0]?.documentID || null);
                                if (props.setActiveTab)
                                    props.setActiveTab(category)
                            }

                        })
                        .catch((error) => {
                            console.error(error);

                            toast.notify('Something went wrong', {
                                duration: 2000
                            })
                        });


                } catch (error) {
                    console.error('Error in file upload:', error);
                }

            }
        }
    };



    //#endregion

    const baseStyle = {
        width: '100%',
        background: '#f2f7ff',
        border: '2px dashed #a7cbff',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#4466f2',
    };
    const baseBlackStyle = {
        width: '100%',
        background: '#eeeff2',
        border: '2px dashed #808080',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#00000',
    };
    const activeStyle = {
        border: '2px dashed #2bb673',
    };

    const acceptStyle = {
        borderColor: '#2bb673',
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = useMemo(() => ({
        ...(props?.dealersettings?.isPorsche ? baseBlackStyle : baseStyle),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const handlesignrequest = (e) => {
        if (e) e.preventDefault();
        let errors = {};
        let formIsValid = true;

        if (_.isEmpty(selectedFiles)) {
            formIsValid = false;
            setErrorURL('dashed_error')
        }

        // let fields = Object.assign({}, state.fields);
        // let errorClass = 'dashed_error';

        // for (let [key] of Object.entries(fields)) {
        //     if (mandatoryFields.indexOf(key) >= 0 && !fields[key]) {
        //         formIsValid = false;
        //         errors[key] = errorClass;
        //     }
        // }

        // setState({ ...state, errors: errors });

        if (formIsValid === true) {
            setRecpModel({
                show: true,
                title: 'Review recipients',
                data: {
                    recipients: props?.contact ? [{
                        ..._.pick(props.contact, ['firstName', 'lastName', 'email']),
                        name: `${props.contact.firstName || ''} ${props.contact.lastName || ''}`,
                        imageURL: props.contact.userImageURL
                    }] : []
                }
            })
        }
        else {
            return formIsValid;
        }
    }

    const saveDocument = async (data, objData, _fileCollection) => {
        const apikey = props?.dealersettings?.client?.integrations?.filter(e => e.type === "pandadoc")?.[0]?.settings?.api_key
        const payload = {
            "name": data.subject,
            "url": objData.url,
            "parse_form_fields": false,
            "metadata": {
                clientID: props.dealersettings?.client?.id,
                collection: _fileCollection,
                fileID: objData.documentID || '',
                fileName: objData.name,
                fileSize: objData.size,
                projectId: _fileCollection?.includes('stock/') ? null : props.dealersettings?.client?.projectId || null,
                ..._.pick(data, ['subject', 'message', 'signingOrder'])
            }
        }
        const _emails = [];
        if (data?.recipients) {
            data.recipients.map((rec, index) => {
                return _emails.push({
                    first_name: rec.firstName || rec?.name?.split(' ')[0] || rec.name,
                    last_name: rec.lastName || rec?.name?.split(' ')[1] || '',
                    email: rec.email,
                    signing_order: data?.signingOrder ? index + 1 : 1
                })
            })
        }
        payload.recipients = _emails;
        if (!payload.metadata.projectId) {
            delete payload.metadata.projectId;
        }
        const createDocument = window.firebase.functions().httpsCallable('pandadoc-createDocument');
        createDocument({
            "api_key": apikey,
            "payload": payload
        }).then(async (resp) => {
            console.log(resp)
            if (resp.data.success === true) {
                const __settings = props?.dealersettings?.client?.integrations?.filter(e => e.type === "pandadoc")?.[0]?.settings || {};
                setSignLoading(false);
                setSignModel({
                    show: true,
                    title: 'Edit Document',
                    document: resp.data.data,
                    iframeID: moment().format('X'),
                    payload: data,
                    apikey,
                    ...__settings
                })
                // setTimeout(() => {
                //     props.handleClose();
                //     if (props.setActiveTab)
                //         props.setActiveTab(state.category)
                // }, 1000);


            }
            else {
                Swal.fire(resp.data.message || 'Something went wrong', '', 'error')
            }
        });
    }

    const divStyle = {
        maxHeight: '440px',
        overflow: 'auto'
    };

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-file-manager custom-cal-pop"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={'Upload Files'} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            {
                                props.stockID || props.fleetProID
                                    ?
                                    <></>
                                    :
                                    <>
                                        <div className="form-style w-100">
                                            <div className="form-row ">
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'Choose Folder'} /></label>
                                                    <ReactSelect
                                                        options={_fileCategories}
                                                        name={"category"}
                                                        placeholder={'select category'}
                                                        onChange={handleReactSelectChange}
                                                        value={category}
                                                        classNamePrefix={`basic-select`}
                                                        removeClearable={true}
                                                        maxMenuHeight={200}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }


                            <div className="upload-file-box">
                                <div {...getRootProps({ style, className: `upload-iconwrap upload-box-dragndrop ${errorURL || ''}` })}>
                                    <input {...getInputProps()} />
                                    <i className="ico icon-files-upload"></i>
                                    <p><Translate text={"Drag 'n' drop some files here, or click to upload files"} /></p>
                                </div>



                            </div>

                            {
                                !_.isEmpty(selectedFiles)
                                    ?
                                    <>
                                        <div className="upload-file-box " style={divStyle} >
                                            <div className='mr-2'>
                                                {
                                                    selectedFiles.map((fields, index) => {
                                                        return <div key={index} className="uploaded-list-video">
                                                            <div className="flex-upload">
                                                                <div className="upload-clear">
                                                                    <a href="#" onClick={(e) => handleFileRemove(fields.documentID)} className={`${uploadProgress[fields.documentID] ? 'form-disable' : ''}`}>
                                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                                    </a>
                                                                </div>
                                                                <div className="file-upload-icon">
                                                                    {
                                                                        showFileIcon(fields.type)
                                                                    }
                                                                </div>
                                                                <div className="upload-data">
                                                                    {fields.name}
                                                                    {' (' + CommonHelper.showFileSize(fields.size) + ')'}
                                                                </div>
                                                            </div>
                                                            {uploadProgress[fields.documentID] ? <Progress percent={Math.round(uploadProgress[fields.documentID])} /> : <></>}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }



                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${isLoading ? 'form-disable' : ''}`}>
                    <button
                        type="button"
                        className="btn btn-default float-left"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose();
                        }}
                    ><Translate text={'cancel'} />
                    </button>


                    {
                        selectedFiles && selectedFiles.length === 1 && CommonHelper.isValidSignatureFile(selectedFiles[0]?.type) === true && pandadocEnabled
                            ?
                            <div className={`dropdown multi-action-dropbutton float-right  ${(isLoading || signLoading) ? 'form-disable' : ''}`}>
                                <DropDownMenu
                                    text={(isLoading || signLoading) ? <><span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> <Translate text={isLoading ? 'save' : (signLoading ? 'requestSignature' : 'done')} /> </> : <>{'Done'}</>}
                                    keyVal="17834df1"
                                    tIndex="99999"
                                    menu={saveList}
                                    icon={false}
                                    className={'btn btn-primary dropdown-toggle'}
                                    handleChange={(status) => {
                                        if (status === 'save') {
                                            handleSubmit();
                                        }
                                        else if (status === 'requestSignature') {
                                            handlesignrequest();
                                        }
                                    }}
                                >
                                </DropDownMenu>
                            </div>
                            :
                            <>
                                <button
                                    type="button"
                                    className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                    <Translate text={'done'} />
                                </button>
                            </>

                    }
                    {/* {
                        pandadocEnabled ? (
                            <button
                                type="button"
                                className={`btn btn-primary float-right mr-2 ${signLoading ? 'form-disable' : ''}`}
                                onClick={(e) => handlesignrequest(e)}
                            >
                                {signLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'requestSignature'} />
                            </button>
                        ) : (<></>)
                    } */}
                </Modal.Footer>
            </Modal>

            <PopUpModal show={signModel.show}>
                <PandaDocEditor
                    {...props}
                    {...signModel}
                    handleClose={() => {
                        setSignModel({ show: false });
                        props.handleClose();
                    }}
                />
            </PopUpModal>
            <PopUpModal show={recpModel.show}>
                <ViewRecipient
                    {...props}
                    {...recpModel}
                    handleClose={(val) => {
                        if (val) {
                            addFile(val)
                        }
                        setRecpModel({ show: false })
                    }}
                />
            </PopUpModal>



        </>


    )
}

export default AddFile