import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { ContentOverlay, BarChart, PieChart, LineChart, NoDataPieChart } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import { reportGroupBy, reportDynColumns } from '../../reports/viewModel'
import Translate from '../../../constants/translate';
import NumberFormat from 'react-number-format';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


const ViewDynamicReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [progressReport, setProgressReport] = useState([])
    const [tempData, setTempData] = useState([])
    const [reportData, setReportData] = useState({
        enquiries: [],
        walkins: [],
        testdrives: [],
        doneActivities: [],
        scheduleActivities: [],
        quotations: []
    })
    const [dynReport, setDynReport] = useState()
    const [dealersettings, setDealersettings] = useState()
    const [clientUsers, setClientUsers] = useState()
    const [propsSettings, setPropsSettings] = useState()
    const [isInbound, setisInbound] = useState()
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [inclInboundLeadsinRpt, setInclInboundLeadsinRpt] = useState(false);
    const [currencySymbol, setcurrencySymbol] = useState('');
    const [calcCurrentStatus, setCalcCurrentStatus] = useState('false');
    const [multiTDperLead, setMultiTDperLead] = useState('false');
    const [mvwReport, setmvwReport] = useState(false);
    const [creditScore, setcreditScore] = useState();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    let _startdate = params.get('startDate');
    let _enddate = params.get('endDate');

    useEffect(() => {
        window.firebase.firestore().doc(`reports/${props.reportid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    const schedularDoc = await window.firebase.firestore().doc(`schedular/${props.reportid}`).get();
                    if (!_.isEmpty(schedularDoc.data()?.reportJSON)) {
                        setDynReport({
                            ...schedularDoc.data().reportJSON,
                            'documentID': schedularDoc.id,
                            'upcomingDays': schedularDoc?.data()?.upcomingDays ? schedularDoc?.data().upcomingDays : [],
                            'dataParam': schedularDoc?.data()?.dataParam ? schedularDoc?.data().dataParam : ''
                        })
                        setisInbound(schedularDoc.data()?.reportJSON?.pipeline && schedularDoc.data()?.reportJSON?.pipeline.some(b => b === 'LeadsBucket') && schedularDoc.data()?.reportJSON?.length === 1 ? true : false)
                    }
                    else {
                        setDynReport({
                            ...doc.data(),
                            'documentID': doc.id,
                            'pipeline': _.isArray(doc.data().pipeline) ? doc.data().pipeline : doc.data().pipeline !== 'all' ? [doc.data().pipeline] : [],
                            'columns': _.isArray(doc.data().columns) ? doc.data().columns : [doc.data().columns],
                            'upcomingDays': schedularDoc?.data()?.upcomingDays ? schedularDoc?.data().upcomingDays : [],
                            'dataParam': schedularDoc?.data()?.dataParam ? schedularDoc?.data().dataParam : ''
                        })
                        setisInbound(doc.data().pipeline && doc.data().pipeline.some(b => b === 'LeadsBucket') && doc.data().pipeline.length === 1 ? true : false)
                    }

                } else {
                    const schedularDoc = await window.firebase.firestore().doc(`schedular/${props.reportid}`).get();
                    if (!_.isEmpty(schedularDoc.data()?.reportJSON)) {
                        setDynReport({
                            ...schedularDoc.data().reportJSON,
                            'documentID': schedularDoc.id,
                            'upcomingDays': schedularDoc?.data()?.upcomingDays ? schedularDoc?.data().upcomingDays : [],
                            'dataParam': schedularDoc?.data()?.dataParam ? schedularDoc?.data().dataParam : ''
                        })
                        setisInbound(schedularDoc.data()?.reportJSON?.pipeline && schedularDoc.data()?.reportJSON?.pipeline.some(b => b === 'LeadsBucket') && schedularDoc.data()?.reportJSON?.length === 1 ? true : false)
                    }
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(dynReport) || _.isEmpty(dynReport.clientID))
            return


        window.firebase.firestore().doc(`clients/${dynReport.clientID}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setInclInboundLeadsinRpt((!_.isEmpty(doc.data().clientSettings) &&
                        doc.data().clientSettings.inclInboundLeadsinRpt) ? true : false);
                    setcurrencySymbol(!_.isEmpty(doc.data().currency) ? doc.data().currency.symbol + ' ' : '$ ');
                    if (!_.isEmpty(doc.data().clientSettings) && doc.data().clientSettings.calcCurrentStatus) {
                        setCalcCurrentStatus('true');
                    }
                    if (!_.isEmpty(doc.data().clientSettings) && doc.data().clientSettings.multiTDperLead) {
                        setMultiTDperLead('true');
                    }
                    if (doc?.data()?.clientSettings?.creditScore?.approved) {
                        setcreditScore(doc?.data()?.clientSettings?.creditScore?.approved);
                    }
                }
                window.firebase.firestore().doc(`clients/${dynReport.clientID}/currentSettings/${dynReport.clientID}`)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            let timezone = !_.isEmpty(doc.data().timezone) ? doc.data().timezone : moment.tz.guess();
                            const _currentDate = moment().tz(timezone).format('YYYY-MM-DD HH:mm');
                            if (_startdate && _enddate) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_startdate).tz(timezone).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_enddate).tz(timezone).endOf('day').toDate())
                                });
                            }
                            else if (_date === 'today') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                                });
                            }
                            else if (_date === 'lwd') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                                });
                            }
                            else if (_date === 'wtd') {
                                if (!_.isEmpty(doc.data().weekStart)) {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: moment().day(doc.data().weekStart).day(),
                                        },
                                    })
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate()),
                                    });
                                }
                                else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                                    });
                                }
                            }
                            else if (_date === 'pwd') {
                                if (!_.isEmpty(doc.data().weekStart)) {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: moment().day(doc.data().weekStart).day(),
                                        },
                                    })
                                }
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('week', -1).startOf('week').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('week', -1).endOf('week').toDate()),
                                });
                            }
                            else if (_date === 'lmd') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('month', -1).startOf('month').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('month', -1).endOf('month').toDate()),
                                });
                            }
                            else if (dynReport.dataParam === 'upcoming' && !_.isEmpty(dynReport.upcomingDays)) {
                                if (!_.isEmpty(doc.data().weekStart)) {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: moment().day(doc.data().weekStart).day(),
                                        },
                                    })
                                }
                                if (moment().startOf('week').day() >= 1 && dynReport.upcomingDays.includes('0')) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('week').toDate())
                                    })
                                }
                                else if (moment().startOf('week').day() === 0 && dynReport.upcomingDays.includes('0')) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                                    })
                                }
                                else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays)).toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays)).toDate()),
                                    })
                                }

                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('month').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('month').toDate())
                                });
                            }
                            setPropsSettings({
                                dealersettings: {
                                    ...doc.data(),
                                    client: {
                                        id: doc.id,
                                        settings: doc.data().settings,
                                        integrations: doc.data().integrations,
                                        workingHours: doc.data().workingHours,
                                    }
                                },
                                pipeline: doc.data().settings.allPipelines,
                                clientUsers: doc.data().users,

                            })
                            setmvwReport(doc?.data()?.mvwReport)
                            setDealersettings({
                                ...doc.data(),
                                'clientID': doc.id
                            })
                            setClientUsers(doc.data().users.filter(a => !Boolean(a.isDisabled)))
                        }
                    })
            })

    }, [dynReport])

    useEffect(() => {
        if (_.isEmpty(propsSettings))
            return
        let _leadSource = []
        const enqStatus = propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.enquiryStatus ?
            propsSettings.dealersettings.client.settings.enquiryStatus : [];
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    const _sold = _.find(enqStatus, { value: 'won' })?.name || 'Won';
                    _leadSource.push({
                        label: rec.name,
                        value: `enqtype-${rec.value}`,
                        name: `${rec.name} (Enquiry Type)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                    _leadSource.push({
                        label: `${rec.name} ${_sold}`,
                        value: `enqtypesold-${rec.value}`,
                        name: `${rec.name} ${_sold} (Enquiry Type)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    const _sold = _.find(enqStatus, { value: 'won' })?.name || 'Won';
                    _leadSource.push({
                        label: rec.name,
                        value: `origin-${rec.value}`,
                        name: `${rec.name} (Origin)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                    _leadSource.push({
                        label: `${rec.name} ${_sold}`,
                        value: `originsold-${rec.value}`,
                        name: `${rec.name} ${_sold} (Origin)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.leadSource &&
            propsSettings.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `leadSource-${rec.value}`,
                        name: `${rec.name} (Lead Source)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lead Source',
                    })
                })
        if (dynReport.pipeline
            && dynReport.pipeline.length === 1
            && propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.allPipelines
            && propsSettings.dealersettings.client.settings.allPipelines.find(a => a.value === dynReport.pipeline[0])) {
            propsSettings.dealersettings.client.settings.allPipelines.find(a => a.value === dynReport.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    _leadSource.push({
                        label: `${rec.name} (Stage)`,
                        searchlabel: `${rec.name} Stage`,
                        value: `stage-${rec.value}`,
                        name: `${rec.name} (Stage)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Stage',
                    })
                }
            })
        }
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.activityOutcome &&
            propsSettings.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `outcome-${rec.value}`,
                        name: `${rec.name} (Activity Outcome)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Activity Outcome',
                        type: 'activity'
                    })
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.callStatus &&
            propsSettings.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `callstatus-${rec.value}`,
                        name: `${rec.name} (Call Status)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Call Status',
                        type: 'log'
                    })
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.enquiryStatus &&
            propsSettings.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `enqstatus-${rec.value}`,
                        name: `${rec.name} (Enquiry Status)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                    _leadSource.push({
                        label: `${rec.name} Conv.`,
                        value: `enqstatus-${rec.value}Conv`,
                        name: `${rec.name} Conv.`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['percentage'],
                        percentage: true,
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings?.financeStatus &&
            propsSettings.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `financestatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Finance Status'
                    })
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings?.enquiryOptionsDF &&
            propsSettings.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        _leadSource.push({
                            label: rec,
                            value: `${item.type};${item.value};${rec}`,
                            name: item.name,
                            view: ['table', 'column', 'pie', 'target'],
                            calculationType: ['count'],
                            color: '#4967CC',
                            sourceType: item.name
                        })
                    })
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.lostReasons &&
            propsSettings.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    const _sold = _.find(enqStatus, { value: 'won' })?.name || 'Won';
                    _leadSource.push({
                        label: rec.name,
                        value: `lostReason-${rec.value}`,
                        name: `${rec.name} (Lost Reasons)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lost Reasons',
                    })
                })

        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            _leadSource.push({
                searchlabel: rec,
                value: rec,
                name: rec === '15' ? '15+' : rec,
                label: rec === '15' ? '15+' : rec,
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#4967CC',
                sourceType: 'Persistency'
            })
            if (rec === '4' || rec === '15') {
                _leadSource.push({
                    searchlabel: 'Total',
                    value: `${rec}plus`,
                    name: rec === '4' ? (<>0-4 <br /> Total</>) : (<>5-15+ <br /> Total</>),
                    label: rec === '4' ? (<>0-4 <br /> Total</>) : (<>5-15+ <br /> Total</>),
                    view: ['table', 'column', 'pie', 'target'],
                    calculationType: ['count'],
                    color: '#4967CC',
                    sourceType: 'Persistency',
                    cls: 'table-grey-sub-total'
                })
            }
        })
        if (propsSettings?.dealersettings?.isPorsche === true) {
            _leadSource.push({
                label: 'Leads Created in C@P',
                searchlabel: 'Leads Created in C@P',
                value: 'newCAP',
                name: 'Leads Created in C@P',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Lost From Enquiries',
                searchlabel: 'Lost From Enquiries',
                value: 'lostEnq',
                name: 'Lost From Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Assigned Enquiries',
                searchlabel: 'Assigned Enquiries',
                value: 'allocated',
                name: 'Assigned Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Outdated',
                searchlabel: 'Outdated',
                value: 'outdated',
                name: 'Outdated',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
        }
        setReportColumns([...reportColumns.map(rec => {
            return rec.status === true && _.find(enqStatus, { value: rec.value }) ? {
                ...rec,
                name: rec.value === 'won' && dynReport.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${propsSettings?.dealersettings?.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: rec.value === 'won' && dynReport.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${propsSettings?.dealersettings?.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                cls: rec.value === 'won' && dynReport.columnType === 'persistency' ? 'table-sub-total' : null
            } : (rec.value === 'preApproved' || rec.value === 'notpreApproved') && creditScore ?
                {
                    ...rec,
                    name: `${rec.name} ${rec.value === 'preApproved' ? `( >= ${creditScore})` : `( < ${creditScore})`}`,
                    label: `${rec.label} ${rec.value === 'preApproved' ? `( >= ${creditScore})` : `( < ${creditScore})`}`,
                }
                : { ...rec }
        }), ..._leadSource]);
    }, [propsSettings])

    useEffect(() => {
        if (_.isEmpty(dealersettings))
            return
        setLoader(true)
        let _filters = {};
        if (!_.isEmpty(dynReport.objFilters) && dynReport.objFilters.length > 0) {
            dynReport.objFilters.forEach(rec => {
                _filters = rec.name === 'date' ? {
                    ..._filters,
                    startDate: moment.unix(rec.value.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(rec.value.endDate.seconds).format('YYYY-MM-DD')
                } : rec?.name?.includes('select-') || rec?.name?.includes('multiselect-') ? {
                    ..._filters,
                    dynamicFilter: _filters?.dynamicFilter ? [
                        ..._filters?.dynamicFilter, {
                            name: rec.name,
                            value: rec?.value?.[rec.name] || null
                        }
                    ] : [{
                        name: rec.name,
                        value: rec?.value?.[rec.name] || null
                    }]
                } : {
                    ..._filters,
                    ...rec.value,
                    ...(rec.subList ? rec.subList : {})
                }
            })
        }
        else {
            _filters = {
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            }
        }
        if (_filters.dynamicFilter) {
            _filters = {
                ..._filters,
                dynamicFilter: JSON.stringify(_filters.dynamicFilter)
            }
        }
        if (propsSettings?.dealersettings?.isPorsche === true) {
            _filters.isPorsche = true;
        }
        if (creditScore) {
            _filters.creditScore = creditScore;
        }
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: mvwReport ? "getDynamicReportV2" : "getDynamicReport",
                params: JSON.stringify({
                    clientID: dealersettings.clientID,
                    reportJSON: JSON.stringify(dynReport),
                    ..._filters,
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    calcCurrentStatus,
                    multiTDperLead
                    // ...objFilter
                })
            }).then((response) => {
                setLoader(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
            });
        }
        catch (error) {
            console.log(error)
            setLoader(false)
            // Swal.fire('Something went wrong', '', 'error')
        }

    }, [dealersettings])

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        const _score = _.sumBy(progressReport, '4plus') > 0 ? Math.round((_.sumBy(progressReport, '15plus') / _.sumBy(progressReport, '4plus')) * 100) : 0;
        var chart = am4core.create(`progressive-${dynReport.documentID}`, am4charts.PieChart);
        chart.logo.disabled = true;
        chart.data = [{
            "category": "",
            "value": _score,
            "color": "#4466f2"

        }, {
            "category": "",
            "value": 100 - _score,
            "color": '#d3d9e6'
        }];

        chart.innerRadius = am4core.percent(90)
        //chart.radius = am4core.percent(90)

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.tooltip.disabled = true;
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        var label = pieSeries.createChild(am4core.Label)
        label.text = `${_score}%`;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = "15px";
        //chart.validateNow();

        pieSeries.slices.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.dataContext.color)
                return am4core.color(target.dataItem.dataContext.color);
            return color
        });

        window.persistencyChart = chart;
    }, [progressReport])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][dealersettings.clientID] : []
        let reports = [];
        _report.forEach(rec => {
            if (Object.keys(rec).filter(r => Boolean(dynReport.includeZero) ? true : _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                reports.push({
                    ...rec,
                    arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                    profileImage: dynReport.groupBy === 'user' && _.find(clientUsers, { id: rec.id }) ? _.find(clientUsers, { id: rec.id }).profileImage : '',
                    newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                    qualifiedLeads: rec.arrqualifiedLeads ? rec.arrqualifiedLeads.length : 0,
                    exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                    pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                    totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                    overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                    convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                    show: rec.arrshow ? rec.arrshow.length : 0,
                    showCount: rec.arrshow ? rec.arrshow.length : 0,
                    noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                    //avg response time
                    arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                    totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                    responseTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    maxresponseTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    minresponseTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),

                    //avg allocated time
                    arrallocatedLeads: rec.arrallocatedTime ? _.uniq(rec.arrallocatedTime.map(r => { return r.documentID })) : [],
                    totalallocatedTime: _.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0,
                    allocatedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0, propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                    arrmaxallocatedLeads: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? [_.maxBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                    totalmaxallocatedTime: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                    maxallocatedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //min allocated time
                    arrminallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                    arrminallocatedLeads: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? [_.minBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                    totalminallocatedTime: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                    minallocatedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),

                    //avg contacted time
                    arrcontactedLeads: rec.arrcontactedTime ? _.uniq(rec.arrcontactedTime.map(r => { return r.documentID })) : [],
                    totalcontactedTime: _.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0,
                    contactedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0, propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxcontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                    arrmaxcontactedLeads: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? [_.maxBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                    totalmaxcontactedTime: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                    maxcontactedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                    //min contacted time
                    arrmincontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                    arrmincontactedLeads: _.minBy(rec.arrcontactedTime, 'contactedTime') ? [_.minBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                    totalmincontactedTime: _.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                    mincontactedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), propsSettings.dealersettings.client, (dynReport.reportType === 'table' ? '' : 'seconds')),


                    open: rec.arropen ? rec.arropen.length : 0,
                    statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                    //won: rec.arrwon ? rec.arrwon.length : 0,
                    arrwon: rec.arrwon ? _.uniq(rec.arrwon.map(r => { return r.documentID })) : [],
                    won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,

                    arrwonTD: rec.arrwonTD ? _.uniq(rec.arrwonTD.map(r => { return r.documentID })) : [],
                    wonTD: rec.arrwonTD ? _.sumBy(rec.arrwonTD, "soldCount") : 0,

                    arrdelivered: rec.arrdelivered ? _.uniq(rec.arrdelivered.map(r => { return r.documentID })) : [],
                    delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                    lost: rec.arrlost ? rec.arrlost.length : 0,
                    prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                    leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                    totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                    //delivered: rec.arrdelivered ? rec.arrdelivered.length : 0,
                    walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                    newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                    repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                    testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                    tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                    tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                    tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                    tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                    tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                    inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                    outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                    totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                    messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                    emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                    noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                    appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                    testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                    callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                    mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                    deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                    appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                    testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                    callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                    mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                    deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                    total: rec.arrtotal ? rec.arrtotal.length : 0,
                    tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                    tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                    apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                    target: dynReport.target ? dynReport.target : 0,
                    totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                    totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                    totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                    doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                    noActivity: rec.arrnoActivity ? rec.arrnoActivity.length : 0,
                    unactionedLeads: rec.arrunactionedLeads ? rec.arrunactionedLeads.length : 0,
                    noNotes: rec.arrnoNotes ? rec.arrnoNotes.length : 0,
                    totalStock: rec.arrtotalStock ? rec.arrtotalStock.length : 0,
                    stockwithEnq: rec.arrstockwithEnq ? rec.arrstockwithEnq.length : 0,
                    stockwithoutEnq: rec.arrstockwithoutEnq ? rec.arrstockwithoutEnq.length : 0,
                    noCalls: rec.arrnoCalls ? rec.arrnoCalls.length : 0,
                    noMessage: rec.arrnoMessage ? rec.arrnoMessage.length : 0,
                    allocated: rec.arrallocated ? rec.arrallocated.length : 0,
                    newCAP: rec.arrnewCAP ? rec.arrnewCAP.length : 0,
                    lostEnq: rec.arrlostEnq ? rec.arrlostEnq.length : 0,
                    outdated: rec.arroutdated ? rec.arroutdated.length : 0,
                    overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                    quotes: rec.arrquotes ? rec.arrquotes.length : 0,
                    arrquotes: rec.arrquotes ? _.uniq(rec.arrquotes.map(r => { return r.documentID })) : [],
                    wonAmount: rec.arrwonAmount ? Math.round((_.sumBy(rec.arrwonAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                    quoteAmount: rec.arrquoteAmount ? Math.round((_.sumBy(rec.arrquoteAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                    creditScore: rec.arrcreditScore ? rec.arrcreditScore.length : 0,
                    preApproved: rec.arrpreApproved ? rec.arrpreApproved.length : 0,
                    notpreApproved: rec.arrnotpreApproved ? rec.arrnotpreApproved.length : 0,
                    formatquoteAmount: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrquoteAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100}
                    />,
                    formatwonAmount: <NumberFormat
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        displayType={'text'}
                        value={Math.round((_.sumBy(rec.arrwonAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100}
                    />,
                    ...getDynamicTotalLength(rec)
                })
            }
        });

        reports = reports.map(rec => {
            return {
                ...rec,
                carryover: rec.arrcarryover.length,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
                showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
                noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })
        dynReport.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setProgressReport(reports)
    }

    const getDynamicTotalLength = (data) => {
        let leadSourceData = {};
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: data[`arrenqtype-${rec.value}`] ? data[`arrenqtype-${rec.value}`].length : 0,
                        [`enqtypesold-${rec.value}`]: data[`arrenqtypesold-${rec.value}`] ? data[`arrenqtypesold-${rec.value}`].length : 0
                    }
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: data[`arrorigin-${rec.value}`] ? data[`arrorigin-${rec.value}`].length : 0,
                        [`originsold-${rec.value}`]: data[`arroriginsold-${rec.value}`] ? data[`arroriginsold-${rec.value}`].length : 0
                    }
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.leadSource &&
            propsSettings.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: data[`arrleadSource-${rec.value}`] ? data[`arrleadSource-${rec.value}`].length : 0
                    }
                })
        if (dynReport.pipeline
            && dynReport.pipeline.length === 1
            && propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.pipelines
            && propsSettings.dealersettings.client.settings.pipelines.find(a => a.value === dynReport.pipeline[0])) {
            let _leadSource = []
            let _sourceFields = []
            propsSettings.dealersettings.client.settings.pipelines.find(a => a.value === dynReport.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: data[`arrstage-${rec.value}`] ? data[`arrstage-${rec.value}`].length : 0
                    }
                }
            })
        }
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: data[`arrlostReason-${rec.value}`] ? data[`arrlostReason-${rec.value}`].length : 0
                    }
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.activityOutcome &&
            propsSettings.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: data[`arroutcome-${rec.value}`] ? data[`arroutcome-${rec.value}`].length : 0
                    }
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: data[`arrcallstatus-${rec.value}`] ? data[`arrcallstatus-${rec.value}`].length : 0
                    }
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = data[`arrenqstatus-${rec.value}`] ? _.sumBy(data[`arrenqstatus-${rec.value}`], "soldCount") : 0;
                    let _totalCount = data[`arrtotal`] ? data[`arrtotal`].length : 0;
                    let _convertedCount = data[`arrconvertedLeads`] ? data[`arrconvertedLeads`].length : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrenqstatus-${rec.value}`]: data[`arrenqstatus-${rec.value}`] ? _.uniq(data[`arrenqstatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
                    }
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings?.financeStatus &&
            propsSettings.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = data[`arrfinancestatus-${rec.value}`] ? _.sumBy(data[`arrfinancestatus-${rec.value}`], "soldCount") : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrfinancestatus-${rec.value}`]: data[`arrfinancestatus-${rec.value}`] ? _.uniq(data[`arrfinancestatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`financestatus-${rec.value}`]: _stsCount,
                    }
                })
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings?.enquiryOptionsDF &&
            propsSettings.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: data[`arr${item.type};${item.value};${rec}`] ? data[`arr${item.type};${item.value};${rec}`].length : 0
                        }
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = data[`arr${rec}`] ? _.sumBy(data[`arr${rec}`], "soldCount") : 0;
            leadSourceData = {
                ...leadSourceData,
                [`arr${rec}`]: data[`arr${rec}`] ? _.uniq(data[`arr${rec}`].map(r => { return r.documentID })) : [],
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = data[`arr${rec}plus`] ? _.sumBy(data[`arr${rec}plus`], "soldCount") : 0;
                leadSourceData = {
                    ...leadSourceData,
                    [`${rec}plus`]: __stsCount,
                }
            }
        })
        if (dynReport?.dynColumns?.length > 0) {
            dynReport.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let total = _.sumBy(rec.columns1.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }))
                    }
                }
            })
        }
        return leadSourceData;
    }


    return (_.isEmpty(dealersettings) || loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        dealersettings && dealersettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={dealersettings.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{dealersettings.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> {dynReport.name} <br></br>
                                    {
                                        _date === 'wtd' || _date === 'lwd' || _date === 'pwd' || _date === 'lmd' || _startdate || _enddate || dynReport.dataParam === 'upcoming' ? (
                                            <span> {`${moment.unix(dateRange.startDate.seconds).format('DD MMM')}-${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`}</span>
                                        ) : !_.isEmpty(dealersettings.timezone) ? (
                                            <span> {moment().tz(dealersettings.timezone).format('DD MMMM YYYY')}</span>
                                        ) : (
                                            <span> {moment().format('DD MMMM YYYY')}</span>
                                        )
                                    }
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : progressReport && progressReport.length === 0 ? (
                        <div className="table-wraper">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>
                    ) : dynReport.reportType === 'table' ? (<div className="table-wraper">
                        {
                            dynReport.columnType === 'persistency' ? (

                                <div className="table-progressive-wrapper mb-2">
                                    <div className="table-progressive-graph">
                                        <div className="table-progressive-graph-image">
                                            <div id={`progressive-${dynReport.documentID}`} style={{ width: "60px", height: '55px', marginLeft: '5px', marginTop: '2px' }}></div>
                                        </div>
                                        <div className="table-progressive-graph-title">Progressive Sales <br /> ({_.sumBy(progressReport, '15plus')}/{_.sumBy(progressReport, '4plus')}) x 100</div>

                                    </div>
                                    <div className="table-progressive-table">

                                        <div className="common-table">
                                            <table className="table table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="head-light" width="25%">Sales at first contact</th>
                                                        <th scope="col" className="head-light" width="25%">Sales within 4 contacts</th>
                                                        <th scope="col" className="head-light" width="25%">Sales after 5 contacts</th>
                                                        <th scope="col" className="head-light table-sub-total" width="25%">Total</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{_.sumBy(progressReport, '0')}</td>
                                                        <td>{_.sumBy(progressReport, '4plus')}</td>
                                                        <td>{_.sumBy(progressReport, '15plus')}</td>
                                                        <td className='table-sub-total'>{_.sumBy(progressReport, 'won')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        <table className="table-loop">
                            <thead>
                                <tr>
                                    <th className="cell-head" width="10%">
                                        {CommonHelper.getCustomValue(reportGroupBy, dynReport.groupBy, 'tableHeader', 'value')}
                                    </th>
                                    {
                                        dynReport.columns.map((rec, index) => {
                                            return <Fragment key={index}><th className="head-light text-center" width={`${(90 / (dynReport.columns.length + (dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                {
                                                    reportColumns.find(item => item.value === rec) ?
                                                        (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                        (<span className="pipeline-inactive-span">(In Active)</span>)
                                                }
                                                {
                                                    rec.includes('enqtype-') || rec.includes('enqtypesold-') ? (
                                                        <span className="th-subhead">(Enquiry Type)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('origin-') || rec.includes('originsold-') ? (
                                                        <span className="th-subhead">(Origin)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('leadSource-') ? (
                                                        <span className="th-subhead">(Lead Source)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('outcome-') ? (
                                                        <span className="th-subhead">(Activity Outcome)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('callstatus-') ? (
                                                        <span className="th-subhead">(Call Status)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('enqstatus-') ? (
                                                        <span className="th-subhead">(Enquiry Status)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('financestatus-') ? (
                                                        <span className="th-subhead">(Finance Status)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('select;') ? (
                                                        <span className="th-subhead">({reportColumns.find(item => item.value === rec)?.name || 'Dynamic Field'})</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('lostReason-') ? (
                                                        <span className="th-subhead">(Lost Reason)</span>
                                                    ) : (<></>)
                                                }
                                            </th>
                                                {
                                                    dynReport?.dynColumns?.filter(a => a.moveAfter === rec)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === rec).map((dynCol) => {
                                                        return <th key={dynCol.id} className={`head-light text-center`} width={`${(90 / (dynReport.columns.length + (dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                            <div className="sort-cell cursor-pointer" >
                                                                {dynCol.name}
                                                            </div>
                                                        </th>
                                                    }) : (<></>)
                                                }
                                            </Fragment>
                                        })
                                    }
                                    {
                                        dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((rec, index) => {
                                            return <th key={index} className={`head-light text-center`} width={`${(90 / (dynReport.columns.length + (dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                <div className="sort-cell cursor-pointer" >
                                                    {rec.name}
                                                </div>
                                            </th>
                                        }) : (<></>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    progressReport.length > 0 ?
                                        progressReport.map((rec, index) => {
                                            return <tr key={index}>
                                                <td>
                                                    {
                                                        dynReport.groupBy === 'user' ? (<div className="table-user-list">
                                                            <a href="#">
                                                                <div className="table-user-avatar">
                                                                    <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                                {rec.displayName}
                                                            </a>
                                                        </div>) : (
                                                            <div className="dealership-tbl float-left text-left">
                                                                {rec.displayName}
                                                            </div>
                                                        )
                                                    }

                                                </td>
                                                {
                                                    dynReport.columns.map((val, index) => {
                                                        return <Fragment key={index}><td key={index} className={`count-large ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}>
                                                            {
                                                                (val === 'wonAmount' || val === 'quoteAmount') ? rec[`format${val}`] :
                                                                    `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                            }
                                                        </td>
                                                            {
                                                                dynReport?.dynColumns?.filter(a => a.moveAfter === val)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                    return <td key={dynCol.id} className={`count-large`}>
                                                                        {rec[dynCol.id]}{dynCol.action === 'percentage' ? '%' : ''}
                                                                    </td>
                                                                }) : (<></>)
                                                            }
                                                        </Fragment>
                                                    })
                                                }
                                                {
                                                    dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                        return <td key={index} className={`count-large`}>
                                                            {rec[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                        </td>
                                                    }) : (<></>)
                                                }
                                            </tr>
                                        }) : (
                                            <tr>
                                                <td colSpan={dynReport.columns.length + 1}>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2"> No data</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                }
                            </tbody>
                            {
                                progressReport.length > 0 ?
                                    (<tfoot>
                                        <tr>
                                            <td className="td-head table-sub-total">Totals</td>
                                            {
                                                dynReport.columns.map((val, index) => {
                                                    return <Fragment key={index} ><td className="count-large text-center table-sub-total">
                                                        {
                                                            (val === 'walkintestdrive' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                                val === 'walkinsold' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) : 0) :
                                                                    val === 'appointmenttestdrive' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "tdappt")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "tdappt")) : 0) :
                                                                        val === 'testdrivetotestdrive' ? (_.sumBy(progressReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(progressReport, "testdriveActivity")) * _.sumBy(progressReport, "tdtestdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "testdriveActivity")) * _.sumBy(progressReport, "tdtestdrive")) : 0) :
                                                                            val === 'appointmentsold' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "apptsold")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "apptsold")) : 0) :
                                                                                val === 'testdriveConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                                                    val === 'tradeinwonConv' ? (_.sumBy(progressReport, "tradein") > 0 ? Math.round((100 / _.sumBy(progressReport, "tradein")) * _.sumBy(progressReport, "tradeinwon")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "tradein")) * _.sumBy(progressReport, "tradeinwon")) : 0) :
                                                                                        val === 'wonConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) : 0) :
                                                                                            val === 'responseTime' ? (_.sumBy(progressReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalresponseTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                val === 'maxresponseTime' ? (_.sumBy(progressReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxresponseTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                    val === 'minresponseTime' ? (_.sumBy(progressReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminresponseTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                        val === 'allocatedTime' ? (_.sumBy(progressReport, `totalallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalallocatedTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                            val === 'maxallocatedTime' ? (_.sumBy(progressReport, `totalmaxallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxallocatedTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                                val === 'minallocatedTime' ? (_.sumBy(progressReport, `totalminallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminallocatedTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                                    val === 'contactedTime' ? (_.sumBy(progressReport, `totalcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalcontactedTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                                        val === 'maxcontactedTime' ? (_.sumBy(progressReport, `totalmaxcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxcontactedTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                                            val === 'mincontactedTime' ? (_.sumBy(progressReport, `totalmincontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmincontactedTime), propsSettings.dealersettings.client) : '00:00:00') :
                                                                                                                                val === 'overallPending' && dynReport.groupBy === 'dateFilter' && progressReport[0] ? progressReport[0].overallPending :
                                                                                                                                    val === 'wonAmount' || val === 'quoteAmount' ? <NumberFormat
                                                                                                                                        thousandSeparator={true}
                                                                                                                                        prefix={currencySymbol}
                                                                                                                                        displayType={'text'}
                                                                                                                                        value={Math.round((_.sumBy(progressReport, val) + Number.EPSILON) * 100) / 100}
                                                                                                                                    /> :
                                                                                                                                        val === 'scheduledConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) : 0) :
                                                                                                                                            val === 'showConv' ? (_.sumBy(progressReport, `convertedLeads`) > 0 ? Math.round((100 / _.sumBy(progressReport, `convertedLeads`)) * _.sumBy(progressReport, "show")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `convertedLeads`)) * _.sumBy(progressReport, "show")) : 0) :
                                                                                                                                                val === 'noshowConv' ? (_.sumBy(progressReport, `convertedLeads`) > 0 ? Math.round((100 / _.sumBy(progressReport, `convertedLeads`)) * _.sumBy(progressReport, "noshow")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `convertedLeads`)) * _.sumBy(progressReport, "noshow")) : 0) :
                                                                                                                                                    val === 'convertedConv' ? (_.sumBy(progressReport, `totalInbound`) > 0 ? Math.round((100 / _.sumBy(progressReport, `totalInbound`)) * _.sumBy(progressReport, "convertedLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `totalInbound`)) * _.sumBy(progressReport, "convertedLeads")) : 0) :
                                                                                                                                                        reportColumns.some(item => item.value === val && val.includes('enqstatus-') && item.percentage === true) ?
                                                                                                                                                            reportColumns.filter(item => item.value === val && val.includes('enqstatus-') && item.percentage === true).map(col => {
                                                                                                                                                                return col.value === val ?
                                                                                                                                                                    (isInbound ? _.sumBy(progressReport, `convertedLeads`) : _.sumBy(progressReport, `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(progressReport, `convertedLeads`) : _.sumBy(progressReport, `total`))) * _.sumBy(progressReport, `${val.replace('Conv', '')}`)) : 0
                                                                                                                                                                    : 0
                                                                                                                                                            }) : _.sumBy(progressReport, val))
                                                        }
                                                        {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                                    </td>
                                                        {
                                                            dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                let ptg = dynCol.columns ? _.sumBy(dynCol.columns.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                                let total = dynCol.columns1 ? _.sumBy(dynCol.columns1.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                                let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                                return <td key={dynCol.id} className="count-large text-center table-sub-total">
                                                                    {
                                                                        dynCol.action === 'percentage' ? (
                                                                            <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                                        ) : (<>{_.sumBy(progressReport, dynCol.id)}</>)
                                                                    }
                                                                </td>
                                                            }) : (<></>)
                                                        }
                                                    </Fragment>
                                                })
                                            }
                                            {
                                                dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                    let ptg = val.columns ? _.sumBy(val.columns.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                    let total = val.columns1 ? _.sumBy(val.columns1.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                    return <td key={index} className="count-large text-center table-sub-total">
                                                        {
                                                            val.action === 'percentage' ? (
                                                                <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                            ) : (<>{_.sumBy(progressReport, val.id)}</>)
                                                        }
                                                    </td>
                                                }) : (<></>)
                                            }
                                        </tr>
                                    </tfoot>
                                    ) : (<></>)
                            }
                        </table>
                    </div>
                    ) : dynReport.reportType === 'column' ?
                        (
                            <div className="text-center w-100 float-left">
                                <BarChart
                                    id={'view-report-bar-chart'}
                                    height={`550px`}
                                    data={progressReport}
                                    columns={dynReport.columns ? _.isArray(dynReport.columns) ? dynReport.columns : [dynReport.columns] : []}
                                    dynamicColumns={reportColumns}
                                    xCategory={'displayName'}
                                    calculationType={dynReport.calculationType}
                                    handleShowEnquiryList={(val, rec) => {
                                        console.log(val)
                                    }}
                                />
                            </div>
                        ) : dynReport.reportType === 'pie' ? (<>
                            {
                                progressReport.some(item => item[dynReport.columns] > 0) ? (
                                    <div className="text-center w-100 float-left">
                                        <PieChart
                                            id={'view-report-pie-chart'}
                                            height={`550px`}
                                            data={progressReport}
                                            dynamicReport={true}
                                            columns={_.isArray(dynReport.columns) ? dynReport.columns[0] : dynReport.columns}
                                            handleShowEnquiryList={(val, rec) => {
                                                console.log(val)
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <NoDataPieChart
                                        id={'no-data-pie-chart'}
                                        height={`550px`}
                                    />)
                            }
                        </>) : dynReport.reportType === 'target' ? (
                            <div className="text-center w-100 float-left">
                                <LineChart
                                    id={'view-report-line-chart'}
                                    height={`550px`}
                                    data={progressReport.map(rec => { return { ...rec, 'name': rec.displayName } })}
                                    dynamicColumns={reportColumns}
                                    xCategory={'displayName'}
                                    columns={dynReport.target > 0 ? (_.isArray(dynReport.columns) ? [...dynReport.columns, 'target'] : [dynReport.columns, 'target']) : (_.isArray(dynReport.columns) ? dynReport.columns : [dynReport.columns])}
                                    calculationType={dynReport.calculationType}
                                    handleShowEnquiryList={(val, rec) => {
                                        console.log(val)
                                    }}
                                />
                            </div>
                        ) : (<></>)
                }
                {
                    dealersettings ? (<div className="footer">
                        <div className="footer-bottom">
                            <p>
                                <span style={{ paddingLeft: 0 }} >
                                    {dealersettings.address ? <>{dealersettings.address} &nbsp; | &nbsp;</> : <></>}
                                </span>
                                <span style={{ paddingLeft: 0 }}>
                                    {dealersettings.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{dealersettings.phone}</span></> : <></>}
                                    {dealersettings.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >&nbsp; | &nbsp;{dealersettings.website}</span></> : <></>}
                                </span>
                            </p>
                        </div>
                        <div className="footer-cpy">
                            <div className="footer-left">{'Powered by FusionSD.'}</div>

                            <div className="footer-right"></div>

                        </div>
                        <div className="clear"></div>
                    </div>) : (<></>)
                }

            </section>
        </div>
    </>)
}

export default ViewDynamicReport;