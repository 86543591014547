/** LIBRARIES */
import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import imageCompression from 'browser-image-compression';
import { Modal } from "react-bootstrap";

import { collection } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'

import Icon from '@mdi/react';
import { mdiNoteCheck, mdiHistory, mdiLock } from '@mdi/js';

import {
    ImageViewer, PopUpModal,
    ContentOverlay,
    InputAutoNumber,
    PopoverPanel,
    ImageCropHook,
    MarkingInfo,
    ImageCanvas
} from '../../../components';

import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import PageNotFound from '../../../components/pagenotFound';

import AddContact from '../../contacts/add';
import Files from '../../files';
import AddFile from '../../files/add';
import CommonHelper from '../../../services/common';

import AddTradeIn from '../../tradeIn/add';
import TradeinInfo from '../tradeinInfo'
//import VehicleInfo from '../../service/requirement/info';
import SendTradeInLink from '../../tradeIn/sendLink';
import PipelineSwitch from '../pipelineSwitch';
import SendMail from '../../pipeline/details/sendMail';
import RealatedTab from '../../pipeline/quickview/relatedTab'
import ActivityTab from '../../pipeline/quickview/activityTab'
import AddVideo from '../../common/addVideo'
import ChatSidePanel from '../../chat/chatsidepanel';
import OfferNotes from '../offerNotes'
import OfferLogs from '../offerLogs'
import OfferPriceLogs from '../offerLogs/priceLogs'

import RequestOffers from '../details/requestOffers'
import ReRequestOffers from '../details/reRequestOffers'
import SelfEvaluationOffers from '../details/selfEvaluation'

import EnquiryFields from '../enquiryFields'
import TipInfoInputs from './infoinputs'
import AutoGrab from "../../tradeIn/autograb";
import ExistingTIPLIst from '../list/existingList'
/** VIEW-MODELS */
import { tipStatus, tipOfferStatus } from '../../../services/enum';
import {
    objContact,
    contactBasicDetailsVM,
} from '../../contacts/viewModel';
import { objTradeIn, objMark, sidePhotosVM, tradeinSortOptions } from '../../tradeIn/viewModel';
import { objTradeIP, objBasicTradeInVM, objOfferNotesVM } from '../viewModel';
import { firestoreDB, storageBucket } from '../../../services/helper';

//#region  ASSIGN VARIABLES
let titles = [];
let enquiryOptionsDF = [];
let tradeinProAllStatus = [];

const fieldsData = {
    contact: '',
    businessContact: '',
    tradeinPro: '',
    company: ''
}

let enquirySortOptions = [
    "origin", "lostReason", "lostSubReason"
];
//#endregion

class TradeinPlusInfo extends Component {
    // _isMounted = false;
    constructor(props) {
        super(props);
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.refTradeIn = firestoreDB(this.props.dealersettings).firestore().collection('tradeins');
        this.unsubscribeTIP = null;
        this.unsubscribeTradeIn = null;
        this.unsubscribeContact = null;
        this.unsubscribeNote = null;
        this.unsubscribeTIPHistory = null;
        this.mandatoryFields = [];
        this.mandatoryVehicleFields = [];
        this.state = {
            loading: false,
            activeLeftKey: '0',
            activeCntKey: '0',
            activeVehKey: '1',
            activeTipKey: '2',
            activeRightKey: '',
            activeTab: 'tradein',
            fields: Object.assign({}, objTradeIP),
            contact: Object.assign({}, objContact),
            tradeIn: Object.assign({}),
            tradeinNotes: Object.assign([]),
            tipHistory: Object.assign([]),
            tipOffers: Object.assign([]),
            tipOfferLogs: Object.assign([]),
            tipOfferNotes: Object.assign([]),
            tradeinVideos: Object.assign([]),
            tradeinProID: null,
            tradeinID: null,
            _clientID: null,
            errors: {},
            errorPhotos: {},
            pipelines: [],
            defaulStages: [],
            allUsers: [],
            rottenDays: 0,
            tipInfo:
            {
                new: false,
                lead: false
            },
            contactModal:
            {
                show: false,
                showSearch: false,
                title: '',
                clsActive: '',
                mandatoryFields: null
            },
            vehicleModal:
            {
                show: false,
                title: '',
                clsActive: '',
                docID: null,
                mandatoryFields: null
            },
            fileModal:
            {
                show: false
            },
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            },
            videoModal:
            {
                show: false,
                recordID: '',
            },
            pipelinePopOver: { showpopover: false, targetpopover: null },
            statusSettings: [],
            activeStageIndex: 0,
            isEmptyPipeline: false,
            showReAuthentication: false,
            showPipelineOnOpen: false,
            showCanvas: false,
            showDamage: false,
            isLoadingInspection: false,
            imageURL: null,
            imageURLs: null,
            imageLoader: Object.assign({}),
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            sendLinkModal: {
                show: false,
                tradeinID: null
            },
            showNotes: false,
            showEvaluations: false,
            showReEvaluations: false,
            showSelfEvaluations: false,
            selectedOffer: null,
            editPrice: null,
            editEvaluationPrice: null,
            putPrice: null,
            editFinalPrice: false,
            editValuerPrice: false,
            notesModal:
            {
                show: false,
                recordID: '',
            },
            logsModal:
            {
                show: false,
                recordID: '',
            },
            origins: [],
            lostReasons: [],
            lostSubReasons: [],
            enquiryModal: {
                show: false,
                errors: null,
                mandatoryFields: null,
                newStatus: null,
                newStatusValue: null,
                ismodifyStatusDate: false
            },
            showPriceLogs: false,
            pagenotFound: false,
            dealersettings: props.dealersettings,
            autoGrabModal: {
                show: false,
                tradeinID: "",
                selectedVehicle: {},
                title: "",
                clsActive: "",
            },
            showExisting: { show: false, keywords: null },
            imageViewer: {
                show: false,
                images: null
            }
        }
        const { dealersettings } = this.props;
        this.currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
    }

    //#region FUNCTIONS

    //#region LOAD PAGE

    async loadTradeinPro() {


        const { tradeinproid, clientUsers, groupUsers, dealersettings } = this.props;
        if (tradeinproid && this.props.dealersettings) {
            // if (dealersettings.client.settings.pipelines.length === 0) {
            //     Swal.fire('Access denied. Please contact administrator.', '', 'info')
            //     this.props.history.push('/tradeinpro');
            //     return
            // }

            const refData = await firestoreDB(dealersettings).firestore().collection('tradeinPro').doc(tradeinproid).get();
            var _stage = '';

            if (refData.exists) {
                const _objTIP = Object.assign({}, refData.data());
                // if (localStorage.defaultModule !== 'oem' && _objTIP.clientID && this.props.dealersettings.tradeinProClients && !this.props.dealersettings.tradeinProClients.some(e => e.id === _objTIP.clientID)) {
                //     if (localStorage.defaultModule === 'tradeinPro') {
                //         Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have dealer access. Please contact administrator.'), '', 'info')
                //         this.props.history.push('/tradeinpro');
                //     }

                //     return
                // }

                const _tradeinpro = Object.assign({}, objTradeIP);
                let _isEmptyPipeline = false;
                for (let [key, value] of Object.entries(_objTIP)) {
                    _tradeinpro[key] = value;
                }
                _tradeinpro.documentID = refData.id;

                if (_.isEmpty(_tradeinpro.pipeline)) {
                    _isEmptyPipeline = true;
                }

                let dealersettings = this.props.dealersettings;
                if (_tradeinpro.clientID && _tradeinpro.clientID !== dealersettings.client.id) {
                    dealersettings = await CommonHelper.updateClientSettings(_tradeinpro.clientID, Object.assign({}, this.props.dealersettings), 'tradeinPro');

                }

                const _client = _tradeinpro.clientID && !_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.group) &&
                    !_.isEmpty(dealersettings.group.clients) &&
                    dealersettings.group.clients[_tradeinpro.clientID] &&
                    dealersettings.group.clients[_tradeinpro.clientID].tradeinPro
                    ? dealersettings.group.clients[_tradeinpro.clientID] : dealersettings.client;

                //console.log('E              X             I                S                  T             S', _isEmptyPipeline)
                // if (_client && _client.tradeinPro && _tradeinpro.pipeline && !_client.tradeinPro.pipelines.some(item => item.value === _tradeinpro.pipeline)) {
                //     if (localStorage.defaultModule === 'tradeinPro') {
                //         Swal.fire('Access denied.You dont have pipleine access. Please contact administrator.', '', 'info')
                //         this.props.history.push('/tradeinpro');
                //     }
                //     return
                // }



                const contact = Object.assign({}, objContact);
                const _tradein = Object.assign({});
                const origins = [];
                const pipelines = [];
                const lostReasons = [];
                const lostSubReasons = [];
                let defaultstages;
                let statusSettings;
                let stages = null;

                _stage = _tradeinpro['stage'];


                // //add tradeinpro into routes
                // this.props.handleRouteChange({
                //     name: (_tradeinpro.isContact && !_.isEmpty(_tradeinpro.contact) && (!_.isEmpty(_tradeinpro.contact.firstName) || !_.isEmpty(_tradeinpro.contact.lastName)))
                //         ? CommonHelper.displayContact([], _tradeinpro.contact, '--')
                //         : (!_.isEmpty(_tradeinpro.engageNewContact) ? CommonHelper.displayContact([], _tradeinpro.engageNewContact, '--') : (_tradeinpro.displayID ? _tradeinpro.displayID : refData.id)),
                //     id: refData.id,
                //     displayID: _tradeinpro.displayID ? _tradeinpro.displayID : refData.id,
                //     status: _tradeinpro.status,
                //     active: true,
                //     clientid: _tradeinpro.clientID,
                //     isNew: null,
                //     params: '',
                //     module: 'tradeinPro'
                // }, 'tradeinPro');


                if ((!_.isEmpty(_tradeinpro['contact']) && _tradeinpro['contact'].documentID) || _tradeinpro.contactID) {

                    let _contactid = _tradeinpro.contactID ? _tradeinpro.contactID : _tradeinpro['contact'].documentID;

                    const refContactData2 = await firestoreDB(dealersettings).firestore().collection('contactDetails').doc(_contactid).get();
                    if (refContactData2.exists) {
                        const objcontactData = Object.assign({}, refContactData2.data());
                        for (let [key, value] of Object.entries(objcontactData)) {
                            contact[key] = value;
                        }
                        contact.documentID = _contactid;
                        _tradeinpro.contactID = _contactid;
                        //console.log('refContactData', objcontactData, contact);
                    }

                    this.unsubscribeContact = firestoreDB(dealersettings).firestore().collection('contactDetails')
                        .doc(_contactid)
                        .onSnapshot(this.onContactCollectionUpdate);

                    this.unsubscribeTIPHistory = firestoreDB(dealersettings).firestore().collection('tradeinPro')
                        .where('contact.documentID', '==', _contactid)
                        .where('isDeleted', '==', false)
                        .onSnapshot(this.onTIPHistoryCollectionUpdate);

                }

                if ((!_.isEmpty(_tradeinpro['tradein']) && _tradeinpro['tradein'].documentID) || _tradeinpro.tradeinID) {

                    let _tradeinid = _tradeinpro.tradeinID ? _tradeinpro.tradeinID : _tradeinpro['tradein'].documentID;

                    const refTradeData2 = await firestoreDB(dealersettings).firestore().collection('tradeins').doc(_tradeinid).get();
                    if (refTradeData2.exists) {
                        const objTradeInData = Object.assign({}, refTradeData2.data());
                        for (let [key, value] of Object.entries(objTradeInData)) {
                            _tradein[key] = value;
                        }
                        _tradein.documentID = _tradeinid;
                        _tradeinpro.tradeinID = _tradeinid;
                        //console.log('refContactData', objcontactData, contact);
                    }

                    this.unsubscribeTradeIn = firestoreDB(dealersettings).firestore().collection('tradeins')
                        .doc(_tradeinid)
                        .onSnapshot(this.onTradeInCollectionUpdate);
                }

                if (!_.isEmpty(_client) && !_.isEmpty(_client.settings)) {
                    const setttings = _client.settings;
                    titles = [];
                    setttings.titles && setttings.titles.forEach((doc) => {
                        titles.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });
                }


                if (!_.isEmpty(_client) && !_.isEmpty(_client.tradeinPro)) {
                    const setttings = _client.tradeinPro;

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.tradeinPro))
                        this.mandatoryFields = setttings.mandatoryFields.tradeinPro.split(',');

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.tradein))
                        this.mandatoryVehicleFields = setttings.mandatoryFields.tradein.split(',');

                    enquiryOptionsDF = [];
                    if (setttings.enquiryOptionsDF)
                        enquiryOptionsDF = setttings.enquiryOptionsDF;

                    let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
                    if (_tradeinpro.pipeline) {
                        let _selPipeline = allPipelines.filter(e => e.value === _tradeinpro.pipeline)[0];
                        defaultstages = _selPipeline && _selPipeline.stages;
                        statusSettings = _selPipeline && _selPipeline.statusSettings;

                        if (!_.isEmpty(defaultstages))
                            stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')
                    }

                    tradeinProAllStatus = [];
                    dealersettings?.client?.tradeinPro?.tradeinProStatus && dealersettings.client.tradeinPro.tradeinProStatus.forEach((doc) => {
                        tradeinProAllStatus.push({
                            ...doc,
                            label: <div className={`badge badge-pill badge-status-${doc.value === 'New' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                        });
                    });

                    allPipelines && allPipelines.forEach((doc) => {
                        pipelines.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });

                    setttings.origins && setttings.origins.forEach(doc => {
                        origins.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });

                    setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                        lostReasons.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name,
                            subList: !_.isEmpty(doc.subList) ? doc.subList : []
                        });
                    });

                    if (_tradeinpro.lostReason && setttings.lostReasons && !_.isEmpty(setttings.lostReasons.filter(e => e.value === _tradeinpro.lostReason)[0])) {
                        let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === _tradeinpro.lostReason)[0].subList) ?
                            setttings.lostReasons.filter(e => e.value === _tradeinpro.lostReason)[0].subList : null;

                        !_.isEmpty(_subList) && _subList.forEach(doc => {
                            lostSubReasons.push({
                                value: doc.value,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name
                            });
                        });
                    }


                }



                // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                let options = [];
                if (!_.isEmpty(dealersettings.client.roles) && _tradeinpro.clientID) {

                    let roleIds = [..._.map(dealersettings.client.roles.filter(e => _tradeinpro.pipeline && e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === _tradeinpro.pipeline)), function (obj) {
                        return obj.documentID;
                    })];
                    let _selectedUsers = [];
                    let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                    if (!_.isEmpty(roleIds))
                        _selectedUsers = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

                    if (_tradeinpro.pipeline) {
                        _selectedUsers && _selectedUsers.map(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                    }
                    else {
                        clientUsers.forEach(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                    }
                }

                const _teams = [];
                !_.isEmpty(dealersettings.client.teams) && dealersettings.client.teams.forEach(doc => {
                    _teams.push({
                        value: doc.id,
                        type: 'team',
                        searchlabel: doc.name,
                        user_ids: doc.user_ids ? doc.user_ids : [],
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar('', doc.name)} alt="" width="50" className="img-object-fit" />
                                {doc.name}
                            </div>
                        )
                    });
                });

                this.setState({
                    tradeinProID: refData.id,
                    _clientID: _tradeinpro.clientID ? _tradeinpro.clientID : dealersettings.client.id,
                    fields: _tradeinpro,
                    selectedOffer: !_.isEmpty(_tradeinpro?.offer) ? _tradeinpro.offer : null,
                    contact: contact,
                    tradeIn: _tradein,
                    tradeinID: _tradein ? _tradein.documentID : null,
                    pipelines: pipelines,
                    allUsers: _.uniqBy(options, 'value'),
                    origins: origins,
                    lostReasons: lostReasons,
                    lostSubReasons: lostSubReasons,
                    defaulStages: defaultstages,
                    activeStageIndex: stages && stages.indexOf(_stage),
                    isEmptyPipeline: _isEmptyPipeline,
                    statusSettings: statusSettings,
                    dealersettings: dealersettings,
                    allTeamUsers: [
                        {
                            label: 'Teams',
                            options: _teams
                        },
                        {
                            label: 'Users',
                            options: _.uniqBy(options, 'value')
                        }
                    ],
                }, () => {


                    this.unsubscribeTIP = firestoreDB(dealersettings).firestore().collection('tradeinPro')
                        .doc(tradeinproid)
                        .onSnapshot(this.onTIPCollectionUpdate);

                    this.loadTIPData(_tradein ? _tradein.documentID : null);

                });


            }
            else {
                this.setState({
                    pagenotFound: true,
                    tradeinProID: null,
                    tradeinID: null,
                })
            }
        }
    }


    loadTIPData = (_tradeinID) => {

        const { tradeinproid, dealersettings } = this.props;

        window.refCollectionJobDetailData = combineLatest(
            collection(firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeinproid}/offers`)),
            collection(firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeinproid}/offerLogs`)),
            collection(firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeinproid}/offerNotes`)),
            _tradeinID ? collection(firestoreDB(dealersettings).firestore().collection(`tradeins/${_tradeinID}/videos`).where('isDeleted', '==', false)) : of([]),

        ).pipe(
            map(([offersSnapshot, offerLogsSnapshot, offerNotesSnapshot, videosSnapshot]) => {
                return [
                    _.map(offersSnapshot, function (obj) {
                        let _data = obj.data()
                        return {
                            ..._data,
                            dealerPrice: _data.dealerPrice ? _data.dealerPrice : _data.evaluationPrice ? _data.evaluationPrice : null
                        }
                    }),
                    _.map(offerLogsSnapshot, function (obj) { return { ...obj.data() } }),
                    _.map(offerNotesSnapshot, function (obj) { return { ...obj.data() } }),
                    _.map(videosSnapshot, function (obj) { return { ...obj.data() } }),

                ]
            }))
            .subscribe(async ([offersSnapshot, offerLogsSnapshot, offerNotesSnapshot, videosSnapshot]) => {

                if (!_.isEmpty(offersSnapshot)) {
                    offersSnapshot = offersSnapshot.sort(function (x, y) { return (x.evaluationPrice < y.evaluationPrice ? 1 : -1); })

                    if (_.find(offersSnapshot, { evaluationPrice: true }))
                        offersSnapshot = offersSnapshot.sort(function (x, y) { return (x.evaluationPrice ? -1 : 1); })

                    if (_.find(offersSnapshot, { isSelfEvaluation: true }))
                        offersSnapshot = offersSnapshot.sort(function (x, y) { return (x.isSelfEvaluation ? -1 : 1); })

                    if (_.find(offersSnapshot, { isSelected: true }))
                        offersSnapshot = offersSnapshot.sort(function (x, y) { return (x.isSelected ? -1 : 1); })

                    if (_.find(offersSnapshot, { isApproved: true }))
                        offersSnapshot = offersSnapshot.sort(function (x, y) { return (x.isApproved ? -1 : 1); })
                }

                const offerNotes = [];
                offerNotesSnapshot && offerNotesSnapshot.forEach((doc) => {
                    const _notes = Object.assign({}, objOfferNotesVM);
                    for (let [key, value] of Object.entries(doc)) {
                        _notes[key] = value;
                    }
                    if ((_notes.isInternalNotes === true && _notes.wholeSalerNotes === false) || // isDealerInternalNotes 
                        (_notes.isInternalNotes === false && _notes.wholeSalerNotes === false) || // isDealerPublicNotes 
                        (_notes.isInternalNotes === false && _notes.wholeSalerNotes === true) // isWholesalerPublicNotes 
                    ) offerNotes.push(_notes);
                });


                this.setState({
                    tipOffers: offersSnapshot.filter(e => !e.isDeleted),
                    tipOfferLogs: offerLogsSnapshot.filter(e => !e.isDeleted),
                    tipOfferNotes: offerNotes.filter(e => !e.isDeleted),
                    tradeinVideos: videosSnapshot
                }, () => { });

            });

    }

    onTIPCollectionUpdate = (docSnapshot) => {


        if (!docSnapshot.exists) {
            return;
        }

        const { dealersettings } = this.state;
        const pipelines = [];
        let rottendays = 0;
        let stages = null;
        let defaultstages;
        let statusSettings;

        const _tradeinpro = Object.assign({}, objTradeIP);
        const _objTIP = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(_objTIP)) {
            _tradeinpro[key] = value;
        }

        _tradeinpro.documentID = docSnapshot.id;


        let _client = _tradeinpro.clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[_tradeinpro.clientID] &&
            dealersettings.group.clients[_tradeinpro.clientID].tradeinPro
            ? dealersettings.group.clients[_tradeinpro.clientID] : dealersettings.client;

        if (!_.isEmpty(_client) && !_.isEmpty(_client.settings) && _tradeinpro.pipeline) {
            const setttings = _client.tradeinPro;

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.tradeinPro))
                this.mandatoryFields = setttings.mandatoryFields.tradeinPro.split(',');

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.tradein))
                this.mandatoryVehicleFields = setttings.mandatoryFields.tradein.split(',');

            let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
            let _selPipeline = allPipelines.filter(e => e.value === _tradeinpro.pipeline)[0];
            defaultstages = _selPipeline && _selPipeline.stages;
            statusSettings = _selPipeline && _selPipeline.statusSettings;

            if (!_.isEmpty(defaultstages))
                stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

            allPipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    label: doc.name
                });
            });

            allPipelines
                .filter(e => e.value === _tradeinpro.pipeline)[0]
                .stages.forEach(col => {
                    if (_tradeinpro.stage === col.value) {
                        if (col.rottenDays) {
                            var enqage = _tradeinpro.modifiedDate ? moment().diff(moment.unix(_tradeinpro.modifiedDate.seconds), 'days') : 0;
                            rottendays = enqage - col.rottenDays;
                        }
                    }
                });

        }

        const { displayID, status, isContact, contact, ownerResponse } = _tradeinpro;
        if (_tradeinpro.status !== this.state.fields.status) {
            this.props.updatenavbar({
                id: this.state.tradeinProID,
                displayID: _tradeinpro.displayID,
                status: status,
                name: (isContact && !_.isEmpty(contact)) ? CommonHelper.displayContact([], contact, '--') : (_tradeinpro.displayID ? _tradeinpro.displayID : docSnapshot.id),
                module: 'tradeinPro'
            }, 'tradeinPro');
        }

        this.setState({
            fields: _tradeinpro,
            pipelines: pipelines,
            statusSettings: statusSettings,
            defaulStages: defaultstages,
            activeStageIndex: stages && stages.indexOf(_tradeinpro['stage'])
        });
    };

    onContactCollectionUpdate = (docSnapshot) => {

        const contact = Object.assign({}, objContact);
        const objcontactData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key))
                contact[key] = value;
        }
        contact.documentID = docSnapshot.id;

        this.setState({
            contact: contact
        });
    }

    onTradeInCollectionUpdate = (docSnapshot) => {

        const tradein = Object.assign({}, objTradeIn);
        const objcontactData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objcontactData)) {
            if (tradein.hasOwnProperty(key))
                tradein[key] = value;
        }
        tradein.documentID = docSnapshot.id;

        const _marks = [];
        if (!_.isEmpty(tradein.marks)) {
            tradein.marks.map((mark) => {
                const _mark = Object.assign({}, objMark);
                for (let [key, value] of Object.entries(mark)) {
                    _mark[key] = value;
                }
                _marks.push(_mark)
            })
            tradein.marks = _marks;
        }

        this.setState({
            tradeIn: tradein,
            tradeinID: tradein.documentID,
        });
    }

    onTIPHistoryCollectionUpdate = querySnapshot => {

        const tradeinPro = [];
        let _fields = Object.assign({});
        const { tradeinproid } = this.props;

        querySnapshot.forEach(_enquiry => {
            const enquiry = Object.assign({});
            const objEnquiryData = Object.assign({}, _enquiry.data());
            for (let [key, value] of Object.entries(objEnquiryData)) {
                enquiry[key] = value;
            }
            enquiry.documentID = _enquiry.id;

            let dealersettings = this.state.dealersettings;
            if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
            }

            if (!_.isEmpty(enquiry.pipeline) && enquiry.documentID !== tradeinproid) {
                _fields = enquiry;
                tradeinPro.push(enquiry);
            }

        });

        this.setState({
            tipHistory: _.orderBy(tradeinPro, ["modifiedDate"], ["desc"])
        });
    };

    handleSpaceScrollLock = (e) => {
        if (e.keyCode == 32 && e.target == document.body) {
            e.preventDefault();
        }
    }

    updateDimensions() {
        var tabHeight = (window.innerHeight - 265)
        var activitytabHeight = (window.innerHeight - 300)
        document.documentElement.style.setProperty('--quicktabHeight', tabHeight + 'px');
        document.documentElement.style.setProperty('--activitytabHeight', activitytabHeight + 'px');
    }

    componentDidMount() {
        localStorage.removeItem("objTradeIP");
        this._isMounted = true;
        if (this._isMounted) {
            this.loadTradeinPro();
        }

        window.addEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    componentDidUpdate() {
        let classList = document.getElementsByTagName('body')[0].classList.value;
        if (classList.indexOf('enquiry-bg') >= 0 && classList.indexOf('inner-bg') >= 0) {
            //console.log('componentDidUpdate-classList', classList.indexOf('enquiry-bg'), classList.indexOf('inner-bg'));
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        if (this.state.loading) {
            this.clearAllSubscribe();
            this.loadTradeinPro();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tradeinproid !== state.tradeinProID && state.tradeinProID) {
            return {
                loading: true,
                tradeinProID: null,
                tipOffers: Object.assign([]),
            };
        } else {
            return {
                loading: false,
            };
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        window.removeEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });
        this.clearAllSubscribe();
        // if (window.location.pathname.indexOf('tradeinpro/details') < 0) {
        //     this.props.resetnavbar();
        // }
        this._isMounted = false;

        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    clearAllSubscribe = () => {
        this.unsubscribeTIP && this.unsubscribeTIP();
        this.unsubscribeContact && this.unsubscribeContact();
        this.unsubscribeTradeIn && this.unsubscribeTradeIn();
        this.unsubscribeNote && this.unsubscribeNote();
        this.unsubscribeTIPHistory && this.unsubscribeTIPHistory();
        window?.refCollectionJobDetailData?.unsubscribe && window.refCollectionJobDetailData.unsubscribe()
    }
    //#endregion    

    //#region Vehicle CHANGES

    // handleMandatoryTradeinOpen = (title) => {
    //     let _manFields = this.getMandatoryFieldsByType('tradein');
    //     const { tradeIn } = this.state;
    //     //console.log('handleMandatoryTradeinOpen', _manFields)
    //     this.setState({
    //         vehicleModal: {
    //             show: true,
    //             title: 'tradeIn',
    //             clsActive: 'overlay-modal active',
    //             docID: !_.isEmpty(tradeIn) ? tradeIn.documentID : null,
    //             mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
    //         },
    //     });
    // }

    handleTradeinOpen = (title, _manFields) => {
        const { tradeIn } = this.state;
        setTimeout(() => {
            this.setState({
                vehicleModal: {
                    show: true,
                    title: 'tradeIn',
                    clsActive: 'overlay-modal active',
                    docID: !_.isEmpty(tradeIn) ? tradeIn.documentID : null,
                    mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
                },
            });
        }, 200);

    }

    handleTradeInClose = (tradeinID, tradein, isLink) => {
        const { tipOffers, fields, tradeinProID } = this.state;
        const { dealersettings } = this.props;
        if (tradeinID) {

            let objData = {};
            objData.isTradein = isLink ? false : true;
            objData.tradeinID = tradeinID;
            objData.modifiedBy = dealersettings ? dealersettings.id : '';
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';
            if (!fields.isNewTradein && !isLink) objData.isNewTradein = false;

            if (!_.isEmpty(tradein)) {
                const _vehicle = Object.assign({}, objBasicTradeInVM);
                for (let [key, value] of Object.entries(tradein)) {
                    if (_vehicle.hasOwnProperty(key))
                        _vehicle[key] = value;
                }
                objData['tradein'] = _vehicle;
            }

            const updateRef = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc(tradeinProID);
            updateRef.set(objData, { merge: true }).then((docRef) => {

                this.unsubscribeTradeIn = firestoreDB(dealersettings).firestore().collection('tradeins')
                    .doc(tradeinID)
                    .onSnapshot(this.onTradeInCollectionUpdate);

                this.loadTIPData(tradeinID);

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }

        if (tradeinID && fields.status === tipStatus.PENDING && !_.isEmpty(tipOffers) && tipOffers.filter(e => !e.isSelfEvaluation)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'You have updated trade-in data.'),
                text: CommonHelper.showLocale(this.props, `Do you want to send re-request offer to wholesaler?`),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            }).then((result) => {
                if (result.value) {
                    this.isValidForReRequest();
                }
            });
        }

        this.setState({
            vehicleModal: {
                show: false,
                title: 'tradeIn',
                clsActive: 'overlay-modal active',
                mandatoryFields: null,
                docID: null
            },
        });
    }

    //#endregion

    //#region SEND LINK CHANGES

    isValidForTradeIn = (isLink) => {
        const { tradeIn, fields } = this.state;
        let _manFields = this.getMandatoryFieldsByType('contact');
        let _manFieldsTIP = this.getMandatoryFieldsByType('tradeinPro');
        if (!fields.isContact && (!_.isEmpty(_manFields) || _manFieldsTIP?.includes('isContact'))) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then((result) => {
                if (result.value) {
                    this.handleContactOpen('addContact');
                }
            });
            return false;

        }
        else {
            if (isLink) {
                this.setState({
                    sendLinkModal: {
                        show: true,
                        tradeinID: null
                    }
                })
            } else {
                this.handleTradeinOpen();
            }
            return true;
        }
    }

    handleReSendLink = (tradeinID) => {
        const { tradeIn, fields } = this.state;
        let _manFields = this.getMandatoryFieldsByType('contact');
        let _manFieldsTIP = this.getMandatoryFieldsByType('tradeinPro');
        if (!fields.isContact && (!_.isEmpty(_manFields) || _manFieldsTIP?.includes('isContact'))) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then((result) => {
                if (result.value) {
                    this.handleContactOpen('addContact');
                }
            });
            return false;
        }
        else {
            this.setState({
                sendLinkModal: {
                    show: true,
                    tradeinID: tradeinID
                }
            })
            return true;
        }

    }

    handleCloseSendLink = (data, tradeinID) => {
        if (!_.isEmpty(data)) {
            const { dealersettings } = this.props;
            toast.notify((<div style={{ fontSize: `14px` }}>
                <span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span>
                {tradeinID ? 're-sending...' : 'sending...'}
            </div>),
                {
                    position: 'top',
                    duration: null
                },
            )

            const objData = Object.assign({});
            const { fields, contact, tradeinProID } = this.state;

            if (!tradeinID) {
                objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                objData.documentID = this.refTradeIn.doc().id;

                if (!_.isEmpty(contact)) {
                    let _contact = Object.assign({}, contactBasicDetailsVM);
                    for (let [key, value] of Object.entries(contact)) {
                        if (_contact.hasOwnProperty(key))
                            _contact[key] = value;
                    }
                    objData.contact = _contact;
                    objData.contactID = _contact.documentID;
                }

                // if (!_.isEmpty(fields)) {
                //     let _enquiry = Object.assign({}, enquiryBasicTDVM);
                //     for (let [key, value] of Object.entries(fields)) {
                //         if (_enquiry.hasOwnProperty(key))
                //             _enquiry[key] = value;
                //     }
                //     objData.enquiry = _enquiry;
                objData.tradeinProID = tradeinProID;
                // }

            }
            else { // for resend
                objData.documentID = tradeinID;
            }

            objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';
            objData.isDeleted = false;
            objData.clientID = fields.clientID ? fields.clientID : null;
            objData.projectId = fields.projectId ? fields.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
            objData.linkURL = 'tradein/customer/' + objData.documentID;

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeins').doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                // toast.notify('Trade-In saved successfully', {
                // 	duration: 2000
                // })

                let _postObject = {
                    "documentID": objData.documentID,
                    "sendSMS": data.sendSMS ? true : false,
                    "phone": data.phone ? data.phone : null,
                    "phoneCode": data.phoneCode ? data.phoneCode : null,
                    "sendEmail": data.sendEmail ? true : false,
                    "email": data.email ? data.email : null,
                    "projectId": objData.projectId ? objData.projectId : null,
                    "displayName": data.fullName ? data.fullName : null
                }

                if (!_.isEmpty(data.emails)) {
                    let _data = Array.prototype.map.call(data.emails.filter(item => !_.isEmpty(item.value)), function (item) { return item.value ? item.value : ''; }).join(",")
                    _postObject.ccEmails = _data;
                }

                let _logNote = `Trade-In link ${tradeinID ? 're-sent' : 'sent'}. ${data.phone ? '\nPhone: ' + data.phone : ''} ${(data.email ? ('\nEmail: ' + data.email) : '')} ${(data.ccEmails ? ('\nCC Emails: ' + data.ccEmails) : '')}`;
                //console.log('_postObject', _postObject);
                const tradeinSendLink = window.firebase.functions().httpsCallable('tradein-sendLink');
                tradeinSendLink(_postObject).then((data) => {
                    toast.closeAll();
                    if (data.data.success === true) {
                        toast.notify(`Trade-In link ${tradeinID ? 're-send' : 'send'} successfully.`, {
                            duration: 2000
                        })
                        CommonHelper.saveChangeLog(objData.clientID, null, tradeinProID, _logNote, 'tradeinPro', 'updated');
                        this.handleTradeInClose(objData.documentID, null, true)


                    }
                    else {
                        Swal.fire(data.data.message, '', 'error')
                    }
                });
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }
        this.setState({
            sendLinkModal: {
                show: false,
                tradeinID: null
            }
        })



    }

    //#endregion

    //#region CONTACT CHANGES

    handleMandatoryContactOpen = (title) => {
        let _manFields = this.getMandatoryFieldsByType('contact');
        //console.log('handleMandatoryContactOpen', _manFields)
        this.setState({
            contactModal: {
                show: true,
                showSearch: true,
                title: title,
                clsActive: 'overlay-modal active',
                mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
            },
        });
    }

    handleContactOpen = (title, _manFields) => {
        //console.log('handleContactOpen', _manFields)
        setTimeout(() => {
            this.setState({
                contactModal: {
                    show: true,
                    showSearch: true,
                    title: title,
                    clsActive: 'overlay-modal active',
                    mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
                },
            });
        }, 200);

    }

    handleContactClose = (ID) => {
        if (ID) {
            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {
                    const objcontactData = Object.assign({}, doc.data());
                    const contact = Object.assign({}, objContact);
                    const _contact = Object.assign({}, contactBasicDetailsVM);
                    for (let [key, value] of Object.entries(objcontactData)) {
                        contact[key] = value;
                        if (_contact.hasOwnProperty(key))
                            _contact[key] = value;
                    }
                    contact.documentID = doc.id;

                    let state = Object.assign({}, this.state.fields);
                    state['isContact'] = true;
                    state['contact'] = _contact;
                    state.contactID = doc.id;

                    if (state.isNewTradein)
                        state.isNewTradein = false;

                    state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                    state.modifiedDate = window.firebase.firestore.Timestamp.now();
                    state.modifiedFrom = 'web';
                    const objData = Object.assign({}, state);
                    //Remove empty value from object
                    for (var propName in objData) {
                        if (
                            objData[propName] === null ||
                            objData[propName] === undefined ||
                            objData[propName] === '' ||
                            (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
                        ) {
                            delete objData[propName];
                        }
                    }
                    this.setState({ contact: contact, fields: state });
                    const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc(this.state.tradeinProID);

                    updateRef.update({
                        isContact: state.isContact,
                        contact: state.contact,
                        contactID: state.contactID,
                        isNewTradein: state.isNewTradein,
                        modifiedBy: state.modifiedBy,
                        modifiedDate: state.modifiedDate,
                        modifiedFrom: 'web'
                    }).then((docRef) => {
                        const { isContact, status, displayID, documentID, ownerResponse, contactID } = state;
                        this.props.updatenavbar({
                            id: this.state.tradeinProID,
                            status: status,
                            name: (isContact && !_.isEmpty(contact)) ? CommonHelper.displayContact([], contact, '--') : (displayID ? displayID : documentID),
                            isNew: null,
                            params: null,
                            module: 'tradeinPro'
                        }, 'tradeinPro');

                        this.setState({ tipInfo: { new: false, lead: false } })

                        this.unsubscribeContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails')
                            .doc(contactID)
                            .onSnapshot(this.onContactCollectionUpdate);

                        this.unsubscribeTIPHistory = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro')
                            .where('contact.documentID', '==', contactID)
                            .where('isDeleted', '==', false)
                            .onSnapshot(this.onTIPHistoryCollectionUpdate);

                    }).catch((error) => {
                        console.error("Error updating enquiries: ", error);
                    });

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            contactModal:
            {
                show: false,
                showSearch: false,
                title: '',
                clsActive: ''
            }
        });
    }
    //#endregion

    //#region CHANGE EVENT TIP

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.saveTradeinProField(name, value);
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleOnDateChange = (value, name) => {
        let _selectedDate = null;
        if (value) _selectedDate = window.firebase.firestore.Timestamp.fromDate(moment(moment(value).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        this.saveTradeinProField(name, _selectedDate);

        setTimeout(() => this.errorChange(name), 500);
    };

    handleTimeChange = (date, name) => {

        this.saveTradeinProField(name, window.firebase.firestore.Timestamp.fromDate(moment(date)._d));
        setTimeout(() => this.errorChange(name), 500);
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (!_.isEmpty(errors)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if (key === 'status') {
                    if (!this.state.tipInfo.lead)
                        errors[key] = errorClass;
                }
                else
                    errors[key] = errorClass;
            }
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    };

    handleSelectSave = (e, data) => {
        this.saveTradeinProField(data.name, (e ? e.value : null));
        setTimeout(() => this.errorChange(data.name), 500);
    };

    handleDeliveryDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        this.saveTradeinProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    handleDateChange = (val, name) => {
        if (val) val = moment(val).format('YYYY-MM-DD');

        this.saveTradeinProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    handlePIDateSave = (val, name) => {
        if (val) val = moment(val).format('MMM YYYY');

        this.saveTradeinProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    isPIValid = (date) => {
        let _date = moment(date, 'MMM YYYY');
        if (moment().isBefore(_date) || moment().isSame(_date, 'month')) return '';
        else return 'form-alert ';
    };

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleLostReasonSave = (e, data) => {
        const { lostSubReason } = this.state.fields;
        const { lostReasons } = this.state;
        const lostSubReasons = [];
        if (!_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
            lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                lostSubReasons.push({
                    value: doc.value,
                    label: doc.name
                });
            });
        }
        this.setState({ lostSubReasons: lostSubReasons });
        this.saveTradeinProField(data.name, e.value);
        if (lostSubReason)
            this.saveTradeinProField('lostSubReason', null);

        setTimeout(() => this.errorChange(data.name), 500);
    };


    handleLeftActiveKey = (index) => {
        this.setState({ activeLeftKey: index });
    }
    handleCntActiveKey = (index) => {
        this.setState({ activeCntKey: index });
    }
    handleVehActiveKey = (index) => {
        this.setState({ activeVehKey: index });
    }
    handleTipActiveKey = (index) => {
        this.setState({ activeTipKey: index });
    }

    //#endregion

    //#region  SAVE TIP INFO


    cancelTradeinProField = (name, val) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state, editFinalPrice: false, editValuerPrice: false }, () => { this.errorChange(name); });
    }

    saveFinalPrice = (_name, val, defaultVaue) => {
        let name = _name.split('_')[0];
        const { fields, dealersettings } = this.state;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        let _msg = ''
        let __bindObj = {}
        if (name === 'finalPrice') {
            let _defaultVal = defaultVaue ? defaultVaue : 0;
            if (_defaultVal === val) {
                __bindObj.finalizedBy = localStorage.uid;
                __bindObj.finalizedDate = window.firebase.firestore.Timestamp.now();
                _msg = `The evaluation has been approved, setting the final price at ${CommonHelper.formatCurrency(currencySymbol, val ? val : 0)}`
            }
            else
                _msg = `Updated the final price from ${CommonHelper.formatCurrency(currencySymbol, _defaultVal)} to ${CommonHelper.formatCurrency(currencySymbol, val ? val : 0)}`

        }
        else {
            let _defaultVal = defaultVaue ? defaultVaue : 0;
            _msg = `Updated the valuer price from ${CommonHelper.formatCurrency(currencySymbol, _defaultVal)} to ${CommonHelper.formatCurrency(currencySymbol, val ? val : 0)}`

        }

        let _objLogData = {
            tradeinProID: fields.documentID,
            offerID: fields.offer?.documentID ? fields?.offer?.documentID : null,
            projectId: fields?.projectId ? fields?.projectId : null,
            message: _msg
        }
        CommonHelper.saveTradeInProLog(dealersettings, _objLogData);
        this.saveTradeinProField(name, val, false, false, __bindObj)
        this.setState({ editFinalPrice: false, editValuerPrice: false });
    }

    saveTradeinProField = (name, val, noToast, _isRepoen, _bindObj) => {

        if (!val && this.mandatoryFields && this.mandatoryFields.indexOf(name) >= 0) {
            this.errorChange(name);
        }
        else {
            let objData = !_.isEmpty(_bindObj) ? _bindObj : {};
            let state = Object.assign({}, this.state.fields);
            if (name !== 'isFav') {
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
            }

            if (name === 'tradein') {
                state['isTradein'] = true;
                objData['isTradein'] = true;
            }

            if (name === 'offer' && val) {
                delete val['tradeinPro']
            }

            if (name === 'status') {
                if (state.isNewTradein)
                    objData.isNewTradein = false;

                if (val === tipStatus.COMPLETED) {
                    objData.completedDate = state.modifiedDate;
                    if (_.isEmpty(state.owner)) objData.owner = localStorage.uid;
                    objData.wonDate = null;
                    objData.lostDate = null;
                    objData.lostReason = null;
                    objData.lostSubReason = null;
                }
                else if (val === tipStatus.WON) {
                    objData.wonDate = state.modifiedDate;
                    objData.lostDate = null;
                    objData.lostReason = null;
                    objData.lostSubReason = null;
                }
                else if (val === tipStatus.LOST) {
                    objData.lostDate = state.modifiedDate;
                    objData.wonDate = null;
                }
                else if (val === tipStatus.PENDING) {
                    objData.completedDate = null;
                }


                objData = this.handleMoveStageForStatusChange(val, objData);

            }

            if (name === 'finalPrice' || name === 'valuerPrice') {
                if (!_.isEmpty(state.tradein))
                    objData.tradein = {
                        ...state.tradein,
                        [name === 'finalPrice' ? 'offerPrice' : name]: val
                    }
                objData = this.handleMoveStageForStatusChange(name, objData);

            }

            if (_isRepoen && this.state.dealersettings?.client?.clientSettings?.managerWorkflow) {
                if (!_.isEmpty(state.tradein))
                    objData.tradein = {
                        ...state.tradein,
                        valuerPrice: 0,
                        offerPrice: 0,
                        [name]: val
                    }
                state['finalPrice'] = 0;
                objData['finalPrice'] = 0;
                state['valuerPrice'] = 0;
                objData['valuerPrice'] = 0;

                objData.finalizedBy = '';
                objData.finalizedDate = null;
            }

            state[name] = val;
            objData[name] = val;
            objData.modifiedBy = state.modifiedBy;
            objData.modifiedDate = state.modifiedDate;
            objData.modifiedFrom = 'web';

            this.setState({ fields: state });

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc(this.state.tradeinProID);
            updateRef.set(objData, { merge: true }).then((docRef) => {

                if (!noToast)
                    toast.notify('Tradein pro updated successfully.', { duration: 2000 });

                if (name === 'status')
                    this.saveTradeinFieldStatus(val)

                if (name === 'finalPrice' || name === 'valuerPrice')
                    this.updateTradeinFieldOfferPrice(val, name)

                if (name === 'status' && (val === tipStatus.WON || val === tipStatus.LOST))
                    this.saveOfferStatus(val);

                if (name === 'status' || name === 'isNewTradein') {

                    this.props.updatenavbar({
                        id: this.state.tradeinProID,
                        status: state.status,
                        name: CommonHelper.displayName(state),
                    });
                }

                if (this.props.isFilterApplied) {
                    if (this.props.updateTradeinPro) {
                        this.props.updateTradeinPro({
                            ...objData,
                            id: objData.documentID
                        }, true);
                    }
                    if (this.props.updateListEnquiry) {
                        this.props.updateListEnquiry(objData);
                    }
                }


            }).catch(error => {
                console.error('Error updating enquiries: ', error);
            });
        }
    };

    saveOfferStatus = (_status, _isRepoen) => {
        const { fields, tipOffers } = this.state;
        const batch = firestoreDB(this.props.dealersettings).firestore().batch();

        tipOffers && tipOffers.forEach(newVM => {
            newVM.modifiedBy = localStorage.uid;
            newVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newVM.modifiedFrom = 'web';

            if (_isRepoen) {
                if (newVM.evaluationPrice) newVM.status = tipOfferStatus.COMPLETED; else newVM.status = tipOfferStatus.PENDING;
                newVM.approvedBy = null;
                newVM.approvedDate = null;
                newVM.approvedFrom = null;
                newVM.isApproved = false;
            }
            else if (_status === tipStatus.WON || _status === tipStatus.LOST) {
                if (!_.isEmpty(fields.offer) && fields.offer.documentID === newVM.documentID && _status === tipStatus.WON)
                    newVM.status = tipOfferStatus.WON;
                else
                    newVM.status = tipOfferStatus.LOST;
            }

            if (newVM.tradeinProID && newVM.documentID)
                batch.set(firestoreDB(this.props.dealersettings).firestore().doc(`tradeinPro/${newVM.tradeinProID}/offers/${newVM.documentID}`), newVM, { merge: true });
        })
        batch.commit();

    }

    saveTradeinFieldStatus = (_status) => {
        const { fields, tradeIn, contact, dealersettings } = this.state;
        let objData = {};
        objData.status = _status;
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        if (dealersettings?.client?.clientSettings?.managerWorkflow) {
            objData.valuerPrice = fields.valuerPrice ? fields.valuerPrice : 0;
            objData.offerPrice = fields.finalPrice ? fields.finalPrice : 0;
        }
        else if (fields?.offer?.dealerPrice) objData.offerPrice = fields.offer.dealerPrice;
        objData.offer = (!_.isEmpty(fields.offer) ? fields.offer : null);
        // update Customer Owned Vehicle
        let _currentlyOwned = null;
        if (tradeIn.status == tipStatus.WON && _status != tipStatus.WON)
            _currentlyOwned = true;
        else if (_status == tipStatus.WON)
            _currentlyOwned = false;

        firestoreDB(this.props.dealersettings).firestore().collection('tradeins').doc(this.state.tradeinID).set(objData, { merge: true })

        if (contact?.documentID && _.isBoolean(_currentlyOwned) && (tradeIn.regNo || tradeIn.chassisNo))
            CommonHelper.updateCustomerOwnedVehicle(contact?.documentID, tradeIn.chassisNo, tradeIn.regNo, _currentlyOwned, this.props)
    }

    updateTradeinFieldOfferPrice = (val, name) => {
        const { fields, dealersettings } = this.state;
        let objData = {};
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        if (name === 'valuerPrice') {
            objData.valuerPrice = val ? val : 0;
        }
        else if (val)
            objData.offerPrice = val;
        else if (dealersettings?.client?.clientSettings?.managerWorkflow)
            objData.offerPrice = fields.finalPrice ? fields.finalPrice : 0;
        else if (fields?.offer?.dealerPrice) objData.offerPrice = fields.offer.dealerPrice;
        objData.offer = (!_.isEmpty(fields.offer) ? fields.offer : null);
        firestoreDB(this.props.dealersettings).firestore().collection('tradeins').doc(this.state.tradeinID).set(objData, { merge: true })
    }

    saveTradeinField = (name, val) => {


        let objData = {};
        let state = Object.assign({}, this.state.tradeIn);
        if (name !== 'isFav') {
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
        }

        if (name === 'marks' && val) {
            state['isInspection'] = true;
            objData['isInspection'] = true;
        }

        state[name] = val;
        objData[name] = val;
        objData.modifiedBy = state.modifiedBy;
        objData.modifiedDate = state.modifiedDate;
        objData.modifiedFrom = 'web';

        this.setState({ tradeIn: state });

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeins').doc(this.state.tradeinID);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            toast.notify('Tradein updated successfully', {
                duration: 2000,
            });



        }).catch(error => {
            console.error('Error updating enquiries: ', error);
        });

    };

    handleDeleteTIP = e => {
        e.preventDefault();
        const { tipInfo } = this.state;
        const { clientID } = this.state.fields;

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete this appraisal.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                state.isDeleted = true;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc(this.state.tradeinProID);
                updateRef.update({
                    isDeleted: state.isDeleted,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    var objRecord = {
                        id: this.state.tradeinProID,
                        clientid: clientID
                    }

                    this.props.handleRouteRemove(objRecord, 'tradeinPro');
                    toast.notify(`Tradein pro deleted successfully`, {
                        duration: 2000,
                    });
                    this.setState({ fields: state });

                    let logNotes = 'Tradein pro deleted';
                    if (!_.isEmpty(state) && state.displayID)
                        logNotes = 'Tradein pro: ' + state.displayID + ' deleted';

                    let _objLogData = {
                        notes: logNotes,
                        type: 'tradeinPro',
                        subType: 'delete',
                        recordId: this.state.tradeinProID,
                    }
                    _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, this.props.dealersettings);
                    CommonHelper.saveAuditLog(_objLogData)

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });

            }

        });

    }

    handlePipelineClose = async (data, origin) => {
        if (!_.isEmpty(data)) {

            if (!_.isEmpty(origin) || this.isValidForStageUpdate(data.pipeline, data.stage, 'PipelineClose', data)) {
                let state = Object.assign({}, this.state.fields);

                const { dealersettings } = this.state;

                let setttings = dealersettings.client.tradeinPro;

                if (state.clientID && dealersettings && dealersettings.group &&
                    dealersettings.group.clients && dealersettings.group.clients[state.clientID] && dealersettings.group.clients[state.clientID].tradeinPro)
                    setttings = dealersettings.group.clients[state.clientID].tradeinPro;

                let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
                let defaultstages = allPipelines.filter(e => e.value === data.pipeline)[0].stages;
                let statusSettings = allPipelines.filter(e => e.value === data.pipeline)[0].statusSettings;

                let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

                if (state.stage !== data.stage || state.pipeline !== data.pipeline) {
                    let stageHistory = Object.assign([], state.stageHistory);
                    let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
                    if (!_.isEmpty(_stageData)) {
                        stageHistory.push(_stageData);
                        state.stageHistory = Object.assign([], stageHistory);
                        state.pipeline = data.pipeline;
                        state.stage = data.stage;
                        state.stageDate = window.firebase.firestore.Timestamp.now();
                    }
                }
                state.owner = data.owner ? data.owner : state.owner;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc(this.state.tradeinProID);
                let updateObj = {
                    pipeline: state.pipeline,
                    stage: state.stage,
                    owner: state.owner,
                    stageDate: state.stageDate,
                    stageHistory: state.stageHistory,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }

                if (origin === 'fromOpenLead') {
                    updateObj.completedDate = null;
                    updateObj.wonDate = null;
                    updateObj.lostDate = null;
                    updateObj.lostReason = null;
                    updateObj.lostSubReason = null;
                }
                await updateRef.update(updateObj).then((docRef) => {
                    this.setState({
                        fields: state,
                        defaulStages: defaultstages,
                        statusSettings: statusSettings,
                        activeStageIndex: stages.indexOf(data.stage)
                    }, () => { this.errorChange(data.name); });

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }
        this.setState({
            pipelinePopOver: { showpopover: false, targetpopover: '' }
        });
    }

    handleStageChange = (id, name) => {
        //e.preventDefault();

        //const { id, name } = e.target;
        const { pipeline, status } = this.state.fields;

        const { statusSettings } = this.state;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === pipeline && e.stage === name)[0];
        if (_statusMndry && status !== _statusMndry.status) {
            let __stage = this.state.defaulStages.find(x => x.value === name);
            let _status = CommonHelper.getNameByValue(tradeinProAllStatus, _statusMndry.status)
            Swal.fire(`${CommonHelper.showLocale(this.props, 'Please change appraisal status to')} ${_status.toUpperCase()} ${CommonHelper.showLocale(this.props, 'before moving to stage')}: ${__stage ? __stage.name : ''}`, '', 'info')
            return;
        }

        if (this.isValidForStageUpdate(pipeline, name, 'stage')) {
            this.setState({
                activeStageIndex: id
            })
            let state = Object.assign({}, this.state.fields);
            let stageHistory = Object.assign([], state.stageHistory);

            let _stageData = CommonHelper.handleStageHistory(stageHistory, state.pipeline, name);
            if (!_.isEmpty(_stageData)) {
                stageHistory.push(_stageData)
                state.stageHistory = Object.assign([], stageHistory);
                state.stage = name;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
                state.stageDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc(this.state.tradeinProID);
                updateRef.update({
                    stage: state.stage,
                    stageDate: state.stageDate,
                    stageHistory: state.stageHistory,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    this.setState({ fields: state });
                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }

    }

    handleStageComplete = _stage => {
        const { stageHistory, pipeline } = this.state.fields;
        let response = 'active-disable';
        if (!_.isEmpty(stageHistory) &&
            stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0]) {
            let _stageDays = stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0];
            if (!_.isEmpty(_stageDays)) {
                response = 'active';
            }
        }
        return response;
    };

    //#endregion

    //#region MANDATORY CHECK

    getMandatoryFieldsByType = (type, _newStatus) => {
        const { dealersettings } = this.state;
        const { status } = this.state.fields;
        let _mandEnquiryStatus;
        let _response = [];
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.tradeinPro) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields.status))
            _mandEnquiryStatus = dealersettings.client.tradeinPro.mandatoryFields.status;

        if (!_.isEmpty(_mandEnquiryStatus) && (status || _newStatus)) {
            let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === (_newStatus ? _newStatus : status))[0];

            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

            if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type]) {
                _response = _.uniq(_newStatusMandatoryFields[type].split(','));
            }
        }
        else {
            let _newStatusMandatoryFields = [];
            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

            if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type])
                _response = _.uniq(_newStatusMandatoryFields[type].split(','))

        }
        return _response;
    }

    isValidForStatusUpdate = (_newStatus, origin, _previousStatus) => {
        const { dealersettings } = this.state;

        let _mandEnquiryStatus;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.tradeinPro) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields.status))
            _mandEnquiryStatus = dealersettings.client.tradeinPro.mandatoryFields.status;

        if (!_.isEmpty(_mandEnquiryStatus) && _newStatus) {
            let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === _newStatus)[0];

            if (_previousStatus)
                _newStatusMandatoryFields = this.mergeNewAndPreviousStatus(_mandEnquiryStatus, Object.assign({}, _newStatusMandatoryFields), _previousStatus);

            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);
            if (!_.isEmpty(_newStatusMandatoryFields) || ['approve', tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(_newStatus)) {
                _isValid = this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))
            }
        }
        else {
            let _newStatusMandatoryFields = [];

            _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);

            _isValid = this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))

        }

        // if (_newStatus === tipStatus.OPEN && !_isValid)
        // 	this.setState({ isAccessToReOpen: true });

        return _isValid;
    }

    isValidForStageUpdate = (newPipeline, newStage, origin, originValue) => {
        const { pipeline, stage } = this.state.fields;
        const _newStage = newPipeline + '-' + newStage;
        const _oldStage = pipeline + '-' + stage;
        const { dealersettings } = this.state;

        let _mandPiplines;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.tradeinPro) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields.pipeline))
            _mandPiplines = dealersettings.client.tradeinPro.mandatoryFields.pipeline;

        if (!_.isEmpty(_mandPiplines)) {

            let __newPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];
            let __oldPipeline = _mandPiplines.filter(e => e.name === _oldStage)[0];

            if (!_.isEmpty(__newPipeline)) {

                if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                    for (let [key] of Object.entries(fieldsData)) {
                        __newPipeline[key] = ((__newPipeline[key] ? __newPipeline[key] : "") + (__oldPipeline[key] ? (__newPipeline[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                    }
                }
                //	console.log('isValidFor StageUpdate', '==>', _newStage, '==>', _oldStage, '==>', __newPipeline, '==>', __oldPipeline);
                _isValid = this.handleMandatoryPermission(__newPipeline, (origin ? origin : newStage), originValue)
                //	console.log('isValidFor StageUpdate-Response', _isValid);
            }
            else if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                for (let [key] of Object.entries(fieldsData)) {
                    __oldPipeline[key] = (__oldPipeline[key] ? __oldPipeline[key] : "");
                }

                //	console.log('isValidForStageUpdate', '==>', _oldStage, '==>', __oldPipeline);
                _isValid = this.handleMandatoryPermission(__oldPipeline, (origin ? origin : newStage), originValue)
                //	console.log('isValidFor StageUpdate-Response', _isValid);
            }
        }

        return _isValid;
    }
    mergeNewAndPreviousStatus = (_mandEnquiryStatus, _newMandatoryStatus, _prevStatus) => {
        if (!_.isEmpty(_mandEnquiryStatus)) {
            let __oldPipeline = _mandEnquiryStatus.filter(e => e.name === _prevStatus)[0];

            if (!_.isEmpty(__oldPipeline)) {
                for (let [key] of Object.entries(fieldsData)) {
                    _newMandatoryStatus[key] = ((_newMandatoryStatus[key] ? _newMandatoryStatus[key] : "") + (__oldPipeline[key] ? (_newMandatoryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                }
            }
        }
        return _newMandatoryStatus;
    }

    mergeStatusAndStage = (_mandEnquiryStatus, _newStatus) => {

        const { pipeline, stage } = this.state.fields;
        const _newStage = pipeline + '-' + stage;
        const { dealersettings } = this.state;

        let _mandPiplines;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.tradeinPro) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields.pipeline))
            _mandPiplines = dealersettings.client.tradeinPro.mandatoryFields.pipeline;

        if (!_.isEmpty(_mandPiplines)) {
            let __oldPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];

            if (!_.isEmpty(__oldPipeline)) {
                for (let [key] of Object.entries(fieldsData)) {
                    _mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__oldPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                }
            }

            if (_newStatus) {
                const { statusSettings } = this.state;
                let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _newStatus)[0];
                const _newStatusPipelineName = !_.isEmpty(_statusMndry) ? _statusMndry.pipeline + '-' + _statusMndry.stage : null;
                let __newStatusPipeline = !_.isEmpty(_newStatusPipelineName) && _mandPiplines.filter(e => e.name === _newStatusPipelineName)[0];
                if (!_.isEmpty(__newStatusPipeline)) {
                    for (let [key] of Object.entries(fieldsData)) {
                        _mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__newStatusPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __newStatusPipeline[key] : __newStatusPipeline[key]) : ""));
                    }
                }
            }

        }

        return _mandEnquiryStatus;
    }

    handleMoveStageForStatusChange = (_status, state) => {
        const { statusSettings } = this.state;
        const { stageHistory } = this.state.fields;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], stageHistory);
            let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else
            return state;
    }

    //#endregion

    //#region MANDATORY PERMISSION
    handleMandatoryPermission = (objFields, _newStatus, _newStatusValue) => {
        let isBreak = false;


        if (!_.isEmpty(objFields) || ['approve', tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(_newStatus)) {
            const _fieldsData = Object.assign({}, fieldsData);
            for (let [key, value] of Object.entries(objFields)) {
                _fieldsData[key] = value;
            }
            const { contact } = this.state;
            for (let [key, value] of Object.entries(_fieldsData)) {
                if (key === 'contact' && !contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'businessContact' && contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'tradein' && !_.isEmpty(value)) {
                    if (!this.checkMandatoryTradeIn(value)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'tradeinPro' && (!_.isEmpty(value) || ['approve', tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(_newStatus))) {
                    if (!this.checkMandatoryTIP(value, _newStatus, _newStatusValue)) {
                        isBreak = true;
                        break;
                    }
                }

            }
        }

        if (!isBreak && this.state.fields.status === tipStatus.NEW) {
            const tradeinPro_setttings = this.state.dealersettings?.client?.tradeinPro;
            if (tradeinPro_setttings?.mandatoryFields?.tradein && !this.checkMandatoryTradeIn(tradeinPro_setttings?.mandatoryFields?.tradein)) {
                isBreak = true;
            }
        }

        //console.log('handleMandatoryPermission', !isBreak, objFields, '==>', _newStatus)
        if (isBreak)
            return false;
        else
            return true;
    }

    checkMandatoryContact = value => {
        const { isContact } = this.state.fields;

        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];

        //console.log('checkMandatoryContact', isContact, _mandatoryFields)
        if (!isContact && !_.isEmpty(_mandatoryFields)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleContactOpen('addContact', _mandatoryFields);
                }
            });
            // <SweetAlert title="Please add contact" onConfirm={this.handleContactOpen('addContact', _mandatoryFields)}  />
            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            const ObjCustomer = Object.assign({}, this.state.contact);
            let msg;
            _mandatoryFields.some((el, index) => {
                if (el.trim() === 'licenseFrontURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license front image';
                        return true;
                    }
                } else if (el.trim() === 'licenseBackURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license back image';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemail') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                        msg = 'Please enter Phone or Email';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemailORlicenseNo') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                        msg = 'Please enter Phone or Email or License No.';
                        return true;
                    }
                } else if (!ObjCustomer[el]) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });
            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleContactOpen('addContact', _mandatoryFields);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryTradeIn = value => {
        const { isTradein } = this.state.fields;

        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];

        //console.log('checkMandatoryContact', isContact, _mandatoryFields)
        if (!isTradein) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add tradein'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleTradeinOpen('addContact', _mandatoryFields);
                }
            });
            // <SweetAlert title="Please add contact" onConfirm={this.handleContactOpen('addContact', _mandatoryFields)}  />
            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            const fields = Object.assign({}, this.state.tradeIn);

            const { dealersettings } = this.props;
            let tradeinOptionsDF = Object.assign([], dealersettings?.client?.settings?.tradeinOptionsDF);
            let dynamicDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.tradeinOptions))
                ? dealersettings.client.tradeinOptions :
                Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
            dynamicDetailList = _.union(dynamicDetailList, ['images', 'sidePhotos', 'marks'])

            let msg;
            _mandatoryFields.some((key, index) => {
                if (dynamicDetailList.includes(key)) {
                    if (objTradeIn.hasOwnProperty(key) && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                        if (['images'].includes(key)) {
                            if (_.isEmpty(CommonHelper.bindAllImages(fields.images, fields.sidePhotos))) {
                                msg = 'Please enter ' + key;
                                return true;
                            }
                        }
                        else {
                            msg = 'Please enter ' + key;
                            return true;
                        }
                    }
                    else if ((!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                        (_.isEmpty(fields.dynamicFields) ||
                            (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                        )) {
                        msg = 'Please enter ' + key;
                        return true;
                    }
                }
                // if (!ObjCustomer[el]) {
                //     msg = 'Please enter ' + el;
                //     return true;
                // }
            });
            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in tradein'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleTradeinOpen('addContact', _mandatoryFields);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryTIP = (value, _newStatus, _newStatusValue) => {
        let _mandatoryFields = !_.isEmpty(value) ? _.uniq(value.split(',')) : [];

        if (!_.isEmpty(this.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.mandatoryFields);

        if (this.state.dealersettings?.client?.clientSettings?.managerWorkflow) {
            if (['approve', tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(_newStatus)) _mandatoryFields.push('valuerPrice');
            if ([tipStatus.WON, tipStatus.LOST].includes(_newStatus)) _mandatoryFields.push('finalPrice');
        }

        if (!_.isEmpty(_mandatoryFields)) {
            const objTradeIP = Object.assign({}, this.state.fields);
            let msg;


            let _isbreak = false;

            let _specialKey = ['isContact', 'isTradein', 'isFiles']

            let _requiredObject = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) >= 0));

            let _requiredFields = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) < 0));

            //let _recommRequiredList = this.state.recommendations.filter(item => item.choice === 'Required')
            //console.log('checkMandatoryTIP', _newStatus, value, _mandatoryFields, this.mandatoryFields, _requiredObject, _requiredFields)
            !_.isEmpty(_requiredObject) && _requiredObject.some((el, index) => {
                if (el.trim() === 'isContact') {
                    if (!objTradeIP[el] && _.isEmpty(objTradeIP['contact'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add contact'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleContactOpen('addContact');
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isTradein') {
                    if (!objTradeIP[el] && _.isEmpty(objTradeIP['tradein'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add tradein'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    this.handleTradeinOpen();
                                }, 200);

                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isInspection') {
                    if (!objTradeIP[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add inspection'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    this.setState({ showCanvas: true })
                                }, 200);

                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'notes') {
                    // if (_.isEmpty(objTradeIP[el])) {
                    //     Swal.fire({
                    //         title: 'Please add notes',
                    //         icon: 'info',
                    //         showCancelButton: true,
                    //         confirmButtonText: 'Add Now',
                    //         cancelButtonText: 'Later',
                    //     }).then(result => {
                    //         if (result.value) {
                    //             setTimeout(() => {
                    //                 this.setState({ showNotes: true })
                    //             }, 200);

                    //         }
                    //     });
                    //     _isbreak = true;
                    //     return true;
                    // }
                } else if (el.trim() === 'isFiles') {
                    if (!objTradeIP[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add file'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    this.isValidForFile();
                                }, 200);

                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                }
            });

            if (_isbreak)
                return false;

            !_.isEmpty(_requiredFields) && _requiredFields.some((el, index) => {
                if (!objTradeIP[el]) {
                    if (objTradeIP.hasOwnProperty(el)) {
                        msg = 'Please enter ' + el;
                        return true;
                    }
                    else if (
                        (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === el && m.active === true)[0])) &&
                        (_.isEmpty(objTradeIP.dynamicFields) ||
                            (!_.isEmpty(objTradeIP.dynamicFields) && _.isEmpty(objTradeIP.dynamicFields[el]) && !_.isBoolean(objTradeIP.dynamicFields[el]) && !_.isNumber(objTradeIP.dynamicFields[el]))
                        )
                    ) {
                        msg = 'Please enter ' + el;
                        return true;
                    }
                }
            });

            if (msg) {

                let errors = {};
                let formIsValid = true;
                let errorClass = 'input_error';

                _requiredFields.forEach((key, index) => {
                    if (!objTradeIP[key] && _requiredFields.indexOf(key) >= 0) {
                        if (objTradeIP.hasOwnProperty(key)) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                        else if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                            (_.isEmpty(objTradeIP.dynamicFields) ||
                                (!_.isEmpty(objTradeIP.dynamicFields) && _.isEmpty(objTradeIP.dynamicFields[key]) && !_.isBoolean(objTradeIP.dynamicFields[key]) && !_.isNumber(objTradeIP.dynamicFields[key]))
                            )) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                });

                if (formIsValid === true) {
                    return true;
                } else {

                    this.setState({
                        enquiryModal: {
                            show: true,
                            errors: errors,
                            newStatus: _newStatus,
                            newStatusValue: _newStatusValue,
                            ismodifyStatusDate: false,
                            mandatoryFields: _requiredFields
                        },
                    });
                    return formIsValid;
                }

            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }
    //#endregion 

    //#region RE-AUTHENTICATION
    handleReAuthOpen = () => {
        this.setState({ showReAuthentication: true });
    }

    handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            this.forceUpdate();
        }
        this.setState({ showReAuthentication: false });
    }

    handleSaveAuditLog = (imagePath) => {
        const { documentID } = this.state.contact;
        const { dealersettings } = this.state;
        let _name = CommonHelper.getFullNameWithID(null, this.state.contact, '');
        let _objLogData = {
            notes: 'License image viewed for ' + _name,
            type: 'licenseImage',
            subType: 'contactDetails',
            recordId: documentID ? documentID : null,
            contactID: documentID ? documentID : null,
            imageURL: imagePath,
        }

        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
        CommonHelper.saveAuditLog(_objLogData);
    }
    //#endregion    

    //#region INSPECTION

    handleDamageClose = () => {
        this.setState({ showDamage: false })
    }

    handleCanvasClose = (marks) => {
        this.setState({ showCanvas: false })
        let state = Object.assign({}, this.state.tradeIn);
        if (!_.isEmpty(marks) || (!_.isEmpty(state.marks) && _.isEmpty(marks))) {
            this.saveTradeinField('marks', marks);

            if (JSON.stringify(Object.assign([], this.state.tradeIn.marks)) !== JSON.stringify(marks)) {
                this.getInspectionSnapshot(marks);
            }

        }
    }

    getInspectionSnapshot = async (_marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        var context = canvas.getContext('2d');

        var img = new Image();
        const { dealersettings } = this.state;
        if (dealersettings?.client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        img.onload = async (e) => {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await this.asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await this.loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            if (dataURL)
                this.saveInspectionToStorage(dataURL);

            return dataURL;
        }
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    saveInspectionToStorage = (imageBase64) => {
        this.setState({ isLoadingInspection: true })
        const { tradeinproid, dealersettings } = this.props;
        const { tradeinID } = this.state;
        let clientID = dealersettings ? dealersettings.client.id : '';
        let _tradeinid = tradeinID ? tradeinID : tradeinproid;
        var storageRef = storageBucket(dealersettings).ref(`${clientID}/tradeins/${_tradeinid}/inspections/snapshot.png`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(dealersettings).ref(`${clientID}/tradeins/${_tradeinid}/inspections`).child('snapshot.png').getDownloadURL()
                    .then(dataURL => {
                        //console.log(dataURL);
                        this.setState({ isLoadingInspection: false })
                        this.saveTradeinField('inspectionSnapshot', dataURL);
                    })
            })
    };
    //#endregion

    //#region IMAGE UPLOAD

    saveBaseImageToStorage = async (imageBase64, id, ext, fileID) => {

        let uid = moment()._d.getTime().toString() + '.' + ext;
        const { tradeinproid, dealersettings } = this.props;
        const { tradeinID } = this.state;
        let clientID = dealersettings ? dealersettings.client.id : '';
        let _tradeinid = tradeinID ? tradeinID : tradeinproid;
        let promises = [];
        let storageRef = storageBucket(dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/${uid}`);
        promises.push(
            storageRef.putString(imageBase64, 'data_url').then((snapshot) => {
                return snapshot.ref.getDownloadURL()
            })
        );
        const snapshots = await Promise.all(promises)
        let state = Object.assign({}, this.state.tradeIn);
        if (fileID) {
            let sidePhotos = Object.assign({}, state.sidePhotos);
            let imageLoader = Object.assign({}, this.state.imageLoader)

            sidePhotos[fileID] = !_.isEmpty(snapshots) ? snapshots[0] : null;
            this.saveTradeinField('sidePhotos', sidePhotos);

            imageLoader[fileID] = null;
            this.setState({ imageLoader: imageLoader })
        }
        else {
            let fileURLS = Object.assign([], state[id]);
            if (!_.isEmpty(snapshots)) fileURLS.push(snapshots[0]);
            this.saveTradeinField(id, Object.assign([], fileURLS));
            this.setState({ imageURL: null })
        }

        if (id && document.getElementById(id))
            document.getElementById(id).value = "";


    };

    saveImagesToStorage = async (filez, id, fileID) => {
        let promises = [];

        let files = [];
        for (let [key, file] of Object.entries(filez)) {
            files.push(file);
        }
        if (fileID) {
            let imageLoader = Object.assign({}, this.state.imageLoader)
            imageLoader[fileID] = fileID;
            this.setState({ imageLoader: imageLoader });
        }
        else
            this.setState({ imageURLs: files });

        const { tradeinproid, dealersettings } = this.props;
        const { tradeinID } = this.state;
        let clientID = dealersettings ? dealersettings.client.id : '';
        let _tradeinid = tradeinID ? tradeinID : tradeinproid;

        files.forEach((file, index) => {
            let uid = moment()._d.getTime().toString() + index + '.' + file.name.substr((file.name.lastIndexOf('.') + 1));
            let storageRef = storageBucket(dealersettings).ref(`${clientID}/tradeins/${_tradeinid}/${uid}`);
            promises.push(
                storageRef.put(file).then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
            );
        });
        const snapshots = await Promise.all(promises)
        let state = Object.assign({}, this.state.tradeIn);
        let fileURLS = Object.assign([], state.images);
        let sidePhotos = Object.assign({}, state.sidePhotos);
        let imageLoader = Object.assign({}, this.state.imageLoader)

        if (fileID) {
            sidePhotos[fileID] = snapshots ? snapshots[0] : null;

            imageLoader[fileID] = null;
            this.setState({ imageLoader: imageLoader })
            this.saveTradeinField('sidePhotos', sidePhotos);
        }
        else {
            snapshots.forEach(url => {
                fileURLS.push(url);
            })
            this.setState({ imageURLs: null })
            this.saveTradeinField(id, fileURLS)
        }

        if (id && document.getElementById(id))
            document.getElementById(id).value = "";
    }


    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.tradeIn);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                this.saveTradeinField('images', Object.assign([], _data));
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }
    handleSideImageDel = (fileID) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {

                let state = Object.assign({}, this.state.tradeIn);
                let sidePhotos = Object.assign({}, state.sidePhotos);

                if (fileID)
                    sidePhotos[fileID] = null;

                this.saveTradeinField('sidePhotos', Object.assign({}, sidePhotos));

                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {

        const { src, id, ext, fileID } = cropShow;

        this.saveBaseImageToStorage(src, id, ext, fileID);

        if (fileID) {
            let imageLoader = Object.assign({}, this.state.imageLoader)
            imageLoader[fileID] = fileID;
            this.setState({
                imageLoader: imageLoader,
                cropShow: { show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '', fileID: null }
            });
        }
        else {
            this.setState({
                imageURL: src,
                cropShow: { show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '', fileID: null }
            });
        }
    }

    onSelectFile = async (e, type, id, title, fileID) => {

        const files = e.target.files;
        if (files && files.length > 1) {
            const imageFile = []
            if (fileID) {
                let imageLoader = Object.assign({}, this.state.imageLoader)
                imageLoader[fileID] = fileID;
                this.setState({ imageLoader: imageLoader });
            }
            else
                this.setState({ imageURLs: Object.assign([], files) });

            for (let [key, file] of Object.entries(files)) {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Maximum size of each file is 10MB'), '', 'info')
                    return;
                }

                if (file.name.toLowerCase().includes('.heic')) {
                    let _file = await CommonHelper.heic2Image(file);
                    imageFile.push(_file)
                } else if (filesize > 1) {
                    //console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

                    const options = {
                        maxSizeMB: 0.5,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    try {
                        const compressedFile = await imageCompression(file, options);
                        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                        imageFile.push(compressedFile)

                    } catch (error) {
                        console.log(error);
                    }
                }
                else
                    imageFile.push(file)
            }

            this.saveImagesToStorage(imageFile, id, fileID)

        } else if (files && files.length === 1) {

            var _size = files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = files[0];
            if (file.name.toLowerCase().includes('.heic')) {
                let imageLoader = Object.assign({}, this.state.imageLoader)
                if (fileID) {
                    imageLoader[fileID] = fileID;
                    this.setState({ imageLoader: imageLoader });
                }
                else {
                    this.setState({ imageURL: _images.novehicle });
                }
                file = await CommonHelper.heic2Image(file);
                imageLoader[fileID] = null;
                this.setState({
                    imageURL: null,
                    imageLoader: imageLoader,
                })
            }
            reader.onloadend = () => {
                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title,
                        fileID: fileID
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }
    //#endregion

    //#region SHARE CHANGES
    handleSharePanelOpen = (e) => {
        e.preventDefault();
        localStorage.removeItem('showChat');
        this.setState({
            showSharePanel: {
                chatClsActive: 'active',
                chatClsName: 'sidebar',
            },
        });
    };

    handleSharePanelClose = () => {
        this.setState({
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            },
        });
    };
    //#endregion

    //#region FILE CHANGES

    handleFileClose = (ID) => {
        if (ID) {
            let state = Object.assign({}, this.state.fields);
            if (!state.isFiles) {
                state.isFiles = true;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc(this.state.tradeinProID);
                updateRef.update({
                    isFiles: state.isFiles,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    this.setState({ fields: state });

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }

        this.setState({
            fileModal: {
                show: false,
            },
        });
    }

    handleFileOpen = () => {
        this.setState({
            fileModal: {
                show: true,
            },
        });
    }

    isValidForFile = () => {
        const { isContact } = this.state.fields;
        let _manFields = this.getMandatoryFieldsByType('contact');
        let _manFieldsTIP = this.getMandatoryFieldsByType('tradeinPro');
        if (!isContact && (!_.isEmpty(_manFields) || _manFieldsTIP?.includes('isContact'))) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then((result) => {
                if (result.value) {
                    this.handleContactOpen('addContact');

                }
            });
            return false;
        }
        else {
            this.handleFileOpen();
            return true;
        }
    }
    //#endregion

    //#region  QUICK VIEW TIP
    sidepanelTIPOpen = id => {
        const { tradeinProID } = this.state;

        if (tradeinProID !== id)
            this.props.history.push('/tradeinpro/details/' + id);

        // this.setState({
        //     showTIPpanel: {
        //         clsActive: 'active',
        //         clsName: 'sidebar-quickview',
        //         tradeinproid: id,
        //     },
        // });


    };

    sidepanelTIPClose = () => {

        // this.setState({
        //     showTIPpanel: {
        //         clsActive: '',
        //         clsName: 'sidebar-quickview',
        //         tradeinproid: 0,
        //     },
        // });
    };
    //#endregion


    //#region VIDEO CHANGES

    handleVideoClose = (ID) => {
        if (ID) {
            let state = Object.assign({}, this.state.tradeIn);
            if (!state.isVideo) {
                this.saveTradeinField('isVideo', true);
            }
        }

        this.setState({
            videoModal: {
                show: false,
                recordID: null
            }
        });
    }

    handleVideoOpen = (ID) => {
        this.setState({
            videoModal: {
                show: true,
                recordID: ID
            }
        });
    }

    deleteVideo = (fileID) => {

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this video.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                const { dealersettings } = this.props;
                let modifiedBy = dealersettings ? dealersettings.id : '';
                let modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(dealersettings).firestore().collection(`tradeins/${this.state.tradeinID}/videos`).doc(fileID)
                updateRef.
                    set({
                        isDeleted: true,
                        modifiedBy,
                        modifiedDate
                    }, { merge: true })
                    .then((docRef) => {
                        toast.notify('Video successfully deleted.', {
                            duration: 2000
                        });
                    }).catch((error) => {
                        console.error("Error deleting Video: ", error);
                    });

            }
        })

    }
    //#endregion 

    //#region NOTE CHANGES
    handleNoteClose = (ID) => {
        // if (ID) {
        //     let state = Object.assign({}, this.state.tradeIn);
        //     if (!state.isVideo) {
        //         this.saveTradeinField('isVideo', true);
        //     }
        // }

        this.setState({
            notesModal: {
                show: false,
                recordID: null
            }
        });
    }

    handleNoteOpen = (ID) => {
        this.setState({
            notesModal: {
                show: true,
                recordID: ID
            }
        });
    }

    //#endregion 

    //#region NOTE CHANGES
    handleLogClose = (ID) => {
        // if (ID) {
        //     let state = Object.assign({}, this.state.tradeIn);
        //     if (!state.isVideo) {
        //         this.saveTradeinField('isVideo', true);
        //     }
        // }

        this.setState({
            showPriceLogs: false,
            logsModal: {
                show: false,
                recordID: null
            }
        });
    }

    handleLogOpen = (ID) => {
        this.setState({
            logsModal: {
                show: true,
                recordID: ID
            }
        });
    }

    handlePriceLogOpen = () => {
        this.setState({
            showPriceLogs: true
        });
    }

    //#endregion 

    getUserNamebyId = (id) => {
        const { groupUsers, clientUsers } = this.props;
        return CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), id)
    }

    handleEvaluationClose = (userIds) => {
        this.setState({
            showEvaluations: false,
            showSelfEvaluations: false,
            showReEvaluations: false
        });
    }

    handleOfferCheckChange = (e, name, data) => {
        const { checked } = e.target;
        //console.log('handleOfferCheckChange', name, checked, data)
        let state = Object.assign({});
        if (checked)
            state = data;
        this.setState({ selectedOffer: state });

    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        this.saveTradeinProField(name, checked);
        setTimeout(() => this.errorChange(name), 500);
    }

    cancelofferPriceField = (_field, defaultVaue) => {
        let name = _field.split('_')[0];
        let recommID = _field.split('_')[1];
        //console.log('cancelofferPriceField', name, recommID, defaultVaue)
        const _recommendations = Object.assign([], this.state.tipOffers);

        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === recommID) {
                obj[name] = defaultVaue ? defaultVaue : 0;
            }
        });
        this.setState({ tipOffers: _recommendations, editPrice: null, putPrice: null, editEvaluationPricePrice: null });
    }

    saveofferPriceField = (_field, val, defaultVaue) => {
        let name = _field.split('_')[0];
        let recommID = _field.split('_')[1];

        const { dealersettings } = this.state;
        const { tradeinProID, fields } = this.state;
        const { wholesalers } = this.props.dealersettings;
        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        //console.log('saveofferPriceField', name, recommID, val)
        let _this = this;
        const _recommendations = Object.assign([], this.state.tipOffers);
        let _offerData;
        let _msg = ''
        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === recommID) {

                if (name === 'dealerPrice') {
                    let _defaultVal = defaultVaue ? defaultVaue : (obj[name] ? obj[name] : obj['evaluationPrice'] ? obj['evaluationPrice'] : 0)

                    if (obj.isSelfEvaluation)
                        _msg = `Updated ${_this.getUserNamebyId(obj.addedBy)}'s self-evaluation price from ${CommonHelper.formatCurrency(currencySymbol, _defaultVal)} to ${CommonHelper.formatCurrency(currencySymbol, val ? val : 0)}`
                    else if (obj.wholeSellerId) {
                        _msg = `Updated ${CommonHelper.getUserNamebyId(wholesalers, obj.wholeSellerId, '')}'s price from ${CommonHelper.formatCurrency(currencySymbol, _defaultVal)} to ${CommonHelper.formatCurrency(currencySymbol, val ? val : 0)}`
                    }
                }

                if (name === 'evaluationPrice') {
                    let _currentUser = localStorage.uid;
                    let _currentDate = window.firebase.firestore.Timestamp.now();

                    obj.modifiedBy = _currentUser;
                    obj.modifiedDate = _currentDate;
                    obj.evaluatedBy = _currentUser;
                    obj.evaluationDate = _currentDate;
                    obj.onBehalfUserID = _currentUser;
                    obj.isSelfEvaluation = false;
                    obj.dealerPrice = val;
                    obj.status = tipOfferStatus.COMPLETED;

                    _msg = `Confirmed the offer price ${CommonHelper.formatCurrency(currencySymbol, val ? val : 0)} on behalf of ${CommonHelper.getUserNamebyId(wholesalers, obj.wholeSellerId, '')}.`
                }


                obj[name] = val ? val : 0;
                _offerData = Object.assign({}, obj);
            }

        });
        this.setState({ tipOffers: _recommendations, editPrice: null, putPrice: null });

        if (!_.isEmpty(_offerData))
            this.handleSaveOffers(_offerData);

        if (name === 'evaluationPrice' && (fields.status === tipStatus.NEW || fields.status === tipStatus.INPROGRESS)) {
            this.saveTradeinProField('status', tipStatus.PENDING);
        }

        if (name === 'dealerPrice' && fields.status === tipStatus.COMPLETED && !_.isEmpty(fields.offer) && !_.isEmpty(_offerData) && fields.offer?.documentID === _offerData?.documentID) {
            this.updateTradeinFieldOfferPrice(val)
        }

        if (_msg) {
            let _objLogData = {
                tradeinProID: tradeinProID,
                offerID: _offerData.documentID,
                projectId: _offerData?.projectId ? _offerData?.projectId : null,
                message: _msg
            }

            CommonHelper.saveTradeInProLog(dealersettings, _objLogData);
        }

    }

    saveofferEvalPriceField = (_field, val, defaultVaue) => {
        let name = _field.split('_')[0];
        let recommID = _field.split('_')[1];

        const { dealersettings } = this.state;
        const { tradeinProID, fields } = this.state;
        const { wholesalers } = this.props.dealersettings;
        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        //console.log('saveofferPriceField', name, recommID, val)
        let _this = this;
        const _recommendations = Object.assign([], this.state.tipOffers);
        let _offerData;
        let _msg = ''
        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === recommID) {

                if (name === 'evaluationPrice') {
                    let _currentUser = localStorage.uid;
                    let _currentDate = window.firebase.firestore.Timestamp.now();

                    obj.modifiedBy = _currentUser;
                    obj.modifiedDate = _currentDate;

                    let _defaultVal = defaultVaue ? defaultVaue : obj['evaluationPrice'] ? obj['evaluationPrice'] : 0
                    _msg = `Updated ${_this.getUserNamebyId(obj.addedBy)}'s evaluation price from ${CommonHelper.formatCurrency(currencySymbol, _defaultVal)} to ${CommonHelper.formatCurrency(currencySymbol, val ? val : 0)}`

                }

                obj[name] = val ? val : 0;
                _offerData = Object.assign({}, obj);
            }

        });
        this.setState({ tipOffers: _recommendations, editEvaluationPrice: null });

        if (!_.isEmpty(_offerData))
            this.handleSaveOffers(_offerData);

        if (_msg) {
            let _objLogData = {
                tradeinProID: tradeinProID,
                offerID: _offerData.documentID,
                projectId: _offerData?.projectId ? _offerData?.projectId : null,
                message: _msg
            }
            CommonHelper.saveTradeInProLog(dealersettings, _objLogData);
        }

    }

    onCostChange = (name, recommID, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        const _recommendations = Object.assign([], this.state.tipOffers);

        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === recommID) {
                obj[name] = _.isNumber(e.floatValue) && e.floatValue ? e.floatValue : null;
            }
        });
        this.setState({ tipOffers: _recommendations });
    }

    handleSaveOffers = (_offerData, noNotify) => {
        const { tradeinproid, dealersettings } = this.props;
        const { selectedOffer, fields } = this.state;
        //Remove empty value from object
        for (var propName in _offerData) {
            if (_offerData[propName] === undefined || _offerData[propName] === '' || (_.isObject(_offerData[propName]) && _.isEmpty(_offerData[propName]))) {
                delete _offerData[propName];
            }
        }
        if (!_offerData.projectId) _offerData.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
        if (_offerData.documentID) {

            if (!_.isEmpty(fields.offer) && fields.offer.documentID === _offerData.documentID) {
                this.setState({ selectedOffer: _offerData })
                this.saveTradeinProField('offer', _offerData);
            }

            const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`tradeinPro/${tradeinproid}/offers/${_offerData.documentID}`);
            updateRef.set(_offerData, { merge: true }).then((docRef) => {
                if (!noNotify)
                    toast.notify('Offer updated successfully', { duration: 2000 })
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }

    }

    handleUndoPreviousOffers = (_offerID) => {
        const { tradeinproid, dealersettings } = this.props;
        const { tipOffers, fields } = this.state;
        if (_offerID && _.find(tipOffers, { documentID: _offerID })) {
            let _offerData = Object.assign({}, _.find(tipOffers, { documentID: _offerID }));

            _offerData.approvedBy = null;
            _offerData.approvedDate = null;
            _offerData.approvedFrom = null;
            _offerData.isApproved = false;
            _offerData.selectedBy = null;
            _offerData.selectedDate = null;
            _offerData.selectedFrom = null;
            _offerData.isSelected = false;
            _offerData.status = tipOfferStatus.COMPLETED;
            if (!_offerData.projectId) _offerData.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
            //Remove empty value from object
            Object.keys(_offerData).forEach(key => _offerData[key] === undefined ? delete _offerData[key] : {});

            if (_offerData.documentID) {
                const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`tradeinPro/${tradeinproid}/offers/${_offerData.documentID}`);
                updateRef.set(_offerData, { merge: true });
            }
        }


    }

    handleDeleteTIPOffer = (_offerID, offer) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete this offer.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                this.deleteOffer(_offerID, offer);
            }
        });
    }

    deleteOffer = async (_offerID, offer) => {
        const { tradeinproid, dealersettings } = this.props;
        const { fields, tipOfferLogs, tipOfferNotes, selectedOffer } = this.state;

        const batch = firestoreDB(dealersettings).firestore().batch();
        batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}/offers/${_offerID}`), { isDeleted: true }, { merge: true });

        tipOfferLogs && tipOfferLogs.filter(e => e.offerID === _offerID).forEach((doc) => {
            if (doc.documentID)
                batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}/offerLogs/${doc.documentID}`), { isDeleted: true }, { merge: true });
        });

        tipOfferNotes && tipOfferNotes.filter(e => e.offerID === _offerID).forEach((doc) => {
            if (doc.documentID)
                batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}/offerNotes/${doc.documentID}`), { isDeleted: true }, { merge: true });
        });

        batch.commit().then(result => {
            toast.notify(`Offer deleted successfully.`, {
                duration: 2000
            })
            if (!_.isEmpty(selectedOffer) && selectedOffer.documentID === _offerID) this.setState({ selectedOffer: null })
            CommonHelper.saveChangeLog(fields.clientID, null, tradeinproid, `${this.getUserNamebyId(offer?.addedBy)}'s self-evaluation has been deleted.`, 'tradeinPro', 'updated');
        });
    }

    isValidForRequest = () => {
        const { status, pipeline, stage } = this.state.fields;
        if (status === tipStatus.NEW ? this.isValidForStatusUpdate(tipStatus.INPROGRESS, 'request') : this.isValidForStageUpdate(pipeline, stage, 'request')) {
            this.handleExistingAppraisalSearch('request');
        }

    };
    handleShowEvaluation = () => {
        this.setState({ showEvaluations: true });
    }

    isValidForSelfRequest = () => {
        const { dealersettings } = this.state;
        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);
        const approveOfferAccess = ((!_.isEmpty(_permissions) && _permissions.approveOfferAccess) ? true : false);

        if (this.isValidForStatusUpdate((tipStatus.PENDING), 'selfRequest')) {
            this.handleExistingAppraisalSearch('selfRequest');
        }

    };

    handleShowSelfEvaluation = () => {
        this.setState({ showSelfEvaluations: true });
    }

    handleExistingAppraisalSearch = async (_origin) => {
        const { dealersettings } = this.props;
        const { tradeIn, tradeinProID } = this.state;
        let _keywords = [];
        if (tradeIn.regNo) _keywords.push(tradeIn.regNo.toLowerCase());
        if (tradeIn.chassisNo) _keywords.push(tradeIn.chassisNo.toLowerCase());
        if (!_.isEmpty(_keywords)) {
            let exists = false;
            const checkExist = firestoreDB(dealersettings).firestore().collection('tradeinPro')
                .where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
                .where('keywords', 'array-contains-any', _keywords)
                .where('isDeleted', '==', false).limit(10).get()
                .then(snapshot => {
                    if (tradeinProID) {
                        snapshot.forEach(doc => {
                            const _enq = doc.data();
                            if (tradeinProID !== doc.id && _enq.status !== tipStatus.LOST && (!_.isEmpty(_enq.offer) || _enq.status === tipStatus.PENDING) && ((_enq?.tradein?.regNo && _keywords.includes(_enq?.tradein?.regNo.toLowerCase())) || (_enq?.tradein?.chassisNo && _keywords.includes(_enq?.tradein?.chassisNo.toLowerCase())))) {
                                exists = true;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkExist]);
            if (checkQuery) {
                this.handleExist(_keywords, _origin)
            }
            else {
                this.showByOrigin(_origin);
            }
        }
        else {
            this.showByOrigin(_origin);
        }
    }

    handleExist = (_keywords, _origin) => {
        Swal.fire({
            title: 'Appraisal Exists!',
            html: 'There are existing appraisals corresponding to the Registration / Chassis Number.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#545a6d',
            confirmButtonText: CommonHelper.showLocale(this.props, 'View Appraisal(s)'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Continue'),
            //reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                this.setState({ showExisting: { show: true, keywords: _keywords } })
            }
            else {
                this.showByOrigin(_origin);
            }
        });

    }

    showByOrigin = (_origin) => {

        if (_origin === 'selfRequest') this.handleShowSelfEvaluation();
        else if (_origin === 'request') this.handleShowEvaluation();

    }

    isValidForOffers = () => {
        const { isTradein, isContact } = this.state.fields;

        if (!isContact) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleContactOpen('addContact');
                }
            });
            return false;
        }
        else if (!isTradein) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add tradein'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleTradeinOpen('addContact');
                }
            });
            return false;
        }
        else return true;
    }

    isValidForReRequest = () => {
        this.setState({
            showReEvaluations: true
        });
    };

    handleSelectOffer = () => {
        const { selectedOffer } = this.state;
        if (_.isEmpty(selectedOffer)) {
            this.setState({ activeTab: 'offers' })
            Swal.fire(CommonHelper.showLocale(this.props, 'Please select one final offer for approval'), '', 'info')
            return;
        }
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to send this selected offer for approval`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            if (result.value) {
                this.saveApprovedOffer(false, selectedOffer)
            }

        });

    }

    handleApproveOffer = () => {
        const { selectedOffer, tipOffers } = this.state;
        const { offer } = this.state.fields;

        let _selectedOffer = !_.isEmpty(selectedOffer) ? selectedOffer : offer
        if (!_.isEmpty(_selectedOffer) && _.find(tipOffers, { documentID: _selectedOffer.documentID }))
            _selectedOffer = _.find(tipOffers, { documentID: _selectedOffer.documentID });

        if (_.isEmpty(_selectedOffer)) {
            this.setState({ activeTab: 'offers' })
            Swal.fire(CommonHelper.showLocale(this.props, 'Please select one final offer to approve'), '', 'info')
            return;
        }

        if (!_selectedOffer.dealerPrice && !_selectedOffer.evaluationPrice) {
            this.setState({ activeTab: 'offers' })
            Swal.fire(CommonHelper.showLocale(this.props, 'Enter dealer price for selected offer'), '', 'info')
            return;
        }

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to approve the selected offer`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            if (result.value) {
                this.isValidApprovedOffer(true, _selectedOffer)
            }

        });


    }

    isValidApprovedOffer = () => {
        if (this.isValidForStatusUpdate(tipStatus.COMPLETED, 'approve')) {
            const { selectedOffer } = this.state;
            const { offer } = this.state.fields;

            let _selectedOffer = !_.isEmpty(selectedOffer) ? selectedOffer : offer
            this.saveApprovedOffer(true, _selectedOffer)
        }

    }

    saveApprovedOffer = (isApproved, selectedOffer, _status) => {
        let fields = Object.assign({}, selectedOffer);

        // if (fields['tradeinPro'])
        //     delete fields['tradeinPro']
        const { tradeinProID, dealersettings } = this.state;
        const { offer } = this.state.fields;
        const { wholesalers } = this.props.dealersettings;
        const managerWorkflow = dealersettings?.client?.clientSettings?.managerWorkflow ? true : false;
        let _currentUser = dealersettings ? dealersettings.id : '';
        let _currentDate = window.firebase.firestore.Timestamp.now();
        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        if (!fields.dealerPrice)
            fields.dealerPrice = fields.evaluationPrice ? fields.evaluationPrice : 0;

        let _previousOfferID = null;
        let _msg = [];
        if (isApproved) {
            if (!_.isEmpty(offer) && offer?.documentID !== fields.documentID)
                _previousOfferID = offer?.documentID;
            fields.approvedBy = _currentUser;
            fields.approvedDate = _currentDate;
            fields.approvedFrom = 'web';
            fields.isApproved = true;

            if (!fields.isSelected) {
                fields.selectedBy = _currentUser;
                fields.selectedDate = _currentDate;
                fields.selectedFrom = 'web';
                fields.isSelected = true;

                if (managerWorkflow) {
                    if (fields.isSelfEvaluation)
                        _msg.push(`${this.getUserNamebyId(fields.addedBy)}'s self-evaluation has been confirmed.`)
                    else if (fields.wholeSellerId) {
                        _msg.push(`${CommonHelper.getUserNamebyId(wholesalers, fields.wholeSellerId, '')}'s evaluation has been confirmed.`);
                    }
                }
                else {
                    if (fields.isSelfEvaluation)
                        _msg.push(`${this.getUserNamebyId(fields.addedBy)}'s self-evaluation has been selected, presenting an offer of ${CommonHelper.formatCurrency(currencySymbol, fields.dealerPrice)}.`)
                    else if (fields.wholeSellerId) {
                        _msg.push(`${CommonHelper.getUserNamebyId(wholesalers, fields.wholeSellerId, '')}'s evaluation has been selected, presenting an offer of ${CommonHelper.formatCurrency(currencySymbol, fields.dealerPrice)}.`);
                    }
                }
            }
            if (!managerWorkflow)
                _msg.push(`The evaluation has been approved, setting the final price at ${CommonHelper.formatCurrency(currencySymbol, fields.dealerPrice)}.`)
        }
        else if (_status) {
            fields.status = _status;
            fields.approvedBy = null;
            fields.approvedDate = null;
            fields.approvedFrom = null;
            fields.isApproved = false;

            _msg.push(`The appraisal process has been reopened for further consideration.`)
        }
        else {
            fields.selectedBy = _currentUser;
            fields.selectedDate = _currentDate;
            fields.selectedFrom = 'web';
            fields.isSelected = true;

            if (fields.isSelfEvaluation)
                _msg.push(`${this.getUserNamebyId(fields.addedBy)}'s self-evaluation has been selected, presenting an offer of ${CommonHelper.formatCurrency(currencySymbol, fields.dealerPrice)}.`)
            else if (fields.wholeSellerId) {
                _msg.push(`${CommonHelper.getUserNamebyId(wholesalers, fields.wholeSellerId, '')}'s evaluation has been selected, presenting an offer of ${CommonHelper.formatCurrency(currencySymbol, fields.dealerPrice)}.`);
            }
        }



        fields.modifiedBy = _currentUser;
        fields.modifiedDate = _currentDate;
        fields.modifiedFrom = 'web';

        this.saveTradeinProField('offer', fields);
        if (isApproved)
            this.saveTradeinProField('status', tipStatus.COMPLETED, true)

        _msg && _msg.forEach(_data => {
            let _objLogData = {
                tradeinProID: tradeinProID,
                offerID: fields.documentID,
                projectId: fields?.projectId ? fields?.projectId : null,
                message: _data
            }

            CommonHelper.saveTradeInProLog(dealersettings, _objLogData);
        })


        if (!_status) {
            this.handleSaveOffers(fields, true);
            this.handleUndoPreviousOffers(_previousOfferID)
        }
    }

    handleStatusSave = (_status) => {
        let __status = CommonHelper.getNameByValue(tradeinProAllStatus, _status)
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: `${CommonHelper.showLocale(this.props, 'You want to change appraisal status to')} ${__status.toUpperCase()}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            if (result.value) {
                if (_status === tipStatus.WON)
                    this.handleTIPWon();
                else if (_status === tipStatus.LOST)
                    this.handleTIPLost();
                else
                    this.saveTradeinProField('status', _status);
            }

        });
    }

    handleTIPWon = () => {
        if (this.isValidForStatusUpdate(tipStatus.WON)) {

            this.saveTradeinProField('status', tipStatus.WON);
        }
    }

    handleTIPLost = () => {
        if (this.isValidForStatusUpdate(tipStatus.LOST)) {
            this.saveTradeinProField('status', tipStatus.LOST);
        }
    }

    //#endregion

    //#region DYNAMIC FIELDS

    handleDynamicCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = checked;
        this.saveTradeinProField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(name), 500);
    }

    handleDynamicOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        if (value)
            _dynamicFields[name] = _.trim(value.toUpperCase());
        else
            _dynamicFields[name] = null;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    onDynamicNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        _dynamicFields[name] = e.floatValue;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleDynamicSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[data.name] = (e?.value || null);
        this.saveTradeinProField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(data.name), 500);
    };

    handleDynamicMultiSelectChange = (selectedOptions, _data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[_data.name] = value;
        this.saveTradeinProField('dynamicFields', _dynamicFields);
        this.setState({ fields: state });
    }

    handleDynamicDateChange = (val, name) => {

        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = val;

        this.saveTradeinProField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(name), 500);
    };

    saveDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        this.saveTradeinProField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(name), 500);
    }

    cancelDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }
    //#endregion

    handleEnquiryOptionClose = (status) => {
        if (!_.isEmpty(status)) {

            //let _enquiryModal = Object.assign({}, this.state.enquiryModal)
            //console.log('handleEnquiryOptionClose', status, this.state.defaulStages)
            if (status === tipStatus.WON)
                this.handleTIPWon();
            else if (status === tipStatus.LOST)
                this.handleTIPLost();
            else if (status === 'request')
                this.isValidForRequest();
            else if (status === 'selfRequest')
                this.isValidForSelfRequest();
            else if (status === 'approve')
                this.isValidApprovedOffer();
            else if (status === 'addContact')
                this.handleMandatoryContactOpen(status)
            else if (status === 'tradeIn' || status === 'isTradeIn')
                this.isValidForTradeIn()
            else if (status === 'tradeInLink')
                this.isValidForTradeIn(true)
            else if (status === 'stage') {
                var indexVal = this.state.defaulStages.findIndex(x => x.value === status);
                if (indexVal >= 0) {
                    this.handleStageChange(indexVal, status)
                }
            }
            // else if (status === 'activity') {
            //     //this.handleDynamicStatus(status)
            // }
            // else if (status)
            //     this.handleDynamicStatus(status)

        }
        this.setState({
            enquiryModal: {
                show: false,
                errors: null,
                mandatoryFields: null,
                newStatus: null,
                newStatusValue: null,
                ismodifyStatusDate: false
            }
        });
    };

    handleReOpen = () => {

        const { statusSettings, fields } = this.state;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === tipStatus.PENDING)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, `You want to re-open appraisal.`),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    this.saveTradeinProField('status', tipStatus.PENDING, false, true);
                    this.saveOfferStatus(null, true)
                    this.saveApprovedOffer(null, fields.offer, tipOfferStatus.COMPLETED)
                }
            });
        }
        else {
            this.setState({ showPipelineOnOpen: true });
        }


    }

    handleOpenLeadClose = async (data) => {
        if (!_.isEmpty(data)) {
            const { fields } = this.state;
            await this.handlePipelineClose(data, 'fromOpenLead');
            this.saveTradeinProField('status', tipStatus.PENDING, false, true);
            this.saveOfferStatus(null, true)
            this.saveApprovedOffer(null, fields.offer, tipOfferStatus.COMPLETED)
        }
        this.setState({ showPipelineOnOpen: false });
    }

    isValidForPushToAutoIT = (_origin) => {
        if (!_origin) return;

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to push this lead to DMS`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(async result => {
            if (result.value) {
                this.setState({ loaderErapower: true })
                try {
                    //const tradeinData = await firestoreDB(this.props.dealersettings).firestore().collection('tradeins').doc(this.state.fields.tradein.documentID).get();
                    const dmsreq = window.firebase.functions().httpsCallable(_origin);
                    dmsreq({
                        "clientID": this.state.fields.clientID,
                        "tradein": {
                            ...this.state.tradeIn,
                            contact: this.state.contact,
                            owner: this.state.fields.owner
                        }
                    }).then((res) => {
                        this.setState({ loaderErapower: false });
                        if (res.data.success) {
                            toast.notify('Appraisal has been sent successfully', {
                                duration: 2000,
                            });
                        }
                        else {
                            Swal.fire(res.data.message, '', 'error')
                        }
                    }).catch((error) => {
                        this.setState({ loaderErapower: false });
                        console.log(error);
                        Swal.fire('Something went wrong. Please contact support.', '', 'error')
                    })
                }
                catch (error) {
                    Swal.fire('Something went wrong. Please contact support.', '', 'error')
                }
            }
        });
    }

    handleAutograbClose = () => {

        this.setState({
            autoGrabModal: {
                show: false,
                tradeinID: "",
                title: "",
                clsActive: "",
            },
        });
    };
    handleAutoGrap = (tradeinId, vehicle) => {
        this.setState({
            autoGrabModal: {
                show: true,
                tradeinID: tradeinId,
                selectedVehicle: vehicle,
                title: "Market Overlay",
                clsActive: "overlay-modal active",
            },
        });
    };

    loadScreen = (chk) => {
        this.setState({ screenLoading: chk });
    }

    render() {

        //console.log('R E - R E N D E R - E N Q U I R Y - E D I T ');
        let { documentID, clientID, displayID, finalPrice, valuerPrice,
            pipeline, stage, status, owner,
            tradein, isFav, engageNewContact,
            isContact, isTradein, isFiles,
            stageHistory, offer, approvedOffer,
            completedDate, wonDate, lostDate, tags, ownerSales,
            addedBy, addedDate, isDeleted, modifiedBy, modifiedDate, modifiedFrom, addedFrom,
        } = this.state.fields;

        const _approvedOffer = !_.isEmpty(offer) ? offer : approvedOffer;
        let _badgeColor = status === tipStatus.PENDING ? 'blue' : status === tipStatus.LOST ? 'orange' : 'green'

        const { dob, businessContact, gdprDeleted,
            gender, address, isVIP, contactType,
            company, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL,
            phone, phoneCode, email, optinPhone, optinEmail, optinPost
        } = this.state.contact;

        const { images, videos, marks, linkStatus, sidePhotos,
        } = this.state.tradeIn;
        const isLinkSent = linkStatus === 'sent' && !isTradein ? true : false;

        const {
            tipOffers, tipHistory, tradeIn, tradeinProID,
            activeCntKey, activeVehKey, activeTipKey, videoModal,
            activeLeftKey, activeRightKey, activeTab,
            contactModal, vehicleModal, sendLinkModal,
            fileModal, showSharePanel, autoGrabModal,
            contact, showEvaluations, showSelfEvaluations, showReEvaluations,
            allUsers, cropShow, showCanvas, showDamage,
            imageURL, imageURLs, pipelinePopOver, showPipelineOnOpen,
            showExisting, selectedOffer, tipOfferNotes,
            editPrice, putPrice, notesModal, logsModal, enquiryModal,
            lostReasons, lostSubReasons, showPriceLogs,
            errorPhotos, imageLoader, dealersettings,
            editFinalPrice, editEvaluationPrice, editValuerPrice,
            screenLoading, imageViewer
        } = this.state;
        let _selectedOffer = !_.isEmpty(selectedOffer) ? selectedOffer : offer
        const { groupUsers, clientUsers, isReadOnlyView, handleModuleChange, isHideExpandView } = this.props;

        let _tags = _.uniqBy(_.union((tags || []), (tradeIn?.tags || [])), 'type')

        let _client = clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[clientID] &&
            dealersettings.group.clients[clientID].tradeinPro ? dealersettings.group.clients[clientID] : dealersettings.client;

        // const _clientTIP = (!_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.client) &&
        //     !_.isEmpty(dealersettings.client.clientSettings) &&
        //     !_.isEmpty(dealersettings.client.clientSettings.tradeinplus)) ? dealersettings.client.clientSettings._tradeinpro : null;

        const _settings = _client && _client.tradeinPro;

        const _hasNoPipelineAccess = pipeline && _settings && !_settings.pipelines.some(item => item.value === pipeline) ? true : false;
        let _hasNoDealerAccess = clientID && this.props.dealersettings?.tradeinProClients && !this.props.dealersettings.tradeinProClients.some(e => e.id === clientID) ? true : false;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);

        const deleteAccess = ((!_.isEmpty(_permissions) && _permissions.deleteTradein) ? true : false);
        const previewAppraisal = ((!_.isEmpty(_permissions) && _permissions.previewAppraisal) ? true : false);
        const movePipeline = ((!_.isEmpty(_permissions) && _permissions.movePipeline) ? true : false);
        const viewOnlyAccess = ((!_.isEmpty(_permissions) && _permissions.viewOnlyAccess) ? true : false);

        const selfEvaluationAccess = ((!_.isEmpty(_permissions) && _permissions.selfEvaluationAccess) ? true : false);
        const approveOfferAccess = ((!_.isEmpty(_permissions) && _permissions.approveOfferAccess) ? true : false);
        const resendOfferAccess = ((!_.isEmpty(_permissions) && _permissions.resendOfferAccess) ? true : false);
        const requestOfferAccess = ((!_.isEmpty(_permissions) && _permissions.requestOfferAccess) ? true : false);
        const modifyOfferAccess = ((!_.isEmpty(_permissions) && _permissions.modifyOfferAccess) ? true : false);
        const putPriceAccess = ((!_.isEmpty(_permissions) && _permissions.putPriceAccess) ? true : false);
        const priceLogAccess = ((!_.isEmpty(_permissions) && _permissions.priceLogAccess) ? true : false);
        const reopenAppraisal = ((!_.isEmpty(_permissions) && _permissions.reopenAppraisal) ? true : false);
        const showDamageCost = ((!_.isEmpty(_permissions) && _permissions.showDamageCost) ? true : false);
        const modifyStatusAccess = ((!_.isEmpty(_permissions) && _permissions.modifyStatusAccess) ? true : false);

        const lockOfferPrice = ((!_.isEmpty(_permissions) && _permissions.lockOfferPrice) ? true : false);
        const lockFinalPrice = ((!_.isEmpty(_permissions) && _permissions.lockFinalPrice) ? true : false);
        const lockValuerPrice = ((!_.isEmpty(_permissions) && _permissions.lockValuerPrice) ? true : false);

        const accessToShowTradeInPrice = ((!_.isEmpty(_permissions) && _permissions.accessToShowTradeInPrice) ? true : false);
        const currencySymbol = ((!_.isEmpty(_client) &&
            !_.isEmpty(_client.currency)) ? _client.currency.symbol + ' ' : '$ ');

        const setttings = _client && _client.settings;

        let dealerNameContact = '';
        if (contact && contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
            dealerNameContact = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);

        let dealerName = clientID ? CommonHelper.getOtherDealerName(dealersettings, clientID) : null;

        const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);
        const _isTradeinWonOrLost = status === tipStatus.WON || status === tipStatus.LOST ? true : false;
        const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(status) ? true : false;

        const canModifyTIP = (_hasNoPipelineAccess || _hasNoDealerAccess || isDeleted || viewOnlyAccess || isReadOnlyView || gdprDeleted) ? false : true;

        const disableTradeInLink = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.disableTradeInLink) ? true : false);

        let dynamicDetailList = !_.isEmpty(_settings?.sortEnquiryOptions) ? _settings.sortEnquiryOptions : (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.tradeinProOptions))
            ? dealersettings.client.tradeinProOptions :
            Object.assign([], _.uniq(_.union(enquirySortOptions, Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

        let _objCurrentStatus = status && tradeinProAllStatus.filter(e => e.value === status)[0]

        const aircallEnabled = ((!_.isEmpty(this.state.dealersettings) &&
            !_.isEmpty(this.state.dealersettings.client) && !_.isEmpty(this.state.dealersettings.client.integrations) &&
            this.state.dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
            this.state.dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
            this.state.dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
        ) ? true : false);

        const autoitEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "autoit")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "autoit")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "autoit")[0].enabled === true &&
            dealersettings.client.integrations.filter(e => e.type === "autoit")[0].settings &&
            dealersettings.client.integrations.filter(e => e.type === "autoit")[0].settings.enableTradeIn === true
        ) ? true : false);

        const revolutionEnabled = !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter((e) => e.type === "revolution")[0] &&
            dealersettings.client.integrations.filter((e) => e.type === "revolution")[0].active === true &&
            dealersettings.client.integrations.filter((e) => e.type === "revolution")[0].enabled === true ? true : false;

        const titanEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "titan")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "titan")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "titan")[0].enabled === true &&
            dealersettings.client.integrations.filter(e => e.type === "titan")[0].settings?.enableTradeIn === true
        ) ? true : false);
        const managerWorkflow = this.state.dealersettings?.client?.clientSettings?.managerWorkflow ? true : false;
        let _allImages = CommonHelper.bindAllImages(images, sidePhotos)
        return this.state.tradeinProID ? (
            <>

                {screenLoading ? <div className="lds-roller-loader text-center add-message-lds ">
                    <div className="lds-roller-loader-inner ">
                        <div role="status" className="spinner-border loader-primary"></div>
                        <p className="text-muted mt-3">generating intake agreement...</p>
                    </div>
                </div> : <></>}
                <div className="quickview-header">
                    <div className="float-right">
                        {
                            (status && _objCurrentStatus)
                                ?
                                <div className="current-status-quickview mt-1 mr-2">
                                    <Translate text={'currentStatus'} />
                                    <div className={`badge badge-pill badge-${status === tipStatus.NEW ? 'open' : 'empty'} ml-2`}
                                        style={{ background: (_objCurrentStatus && _objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                        <Translate
                                            text={_objCurrentStatus ? _objCurrentStatus.name : status.toLowerCase()}
                                            upperCase={true}
                                        />
                                    </div>
                                </div>
                                :
                                <></>

                        }

                        <div className="quickview-btn-wrap">
                            {
                                canModifyTIP && selfEvaluationAccess && !isLinkSent && !_isTradeinCompleted && (_.isEmpty(tipOffers) || (!_.isEmpty(tipOffers) && !_.find(tipOffers, { addedBy: localStorage.uid, isSelfEvaluation: true })))
                                    ?
                                    <><a href="#" className="btn btn-common  button-green" onClick={(e) => {
                                        e.preventDefault();
                                        if (canModifyTIP) {
                                            this.isValidForSelfRequest();
                                        }

                                    }}> <Translate text={'Self Evaluation'} upperCase={true} /> </a></>
                                    :
                                    <></>
                            }

                            {
                                canModifyTIP && requestOfferAccess && !isLinkSent && !_isTradeinCompleted
                                    ?
                                    <><a href="#" className="btn btn-common  button-blue" onClick={(e) => {
                                        e.preventDefault();
                                        if (canModifyTIP) {
                                            this.isValidForRequest();
                                        }

                                    }}> <Translate text={'Request Offers'} upperCase={true} /> </a></>
                                    :
                                    <></>
                            }

                            {
                                canModifyTIP && !approveOfferAccess && _.isEmpty(_approvedOffer) && !_.isEmpty(tipOffers) && _.find(tipOffers, { status: tipOfferStatus.COMPLETED })
                                    ?
                                    <><a href="#" className="btn btn-common button-delivered" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSelectOffer();

                                    }}> <Translate text={'Send For Approval'} /> </a></>
                                    :
                                    <></>
                            }
                            {
                                canModifyTIP && approveOfferAccess && ((_.isEmpty(_approvedOffer) && !_.isEmpty(tipOffers) && _.find(tipOffers, { status: tipOfferStatus.COMPLETED })) ||
                                    (!_.isEmpty(_approvedOffer) && !_approvedOffer.isApproved && status === tipStatus.PENDING))
                                    ?
                                    <><a href="#" className="btn btn-common button-delivered" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleApproveOffer();

                                    }}> <Translate text={'Approve'} upperCase={true} /> </a></>
                                    :
                                    <></>
                            }

                            {
                                canModifyTIP && !_.isEmpty(_approvedOffer) && _approvedOffer.isApproved && status === tipStatus.COMPLETED && modifyStatusAccess
                                    ?
                                    <>

                                        <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleStatusSave(tipStatus.WON);

                                        }}> <Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON)} upperCase={true} /> </a>

                                        <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleStatusSave(tipStatus.LOST);

                                        }}> <Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST)} upperCase={true} /> </a>
                                    </>
                                    :
                                    <></>
                            }

                            {
                                canModifyTIP && modifyStatusAccess && (status === tipStatus.WON || status === tipStatus.LOST)
                                    ?
                                    <>
                                        {
                                            status === tipStatus.WON
                                                ?
                                                <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleStatusSave(tipStatus.LOST);

                                                }}> <Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST)} upperCase={true} /> </a>
                                                :
                                                <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleStatusSave(tipStatus.WON);

                                                }}> <Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON)} upperCase={true} /> </a>

                                        }
                                    </>
                                    :
                                    <></>
                            }

                            {
                                canModifyTIP && reopenAppraisal && (status === tipStatus.COMPLETED || status === tipStatus.WON || status === tipStatus.LOST)
                                    ?
                                    <>
                                        <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleReOpen();
                                        }}> <Translate text={`reopen`} upperCase={true} /> </a>
                                    </>
                                    :
                                    <></>
                            }
                            {/* {
                                _.isEmpty(_approvedOffer) && !isLinkSent
                                    ?
                                    <>
                                        {
                                            selfEvaluationAccess && (_.isEmpty(tipOffers) || (!_.isEmpty(tipOffers) && !_.find(tipOffers, { isSelfEvaluation: true })))
                                                ?
                                                <><a href="#" className="btn btn-common  button-green" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (canModifyTIP) {
                                                        this.isValidForSelfRequest();
                                                    }

                                                }}> <Translate text={'Self Evaluation'} upperCase={true} /> </a></>
                                                :
                                                <></>
                                        }


                                        {
                                            requestOfferAccess
                                                ?
                                                <><a href="#" className="btn btn-common  button-blue" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (canModifyTIP) {
                                                        this.isValidForRequest();
                                                    }

                                                }}> <Translate text={'Request Offers'} upperCase={true} /> </a></>
                                                :
                                                <></>
                                        }



                                        {
                                            !approveOfferAccess && !_.isEmpty(tipOffers) && _.find(tipOffers, { status: tipOfferStatus.COMPLETED })
                                                ?
                                                <><a href="#" className="btn btn-common button-delivered" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleSelectOffer();

                                                }}><Translate text={'Send For Approval'} /> </a></>
                                                :
                                                <></>
                                        }
                                        {
                                            approveOfferAccess && !_.isEmpty(tipOffers) && _.find(tipOffers, { status: tipOfferStatus.COMPLETED })
                                                ?
                                                <><a href="#" className="btn btn-common button-delivered" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleApproveOffer();

                                                }}><Translate text={'Approve'} upperCase={true} /> </a></>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            !_.isEmpty(_approvedOffer) && !_approvedOffer.isApproved && status === tipStatus.PENDING && approveOfferAccess
                                                ?
                                                <><a href="#" className="btn btn-common button-delivered" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleApproveOffer();

                                                }}><Translate text={'Approve'} upperCase={true} /></a></>
                                                :
                                                <></>
                                        }


                                        {
                                            !_.isEmpty(_approvedOffer) && _approvedOffer.isApproved && status === tipStatus.COMPLETED && modifyStatusAccess
                                                ?
                                                <>

                                                    <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleStatusSave(tipStatus.WON);

                                                    }}><Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON)} upperCase={true} /> </a>

                                                    <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleStatusSave(tipStatus.LOST);

                                                    }}><Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST)} upperCase={true} /> </a>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            modifyStatusAccess && (status === tipStatus.WON || status === tipStatus.LOST)
                                                ?
                                                <>
                                                    {
                                                        status === tipStatus.WON
                                                            ?
                                                            <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleStatusSave(tipStatus.LOST);

                                                            }}><Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST)} upperCase={true} /> </a>
                                                            :
                                                            <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleStatusSave(tipStatus.WON);

                                                            }}><Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON)} upperCase={true} /> </a>

                                                    }
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            reopenAppraisal && (status === tipStatus.COMPLETED || status === tipStatus.WON || status === tipStatus.LOST)
                                                ?
                                                <>
                                                    <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleReOpen();
                                                    }}> <Translate text={`reopen`} upperCase={true} /> </a>
                                                </>
                                                :
                                                <></>
                                        }


                                    </>
                            } */}
                            {
                                canModifyTIP && (autoitEnabled || revolutionEnabled || titanEnabled) && this.state.contact && this.state.tradeIn ? (
                                    <a href="#" className="btn btn-common button-grey"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let _origin = autoitEnabled ? `autoit-sendTradein` :
                                                revolutionEnabled ? `revolution-sendTradein` :
                                                    titanEnabled ? `titan-sendTradein` : null
                                            this.isValidForPushToAutoIT(_origin)
                                        }}>
                                        {
                                            this.state.loaderErapower ?
                                                (<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
                                                :
                                                (<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
                                        }
                                        <Translate text={'Push To DMS'} upperCase={true} />
                                    </a>
                                ) : (<></>)
                            }

                        </div>

                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>{owner ? <>{CommonHelper.getUserNamebyId(clientUsers, owner)}</> : '--'}</Tooltip>
                            }
                        >
                            <div className="quick-view-avatar">
                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                    <img src={CommonHelper.getUserImagebyId(clientUsers, owner)} alt="" className="img-object-fit" />
                                </a>
                            </div>
                        </OverlayTrigger>

                        {
                            isHideExpandView
                                ?
                                <></>
                                :
                                <>
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip><Translate text={'Expand View'} /></Tooltip>
                                        }
                                    >
                                        <div className="quick-view-expand">
                                            {
                                                isReadOnlyView
                                                    ?
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (handleModuleChange) {
                                                            localStorage.setItem('objSales', JSON.stringify({ id: documentID, type: 'tradeinPro' }));
                                                            localStorage.setItem('defaultModule', 'tradeinPro');
                                                            handleModuleChange('tradeinPro');
                                                        }
                                                        else {
                                                            if (this.props.sidepanelClose) this.props.sidepanelClose('expand');
                                                            this.props.history.push("/tradeinpro/details/" + documentID);
                                                        }
                                                    }}>
                                                        <i className="ico icon-expand" data-toggle="tooltip" title="" data-placement="bottom" data-original-title=" Go to detail view"></i>
                                                    </a>
                                                    :
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (this.props.sidepanelClose) this.props.sidepanelClose('expand');
                                                        this.props.history.push("/tradeinpro/details/" + tradeinProID);
                                                    }}>
                                                        <i className="ico icon-expand" data-toggle="tooltip" title="" data-placement="bottom" data-original-title=" Go to detail view"></i>
                                                    </a>
                                            }
                                        </div>
                                    </OverlayTrigger>
                                </>
                        }

                        <Dropdown className='quick-view-more'>
                            <Dropdown.Toggle
                                as={CustomToggle}
                            //className='common-button'
                            >
                                <i className='ico icon-more'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={CustomMenu}
                                ChildClass="more-dropdown"
                                xplacement="bottom-end"
                            >


                                {
                                    canModifyTIP && resendOfferAccess && !isLinkSent && !_isTradeinCompleted && !_.isEmpty(tipOffers) && !_.isEmpty(tipOffers.filter(e => !e.isSelfEvaluation)[0])
                                        ?
                                        <><Dropdown.Item eventKey="3" onClick={(e) => {
                                            e.preventDefault();
                                            if (canModifyTIP) {
                                                this.isValidForReRequest();
                                            }
                                        }}><i className="ico icon-resend-offer-fix"></i><Translate text={'Re-request Offers'} /></Dropdown.Item></>
                                        :
                                        <></>
                                }



                                {
                                    canModifyTIP && previewAppraisal && !lockOfferPrice
                                        ?
                                        <>
                                            {/* <Dropdown.Item eventKey="2" onClick={(e) => {
                                                e.preventDefault();
                                                const url = `/tradeinpro/preview/${this.state.tradeinProID}?isView=y${accessToShowTradeInPrice ? '' : 'es'}`;
                                                window.open(url, '_blank');
                                            }}><i className="ico icon-waiver"></i><Translate text={'preview'} /></Dropdown.Item> */}
                                            {
                                                this.state.tradeinID && !lockFinalPrice
                                                    ?
                                                    <Dropdown.Item eventKey="11" onClick={(e) => {
                                                        e.preventDefault();
                                                        CommonHelper.saveChangeLog(clientID, null, this.state.tradeinProID, `External Report - Viewed `, 'tradeinPro', 'view');
                                                        const url = `/tradein/details/${this.state.tradeinID}?isView=y${accessToShowTradeInPrice ? '' : 'es'}`;
                                                        window.open(url, '_blank');
                                                    }}><i className="ico icon-waiver"></i><Translate text={'External Report'} /></Dropdown.Item>
                                                    :
                                                    <></>
                                            }
                                            <Dropdown.Item eventKey="11" onClick={(e) => {
                                                e.preventDefault();
                                                CommonHelper.saveChangeLog(clientID, null, this.state.tradeinProID, `Internal Report - Viewed `, 'tradeinPro', 'view');
                                                const url = `/tradeinpro/internal/${this.state.tradeinProID}?isView=y`;
                                                window.open(url, '_blank');
                                            }}><i className="ico icon-waiver"></i><Translate text={'Internal Report'} /></Dropdown.Item>
                                        </>
                                        :
                                        <></>
                                }
                                {/* <Dropdown.Item eventKey="1" onClick={(e) => { this.handleSharePanelOpen(e) }}><i className="ico icon-share"></i>  <Translate text={'Share'} /></Dropdown.Item> */}

                                {
                                    canModifyTIP && deleteAccess
                                        ?
                                        <Dropdown.Item eventKey="0" onClick={(e) => {
                                            this.handleDeleteTIP(e);
                                        }}><i className="ico icon-delete"></i>
                                            <Translate text={'delete'} />
                                        </Dropdown.Item>
                                        :
                                        <></>
                                }

                            </Dropdown.Menu>
                        </Dropdown>

                    </div>

                    <div className="quickview-mid-header">
                        <div className="float-left quickview-title-wrap w-100">

                            {
                                isContact && !_.isEmpty(contact)
                                    ?
                                    <>
                                        <div className="quickview-contact">
                                            {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                // const url = `/contacts/details/${contact.documentID}`;
                                                // window.open(url, '_blank');

                                                let pathname = window.location.pathname;
                                                if (pathname.indexOf('contacts/details') < 0) {
                                                    this.props.history.push("/contacts/details/" + contact.documentID + '?back-url=' + pathname);
                                                }

                                            }}>
                                                {CommonHelper.displayContact(titles, contact, '--')}
                                            </a>
                                        </div>
                                        {CommonHelper.displayPreferredContact(contact)}
                                        {/* {contact.displayID ? <span className='enquiry-details-preferred'> ({contact.displayID})</span> : <></>} */}

                                        <span className="quickview-favourite-ico">
                                            {(() => {
                                                if (isFav) {
                                                    return (
                                                        <a href="#"><i className="ico icon-star-active" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.saveTradeinProField('isFav', false);
                                                        }}></i></a>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <a href="#"><i className="ico icon-star" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.saveTradeinProField('isFav', true)
                                                        }}></i></a>
                                                    )
                                                }
                                            })()}

                                        </span>
                                        <div className="quickview-optin">
                                            {
                                                contact.optinPhone === false
                                                    ?
                                                    <span><i className={`ico icon-no-call`}></i></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                contact.optinSMS === false
                                                    ?
                                                    <span><i className={`ico icon-no-sms`}></i></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                contact.optinEmail === false
                                                    ?
                                                    <span><i className={`ico icon-no-email`}></i></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                contact.optinPost === false
                                                    ?
                                                    <span><i className={`ico icon-no-post`}></i></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                contact.marketingOptinEmail === false
                                                    ?
                                                    <span><i className={`ico icon-no-email`}></i></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                contact.marketingOptinSMS === false
                                                    ?
                                                    <span><i className={`ico icon-no-sms`}></i></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                contact.banLoanVehicle ? <span><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i></span> : <></>
                                            }
                                        </div>

                                        <div className="quickview-contact-list">
                                            <ul>
                                                {contact.phone ? aircallEnabled ?
                                                    <li><i className="ico icon-call"></i> <a href='#' className='hyperlink-blue' onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.sidepanelClose()
                                                        this.props.dialNumber(CommonHelper.validPhone(contact.phone, contact.phoneCode), null, contact.documentID, null, null, this.state.tradeinProID)
                                                    }}> {CommonHelper.phoneFormat(contact.phoneCode, contact.phone, dealersettings)}</a></li> :
                                                    <li><i className="ico icon-call"></i> {CommonHelper.phoneFormat(contact.phoneCode, contact.phone, dealersettings)}</li> : <></>}
                                                {contact.email ? <li><i className="ico icon-Mail"></i> <SendMail
                                                    email={email}
                                                    tradeinPro={this.state.fields}
                                                    dealersettings={this.state.dealersettings}
                                                    history={this.props.history}
                                                >
                                                </SendMail>
                                                </li> : <></>}
                                                {
                                                    (contact.businessContact)
                                                        ?
                                                        <li>
                                                            <i className={`ico icon-Contacts ${contact.contactType && contact.contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''}`}></i>
                                                            {CommonHelper.getFullName(titles, contact, '--')}
                                                        </li>
                                                        :
                                                        <>{contact.company && contact.company.name ? <li> <i className="ico icon-company"></i> {contact.company.name}</li> : ''}</>

                                                }
                                                {dealerNameContact ? <li><span className="quickview-dealer-tag"><i className="ico icon-group"></i>{dealerNameContact}</span></li> : <></>}
                                            </ul>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            !_.isEmpty(engageNewContact)
                                                ?
                                                <>
                                                    <div className="quickview-contact text-red-indicator">
                                                        {CommonHelper.displayContactIcon(engageNewContact, 'mr-1')}

                                                        {CommonHelper.displayContact(titles, engageNewContact, '--')}

                                                    </div>
                                                    {CommonHelper.displayPreferredContact(engageNewContact)}

                                                    <span className="quickview-favourite-ico">
                                                        {(() => {
                                                            if (isFav) {
                                                                return (
                                                                    <a href="#"><i className="ico icon-star-active" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.updateStatus('isFav', false)
                                                                    }}></i></a>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <a href="#"><i className="ico icon-star" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.updateStatus('isFav', true)
                                                                    }}></i></a>
                                                                )
                                                            }
                                                        })()}

                                                    </span>
                                                    <div className="quickview-optin">
                                                        {
                                                            engageNewContact.optinPhone === false
                                                                ?
                                                                <span><i className={`ico icon-no-call`}></i></span>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            engageNewContact.optinSMS === false
                                                                ?
                                                                <span><i className={`ico icon-no-sms`}></i></span>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            engageNewContact.optinEmail === false
                                                                ?
                                                                <span><i className={`ico icon-no-email`}></i></span>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            engageNewContact.optinPost === false
                                                                ?
                                                                <span><i className={`ico icon-no-post`}></i></span>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            engageNewContact.marketingOptinEmail === false
                                                                ?
                                                                <span><i className={`ico icon-no-email`}></i></span>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            engageNewContact.marketingOptinSMS === false
                                                                ?
                                                                <span><i className={`ico icon-no-sms`}></i></span>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            engageNewContact.banLoanVehicle ? <span><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i></span> : <></>
                                                        }
                                                    </div>
                                                    <div className="quickview-contact-list">
                                                        <ul>
                                                            {engageNewContact.phone ? <li><i className="ico icon-call"></i> {CommonHelper.phoneFormat(engageNewContact.phoneCode, engageNewContact.phone, dealersettings)}</li> : <></>}
                                                            {engageNewContact.email ? <li><i className="ico icon-Mail"></i> <SendMail
                                                                email={engageNewContact.email}
                                                                tradeinPro={this.state.fields}
                                                                dealersettings={dealersettings}
                                                                history={this.props.history}
                                                            >
                                                            </SendMail></li> : <></>}


                                                        </ul>
                                                    </div>
                                                </>
                                                :
                                                <><div className="quickview-subtitle"><div className="no-data-red"><Translate text={'contactDetailsMissing'} /></div></div></>
                                        }


                                    </>
                            }


                            <div className="quickview-subtitle">
                                {
                                    (!_.isEmpty(tradein))
                                        ?
                                        <>
                                            <span>
                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                {(tradein.make ? tradein.make : '') + ' ' + (tradein.model ? tradein.model : '')}
                                                {tradein.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                    {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                        tradein.saleType, '')}
                                                </div>) : ''}
                                            </span>
                                        </>
                                        :
                                        <>
                                        </>
                                }

                                {
                                    managerWorkflow
                                        ?
                                        <>
                                            {_isTradeinCompleted && canModifyTIP && (<>{
                                                editFinalPrice === true
                                                    ?
                                                    <>
                                                        <div className="finalprice-quickview-offerwrap mr-3">
                                                            <div className="finalprice-quickview-offerwrap-edit input-quickview-close-fix"> <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ editFinalPrice: false });
                                                            }}> <i className="ico icon-remove"></i> </a> </div>
                                                            <div className="finalprice-quickview-offerwrap-title">
                                                                <Translate text={'finalPrice'} />{':'}
                                                                <span>
                                                                    <InputAutoNumber
                                                                        id={`finalPrice_${documentID}`}
                                                                        name={`finalPrice_${documentID}`}
                                                                        allowNegative={false}
                                                                        thousandSeparator={true}
                                                                        allowLeadingZeros={true}
                                                                        prefix={currencySymbol}
                                                                        placeholder={currencySymbol}
                                                                        className={`form-control cursor-pointer`}// ${this.state.errorsRecomm[`dealerPrice_${_data.documentID}`]}`}
                                                                        onValueChange={this.onCurrencyChange.bind(this, 'finalPrice')}
                                                                        value={finalPrice ? finalPrice : null}
                                                                        onSaveField={this.saveFinalPrice}
                                                                        onCancelField={(nam, val) => { this.cancelTradeinProField('finalPrice', val); }}
                                                                    />

                                                                </span>
                                                                <i className="edit"></i>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>

                                                        {
                                                            lockFinalPrice
                                                                ?
                                                                <div className="finalprice-quickview-offerwrap mr-3">
                                                                    <div className="finalprice-quickview-offerwrap-title">
                                                                        <Translate text={'finalPrice'} /> : <span><i className="ico icon-activity-lock font-size-16"></i></span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="finalprice-quickview-offerwrap mr-3">
                                                                    {
                                                                        modifyOfferAccess && status === tipStatus.COMPLETED
                                                                            ?
                                                                            <>
                                                                                <div className="finalprice-quickview-offerwrap-edit"> <a href="#" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({ editFinalPrice: true });
                                                                                }}> <i className="ico icon-edit"></i> </a>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    <div className="finalprice-quickview-offerwrap-title">
                                                                        <Translate text={'finalPrice'} /> :
                                                                        <NumberFormat value={finalPrice ? finalPrice : (_isTradeinWonOrLost && offer?.dealerPrice ? offer.dealerPrice : 0)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                    </div>

                                                                </div>
                                                        }

                                                    </>
                                            }</>)}
                                        </>
                                        :
                                        <>
                                            {
                                                _approvedOffer?.dealerPrice && !lockFinalPrice && _isTradeinCompleted
                                                    ?
                                                    <div className="float-right">
                                                        <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}>
                                                            <i className="ico icon-sent"></i>

                                                            <NumberFormat
                                                                value={_approvedOffer.dealerPrice}
                                                                decimalScale={2}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol} />

                                                        </div>

                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </>
                                }

                            </div>


                            <div className="quickview-subtitle mt-2">
                                <div className="float-left">
                                    {displayID ? <div className="verified-status tradein-valuation-num text-uppercase" onDoubleClick={(e) => {
                                        e.preventDefault()
                                        console.log(dealersettings);
                                        console.log('clientUsers:-', clientUsers)
                                        console.log('groupUsers:-', groupUsers)
                                    }}><Translate text={'Valuation No'} />: {displayID ? displayID : (this.state.tradeinProID.substring(0, 8))}</div> : <></>}

                                    {dealerName ? <div className="verified-status blue-color ml-2"><i className="ico icon-group"></i>{dealerName}</div> : <></>}

                                    {
                                        !canModifyTIP ? (

                                            <div className="verified-status">
                                                <div className="badge badge-pill badge-hot">
                                                    <i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
                                                </div>
                                            </div>

                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        isDeleted ? (

                                            <div className="verified-status">
                                                <div className="badge badge-pill badge-hot">
                                                    <i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
                                                </div>
                                            </div>

                                        ) : (
                                            <></>
                                        )
                                    }

                                    {
                                        gdprDeleted ? (

                                            <div className="verified-status">
                                                <div className="badge badge-pill badge-warm">
                                                    <i className="ico icon-delete mr-1"> </i> <Translate text={'gdprDeleted'} upperCase={true} />
                                                </div>
                                            </div>

                                        ) : (
                                            <></>
                                        )
                                    }


                                </div>

                                {
                                    _tags && _tags.length > 0 ? (<div className="quickview-id ml-1 mt-0">
                                        {
                                            _tags.map((r, i) => {
                                                return r.subType ? (
                                                    <span key={i} className="lead-badge-border">
                                                        <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title={r.refID || ''}>{CommonHelper.getNameByValue(this.props.dealersettings.integrations, r.type, r.type)} - {r.subType}</div>
                                                        {/* <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div> */}
                                                    </span>
                                                ) : r.type === 'cap' ? (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title='C@P'>C@P-{r.refID || CommonHelper.getNameByValue(this.props.dealersettings.integrations, r.type, r.type)}</div>)
                                                    : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title={r.refID || ''}>{CommonHelper.getNameByValue(this.props.dealersettings.integrations, r.type, r.type)}</div>)
                                            })
                                        }
                                    </div>) : (<></>)
                                }

                                {
                                    managerWorkflow
                                        ?
                                        <>
                                            {_isTradeinCompleted && canModifyTIP && (<>{
                                                editValuerPrice === true
                                                    ?
                                                    <>
                                                        <div className="finalprice-quickview-offerwrap-blue mt-1 mr-3">
                                                            <div className="finalprice-quickview-offerwrap-edit-blue input-quickview-close-fix"> <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ editValuerPrice: false });
                                                            }}> <i className="ico icon-remove"></i> </a> </div>
                                                            <div className="finalprice-quickview-offerwrap-title-blue">
                                                                <Translate text={'valuerPrice'} />{':'}
                                                                <span>
                                                                    <InputAutoNumber
                                                                        id={`valuerPrice_${documentID}`}
                                                                        name={`valuerPrice_${documentID}`}
                                                                        allowNegative={false}
                                                                        thousandSeparator={true}
                                                                        allowLeadingZeros={true}
                                                                        prefix={currencySymbol}
                                                                        placeholder={currencySymbol}
                                                                        className={`form-control cursor-pointer`}// ${this.state.errorsRecomm[`dealerPrice_${_data.documentID}`]}`}
                                                                        onValueChange={this.onCurrencyChange.bind(this, 'valuerPrice')}
                                                                        value={valuerPrice ? valuerPrice : (offer?.dealerPrice ? offer.dealerPrice : null)}
                                                                        onSaveField={this.saveFinalPrice}
                                                                        onCancelField={(nam, val) => { this.cancelTradeinProField('valuerPrice', val); }}
                                                                    />

                                                                </span>
                                                                <i className="edit"></i>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>

                                                        {
                                                            lockValuerPrice
                                                                ?
                                                                <div className="finalprice-quickview-offerwrap-blue mt-1 mr-3">
                                                                    <div className="finalprice-quickview-offerwrap-title-blue">
                                                                        <Translate text={'valuerPrice'} /> : <span><i className="ico icon-activity-lock font-size-16"></i></span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="finalprice-quickview-offerwrap-blue mt-1 mr-3">
                                                                    {
                                                                        modifyOfferAccess && status === tipStatus.COMPLETED
                                                                            ?
                                                                            <>
                                                                                <div className="finalprice-quickview-offerwrap-edit-blue"> <a href="#" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({ editValuerPrice: true });
                                                                                }}> <i className="ico icon-edit"></i> </a>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    <div className="finalprice-quickview-offerwrap-title-blue">
                                                                        <Translate text={'valuerPrice'} /> :
                                                                        <NumberFormat value={valuerPrice ? valuerPrice : (offer?.dealerPrice ? offer.dealerPrice : 0)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                    </div>

                                                                </div>
                                                        }

                                                    </>
                                            }</>)}
                                        </>
                                        :
                                        <>

                                        </>
                                }


                            </div>


                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div >


                <div className="quickview-contentarea">
                    <section className="tabs">
                        <div className="container">
                            <div className="row">
                                <div className="tabs-wraper">
                                    <Tabs
                                        //defaultActiveKey="quickview"
                                        activeKey={activeTab}
                                        onSelect={(k) => { this.setState({ activeTab: k }) }}
                                        transition={false}
                                        id="noanim-tab-example-tradeinpro"
                                        className="nav-fill">



                                        <Tab eventKey="tradein" title="Trade-In" className="quick-view-tab-scroll">

                                            <div className="quickview-files-wraper">
                                                {(() => {
                                                    if (!_.isEmpty(tradeIn)) {
                                                        return (
                                                            <>
                                                                <div className="quickview-list">
                                                                    <TradeinInfo
                                                                        tradein={tradeIn}
                                                                        isTradein={isTradein}
                                                                        handleEditTradeIn={this.handleTradeinOpen}
                                                                        handleReSendLink={this.handleReSendLink}
                                                                        handleAutoGrap={this.handleAutoGrap}
                                                                        loadScreen={this.loadScreen}
                                                                        isDeivered={canModifyTIP ? _isTradeinCompleted : true}
                                                                        dealersettings={dealersettings}
                                                                        clientUsers={clientUsers}
                                                                        groupUsers={groupUsers}
                                                                        showTag={true}
                                                                    >
                                                                    </TradeinInfo>

                                                                    {
                                                                        tradeIn?.linkStatus === 'sent' && !isTradein
                                                                            ?
                                                                            <></>
                                                                            :
                                                                            <>
                                                                                <div className="tradein-quickview-section-inspection-wraper">
                                                                                    <div className="vehicle-title-inspection">Vehicle Details</div>
                                                                                    <div className="float-right">
                                                                                        {
                                                                                            showDamageCost
                                                                                                ?
                                                                                                <>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className={`btn btn-inspection-service mt-1 ml-2 float-right ${!_.isEmpty(marks) && marks.length > 0 ? '' : 'btn-inspection-cost-disable'}`}
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ showDamage: true })
                                                                                                        }}
                                                                                                    ><Translate text={'damageCost'} />
                                                                                                        <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                                                        <div className="float-right ml-4">
                                                                                                            {CommonHelper.showReconditioningCost(marks, currencySymbol)}
                                                                                                        </div>
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-inspection-service mt-1"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({ showCanvas: true })
                                                                                            }}
                                                                                        >
                                                                                            {this.state.isLoadingInspection ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-inspection')}></i>}

                                                                                            <Translate text={'vehicleInspection'} />
                                                                                            {
                                                                                                marks && marks.length > 0
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                                                        <div className="float-right"><span className="badge badge-inspection ml-1">{marks.length}</span></div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            _isTradeinWonOrLost === false && canModifyTIP
                                                                                                                ?
                                                                                                                <div className="float-right ml-2"><i className="ico icon-add" aria-hidden="true"></i></div>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }
                                                                                                    </>
                                                                                            }

                                                                                        </button>


                                                                                    </div>

                                                                                </div>
                                                                                <div className="tradein-quickview-section-thumb-wraper mt-2">
                                                                                    {
                                                                                        sidePhotosVM && sidePhotosVM.filter(e => e.value !== 'others').map((data, index) => {
                                                                                            return <div key={index}>
                                                                                                {
                                                                                                    !_.isEmpty(sidePhotos) && sidePhotos[data.value] && data.default
                                                                                                        ?
                                                                                                        <>
                                                                                                            <div title={data.name} className="tradein-quickview-section-item-image">
                                                                                                                {
                                                                                                                    _isTradeinCompleted
                                                                                                                        ?
                                                                                                                        <></>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                                                e.preventDefault();
                                                                                                                                this.handleSideImageDel(data.value);
                                                                                                                            }}>
                                                                                                                                <i className="ico icon-delete" ></i>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                }

                                                                                                                <a data-fancy-box={`tradeinPro_img_quickview`}
                                                                                                                    href={sidePhotos[data.value]}
                                                                                                                    onClick={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        this.setState({
                                                                                                                            imageViewer: {
                                                                                                                                show: true,
                                                                                                                                images: _allImages,
                                                                                                                                index: _allImages.findIndex(e => e === sidePhotos[data.value])
                                                                                                                            }
                                                                                                                        });
                                                                                                                    }}>
                                                                                                                    <img src={sidePhotos[data.value]} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                                </a>
                                                                                                                {/* <div className="tradein-quickview-section-item-label">{data.name}</div> */}
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>

                                                                                                            {
                                                                                                                _isTradeinCompleted
                                                                                                                    ?
                                                                                                                    <></>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            (imageLoader && imageLoader[data.value]) ?
                                                                                                                                (
                                                                                                                                    <div title={data.name} className="tradein-quickview-section-item-image">
                                                                                                                                        <div className="img-loader">
                                                                                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                                                                                                    <span className="sr-only">Loading...</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                                                            <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                                                                        </a>
                                                                                                                                        {/* <div className="tradein-quickview-section-item-label">{data.name}</div> */}
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                                :
                                                                                                                                <>

                                                                                                                                    <div title={data.name} className={`tradein-quickview-section-item-image upload ${errorPhotos[data.value]}`}>
                                                                                                                                        <label htmlFor={`sidePhotos_tip_${data.value}`}>
                                                                                                                                            <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, data.ico)]} alt="" htmlFor={`sidePhotos_tip_${data.value}`} />
                                                                                                                                            <input className="fileInput"
                                                                                                                                                type="file"
                                                                                                                                                name={`sidePhotos_tip_${data.value}`}
                                                                                                                                                id={`sidePhotos_tip_${data.value}`}
                                                                                                                                                accept="image/*"
                                                                                                                                                style={{ display: 'none' }}
                                                                                                                                                multiple={data.default ? false : true}
                                                                                                                                                onChange={(e) => this.onSelectFile(e, 'tradein', `sidePhotos_tip_${data.value}`, `Side Photo (${data.name})`, data.value)} />
                                                                                                                                            {
                                                                                                                                                data.default
                                                                                                                                                    ?
                                                                                                                                                    <div className="tradein-quickview-section-item-addwrap">
                                                                                                                                                        <div className="tradein-quickview-section-item-add"><i className="ico icon-add"></i></div>
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <></>
                                                                                                                                            }

                                                                                                                                        </label>

                                                                                                                                        {/* <div className="tradein-quickview-section-item-label">{data.name}</div> */}
                                                                                                                                    </div>


                                                                                                                                </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                            }


                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                        })
                                                                                    }

                                                                                    {
                                                                                        _isTradeinCompleted && _.isEmpty(sidePhotos) && _.isEmpty(images)
                                                                                            ?
                                                                                            <>
                                                                                                <div className="tradein-quickview-section-item-image">

                                                                                                    <a href={'#'} onClick={(e) => { e.preventDefault(); }}>
                                                                                                        <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>

                                                                                            </>
                                                                                    }
                                                                                    {
                                                                                        !_.isEmpty(images)
                                                                                            ?
                                                                                            <>
                                                                                                {
                                                                                                    images.map((data, index) => {
                                                                                                        return <div className="tradein-quickview-section-item-image" key={index}>
                                                                                                            {_isTradeinCompleted ? <></> :
                                                                                                                <>
                                                                                                                    <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        this.handleImageDel(index);
                                                                                                                    }}>
                                                                                                                        <i className="ico icon-delete" ></i>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }

                                                                                                            <a data-fancy-box={`tradeinPro_img_quickview`}
                                                                                                                href={data}
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    this.setState({
                                                                                                                        imageViewer: {
                                                                                                                            show: true,
                                                                                                                            images: _allImages,
                                                                                                                            index: _allImages.findIndex(e => e === data)
                                                                                                                        }
                                                                                                                    });
                                                                                                                }}>
                                                                                                                <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    })
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                    }
                                                                                    {
                                                                                        (imageURL) ?
                                                                                            (
                                                                                                <div className="tradein-quickview-section-item-image">
                                                                                                    <div className="img-loader">
                                                                                                        <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                            <div className="spinner-border spinner-border-sm" role="status">
                                                                                                                <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                        <img src={imageURL} width="70" height="70" alt="" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            )
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        (imageURLs) ?
                                                                                            <>
                                                                                                {
                                                                                                    imageURLs.map((data, index) => {
                                                                                                        return <div key={index} className="tradein-quickview-section-item-image">
                                                                                                            <div className="img-loader">
                                                                                                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                                                                                        <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                                <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    })
                                                                                                }


                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        _isTradeinCompleted
                                                                                            ?
                                                                                            <></>
                                                                                            :
                                                                                            <div className={`tradein-quickview-section-item-image upload ${this.state.errors["images"] ? 'border-error' : ''}`}>
                                                                                                <label htmlFor="imagesTIPQuickview">
                                                                                                    <img src={_images[`addvehicle${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} alt="" htmlFor="imagesTIPQuickview" />
                                                                                                    <input className="fileInput"
                                                                                                        type="file"
                                                                                                        name="imagesTIPQuickview"
                                                                                                        id="imagesTIPQuickview"
                                                                                                        accept="image/*"
                                                                                                        style={{ display: 'none' }}
                                                                                                        multiple={true}
                                                                                                        onChange={(e) => this.onSelectFile(e, 'tradeinPro', 'images', 'Vehicle Image')} />
                                                                                                </label>
                                                                                            </div>
                                                                                    }


                                                                                </div>

                                                                                <table className="contact-tbl" width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                                                    <tbody>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'make'} /></span>
                                                                                                <div>{tradeIn.make ? tradeIn.make : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'model'} /></span>
                                                                                                <div>{tradeIn.model ? tradeIn.model : '--'}</div></td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'type'} /></span>
                                                                                                <div>{tradeIn.type ? tradeIn.type : '--'} </div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'year'} /></span>
                                                                                                <div>{tradeIn.year ? tradeIn.year : '--'}</div></td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'registrationNumber'} /></span>
                                                                                                <div>{tradeIn.regNo ? tradeIn.regNo : '--'}</div> </td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'registrationExpiry'} /></span>
                                                                                                <div>{tradeIn.regDate ? moment(tradeIn.regDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div></td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'chassisNo'} /></span>
                                                                                                <div>{tradeIn.chassisNo ? tradeIn.chassisNo : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'mileage'} /></span>
                                                                                                <div>{tradeIn.mileage ? tradeIn.mileage : '--'}</div></td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'colour'} /></span>
                                                                                                <div>{tradeIn.exteriorColor ? tradeIn.exteriorColor : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'interiorColor'} /></span>
                                                                                                <div>{tradeIn.interiorColor ? tradeIn.interiorColor : '--'}</div> </td>

                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'complianceDate'} /></span>
                                                                                                <div>{tradeIn.complianceDate ? moment(tradeIn.complianceDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'buildDate'} /></span>
                                                                                                <div>{tradeIn.buildDate ? moment(tradeIn.buildDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div></td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'engineType'} /></span>
                                                                                                <div>{tradeIn.engineType ? tradeIn.engineType : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'engineNo'} /></span>
                                                                                                <div>{tradeIn.engineNo ? tradeIn.engineNo : '--'}</div> </td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'driveType'} /></span>
                                                                                                <div>{tradeIn.driveType ? tradeIn.driveType : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'fuelType'} /></span>
                                                                                                <div>{tradeIn.fuelType ? tradeIn.fuelType : '--'}</div> </td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'transmissionType'} /></span>
                                                                                                <div>{tradeIn.transmissionType ? tradeIn.transmissionType : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'cylinders'} /></span>
                                                                                                <div>{tradeIn.cylinders ? tradeIn.cylinders : '--'}</div> </td>
                                                                                        </tr>
                                                                                        <tr className="tr-brk">
                                                                                            <td width="50%"><span className="subDept"><Translate text={'gears'} /></span>
                                                                                                <div>{tradeIn.gears ? tradeIn.gears : '--'}</div></td>
                                                                                            <td width="50%"><span className="subDept"><Translate text={'seats'} /></span>
                                                                                                <div>{tradeIn.seats ? tradeIn.seats : '--'}</div> </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                    }

                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            (_isTradeinWonOrLost === false && canModifyTIP)
                                                                ?
                                                                <>
                                                                    <div className='text-center p-3'>
                                                                        <p><Translate text={'nodata'} /></p>
                                                                        <button
                                                                            className='btn btn-sm btn-default'
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.isValidForTradeIn();
                                                                            }}
                                                                        >
                                                                            <i className='ico icon-add mr-1'></i>{' '}
                                                                            <Translate text={'add'} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='text-center p-3'>
                                                                        <p><Translate text={'notradeindata'} /></p>
                                                                    </div>
                                                                </>

                                                        );

                                                    }

                                                })()}

                                            </div>
                                        </Tab>
                                        <Tab eventKey="offers" title="Offers" className="quick-view-tab-scroll">
                                            <div className="quickview-files-wraper">
                                                <div className="tradein-section-page-content">
                                                    {
                                                        !_.isEmpty(tipOffers)
                                                            ?
                                                            <>
                                                                {
                                                                    tipOffers.map((_data, index) => {
                                                                        return <div key={index} id={`offer_quickview_data_${_data.documentID}`} className={`tradein-section-offerwrap ${!_.isEmpty(_approvedOffer) && _approvedOffer.documentID === _data.documentID ? ' offer-won-cell' : ''}`}>

                                                                            <div className="tradein-section-offerwrap-head">
                                                                                {
                                                                                    _data.isSelfEvaluation && _data.addedBy === localStorage.uid && _approvedOffer?.documentID !== _data.documentID && ![tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(status)
                                                                                        ?
                                                                                        <>
                                                                                            <div className="offer-custom-control-more">
                                                                                                <a href="#" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.handleDeleteTIPOffer(_data.documentID, _data);
                                                                                                }}><i className="ico icon-delete"></i></a>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <div className="checkbox icheck-success float-left mr-3">
                                                                                    {
                                                                                        _data.status === tipOfferStatus.COMPLETED && status === tipStatus.PENDING// && _.isEmpty(_approvedOffer)
                                                                                            ?
                                                                                            <>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`quickview_det_${_data.documentID}`}
                                                                                                    name={`quickview_det_${_data.documentID}`}
                                                                                                    className="uncheck-activity"
                                                                                                    checked={!_.isEmpty(selectedOffer) && selectedOffer.documentID === _data.documentID ? true : false}
                                                                                                    onChange={(e) => {
                                                                                                        this.handleOfferCheckChange(e, _data.documentID, _data)
                                                                                                    }} />

                                                                                                <label htmlFor={`quickview_det_${_data.documentID}`}>{_data.wholeSellerId ? CommonHelper.getUserNamebyId(this.props.dealersettings.wholesalers, _data.wholeSellerId) : 'Self Evaluation'} </label>
                                                                                            </>
                                                                                            :
                                                                                            <><span>{_data.wholeSellerId ? CommonHelper.getUserNamebyId(this.props.dealersettings.wholesalers, _data.wholeSellerId) : 'Self Evaluation'} </span></>
                                                                                    }


                                                                                </div></div>
                                                                            {
                                                                                !_.isEmpty(_approvedOffer) && _approvedOffer.documentID === _data.documentID
                                                                                    ?
                                                                                    <>
                                                                                        {
                                                                                            _data.isSelfEvaluation
                                                                                                ?
                                                                                                <>
                                                                                                    {
                                                                                                        editEvaluationPrice && editEvaluationPrice === `evaluationPrice_${_data.documentID}`
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div className="tradein-section-offerwrap-price">
                                                                                                                    <div className="tradein-section-offerwrap-price-edit"> <a href="#" onClick={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        this.setState({ editEvaluationPrice: null });
                                                                                                                    }}> <i className="ico icon-remove"></i> </a> </div>
                                                                                                                    <div className="tradein-section-offerwrap-price-title"><Translate text={'Evaluation Price'} />:
                                                                                                                        <span>

                                                                                                                            <InputAutoNumber
                                                                                                                                id={`evaluationPrice_${_data.documentID}`}
                                                                                                                                name={`evaluationPrice_${_data.documentID}`}
                                                                                                                                allowNegative={false}
                                                                                                                                thousandSeparator={true}
                                                                                                                                allowLeadingZeros={true}
                                                                                                                                prefix={currencySymbol}
                                                                                                                                placeholder={currencySymbol}
                                                                                                                                className={`form-control cursor-pointer`}// ${this.state.errorsRecomm[`putPrice_${_data.documentID}`]}`}
                                                                                                                                onValueChange={this.onCostChange.bind(this, 'evaluationPrice', _data.documentID)}
                                                                                                                                value={_data.evaluationPrice}
                                                                                                                                onSaveField={this.saveofferEvalPriceField}
                                                                                                                                onCancelField={this.cancelofferPriceField}
                                                                                                                            />

                                                                                                                        </span>
                                                                                                                        <i className="edit"></i>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <div className="tradein-section-offerwrap-middle">
                                                                                                                    <Translate text={'Evaluation Price'} />:
                                                                                                                    {
                                                                                                                        lockOfferPrice
                                                                                                                            ?
                                                                                                                            <span className="tradein-section-wonprice">
                                                                                                                                <span><i className="ico icon-activity-lock"></i></span>
                                                                                                                            </span>
                                                                                                                            :
                                                                                                                            <span className="tradein-section-wonprice">
                                                                                                                                <NumberFormat value={_data.evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                                            </span>

                                                                                                                    }
                                                                                                                    {
                                                                                                                        _data.addedBy === localStorage.uid && _data.evaluationPrice && !lockOfferPrice && status !== tipStatus.WON && status !== tipStatus.LOST
                                                                                                                            ?
                                                                                                                            <>
                                                                                                                                <div className="edit-price-mini"> <a href="#" onClick={(e) => {
                                                                                                                                    e.preventDefault();
                                                                                                                                    this.setState({ editEvaluationPrice: `evaluationPrice_${_data.documentID}` });
                                                                                                                                }}> <i className="ico icon-edit"></i> </a>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                    }
                                                                                                    {
                                                                                                        _data.evaluatedBy
                                                                                                            ?
                                                                                                            <><div className="tradein-section-offerwrap-ownerlist"> <i className="fa fa-regular fa-comment"></i>
                                                                                                                {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _data.evaluatedBy)}</div></>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div className="tradein-section-offerwrap-middle"><Translate text={'Wholesaler Price'} />:
                                                                                                        {
                                                                                                            lockOfferPrice
                                                                                                                ?
                                                                                                                <span className="tradein-section-wonprice">
                                                                                                                    <span><i className="ico icon-activity-lock"></i></span>
                                                                                                                </span>
                                                                                                                :
                                                                                                                <span className="tradein-section-wonprice">
                                                                                                                    <NumberFormat value={_data.evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                                </span>

                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        _data.onBehalfUserID
                                                                                                            ?
                                                                                                            <><div className="tradein-section-offerwrap-ownerlist"> <i className="fa fa-regular fa-comment"></i>
                                                                                                                {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _data.onBehalfUserID)}</div></>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </>
                                                                                        }

                                                                                        {
                                                                                            editPrice && editPrice === `dealerPrice_${_data.documentID}`
                                                                                                ?
                                                                                                <>
                                                                                                    <div className="tradein-section-offerwrap-price">
                                                                                                        <div className="tradein-section-offerwrap-price-edit"> <a href="#" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ editPrice: null });
                                                                                                        }}> <i className="ico icon-remove"></i> </a> </div>
                                                                                                        <div className="tradein-section-offerwrap-price-title"><Translate text={'Dealer Price'} />:
                                                                                                            <span>
                                                                                                                <InputAutoNumber
                                                                                                                    id={`dealerPrice_${_data.documentID}`}
                                                                                                                    name={`dealerPrice_${_data.documentID}`}
                                                                                                                    allowNegative={false}
                                                                                                                    thousandSeparator={true}
                                                                                                                    allowLeadingZeros={true}
                                                                                                                    prefix={currencySymbol}
                                                                                                                    placeholder={currencySymbol}
                                                                                                                    className={`form-control cursor-pointer`}// ${this.state.errorsRecomm[`dealerPrice_${_data.documentID}`]}`}
                                                                                                                    onValueChange={this.onCostChange.bind(this, 'dealerPrice', _data.documentID)}
                                                                                                                    value={_data.dealerPrice}
                                                                                                                    onSaveField={this.saveofferPriceField}
                                                                                                                    onCancelField={this.cancelofferPriceField}
                                                                                                                />

                                                                                                            </span>
                                                                                                            <i className="edit"></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        managerWorkflow
                                                                                                            ?
                                                                                                            <></>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    lockFinalPrice
                                                                                                                        ?
                                                                                                                        <div className="tradein-section-offerwrap-won">
                                                                                                                            <div className="tradein-section-offerwrap-won-title">
                                                                                                                                <Translate text={'Dealer Price'} /> : <span><i className="ico icon-activity-lock font-size-16"></i></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="tradein-section-offerwrap-won">
                                                                                                                            <div className="tradein-section-offerwrap-won-title">
                                                                                                                                <Translate text={'Dealer Price'} /> : <NumberFormat value={_data.dealerPrice ? _data.dealerPrice : _data.evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                canModifyTIP && modifyOfferAccess && (_data.status === tipOfferStatus.COMPLETED || _data.isSelfEvaluation) && status !== tipStatus.WON && status !== tipStatus.LOST
                                                                                                                                    ?
                                                                                                                                    <>
                                                                                                                                        <div className="tradeinsection-finalprice-edit "> <a href="#" onClick={(e) => {
                                                                                                                                            e.preventDefault();
                                                                                                                                            this.setState({ editPrice: `dealerPrice_${_data.documentID}` });
                                                                                                                                        }}> <i className="ico icon-edit"></i> </a>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <></>
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>

                                                                                        {
                                                                                            _data.isSelfEvaluation
                                                                                                ?
                                                                                                <>
                                                                                                    {
                                                                                                        editEvaluationPrice && editEvaluationPrice === `evaluationPrice_${_data.documentID}`
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div className="tradein-section-offerwrap-price">
                                                                                                                    <div className="tradein-section-offerwrap-price-edit"> <a href="#" onClick={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        this.setState({ editEvaluationPrice: null });
                                                                                                                    }}> <i className="ico icon-remove"></i> </a> </div>
                                                                                                                    <div className="tradein-section-offerwrap-price-title"><Translate text={'Evaluation Price'} />:
                                                                                                                        <span>

                                                                                                                            <InputAutoNumber
                                                                                                                                id={`evaluationPrice_${_data.documentID}`}
                                                                                                                                name={`evaluationPrice_${_data.documentID}`}
                                                                                                                                allowNegative={false}
                                                                                                                                thousandSeparator={true}
                                                                                                                                allowLeadingZeros={true}
                                                                                                                                prefix={currencySymbol}
                                                                                                                                placeholder={currencySymbol}
                                                                                                                                className={`form-control cursor-pointer`}// ${this.state.errorsRecomm[`putPrice_${_data.documentID}`]}`}
                                                                                                                                onValueChange={this.onCostChange.bind(this, 'evaluationPrice', _data.documentID)}
                                                                                                                                value={_data.evaluationPrice}
                                                                                                                                onSaveField={this.saveofferEvalPriceField}
                                                                                                                                onCancelField={this.cancelofferPriceField}
                                                                                                                            />

                                                                                                                        </span>
                                                                                                                        <i className="edit"></i>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <div className="tradein-section-offerwrap-middle">
                                                                                                                    <Translate text={'Evaluation Price'} />:
                                                                                                                    {
                                                                                                                        _data.evaluationPrice ?
                                                                                                                            <span className="tradein-section-pricegreen">
                                                                                                                                {
                                                                                                                                    lockOfferPrice
                                                                                                                                        ?
                                                                                                                                        <span><i className="ico icon-activity-lock"></i></span>
                                                                                                                                        :
                                                                                                                                        <NumberFormat value={_data.evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        _data.addedBy === localStorage.uid && _data.evaluationPrice && !lockOfferPrice && status !== tipStatus.WON && status !== tipStatus.LOST
                                                                                                                            ?
                                                                                                                            <>
                                                                                                                                <div className="edit-price-mini"> <a href="#" onClick={(e) => {
                                                                                                                                    e.preventDefault();
                                                                                                                                    this.setState({ editEvaluationPrice: `evaluationPrice_${_data.documentID}` });
                                                                                                                                }}> <i className="ico icon-edit"></i> </a>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </div>


                                                                                                            </>
                                                                                                    }
                                                                                                    {
                                                                                                        _data.evaluatedBy
                                                                                                            ?
                                                                                                            <><div className="tradein-section-offerwrap-ownerlist"> <i className="fa fa-regular fa-comment"></i>
                                                                                                                {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _data.evaluatedBy)}</div></>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>

                                                                                                    {
                                                                                                        _data.status === tipOfferStatus.COMPLETED || _data.status === tipOfferStatus.LOST
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div className="tradein-section-offerwrap-middle">
                                                                                                                    <Translate text={'Wholesaler Price'} />:
                                                                                                                    {
                                                                                                                        _data.evaluationPrice ?
                                                                                                                            <span className="tradein-section-pricegreen">
                                                                                                                                {
                                                                                                                                    lockOfferPrice
                                                                                                                                        ?
                                                                                                                                        <span><i className="ico icon-activity-lock"></i></span>
                                                                                                                                        :
                                                                                                                                        <NumberFormat value={_data.evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />

                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            :
                                                                                                                            <span className="tradein-section-awaiting">{'Awaiting...'}</span>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {
                                                                                                                    _data.onBehalfUserID
                                                                                                                        ?
                                                                                                                        <><div className="tradein-section-offerwrap-ownerlist"> <i className="fa fa-regular fa-comment"></i>
                                                                                                                            {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _data.onBehalfUserID)}</div></>
                                                                                                                        :
                                                                                                                        <></>
                                                                                                                }

                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <div className="tradein-section-offerwrap-middle">
                                                                                                                    <Translate text={'Wholesaler Price'} />:
                                                                                                                    <span className="tradein-section-awaiting">{'Awaiting...'}</span>
                                                                                                                </div>



                                                                                                                {
                                                                                                                    putPrice && putPrice === `evaluationPrice_${_data.documentID}`
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <div className="tradein-section-offerwrap-price">
                                                                                                                                <div className="tradein-section-offerwrap-price-edit"> <a href="#" onClick={(e) => {
                                                                                                                                    e.preventDefault();
                                                                                                                                    this.setState({ putPrice: null });
                                                                                                                                }}> <i className="ico icon-remove"></i> </a> </div>
                                                                                                                                <div className="tradein-section-offerwrap-price-title"><Translate text={'Put Price'} />:
                                                                                                                                    <span>

                                                                                                                                        <InputAutoNumber
                                                                                                                                            id={`evaluationPrice_${_data.documentID}`}
                                                                                                                                            name={`evaluationPrice_${_data.documentID}`}
                                                                                                                                            allowNegative={false}
                                                                                                                                            thousandSeparator={true}
                                                                                                                                            allowLeadingZeros={true}
                                                                                                                                            prefix={currencySymbol}
                                                                                                                                            placeholder={currencySymbol}
                                                                                                                                            className={`form-control cursor-pointer`}// ${this.state.errorsRecomm[`putPrice_${_data.documentID}`]}`}
                                                                                                                                            onValueChange={this.onCostChange.bind(this, 'evaluationPrice', _data.documentID)}
                                                                                                                                            value={_data.evaluationPrice}
                                                                                                                                            onSaveField={this.saveofferPriceField}
                                                                                                                                            onCancelField={this.cancelofferPriceField}
                                                                                                                                        />

                                                                                                                                    </span>
                                                                                                                                    <i className="edit"></i>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                canModifyTIP && putPriceAccess && !_isTradeinCompleted//_.isEmpty(offer)
                                                                                                                                    ?
                                                                                                                                    <>
                                                                                                                                        <div className="tradein-section-offerwrap-putprice mb-2">
                                                                                                                                            <button type="button" className="btn btn-primary float-right" onClick={(e) => {
                                                                                                                                                e.preventDefault();
                                                                                                                                                this.setState({ putPrice: `evaluationPrice_${_data.documentID}` });
                                                                                                                                            }}>{/*currencySymbol*/} <Translate text={'Put Price'} /></button>

                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <></>
                                                                                                                            }


                                                                                                                        </>
                                                                                                                }



                                                                                                            </>
                                                                                                    }




                                                                                                </>
                                                                                        }


                                                                                        {
                                                                                            (_data.status === tipOfferStatus.COMPLETED || _data.isSelfEvaluation || (_data.dealerPrice && _data.status === tipOfferStatus.LOST)) && !managerWorkflow
                                                                                                ?
                                                                                                <>
                                                                                                    <div className="tradein-section-offerwrap-price">

                                                                                                        {
                                                                                                            editPrice && editPrice === `dealerPrice_${_data.documentID}`
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <div className="tradein-section-offerwrap-price-edit"> <a href="#" onClick={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        this.setState({ editPrice: null });
                                                                                                                    }}> <i className="ico icon-remove"></i> </a> </div>
                                                                                                                    <div className="tradein-section-offerwrap-price-title"><Translate text={'Dealer Price'} />:
                                                                                                                        <span>
                                                                                                                            <InputAutoNumber
                                                                                                                                id={`dealerPrice_${_data.documentID}`}
                                                                                                                                name={`dealerPrice_${_data.documentID}`}
                                                                                                                                allowNegative={false}
                                                                                                                                thousandSeparator={true}
                                                                                                                                allowLeadingZeros={true}
                                                                                                                                prefix={currencySymbol}
                                                                                                                                placeholder={currencySymbol}
                                                                                                                                className={`form-control cursor-pointer`}// ${this.state.errorsRecomm[`dealerPrice_${_data.documentID}`]}`}
                                                                                                                                onValueChange={this.onCostChange.bind(this, 'dealerPrice', _data.documentID)}
                                                                                                                                value={_data.dealerPrice}
                                                                                                                                onSaveField={this.saveofferPriceField}
                                                                                                                                onCancelField={this.cancelofferPriceField}
                                                                                                                            />

                                                                                                                        </span>
                                                                                                                        <i className="edit"></i>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        canModifyTIP && !lockOfferPrice && modifyOfferAccess && !_isTradeinCompleted//_.isEmpty(offer)
                                                                                                                            ?
                                                                                                                            <>
                                                                                                                                <div className="tradein-section-offerwrap-price-edit">
                                                                                                                                    <a href="#" onClick={(e) => {
                                                                                                                                        e.preventDefault();
                                                                                                                                        this.setState({ editPrice: `dealerPrice_${_data.documentID}` });
                                                                                                                                    }}> <i className="ico icon-edit"></i> </a>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }

                                                                                                                    <div className="tradein-section-offerwrap-price-title"><Translate text={'Dealer Price'} /> :
                                                                                                                        {
                                                                                                                            lockOfferPrice
                                                                                                                                ?
                                                                                                                                <span><i className="ico icon-activity-lock font-size-16"></i></span>
                                                                                                                                :
                                                                                                                                <span>
                                                                                                                                    <NumberFormat value={_data.dealerPrice ? _data.dealerPrice : _data.evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                                                </span>

                                                                                                                        }
                                                                                                                        <i className="edit"></i>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                        }





                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </>
                                                                            }



                                                                            {
                                                                                priceLogAccess && (<>
                                                                                    <div className="tradein-section-offerwrap-action">
                                                                                        <a href="#" className="btn" onClick={(e) => { e.preventDefault(); this.handleNoteOpen(_data.documentID); }}>
                                                                                            <Icon path={mdiNoteCheck} size={1} /><Translate text={'Notes'} />&nbsp;({tipOfferNotes ? tipOfferNotes.filter(e => e.offerID === _data.documentID).length : 0})</a>

                                                                                        {
                                                                                            ((lockFinalPrice || (lockValuerPrice && managerWorkflow)) && !_.isEmpty(_approvedOffer) && _approvedOffer.documentID === _data.documentID) || (lockOfferPrice && (_.isEmpty(_approvedOffer) || (!_.isEmpty(_approvedOffer) && _approvedOffer.documentID !== _data.documentID)))
                                                                                                ?
                                                                                                <a href="#" className="btn" onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <Icon path={mdiLock} size={1} /><Translate text={'Offers Log'} /></a>
                                                                                                :
                                                                                                <a href="#" className="btn" onClick={(e) => { e.preventDefault(); this.handleLogOpen(_data.documentID); }}>
                                                                                                    <Icon path={mdiHistory} size={1} /><Translate text={'Offers Log'} /></a>

                                                                                        }


                                                                                    </div>
                                                                                </>)
                                                                            }

                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <div className="spinner-loader w-100">
                                                                    <div className="no-cafe-flex justify-content-center align-items-center ">
                                                                        <div className="no-cafe-img">
                                                                            <i className="ico icon-activity-tag"></i> </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="quickview" title="Appraisal Info" className="quick-view-tab-scroll">
                                            <div className="quickview-activity-item pb-5">
                                                <div className="row">
                                                    <div className={`col-md-12 form-style`}>
                                                        <div className="form-row">

                                                            {
                                                                dynamicDetailList && dynamicDetailList.map((info, index) => (
                                                                    <TipInfoInputs
                                                                        key={index}
                                                                        type={info}
                                                                        fields={this.state.fields}
                                                                        errors={this.state.errors}
                                                                        currencySymbol={currencySymbol}
                                                                        dealersettings={this.state.dealersettings}
                                                                        canModifyTIP={canModifyTIP}
                                                                        origins={this.state.origins}
                                                                        lostReasons={lostReasons}
                                                                        lostSubReasons={lostSubReasons}
                                                                        enquiryOptionsDF={enquiryOptionsDF}
                                                                        handleSelectSave={this.handleSelectSave}
                                                                        handleLostReasonSave={this.handleLostReasonSave}
                                                                        handleDynamicCheckChange={this.handleDynamicCheckChange}
                                                                        handleDynamicOnChange={this.handleDynamicOnChange}
                                                                        saveDynamicField={this.saveDynamicField}
                                                                        cancelDynamicField={this.cancelDynamicField}
                                                                        onDynamicNumberChange={this.onDynamicNumberChange}
                                                                        handleDynamicDateChange={this.handleDynamicDateChange}
                                                                        handleDynamicSelectSave={this.handleDynamicSelectSave}
                                                                        handleDynamicMultiSelectChange={this.handleDynamicMultiSelectChange}

                                                                    ></TipInfoInputs>
                                                                ))

                                                            }


                                                            <div className={`form-group col-md-6 ${(movePipeline && canModifyTIP) ? '' : 'form-disable'}`}>
                                                                <label><Translate text={'pipeline'} /></label>
                                                                <div
                                                                    className="form-control cursor-pointer"
                                                                    title={CommonHelper.showPSAge(stageHistory, pipeline, null, 'pipeline')}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (movePipeline && canModifyTIP) {
                                                                            this.setState({
                                                                                pipelinePopOver: { showpopover: true, targetpopover: e.target }
                                                                            });
                                                                        }
                                                                    }}>
                                                                    {this.state.pipelines.find(
                                                                        e => e.value === pipeline
                                                                    )
                                                                        ? this.state.pipelines.find(
                                                                            e => e.value === pipeline
                                                                        ).label
                                                                        : ''}
                                                                </div>
                                                            </div>

                                                            <div className="form-group col-md-12">
                                                                <label><Translate text={'stage'} /></label>
                                                                <div className={`${this.state.errors["stage"] ? 'custom_error' : ''}`} id="stage">
                                                                    <ul className="pipeline-step">
                                                                        {
                                                                            this.state.defaulStages && this.state.defaulStages.map((_stage, index) => {
                                                                                let _sittingDays = this.state.activeStageIndex >= index ? CommonHelper.handleSittingDays(stageHistory, pipeline, _stage) : '';
                                                                                return (
                                                                                    <OverlayTrigger
                                                                                        key={index}
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip><>{_stage.name} {_sittingDays ? <><br />{_sittingDays}</> : ''}</></Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <li
                                                                                            key={index}
                                                                                            name={_stage}
                                                                                            className={this.state.activeStageIndex >= index ? (this.handleStageComplete(_stage.value)) : ''}
                                                                                        >
                                                                                            <a
                                                                                                href="#"
                                                                                                //className={`stage-span ${(_canModifyEnquiry && (!isDeivered || (isDeivered && modifyStageAfterDeliveredLost))) ? '' : 'form-disable'}`}
                                                                                                id={index}
                                                                                                name={_stage.value}
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    //if (_canModifyEnquiry && (!isDeivered || (isDeivered && modifyStageAfterDeliveredLost)))
                                                                                                    if (canModifyTIP) this.handleStageChange(index, _stage.value)
                                                                                                }}
                                                                                            >
                                                                                                {/* {_stage.name} */}
                                                                                                {<>&nbsp;</>}
                                                                                            </a>
                                                                                        </li>
                                                                                    </OverlayTrigger>

                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>





                                                        </div>

                                                        <div className="enquiry-detailed-info">

                                                            {/* {
                                                                (modifyStatusDate && (isStatusSold || status === enquiryStatus.LOST || status === enquiryStatus.PROSPECTLOST || status === enquiryStatus.WON || status === enquiryStatus.DELIEVERD))
                                                                    ?
                                                                    <>
                                                                        <div className="edit-enquiry-detailes-btn">
                                                                            <a href="#" className="mini-button"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.props.handleStatusDateOpen();
                                                                                }}>
                                                                                <i className="ico icon-edit"></i>
                                                                            </a>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            } */}
                                                            <ul>
                                                                {
                                                                    (status === tipStatus.LOST) && !_.isEmpty(lostDate)
                                                                        ?
                                                                        <>
                                                                            <li> <span><Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST) + ' Date'} />:</span> {lostDate ? moment.unix(lostDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    (status === tipStatus.WON) && !_.isEmpty(wonDate)
                                                                        ?
                                                                        <>
                                                                            <li> <span><Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON) + ' Date'} />:</span> {wonDate ? moment.unix(wonDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    (status === tipStatus.COMPLETED || status === tipStatus.WON) && !_.isEmpty(completedDate)
                                                                        ?
                                                                        <>
                                                                            <li> <span><Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.COMPLETED, tipStatus.COMPLETED) + ' Date'} />:</span> {completedDate ? moment.unix(completedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }


                                                                <li> <span><Translate text={'modifiedBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), modifiedBy)}</li>
                                                                <li> <span><Translate text={'modifiedDate'} />:</span> {modifiedDate ? moment.unix(modifiedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                                                {
                                                                    !_.isEmpty(modifiedFrom)
                                                                        ?
                                                                        <>
                                                                            <li> <span><Translate text={'modifiedFrom'} />:</span> {modifiedFrom}</li>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                <li> <span><Translate text={'addedBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), addedBy)}</li>
                                                                <li> <span><Translate text={'addedDate'} />:</span> {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                                                {
                                                                    !_.isEmpty(addedFrom)
                                                                        ?
                                                                        <>
                                                                            <li> <span><Translate text={'addedFrom'} />:</span> {addedFrom}</li>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {ownerSales ? <li> <span><Translate text={'ownerSales'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), ownerSales)}</li> : <></>}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </Tab>
                                        <Tab eventKey="logs" title="Activity Logs" className="related-tab-scroll">
                                            <RealatedTab
                                                tradeinPro={this.state.fields}
                                                tipOffers={tipOffers}
                                                clientUsers={clientUsers}
                                                groupUsers={groupUsers}
                                                dealersettings={dealersettings}
                                                history={this.props.history}
                                                dialNumber={this.props.dialNumber}
                                                sidepanelClose={this.props.sidepanelClose}
                                                originType={['tradeinPro'].includes(localStorage.defaultModule) ? null : 'tradeinPro'}
                                            ></RealatedTab>
                                        </Tab>
                                        <Tab eventKey="activity" title="Schedule Activity" className="activity-tab-scroll" >
                                            <ActivityTab
                                                dealersettings={dealersettings}
                                                tradeinPro={this.state.fields}
                                                clientUsers={clientUsers}
                                                groupUsers={groupUsers}
                                                isFromLayout={true}
                                                originType={['tradeinPro'].includes(localStorage.defaultModule) ? null : 'tradeinPro'}
                                            ></ActivityTab>
                                        </Tab>
                                        <Tab eventKey="files" title="Files" className="quick-view-tab-scroll">
                                            <div className="quickview-files-wraper">
                                                <Files
                                                    tradeinProID={this.state.tradeinProID}
                                                    contact={contact}
                                                    contactID={contact?.documentID}
                                                    clientUsers={clientUsers}
                                                    groupUsers={groupUsers}
                                                    dealersettings={dealersettings}
                                                    isDeivered={canModifyTIP ? false : true}
                                                    filePath={`tradeinPro/${this.state.tradeinProID}/files`}
                                                    showAdd={true}
                                                ></Files>
                                            </div>
                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <PopUpModal show={contactModal.show}>
                    <AddContact
                        docID={this.state.contact.documentID}
                        show={contactModal.show}
                        clsActive={contactModal.clsActive}
                        handleClose={this.handleContactClose}
                        title={contactModal.title}
                        showSearch={true}
                        mandatoryFields={contactModal.mandatoryFields}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        tradeinProID={this.state.tradeinProID}
                        selectedClientID={this.state.fields.clientID}
                    >
                    </AddContact>
                </PopUpModal>

                <PopUpModal show={vehicleModal.show}>
                    <AddTradeIn
                        docID={vehicleModal.docID}
                        show={vehicleModal.show}
                        clsActive={vehicleModal.clsActive}
                        handleClose={this.handleTradeInClose}
                        title={vehicleModal.title}
                        mandatoryFields={vehicleModal.mandatoryFields}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        tradeinProID={this.state.tradeinProID}
                        tradeinPro={this.state.fields}
                        contact={this.state.contact}
                        isDeivered={!_.isEmpty(vehicleModal.mandatoryFields) ? false : (canModifyTIP ? _isTradeinCompleted : true)}
                    >
                    </AddTradeIn>
                </PopUpModal>

                <PopUpModal show={sendLinkModal.show}>
                    <SendTradeInLink
                        show={sendLinkModal.show}
                        tradeinID={sendLinkModal.tradeinID}
                        handleClose={this.handleCloseSendLink}
                        contact={this.state.contact}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        showInternal={true}
                    />
                </PopUpModal>

                <PopUpModal show={fileModal.show}>
                    <AddFile
                        show={fileModal.show}
                        tradeinProID={this.state.tradeinProID}
                        contactID={this.state.contact.documentID}
                        handleClose={this.handleFileClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddFile>
                </PopUpModal>

                <PopUpModal show={cropShow.show}>
                    <ImageCropHook
                        cropShow={cropShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>
                </PopUpModal>

                <PopUpModal show={showCanvas}>
                    <ImageCanvas
                        show={showCanvas}
                        clsActive='overlay-modal active'
                        imagePath='tradeinPro'
                        marks={this.state.tradeIn.marks}
                        handleClose={this.handleCanvasClose}
                        dealersettings={dealersettings}
                        showCost={showDamageCost}
                        isReadOnly={(_isTradeinWonOrLost || !canModifyTIP) ? true : false}
                    ></ImageCanvas>
                </PopUpModal>

                <PopUpModal show={showDamage}>
                    <MarkingInfo
                        show={showDamage}
                        clsActive='overlay-modal active'
                        marks={this.state.tradeIn.marks}
                        handleClose={this.handleDamageClose}
                        dealersettings={dealersettings}
                        showCost={true}
                    ></MarkingInfo>
                </PopUpModal>

                <ChatSidePanel
                    sidepanelClose={this.handleSharePanelClose}
                    clsActive={showSharePanel.chatClsActive}
                    clsName={showSharePanel.chatClsName}
                    title='selectUser'
                    sharePanel={true}
                    sendAs='tradeinPro'
                    tradeinPro={this.state.fields}
                />

                <PopoverPanel
                    showpopover={pipelinePopOver.showpopover}
                    targetpopover={pipelinePopOver.targetpopover}
                    title='Pipeline'
                    position='bottom'
                    id='pipeline-switch'
                    className='popover-pipeline-switch'
                    closepopover={() => {
                        this.setState({
                            pipelinePopOver: { showpopover: false, targetpopover: '' }
                        });
                    }}>
                    <PipelineSwitch
                        pipeline={pipeline}
                        stage={stage}
                        clientID={this.state.fields.clientID}
                        dealersettings={dealersettings}
                        handleClose={this.handlePipelineClose}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        owner={owner}
                        status={status}
                    />
                </PopoverPanel>

                <PopUpModal show={showPipelineOnOpen}>
                    <Modal
                        show={showPipelineOnOpen}
                        onHide={this.handleOpenLeadClose}
                        backdrop="static"
                        backdropClassName={showPipelineOnOpen ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-centered">
                        <Modal.Header closeButton>
                            <Modal.Title>{'Select Pipeline '}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0 pt-0">

                            <PipelineSwitch
                                clientID={this.state.fields.clientID}
                                dealersettings={dealersettings}
                                handleClose={this.handleOpenLeadClose}
                                clientUsers={clientUsers}
                                groupUsers={groupUsers}
                                pipeline={pipeline}
                                stage={stage}
                                owner={owner}
                                status={tipStatus.COMPLETED}
                                isNewEnq={true}
                            />

                        </Modal.Body>
                    </Modal>
                </PopUpModal>

                <PopUpModal show={videoModal.show}>
                    <AddVideo
                        show={videoModal.show}
                        recordID={this.state.tradeinID}
                        collection={'tradeins'}
                        handleClose={this.handleVideoClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddVideo>
                </PopUpModal>

                <PopUpModal show={showEvaluations}>
                    <RequestOffers
                        show={showEvaluations}
                        selectedOffers={tipOffers}
                        title={'Request Offers'}
                        tradeinProID={this.state.tradeinProID}
                        tradeinpro={this.state.fields}
                        handleClose={this.handleEvaluationClose}
                        handleSave={this.saveTradeinProField}
                        dealersettings={this.props.dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </RequestOffers>
                </PopUpModal>
                <PopUpModal show={showReEvaluations}>
                    <ReRequestOffers
                        show={showReEvaluations}
                        selectedOffers={tipOffers}
                        title={'Re-Request Offers'}
                        tradeinProID={this.state.tradeinProID}
                        tradeinpro={this.state.fields}
                        handleClose={this.handleEvaluationClose}
                        handleSave={this.saveTradeinProField}
                        dealersettings={this.props.dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </ReRequestOffers>
                </PopUpModal>

                <PopUpModal show={showSelfEvaluations}>
                    <SelfEvaluationOffers
                        show={showSelfEvaluations}
                        selectedOffers={tipOffers}
                        title={'Self Evaluation'}
                        tradeinProID={this.state.tradeinProID}
                        tradeinpro={this.state.fields}
                        offerPrice={this.state.tradeIn.offerPrice}
                        handleClose={this.handleEvaluationClose}
                        handleSave={this.saveTradeinProField}
                        approveOfferAccess={false}//new changes for keyvan
                        dealersettings={this.props.dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </SelfEvaluationOffers>
                </PopUpModal>

                <PopUpModal show={notesModal.show}>
                    <OfferNotes
                        show={notesModal.show}
                        tradeinProID={this.state.tradeinProID}
                        offerID={notesModal.recordID}
                        handleClose={this.handleNoteClose}
                        dealersettings={this.props.dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </OfferNotes>
                </PopUpModal>

                <PopUpModal show={logsModal.show}>
                    <OfferLogs
                        show={logsModal.show}
                        tradeinProID={this.state.tradeinProID}
                        offerID={logsModal.recordID}
                        handleClose={this.handleLogClose}
                        dealersettings={this.props.dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </OfferLogs>
                </PopUpModal>

                <PopUpModal show={showPriceLogs}>
                    <OfferPriceLogs
                        show={showPriceLogs}
                        tradeinProID={this.state.tradeinProID}
                        handleClose={this.handleLogClose}
                        dealersettings={this.props.dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </OfferPriceLogs>
                </PopUpModal>

                <PopUpModal show={enquiryModal.show}>
                    <EnquiryFields
                        title='appraisalInfo'
                        show={enquiryModal.show}
                        errors={enquiryModal.errors}
                        newStatus={enquiryModal.newStatus}
                        ismodifyStatusDate={enquiryModal.ismodifyStatusDate}
                        mandatoryFields={enquiryModal.mandatoryFields}
                        tradeinPro={this.state.fields}
                        contact={this.state.contact}
                        canModifyTIP={canModifyTIP}
                        tradeinProID={this.state.tradeinProID}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        selectedOffer={_selectedOffer}
                        handleClose={this.handleEnquiryOptionClose}
                    />
                </PopUpModal>

                <PopUpModal show={autoGrabModal.show}>
                    <AutoGrab
                        selectedVehicle={autoGrabModal.selectedVehicle}
                        history={this.props.history}
                        docID={autoGrabModal.tradeinID}
                        show={autoGrabModal.show}
                        clsActive={autoGrabModal.clsActive}
                        handleClose={this.handleAutograbClose}
                        title={autoGrabModal.title}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        dealersettings={dealersettings}
                    ></AutoGrab>
                </PopUpModal>
                <PopUpModal show={showExisting.show}>
                    <ExistingTIPLIst
                        {...this.props}
                        show={showExisting.show}
                        _keywords={showExisting.keywords}
                        tradeinProID={this.state.tradeinProID}
                        handleClose={(_data) => {
                            this.setState({ showExisting: { show: false, keywords: null } })
                        }}
                        hideContinue={true}
                        hasOffer={true}
                    ></ExistingTIPLIst>
                </PopUpModal>
                <PopUpModal show={imageViewer.show}>
                    <ImageViewer
                        {...imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>

            </>
        ) : this.state.pagenotFound ? (
            <PageNotFound />
        ) : (
            <ContentOverlay active={true} />
        );
    }
}

export default TradeinPlusInfo;
