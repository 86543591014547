import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { customerLogFields } from './customerLogReport/viewModel'
import { TableView, SidePanel, PopUpModal } from '../../components'
// import { StageProvider, StageConsumer } from '../pipeline/provider'
// import QuickView from "../pipeline/quickview";
import QuickEnquiryInfo from '../pipeline/details/quickInfo'
import _images from '../../images'
import ReactExport from 'react-export-excel-xlsx-fix';
import CommonHelper from '../../services/common'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import ReAuthenticate from '../common/reAuthenticate';
import Translate from '../../constants/translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const LogListModel = (props) => {
    const [allCustomerLogs, setallCustomerLogs] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 150) })
    const [searchText, setSearchText] = useState('')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();
    const csvDirectBtn = useRef();

    const [pageNum, setPageNum] = useState(0);
    const [filterpageNum, setfilterPageNum] = useState(0);

    const [loader, setLoader] = useState(false);
    const [showReAuthentication, setReAuthentication] = useState(false);

    const [activityFilter, setactivityFilter] = useState({})
    const [searchKeywords, setSearchKeywords] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [hasMore, setHasMore] = useState([1])

    useEffect(() => {
        if (_.isEmpty(allCustomerLogs))
            return

        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers.push(
            { title: 'Contact Name', dynValue: 'contactName', dynWidth: 10, ...csvStyle.header },
            { title: 'Email', dynValue: 'contactEmail', dynWidth: 10, ...csvStyle.header },
            { title: 'Phone', dynValue: 'phone', dynWidth: 10, ...csvStyle.header },
            { title: 'Vehicle Model', dynValue: 'vehicleModel', dynWidth: 10, ...csvStyle.header },
            { title: 'Sales Type', dynValue: 'vehicleSaletype', dynWidth: 10, ...csvStyle.header },
            { title: 'Notes', dynValue: 'notes', dynWidth: 10, ...csvStyle.header },
            { title: 'Created On', dynValue: 'activityAddedDate', dynWidth: 10, ...csvStyle.header },
            { title: 'Created By', dynValue: 'activityOwner', dynWidth: 10, ...csvStyle.header },
            { title: 'Enquiry Number', dynValue: 'enqNumber', dynWidth: 15, ...csvStyle.header }
        )
        var exportData = []
        allCustomerLogs.forEach(rec => {
            let obj = rec;
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${obj[col.dynValue] ? obj[col.dynValue] : ''}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return (h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth) ? {
                    ...h,
                    'width': { wch: w.dynWidth + 2 }
                } : h.width ? { ...h } : {
                    ...h,
                    'width': { wch: h.dynWidth + 2 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])
    }, [allCustomerLogs])

    const fetchAct = async (type = 'generic', _pageNum, localFilter) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled && type === 'excel') {
            handleReAuthOpen();
            return;
        }

        let num = _pageNum
        let pageLimit = props.counts ? props.counts > 49 ? 50 : props.counts : 50
        let filters = props.filter
        // on export fetch success csvBtn.current && csvBtn.current.link.click();
        let _filter = {}
        if (filters && filters.documentIDs) {
            _filter = {
                documentIDs: filters.documentIDs.join(',')
            }
        }
        _filter = {
            ..._filter,
            customerLogs: 'true'
        }
        if (filters.columnValue === "emailLog") {
            _filter.emailLog = 'true';
        }
        if (!_.isEmpty(localFilter)) {
            _filter = { ..._filter, ...localFilter }
            // num = 0
            pageLimit = 50
        }
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        let _searchObject = {
            "type": "searchActivities",
            "filters": JSON.stringify(_filter),
            "sortOrder": `${_filter.dateType ? _filter.dateType : 'addedDate'} desc`,
            "pageNum": num,
            pageLimit
        }
        if (type === 'excel') {
            _searchObject.pageLimit = (!props.counts || props.counts > 999) ? 1000 : props.counts
            _searchObject.pageNum = 0
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
        }
        _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, null, true);
        if (type === 'generic') {
            setLoader(true)
        }
        const searchActivities = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchActivities(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _pageNum = num + 1;
            let activities_ = resp.data.data.map(a => CommonHelper.convertActivityLogVM(a, { ...props }))
            if (!_.isEmpty(localFilter)) {
                setfilterPageNum(_pageNum)
            } else {
                setPageNum(_pageNum)
            }
            if (type === 'excel') {
                setallCustomerLogs(activities_)
                csvBtn.current && csvBtn.current.link.click();
                Swal.fire({
                    icon: 'success',
                    title: 'Data downloaded successfully.',
                    showConfirmButton: false,
                    timer: 1500
                })
                toast.closeAll();

                let _objLogData = {
                    notes: 'Excel export from dashboard - activities',
                    type: 'excelExport',
                    subType: 'activities',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);

            } else {
                if (!_.isEmpty(localFilter)) {
                    if (type === 'paging') {
                        setallCustomerLogs(_.uniqBy([...allCustomerLogs, ...activities_], 'documentID'))
                    } else {
                        setallCustomerLogs(activities_)
                    }
                } else {
                    setallCustomerLogs(_.uniqBy([...allCustomerLogs, ...activities_], 'documentID'))
                }
                // setActivities(_.uniqBy([...activitylogs, ...activities_], 'documentID'))
            }
            if (type === 'generic') {
                setLoader(false)
            }
        }
        else {
            console.log('error: ', resp.data.message)
            toast.closeAll();
            Swal.fire('Something went wrong.');
            if (type === 'generic') {
                setLoader(false)
            }
        }
    }

    const fetchDirectExcel = () => {
        //console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        csvDirectBtn.current && csvDirectBtn.current.link.click();
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard)
                    if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
                        fetchAct('excel', filterpageNum, activityFilter.value)
                    } else {
                        fetchAct('excel', pageNum)
                    }
                else
                    fetchDirectExcel();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        if (props.isFromDashboard) {
            fetchAct('generic', pageNum)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.filter) || props.isFromDashboard) {
            return;
        }

        var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
        if (!_.isEmpty(props.selectedPipeline)) {
            _pipelines = [props.selectedPipeline.value]
        }

        setallCustomerLogs(props.dataList.filter(a =>
            (!_.isEmpty(props.filter.activityIDs) ? props.filter.activityIDs.some(e => e === a.documentID) : true)
            && (!_.isEmpty(searchText) ?
                (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.modelDescription) ? a.modelDescription.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.regNo) ? a.regNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.chassisNo) ? a.chassisNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.contactEmail) ? a.contactEmail.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.phone) ? a.phone.includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.licenseNo) ? a.licenseNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.enqOwner) ? a.enqOwner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.pipeline) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.enqNumber) ? a.enqNumber.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.notes) ? a.notes.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.stage) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false)
                : true)
        ))

    }, [props.dataList, searchText])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 150)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div title="View Enquiry" style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    if (!_.isEmpty(data.enquiryID))
                        sidepanelOpen(data.enquiryID)
                    else if (!_.isEmpty(data.contactID))
                        props.history.push("/contacts/details/" + data.contactID);
                }}>
                    <i className="ico icon-more"></i>
                </div>
            </div>
        )
    }

    const handleDetailclick = (e, id, data) => {
        if (!_.isEmpty(data.enquiryID))
            sidepanelOpen(data.enquiryID)
        else if (!_.isEmpty(data.contactID))
            props.history.push("/contacts/details/" + data.contactID);
    }

    const handlePagination = () => {
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
            fetchAct('paging', filterpageNum, activityFilter.value)
        } else {
            if (allCustomerLogs.length >= props.counts) {
                return
            }
            fetchAct('paging', pageNum)
        }
        // fetchAct('paging')
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            fetchAct('generic', 0, _filter.value)
            setactivityFilter(_filter)
            setFilterApplied(true);
            setPageNum(0);
            setfilterPageNum(0)
            if (_filter.value.hasOwnProperty('keywords')) {
                setSearchText(_filter.value.keywords);
            } else {
                setSearchText('');
            }
            // localStorage.enquirySearchText = '';
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(activityFilter)) {
            setactivityFilter({})
            fetchAct('generic', pageNum)

            // localStorage.removeItem('activityFilter');
            // localStorage.enquirySearchText = '';
            setFilterApplied(false);
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setPageNum(0);
            setfilterPageNum(0)
            setSearchText('');
        }
    }

    const handlesearchText = (text) => {
        if (text) {
            fetchAct('generic', 0, { keywords: text })
            setactivityFilter({
                name: 'Adv Filter',
                type: 'filter',
                value: { keywords: text }
            })
            setPageNum(0);
            setfilterPageNum(0)
        } else {
            setPageNum(0);
            setfilterPageNum(0)
            setactivityFilter({})
            fetchAct('generic', pageNum)
        }
    }


    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">
                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="float-right">
                                    {
                                        (exportData)
                                            ?
                                            <>
                                                <div className="filter-item-btn">
                                                    <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                        <ExcelSheet dataSet={csvData} name="Sheet1" />
                                                    </ExcelFile>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                </div>
                            </div>
                            {
                                props.isFromDashboard ? loader ? (
                                    <>
                                        <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                            <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="common-table">
                                        <TableView
                                            isReorderable={true}
                                            datalist={_.orderBy(allCustomerLogs, ['addedDate'], ['desc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={customerLogFields}
                                            dynamicFields={customerLogFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={handleDetailclick}
                                            isSettings={true}
                                            showSNo={true}
                                            hasMore={hasMore}
                                            handlePagination={handlePagination}
                                            isPaging={true}
                                        />
                                    </div>
                                ) : (
                                    <div className="common-table">
                                        <TableView
                                            isReorderable={true}
                                            datalist={_.orderBy(allCustomerLogs, ['addedDate'], ['desc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={customerLogFields}
                                            dynamicFields={customerLogFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={handleDetailclick}
                                            rowHeight={70}
                                        />
                                    </div>)
                            }
                            {/* {
                                showpanel.clsActive ? (
                                    <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers} history={props.history}>
                                        <StageConsumer>
                                            {({ enquiries, isLoading, pipeline, columns, handleEnquiryFav, listenScrollEvent, stageLoading, updateEnquiry, sectionloading }) => (
                                                <SidePanel
                                                    clsActive={showpanel.clsActive}
                                                    clsName={showpanel.clsName}
                                                    sidepanelClose={sidepanelClose}
                                                    title=''
                                                >
                                                    <QuickView {...props} enquiryid={showpanel.enquiryid} isFilterApplied={true} sidepanelClose={sidepanelClose} isReadOnlyView={props.isReadOnlyView} />
                                                </SidePanel>
                                            )}
                                        </StageConsumer>
                                    </StageProvider>
                                ) : (<></>)
                            } */}
                            <QuickEnquiryInfo
                                {...props}
                                enquiryid={showpanel.enquiryid}
                                clsActive={showpanel.clsActive}
                                clsName={showpanel.clsName}
                                isFilterApplied={true}
                                isReadOnlyView={props.isReadOnlyView}
                                sidepanelClose={sidepanelClose}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
        </>
    );
}

export default LogListModel;