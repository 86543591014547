/** LIBRARIES */
import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import uuid from 'react-uuid';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { default as _images } from "../../images";
import toast from 'toasted-notes'
import moment from 'moment';
import { ContentOverlay } from '../../components';
import Swal from 'sweetalert2';
import Info from './info';


function RosterNotes(props) {
    const [notes, setNotes] = useState([])
    const [showNotes, setShowNotes] = useState(false)
    const [newLog, setNewLog] = useState(null);
    const [loader, setLoader] = useState(false)
    const [notesLoader, setNotesLoader] = useState(true)
    const textareaRef = useRef(null);
    const accessToAddRosterNotes = props?.dealersettings?.rolePermissions?.permissions?.accessToAddRosterNotes;
    const accessToDeleteRosterNotes = props?.dealersettings?.rolePermissions?.permissions?.accessToDeleteRosterNotes;

    useEffect(() => {
        const notesSnapshot = window.firebase.firestore().doc(`rosterNotes/${props.id}-${props.date}`)
            .onSnapshot(querySnapshot => {
                let _notes = [];
                if (querySnapshot.exists && querySnapshot.data().notes) {
                    _notes = querySnapshot.data().notes;
                }
                setNotes(_.orderBy(_notes, ['addedDate'], ['desc']))
                setNotesLoader(false)
            })
        return () => {
            notesSnapshot && notesSnapshot();
        }
    }, [])

    useEffect(() => {
        if (textareaRef && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [newLog])

    const handlechangelog = (e, objLog) => {
        var domTextref = document.getElementById('notes-tab-' + objLog.id)
        if (e.target.name === 'notes' && e.target.value.length === 0) {
            domTextref && domTextref.classList.add('input_error')
        }
        else {
            domTextref && domTextref.classList.remove('input_error')
        }
        e.preventDefault && e.preventDefault();
        if (objLog.isEditable) {

        }
        else {
            setNewLog({
                ...objLog,
                [e.target.name]: e.target.value
            });
        }
        if (notes.some(a => a.id === objLog.id))
            setNotes(notes.map(rec => {
                return rec.id === objLog.id ? {
                    ...rec,
                    notes: e.target.value
                } : {
                    ...rec
                }
            }))

    }

    const handlesavelog = (e, objLog) => {
        e.preventDefault();
        let updatelog = false;

        var domTextref = document.getElementById('notes-tab-' + objLog.id)
        if (objLog.notes.length === 0 && domTextref) {
            domTextref.focus();
            domTextref.classList.add('input_error')
            return
        }

        if (objLog.isNewlog) {
            objLog.addedBy = localStorage.uid;
            objLog.addedDate = window.firebase.firestore.Timestamp.now();
        }
        else if (objLog.isEditable) {
            objLog.modifiedBy = localStorage.uid;
            objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
            updatelog = true;
        }
        delete objLog.oldnotes;
        delete objLog.isNewlog;
        delete objLog.isEditable;
        setLoader(true)
        window.firebase.firestore().doc(`rosterNotes/${props.id}-${props.date}`).set({
            notes: [
                ...notes.filter(a => a.id !== objLog.id),
                objLog
            ],
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now()
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                setShowNotes(false)
                toast.notify('Log updated successfully', {
                    duration: 2000
                })
                props.updateRosterData(props.id, props.date, { notes: [props.id] })
                if (props.client_ids) {
                    const batch = window.firebase.firestore().batch();
                    props.client_ids.forEach(client => {
                        batch.set(window.firebase.firestore().doc(`roster/${client}-${props.date}`), {
                            notes: window.firebase.firestore.FieldValue.arrayUnion(props.id)
                        }, { merge: true });
                    })
                    batch.commit();
                }
            })
            .catch(error => {
                setLoader(false)
                setShowNotes(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    const handlecancellog = (e, objLog) => {
        e.preventDefault();
        setNotes(notes.map(rec => {
            return rec.id === objLog.id ? {
                ...rec,
                isEditable: false,
                notes: rec.oldnotes
            } : {
                ...rec
            }
        }))
        setShowNotes(false)
    };

    const deleteNote = (objLog) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`rosterNotes/${props.id}-${props.date}`).set({
                    notes: [
                        ...notes.filter(a => a.id !== objLog.id)
                    ],
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }, { merge: true })
                    .then(snapshot => {
                        toast.notify('Log deleted successfully', {
                            duration: 2000
                        })
                        if (props.client_ids && notes.filter(a => a.id !== objLog.id).length === 0) {
                            props.updateRosterData(props.id, props.date, { notes: [] })
                            const batch = window.firebase.firestore().batch();
                            props.client_ids.forEach(client => {
                                batch.set(window.firebase.firestore().doc(`roster/${client}-${props.date}`), {
                                    notes: window.firebase.firestore.FieldValue.arrayRemove(props.id)
                                }, { merge: true });
                            })
                            batch.commit();
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });

            }
        })
    }

    const editNote = (objLog) => {
        setShowNotes(false)
        setNewLog(objLog)
        setNotes(notes.map(rec => {
            return rec.id === objLog.id ? {
                ...rec,
                isEditable: true,
                oldnotes: rec.notes
            } : {
                ...rec,
                isEditable: false,
                notes: rec.oldnotes ? rec.oldnotes : rec.notes
            }
        }))
    }

    const TextArea = () => {
        return <>
            <div className='form-group col-md-12 mt-2'>
                <textarea
                    ref={textareaRef}
                    id={`notes-tab-${newLog.id}`}
                    className='form-control notes'
                    name='notes'
                    value={newLog.notes}
                    rows='3'
                    placeholder='write something...'
                    onChange={e => {
                        handlechangelog(e, newLog);
                    }}
                    onFocus={e => {
                        var temp_value = e.target.value;
                        e.target.value = '';
                        e.target.value = temp_value;
                    }}
                ></textarea>
            </div>
            <div className='editmode-footer mt-2 mr-1'>
                <button
                    type='button'
                    className='btn btn-primary float-right ml-2'
                    onClick={e => {
                        handlesavelog(e, newLog);
                    }}
                >
                    {loader ? (
                        <span
                            className='spinner-border spinner-button mr-1'
                            role='status'
                            aria-hidden='true'
                        ></span>
                    ) : (
                        <></>
                    )}
                    <Translate text={'save'} />
                </button>
                <button
                    type='button'
                    className='btn btn-default float-right'
                    onClick={e => {
                        handlecancellog(e, newLog);
                    }}
                >
                    <Translate text={'cancel'} />
                </button>
            </div></>
    }

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-add-log-activity"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="alert alert-info field-alert w-100" role="alert">
                                <div className="field-alert-list"><strong><Translate text={'user'} /> : </strong> {props.name} </div>
                                <div className="field-alert-list"><strong><Translate text={'date'} /> : </strong> {moment(props.date).format('DD/MM/YYYY')} </div>
                            </div>
                            {
                                accessToAddRosterNotes ?
                                    !showNotes ? (
                                        <div className="roster-addnote-wrap " onClick={() => {
                                            setNewLog({
                                                notes: '',
                                                id: uuid(),
                                                isNewlog: true
                                            })
                                            setNotes(notes.map(rec => {
                                                return {
                                                    ...rec,
                                                    isEditable: false,
                                                    notes: rec.oldnotes ? rec.oldnotes : rec.notes
                                                }
                                            }))
                                            setShowNotes(true)
                                        }}>

                                            <div className="roster-addnote-sub border-line-px">
                                                <i className="ico icon-add"></i> <Translate text={'Add Notes'} />
                                            </div>

                                        </div>
                                    ) : (
                                        <div className="mid-column-wrap">
                                            <div className="form-row form-style activity-nav-editmode ml-3 mr-3">
                                                <TextArea />
                                            </div>
                                        </div>
                                    )
                                    : (<></>)
                            }
                            {
                                notesLoader ? (<div className="enquiry-flow mt-0 mb-3" ><div className="mt-5"><ContentOverlay active={true} /></div></div>) : notes.length > 0
                                    ?
                                    <>
                                        <div className="enquiry-flow mt-0 mb-3" >
                                            <div className="enquiry-flow-wrap mt-0">
                                                <div className="flow-timelinebar"></div>
                                                {
                                                    notes.map((log, index) =>
                                                        <Info {...props}
                                                            log={log}
                                                            key={index}
                                                            deleteNote={deleteNote}
                                                            editNote={editNote}
                                                            accessToDeleteRosterNotes={accessToDeleteRosterNotes}
                                                            accessToAddRosterNotes={accessToAddRosterNotes}
                                                            TextArea={TextArea}
                                                        ></Info>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="spinner-loader w-100 p-5">
                                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                <div className="no-data-txt mt-2"> <Translate text={'noNotesAdded'} /></div>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default RosterNotes;
