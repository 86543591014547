import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { ContentOverlay } from '../../components'
import { default as _images } from '../../images';
import JobViewItem from './jobViewListItem'
import Translate from '../../constants/translate';

const ServiceJobsCount = (props) => {
    const [loader, setLoader] = useState(true)
    const [serviceLogs, setServicelogs] = useState({ Today: [], Upcoming: [] })
    const serviceTags = ["Today", "Upcoming"]
    const [activeServiceTag, setActiveservice] = useState('Today')
    const [hasMore, setHasMoreData] = useState({})
    const [checkDataLoad, setDataload] = useState({ "Today": true, "Upcoming": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const pageLimit = 20
    const [serviceCounts, setServiceCounts] = useState({})
    const [pageNum, setPageNum] = useState({ Today: 0, Upcoming: 0 });




    useEffect(() => {
        let dataload = { "Today": true, "Upcoming": true }
        setPageNum({ Today: 0, Upcoming: 0 });
        setHasMoreData({});
        setDataload({
            ...dataload,
            [activeServiceTag]: true
        })
    }, [props.selectedUser, props.selectedPipeline])

    useEffect(() => {
        const fetchCounts = async () => {

            let _filter = {
                clientID: props.dealersettings.client.id,
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
            if (props.selectedPipeline) {
                _filter.pipeline = props.selectedPipeline.value
            }
            else if (!_.isEmpty(props.pipeline)) {
                _filter.pipeline = props.pipeline.map(item => item.value).join(',')
            }
            if (props.selectedUser) {
                _filter.owner = props.selectedUser.value
            }
            if (props.ownerDashboard) {
                _filter.owner = localStorage.uid
            }

            let _searchObject = {
                type: "getServiceJobsCount",
                params: JSON.stringify(_filter),
            }
            const getData = window.firebase.functions().httpsCallable('generic-getData');
            const resp = await getData(_searchObject);
            if (resp.data.success) {
                const data = resp.data.data[0]
                setServiceCounts(data)
            }
        }
        fetchCounts()
    }, [props.selectedUser, props.selectedPipeline, props.refreshData])

    useEffect(() => {
        if (!checkDataLoad[activeServiceTag]) {
            return;
        }
        const fetchServiceJobs = async () => {
            let _filter = {
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
            if (props.selectedPipeline) {
                _filter.pipeline = props.selectedPipeline.value
            }
            else if (!_.isEmpty(props.pipeline)) {
                _filter.pipeline = props.pipeline.map(item => item.value).join(',')
            }
            if (props.selectedUser) {
                _filter.owner = props.selectedUser.value
            }
            if (props.ownerDashboard) {
                _filter.owner = localStorage.uid
            }

            _filter.dateType = 'appointmentDate'
            if (activeServiceTag === 'Today') {
                _filter.startDate = moment().format('YYYY-MM-DD');
                _filter.endDate = moment().format('YYYY-MM-DD');
            }
            else {
                _filter.startDate = moment().add(1, 'day').format('YYYY-MM-DD');
                _filter.endDate = moment().add(3, 'month').format('YYYY-MM-DD');
            }

            let _searchObject = {
                "type": "searchServiceJobs",
                "filters": _filter,
                "sortOrder": `appointmentDate asc`,
                "pageNum": pageNum[activeServiceTag],
                pageLimit,
            }
            _searchObject.clientID = props.dealersettings.client ? props.dealersettings.client.id : '';;

            setPagingloader(true)
            const getData = window.firebase.functions().httpsCallable('generic-searchData');
            const resp = await getData(_searchObject);
            setDataload({
                ...checkDataLoad,
                [activeServiceTag]: false
            })
            setLoader(false)
            setPagingloader(false)
            if (resp.data.success) {
                let data_ = resp.data.data;
                setHasMoreData({
                    ...hasMore,
                    [activeServiceTag]: data_.length >= pageLimit
                })
                if (data_.length >= pageLimit)
                    setPageNum({
                        ...pageNum,
                        [activeServiceTag]: pageNum [activeServiceTag] + 1
                    })
                setServicelogs({
                    ...serviceLogs,
                    [activeServiceTag]: _.uniqBy([...serviceLogs[activeServiceTag], ...data_], 'documentID')
                })
            }
        }
        fetchServiceJobs();
    }, [checkDataLoad, activeServiceTag])

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <h3><Translate text={'Service Jobs'} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        <div className="dashboard-activities-nav pl-0">
                                            <ul>
                                                {
                                                    serviceTags.map((rec, index) => {
                                                        return <li key={index}>
                                                            <a href="#"
                                                                id={rec}
                                                                className={`nav-li-${rec} ${activeServiceTag === rec ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActiveservice(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            ><Translate text={rec.toLocaleLowerCase()} />
                                                                {!_.isEmpty(serviceCounts) ?
                                                                    rec === 'Today' ? (<span className="badge badge-pill badge-widget-counter ml-1">{serviceCounts.today ? serviceCounts.today : '0'}</span>)
                                                                        : rec === 'Upcoming' ? (<span className="badge badge-pill badge-widget-counter ml-1">{serviceCounts.upcoming ? serviceCounts.upcoming : '0'}</span>)
                                                                            : (<></>)
                                                                    : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            serviceLogs[activeServiceTag].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item cursor-pointer" style={{ height: 'calc(100% - 90px)' }}>
                                                        {
                                                            serviceLogs[activeServiceTag].map((log, index) => {
                                                                return <JobViewItem
                                                                    key={index}
                                                                    title={log.title}
                                                                    id={log.documentID}
                                                                    dealersettings={props.dealersettings}
                                                                    clientUsers={props.clientUsers}
                                                                    groupUsers={props.groupUsers}
                                                                    service={log}
                                                                    sidepanelOpen={props.sidepanelOpen}
                                                                />
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!pagingLoader && serviceLogs[activeServiceTag].length == 0) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'No Service Available'} /> </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                        {
                                            pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                                hasMore[activeServiceTag] ?
                                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeServiceTag]: true
                                                        })
                                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                    : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceJobsCount;