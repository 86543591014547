import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection, collectionData } from 'rxfire/firestore';
import { ContentOverlay, PopoverPanel, AntMonthPicker, BarChart, PopUpModal } from "../../../components";
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';
import _images from '../../../images'
import TargetPopover from '../targetPopover'
import { dealertargetDynCols } from '../viewModel';
import ScheduleReport from '../scheduleReport';
import { Dropdown } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import cronstrue from 'cronstrue';

const DealerTarget = (props) => {
    const [loader, setLoader] = useState(true)
    const [selectedUser, setUser] = useState({ value: localStorage.uid });
    const [startDate, setStartDate] = useState(props.dealersettings.isMclaren === true ?
        window.firebase.firestore.Timestamp.fromDate(moment().startOf('year')._d) :
        window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d))
    const [endDate, setEndDate] = useState(props.dealersettings.isMclaren === true ?
        window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d) :
        window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d))
    const [allEnquiries, setEnquiries] = useState([]);
    const [allActivities, setActivities] = useState([]);
    const [allTestdrives, setTestdrives] = useState([]);
    const [allTradeins, setTradeins] = useState([]);
    const [allContacts, setContacts] = useState([]);
    const [userTarget, setUserTarget] = useState();
    const [months, setMonths] = useState(moment().format("MMM"))
    const [year, setYear] = useState(moment().format("YYYY"));
    const [targets, setTargets] = useState([])
    const [cummulativeTargets, setCummulativeTargets] = useState([])
    const [refreshData, setRefresh] = useState(true);
    const [years, setYears] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [targetPopOver, setTargetPopOver] = useState({ show: false, target: null, field: null })
    const [activeTarget, setActiveTarget] = useState(null)
    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: []
    })
    const [modelShow, setModelShow] = useState({
        sheduleReport: false,
    })
    const [popHeader, setPopHeader] = useState();
    const [report, setReport] = useState();
    const [schedueReport, setSchedeReport] = useState({});
    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');

    useEffect(() => {
        const refScheduleReport = window.firebase.firestore().doc(`schedular/target${props.dealersettings.client.id}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.filter(a => Boolean(a.superAdmin) === false).forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" />
                    {rec.name}
                </div>,
                data: rec
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])

    useEffect(() => {
        let _years = []
        _years.push({
            label: moment().add(-1, 'year').format('YYYY'),
            value: moment().add(-1, 'year').format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().format('YYYY'),
            value: moment().format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().add(1, 'year').format('YYYY'),
            value: moment().add(1, 'year').format('YYYY'),
            active: true,
        })
        setYears(_years)
    }, [])

    useEffect(() => {
        if (_.isEmpty(selectedUser)) {
            setLoader(false)
            return
        }

        setLoader(true)
        const reportData = window.firebase.functions().httpsCallable('generic-getData');
        reportData({
            type: props?.dealersettings?.mvwReport ? "getDealerTargetV2" : "getDealerTarget",
            params: JSON.stringify({
                clientID: props.dealersettings.client.id,
                startDate: moment.unix(startDate.seconds).format('YYYY-MM-DD'),
                endDate: moment.unix(endDate.seconds).format('YYYY-MM-DD'),
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                calcCurrentStatus: calcCurrentStatus
            })
        }).then((response) => {
            setLoader(false);
            if (response.data.success) {
                setTargets(response.data.data)
                let _target = _.chain(response.data.data)
                    .groupBy('targetID')
                    .map((value, key) => {
                        return {
                            targetName: value[0].name,
                            calculationType: value[0].calculationType,
                            targetID: key,
                            counts: value.map((rec, index) => {
                                return {
                                    ...rec,
                                    delta: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.totarget ? -(parseInt(rec.totarget)) : 0 : null,
                                    target: parseInt(rec.target),
                                    actual: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.actual ? parseInt(rec.actual) : 0 : null,
                                    index
                                }
                            })
                        }
                    }).value();
                let _cummulativeTarget = _target.map(rec => {
                    return {
                        ...rec,
                        counts: rec.counts.map((r, i) => {
                            return {
                                ...r,
                                cummulativeTarget: _.sumBy(rec.counts.filter(a => a.index <= i), 'target'),
                                cummulativeActual: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.actual : _.sumBy(rec.counts.filter(a => a.index <= i), 'actual') : null,
                                cummulativeDelta: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.delta : _.sumBy(rec.counts.filter(a => a.index <= i), 'delta') : null
                            }
                        })
                    }
                })
                setCummulativeTargets(_cummulativeTarget);
            }
        });

    }, [selectedUser, startDate, endDate])

    useEffect(() => {
        async function getWalkinEnq(walkins, enquiries) {
            const promises = []
            walkins.forEach(el => {
                if (!enquiries.some(e => e.documentID === el.enquiryID)) {
                    promises.push(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${el.enquiryID}`).get())
                }
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id }
                if (dataDoc.isDeleted === false) {
                    enquiries.push({
                        ...dataDoc,
                        walkins: walkins.filter(a => a.enquiryID === snap.id)
                    })
                }
            })
            setEnquiries(enquiries)
            setLoader(false)
        }
        if (tempData.walkins.length > 0)
            getWalkinEnq(tempData.walkins, tempData.enquiries);
        else
            setEnquiries(tempData.enquiries)

    }, [tempData])


    const handleShowTargetDetails = (e, target) => {
        setActiveTarget(target)
        setTargetPopOver({
            show: true,
            target: e.target
        });
        e.preventDefault()
    }


    const handleModelClose = () => {
        setModelShow({
            sheduleReport: false,
        })
    }

    return (
        <>
            <h2>Dealer Target
                {
                    props.dealersettings.isMclaren === true ? (<>
                        <Dropdown className='enquiry-view-more float-right mt-0'>
                            <Dropdown.Toggle as={CustomToggle} className='common-button'>
                                <i className='ico icon-more'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={CustomMenu}
                                ChildClass="more-dropdown dropsub-fix"
                                xplacement="bottom-end"
                            >
                                <Dropdown.Item eventKey="3" onClick={(e) => {
                                    setReport({
                                        documentID: `target${props.dealersettings.client.id}`,
                                        clientID: `${props.dealersettings.client.id}`,
                                        landscape: true
                                    })
                                    setPopHeader('Schedule Report')
                                    setModelShow({
                                        ...modelShow,
                                        sheduleReport: true
                                    })
                                }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>

                                <Dropdown.Item eventKey="4" onClick={(e) => {
                                    e.preventDefault();
                                    const url = `/dealertargets/report/${props.dealersettings.client.id}?year=${year}`;
                                    window.open(url, '_blank');
                                }}><i className="ico icon-mail-print" style={{ fontSize: '18px' }}></i> Print Preview </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            !_.isEmpty(schedueReport) ? (
                                <div className="reportsuccess-item-btn mr-2 float-right">
                                    <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                                    <div className="reportsuccess-inner-text">
                                        <div className="reportsuccess-inner-text-top"> Scheduled </div>
                                        <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                                    </div>
                                    <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                                </div>
                            ) : (<></>)
                        }
                    </>) : (<></>)
                }
            </h2>
            <div className="divider-line"></div>
            <div className="report-content-head">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="form-group col-md-3 pr-0">
                                {
                                    props.dealersettings.isMclaren === true ? (
                                        <>
                                            <label htmlFor="">Year</label>
                                            <AntMonthPicker
                                                value={moment().month(months).year(year)._d}
                                                name={'year'}
                                                onChange={val => {
                                                    setMonths(moment(val).format("MMM"))
                                                    setYear(moment(val).format("YYYY"))
                                                    setStartDate(window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('year')._d))
                                                    setEndDate(window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('year')._d))
                                                }}
                                                placeholder='select year'
                                                className={`form-control cursor-pointer`}
                                                format={'YYYY'}
                                                picker={'year'}
                                            /></>
                                    ) : (
                                        <>
                                            <label htmlFor="">Month</label>
                                            <AntMonthPicker
                                                value={moment().month(months).year(year)._d}
                                                name={'month'}
                                                onChange={val => {
                                                    setMonths(moment(val).format("MMM"))
                                                    setYear(moment(val).format("YYYY"))
                                                    setStartDate(window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('month')._d))
                                                    setEndDate(window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('month')._d))
                                                }}
                                                placeholder='select month'
                                                className={`form-control cursor-pointer`}
                                                format={'MMM YYYY'}
                                                picker={'month'}
                                            /></>
                                    )
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : props.dealersettings.isMclaren === true ? (<div className="common-table">
                    {
                        !_.isEmpty(cummulativeTargets) ? cummulativeTargets.map((cTarget, index) => {
                            return (<Fragment key={index}>
                                <h5>{cTarget.targetName}</h5>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <td className="head-light" width="20%"></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="head-light" width="20%"><span className="th-blue-clr">TARGET</span></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className="count-large text-center">{`${_rec.target !== null ? `${_rec.target}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL</span></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className="count-large text-center">{`${_rec.actual !== null ? `${_rec.actual}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td className="head-light" width="20%"><span className="th-blue-clr">DELTA</span></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className={`count-large text-center ${_rec.showRed === "true" && _rec.calculationType === 'percentage' ? 'down-ico' : ''}`}>
                                                        {
                                                            _rec.showRed === "false" && _rec.calculationType === 'percentage' ? <img src={_images.tickgreen} alt="" height="20" /> :
                                                                `${_rec.delta ? `${_rec.delta}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`
                                                        }
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                {
                                    cTarget.calculationType === 'count' ? (<table className="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <td className="head-light" width="20%"></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">TARGET - CUMMULATIVE</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="text-center">{_rec.cummulativeTarget}</td>
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL - CUMMULATIVE</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="text-center">{_rec.cummulativeActual}</td>
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">DELTA - CUMMULATIVE</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="text-center">{_rec.cummulativeDelta}</td>
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>) : (<></>)
                                }
                                <div className="text-center">
                                    <BarChart
                                        id={cTarget.targetID}
                                        height={`400px`}
                                        data={cTarget.counts}
                                        columns={['target', 'actual']}
                                        lineColumns={cTarget.calculationType === 'count' ? ['cummulativeTarget', 'cummulativeActual'] : []}
                                        dynamicColumns={dealertargetDynCols}
                                        xCategory={'month'}
                                        calculationType={cTarget.calculationType}
                                        handleShowEnquiryList={(value, startDate, endDate) => {

                                        }}
                                        hideBullet={true}
                                        dynColor={true}
                                    />
                                </div>
                            </Fragment>)
                        }) : (<div className="spinner-loader h-100 p-5">
                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> Target not set for selected dealer</div>
                            </div>
                        </div>)
                    }
                </div>
                ) : (<>
                    {
                        _.isEmpty(selectedUser) ? (<div className="spinner-loader h-100 p-5">
                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> Please select user</div>
                            </div>
                        </div>) : !_.isEmpty(targets) ?
                            (<table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <td className="head-light" width="30%">KPI</td>
                                        <td className="head-light text-center" width="10%">Target</td>
                                        <td className="head-light text-center" width="10%">Actual</td>
                                        <td className="head-light text-center" width="10%">To Target</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        targets.length > 0 ?
                                            targets.map((rec, index) => {
                                                return <tr key={index}>
                                                    <td><div className="dealership-tbl cursor-pointer float-left" onClick={(e) => {
                                                        handleShowTargetDetails(e, rec)
                                                    }}>{rec.name}</div></td>
                                                    <td className="text-center">{rec.target}</td>
                                                    <td className="text-center">
                                                        <div className={rec.showRed === "true" ? 'down-ico' : 'up-ico'}>
                                                            {rec.actual}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">

                                                        {
                                                            rec.showRed === "true" ? (
                                                                <span className={'down-ico ml-2'}>
                                                                    {rec.totarget}
                                                                </span>
                                                            ) : (
                                                                <img src={_images.tickgreen} alt="" height="20" />
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            }) : (
                                                <tr>
                                                    <td colSpan="4">
                                                        <div className="spinner-loader h-100 p-5">
                                                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                                <div className="no-data-txt mt-2"> Target not set for selected dealer / month</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </table>) : (<div className="spinner-loader h-100 p-5">
                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                    <div className="no-data-txt mt-2"> Target not set for selected dealer / month</div>
                                </div>
                            </div>)
                    }
                </>)
            }
            <PopoverPanel
                showpopover={targetPopOver.show}
                targetpopover={targetPopOver.target}
                title={'Target Details '}
                position="right"
                className="popover-required-fields"
                closepopover={() => {
                    setTargetPopOver({ show: false, target: '', field: '' });
                }}>
                <TargetPopover
                    {...props}
                    activeTarget={activeTarget}
                />
            </PopoverPanel>
            <PopUpModal show={modelShow.sheduleReport}>
                <ScheduleReport
                    {...props}
                    show={modelShow.sheduleReport}
                    handleClose={handleModelClose}
                    title={popHeader}
                    report={report}
                    dealerTarget={true}
                ></ScheduleReport>
            </PopUpModal>
        </>
    )
}

export default DealerTarget;