import React from 'react';
import { NavLink } from 'react-router-dom';
import { MailsRoutes } from '../../constants/routes';
import Translate from '../../constants/translate';
import _, { isEmpty } from 'lodash';
import CommonHelper from '../../services/common';

const EmailLeftBar = ({ handleOpenComposeModal, unreadCount, setSelectedThread, folders }) => {
	return (
		<div className='email-leftbar'>
			<div className='email-compose-wrap'>
				<a
					onClick={e => {
						e.preventDefault();
						handleOpenComposeModal({
							type: 'compose',
							title: 'New Message'
						});
					}}
					className='btn btn-primary btn-block compose-btn'
					style={{ color: '#fff' }}
				>
					<i className='ico icon-mail-compose'> </i> <span>Compose</span>
				</a>
			</div>
			<div className='email-menulink'>
				<ul>
					{CommonHelper.bindNylasFolders(folders).map((rec, index) => {
						return (
							<li className='email-nav-item' key={index} onClick={() => setSelectedThread(null)}>
								<NavLink to={rec.route} className='email-nav-link'>
									<div className='email-nav-item'>
										<div className='email-nav-inner email-nav-grid'>
											<i className={rec.class}> </i>
											<span>
												<Translate text={rec && rec.name} />
											</span>
										</div>
										<div className='email-nav-inner'>
											{/* {
												!_.isEmpty(folders) && rec.name === 'Inbox' && _.find(folders, { id: rec.id })?.unreadCount > 0 && (<span className='badge badge-pill badge-mail'>{_.find(folders, { id: rec.id })?.unreadCount}</span>)
											} */}
											 {unreadCount > 0 && rec.attribute === 'Inbox' && (
												<span className='badge badge-pill badge-mail'>{unreadCount}</span>
											)} 
										</div>
									</div>
								</NavLink>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default EmailLeftBar;
