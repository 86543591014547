import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { ContentOverlay, PopUpModal } from '../../components'
import CommonHelper from '../../services/common'
import { default as _images } from '../../images';
import AcitivitylogReducer from '../pipeline/quickview/activitylogReducer'
import LoanBookingView from './loanBookingListItem'
import LoanBookingsModal from '../fleet/loanBookings/loanBookingsModal';
import LoanBookings from '../fleet/loanBookings';
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';

const LoanBookingsWidget = (props) => {
    const [loadingLoanBookings, setLoadingLoanBookings] = useState(true)
    const [allLoanBookingsData, dispatch] = useReducer(AcitivitylogReducer, { Overdue: [], Today: [], Upcoming: [] })
    const loanBookingsTags = ["Overdue", "Upcoming", "Today",]
    const [activeLoanBookingsTag, setActivectiveLoanBookingsTag] = useState('Upcoming')
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState({ "Overdue": true, "Today": true, "Upcoming": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const pageLimit = 10
    const [loanBookingsCounts, setLoanBookingsCounts] = useState({
        completed: '0',
        total: '0',
        today: '0',
    })
    const [showLoanVehicle, setShowLoanVehicle] = useState({
        show: false,
        data: null,
    });
    const [reloadLoanBookingCounts, setReloadLoanBookingCounts] = useState(false);

    useEffect(() => {
        return () => {
            window.unsubdashboardfLoanBookinglist && window.unsubdashboardfLoanBookinglist();
        }
    }, [])

    useEffect(() => {
        let dataload = { "Overdue": true, "Today": true, "Upcoming": true }
        setHasMoreData([])
        dispatch({
            type: "CLEAR_ALL"
        });
        setDataload({
            ...dataload,
            [activeLoanBookingsTag]: true
        })
    }, [props.selectedUser])

    useEffect(() => {
        const fetchCounts = async () => {

            let _filter = {
                clientID: props.dealersettings.client.id,
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
            if (props.selectedUser) {
                _filter.owner = props.selectedUser.value
            }
            if (props.ownerDashboard) {
                _filter.owner = localStorage.uid
            }
            if (props.dateRange) {
                _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
            }

            let _searchObject = {
                type: "getLoanBookingCount",
                params: JSON.stringify(_filter),
            }
            const getData = window.firebase.functions().httpsCallable('generic-getData');
            const resp = await getData(_searchObject);
            //console.log('resp getLoanBookingCount, ', resp)
            if (resp.data.success) {
                const data = resp.data.data[0]
                setLoanBookingsCounts(data)
            }
        }
        fetchCounts()
    }, [props.selectedUser, props.refreshData, reloadLoanBookingCounts])

    useEffect(() => {
        if (!checkDataLoad[activeLoanBookingsTag]) {
            return;
        }
        setPagingloader(true)
        let refLoanBookingData = firestoreDB(props.dealersettings).firestore().collection('loanBookings')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)


        // @info: Today Bookings
        if (activeLoanBookingsTag === 'Upcoming') {
            refLoanBookingData = refLoanBookingData
                .where('status', '==', 0)
                .where('appointmentOn', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .where('appointmentOn', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
            // @info: Loaned 
        } else if (activeLoanBookingsTag === 'Today') {
            refLoanBookingData = refLoanBookingData
                .where('status', '==', 1)
            // @info: Bookings
        } else if (activeLoanBookingsTag === 'Overdue') {
            refLoanBookingData = refLoanBookingData
                .where('status', '==', 0)
        } else {
            refLoanBookingData = refLoanBookingData
                .where('status', '==', 0)
        }

        refLoanBookingData = refLoanBookingData
            .orderBy('appointmentOn', 'asc')

        let lastRecord = hasMore.filter(e => e.type === activeLoanBookingsTag);
        if (lastRecord.length > 0) {
            refLoanBookingData = refLoanBookingData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refLoanBookingData = refLoanBookingData
                .limit(pageLimit)
        }

        window.unsubdashboardfLoanBookinglist = refLoanBookingData
            .onSnapshot(onCollectionUpdate);
    }, [checkDataLoad, activeLoanBookingsTag])

    const onCollectionUpdate = async (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = {
                    ...change.doc.data(),
                    documentID: change.doc.id
                };
                snapshotDoc = convertVM(snapshotDoc)
                if (change.type === 'added') {
                    actionType = "ADDNEWLOG";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_ACTIVITY"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVELOG"
                }
            }
        })
        const _LoanBookingsData = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeLoanBookingsTag),
                {
                    type: activeLoanBookingsTag,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeLoanBookingsTag)]);
            }
            querySnapshot.forEach(function (doc) {
                const objlogData = Object.assign({}, convertVM(doc.data()));
                objlogData.documentID = doc.id;
                _LoanBookingsData.push(objlogData)
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : _LoanBookingsData,
            activeTab: activeLoanBookingsTag
        });
        setDataload({
            ...checkDataLoad,
            [activeLoanBookingsTag]: false
        })
        setLoadingLoanBookings(false)
        setPagingloader(false)
    }


    const convertVM = (data, fromSearch) => {
        let objData = { ...data }
        let appointmentOn = ''
        if (fromSearch && data.appointmentOn) {
            appointmentOn = moment.unix(data.appointmentOn._seconds).format('DD/MM/YYYY hh:mm A')
        } else if (!fromSearch && data.appointmentOn) {
            appointmentOn = moment(data.appointmentOn.toDate()).format('DD/MM/YYYY hh:mm A')
        }

        let convertedOn = ''
        if (fromSearch && data.convertedOn) {
            convertedOn = moment.unix(data.convertedOn._seconds).format('DD/MM/YYYY hh:mm A')
        } else if (!fromSearch && data.convertedOn) {
            convertedOn = moment(data.convertedOn.toDate()).format('DD/MM/YYYY hh:mm A')
        }

        objData.contactName = !_.isEmpty(objData.contact) && (objData.contact.firstName || objData.contact.lastName) ? CommonHelper.displayContactName([], objData.contact) : ''
        objData.vehicle = !_.isEmpty(objData.request) && (objData.request.Make || objData.request.Model) ? `${objData.request.Make ? objData.request.Make : ''} ${objData.request.Model ? objData.request.Model : ''}` : ''
        objData.appointmentOn = appointmentOn ? appointmentOn : ''
        objData.loanedOn = convertedOn ? convertedOn : ''

        objData.email = !_.isEmpty(objData.contact) && (objData.contact.email) ? objData.contact.email : ''
        objData.phone = !_.isEmpty(objData.contact) && (objData.contact.phone) ? objData.contact.phone : ''
        objData.vinNo = !_.isEmpty(objData.request) && (objData.request.VIN) ? objData.request.VIN : ''
        objData.regNo = !_.isEmpty(objData.request) && (objData.request.RegNum) ? objData.request.RegNum : ''
        objData.year = !_.isEmpty(objData.request) && (objData.request.Year) ? objData.request.Year : ''

        return objData
    }

    const handleUpdateLoanBooking = async (id, fleetID) => {
        if (fleetID && activeLoanBookingsTag !== 'Today') {
            await firestoreDB(props.dealersettings).firestore().collection('loanBookings').doc(id).set({
                status: 1,
                convertedOn: window.firebase.firestore.Timestamp.now(),
                fleetID: fleetID ? fleetID : '',
                fleetAddedBy: localStorage.uid,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }, { merge: true })
            setTimeout(() => {
                setReloadLoanBookingCounts(!reloadLoanBookingCounts)
            }, 1000);
        }
    }

    const handleOpenLoanVehicle = (e, data, LoanBookingTag) => {
        setShowLoanVehicle({
            show: true,
            data: data,
            fleetID: data.fleetID ? data.fleetID : '',
            type: LoanBookingTag === 'Today' ? 'completed' : 'total'
        })
    }

    const handleCloseLoanVehicle = () => {
        setShowLoanVehicle({
            show: false,
            data: null,
            fleetID: '',
            type: '',
        })
    }

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }

                        {/* <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a> */}
                    </div>
                    <h3><Translate text={'loanVehicleBookings'} /> </h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loadingLoanBookings ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        <div className="dashboard-activities-nav pl-0">
                                            <ul>
                                                {
                                                    loanBookingsTags.map((rec, index) => {
                                                        return (<li key={index}>
                                                            <a href="#"
                                                                id={rec}
                                                                className={`nav-li-${rec} ${activeLoanBookingsTag === rec ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActivectiveLoanBookingsTag(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            ><Translate text={rec === 'Upcoming' ? `todaysBookings` : rec === 'Today' ? 'loaned' : 'bookings'} />
                                                                {!_.isEmpty(loanBookingsCounts) ?
                                                                    rec === 'Overdue' ? (<span className="badge badge-pill badge-widget-counter ml-1">{loanBookingsCounts.total ? loanBookingsCounts.total : '0'}</span>)
                                                                        : rec === 'Upcoming' ? (<span className="badge badge-pill badge-widget-counter ml-1">{loanBookingsCounts.today ? loanBookingsCounts.today : '0'}</span>)
                                                                            : rec === 'Today' ? (<span className="badge badge-pill badge-widget-counter ml-1">{loanBookingsCounts.completed ? loanBookingsCounts.completed : '0'}</span>)
                                                                                : (<></>)
                                                                    : (<></>)
                                                                }
                                                            </a>
                                                        </li>)
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            !_.isEmpty(allLoanBookingsData[activeLoanBookingsTag]) && allLoanBookingsData[activeLoanBookingsTag].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item" style={{ height: 'calc(100% - 90px)' }}>
                                                        {
                                                            allLoanBookingsData[activeLoanBookingsTag].map((log, index) => {
                                                                return <LoanBookingView
                                                                    key={index}
                                                                    title={log.title}
                                                                    id={log.documentID}
                                                                    addedBy={CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.addedBy)}
                                                                    LoanBookingTag={activeLoanBookingsTag}
                                                                    dealersettings={props.dealersettings}
                                                                    LoanBookingLog={log}
                                                                    handleLoanBookingOpen={handleOpenLoanVehicle}
                                                                    clientUsers={props.clientUsers}
                                                                />
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!pagingLoader && allLoanBookingsData[activeLoanBookingsTag].length == 0) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'No Loan Vehicles'} /> </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                        {
                                            pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                                !_.isEmpty(hasMore) && hasMore.filter(e => e.type === activeLoanBookingsTag).length > 0 ?
                                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeLoanBookingsTag]: true
                                                        })
                                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                    : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>

            {/* Loan Bookings Modal */}
            <PopUpModal show={showLoanVehicle.show}>
                <LoanBookingsModal
                    show={showLoanVehicle.show}
                    title={'Loan Booking'}
                    handleClose={handleCloseLoanVehicle}
                    data={showLoanVehicle.data}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    handleUpdateLoanBooking={handleUpdateLoanBooking}
                    type={showLoanVehicle.type ? showLoanVehicle.type : 'total'}
                />
            </PopUpModal>

            {/* Loan Bookings List Modal */}
            {/* <PopUpModal show={showLoanBookings.show}>
                <LoanBookings
                    show={showLoanBookings.show}
                    type={showLoanBookings.type}
                    title={showLoanBookings.title ? showLoanBookings.title : 'Loan Bookings'}
                    handleClose={handleCloseLoanBookings}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    reloadLoanBookingCounts={reloadLoanBookingCounts}
                    setReloadLoanBookingCounts={setReloadLoanBookingCounts}
                />
			</PopUpModal> */}
        </>
    );
}

export default LoanBookingsWidget;