import React, { useState, useEffect } from 'react';
import "../../../styles/enquiry-contract.scss";
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import _ from 'lodash'
import Contract from './contract';
import { ReactSelect, PopUpModal } from '../../../components';
import { firestoreDB } from '../../../services/helper';
import Contracts from './list'
import CommonHelper from '../../../services/common'
const SalesContract = (props) => {
    const { salesContracts, enquiry, clientUsers, groupUsers, dealersettings } = props;
    const [autoitQuoteID, setContractID] = useState(null)
    const [modelFleetShow, setModelFleetShow] = useState(false)
    const _contract = autoitQuoteID && salesContracts && _.find(salesContracts, { documentID: autoitQuoteID }) ? _.find(salesContracts, { documentID: autoitQuoteID }) : null
    // let _contracts = [];
    // if (!_.isEmpty(salesContracts)) _contracts = _.map(Object.assign([], salesContracts), function (e) {
    //     return {
    //         ...e,
    //         value: e.documentID,
    //         label: e.request['purchase-order-number'] || '',
    //     }
    // })

    useEffect(() => {
        if (enquiry?.autoitQuoteID) {
            setContractID(enquiry?.autoitQuoteID)
        }
        else setContractID(null)
        // else if (!_.isEmpty(_contracts)) {
        //     setContractID(_contracts[0]?.documentID)
        //     saveContract(_contracts[0]?.documentID)
        // }
    }, [enquiry?.autoitQuoteID])


    const saveContract = (id) => {
        if (enquiry.documentID) {
            const updateRef = firestoreDB(dealersettings).firestore().collection("enquiries").doc(enquiry.documentID);
            updateRef.set({ autoitQuoteID: id }, { merge: true })
                .catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });


            let _logNote = `Order Number: ${_.find(salesContracts, { documentID: id })?.request['purchase-order-number']} linked.`
            CommonHelper.saveChangeLog(enquiry.clientID, null, enquiry.documentID, _logNote, "autoit", "enquiry");
        }

    }
    const handleReactSelectChange = (e, data) => {
        setContractID(e?.value || '')
        saveContract(e?.value || '')
    }

    const handleClose = (id) => {
        setModelFleetShow(false);
        if (id) { setContractID(id); saveContract(id) }
    }


    return (
        <>
            <div className="mid-column-contractnew-info">
                <div className="mid-column-contractnew-flex">
                    <div className="mid-column-contractnew-data form-style">

                        {
                            !_.isEmpty(_contract)
                                ?
                                <>
                                    <div className="mid-contractnew-dropdown-wrap">

                                        {
                                            _contract?.request && _contract?.request['sales-Status'] ? <div> Status <div className="badge badge-pill badge-open ml-1">{_contract?.request['sales-Status'].split(/(?=[A-Z])/).join(' ')}</div></div> : <></>
                                        }



                                        <div className="mid-contractnew-status">
                                            {
                                                _contract?.request && _contract?.request['purchase-order-number'] ? <div> Order No: <b>{_contract?.request['purchase-order-number']}</b></div> : <></>
                                            }
                                            <span><a href="#" className="mini-button float-right ml-2" onClick={(e) => { e.preventDefault(); setModelFleetShow(true) }}> <i className="ico icon-edit"></i></a></span>
                                        </div>

                                    </div>

                                    <Contract
                                        contract={_contract}
                                        clientUsers={clientUsers}
                                        groupUsers={groupUsers}
                                        dealersettings={dealersettings}
                                    />
                                </>
                                :
                                <>
                                    <div className="spinner-loader h-100 p-5">
                                        <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                            <div className="no-cafe-img">
                                                <i className="ico icon-contract-fill"></i> </div>
                                            <div className="no-data-txt mt-2"> <Translate text={'No Order Linked'} /></div>

                                            <button
                                                className="btn btn-sm btn-default mt-4"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setModelFleetShow(true)
                                                }}
                                            >
                                                <i className="ico icon-add mr-1"></i>{" "}
                                                <Translate text={"Link Order"} />
                                            </button>
                                        </div>

                                    </div>
                                </>
                        }



                    </div>
                </div>
            </div>


            <PopUpModal show={modelFleetShow}>
                <Contracts
                    {...props}
                    show={modelFleetShow}
                    handleClose={handleClose}
                >
                </Contracts>
            </PopUpModal>



        </>
    )
}

export default SalesContract