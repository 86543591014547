import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ReactSelect, ReactMultiSelect, AntDateRangePicker } from "../../components";
import CommonHelper from '../../services/common'
import { reportFilters, labels, enquiryStatus } from "./viewModel";
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { collection } from 'rxfire/firestore';
import moment from 'moment';

const leadTypes = [
    { value: 'new', label: 'New Leads Only' },
    { value: 'existing', label: 'Existing Leads Only' },
    { value: 'existingCustomer', label: 'Existing Customers Leads' },
    { value: 'newExistingSold', label: 'New Leads with Existing Sold / Test Drives' }
]

const vehicleBasedFilters = [
    { value: 'requirement', label: 'Requirement Model' },
    { value: 'testdrive', label: 'Test Drive Model' }
]

const DynamicFilters = (props) => {

    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [modelGroups, setModelGroups] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [dynOptions, setDynoptions] = useState([]);
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [lostReason, setLostreason] = useState([]);
    const [triggers, setTriggers] = useState([])
    const [integrations, setIntegrations] = useState();
    const [inbounduserOptions, setInboundUser] = useState([])

    useEffect(() => {

        let options = [];
        if ((props.filter === 'enquiryTypes' || props.filter === 'campaigns' || props.filter === 'origins' || props.filter === 'salesType' || props.filter === 'lostReasons' || props.filter === 'servicePackages' || props.filter === 'leadSource' || props.filter === 'pipelines')
            && !_.isEmpty(props.dealersettings.client.settings[props.filter])) {
            props.dealersettings.client.settings[props.filter].filter(a => a.active === true).map(rec => {
                return options.push({
                    value: rec.value,
                    label: rec.name,
                    subList: rec.subList ? rec.subList.map(r => {
                        return {
                            value: r.value,
                            label: r.name
                        }
                    }) : []
                });
            })

        }
        //(props.filter === 'salesType' && _.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0 ? props.dealersettings.accessSalesType.includes(a.value) : true)
        setDynoptions(options);
    }, [props.filter]);

    useEffect(() => {
        if (props.filter !== 'triggers')
            return
        let refOemTriggers = window.firebase.firestore().collection('triggers')
            .where('isDeleted', '==', false)
            .where('isActive', '==', true)
            .where('triggerType', '==', 'activity')
            .where('clientID', '==', props.dealersettings.client.id);
        let refclientTriggers = window.firebase.firestore().collection('triggers')
            .where('isDeleted', '==', false)
            .where('isActive', '==', true)
            .where('triggerType', '==', 'activity')
            .where('clientIDs', 'array-contains', props.dealersettings.client.id);

        const unSubTriggerCollectionzz = combineLatest(
            refOemTriggers ? collection(refOemTriggers) : of([]),
            refclientTriggers ? collection(refclientTriggers) : of([])
        ).pipe(
            map(([oemTriggers, clientTriggers]) => {
                return [
                    _.map(oemTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(clientTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } })
                ]
            }))
            .subscribe(([oemTriggers, clientTriggers]) => {
                let _triggers = [];
                oemTriggers.forEach((rec) => {
                    if (!_triggers.some(a => a.value === rec.title))
                        _triggers.push({
                            label: rec.title,
                            value: rec.title
                        })
                })
                clientTriggers.forEach((rec) => {
                    if (!_triggers.some(a => a.value === rec.title))
                        _triggers.push({
                            label: rec.title,
                            value: rec.title
                        })
                })
                setTriggers(_triggers)
            });

        return () => {
            unSubTriggerCollectionzz && unSubTriggerCollectionzz.unsubscribe()
        }
    }, [props.filter])

    useEffect(() => {

        if (props.fromOEM === true) {
            setIntegrations(props.integrations);
        }
        else {
            setIntegrations(props.dealersettings.client.integrations ? _.map(props.dealersettings.client.integrations.filter(e => e.inbound === true && e.enabled === true && e.active === true), function (el) {
                return {
                    label: el.name,
                    value: el.value
                }
            }) : [])
        }
    }, [props.integrations])

    useEffect(() => {
        if (lostReason.length === 1 && dynOptions.find(a => a.value === lostReason[0])) {
            setLostSubReasons(dynOptions.find(a => a.value === lostReason[0]).subList);
        }
        else {
            setLostSubReasons([]);
        }
    }, [lostReason])

    useEffect(() => {

        let brands = props.dealersettings.client.brands;
        let makes = [];
        let groups = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (props.objFilter.make || props.defaultMake) {
            let _make = props.objFilter.make ? props.objFilter.make : makes[0]?.value;
            let _models = _make && !_.isEmpty(makes.filter(m => m.value === _make)[0]) ? makes.filter(m => m.value === _make)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModelGroups(_.orderBy(groups, ['label'], ['asc']));
        setModels(_.orderBy(models, ['label'], ['asc']));
    }, [props.objFilter])

    useEffect(() => {
        // if (_.isEmpty(props.clientUsers))
        //     return
        let _users = [];
        if (props.fromOEM === true) {
            props.groupUsers.forEach(rec => {
                if (!_users.some(e => e.value === rec.id)) {
                    _users.push({
                        value: rec.id,
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        clientIDs: rec.clientIDs,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                }
            });
        }
        else {
            props.clientUsers.filter(a => Boolean(a.superAdmin) === false).forEach(rec => {
                _users.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>,
                    data: rec,
                    name: rec.name
                })
            })
        }

        setUserOptions(_users)
    }, [])

    useEffect(() => {
        // if (_.isEmpty(props.clientUsers))
        //     return
        let _roleids = props.dealersettings.client.roles ? props.dealersettings.client.roles.filter(rec => rec.pipeline && rec.pipeline.some(p => p === 'LeadsBucket')).map(rec => { return rec.id }) : [];
        let _users = [];
        if (props.fromOEM === true) {
            _.filter(props.groupUsers, (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).forEach(rec => {
                if (!_users.some(e => e.value === rec.id)) {
                    _users.push({
                        value: rec.id,
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        clientIDs: rec.clientIDs,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                }
            });
        }
        else {
            _.filter(props.clientUsers.filter(a => Boolean(a.superAdmin) === false), (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).forEach(rec => {
                _users.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>,
                    data: rec,
                    name: rec.name
                })
            })
        }

        setInboundUser(_users)
    }, [])


    const getRegions = () => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                (!_.isEmpty(props.objFilter['region']) ? props.objFilter['region'].includes(client.region) : true))) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getGroups = () => {
        let groupsToReturn;
        let filteredGroups;

        filteredGroups = props?.dealersettings?.groups?.filter(group => {
            if (props.dealersettings.clients.some(client => (client.group === group.id) &&
                (!_.isEmpty(props.objFilter['region']) ? props.objFilter['region'].includes(client.region) : true) &&
                (!_.isEmpty(props.objFilter['subregion']) ? props.objFilter['subregion'].includes(client.subregion) : true))) {
                return true
            }
            return false;
        })

        groupsToReturn = filteredGroups?.map(e => {
            return { label: e.name, value: e.id };
        })

        return groupsToReturn || []
    }



    const getDealers = () => {
        let clientsToReturn = props.dealersettings.clients;

        if (!_.isEmpty(props.objFilter['region'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                props.objFilter['region'].includes(client.region)
            );
        }

        if (!_.isEmpty(props.objFilter['subregion'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                props.objFilter['subregion'].includes(client.subregion)
            );
        }

        if (!_.isEmpty(props.objFilter['group'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                props.objFilter['group'].includes(client.group)
            );
        }
        clientsToReturn = clientsToReturn.map(client => {
            return {
                value: client.documentID,
                doc: client,
                label: client.name,
                active: _.isBoolean(client.active) ? client.active : true,
            };
        })

        return clientsToReturn
    }

    return (<>
        {
            props.filter === 'inboundUser' ? (
                <>
                    <label> {props.filterLabel || 'Inbound Users'}</label>
                    <ReactMultiSelect
                        options={inbounduserOptions}
                        name={"inboundUser"}
                        placeholder={'select user'}
                        onChange={(e, data) => {
                            props.setMultibjFilter(e, 'inboundUser')
                        }}
                        value={props.objFilter.inboundUser}
                        classNamePrefix={`cursor-pointer basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'user' ? (
                <>
                    <label>{props.filterLabel || 'Users'}</label>
                    <ReactMultiSelect
                        options={userOptions}
                        name={"user"}
                        placeholder={'select user'}
                        onChange={(e, data) => {
                            props.setMultibjFilter(e, 'user')
                        }}
                        value={props.objFilter.user}
                        classNamePrefix={`cursor-pointer basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'enqAddedBy' ? (
                <>
                    <label>{props.filterLabel || 'Lead Created By'}</label>
                    <ReactMultiSelect
                        options={userOptions}
                        name={"enqAddedBy"}
                        placeholder={'select user'}
                        onChange={(e, data) => {
                            props.setMultibjFilter(e, 'enqAddedBy')
                        }}
                        value={props.objFilter.enqAddedBy}
                        classNamePrefix={`cursor-pointer basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'label' ? (
                <>
                    <label>{props.filterLabel || 'Label'}</label>
                    <ReactMultiSelect
                        options={labels}
                        name={"label"}
                        placeholder={'select label'}
                        onChange={(e, data) => {
                            props.setMultibjFilter(e, 'label')
                        }}
                        value={props.objFilter.label}
                        classNamePrefix={`cursor-pointer basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'status' ? (
                <>
                    <label>{props.filterLabel || 'Status'}</label>
                    <ReactMultiSelect
                        options={enquiryStatus}
                        name={"status"}
                        placeholder={'select status'}
                        onChange={(e, data) => {
                            props.setMultibjFilter(e, 'status')
                        }}
                        value={props.objFilter.status}
                        classNamePrefix={`cursor-pointer basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'inboundSource' ? (
                <>
                    <label>{props.filterLabel || 'Inbound Source'}</label>
                    <ReactMultiSelect
                        options={integrations}
                        name={"dmstype"}
                        placeholder={'select inbound source'}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'dmstype')
                        }}
                        value={props.objFilter.dmstype}
                        classNamePrefix={` basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'makeModels' ? (<>
                <>
                    <label>{props.filterLabel || 'Make'}</label>
                    <ReactSelect
                        options={makes}
                        name={"make"}
                        placeholder={'select make'}
                        onChange={(e) => {
                            props.setObjFilter(e, 'make')
                        }}
                        value={props.objFilter.make}
                        classNamePrefix={` basic-select`}
                    >
                    </ReactSelect>
                </>
                <>
                    <label>Model</label>
                    <ReactMultiSelect
                        options={models}
                        name={"model"}
                        placeholder={'select model'}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'model')
                        }}
                        value={props.objFilter.model}
                        classNamePrefix={` basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
                {
                    props.columns && props.columns.includes('testdrive') ? (
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Vehicle Search Based On</label>
                                <ReactSelect
                                    options={vehicleBasedFilters}
                                    name={"vehicleSearchOn"}
                                    placeholder={'search based on'}
                                    onChange={(e) => {
                                        props.setObjFilter(e, 'vehicleSearchOn')
                                    }}
                                    value={props.objFilter.vehicleSearchOn}
                                    classNamePrefix={` basic-select`}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                    ) : (<></>)
                }
            </>) : (<></>)
        }
        {
            props.filter === 'makes' ? (<>
                <>
                    <label>{props.filterLabel || 'Make'}</label>
                    <ReactMultiSelect
                        options={makes}
                        name={'filter-make'}
                        placeholder={`select make`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'makes')
                        }}
                        value={props.objFilter['makes']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            </>) : (<></>)
        }
        {
            props.filter === 'models' ? (<>
                <>
                    <label>{props.filterLabel || 'Model'}</label>
                    <ReactMultiSelect
                        options={models}
                        name={'filter-model'}
                        placeholder={`select model`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'models')
                        }}
                        value={props.objFilter['models']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            </>) : (<></>)
        }
        {
            props.filter === 'vehicleGroups' ? (<>
                <>
                    <label>{props.filterLabel || 'groups'}</label>
                    <ReactMultiSelect
                        options={modelGroups}
                        name={'filter-groups'}
                        placeholder={`select model`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'vehicleGroups')
                        }}
                        value={props.objFilter['vehicleGroups']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            </>) : (<></>)
        }
        {
            (props.filter === 'enquiryTypes' || props.filter === 'campaigns' || props.filter === 'origins' || props.filter === 'salesType' || props.filter === 'lostReasons' || props.filter === 'servicePackages' || props.filter === 'leadSource' || props.filter === 'pipelines') ? (<>
                <>
                    <label>{props.filterLabel || (_.find(reportFilters, { 'value': props.filter }) ? _.find(reportFilters, { 'value': props.filter }).label : props.filter === 'servicePackages' ? 'Service Packages' : '')}</label>
                    <ReactMultiSelect
                        options={dynOptions}
                        name={props.filter}
                        placeholder={`select ${props.filterLabel || _.find(reportFilters, { 'value': props.filter }) ? _.find(reportFilters, { 'value': props.filter }).displayValue : props.filter === 'servicePackages' ? 'service packages' : ''}`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, props.filter)
                            if (props.filter === 'lostReasons') {
                                let value = [];
                                !_.isEmpty(e) && e.map((data) => {
                                    value.push(data.value)
                                })
                                setLostreason(value)
                            }
                        }}
                        value={props.objFilter[props.filter]}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
                {
                    props.filter === 'lostReasons' ? (
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Lost Sub Reason</label>
                                <ReactMultiSelect
                                    options={lostSubReasons}
                                    name={'lostsubreason'}
                                    placeholder={`select lost sub reason`}
                                    onChange={(e) => {
                                        props.setMultibjFilter(e, 'lostsubreason')
                                    }}
                                    value={props.objFilter['lostsubreason']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                        </div>
                    ) : (<></>)
                }
            </>) : (<></>)
        }
        {
            props.filter === 'leadType' ? (
                <>
                    <label>{props.filterLabel || 'Lead Type'}</label>
                    <ReactSelect
                        options={leadTypes}
                        name={"leadType"}
                        placeholder={'select lead type'}
                        onChange={(e) => {
                            props.setObjFilter(e, 'leadType')
                        }}
                        value={props.objFilter.leadType}
                        classNamePrefix={` basic-select`}
                        isSearchable={true}
                    >
                    </ReactSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'triggers' ? (
                <>
                    <label>{props.filterLabel || 'Triggers'}</label>
                    <ReactMultiSelect
                        options={triggers}
                        name={'triggers'}
                        placeholder={`select triggers`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'triggers')
                        }}
                        value={props.objFilter['triggers']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'region' ? (
                <>
                    <label>{props.filterLabel || 'Regions'}</label>
                    <ReactMultiSelect
                        options={getRegions()}
                        name={'region'}
                        placeholder={`select region`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'region')
                        }}
                        value={props.objFilter['region']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'subregion' ? (
                <>
                    <label>{props.filterLabel || 'Sub Regions'}</label>
                    <ReactMultiSelect
                        options={getSubregions()}
                        name={'subregion'}
                        placeholder={`select subregion`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'subregion')
                        }}
                        value={props.objFilter['subregion']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'group' ? (
                <>
                    <label>{props.filterLabel || 'Groups'}</label>
                    <ReactMultiSelect
                        options={getGroups()}
                        name={'group'}
                        placeholder={`select group`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'group')
                        }}
                        value={props.objFilter['group']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'dealer' ? (
                <>
                    <label>{props.filterLabel || 'Dealers'}</label>
                    <ReactMultiSelect
                        options={getDealers()}
                        name={'dealer'}
                        placeholder={`select dealer`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'dealer')
                        }}
                        value={props.objFilter['dealer']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'client' ? (
                <>
                    <label>{props.filterLabel || 'Clients'}</label>
                    <ReactMultiSelect
                        options={props.dealersettings.clients ? props.dealersettings.clients.map(rec => {
                            return {
                                label: rec.name,
                                value: rec.id
                            }
                        }) : []}
                        name={'client'}
                        placeholder={`select client`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'client')
                        }}
                        value={props.objFilter['client']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'daterange' ? (
                <>
                    <label>{props.filterLabel || 'Date'}</label>
                    <AntDateRangePicker
                        value={props.objFilter['daterange'] ? [moment.unix(props.objFilter['daterange'].startDate.seconds), moment.unix(props.objFilter['daterange'].endDate.seconds)] : null}
                        id={"dashboard-date-range"}
                        name={'dateDashboard'}
                        format='DD/MM/YYYY'
                        onChange={(e) => { props.handleDateRangeChange(e, 'daterange') }}
                        placeholder='DD/MM/YYYY'
                    />
                </>
            ) : (<></>)
        }
        {
            props.filter === 'customPipeline' ? (
                <>
                    <label>{props.filterLabel || 'Pipelines'}</label>
                    <ReactMultiSelect
                        options={props.pipelineOptions || []}
                        name={'pipeline'}
                        placeholder={`select pipeline`}
                        onChange={(e) => {
                            props.setMultibjFilter(e, 'pipelines')
                        }}
                        value={props.objFilter['pipelines']}
                        classNamePrefix={`basic-select`}
                        isMulti={true}
                        isSearchable={true}
                    >
                    </ReactMultiSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'customclient'
                || props.filter === 'customstatus'
                || props.filter === 'source'
                || props.filter === 'dietary' ? (
                <>
                    <label>{CommonHelper.autoCaps(props.filter.replace('custom', ''))}</label>
                    <ReactSelect
                        options={props[`${props.filter.replace('custom', '')}s`] || []}
                        name={props.filter}
                        placeholder={`select ${props.filter.replace('custom', '')}`}
                        onChange={(e) => {
                            props.setObjFilter(e, props.filter.replace('custom', ''))
                        }}
                        value={props.objFilter[props.filter.replace('custom', '')]}
                        classNamePrefix={` basic-select`}
                    >
                    </ReactSelect>
                </>
            ) : (<></>)
        }
        {
            props.filter === 'slot' ? (
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Slot Date</label>
                        <ReactSelect
                            options={props.slots || []}
                            name={'slotdate'}
                            placeholder={`select date`}
                            onChange={(e) => {
                                props.setObjFilter(e, 'slotdate', props.slots)
                            }}
                            value={props.objFilter.slotdate}
                            classNamePrefix={` basic-select`}
                        >
                        </ReactSelect>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Time</label>
                        <ReactSelect
                            options={_.find(props.slots, { value: props.objFilter.slotdate })?.slots || []}
                            name={'slot'}
                            placeholder={`select slot`}
                            onChange={(e) => {
                                props.setObjFilter(e, 'slot')
                            }}
                            value={props.objFilter.slot}
                            classNamePrefix={` basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
            ) : (<></>)
        }
    </>)

}

export default DynamicFilters;