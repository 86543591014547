import React, { useState, useEffect, useContext } from 'react';
import { collection } from 'rxfire/firestore';
import { combineLatest } from 'rxjs';
import _ from 'lodash'
import { map } from 'rxjs/operators'
import Translate from '../../../../constants/translate'
import { PopUpModal, ContentOverlay, TableView, ReactSelect } from '../../../../components'
import { templatelistFields } from './viewModel'
import ImportCSV from './importCSV';
import { servicePartsVM } from '../lookups/viewModel';
import { TemplateContext } from '../templateContext'
import toast from 'toasted-notes'
import AddPart from './addPart';
import Swal from 'sweetalert2';
import { default as _images } from '../../../../images';
import CommonHelper from '../../../../services/common';

const ServiceParts = (props) => {
    const [searchText, setSearchText] = useState('')
    const [serviceParts, setServiceParts] = useState('')
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [showImportCSV, setShowImportCSV] = useState({
        show: false,
        title: '',
    });
    const [showAddPart, setShowAddPart] = useState({
        show: false,
        title: '',
        data: null
    });
    const [fullPageLoader, setFullPageLoader] = useState({
        show: false,
        title: '',
    });
    const [clientOptions, setClientOptions] = useState([]);
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore0, setHasMoreData0] = useState([])
    const [hasMore1, setHasMoreData1] = useState([])
    const [loader, setLoader] = useState(true)
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [queryParam, setQueryParam] = useState({})
    const [selectedIds, setSelectedIds] = useState({})

    const { clients } = useContext(TemplateContext);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client })
            });
            setClientOptions(_clients)
        }
    }, [clients])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length > 2) {
                setDataload(true)
            } else if (searchText && searchText.length < 2) { setDataload(false) }

            if (searchText === '')
                setDataload(true)
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    useEffect(() => {
        let brands = props.dealersettings.allMakeModels;
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (queryParam && queryParam.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === queryParam.make)[0]) ? makes.filter(m => m.value === queryParam.make)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [queryParam])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })

        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        const refs = []
        let _0 = []
        let _1 = []

        let partsByClient = window.firebase.firestore().collection(`serviceParts`).where('isDeleted', '==', false)
        let partsByOEM = window.firebase.firestore().collection(`serviceParts`).where('oemID', '==', props.dealersettings.client.settingsID).where('isDeleted', '==', false)

        if (searchText && searchText.trim()) {
            partsByClient = partsByClient.where('keywords', 'array-contains', searchText.toLowerCase().trim())
            partsByOEM = partsByOEM.where('keywords', 'array-contains', searchText.toLowerCase().trim())
        }
        else {
            partsByClient = partsByClient.where('clientIDs', 'array-contains', props.dealersettings.client.id)
        }

        if (queryParam && queryParam.make) {
            partsByClient = partsByClient.where('make', '==', queryParam.make)
            partsByOEM = partsByOEM.where('make', '==', queryParam.make)
        }
        if (queryParam && queryParam.model) {
            partsByClient = partsByClient.where('model', '==', queryParam.model)
            partsByOEM = partsByOEM.where('model', '==', queryParam.model)
        }
        if (hasMore0.length) {
            partsByClient = partsByClient.startAfter(hasMore0[0]).limit(100)
        } else {
            partsByClient = partsByClient.limit(100)
        }

        if (hasMore1.length) {
            partsByOEM = partsByOEM.startAfter(hasMore1[0]).limit(100)
        } else {
            partsByOEM = partsByOEM.limit(100)
        }

        refs.push(collection(partsByClient))
        refs.push(collection(partsByOEM))

        var servicePartsObserver = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    setDataload(false)
                    let valToReturn = []
                    // console.log('part lookups,', lookups)
                    if (lookups[0] && lookups[0].length) {
                        lookups[0].forEach((lookup) => {
                            let _dataByClient = lookup.data();

                            if ((searchText && searchText.trim() && _dataByClient.clientIDs && _dataByClient.clientIDs.some(b => b === props.dealersettings.client.id)) ||
                                _.isEmpty(searchText) ||
                                (searchText && _.isEmpty(searchText.trim())))
                                valToReturn.push({
                                    ..._dataByClient, id: lookup.id, documentID: lookup.id, levelName: 'OEM', 'vehicle': `${_dataByClient.make ? _dataByClient.make : ''} ${_dataByClient.model ? _dataByClient.model : ''}`
                                })
                        })
                        _0 = [lookups[0][lookups[0].length - 1]]
                    }
                    if (lookups[1] && lookups[1].length) {
                        lookups[1].forEach((lookup) => {
                            valToReturn.push({
                                ...lookup.data(), id: lookup.id, documentID: lookup.id, levelName: 'OEM', 'vehicle': `${lookup.data().make ? lookup.data().make : ''} ${lookup.data().model ? lookup.data().model : ''}`
                            })
                        })
                        _1 = [lookups[1][lookups[1].length - 1]]
                    }
                    return valToReturn
                }))
            .subscribe(allDocs => {
                if (hasMore0.length || hasMore1.length) {
                    setServiceParts(_.uniqBy([...serviceParts, ...allDocs], 'documentID'))
                } else {
                    setServiceParts(_.uniqBy(allDocs, 'documentID'))
                }
                setHasMoreData0(_0)
                setHasMoreData1(_1)
                setLoader(false)
                _0 = []
                _1 = []
            });
        return () => {
            servicePartsObserver && servicePartsObserver.unsubscribe()
        }
    }, [checkDataLoad])

    const convertVM = (data) => {
        let obj = { ...data }
        obj.partCost = `${currencySymbol} ${obj.partCost}`
        return obj
    }

    const handleActionClick = (id, data) => (
        <div className="action-fix">
            {
                data.disableEdit ? (<div style={{ cursor: 'pointer' }} className="mini-button mr-2">
                    <i className="ico icon-lock"></i>
                </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditLookup(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>)
            }
            {
                data.disableEdit ? (<div style={{ cursor: 'pointer' }} className="mini-button">
                    <i className="ico icon-lock"></i>
                </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteLookup(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>)
            }

        </div>
    )



    const handledetailsClick = () => {

    }

    const hadleEditLookup = data => {
        setShowAddPart({
            show: true,
            title: 'Edit Service Part',
            data,
        })
    }


    const hadleDeleteLookup = async (data) => {
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete this service part.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        })

        if (!result.value) {
            return
        }

        let all = serviceParts.filter(a => a.documentID !== data.documentID)
        setServiceParts(_.uniqBy(all, 'documentID'))

        await window.firebase.firestore().collection(`serviceParts`).doc(data.documentID).set({ isDeleted: true }, { merge: true })

        toast.notify(('Service Part deleted successfully'), {
            duration: 2000
        })
    }

    const handleSwitchChange = async (id, checked, data) => {
        // console.log('handleSwitchChange', id, checked, data)

        let all = []
        all = serviceParts.map(a => {
            if (a.documentID === data.documentID) {
                return { ...a, active: checked }
            } else {
                return a
            }
        })

        setServiceParts(_.uniqBy(all, 'documentID'))

        await window.firebase.firestore().collection(`serviceParts`).doc(data.documentID).set({ active: checked }, { merge: true })

        toast.notify((`Service Part ${checked ? 'enabled' : 'disabled'}`), {
            duration: 2000
        })
    }


    const handlePagination = () => {
        setDataload(true)
    }

    const handleCloseAddPart = (data, newLookup) => {
        setShowAddPart({
            show: false,
            title: '',
            data: null
        })

        if (data && newLookup) {
            setServiceParts(_.uniqBy([data, ...serviceParts], 'documentID'))
        } else if (data && !newLookup) {
            let all = []
            all = serviceParts.map(a => {
                if (a.documentID === data.documentID) {
                    return {
                        ...a,
                        ...data,
                        vehicle: `${data.make ? data.make : ''} ${data.model ? data.model : ''}`
                    }
                } else {
                    return a
                }
            })
            setServiceParts(_.uniqBy(all, 'documentID'))
        }
    }

    const handleShowImportCSVClose = async (data, level, levelIDs) => {
        // console.log(`handleShowImportCSVClose data, level, levelIDs`, data, level, levelIDs)
        setShowImportCSV({
            show: false,
        })

        if (!_.isEmpty(data)) {
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> importing service parts...</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
            let all = []
            all = _.uniqBy([...data], 'partNumber')
            all = all.map(a => {
                let obj = { ...a }
                if (level === 'oem') {
                    obj.oemID = props.dealersettings.oemID
                    obj.level = level
                } else if (level === 'individual') {
                    obj.level = level
                    obj.clientIDs = levelIDs
                    delete obj.oemID
                }
                return obj
            })
            const allParts = [...all]
            try {

                const arrayOfRecipientsToSave = []
                while (all.length > 0) {
                    arrayOfRecipientsToSave.push(all.splice(0, 300));
                }
                await Promise.all(
                    arrayOfRecipientsToSave.map(async (arrayOfMessages, index) => {
                        const batch = window.firebase.firestore().batch();
                        arrayOfMessages.forEach(rec => {
                            batch.set(window.firebase.firestore().collection(`serviceParts`).doc(rec.documentID.replace(/\//g, '-')), rec, { merge: true });
                        })
                        await batch.commit()
                    })
                )
                toast.closeAll()
                toast.notify('Service Parts imported successfully', {
                    duration: 2000
                })
            }
            catch (error) {
                toast.closeAll();
                console.log(error);
                Swal.fire(CommonHelper.showLocale(props, 'Something went wrong. Please contact support.'), '', 'error')
            }
            setServiceParts(_.uniqBy([...allParts.map(rec => {
                return {
                    ...rec,
                    vehicle: `${rec.make ? rec.make : ''} ${rec.model ? rec.model : ''}`
                }
            }), ...serviceParts,], 'documentID'))
        }
    }

    const convertBeforeRender = list => {
        let newList = []
        newList = list.map(a => convertVM(a))
        return newList
    }

    const handlesearchText = (val) => {
        //setSearchText(val);
        setLoader(true)
        setHasMoreData0([]);
        setHasMoreData1([]);
        //setDataload(true);
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(serviceParts) && serviceParts.map(file => {
                let record = Object.assign({}, serviceParts.filter(e => e.documentID === file.documentID)[0]);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, serviceParts.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const deleteAll = async () => {

        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the selected service part(s).'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        })

        if (!result.value) {
            return
        }

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> deleting service parts...</div>),
            {
                position: 'top',
                duration: null
            },
        )

        let _selectedIds = Object.keys(selectedIds).map(cid => cid)
        const batch = window.firebase.firestore().batch();
        for (let [key, value] of Object.entries(selectedIds)) {
            batch.set(window.firebase.firestore().doc(`serviceParts/${key}`), { isDeleted: true }, { merge: true });
        }

        batch.commit()
            .then(() => {
                toast.closeAll();
                setSelectedIds({});
                setServiceParts(_.uniqBy(serviceParts.filter(e => !_selectedIds.includes(e.documentID)), 'documentID'))
                let _leadsSize = Object.keys(selectedIds).length;
                toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} service part(s) deleted successfully.`, {
                    duration: 2000
                })
            })
            .catch((error) => {
                toast.closeAll();
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }
    return (
        <>
            <h2> <Translate text={'Service Parts'} /></h2>
            <div className="divider-line"></div>
            <div className="settings-pipeline-wrap">
                <div className="settings-head">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="filter-search search-icon">
                                <input placeholder="Search" aria-label="Search" value={searchText}
                                    onChange={(e) => {
                                        let _val = e.target.value;
                                        setSearchText(_val);

                                        if (!_val) handlesearchText('')
                                        if (_val && _val.length > 2) handlesearchText(_val)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') handlesearchText(searchText)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-3 pl-0">
                                    <ReactSelect
                                        options={makes}
                                        name={"make"}
                                        placeholder={'select make'}
                                        onChange={(e) => {
                                            setQueryParam({
                                                ...queryParam,
                                                'make': e ? e.value : null,
                                                'model': null
                                            })
                                            setLoader(true)
                                            setHasMoreData0([]);
                                            setHasMoreData1([]);
                                            setDataload(true);
                                        }}
                                        value={queryParam ? queryParam.make : null}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="col-md-3 pl-0">
                                    <ReactSelect
                                        options={models}
                                        name={"model"}
                                        placeholder={'select model'}
                                        onChange={(e) => {
                                            setQueryParam({
                                                ...queryParam,
                                                'model': e ? e.value : null
                                            })
                                            setLoader(true)
                                            setHasMoreData0([]);
                                            setHasMoreData1([]);
                                            setDataload(true);
                                        }}
                                        value={queryParam ? queryParam.model : null}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">
                                {
                                    !_.isEmpty(selectedIds) && (<button type="button" className="btn btn-red float-right ml-2" onClick={(e) => {
                                        e.preventDefault();
                                        deleteAll();
                                    }} >   <Translate text={'Delete'} /></button>)
                                }
                                <button type="button" className="btn btn-secondary float-right ml-2" onClick={() => {
                                    setShowImportCSV({
                                        show: true,
                                    })
                                }} >   <Translate text={'Import CSV'} /></button>
                                <button type="button" className="btn btn-primary float-right ml-2" onClick={() => {
                                    setShowAddPart({
                                        show: true,
                                        title: 'Add Service Part'
                                    })
                                }} >   <Translate text={'Add Service Part'} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        !_.isEmpty(serviceParts) ? (
                            <div className="common-table">
                                <TableView
                                    // datalist={convertBeforeRender(serviceParts.filter(({ partNumber, partName, partCost }) =>
                                    // (!_.isEmpty(searchText) ? (
                                    //     (!_.isEmpty(partNumber) ? partNumber.toLowerCase().includes(searchText.toLowerCase().trim()) : false) ||
                                    //     (!_.isEmpty(partCost) ? partCost.toLowerCase().includes(searchText.toLowerCase().trim()) : false) ||
                                    //     (!_.isEmpty(partName) ? partName.toLowerCase().includes(searchText.toLowerCase().trim()) : false)
                                    // ) : true)
                                    // ))}
                                    datalist={convertBeforeRender(serviceParts)}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={templatelistFields}
                                    dynamicFields={templatelistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handledetailsClick}
                                    isSettings={false}
                                    isReorderable={false}
                                    hasMore={hasMore0.length || hasMore1.length ? { hasMore: 1 } : []}
                                    handlePagination={handlePagination}
                                    handleSwitchChange={handleSwitchChange}
                                    isPaging={true}
                                    displayByValue={true}
                                    selectedIds={selectedIds}
                                    handleAllChecked={handleAllChecked}
                                    handleCheckChange={handleCheckChange}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className='spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente'
                                    style={{ minHeight: '360px' }}
                                >
                                    <div className='no-data-flex h-100 justify-content-center align-items-center '>
                                        <div className='no-data-img'>
                                            {' '}
                                            <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
                                        </div>
                                        <div className='no-data-txt mt-2'>
                                            {' '}
                                            <Translate
                                                text={'No service parts added yet'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                }

            </div>
            <PopUpModal show={showImportCSV.show}>
                <ImportCSV
                    show={showImportCSV.show}
                    handleClose={handleShowImportCSVClose}
                    clientOptions={clientOptions}
                    title={showImportCSV.title}
                    recipients={servicePartsVM}
                    setRecipients={setServiceParts}
                    dealersettings={props.dealersettings}
                    setFullPageLoader={setFullPageLoader}
                    fullPageLoader={fullPageLoader}
                />
            </PopUpModal>
            <PopUpModal show={showAddPart.show}>
                <AddPart
                    show={showAddPart.show}
                    handleClose={handleCloseAddPart}
                    clientOptions={clientOptions}
                    title={showAddPart.title}
                    lookup={showAddPart.data}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
        </>
    )
}

export default ServiceParts
