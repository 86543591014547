import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import { ContentOverlay, TableView, PopUpModal } from '../../components'
import AddOEM from '../settings/company/oemSetup/addOEM'
import { oemSwitchlistFields } from '../settings/company/oemSetup/viewModel'
import { ClientContext } from '../layout/clientContext'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import Translate from '../../constants/translate'
import { default as _images } from '../../images';
import DealerListView from './listView'

const Dealers = (props) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth - 30,
    windowHeight: window.innerHeight - 123,
  });
  const [oemData, setOEMData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [loader, setLoader] = useState(true)
  const [showOEMModel, setShowOEMModel] = useState(false)
  const [switchLoading, setSwitchLoading] = useState(false)
  const [popHeader] = useState('')
  const [selectedOEM, setSelectedOEM] = useState('')
  const [showDealerModel, setShowDealerModel] = useState({ show: false })

  const { oemSettingsWithClients, oemBrands } = useContext(ClientContext);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth - 30,
        windowHeight: window.innerHeight - 123,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      //window.unSubscribeCompanies && window.unSubscribeCompanies();
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(oemSettingsWithClients) || _.isEmpty(oemBrands))
      return

    let _oemSettingsWithClients = [];
    oemSettingsWithClients.forEach((rec) => {
      _oemSettingsWithClients.push({
        ...rec,
        strBrands: (<div className="Vehicle-availability">
          {
            _.filter(oemBrands, (v) => _.indexOf(rec.brandIDs, v.documentID) >= 0).map((rec, index) => {
              return <div key={index}><span>{rec.name}</span></div>
            })
          }
        </div>),
        strDealers: (<div className="Vehicle-availability">
          {
            rec.oemClients.filter(a => a.name).map((oemClient) => {
              return <div key={oemClient.documentID}><span>{oemClient.name}</span></div>
            })
          }
        </div>),
        dealerNames: rec.oemClients.filter(a => a.name).map((oemClient, index) => {
          return oemClient.name
        }),
        _brands: _.filter(oemBrands, (v) => _.indexOf(rec.brandIDs, v.documentID) >= 0).map((rec, index) => {
          return rec.name
        }),
        clients: [
          { label: 'OEM', value: 'oem' },
          ...rec.oemClients.filter(a => a.name).map((oemClient, index) => {
            return {
              label: oemClient.name,
              value: oemClient.documentID
            }
          })],
      })
    })
    setOEMData(convertVM(_oemSettingsWithClients.filter(a => !_.isEmpty(a.name))))
    setLoader(false)
  }, [oemSettingsWithClients, oemBrands])

  const convertVM = (data) => {
    let list = [...data]

    list = list.map(item => {
      return {
        ...item,
        _name: <div className="user-list-items-info pr-0 "><h4 title={`${item.name}`}>{item.name}</h4></div>
      }
    })

    return list
  }

  const handleViewOEM = async (data, _id) => {
    try {
      // console.log('handleViewOEM', data)
      // const result = await Swal.fire({
      //   title: 'Are you sure?',
      //   text: 'Do you want to switch to ' + data.name + '?',
      //   icon: 'info',
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   cancelButtonText: 'No',
      // })



      // if (!result.value) {
      //   return
      // }
      setSwitchLoading(true)
      let id = _id;
      if (id === 'oem') {
        id = null;
      }
      const objToUpdate = {
        role: 'Administrator',
        roleID: '',
        department: 'Management',
        defaultClientID: '',
        level: 'oem',
        arrayClientID: []
      }

      let clientsCollection = []

      // Get All Roles and Clients of the selected OEM
      let clientID = data.documentID ? data.documentID : data.id

      const rolesRef = window.firebase.firestore().collection('clientSettings').doc(clientID).collection('roles')
      const rolesSnap = await rolesRef.get()
      let rolesData = []
      if (rolesSnap.size > 0) {
        rolesSnap.forEach(doc => {
          rolesData.push(doc.data())
        })
      }

      const clientsRef = window.firebase.firestore().collection('clients').where('settingsID', '==', clientID)
      const clientsSnap = await clientsRef.get()
      let _clients = []
      let _adminClients = []
      const adminclientPromise = []
      if (clientsSnap.size > 0) {
        clientsSnap.forEach(doc => {
          adminclientPromise.push(window.firebase.firestore().doc(`users/${localStorage.uid}/adminClients/${doc.id}`).get())
          _clients.push(doc.data())
        })
      }
      //fetch link dealers
      const linkclientsRef = window.firebase.firestore().collection('clients').where('settingsIDs', 'array-contains', clientID)
      const linkclientsSnap = await linkclientsRef.get()
      if (linkclientsSnap.size > 0) {
        linkclientsSnap.forEach(doc => {
          adminclientPromise.push(window.firebase.firestore().doc(`users/${localStorage.uid}/adminClients/${doc.id}`).get())
          _clients.push(doc.data())
        })
      }
      _clients = _clients.filter(a => a.documentID && a.name)


      const snapshots = await Promise.all(adminclientPromise)
      snapshots.forEach(snap => {
        if (snap.exists) {
          _adminClients.push({
            ...snap.data(),
            id: snap.id
          });
        }
      })

      // console.log('rolesData', rolesData)
      // console.log('clientsData', _clients)

      // Set The RoleID
      let roleID = ''

      // check role administrator exists in roles
      if (rolesData.filter(a => a.name === 'Administrator').length > 0) {
        let _role = rolesData.filter(a => a.name === 'Administrator')[0]
        roleID = _role.documentID
      } else if (rolesData.filter(a => a.level === 'oem').length > 0) {
        let _role = rolesData.filter(a => a.level === 'oem')[0]
        roleID = _role.documentID
      } else {
        roleID = null
      }

      if (!roleID) {
        // console.log('no role id')
        toast.notify(`Dealer invalid, no role found`, {
          duration: 2000
        })
        setSwitchLoading(false)
        return
      }

      // Now set the values of user obj to update
      objToUpdate.arrayClientID = _clients.map(client => client.documentID)
      objToUpdate.defaultClientID = id ? id : objToUpdate.arrayClientID[0] ? objToUpdate.arrayClientID[0] : ''
      objToUpdate.roleID = roleID
      objToUpdate.settingsID = clientID
      if (_.isEmpty(id)) {
        localStorage.removeItem('oemswitchclient');
      }
      else {
        localStorage.setItem('oemswitchclient', objToUpdate.defaultClientID);
      }

      // Set the clientCollection
      clientsCollection = _clients.map(client => {
        return {
          department: 'Management',
          defaultModule: 'enquiry',
          name: client.name ? client.name : '',
          roleID,
          documentID: client.documentID ? client.documentID : ''
        }
      })

      // console.log('return objToUpdate', objToUpdate)
      // console.log('return clientsCollection', clientsCollection)

      // if (clientsCollection.length <= 0) {
      //   toast.notify(`${data.name} does not have any clients`, {
      //     duration: 2000
      //   })
      //   setSwitchLoading(false)
      //   return
      // }

      // update the user document
      const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
      await userRef.set(objToUpdate, { merge: true })


      const batchToDelete = window.firebase.firestore().batch();
      const batchToWrite = window.firebase.firestore().batch();

      // delete the userClients
      const userClientsRef = window.firebase.firestore().doc(`users/${localStorage.uid}`).collection('userClients')
      const userClientsSnap = await userClientsRef.get()
      let _userClients = []
      if (userClientsSnap.size > 0) {
        userClientsSnap.forEach(doc => {
          _userClients.push({ ...doc.data(), id: doc.id })
          batchToDelete.delete(userClientsRef.doc(doc.id));
        })
        _userClients.forEach(client => {
          window.firebase.firestore().doc(`users/${localStorage.uid}/adminClients/${client.id}`).set({ ...client, isQueue: false, queueNum: 0 }, { merge: true })
          window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${client.id}`).set({ isQueue: false, queueNum: 0 }, { merge: true })
        })
        await batchToDelete.commit()
      }

      // the user clients
      clientsCollection.forEach(clientObj => {
        batchToWrite.set(userClientsRef.doc(clientObj.documentID), {
          ...(_.find(_adminClients, { id: clientObj.documentID }) ? _.find(_adminClients, { id: clientObj.documentID }) : {}),
          department: clientObj.department,
          name: clientObj.name,
          roleID: clientObj.roleID,
          defaultModule: clientObj.defaultModule,
        }, { merge: true });
      })

      const dashboardRef = await window.firebase.firestore().doc(`users/${localStorage.uid}/adminDashboardSettings/${clientID}`).get();
      if (dashboardRef.exists) {
        window.firebase.firestore().doc(`users/${localStorage.uid}/salesDashboardSettings/${localStorage.uid}`).set({
          ...dashboardRef.data()
        }, { merge: true });
      }

      await batchToWrite.commit()

      props.setUser(objToUpdate)
      setSwitchLoading(false)
      props.history.push('/');
    } catch (error) {
      console.error(error)
      setSwitchLoading(false)
    }
  }

  const handleActionClick = (id, data) => {
    return (
      <div style={{
        flexDirection: 'rowReverse',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        {/* <div style={{ cursor: 'pointer' }} className="mini-button mr-2" onClick={(e) => {
          handleViewOEM(data)
        }}>
          <i className="ico icon-zoom"></i>
        </div> */}
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip><span>View</span></Tooltip>
          }
        >
          <div
            style={{ cursor: 'pointer', float: 'right' }}
            className='mini-button mr-2'
            onClick={e => {
              //handleViewOEM(data)
              setShowDealerModel({
                show: true,
                data: data,
              })
            }}
          >
            <i className='ico icon-zoom'></i>
          </div>
        </OverlayTrigger>
        {/* <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
          hadleEditOEM(data)
        }}>
          <i className="ico icon-edit"></i>
        </div> */}
        {/* <OverlayTrigger
						placement='bottom'
						overlay={
								<Tooltip><span>Edit</span></Tooltip>
						}
				>
					<div
						style={{ cursor: 'pointer', float: 'right' }}
						className='mini-button'
						onClick={e => {
							hadleEditOEM(data)
						}}
					>
						<i className='ico icon-edit'></i>
					</div>
				</OverlayTrigger> */}
      </div>
    )
  }

  const handleOEMdetailsClick = (e, id, data) => {
    e.preventDefault();
    setShowDealerModel({
      show: true,
      data: data,
    })
    //handleViewOEM(data,id,true)
    //setSwitchLoading(true)
  }


  const handleModelClose = () => {
    setSelectedOEM(null)
    setShowOEMModel(false)
    setShowDealerModel({
      show: false
    })
  }



  const onSearchChange = (event) => {
    const text = event.target.value
    setSearchText(text)
  }

  const filterSearch = (name, brands, dealerNames) => {
    let valToReturn = false
    const _searchText = searchText.toLowerCase()
    if (searchText && name) {
      if (name.toLowerCase().includes(_searchText.toLowerCase())) {
        valToReturn = true
      }

      if (brands.some(brandName => brandName.toLowerCase().includes(_searchText))) {
        valToReturn = true
      }

      if (dealerNames.some(clientName => clientName.toLowerCase().includes(_searchText))) {
        valToReturn = true
      }
    } else {
      valToReturn = true
    }

    return valToReturn
  }

  useEffect(() => {
    if (document.querySelector('.container-fluid')?.classList?.contains('pl-0')) {
      document.querySelector('.container-fluid').classList.remove('pl-0')
      document.querySelector('.container-fluid').classList.remove('pr-0')
    }
    return () => {
      document.querySelector('.container-fluid')?.classList?.add('pl-0')
      document.querySelector('.container-fluid')?.classList?.add('pr-0')
    }
  }, [])

  return (
    <>
      {/* <h2>OEM Setup
      <div className="float-right">
          <button type="button" className="btn btn-default float-left" onClick={() => {
              setbtnLoader(true)
            window.firebase.firestore().doc(`deployments/webDeployments`)
              .set({
                updatedOn : window.firebase.firestore.Timestamp.now()
              }, { merge: true })
              .then(() => {
                setbtnLoader(false)
                toast.notify('Update successfully', {
                  duration: 2000
              })
              })
          }}>
            {
                btnLoader ?
                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                    : (<></>)
            }
            Push Live Updates
          </button>
        </div>
      </h2> */}
      {/* <div className="divider-line"></div> */}
      {switchLoading && (
        <div className="lds-roller-loader text-center "><div className="lds-roller-loader-inner "><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3">Fetching dealer data...</p></div></div>
      )}
      <div className="middle-wrapper">
        <div className="filter-panel">
          <div className="d-flex justify-content-between">
            <div className="filter-search search-icon">
              <div className='input-group template-search'>
                <input placeholder="Search" aria-label="Search" value={searchText} onChange={onSearchChange} />
                <div className='input-group-append input-search-clear'>
                  {searchText.length > 0 && (
                    <a href='#' className='input-search-clear-icon' style={{ top: '9px' }} onClick={() => setSearchText('')}>
                      <i className='ico icon-remove'></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
            {oemData.length && (
              <div className="list-count-btn">
                <span>Dealers</span>
                <span className="list-count"> {oemData.length} </span>
              </div>
            )}
          </div>
        </div>
        {
          loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
            oemData.length > 0 ? (
              <div className='contact-panel'
                style={{ width: windowSize.windowWidth + 'px' }}>
                {oemData.filter(({ name, _brands, dealerNames }) => filterSearch(name, _brands, dealerNames)).length > 0 ? (
                  <TableView
                    datalist={_.orderBy(oemData.filter(({ name, _brands, dealerNames }) => filterSearch(name, _brands, dealerNames)), ['name'], ['asc'])}
                    // ), ['addedDate.seconds'], ['desc'])}
                    height={windowSize.windowHeight}
                    width={windowSize.windowWidth}
                    columns={oemSwitchlistFields}
                    dynamicFields={oemSwitchlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                    handleActionClick={handleActionClick}
                    handleRowSingleClick={handleOEMdetailsClick}
                    isSettings={false}
                    isReorderable={false}
                  />
                ) : (
                  <div
                    className='spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente'
                    style={{ minHeight: '360px' }}
                  >
                    <div className='no-data-flex h-100 justify-content-center align-items-center '>
                      <div className='no-data-img'>
                        {' '}
                        <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width='60' height='60' alt='' />
                      </div>
                      <div className='no-data-txt mt-2'>
                        {' '}
                        <Translate
                          text={'No results found, please enter different keywords'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="common-table">
                <div className="text-center p-5">
                  <p>No dealers found.</p>
                </div>
              </div>
            )
        }
      </div>
      <PopUpModal show={showOEMModel}>
        <AddOEM
          show={showOEMModel}
          title={popHeader}
          handleClose={handleModelClose}
          selectedOEM={selectedOEM}
        />
      </PopUpModal>
      <PopUpModal show={showDealerModel.show}>
        <DealerListView
          {...showDealerModel}
          handleClose={(data, id) => {
            if (data) {
              handleViewOEM(data, id)
            }
            setShowDealerModel({ show: false });
          }}
        />

      </PopUpModal>
    </>
  )
}

export default Dealers