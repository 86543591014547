import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import _images from '../../images'
import { ContentOverlay, PopoverPanel } from "../../components";
import TargetPopover from '../reports/targetPopover'
import Translate from '../../constants/translate';

const SalesTarget = (props) => {
    const [loader, setLoader] = useState(true)
    const [contactLoader, setContactLoader] = useState(true)
    const [userTarget, setUserTarget] = useState();
    const [months, setMonths] = useState(moment().format("MMM"))
    const [year, setYear] = useState(moment().format("YYYY"));
    const [targets, setTargets] = useState([])
    const [targetPopOver, setTargetPopOver] = useState({ show: false, target: null, field: null })
    const [activeTarget, setActiveTarget] = useState(null)
    const mStartdate = window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d)
    const mEnddate = window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    const [refreshData, setRefreshData] = useState(true)
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)

    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-salesTarget`);

    const [allData, setData] = useState({
        enquiries: [],
        contacts: [],
        activities: [],
        testdrives: [],
        tradeins: []
    })

    useEffect(() => {
        return () => {
            window.unsubsalestargetSnap && window.unsubsalestargetSnap();
        }
    }, []);


    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!initialLoad)
            return
        setLoader(true)
        window.unsubsalestargetSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setTargets(snap.data().data)
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])


    const fetchData = async (hideLoader) => {
        // console.log('lead by campaigns')
        if (!hideLoader)
            setLoader(true);
        const reportData = window.firebase.functions().httpsCallable('generic-getData');
        reportData({
            type: props?.dealersettings?.mvwReport ? "getSalesTargetV2" : "getSalesTarget",
            params: JSON.stringify({
                clientID: props.dealersettings.client.id,
                startDate: moment.unix(mStartdate.seconds).format('YYYY-MM-DD'),
                endDate: moment.unix(mEnddate.seconds).format('YYYY-MM-DD'),
                owner: localStorage.uid,
                pipeline: props?.dashboardFilter?.pipelines?.length > 0 ? props.dashboardFilter.pipelines.join(',') : '',
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            })
        }).then((response) => {
            setLoader(false);
            if (response.data.success) {
                setTargets(response.data.data)
                if (_.isEmpty(props.dashboardFilter)) {
                    docPathRef.set({ data: response.data.data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
                }
            }
        });
    }

    // useEffect(() => {
    //     if (props.applydateRange === true || props.loader === true || contactLoader)
    //         return

    //     setData({
    //         ...allData,
    //         enquiries: props.pipelineEnquiries,
    //         activities: props.allActivities,
    //         testdrives: props.testdrives,
    //         tradeins: props.tradeins
    //     })
    // }, [props.loader, props.applydateRange, props.pipelineEnquiries, props.allActivities, props.testdrives, props.tradeins])

    // useEffect(() => {
    //     if (_.isEmpty(userTarget))
    //         return

    //     if (userTarget[year] && Object.keys(userTarget[year]).some(e => e === months)) {
    //         var monthTarget = userTarget[year][months];
    //         if (monthTarget && monthTarget.filter(e => e.type === 'dataQuality').length > 0) {
    //             let owner = localStorage.uid;
    //             if (!_.isEmpty(props.selectedUser)) {
    //                 owner = props.selectedUser.value
    //             }
    //             window.unsubTargetContactsList = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails')
    //                 .where('clientID', '==', props.dealersettings.client.id)
    //                 .where('isDeleted', '==', false)
    //                 .where('addedDate', '>=', mStartdate)
    //                 .where('addedDate', '<=', mEnddate)
    //                 .where('owner', '==', owner)
    //                 .orderBy('addedDate','desc')
    //                 .onSnapshot(querysnapshot => {
    //                     let _contacts = [];
    //                     querysnapshot.docs.forEach(snap => {
    //                         _contacts.push({
    //                             ...snap.data(),
    //                             'documentID': snap.id
    //                         })
    //                     })
    //                     setData({
    //                         ...allData,
    //                         contacts: _contacts
    //                     })
    //                     setContactLoader(false)
    //                 })
    //         }
    //         else {
    //             setContactLoader(false)
    //         }
    //     }
    //     else {
    //         setContactLoader(false)
    //     }
    // }, [userTarget, props.selectedUser])

    // useEffect(() => {
    //     setLoader(true)
    //     const userTargetRef = window.firebase.firestore().collection('targets')
    //         .where('userIDs', 'array-contains', props.selectedUser ? props.selectedUser.value : localStorage.uid)
    //         .where('clientID', '==', props.dealersettings.client.id)
    //         .where('isDeleted', '==', false)
    //         .where('type', '==', 'user')
    //         .where('isDeleted', '==', false)
    //         .limit(1)
    //         .onSnapshot(snapshot => {
    //             if (snapshot.docs.length > 0) {
    //                 setUserTarget(snapshot.docs[0].data())
    //             }
    //             else {
    //                 setUserTarget(null)
    //             }
    //             setLoader(false)
    //             if (snapshot.docs.length === 0)
    //                 setContactLoader(false)
    //         })

    //     return () => {
    //         userTargetRef && userTargetRef()
    //         window.unsubTargetContactsList && window.unsubTargetContactsList();
    //     }
    // }, [props.selectedUser])

    // useEffect(() => {
    //     if (_.isEmpty(userTarget) || contactLoader)
    //         return


    //     let owner = localStorage.uid;
    //     if (!_.isEmpty(props.selectedUser)) {
    //         owner = props.selectedUser.value
    //     }
    //     let targets = [];
    //     if (userTarget[year] && Object.keys(userTarget[year]).some(e => e === months)) {
    //         Object.keys(userTarget[year]).filter(e => e === months).forEach(month => {
    //             var monthStartdate = window.firebase.firestore.Timestamp.fromDate(moment().month(month).startOf('month')._d)
    //             var monthEnddate = window.firebase.firestore.Timestamp.fromDate(moment().month(month).endOf('month')._d)
    //             var monthTarget = userTarget[year][month];

    //             var monthnewLeads = allData.enquiries.filter(a =>
    //                 a.ownerValue === owner
    //                 && a.addedDate.seconds >= monthStartdate.seconds
    //                 && a.addedDate.seconds <= monthEnddate.seconds
    //             )
    //             var monthallLeads = allData.enquiries.filter(a =>
    //                 a.ownerValue === owner
    //                 && ((a.statusValue === 'won' && a.wonDate && a.wonDate.seconds >= monthStartdate.seconds
    //                     && a.wonDate.seconds <= monthEnddate.seconds) ||
    //                     (a.statusValue === 'delivered' && a.deliveredDate && a.deliveredDate.seconds >= monthStartdate.seconds
    //                         && a.deliveredDate.seconds <= monthEnddate.seconds) ||
    //                     (a.statusValue === 'lost' && a.lostDate && a.lostDate.seconds >= monthStartdate.seconds
    //                         && a.lostDate.seconds <= monthEnddate.seconds) ||
    //                     (a.testdrives && a.testdrives.some(item => item.startDateValue.seconds >= monthStartdate.seconds
    //                         && item.startDateValue.seconds <= monthEnddate.seconds)) ||
    //                     (a.addedDate.seconds >= monthStartdate.seconds
    //                         && a.addedDate.seconds <= monthEnddate.seconds) ||
    //                     (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStartdate.seconds
    //                         && item.walkinDate.seconds <= monthEnddate.seconds)))
    //             )

    //             var monthconvertedLeads = allData.enquiries.filter(a =>
    //                 ((a.ownerValue === owner && a.pipelineValue === 'LeadsBucket') || (a.convertedBy === owner && a.pipelineValue !== 'LeadsBucket'))
    //                 && ((a.statusValue === 'won' && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= monthStartdate.seconds
    //                     && a.wonDate.seconds <= monthEnddate.seconds) ||
    //                     (a.statusValue === 'delivered' && !_.isEmpty(a.deliveredDate) && a.deliveredDate.seconds >= monthStartdate.seconds
    //                         && a.deliveredDate.seconds <= monthEnddate.seconds) ||
    //                     (a.statusValue === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= monthStartdate.seconds
    //                         && a.lostDate.seconds <= monthEnddate.seconds) ||
    //                     (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= monthStartdate.seconds
    //                         && item.startDate.seconds <= monthEnddate.seconds)) ||
    //                     (!_.isEmpty(a.convertedDate) && a.convertedDate.seconds >= monthStartdate.seconds
    //                         && a.convertedDate.seconds <= monthEnddate.seconds) ||
    //                     (a.addedDate.seconds >= monthStartdate.seconds
    //                         && a.addedDate.seconds <= monthEnddate.seconds) ||
    //                     (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStartdate.seconds
    //                         && item.walkinDate.seconds <= monthEnddate.seconds)))
    //             )

    //             var doneActivities = allData.activities.filter(a =>
    //                 a.owner === owner
    //                 && a.isDone === true
    //                 && a.modifiedDate.seconds >= monthStartdate.seconds
    //                 && a.modifiedDate.seconds <= monthEnddate.seconds
    //             )
    //             var testdriveLeads = allData.testdrives.filter(a =>
    //                 a.addedByValue === owner
    //                 && a.startDateValue.seconds >= monthStartdate.seconds
    //                 && a.startDateValue.seconds <= monthEnddate.seconds
    //             )
    //             var tradeinLeads = allData.tradeins.filter(a =>
    //                 a.addedByValue === owner
    //                 && a.addedDate.seconds >= monthStartdate.seconds
    //                 && a.addedDate.seconds <= monthEnddate.seconds
    //             )
    //             var monthContacts = allData.contacts && allData.contacts.filter(a =>
    //                 a.owner === owner
    //                 && a.addedDate.seconds >= monthStartdate.seconds
    //                 && a.addedDate.seconds <= monthEnddate.seconds
    //             )

    //             //targets by enquiry types
    //             monthTarget.filter(e => e.type === 'enquiryTypes').forEach(rec => {
    //                 var leadsbyenquiryType = !_.isEmpty(rec.subType) ? monthnewLeads.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : true)
    //                     && rec.subType.split(',').some(a => a === e.enquiryTypeValue)) : [];
    //                 targets.push(targetCalculation(leadsbyenquiryType, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget));
    //             })

    //             //targets by campaigns
    //             monthTarget.filter(e => e.type === 'campaigns').forEach(rec => {
    //                 var leadsbyCampaign = !_.isEmpty(rec.subType) ? monthnewLeads.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : true)
    //                     && rec.subType.split(',').some(a => a === e.campaignValue)) : [];
    //                 targets.push(targetCalculation(leadsbyCampaign, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget));
    //             })

    //             //targets by origin
    //             monthTarget.filter(e => e.type === 'origins').forEach(rec => {
    //                 var leadsbyOrigin = !_.isEmpty(rec.subType) ? monthnewLeads.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : true)
    //                     && rec.subType.split(',').some(a => a === e.originValue)) : [];
    //                 targets.push(targetCalculation(leadsbyOrigin, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget));
    //             })

    //             //targets by status
    //             monthTarget.filter(e => e.type === 'status').forEach(rec => {
    //                 var leadsbyStatus = [];
    //                 if (!_.isEmpty(rec.subType)) {
    //                     rec.subType.split(',').forEach(status => {
    //                         var sdate = status === 'delivered' ? 'deliveredDate' : status === 'won' ? 'wonDate' : status === 'lost' ? 'lostDate' : 'addedDate'
    //                         leadsbyStatus = [...leadsbyStatus, ..._.map(allData.enquiries.filter(a =>
    //                             a.ownerValue === owner
    //                             && (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : true)
    //                             && (status === 'won' ? (a.statusValue === 'won' || a.statusValue === 'delivered') : a.statusValue === status)
    //                             && a[sdate].seconds >= monthStartdate.seconds
    //                             && a[sdate].seconds <= monthEnddate.seconds
    //                         ), function (e) {
    //                             return {
    //                                 documentID: e.documentID,
    //                                 status: e.statusValue
    //                             }
    //                         })]
    //                     })
    //                 }

    //                 targets.push(targetCalculation(_.uniq(leadsbyStatus), rec, monthallLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget));
    //             })


    //             //targets by inbound status
    //             monthTarget.filter(e => e.type === 'inboundStatus').forEach(rec => {
    //                 var leadsbyinboundStatus = [];
    //                 if (!_.isEmpty(rec.subType)) {
    //                     rec.subType.split(',').forEach(status => {
    //                         var sdate = status === 'created' ? 'addedDate' : 'convertedDate'
    //                         leadsbyinboundStatus = [...leadsbyinboundStatus, ..._.map(allData.enquiries.filter(a =>
    //                             ((a.ownerValue === owner && a.pipelineValue === 'LeadsBucket') || (a.convertedBy === owner && a.pipelineValue !== 'LeadsBucket'))
    //                             //&& (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : true)
    //                             && !_.isEmpty(a.stageHistory)
    //                             && a.stageHistory.some(s => s.pipeline === 'LeadsBucket')
    //                             && (status === 'show' ? a.bdcStatus === 'show' : true)
    //                             && (status === 'noshow' ? a.bdcStatus === 'noshow' : true)
    //                             && a[sdate].seconds >= monthStartdate.seconds
    //                             && a[sdate].seconds <= monthEnddate.seconds
    //                         ), function (e) {
    //                             return {
    //                                 documentID: e.documentID
    //                             }
    //                         })]
    //                     })
    //                 }

    //                 targets.push(targetCalculation(_.uniq(leadsbyinboundStatus), rec, monthconvertedLeads, month, monthTarget));
    //             })

    //             //targets by activities
    //             monthTarget.filter(e => e.type === 'activities').forEach(rec => {
    //                 var activitiesbyType = !_.isEmpty(rec.subType) ? doneActivities.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? (!_.isEmpty(e.enquiry) ? e.enquiry.pipeline === rec.pipeline : false) : true)
    //                     && rec.subType.split(',').some(a => a === e.subType)) : [];
    //                 targets.push(targetCalculation(activitiesbyType, rec, doneActivities, month, monthTarget));
    //             })

    //             //target by salestype
    //             monthTarget.filter(e => e.type === 'salesTypes').forEach(rec => {
    //                 var leadsbySalestypes = [];
    //                 if (!_.isEmpty(rec.subType)) {
    //                     rec.subType.split(',').forEach(salesType => {
    //                         var splitType = salesType.split('AND');
    //                         var status = splitType[1]
    //                         var saleType = splitType[0]
    //                         var sdate = status === 'delivered' ? 'deliveredDate' : status === 'won' ? 'wonDate' : status === 'lost' ? 'lostDate' : 'addedDate'
    //                         leadsbySalestypes = [...leadsbySalestypes, ..._.map(allData.enquiries.filter(a =>
    //                             a.ownerValue === owner
    //                             && (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : true)
    //                             && (!_.isEmpty(status) ? (status === 'won' ? (a.statusValue === 'won' || a.statusValue === 'delivered') : a.statusValue === status) : true)
    //                             && (!_.isEmpty(a.requirement))
    //                             && (!_.isEmpty(a.requirement.saleType))
    //                             && (a.requirement.saleType.toLowerCase() === saleType.toLowerCase())
    //                             && !_.isEmpty(a[sdate])
    //                             && a[sdate].seconds >= monthStartdate.seconds
    //                             && a[sdate].seconds <= monthEnddate.seconds
    //                         ), function (e) {
    //                             return {
    //                                 documentID: e.documentID,
    //                                 status: e.statusValue
    //                             }
    //                         })]
    //                     })
    //                 }

    //                 targets.push(targetCalculation(_.uniq(leadsbySalestypes), rec, monthallLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget));
    //             })

    //             //targets by test drives
    //             monthTarget.filter(e => e.type === 'testdrive').forEach(rec => {
    //                 var testdrives = !_.isEmpty(rec.subType) ? _.map(testdriveLeads.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                 ), function (e) { return e.enquiryID }) : [];
    //                 var monthopenLeads = monthallLeads.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                     //&& e.statusValue === 'open'
    //                 )
    //                 targets.push(targetCalculation(_.uniq(testdrives), rec, monthopenLeads, month, monthTarget));
    //             })
    //             //targets by tradein
    //             monthTarget.filter(e => e.type === 'tradein').forEach(rec => {
    //                 var tradeins = !_.isEmpty(rec.subType) ? tradeinLeads.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                 ) : [];
    //                 var monthopenLeads = monthallLeads.filter(e =>
    //                     (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                     //&& e.status === 'open'
    //                 )
    //                 targets.push(targetCalculation(tradeins, rec, monthopenLeads, month, monthTarget));
    //             })


    //             //targets by dataQuality
    //             let dataCapture = [];
    //             monthTarget.filter(e => e.type === 'dataQuality').forEach(rec => {
    //                 if (!_.isEmpty(rec.subType)) {
    //                     monthContacts.forEach(contact => {
    //                         let i = 1;
    //                         rec.subType.split(',').forEach(field => {
    //                             if (_.isEmpty(contact[field])) {
    //                                 i = 0;
    //                             }
    //                         })
    //                         if (i > 0)
    //                             dataCapture.push(contact)
    //                     })
    //                 }
    //                 // var dataCapture = !_.isEmpty(rec.subType) ? monthContacts.filter(e =>
    //                 //     rec.subType.split(',').some(a => !_.isEmpty(e[a]))) : [];
    //                 targets.push(targetCalculation(dataCapture, rec, monthContacts, month, monthTarget));
    //             })

    //             let enquiryOptions = [];
    //             monthTarget.filter(e => e.type === 'enquiryOptions').forEach(rec => {
    //                 if (!_.isEmpty(rec.subType)) {
    //                     monthnewLeads.forEach(enquiry => {
    //                         let i = 1;
    //                         rec.subType.split(',').forEach(field => {
    //                             if (!enquiry.options || !enquiry.options.some(e => e === field)) {
    //                                 i = 0;
    //                             }
    //                         })
    //                         if (i > 0)
    //                             enquiryOptions.push(enquiry)
    //                     })
    //                 }
    //                 // var dataCapture = !_.isEmpty(rec.subType) ? monthContacts.filter(e =>
    //                 //     rec.subType.split(',').some(a => !_.isEmpty(e[a]))) : [];
    //                 targets.push(targetCalculation(enquiryOptions, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget));
    //             })
    //         })
    //     }
    //     setTargets(_.orderBy(targets, ["sortOrder"], ["asc"]))
    // }, [userTarget, allData, contactLoader])

    const handleShowTargetDetails = (e, target) => {
        setActiveTarget(target)
        setTargetPopOver({
            show: true,
            target: e.target
        });
        e.preventDefault()
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props?.dashboardFilter?.pipelines)) {
            window.unsubsalestargetSnap && window.unsubsalestargetSnap();
            fetchData();
        }
        else {
            setInitialLoad(true)
        }

    }, [props?.dashboardFilter?.pipelines ? props.dashboardFilter.pipelines : false])

    const targetCalculation = (leads, target, monthLeads, month, targetList) => {
        monthLeads = _.uniq(_.map(monthLeads, function (e) { return e.documentID }))
        var targetVM = {
            ...target,
            id: target.targetID,
            month: month,
            sortOrder: _.findIndex(targetList, (e) => {
                return e.targetID == target.targetID;
            }, 0)
        }
        if (target.calculationType === 'count') {
            targetVM = {
                ...targetVM,
                target: target.target,
                actual: leads.length,
                percentage: target.target > 0 ? Math.round((100 / target.target) * leads.length) + '%' : '0%',
                showRed: (target.target > leads.length) ? true : false,
                different: target.target - leads.length
            }
        }
        else {
            var targetPtg = target.target;
            var actualPtg = monthLeads.length > 0 ? Math.round((100 / monthLeads.length) * leads.length) : 0;
            targetVM = {
                ...targetVM,
                target: targetPtg + '%',
                actual: actualPtg + '%',
                percentage: targetPtg > 0 ? Math.round((100 / targetPtg) * actualPtg) + '%' : '0%',
                showRed: (targetPtg > actualPtg) ? true : false,
                different: targetPtg > 0 ? (100 - (Math.round((100 / targetPtg) * actualPtg))) + '%' : '0%'
            }
        }
        return targetVM
    }

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }

                        {/* <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a> */}
                    </div>
                    <h3><Translate text={'Sales Target'} /> - {moment().format('MMM YYYY')}</h3>
                </div>
                <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                    {
                        (loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className=" h-100" style={{ maxHeight: `350px`, minHeight: `350px`, overflow: `auto` }}>
                            {

                                !_.isEmpty(targets) ? (<table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <td className="head-light" width="30%">KPI</td>
                                            <td className="head-light text-center" width="10%">Target</td>
                                            <td className="head-light text-center" width="10%">Actual</td>
                                            <td className="head-light text-center" width="10%">To Target</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            targets.length > 0 ?
                                                targets.map((rec, index) => {
                                                    return <tr key={index}>
                                                        <td><div className="dealership-tbl cursor-pointer float-left" onClick={(e) => {
                                                            handleShowTargetDetails(e, rec)
                                                        }}>{rec.name}</div></td>
                                                        <td className="text-center">{rec.target}</td>
                                                        <td className="text-center">
                                                            <div className={rec.showRed === "true" ? 'down-ico' : 'up-ico'}>
                                                                {/* <i className={`ico icon-sort-${rec.showRed ? 'down' : 'up'}`}></i> */}
                                                                {rec.actual}
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            {/*<span className={rec.showRed ? 'down-ico' : 'up-ico'}>
                                                             <i className={`ico icon-sort-${rec.showRed ? 'down' : 'up'}`}></i> 
                                                            {rec.percentage}
                                                        </span>*/}
                                                            {
                                                                rec.showRed === "true" ? (
                                                                    <span className={'down-ico ml-2'}>
                                                                        {/* <i className={`ico icon-sort-down`}></i> */}
                                                                        {rec.totarget}
                                                                    </span>
                                                                ) : (
                                                                    <img src={_images.tickgreen} alt="" height="20" />
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                }) : (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="spinner-loader h-100 p-5">
                                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                    <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                                    <div className="no-data-txt mt-2"> <Translate text={'Target not set for selected user / month'} /></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                    </tbody>
                                </table>) : (<div className="spinner-loader h-100 p-5">
                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                        <div className="no-data-img"> <img src={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2"> <Translate text={'Target not set for selected user / month'} /></div>
                                    </div>
                                </div>)
                            }
                        </div>)
                    }
                </div>
            </div>
            <PopoverPanel
                showpopover={targetPopOver.show}
                targetpopover={targetPopOver.target}
                title={'Target Details '}
                position="right"
                className="popover-required-fields"
                closepopover={() => {
                    setTargetPopOver({ show: false, target: '', field: '' });
                }}>
                <TargetPopover
                    {...props}
                    activeTarget={activeTarget}
                />
            </PopoverPanel>
        </>
    );
}

export default SalesTarget;