import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import toast from 'toasted-notes';
import Swal from 'sweetalert2';
import EmailSign from './emailsign';
import EmailAuth from './emailauth';
import EmailSyncTimeModal from './emailSyncTime'
import { MainContext } from '../../../layout/provider';
import { PopUpModal, UpgradePlan } from '../../../../components';
import moment from 'moment'
import _, { isEmpty } from 'lodash';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import { useHistory } from 'react-router-dom';

const EmailSettings = () => {
	const history = useHistory()
	const [isUserActivated, setIsUserActivated] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [authLoader, setAuthLoader] = useState(false);
	const [show, setShow] = useState(false);
	const [syncing, setSyncing] = useState(false);
	const [userData, setUserData] = useState(null);
	const [nylasAccountData, setNylasAccountData] = useState(null);

	const { dealersettings, handleUnreadCount, updateDealerSettings, clientUsers } = useContext(MainContext);

	const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);

	const _nylas = Object.assign({}, dealersettings &&
		dealersettings.client &&
		dealersettings.client.integrations &&
		dealersettings.client.integrations.filter(e => e.type === "nylas")[0]);

	const NylasEnabled = _nylas && _nylas.active && _nylas.enabled ? true : false;
	const liteVersion = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.client) &&
		!_.isEmpty(dealersettings.client.clientSettings) &&
		dealersettings.client.clientSettings.liteVersion) ? true : false);
	useEffect(() => {
		let userSnapshot;
		let nylasAccountSnapshot;
		let data = null;
		userSnapshot = window.firebase.firestore().collection(`users`).doc(localStorage.uid)
			.onSnapshot(querySnapshot => {
				if (querySnapshot.exists) {
					data = querySnapshot.data()
					setUserData(data)
					if (!isEmpty(data.nylas_v3) && !isUserActivated) {
						setIsUserActivated(true);
					}
				}
				setIsLoading(false);
			})
		let nylasAccountID = null;
		if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
			nylasAccountID = dealersettings.nylas_v3.grantId
		} else if (userData && !isEmpty(userData.nylas_v3)) {
			nylasAccountID = userData.nylas_v3.grantId
		} else if (data && !isEmpty(data.nylas_v3)) {
			nylasAccountID = data.nylas_v3.grantId
		}
		if (nylasAccountID) {
			nylasAccountSnapshot = window.firebase.firestore().collection(`nylasv3-accounts`).doc(nylasAccountID)
				.onSnapshot(querySnapshot => {
					if (querySnapshot.exists) {
						let _data = querySnapshot.data()
						setNylasAccountData(_data)
						updateDealerSettings('nylasV3AccountData', _data)
						setSyncing(_data.syncing)
					}
				})
		}
		return () => {
			userSnapshot && userSnapshot();
			nylasAccountSnapshot && nylasAccountSnapshot();
		}
	}, [])

	useEffect(() => {
		const auth = async () => {
			try {
				var queryString = window.location.search;
				var urlParams = new URLSearchParams(queryString);
				var code = urlParams.get('code')
				var state = urlParams.get('state')
				// call the function to save the accoutn info
				const nylasAccount = await userRef.get().then(doc => {
					if (doc.exists) return doc.data(); else return null;
				});
				if (!code || (nylasAccount && !isEmpty(nylasAccount.nylas_v3)) || state === 'outlookcalendar') {
					return
				}

				setSyncing(true)

				let reqBody = {
					"uid": localStorage.uid,
					"redirectURI": `${window.location.origin}/settings/profile`,
					"code": code,
					"options": {
						last_message_after: localStorage.last_message_after,
						last_message_before: localStorage.last_message_before,
					},
				}
				// const resp = await axios.post(`${apiURL}nylasv3-saveAccountInfo`,
				// 	{
				// 		"data": reqBody
				// 	}
				// )

				const addUser = window.firebase.functions().httpsCallable('nylasv3-saveAccountInfo');
				const resp = await addUser(reqBody)

				console.log('reqBody', resp, reqBody)
				localStorage.removeItem('last_message_after');
				localStorage.removeItem('last_message_before');
				// show syncing loader and when syncing is finished show modal
				//history.push('/mail/inbox')
				if (resp?.data?.success) {
					setSyncing(false)

					setIsUserActivated(true);
					updateDealerSettings('nylasV3AccountData', resp?.data?.data)
					setNylasAccountData(resp?.data?.data);
					setUserData({
						...userData,
						nylas_v3: resp?.data?.data
					})
					toast.notify('Email synced successfully.', {
						duration: 2000,
					});
					if (resp?.data?.data?.grantId) {
						unAuthenticateOldNylasUser(userData);
						// const syncThreads = window.firebase.functions().httpsCallable('nylasv3-syncThreads');
						// syncThreads({ grantId: resp?.data?.result?.data?.grantId })
					}
					setTimeout(() => {
						history.push('/mails/inbox');
					}, 200);

				}
				else {
					setSyncing(false)
					setIsUserActivated(false);
					Swal.fire(CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`), '', 'error');
					console.error(resp.data.message)
				}
			} catch (error) {
				setSyncing(false)
				setIsUserActivated(false);
				Swal.fire(CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`), '', 'error');
				console.error(error)
			}
		};

		auth();
	}, []);

	const authenticateUser = async (last_message_after, last_message_before) => {
		try {
			setAuthLoader(true);

			// const authUrl = await axios.post(`${apiURL}nylasv3-urlForAuthentication`,
			// 	{
			// 		"data": {
			// 			loginHint: dealersettings.email,
			// 			redirectURI: `${window.location.origin}/settings/profile`,
			// 			scopes: ['email.modify', 'email.send'],
			// 		}
			// 	}

			// )
			//console.log('authUrl', authUrl)
			const nylasUrlForAuthentication = window.firebase.functions().httpsCallable('nylasv3-urlForAuthentication');
			const authUrl = await nylasUrlForAuthentication({
				//loginHint: dealersettings.email,
				redirectURI: `${window.location.origin}/settings/profile`,
				//redirectURI: `${window.location.origin}/settings/profile?key=emailsettings&last_message_after=${last_message_after}&last_message_before=${last_message_before}`,
				scopes: ['email.modify', 'email.send', 'userinfo.email', 'userinfo.profile'],
				accessType: 'offline'
			});
			console.log('authUrl', authUrl)

			if (authUrl?.data) {
				console.log(authUrl.data);
				window.location.replace(authUrl.data);
			}
		} catch (error) {
			setAuthLoader(false);
			Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Something went wrong.'), '', 'error')
			console.log(error);
		}
	};

	const unAuthenticateUser = async () => {
		try {
			Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
				text: CommonHelper.showLocale({ dealersettings }, 'Do you want to disable email.'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
			}).then(async result => {
				if (result.value) {
					setAuthLoader(true);

					let nylasAccountID = null;
					if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
						nylasAccountID = dealersettings.nylas_v3.grantId
					} else if (userData && !isEmpty(userData.nylas_v3)) {
						nylasAccountID = userData.nylas_v3.grantId
					}

					const nylasCancelAccount = window.firebase.functions().httpsCallable('nylasv3-cancelAccount');
					const resp = await nylasCancelAccount({
						grantId: nylasAccountID,
					});
					if (resp.data) {
						console.log(resp.data);
					}
					//if (resp.data.success) {
					await userRef.update({
						nylas_v3: null,
						nylasAccountInfo: null,
						// nylasFolders: null,
						// nylasLabels: null,
					});
					updateDealerSettings('nylasV3AccountData', null)
					toast.notify('Email disabled successfully', {
						duration: 2000,
					});
					handleUnreadCount(0);
					setAuthLoader(false);
					setIsUserActivated(false);
					//}
				}
			});
		} catch (error) {
			setAuthLoader(false);
			Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Something went wrong.'), '', 'error')
			console.log(error);
		}
	};

	const unAuthenticateOldNylasUser = async (_user) => {
		try {
			console.log(_user?.nylasAccessToken);
			if (_user?.nylasAccessToken || _user?.nylas?.token) {
				//console.log('unAuthenticateOldNylasUser', _user?.nylasAccessToken , _user?.nylas?.token)
				const nylasCancelAccount = window.firebase
					.functions()
					.httpsCallable('nylas-cancelAccount');
				const resp = await nylasCancelAccount({
					token: (_user?.nylasAccessToken || _user?.nylas?.token),
				});
				if (resp.data) {
					console.log(resp.data);
				}
				if (resp.data.success) {
					await userRef.update({
						nylas: null,
						nylasAccessToken: null,
						nylasAccountInfo: null
					});

				}
			}

		} catch (error) {
			// toast.notify('Something went wrong', {
			// 	duration: 2000,
			// });
			console.log('unAuthenticateOldNylasUser', error);
		}
	};

	const handleEnable = () => {

		let last_message_after = moment().add('month', '1' * -1).startOf('day').format('X')
		let last_message_before = moment().endOf('day').format('X')
		// console.log(month)
		// console.log(last_message_after)
		// console.log(last_message_before)
		localStorage.setItem('last_message_after', last_message_after);
		localStorage.setItem('last_message_before', last_message_before);
		authenticateUser(last_message_after, last_message_before)

	}

	const handleClose = month => {
		setShow(false)
		if (month) {
			let last_message_after = moment().add('month', month * -1).startOf('day').format('X')
			let last_message_before = moment().endOf('day').format('X')
			// console.log(month)
			// console.log(last_message_after)
			// console.log(last_message_before)
			localStorage.setItem('last_message_after', last_message_after);
			localStorage.setItem('last_message_before', last_message_before);
			authenticateUser(last_message_after, last_message_before)
		}
	}

	return (
		<>
			{
				liteVersion ? (<UpgradePlan />) : (<></>)
			}
			<h2><Translate text={'emailSettings'} /></h2>
			<div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`}>
				<div className='tabs-wraper'>
					<nav>
						<Tabs
							defaultActiveKey='emailsync'
							transition={false}
							className='nav-fill'
						>
							<Tab eventKey='emailsync' title={CommonHelper.showLocale({ dealersettings }, 'Email Sync')}>
								{isLoading ? (
									<div className='mt-5  w-100'>
										<div className='spinner-loader pt-5 h-100'>
											<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
												<div
													role='status'
													className='spinner-border loader-primary'
												></div>
												<p className='text-muted mt-3'></p>
											</div>
										</div>
									</div>
								) : (
									<EmailAuth
										dealersettings={dealersettings}
										setShow={setShow}
										handleEnable={handleEnable}
										syncing={syncing}
										unAuthenticateUser={unAuthenticateUser}
										isUserActivated={isUserActivated}
										loader={authLoader}
										userData={userData}
										NylasEnabled={NylasEnabled}
										nylasObjectPresent={!isEmpty(nylasAccountData)}
										clientUsers={clientUsers}
									/>
								)}
							</Tab>
							<Tab eventKey='emailsignature' title={CommonHelper.showLocale({ dealersettings }, 'Email Signature')}>
								{isLoading ? (
									<div className='mt-5  w-100'>
										<div className='spinner-loader pt-5 h-100'>
											<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
												<div
													role='status'
													className='spinner-border loader-primary'
												></div>
												<p className='text-muted mt-3'></p>
											</div>
										</div>
									</div>
								) : (
									<EmailSign
										userData={userData}
										setUserData={setUserData}
										userRef={userRef}
										dealersettings={dealersettings}
									/>
								)}
							</Tab>
						</Tabs>
					</nav>
				</div>
			</div>
			<PopUpModal show={show}>
				<EmailSyncTimeModal
					show={show}
					title={'Sync Emails'}
					handleClose={handleClose}
				/>
			</PopUpModal>
		</>
	);
};

export default EmailSettings;
