/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
/** COMPONENTS */
import { PopUpModal } from '../../../components';
import Translate from '../../../constants/translate';
import { default as _images } from "../../../images";
import CommonHelper from '../../../services/common';
import Info from './info'
import AddTimeLog from './addLog'
import { firestoreDB } from '../../../services/helper';


class TimeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addLog: false,
            workLog: null,
        };
    }

    componentDidMount() {

    }

    handleAdd = () => {
        this.setState({ addLog: true, workLog: null });
    }

    handleEdit = (data) => {
        this.setState({ addLog: true, workLog: data });
    }

    handleRemove = (_ID) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete this log`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                const { serviceID, dealersettings } = this.props;
                if (serviceID && _ID) {
                    firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/workLogs/${_ID}`).delete().then((docRef) => {

                        toast.notify('log deleted successfully', {
                            duration: 2000
                        });

                        let _objLogData = {
                            notes: 'work log deleted',
                            type: 'serviceJobs',
                            subType: 'delete',
                            recordId: _ID,
                            serviceID: serviceID,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);
                        CommonHelper.saveFileChangeLog(_objLogData, 'updated');

                    }).catch((error) => {
                        console.error("Error updating contact_details: ", error);
                    });
                }
            }

        });
    }


    render() {


        const { show, workLogs, totalSpent, canModify } = this.props;
        const { addLog, workLog } = this.state;
        const divStyle = {
            height: '475px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    id="worklogList"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-worklog">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'Worklogs'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">
                                    <div className="form-group">
                                        <div className="tech-worklog-output">

                                            <div className="tech-worklog-popup-info">
                                                <i className="ico icon-timer"></i>
                                                <strong className="mr-1">{CommonHelper.showDuration(totalSpent)}</strong> Logged
                                            </div>

                                            {
                                                canModify
                                                    ?
                                                    <>
                                                        <div className=" float-right linked-activities-add">
                                                            <button type="button" className="btn btn-secondary float-right" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ addLog: true, workLog: null });
                                                            }}>
                                                                <i className="ico icon-add"></i> {'Log Work Manually'}
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }


                                        </div>

                                    </div>
                                    {
                                        workLogs.length > 0
                                            ?
                                            <>
                                                <div className="enquiry-flow mt-0 mb-3" style={divStyle}>
                                                    <div className="enquiry-flow-wrap mt-0">
                                                        <div className="flow-timelinebar"></div>
                                                        {workLogs.map((log, index) =>
                                                            <Info {...this.props}
                                                                log={log}
                                                                key={index}
                                                                handleEdit={this.handleEdit}
                                                                handleRemove={this.handleRemove}
                                                            ></Info>
                                                        )}
                                                    </div>
                                                </div>

                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className={_images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]}></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'No log'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <PopUpModal show={addLog}>
                    <AddTimeLog {...this.props}
                        show={addLog}
                        workLogs={workLogs}
                        workLog={workLog}
                        handleClose={() => { this.setState({ addLog: false, workLog: null }); }}
                    >
                    </AddTimeLog>
                </PopUpModal>

            </>
        );
    }

}

export default TimeLog;