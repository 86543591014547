/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import Swal from 'sweetalert2';
/** PROVIDERS */
/** VIEW-MODELS */
//import { allcontactFields, objContact } from '../viewModel';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopoverListLoader, PopUpModal, ReactSelect } from '../../../components';
import AddFleet from '../../fleet/add'
import AddTestDrive from '../add'
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import { stockStatus, stockAvail } from '../../../services/enum'
import StockListReducer from '../../stock/stockListReducer'
import { objStock } from '../../stock/viewModel';
import { firestoreDB } from '../../../services/helper';

let sale_type = [];
const StockLists = (props) => {
    //const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [stocks, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [loader, setLoader] = useState(true)

    const [modelFleetShow, setModelFleetShow] = useState(false)
    const [modelTestDriveShow, setModelTestDriveShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [modelStock, setModelStock] = useState({})

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);
    const stockLocations = props?.dealersettings?.client?.clientSettings?.tdStockLocations || props?.dealersettings?.client?.clientSettings?.stockLocations
    const incWonStockinTD = props?.dealersettings?.client?.clientSettings?.incWonStockinTD
    useEffect(() => {
        // function handleResize() {
        //     // setWindowSize({
        //     //     windowWidth: (window.innerWidth - 30),
        //     //     windowHeight: (window.innerHeight - 123)
        //     // })
        // }
        //window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            //window.removeEventListener('resize', handleResize);
            window.unSubAcvTDList && window.unSubAcvTDList();
            //console.log('pageUNLoad', isAvailable, props.dealersettings.client.id, searchText, hasMore)
        }
    }, [])

    useEffect(() => {
        const { dealersettings } = props;

        if (!dealersettings)
            return;

        const setttings = dealersettings?.client?.settings;
        sale_type = [];
        setttings?.salesType && setttings.salesType.forEach(doc => {
            sale_type.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name,
            });
        });

    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }

        if (!props.enquiryID) {
            props.handleClose()
            return;
        }

        //console.log('pageLoad', isAvailable, props.dealersettings.client.id, searchText, hasMore, isPaging)
        const { dealersettings, enquiryID } = props;
        if (dealersettings) {

            let refActivitiesData = firestoreDB(dealersettings).firestore().collection('activities')
                .where('enquiryID', '==', enquiryID)
                .where('type', '==', 'activity')
                .where('subType', '==', 'testdrive')
                .where('isDeleted', '==', false)
                .where('isDone', '==', false)
                .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
                .orderBy('startDate', 'desc')

            window.unSubAcvTDList = refActivitiesData
                .onSnapshot(onCollectionUpdate);

        }


    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = {
                    ...change.doc.data(),
                    documentID: change.doc.id
                };

                if (snapshotDoc.subType === 'testdrive' && !_.isEmpty(snapshotDoc.stock)) {
                    if (change.type === 'added') {
                        actionType = "_ADD";
                    }
                    else if (change.type === 'modified') {
                        actionType = "_UPDATE"
                    }
                    else if (change.type === 'removed') {
                        actionType = "_REMOVE"
                    }
                }
            }
        })
        let activities = [];
        if (!actionType) {
            querySnapshot.forEach((doc) => {
                activities.push({
                    ...doc.data(),
                    documentID: doc.id
                });
            });
        }

        activities = activities.filter(e => e.subType === 'testdrive' && !_.isEmpty(e.stock))


        console.log('activities', props.enquiryID, activities)

        // if (_.isEmpty(activities)) {
        //     console.log('!props.activities')
        //     props.handleClose();
        // }

        dispatch({
            type: actionType ? actionType : "_SUCCESS",
            data: actionType ? snapshotDoc : activities,
            sortName: 'startDate',
            sortOrder: 'desc'
        });

        setDataload(false)
        setLoader(false)
    }



    const handleStockSelect = async (fields, activityID) => {
        let stock = fields;
        const activitySnapshot = await window.firebase.firestore().doc(`stock/${fields.documentID}`).get();
        if (activitySnapshot.exists) {
            stock = activitySnapshot.data();

            if (fields.status !== stock.status) {
                const _activities = Object.assign([], stocks);
                _activities.filter(function (obj) {
                    //Update old data
                    if (obj.documentID === activityID) {
                        obj.stock = stock;
                    }
                });
                dispatch({
                    type: "_SUCCESS",
                    data: _activities,
                    sortName: 'startDate',
                    sortOrder: 'desc'
                });
            }
        }

        if (stock.isServiceLoan) {
            Swal.fire(CommonHelper.showLocale(props, 'This vehicle has been reserved for service loan only'), '', 'info')
            return;
        }
        else if (stock.status === stockStatus.AVAILABLE || stock.status === stockStatus.MCRETAILED ||
            (props.dealersettings.isMclaren === true && stock.status === stockStatus.DELIVERED && stock.saleType === 'Demo') ||
            (stock.status === stockStatus.SOLD && incWonStockinTD)) {
            handleSelectClose(stock.documentID, stock, activityID)
        }
        else if (localStorage.defaultModule !== 'oem' && stock.status === stockStatus.ONTESTDRIVE && !_.isEmpty(stock.testDriveDetail)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Selected vehicle is on Test Drive'),
                text: CommonHelper.showLocale(props, 'In order to select You have to return it'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Return'),
                cancelButtonText: CommonHelper.showLocale(props, 'Cancel'),
            }).then((result) => {
                if (result.value) {
                    setEditDocument(stock.testDriveDetail.testdriveID);
                    setModelTestDriveShow(true);
                    setModelStock(stock);
                }
            })

        }
        else if (localStorage.defaultModule !== 'oem' && stock.status === stockStatus.ONLOAN && !_.isEmpty(stock.fleetDetail)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Selected vehicle is on Loan'),
                text: CommonHelper.showLocale(props, 'In order to select You have to return it'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Return'),
                cancelButtonText: CommonHelper.showLocale(props, 'Cancel'),
            }).then((result) => {
                if (result.value) {
                    setEditDocument(stock.fleetDetail.fleetID);
                    setModelFleetShow(true);
                    setModelStock(stock);
                }
            })

        }
    }

    const handleSelectClose = (ID, objData, activityID) => {

        const { alrtMsg } = props;
        if (alrtMsg) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, alrtMsg),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    props.handleClose(ID, objData, activityID)
                }
            })
        }
        else { props.handleClose(ID, objData, activityID) }

    }

    const handleClose = () => {
        setModelFleetShow(false);
        setModelTestDriveShow(false);
        setModelStock({});
        setEditDocument(null);
    }



    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative"
    };

    const hideStockPrice = (props.dealersettings?.rolePermissions?.permissions?.hideStockPrice) ? true : false;

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    return (
        <>
            <Modal
                id="stock-td-List"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-select-stock">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'testdriveBookings'} /></h5>
                    </Modal.Title>
                    <div className="ro-list-addbutton">
                        <button type="button" className="btn btn-secondary float-right" onClick={(e) => {
                            e.preventDefault();
                            props.handleClose();
                        }}><i className="ico icon-close"></i> <Translate text={'Skip & Continue'} /></button>
                    </div>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <Suspense>
                            <div style={divStyle} >
                                {
                                    loader ? (<PopoverListLoader />) :
                                        (!_.isEmpty(stocks) && stocks.length) > 0
                                            ?
                                            <div className='mr-2'>
                                                {stocks.map(activity =>
                                                    <div key={activity.documentID} id={`vehicle-stock-${activity.documentID}`} className="vehicle-popover-loop" >

                                                        {(() => {
                                                            let _stock = activity.stock

                                                            return <>
                                                                <div className={`divTable`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleStockSelect(_stock, activity.documentID);

                                                                }}>
                                                                    <div className="divTableBody">
                                                                        <div className="divTableRow">
                                                                            <div className="divTableCell width70">
                                                                                <div className="vehicle-stock-content">
                                                                                    <div className="vehicle-stock-image"> <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={(!_.isEmpty(_stock.images)) ? _stock.images[0] : CommonHelper.showBrandLogo(props.dealersettings, _stock.make)} alt="" width="70" height="70" className="img-object-fit-contain" /> </a> </div>
                                                                                    <div className="vehicle-stock-title">{_stock.make + " " + _stock.model}</div>

                                                                                    {
                                                                                        _stock.clientID && (!_.isEmpty(stockLocations) || _stock.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem') ? (
                                                                                            <div className="vehicle-stock-titlesub">
                                                                                                {
                                                                                                    localStorage.defaultModule === 'oem' && _stock.clientID && _stock.oemID && _stock.isAllocated
                                                                                                        ?
                                                                                                        <>
                                                                                                            <strong className="mr-1"><Translate text={'Allocated To'} />:</strong>
                                                                                                        </>
                                                                                                        :
                                                                                                        <> </>
                                                                                                }
                                                                                                <strong className="blue-color">{CommonHelper.getOtherDealerName(props.dealersettings, _stock.clientID)}</strong>

                                                                                            </div>) : (<></>)
                                                                                    }
                                                                                    <div className="dashboard-testdrive-activity-column">
                                                                                        Stock#: <div title={CommonHelper.showLocale(props, 'stockNo')}>{_stock?.stockNo || '--'}</div>
                                                                                        {_stock?.extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'exteriorColor')}>{_stock?.extColor}</div></>)}
                                                                                        {_stock?.year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{_stock?.year}</div></>)}
                                                                                        {_stock?.regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{_stock?.regNo}</div></>)}
                                                                                        {_stock?.vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{_stock?.vinNo}</div></>)}
                                                                                        {!hideStockPrice && props.displayPrice && _stock.price && (<><span className="veh-details-seperator">|</span><Translate text={'price'} />:&nbsp;<NumberFormat value={_stock.price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="divTableCell width30">
                                                                                <div className="vehicle-stock-status float-right">
                                                                                    {_stock.status
                                                                                        ?
                                                                                        <>
                                                                                            {CommonHelper.bindStockLabel(props.dealersettings?.client?.settings?.stockStatus, _stock.status, '', 'ml-1 float-right mt-1')}
                                                                                        </>
                                                                                        :
                                                                                        <></>}

                                                                                    {_stock.saleType ? <div className="badge badge-pill badge-white float-right mt-1">
                                                                                        {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.salesType, _stock.saleType, '')}
                                                                                    </div> : <></>}
                                                                                </div>
                                                                                {
                                                                                    !_.isEmpty(_stock.stockInDate)
                                                                                        ?
                                                                                        <div className="vehicle-stock-status float-right mt-2">
                                                                                            <div className="badge badge-pill status-stock-age">
                                                                                                <Translate text={'stockAge'} />{' : '}
                                                                                                {handleDiffDays(_stock.stockInDate)}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                {
                                                                    _stock.status && _stock.status !== stockStatus.AVAILABLE && _stock.statusModifiedBy && props.clientUsers //&& _stock.enquiryID !== enquiryID
                                                                        ?
                                                                        <>
                                                                            <div className="vehicle-current-status pl-2 pr-2 mt-0 border-0">
                                                                                <div className="vehicle-status-alert alert-user" >
                                                                                    <b className="alert-link">
                                                                                        {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.stockStatus, _stock.status)?.toUpperCase()}
                                                                                    </b>
                                                                                    {' by '}
                                                                                    {_stock.statusModifiedBy && props.clientUsers.filter(e => e.id === _stock.statusModifiedBy).length > 0 ? props.clientUsers.filter(m => m.id === _stock.statusModifiedBy)[0].name + '.' : '--'}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                            </>
                                                        })()}

                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'ico icon-Stock')}></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'noStockMsg'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>

                    </>
                </Modal.Body>
            </Modal>

            <PopUpModal show={modelFleetShow}>
                <AddFleet
                    docID={editDocumentID}
                    show={modelFleetShow}
                    clsActive='overlay-modal active'
                    stock={modelStock}
                    handleClose={handleClose}
                    title={'fleet'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </AddFleet>
            </PopUpModal>

            <PopUpModal show={modelTestDriveShow}>
                <AddTestDrive
                    docID={editDocumentID}
                    show={modelTestDriveShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={'testDrive'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isDeivered={false}
                    contact={null}
                >
                </AddTestDrive>
            </PopUpModal>

        </>
    )
}

export default StockLists