import moment from 'moment';
import _ from 'lodash'

// Date format will be
// 02 Aug 11:10 AM
export const convertMilisecondsTimeStampToDate = miliSecondsTime => {
	return moment(miliSecondsTime).format('DD MMM hh:mm A');
};

export const validateEmail = (email) => {
	let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return !_.isEmpty(email) ? regex.test(email) : false;
}

export const removeParamsFromURL = () => {
	const pathname = window.location.pathname
	if (window.history.pushState) {
		window.history.pushState(null, "title", `/settings`)
		window.history.pushState(null, "title", pathname)
	} else {
		window.history.replaceState(null, "", '/settings');
		window.history.replaceState(null, "", pathname);
	}
}

export const removeHTMLTags = html => {
	if (!html) {
		return ''
	}
	return html.replace(/<[^>]*>?/gm, '').replaceAll('&nbsp;', '')
}

export const firestoreDB = (dealersettings, isEvent) => {
	if (localStorage.defaultModule?.includes('eventsPro') || isEvent === true) {
		return window.firebase2;
	}
	else if (dealersettings?.client?.projectId === window.secondaryProjectId) {
		return window.firebase2;
	}
	return window.firebase;
}

export const storageBucket = (dealersettings, isEvent) => {
	if (localStorage.defaultModule?.includes('eventsPro') || isEvent === true) {
		return window.firebase.app().storage(`gs://${window.firebase.app().options.projectId}-eu`);
	}
	else if (dealersettings?.client?.projectId === window.secondaryProjectId) {
		return window.firebase.app().storage(`gs://${window.firebase.app().options.projectId}-eu`);
	}
	return window.firebase.storage();
}

export const setTheme = (brandTheme) => {
	if (brandTheme === 'porsche') {
		document.documentElement.style.setProperty('--dynfontFamily', "'Porsche Next', Arial, Helvetica, sans-serif");
		document.documentElement.style.setProperty('--body-text-color', "#000000");
		document.documentElement.style.setProperty('--innerbgColor', " #eeeff2");
		document.documentElement.style.setProperty('--primarybgColor', " #000000");
		document.documentElement.style.setProperty('--primarytxtColor', " #ffffff");
		document.documentElement.style.setProperty('--primaryHoverColor', " #535457");
		document.documentElement.style.setProperty('--bgdark', " #000000");
		document.documentElement.style.setProperty('--navbardark', " #363940");
		document.documentElement.style.setProperty('--navbardarkhover', " #4d5058");
		document.documentElement.style.setProperty('--financeinfo', " #000000");
		document.documentElement.style.setProperty('--rbctoday', " #e5e7eb");
		document.documentElement.style.setProperty('--navactive', " #f0f0f1");
		document.documentElement.style.setProperty('--addiconColor', " #dd0c29");
		document.documentElement.style.setProperty('--addiconhover', "#cc1922");
		document.documentElement.style.setProperty('--borderColor', " #d8d8db");
		document.documentElement.style.setProperty('--greybg', "#eeeff2");
		document.documentElement.style.setProperty('--lightbg', "#eeeff2");
		document.documentElement.style.setProperty('--fontweightsemibold', "600");
		document.documentElement.style.setProperty('--labelcolor', "#000000");
		document.documentElement.style.setProperty('--stagebg', "#f7f7f7");
		document.documentElement.style.setProperty('--tilesubfont', "#535457");
		document.documentElement.style.setProperty('--quickview-bg', "#eeeff2");
		document.documentElement.style.setProperty('--checkboxcolor', "#000000");
		document.documentElement.style.setProperty('--settingsmenu', "#495057");
		document.documentElement.style.setProperty('--settingsnavactive', "#d8d8db");
		document.documentElement.style.setProperty('--tableheadbg', "#f7f7f7");
		document.documentElement.style.setProperty('--tabledividerborder', "#D8D8DB");
		document.documentElement.style.setProperty('--stagestepbackground', "#dad9de");
		document.documentElement.style.setProperty('--stagestepactive', "#000000");
		document.documentElement.style.setProperty('--stagestepskip', "#535457");
		document.documentElement.style.setProperty('--btngreybackground', "#ececec");
		document.documentElement.style.setProperty('--bggrey', "#eeeff2");
		document.documentElement.style.setProperty('--bglightgrey', "#d8d8db");
		document.documentElement.style.setProperty('--closebg', "#000000");
		document.documentElement.style.setProperty('--tablehoverbg', "#f7f7f7");
		document.documentElement.style.setProperty('--uploadbg', "#eeeff2");
		document.documentElement.style.setProperty('--uploadborder', "#6b6d70");
		document.documentElement.style.setProperty('--scheduledbg', "#000000");
		document.documentElement.style.setProperty('--badgecompleted', "#197e10");
		document.documentElement.style.setProperty('--labelfontsize', "13px");
		document.documentElement.style.setProperty('--notifybg', "#eeeff2");
		document.documentElement.style.setProperty('--notifyhead', "#f7f7f7");
		document.documentElement.style.setProperty('--notifylabel', "#6b6d70");
		document.documentElement.style.setProperty('--swalborder', "2px solid #000");
		document.documentElement.style.setProperty('--swalblackbutton', "2px solid transparent");
		document.documentElement.style.setProperty('--dashfilterbg', "#f4f4f4");
		document.documentElement.style.setProperty('--greenbg', "#000000");
		document.documentElement.style.setProperty('--greenbadge', "#000000");
		document.documentElement.style.setProperty('--legallink', " #000");
		document.documentElement.style.setProperty('--legallinkhover', "#6b6d70");
		document.documentElement.style.setProperty('--themegreybg', "#f7f7f7");
		document.documentElement.style.setProperty('--mailnotifytitle', "#000000");
		document.documentElement.style.setProperty('--greybuttonbg', "#535457");
		document.documentElement.style.setProperty('--greybuttonbghover', "#6b6d70");
		document.documentElement.style.setProperty('--inputbordercolor', "#d8d8db");
		document.documentElement.style.setProperty('--inputbackgroundcolor', "#ffffff");
		document.documentElement.style.setProperty('--themelightgreybg', "#f7f7f7");
		document.documentElement.style.setProperty('--countrycodeborder', "#d8d8db");
		document.documentElement.style.setProperty('--bluehoverfill', "#535457");
		document.documentElement.style.setProperty('--antpickerborder', "#d8d8db");
		document.documentElement.style.setProperty('--shadowborder', "#d8d8db");
		document.documentElement.style.setProperty('--dealernamecolor', "#000000");
		document.documentElement.style.setProperty('--subtotalbg', "#f7f7f7");
		document.documentElement.style.setProperty('--borderbox', "#d8d8db");
		document.documentElement.style.setProperty('--dividerborder', "#d8d8db");
		document.documentElement.style.setProperty('--tabmenucolor', "#6b6d70");
		document.documentElement.style.setProperty('--tabmenucolorhover', "#495057");
		document.documentElement.style.setProperty('--basicselectfocus', "#d8d8db");
		document.documentElement.style.setProperty('--chatonlinecolor', "#2bb673");
		document.documentElement.style.setProperty('--emailleftbarbg', "#000000");
		document.documentElement.style.setProperty('--emailcomposebg', "#afb0b3");
		document.documentElement.style.setProperty('--emailmenuactive', "#535457");
		document.documentElement.style.setProperty('--emaillistactive', "#eeeff2");
		document.documentElement.style.setProperty('--emailthreadbg', "#eeeff2");
		document.documentElement.style.setProperty('--emailthreadborder', "#d8d8db");
		document.documentElement.style.setProperty('--emailattachmenticonbg', "#eeeff2");
		document.documentElement.style.setProperty('--emailattachmenticoncolor', "#535457");
		document.documentElement.style.setProperty('--emailgreytext', "#6b6d70");
		document.documentElement.style.setProperty('--buttondefaultbordercolor', "#000000");
		document.documentElement.style.setProperty('--antpicker-activebar', "#000000");
		document.documentElement.style.setProperty('--antpicker-focused', "#d8d8db");
		document.documentElement.style.setProperty('--antpicker-hover', "#000000");
		document.documentElement.style.setProperty('--antpicker-cell-inner', "#000000");
		document.documentElement.style.setProperty('--antpicker-headerlinkhover', "#000000");
		document.documentElement.style.setProperty('--antpicker-active', "#000000");
		document.documentElement.style.setProperty('--antpicker-cellselectionbg', "#eeeff2");
		document.documentElement.style.setProperty('--chat-greytext', "#afb0b3");
		document.documentElement.style.setProperty('--chat-messagegreybg', "#d8d8db");
		document.documentElement.style.setProperty('--antpicker-lightbg', "#d8d8db");
		document.documentElement.style.setProperty('--activity-textcolor', "#000000");
		document.documentElement.style.setProperty('--activity-usertcolor', "#000000");
		document.documentElement.style.setProperty('--antpicker-hoverbefore', "#d8d8db");
		document.documentElement.style.setProperty('--antpicker-dotted', "#d8d8db");
		document.documentElement.style.setProperty('--antpicker-focused-light', "#eeeff2");
		document.documentElement.style.setProperty('--pipeline-stagehover', "rgba(0,0,0,.6)");


	} else {
		document.documentElement.style.setProperty('--dynfontFamily', "'Source Sans Pro',Arial,Helvetica,sans-serif");
		document.documentElement.style.setProperty('--body-text-color', "#2a3042");
		document.documentElement.style.setProperty('--innerbgColor', "#f6f9fc");
		document.documentElement.style.setProperty('--primarybgColor', "#4466f2");
		document.documentElement.style.setProperty('--primarytxtColor', "#eff5fd");
		document.documentElement.style.setProperty('--primaryHoverColor', "#2b57e2");
		document.documentElement.style.setProperty('--bgdark', "#2a3042");
		document.documentElement.style.setProperty('--navbardark', "#3f4556");
		document.documentElement.style.setProperty('--navbardarkhover', "#545a6d");
		document.documentElement.style.setProperty('--financeinfo', "#6c9cff");
		document.documentElement.style.setProperty('--rbctoday', "#e6f4ff");
		document.documentElement.style.setProperty('--navactive', "#f2f6fa");
		document.documentElement.style.setProperty('--addiconColor', "#4466f2");
		document.documentElement.style.setProperty('--addiconhover', "#2b57e2");
		document.documentElement.style.setProperty('--borderColor', "#d3d9e6");
		document.documentElement.style.setProperty('--greybg', "#f2f5fa");
		document.documentElement.style.setProperty('--lightbg', "#edf0fe");
		document.documentElement.style.setProperty('--fontweightsemibold', "700");
		document.documentElement.style.setProperty('--labelcolor', "#6b717f");
		document.documentElement.style.setProperty('--stagebg', "#f6f9fc");
		document.documentElement.style.setProperty('--tilesubfont', "#6b717f");
		document.documentElement.style.setProperty('--quickview-bg', "#edf1f2");
		document.documentElement.style.setProperty('--checkboxcolor', "#2bb673");
		document.documentElement.style.setProperty('--settingsmenu', "#495057");
		document.documentElement.style.setProperty('--settingsnavactive', "#e5ebfb");
		document.documentElement.style.setProperty('--tableheadbg', "#f9fafb");
		document.documentElement.style.setProperty('--tabledividerborder', "#e0e7ed");
		document.documentElement.style.setProperty('--stagestepbackground', "#d3d9e6");
		document.documentElement.style.setProperty('--stagestepactive', "#4466f2");
		document.documentElement.style.setProperty('--stagestepskip', "#9fb5e2");
		document.documentElement.style.setProperty('--btngreybackground', "#f7f9fc");
		document.documentElement.style.setProperty('--bggrey', "#f2f4f5");
		document.documentElement.style.setProperty('--bglightgrey', "#e5ebfb");
		document.documentElement.style.setProperty('--closebg', "#30394d");
		document.documentElement.style.setProperty('--tablehoverbg', "#f8f9fc");
		document.documentElement.style.setProperty('--uploadbg', "#f2f7ff");
		document.documentElement.style.setProperty('--uploadborder', "#a7cbff");
		document.documentElement.style.setProperty('--scheduledbg', "#616b84");
		document.documentElement.style.setProperty('--badgecompleted', "#4CAF50");
		document.documentElement.style.setProperty('--labelfontsize', "12px");
		document.documentElement.style.setProperty('--notifybg', "#edf2f9");
		document.documentElement.style.setProperty('--notifyhead', "#f9fafd");
		document.documentElement.style.setProperty('--notifylabel', "#b7b9cc");
		document.documentElement.style.setProperty('--swalborder', "1px solid #d3d9e6");
		document.documentElement.style.setProperty('--swalblackbutton', "0");
		document.documentElement.style.setProperty('--dashfilterbg', "#f2f5f6");
		document.documentElement.style.setProperty('--greenbg', "#08a742");
		document.documentElement.style.setProperty('--greenbadge', "#2bb673");
		document.documentElement.style.setProperty('--legallink', " #007bff");
		document.documentElement.style.setProperty('--legallinkhover', "#0056b3");
		document.documentElement.style.setProperty('--themegreybg', "#f8f9fa");
		document.documentElement.style.setProperty('--mailnotifytitle', "#3c4968");
		document.documentElement.style.setProperty('--greybuttonbg', "#4a546e");
		document.documentElement.style.setProperty('--greybuttonbghover', "#596581");
		document.documentElement.style.setProperty('--inputbordercolor', "#dce3e6");
		document.documentElement.style.setProperty('--inputbackgroundcolor', "#fafbfc");
		document.documentElement.style.setProperty('--themelightgreybg', "#f8fafd");
		document.documentElement.style.setProperty('--countrycodeborder', "#dce3e6");
		document.documentElement.style.setProperty('--bluehoverfill', "#2955c8");
		document.documentElement.style.setProperty('--antpickerborder', "#d9d9d9");
		document.documentElement.style.setProperty('--shadowborder', "#cbcccd");
		document.documentElement.style.setProperty('--dealernamecolor', "#5473e8");
		document.documentElement.style.setProperty('--subtotalbg', "#eff3ff");
		document.documentElement.style.setProperty('--borderbox', "#d3d9e6");
		document.documentElement.style.setProperty('--dividerborder', "#d3d9e6");
		document.documentElement.style.setProperty('--tabmenucolor', "#6b717f");
		document.documentElement.style.setProperty('--tabmenucolorhover', "#495057");
		document.documentElement.style.setProperty('--basicselectfocus', "#deebff");
		document.documentElement.style.setProperty('--chatonlinecolor', "#2bb673");
		document.documentElement.style.setProperty('--emailleftbarbg', "#2a3042");
		document.documentElement.style.setProperty('--emailcomposebg', "#192535");
		document.documentElement.style.setProperty('--emailmenuactive', "#505c6b");
		document.documentElement.style.setProperty('--emaillistactive', "#def0ff");
		document.documentElement.style.setProperty('--emailthreadbg', "#eff8ff");
		document.documentElement.style.setProperty('--emailthreadborder', "#c0dbe6");
		document.documentElement.style.setProperty('--emailattachmenticonbg', "#eceffb");
		document.documentElement.style.setProperty('--emailattachmenticoncolor', "#4c6cd8");
		document.documentElement.style.setProperty('--emailgreytext', "#7a869a");
		document.documentElement.style.setProperty('--buttondefaultbordercolor', "#d3d9e6");
		document.documentElement.style.setProperty('--antpicker-activebar', "#1890ff");
		document.documentElement.style.setProperty('--antpicker-focused', "#1890ff33");
		document.documentElement.style.setProperty('--antpicker-hover', "#40a9ff");
		document.documentElement.style.setProperty('--antpicker-cell-inner', "#1890ff");
		document.documentElement.style.setProperty('--antpicker-headerlinkhover', "#1890ff");
		document.documentElement.style.setProperty('--antpicker-active', "#1890ff");
		document.documentElement.style.setProperty('--antpicker-cellselectionbg', "#e6f7ff");
		document.documentElement.style.setProperty('--chat-greytext', "#8497a3");
		document.documentElement.style.setProperty('--chat-messagegreybg', "#e3e7ef");
		document.documentElement.style.setProperty('--antpicker-lightbg', "#e6f7ff");
		document.documentElement.style.setProperty('--activity-textcolor', "#495057");
		document.documentElement.style.setProperty('--activity-usertcolor', "#6b717f");
		document.documentElement.style.setProperty('--antpicker-hoverbefore', "#cbe6ff");
		document.documentElement.style.setProperty('--antpicker-dotted', "#7ec1ff");
		document.documentElement.style.setProperty('--antpicker-focused-light', "#e6f7ff");
		document.documentElement.style.setProperty('--pipeline-stagehover', "rgba(48,64,120,.6)");
	}
}