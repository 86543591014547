import React, { useState, useEffect, useContext } from 'react';
import _, { toLower } from 'lodash'
import { ContentOverlay, GridView, PopoverPanel, UpgradePlan, ReactSelect } from '../../../../components';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import toast from 'toasted-notes'
import { allenquiryFields } from '../../../dealsLog/viewModel';
import { cellProps, levelOptions, permissionsValues } from './viewmodel';
import { clientLookups } from '../../workflow/lookups/viewModel';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc } from 'rxfire/firestore';
import images from '../../../../images';

const Permissions = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth - 30, windowHeight: (window.innerHeight - 215) })
    const [loader, setLoader] = useState(true)
    const [roles, setRoles] = useState([])
    const [fields, setFields] = useState([])
    const [currentSettings, setcurrentSettings] = useState({})
    const [popover, setpopover] = useState({})
    const [searchText, setSearchText] = useState()
    const [settingLoaded, setsettingLoaded] = useState(false)
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);

    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    useEffect(() => {
        if (localStorage.defaultModule === "oem")
            setSelectedLevel({
                'level': props.dealersettings.level,
                'levelID': props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                    props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                        props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                            props.dealersettings.client.id
            })
        else
            setSelectedLevel({
                'level': 'individual',
                'levelID': props.dealersettings.client.id
            })
    }, [])

    useEffect(() => {
        let _clients = [];
        props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).forEach(_client => {
            _clients.push({ label: _client.name, value: _client.documentID, data: _client })
        });
        setClientOptions(_clients)
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(props.dealersettings.regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(props.dealersettings.groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionID: _.find(props.dealersettings.clients, { "group": rec.documentID }) ? _.find(props.dealersettings.clients, { "group": rec.documentID }).region : ''
                }
            }))

    }, [clientOptions])

    useEffect(() => {
        if (_.isEmpty(clientOptions) || _.isEmpty(selectedLevel) || (_.isEmpty(selectedLevel.levelID) && selectedLevel.level !== 'oem'))
            return

        let refRegionID = ''
        let refGroupID = ''
        let refClientID = ''
        if (selectedLevel.level === 'region') {
            refRegionID = selectedLevel.levelID;
        }
        else if (selectedLevel.level === 'group') {
            if (_.find(clientOptions, { value: selectedLevel.levelID })?.data?.region)
                refRegionID = _.find(clientOptions, { value: selectedLevel.levelID })?.data?.region;
            refGroupID = selectedLevel.levelID;
        }
        else if (selectedLevel.level === 'individual') {
            if (_.find(clientOptions, { value: selectedLevel.levelID })?.data?.region)
                refRegionID = _.find(clientOptions, { value: selectedLevel.levelID })?.data?.region;
            if (_.find(clientOptions, { value: selectedLevel.levelID })?.data?.group)
                refGroupID = _.find(clientOptions, { value: selectedLevel.levelID })?.data?.group;
            refClientID = selectedLevel.levelID;
        }

        var enqOptionlookupsettings = combineLatest(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`)),
            refRegionID ? doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${refRegionID}`)) : of([]),
            refGroupID ? doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${refGroupID}`)) : of([]),
            refClientID ? doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${refClientID}`)) : of([]),
        )
            .pipe(
                map((lookups) => {
                    // Destructure the values to combine a single array.
                    var [oemlookups, regionLookups, groupLookups, clientLookups] = lookups;
                    return [
                        //spread the arrays out to combine as one array
                        { ...oemlookups.data(), id: oemlookups.id, documentID: oemlookups.id },
                        regionLookups.exists ? { ...regionLookups.data(), id: regionLookups.id, documentID: regionLookups.id } : {},
                        groupLookups.exists ? { ...groupLookups.data(), id: groupLookups.id, documentID: groupLookups.id } : {},
                        clientLookups.exists ? { ...clientLookups.data(), id: clientLookups.id, documentID: clientLookups.id } : {},
                    ];

                }))
            .subscribe(allDocs => {
                var lookups = [];
                for (let i = 0; i < 4; i++) {
                    lookups.push(allDocs[i])
                }
                let settingsDoc = {};
                lookups.forEach(rec => {
                    clientLookups.filter(e => e.value === 'enquiryOptionsDF' || e.value === 'enquiryOptionsStatic').forEach((key) => {
                        if (rec[key.value]) {
                            rec[key.value].forEach((_lookups) => {
                                if (settingsDoc[key.value]) {
                                    settingsDoc[key.value] = [
                                        ...settingsDoc[key.value],
                                        {
                                            ..._lookups,
                                            'levelID': rec.id,
                                            'levelName': rec.name,
                                            'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                            'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                            'documentID': _lookups.value,
                                            'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                                (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                        }
                                    ]
                                }
                                else {
                                    settingsDoc[key.value] = [{
                                        ..._lookups,
                                        'levelID': rec.id,
                                        'levelName': rec.name,
                                        'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                        'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                        'documentID': _lookups.value,
                                        'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                    }]
                                }
                            })
                        }
                        else if (_.isEmpty(settingsDoc[key.value])) {
                            settingsDoc[key.value] = [];
                        }
                    })
                })
                const _fields = [{
                    name: 'Roles',
                    value: 'name',
                    default: true,
                    pinned: 'left',
                    width: 200
                }];
                allenquiryFields.forEach(rec => {
                    _fields.push({
                        name: _.find(settingsDoc?.enquiryOptionsStatic, { value: rec.fieldDB })?.name || rec.name,
                        value: rec.value,
                        ...cellProps
                    })
                })
                if (settingsDoc?.enquiryOptionsDF?.length) {
                    settingsDoc?.enquiryOptionsDF.forEach(rec => {
                        _fields.push({
                            name: rec.name,
                            value: rec.type === 'price' || rec.type === 'number' ? rec.value : `str${rec.value}`,
                            ...cellProps
                        })
                    })
                }
                setFields(_fields);
                setcurrentSettings({
                    permissionsValues
                })
            });
        return () => {
            enqOptionlookupsettings && enqOptionlookupsettings.unsubscribe()
        }
    }, [clientOptions, selectedLevel])

    useEffect(() => {
        if (_.isEmpty(fields)) return
        window.firebase.firestore().collection(`clientSettings/${props.dealersettings.oemID}/roles`)
            .get()
            .then(querySnapshot => {
                let _roles = [];
                querySnapshot.forEach((rec) => {
                    _roles.push({ ...rec.data(), 'documentID': rec.id })
                })
                _roles = _roles.map(r => {
                    return {
                        ...r,
                        ...convertVM(r)
                    }
                })
                setRoles(_.orderBy(_roles, 'name', 'asc'))
                setLoader(false)
            })
    }, [fields])

    const convertVM = (data) => {
        let _obj = {};
        fields.filter(a => a.value !== 'name').forEach(rec => {
            _obj = {
                ..._obj,
                [rec.value]: data?.deallogPermissions?.[rec.value] || 'edit'
            }
        })
        return _obj;
    }

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 215)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const CustomPopover = ({ documentID, colId, params }) => {
        return <div className="custom-drop custom-drop-align">
            <ul className="roster-selection-dropdown">
                {
                    permissionsValues.map((rec, index) => {
                        return <li key={index}>
                            <a href="#" onClick={() => {
                                setpopover({ show: false });
                                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/roles/${documentID}`).update({
                                    [`deallogPermissions.${colId}`]: rec.value
                                });
                                params.api.getDisplayedRowAtIndex(params.rowIndex).setDataValue(params.colDef.field, rec.value);
                            }}> <span className={``} >
                                    {rec.name}</span></a>
                        </li>
                    })
                }
            </ul>
        </div >
    }

    const onCellClicked = (params) => {
        if (params?.column?.colId !== 'name') {
            const cellPosition = getcellPosition(params?.event?.target?.getBoundingClientRect())
            setpopover({
                show: true,
                position: cellPosition > 100 ? 'right' : 'left',
                target: params.event.target,
                documentID: params.data.documentID,
                colId: params?.column?.colId,
                params
            })
        }
    }

    const getcellPosition = (rec) => {
        const windowWidth = window.innerWidth;
        const cellRight = rec?.right || 0;
        const popoverWidth = 200;

        return windowWidth - cellRight - popoverWidth;
    }

    return (
        <>
            {
                liteVersion && !loader ? (<UpgradePlan />) : (<></>)
            }
            <h2> <Translate text={'Permissions'} /> </h2>
            <div className="divider-line"></div>
            <div className="settings-templates-wrap">
                <div className="settings-head form-style">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-3 pr-0">
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={(e, data) => {
                                            if (e) {
                                                if (e.value === 'oem')
                                                    setLoader(true)
                                                setSelectedLevel({
                                                    level: e.value
                                                });
                                            }
                                            else
                                                setSelectedLevel(null);
                                        }}
                                        value={selectedLevel ? selectedLevel.level : ''}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ? (<div className="col-md-3 pr-0">
                                        <ReactSelect
                                            options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                            onChange={(e, data) => {
                                                if (e) {
                                                    setLoader(true)
                                                    setSelectedLevel({
                                                        ...selectedLevel,
                                                        levelID: e.value
                                                    });
                                                }
                                                else
                                                    setSelectedLevel(null);
                                            }}
                                            value={selectedLevel ? selectedLevel.levelID : ''}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>) : (<></>)
                                }
                                <div className="col-md-3">
                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    <div className='mt-2'>
                        {
                            selectedLevel && (selectedLevel.level === 'oem' || !_.isEmpty(selectedLevel.levelID)) ? (<div className="common-table">
                                <GridView
                                    {...windowSize}
                                    dealersettings={props.dealersettings}
                                    dynamicCols={fields.filter(({ name, value }) =>
                                    (!_.isEmpty(searchText) ? (
                                        value === 'name'
                                        || (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false)
                                    ) : true))}
                                    rowData={roles}
                                    loader={loader}
                                    hideSno={true}
                                    minCellWidth={120}
                                    hideContextMenu={true}
                                    hideHeaderMenuButton={true}
                                    rowHeight={40}
                                    currentSettings={currentSettings}
                                    onCellClicked={onCellClicked}
                                />
                            </div>
                            ) : (
                                (
                                    <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                                        <div className='h-100  w-100'>
                                            <div className='spinner-loader h-100'>
                                                <div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
                                                    <div className="no-data-img"> <img src={images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2">
                                                        <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
            }
            <PopoverPanel
                showpopover={popover.show}
                targetpopover={popover.target}
                id={'roster-custom-drop'}
                position={popover.position}
                className=""
                closepopover={() => {
                    setpopover({ show: false });
                }}>
                <CustomPopover
                    {...popover}
                />
            </PopoverPanel>
        </>
    )
}

export default Permissions;