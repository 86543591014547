import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap';
import { AntMonthPicker, BarChart, ContentOverlay, PopUpModal, ReactMultiSelect, ReactSelect } from '../../../../components';
import ImportCSV from './importCSV';
import images from '../../../../images';
import { dealertargetDynCols } from '../../../reports/viewModel';
import moment from 'moment';


const CustomerTargetReport = (props) => {
    const [loader, setLoader] = useState(false)
    const [showImportCSV, setImportCSV] = useState({ show: false })
    const [regionOptions, setRegionOptions] = useState();
    const [selectedRegion, setRegion] = useState();
    const [months, setMonths] = useState(moment().format("MMM"))
    const [year, setYear] = useState(moment().format("YYYY"));
    const [cummulativeTargets, setCummulativeTargets] = useState([])
    const [startDate, setStartDate] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('year')._d))
    const [endDate, setEndDate] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d))
    const [targetData, setTargetData] = useState([])
    const [targetType, setTargetType] = useState([])
    const [models, setModels] = useState([])
    const [objFilter, setObjFilter] = useState({});

    useEffect(() => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        setRegionOptions(regionsToReturn)
        setRegion(regionsToReturn[0])
        setLoader(true)
    }, [])

    useEffect(() => {
        if (_.isEmpty(selectedRegion)) {
            return
        }

        let objParam = {
            clientID: props.dealersettings.clients.filter(client => client.region === selectedRegion.value).map(r => { return r.id }).join(','),
            targetdocID: props.dealersettings.clients.filter(client => client.region === selectedRegion.value).map(r => { return `retailer${r.id}` }).join(','),
            startDate: moment.unix(startDate.seconds).format('YYYY-MM-DD'),
            endDate: moment.unix(endDate.seconds).format('YYYY-MM-DD'),
            timezone: moment.tz.guess()
        };
        const reportData = window.firebase.functions().httpsCallable('generic-getData');
        reportData({
            type: 'getRetailerTarget',
            params: JSON.stringify(objParam)
        }).then((response) => {

            const data = response?.data?.data?.length ? response.data.data : [];
            setCummulativeTargets([])
            setTargetType([])
            setModels([])
            setTargetData(data);
            if (data.length) {
                setObjFilter(data[0]?.targetID ? { targetID: data[0]?.targetID } : {})
            } else {
                setLoader(false)
            }
        });

    }, [selectedRegion])

    useEffect(() => {
        if (_.isEmpty(targetData))
            return

        const data = targetData.filter(a =>
            (objFilter?.targetID ? a.targetID === objFilter.targetID : true) &&
            (objFilter?.model?.length ? objFilter?.model?.includes(a.model) : true) &&
            (objFilter?.clients?.length ? objFilter?.clients?.includes(a.clientID) : true)
        )
        let _target = _.chain(data)
            .groupBy('targetID')
            .map((value, key) => {
                return {
                    targetName: value[0].name,
                    calculationType: value[0].calculationType,
                    currSymbol: value[0].currSymbol || null,
                    targetID: key,
                    counts: _.chain(value).groupBy('month').map((value, key) => ({ month: key, values: value.map(r => { return { ...r, totarget: parseInt(r.totarget || 0), actual: parseInt(r.actual || 0), target: parseInt(r.target || 0), year: parseInt(r.year) } }) })).value().map((rec, index) => {
                        return {
                            ...rec.values[0],
                            delta: moment().month(rec.month).month() <= moment().month(`${rec.values[0].year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? _.sumBy(rec.values, 'totarget') === 0 ? 0 : -(value[0].calculationType === 'percentage' ? _.meanBy(rec.values, 'totarget') : _.sumBy(rec.values, 'totarget')) : null,
                            target: value[0].calculationType === 'percentage' ? _.meanBy(rec.values, 'target') : _.sumBy(rec.values, 'target'),
                            actual: moment().month(rec.month).month() <= moment().month(`${rec.values[0].year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? value[0].calculationType === 'percentage' ? _.meanBy(rec.values, 'actual') : _.sumBy(rec.values, 'actual') : null,
                            index
                        }
                    })
                }
            }).value();
        let _cummulativeTarget = _target.map(rec => {
            return {
                ...rec,
                counts: rec.counts.map((r, i) => {
                    return {
                        ...r,
                        cummulativeTarget: _.sumBy(rec.counts.filter(a => a.index <= i), 'target'),
                        cummulativeActual: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.actual : _.sumBy(rec.counts.filter(a => a.index <= i), 'actual') : null,
                        cummulativeDelta: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.delta : _.sumBy(rec.counts.filter(a => a.index <= i), 'delta') : null
                    }
                })
            }
        })
        setCummulativeTargets(_cummulativeTarget);
        setTargetType(_.uniqBy(targetData, 'targetID').map(r => { return { label: r.name, value: r.targetID } }))
        setModels(_.uniqBy(targetData, 'model').map(r => { return { label: r.model, value: r.model } }))
        setLoader(false);
    }, [targetData, objFilter])

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                client.region === selectedRegion?.value)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getDealers = () => {
        let clientsToReturn = props.dealersettings.clients;

        if (selectedRegion) {
            clientsToReturn = clientsToReturn.filter(client =>
                client.region === selectedRegion.value
            );
        }

        if (!_.isEmpty(objFilter['subregion'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['subregion'].includes(client.subregion)
            );
        }

        clientsToReturn = clientsToReturn.map(client => {
            return {
                value: client.documentID,
                doc: client,
                label: client.name,
                active: _.isBoolean(client.active) ? client.active : true,
            };
        })

        return clientsToReturn
    }

    const setMultibjFilter = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let _objFilter = objFilter;
        if (name === 'region') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['subregion']: [],
                ['group']: [],
                ['country']: [],
                ['dealer']: [],
            };
        } else if (name === 'subregion') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['country']: [],
                ['group']: [],
                ['dealer']: [],
            };
        } else {
            _objFilter = {
                ..._objFilter,
                [name]: value,
            };
        }
        _objFilter.clients = props?.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
            (client.region === selectedRegion.value) &&
            (_objFilter?.subregion?.length > 0 ? _objFilter.subregion.includes(client.subregion) : true) &&
            (_objFilter?.dealer?.length > 0 ? _objFilter.dealer.includes(client.documentID) : true)
        ).map(r => { return r.id }) : []
        setObjFilter(_objFilter);
    }

    return (
        <>
            <div className="middle-wrapper">
                <div className="report-page-head mt-3">
                    <h3>Retailer Target Report
                        <button type="button" className="btn btn-primary float-right ml-2" onClick={e => {
                            e.preventDefault();
                            setImportCSV({ show: true })
                        }}>
                            <i className="ico icon-excel-export mr-2 mt-1 float-left"></i>   Upload Excel
                        </button>
                    </h3>
                </div>
                <div className="divider-line"></div>
                <div className="report-content-head">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="form-style d-block">
                                <div className="form-row">
                                    <div className="form-group col-md-1 daterange-fix">
                                        <AntMonthPicker
                                            value={moment().month(months).year(year)._d}
                                            name={'year'}
                                            onChange={val => {
                                                setMonths(moment(val).format("MMM"))
                                                setYear(moment(val).format("YYYY"))
                                                setStartDate(window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('year')._d))
                                                setEndDate(window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('year')._d))
                                                setLoader(true)
                                            }}
                                            placeholder='select year'
                                            className={`form-control cursor-pointer`}
                                            format={'YYYY'}
                                            picker={'year'}
                                        />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <ReactSelect
                                            options={regionOptions}
                                            name={'region'}
                                            placeholder={`select region`}
                                            onChange={(e) => {
                                                setRegion(e)
                                                setLoader(true)
                                            }}
                                            value={selectedRegion?.value}
                                            classNamePrefix={`basic-select`}
                                            isSearchable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <ReactMultiSelect
                                            options={getSubregions()}
                                            name={'subregion'}
                                            placeholder={`select subregion`}
                                            onChange={(e) => {
                                                setMultibjFilter(e, 'subregion')
                                            }}
                                            value={objFilter['subregion']}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <ReactMultiSelect
                                            options={getDealers()}
                                            name={"clientID"}
                                            placeholder={'select client'}
                                            onChange={(e, data) => {
                                                setMultibjFilter(e, 'dealer')
                                            }}
                                            value={objFilter['dealer']}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <ReactSelect
                                            options={targetType}
                                            name={"targetID"}
                                            placeholder={'target category'}
                                            onChange={(e, data) => {
                                                setObjFilter({
                                                    ...objFilter,
                                                    ['targetID']: e?.value
                                                })
                                            }}
                                            value={objFilter?.targetID || null}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <ReactMultiSelect
                                            options={models}
                                            name={"model"}
                                            placeholder={'select model'}
                                            onChange={(selectedOptions) => {
                                                let value = [];
                                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                    value.push(data.value)
                                                })
                                                setObjFilter({
                                                    ...objFilter,
                                                    ['model']: value
                                                })
                                            }}
                                            value={objFilter?.model || []}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className="common-table">
                        {
                            !_.isEmpty(cummulativeTargets) ? cummulativeTargets.map((cTarget, index) => {
                                return (<Fragment key={index}>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td className="head-light" width="20%"></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">TARGET</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="count-large text-center">{`${_rec.target !== null ? `${cTarget.calculationType === 'amount' && cTarget.currSymbol ? `${cTarget.currSymbol} ` : ''}${_rec.target.toLocaleString()}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="count-large text-center">{`${_rec.actual !== null ? `${cTarget.calculationType === 'amount' && cTarget.currSymbol ? `${cTarget.currSymbol} ` : ''}${_rec.actual.toLocaleString()}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">DELTA</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className={`count-large text-center ${_rec.showRed === "true" && _rec.calculationType === 'percentage' ? 'down-ico' : ''}`}>
                                                            {
                                                                _rec.showRed === "false" && _rec.calculationType === 'percentage' ? <img src={images.tickgreen} alt="" height="20" /> :
                                                                    `${cTarget.calculationType === 'amount' && cTarget.currSymbol && _rec.delta ? `${cTarget.currSymbol} ` : ''}${_rec.delta ? `${_rec.delta.toLocaleString()}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`
                                                            }
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                    {
                                        cTarget.calculationType === 'count' || cTarget.calculationType === 'amount' ? (<table className="table table-bordered mt-2">
                                            <thead>
                                                <tr>
                                                    <td className="head-light" width="20%"></td>
                                                    {
                                                        cTarget.counts.map((_rec, _i) => {
                                                            return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="head-light" width="20%"><span className="th-blue-clr">TARGET - CUMMULATIVE</span></td>
                                                    {
                                                        cTarget.counts.map((_rec, _i) => {
                                                            return <td key={_i} className="text-center">{cTarget.calculationType === 'amount' && cTarget.currSymbol && _rec.cummulativeTarget ? `${cTarget.currSymbol} ` : ''}{_rec.cummulativeTarget?.toLocaleString()}</td>
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL - CUMMULATIVE</span></td>
                                                    {
                                                        cTarget.counts.map((_rec, _i) => {
                                                            return <td key={_i} className="text-center">{cTarget.calculationType === 'amount' && cTarget.currSymbol && _rec.cummulativeActual ? `${cTarget.currSymbol} ` : ''}{_rec.cummulativeActual?.toLocaleString()}</td>
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="head-light" width="20%"><span className="th-blue-clr">DELTA - CUMMULATIVE</span></td>
                                                    {
                                                        cTarget.counts.map((_rec, _i) => {
                                                            return <td key={_i} className="text-center">{cTarget.calculationType === 'amount' && cTarget.currSymbol && _rec.cummulativeDelta ? `${cTarget.currSymbol} ` : ''}{_rec.cummulativeDelta?.toLocaleString()}</td>
                                                        })
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>) : (<></>)
                                    }
                                    <div className="text-center">
                                        <BarChart
                                            id={cTarget.targetID}
                                            height={`400px`}
                                            data={cTarget.counts}
                                            columns={['target', 'actual']}
                                            lineColumns={cTarget.calculationType === 'count' || cTarget.calculationType === 'amount' ? ['cummulativeTarget', 'cummulativeActual'] : []}
                                            dynamicColumns={dealertargetDynCols}
                                            xCategory={'month'}
                                            calculationType={cTarget.calculationType}
                                            handleShowEnquiryList={(value, startDate, endDate) => {

                                            }}
                                            hideBullet={true}
                                            dynColor={true}
                                        />
                                    </div>
                                </Fragment>)
                            }) : (<div className="spinner-loader h-100 p-5">
                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                    <div className="no-data-img"> <img src={images[`nodata${localStorage.brandTheme === 'porsche' ? 'Black' : ''}`]} width="60" height="60" alt="" /></div>
                                    <div className="no-data-txt mt-2"> Target not set for selected dealer</div>
                                </div>
                            </div>)
                        }
                    </div>
                    )
                }
            </div>
            <PopUpModal show={showImportCSV.show}>
                <ImportCSV
                    {...props}
                    {...showImportCSV}
                    handleClose={(val) => { setImportCSV({ show: false });  }}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
        </>
    )
}

export default CustomerTargetReport;