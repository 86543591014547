/** LIBRARIES */
import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** PROVIDERS */
import { LayoutConsumer } from '../../layout/provider';
import { StageProvider } from '../../pipeline/provider';
/** COMPONENTS */
import {
	PopUpModal,
	ReactSelect,
	LoaderOverlay,
	SidePanel,
	ContentOverlay
} from '../../../components';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
//import QuickView from '../../pipeline/quickview';
import QuickEnquiryInfo from '../../pipeline/details/quickInfo'
import Activity from '../../pipeline/details/activity';
import EnquiryInfo from '../../pipeline/enquiryInfo';
import AddContact from '../../contacts/add';
// import OwnedVehicleInfo from '../owned/ownedVehicleInfo';
// import OwnedEnquiryInfo from '../owned/ownedEnquiryInfo';
// import AddOwnedVehicle from '../owned/addOwnedVehicle';
import Files from '../../files';
import AddFile from '../../files/add';
import PageNotFound from '../../../components/pagenotFound';

import QuickViewService from '../../service/quickview';
import QuickViewTradeinPro from '../../tradeinplus/quickview'
import ServiceInfo from '../../service/serviceInfo';
import TIPInfo from '../../tradeinplus/info'
/** VIEW-MODELS */
import {
	objContact,
} from '../../contacts/viewModel';
import { objEnquiry } from '../../pipeline/viewModel';
import { objCompany } from '../viewModel';
import { Dropdown } from 'react-bootstrap';
import ChatSidePanel from '../../chat/chatsidepanel';
import AddCompany from '../add';
import { firestoreDB } from '../../../services/helper';


let titles = [];
let languages = [];
let license_state = [];
export default class CompanyInfo extends Component {
	// _isMounted = false;
	constructor(props) {
		super(props);
		this.refEnquiry = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
		this.refContacts = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
		this.refCompany = firestoreDB(this.props.dealersettings).firestore().collection('companies');
		this.refActivity = firestoreDB(this.props.dealersettings).firestore().collection('activities');
		this.refServiceJobs = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs');
		this.refTIP = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro');
		this.newID = this.refCompany.doc().id;
		this.unsubscribeContacts = null;
		this.unsubscribeCompany = null;
		this.unsubscribeEnquiryCoD = null;
		this.unsubscribeService = null;
		this.unsubscribeTradeinPro = null;
		this.unsubscribeActivity = null;
		this.unsubscribeEnquiryOwned = null;
		this.unsubscribeOwned = null;
		this.state = {
			loading: false,
			activeLeftKey: '0',
			activeRightKey: '02',
			// contact: Object.assign({}, objContact),
			company: Object.assign({}, objCompany),
			enquiry: {},
			enquiries: Object.assign([]),
			services: Object.assign([]),
			tradeinPro: Object.assign([]),
			contacts: Object.assign([]),
			activities: Object.assign([]),
			ownedVehicles: Object.assign([]),
			ownedEnquiryVehicles: Object.assign([]),
			contactID: null,
			companyID: null,
			clientUsers: [],
			companyModal: {
				show: false,
				docID: null,
			},
			ownedModal: {
				show: false,
				ID: '',
				title: '',
				clsActive: '',
			},
			showpanel: {
				clsActive: '',
				clsName: 'sidebar-quickview',
				enquiryid: 0,
			},
			showServicepanel: {
				clsActive: '',
				clsName: 'sidebar-service-quickview',
				serviceid: 0,
			},
			showTradeinPropanel: {
				clsActive: '',
				clsName: 'sidebar-tradeinpro-quickview',
				tradeinproid: 0,
			},
			showSharePanel: {
				chatClsActive: '',
				chatClsName: 'sidebar',
			},
			loadContacts: false,
			pagenotFound: false,
			fileModal: {
				show: false
			},
			showContact: false,
			enquiryhasMore: [],
			enquiryPageLimit: 20,
			enquiriesCount: 0
		};
	}
	//#region  QUICK VIEW ENQUIRY
	sidepanelOpen = id => {
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		this.setState({
			showpanel: {
				clsActive: 'active',
				clsName: 'sidebar-quickview',
				enquiryid: id,
			},
		});
	};

	sidepanelClose = () => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		this.setState({
			showpanel: {
				clsActive: '',
				clsName: 'sidebar-quickview',
				enquiryid: 0,
			},
		});
	};
	//#endregion

	//#region  QUICK VIEW SERVICE
	sidepanelServiceOpen = id => {
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		//if (localStorage.defaultModule === 'service') {
		this.setState({
			showServicepanel: {
				clsActive: 'active',
				clsName: 'sidebar-service-quickview',
				serviceid: id,
			},
		});
		//}

	};

	sidepanelServiceClose = () => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		this.setState({
			showServicepanel: {
				clsActive: '',
				clsName: 'sidebar-service-quickview',
				serviceid: 0,
			},
		});
	};
	//#endregion

	//#region  QUICK VIEW Tradein Pro
	sidepanelTIPOpen = id => {
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		//this.props.history.push('/tradeinpro/details/' + id);
		//if (localStorage.defaultModule === 'tradeinPro') {
		this.setState({
			showTradeinPropanel: {
				clsActive: 'active',
				clsName: 'sidebar-tradeinpro-quickview',
				tradeinproid: id,
			},
		});
		//}

	};

	sidepanelTIPClose = () => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		this.setState({
			showTradeinPropanel: {
				clsActive: '',
				clsName: '',
				tradeinproid: 0,
			},
		});
	};
	//#endregion

	//#region CONTACT CHANGES

	handleDeleteCompany = e => {
		e.preventDefault();
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, 'You want to delete company.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
		}).then(result => {
			if (result.value) {
				let state = Object.assign({}, this.state.company);
				state.isDeleted = true;

				state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
				state.modifiedDate = window.firebase.firestore.Timestamp.now();
				this.setState({ company: state });
				const updateRef = this.refCompany.doc(this.state.companyID);
				updateRef
					.update({
						isDeleted: state.isDeleted,
						modifiedBy: state.modifiedBy,
						modifiedDate: state.modifiedDate,
						modifiedFrom: 'web'
					})
					.then(docRef => {
						toast.notify('Company deleted successfully', {
							duration: 2000,
						});

						let logNotes = 'Company deleted';
						if (!_.isEmpty(state) && state.name)
							logNotes = 'Company: ' + state.name + ' deleted';

						let _objLogData = {
							notes: logNotes,
							type: 'companies',
							subType: 'delete',
							recordId: this.state.companyID,
						}
						_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, this.props.dealersettings);
						CommonHelper.saveAuditLog(_objLogData)


						let search = window.location.search;
						let params = new URLSearchParams(search);
						let backURL = params.get('back-url');

						if (backURL) this.props.history.push(backURL);
						else this.props.history.push('/contacts');
					})
					.catch(error => {
						console.error('Error updating company: ', error);
					});

			}
		});
	}

	convertContactVM = (doc) => {

		doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
		doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

		const objcompanyData = Object.assign({}, doc);
		const company = Object.assign({}, objCompany);
		for (let [key, value] of Object.entries(objcompanyData)) {
			company[key] = value;
		}
		// company.fullName = CommonHelper.getFullName(titles, company);
		company.fullName = company.name

		let dealersettings = this.props.dealersettings;
		if (company.clientID && dealersettings && dealersettings.group &&
			dealersettings.group.clients && dealersettings.group.clients[company.clientID] && dealersettings.group.clients[company.clientID].settings) {
			const _setttings = Object.assign({}, dealersettings.group.clients[company.clientID].settings);
			company.type = CommonHelper.getNameByValue(_setttings.companyType, company.type, '');
		}
		else {
			const _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
			company.type = CommonHelper.getNameByValue(_setttings.companyType, company.type, '');
		}

		if (dealersettings && company.clientID && (company.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
			company.dealerName = CommonHelper.getOtherDealerName(dealersettings, company.clientID);
		}

		return company
	}

	handleContactClose = () => {
		this.setState({ showContact: false });
	};

	//#endregion

	//#region LOAD PAGE

	onCompanyCollectionUpdate = docSnapshot => {
		document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		if (!docSnapshot.exists) {
			return;
		}
		const company = this.convertContactVM(docSnapshot.data());
		company.documentID = docSnapshot.id;

		let _companyBasicVM = Object.assign({}, objCompany);
		_companyBasicVM.documentID = docSnapshot.id;
		for (let [key, value] of Object.entries(docSnapshot.data())) {
			if (_companyBasicVM.hasOwnProperty(key)) _companyBasicVM[key] = value;
		}

		company.fullName = company.name

		let dealersettings = this.props.dealersettings;
		if (company.clientID && dealersettings && dealersettings.group &&
			dealersettings.group.clients && dealersettings.group.clients[company.clientID] && dealersettings.group.clients[company.clientID].settings) {
			const _setttings = Object.assign({}, dealersettings.group.clients[company.clientID].settings);
			company.type = CommonHelper.getNameByValue(_setttings.companyType, company.type, '');
		}
		else {
			const _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
			company.type = CommonHelper.getNameByValue(_setttings.companyType, company.type, '');
		}

		if (dealersettings && company.clientID && (company.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
			company.dealerName = CommonHelper.getOtherDealerName(dealersettings, company.clientID);
		}

		this.setState({
			companyID: docSnapshot.id,
			company: company,
			companyBasicVM: _companyBasicVM,
		});
	};

	onEnquiryCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const enquiries = [];
		let _fields = Object.assign({}, objEnquiry);
		if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === this.state.enquiryPageLimit) {
			this.setState({
				enquiryhasMore: [{
					lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
				}]
			})
		}
		else {
			this.setState({
				enquiryhasMore: []
			})
		}
		querySnapshot.forEach(_enquiry => {
			const enquiry = Object.assign({}, objEnquiry);
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
			}

			_fields = enquiry;
			enquiries.push(enquiry);
		});

		this.setState({
			enquiries: _.uniqBy(_.orderBy([
				...this.state.enquiries,
				...enquiries
			], ["modifiedDate"], ["desc"]), 'documentID'),
			enquiry: _fields,
			enqPagingLoader: false
		});
	};

	onContactsCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const contacts = [];
		let _fields = Object.assign({}, objContact);
		querySnapshot.forEach(_contact => {
			const contact = Object.assign({}, objContact);
			const objContactData = Object.assign({}, _contact.data());
			for (let [key, value] of Object.entries(objContactData)) {
				contact[key] = value;
			}
			contact.documentID = _contact.id;

			let dealersettings = this.props.dealersettings;
			// if (contact.clientID && dealersettings && dealersettings.group &&
			// 	dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings) {
			// 	const _setttings = Object.assign({}, dealersettings.group.clients[contact.clientID].settings);

			// 	contact.language = CommonHelper.getNameByCode(dealersettings.languages, contact.language, '');
			// 	contact.nationality = CommonHelper.getNameByValue(dealersettings.nationalities, contact.nationality, '');

			// 	contact.fullName = CommonHelper.getFullName(_setttings.titles, contact, '', true);
			// 	contact.campaign = CommonHelper.getNameByValue(_setttings.campaigns, contact.campaign, '');
			// 	contact.origin = CommonHelper.getNameByValue(_setttings.origins, contact.origin, '');
			// 	contact.gender = CommonHelper.getNameByValue(_setttings.genders, contact.gender, '');
			// 	contact.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, contact.maritalStatus, '');
			// 	contact.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, contact.contactMethod, '');
			// 	contact.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, contact.interests, '', true);
			// 	contact.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, contact.licenseType, '');
			// }

			if (dealersettings && contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);
			}

			_fields = contact;
			contacts.push(contact);
		});

		this.setState({
			contacts: _.orderBy(contacts, ["modifiedDate"], ["desc"]),
			contact: _fields,
			loadContacts: true
		});
	}

	onServiceJobCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const services = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_enquiry => {
			const enquiry = Object.assign({});
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
			}

			if (!_.isEmpty(enquiry.pipeline)) {
				_fields = enquiry;
				services.push(enquiry);
			}

		});

		this.setState({
			services: _.orderBy(services, ["modifiedDate"], ["desc"])
		});
	};

	onTIPCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const _tradeinPro = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_enquiry => {
			const enquiry = Object.assign({});
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			let dealersettings = this.props.dealersettings;
			if (dealersettings && enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				enquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID);
			}

			if (!_.isEmpty(enquiry.pipeline)) {
				_fields = enquiry;
				_tradeinPro.push(enquiry);
			}

		});

		this.setState({
			tradeinPro: _.orderBy(_tradeinPro, ["modifiedDate"], ["desc"])
		});
	};

	async loadEnquiry() {
		const { companyid, dealersettings, clientUsers, groupUsers } = this.props;
		document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		if (companyid && dealersettings) {
			const refData = await this.refCompany.doc(companyid).get();

			if (refData.exists) {
				//console.log('E              X             I                S                  T             S', contactid)
				let _clientUsers = [];
				const company = this.convertContactVM(refData.data());
				company.documentID = refData.id;

				this.unsubscribeCompany = this.refCompany
					.doc(companyid)
					.onSnapshot(this.onCompanyCollectionUpdate);

				this.unsubscribeService = this.refServiceJobs
					.where('contact.company.documentID', '==', companyid)
					.where('contact.businessContact', '==', true)
					.where('isDeleted', '==', false)
					.onSnapshot(this.onServiceJobCollectionUpdate);

				this.unsubscribeTradeinPro = this.refTIP
					.where('contact.company.documentID', '==', companyid)
					.where('contact.businessContact', '==', true)
					.where('isDeleted', '==', false)
					.onSnapshot(this.onTIPCollectionUpdate);

				this.unsubscribeContacts = this.refContacts
					.where('company.documentID', '==', companyid)
					//.orderBy('modifiedDate', 'desc')
					.where('isDeleted', '==', false)
					.onSnapshot(this.onContactsCollectionUpdate);


				if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
					const client = dealersettings.client;
					const setttings = client.settings;
					let defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
					titles = [];
					setttings.titles && setttings.titles.forEach((doc) => {
						titles.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					languages = [];
					dealersettings.languages && dealersettings.languages.forEach((doc) => {
						languages.push({
							value: doc.code,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					license_state = [];
					defaultCountry && dealersettings.states &&
						dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
							license_state.push({
								value: doc.code,
								active: _.isBoolean(doc.active) ? doc.active : true,
								label: doc.name
							});

						});
				}

				let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
				if (company.clientID && !_.isEmpty(groupUsers)) {
					_clientUsers = groupUsers.filter(e => e.clientID === company.clientID);
				}
				else if (!_.isEmpty(_allUsers)) {
					_.uniq(_allUsers.map(v => _.pick(v, ['id']).id)).map(e => { return { ..._.find(_allUsers, { id: e }) } }).forEach((rec) => {
						_clientUsers.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						});
					});
				}

				this.loadPagingEnquiry();
				try {
					let _searchObj = {
						type: "getEnquiriesCount",
						params: JSON.stringify({
							companyID: companyid
						}),
					}
					const getData = window.firebase.functions().httpsCallable('generic-getData');
					getData(_searchObj).then((resp) => {
						if (!_.isEmpty(resp) && !_.isEmpty(resp.data) && resp.data.success) {
							const data = resp.data.data[0]
							this.setState({
								enquiriesCount: data.enquiries
							});
						}
					});
				} catch (err) { }

				this.setState({
					companyID: refData.id,
					company: company,
					clientUsers: _clientUsers,
				});
			}
			else {
				this.setState({
					pagenotFound: true,
					isLoading: false,
					companyID: null
				})
			}
		}
	}

	loadPagingEnquiry() {
		let lastRecord = this.state.enquiryhasMore;

		var enquiryQuery = this.refEnquiry
			.where('contact.company.documentID', '==', this.props.companyid)
			.where('contact.businessContact', '==', true)
			.where('isDeleted', '==', false);

		if (lastRecord.length > 0) {
			enquiryQuery = enquiryQuery
				.startAfter(lastRecord[0].lastDoc)
				.limit(this.state.enquiryPageLimit)
		}
		else {
			enquiryQuery = enquiryQuery
				.limit(this.state.enquiryPageLimit)
		}
		this.unsubscribeEnquiryCoD = enquiryQuery
			.onSnapshot(this.onEnquiryCollectionUpdate);
	}

	componentDidMount() {
		localStorage.removeItem("objSales");
		this._isMounted = true;
		if (this._isMounted) {
			this.loadEnquiry();
			setTimeout(() => this.scrollIntoComments(), 4000);
		}
	}

	componentDidUpdate() {
		if (this.state.loading) {
			this.clearAllSubscribe();
			this.loadEnquiry();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.clearAllSubscribe();
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
	}

	clearAllSubscribe = () => {
		this.unsubscribeContacts && this.unsubscribeContacts();
		this.unsubscribeCompany && this.unsubscribeCompany();
		this.unsubscribeActivity && this.unsubscribeActivity();
		this.unsubscribeEnquiryCoD && this.unsubscribeEnquiryCoD();
		this.unsubscribeService && this.unsubscribeService();
		this.unsubscribeTradeinPro && this.unsubscribeTradeinPro();
		this.unsubscribeEnquiryOwned && this.unsubscribeEnquiryOwned();
		this.unsubscribeOwned && this.unsubscribeOwned();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.companyid !== state.companyID && state.companyID) {
			return {
				loading: true,
				companyID: null,
			};
		} else {
			return {
				loading: false,
			};
		}
		return null;
	}

	scrollIntoComments = () => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let __comments = params.get('comments');

		if (__comments && document.getElementById('enquiry-item-' + __comments)) {
			document.getElementById('enquiry-item-' + __comments).scrollIntoView({
				behavior: "smooth",
				block: "center"
			})
		}

	}
	//#endregion

	//#region CHANGE EVENT ENQUIRY

	handleSelectSave = (e, data) => {
		this.saveContactField(data.name, e.value);
	};

	saveContactField = (name, val) => {
		let state = Object.assign({}, this.state.company);
		state[name] = val;

		state.modifiedBy = this.props.dealersettings
			? this.props.dealersettings.id
			: '';
		state.modifiedDate = window.firebase.firestore.Timestamp.now();
		this.setState({ company: state });
		const updateRef = this.refCompany.doc(this.state.companyID);
		updateRef
			.update({
				[name]: val,
				modifiedBy: state.modifiedBy,
				modifiedDate: state.modifiedDate,
				modifiedFrom: 'web'
			})
			.then(docRef => {
				toast.notify('Company updated successfully', {
					duration: 2000,
				});

			})
			.catch(error => {
				console.error('Error updating enquiries: ', error);
			});
	};

	handleLeftActiveKey = index => {
		this.setState({ activeLeftKey: index });
	};
	handleRightActiveKey = index => {
		this.setState({ activeRightKey: index });
	};

	//#endregion

	//#region OWNED VEHICLE
	ownedCount = () => {
		const { ownedEnquiryVehicles, ownedVehicles } = this.state;

		return parseInt(ownedEnquiryVehicles.length + ownedVehicles.length);
	};

	handleCompanyOpen = () => {
		this.setState({
			companyModal: {
				show: true,
				docID: this.props.companyid,
			}
		})
	}

	handleCompanyClose = () => {
		this.setState({
			companyModal: {
				show: false,
				docID: null,
			}
		})
	}

	updateCompany = (objCompany) => {
		console.log(objCompany)
	}



	//#endregion

	//#region FILE CHANGES

	handleFileClose = () => {
		this.setState({
			fileModal: {
				show: false,
			},
		});
	}

	handleFileOpen = () => {
		this.setState({
			fileModal: {
				show: true,
			},
		});
	}
	//#endregion



	render() {
		const {
			clientID,
			owner,
			name,
			phone,
			email,
			addedDate,
			addedBy,
			website,
			phoneCode,
			address,
			type,
			companyAbnAcn,
			companyACN,
			companyRMS,
			dealerName,
			isDeleted
		} = this.state.company;

		const {
			activeLeftKey,
			activeRightKey,
			activities,
			showpanel,
			companyModal,
			pagenotFound,
			contacts,
			fileModal,
			showContact,
			showServicepanel, showTradeinPropanel,
		} = this.state;

		const lastActivity = Object.assign(
			{},
			activities.filter(e => e.type === 'activity')[0],
		);
		const { dealersettings } = this.props;
		const canModifyContact = isDeleted ? false : true;
		const isGroupDealer = localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id ? true : false;

		const modifyContactOwner = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.permissions) &&
			dealersettings.rolePermissions.permissions.modifyContactOwner) ? (isGroupDealer ? false : true) : false);

		const _moduleSettings = (!_.isEmpty(dealersettings?.client?.moduleSettings) ? dealersettings.client.moduleSettings : null);

		const enquiryEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.sales) &&
			_moduleSettings.sales.enabled) ? true : false);

		const serviceEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.service) &&
			_moduleSettings.service.enabled) ? true : false);

		const tradeinProEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.tradeinPro) &&
			_moduleSettings.tradeinPro.enabled) ? true : false);

		const enquiryAccess = (enquiryEnabled && (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.modules) &&
			dealersettings.rolePermissions.modules.enquiry) ? true : false);

		const serviceAccess = (serviceEnabled && (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.modules) &&
			dealersettings.rolePermissions.modules.service) ? true : false);

		const tradeinProAccess = (tradeinProEnabled && (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.modules) &&
			dealersettings.rolePermissions.modules.tradeinPro) ? true : false);

		return this.state.companyID ? (
			<>
				<div className='middle-wrapper'>
					<div className="filter-panel"><div className="float-left fleet-head"><h3><Translate text={'Company Details'} /></h3></div></div>
					<div className='enquiry-detailswrap mt-2'>
						<div className='enquiry-detail-head'>
							<div className='float-left  enquiry-details-title-wrap'>
								<div className='enquiry-details-contact'>
									<i className={`ico icon-company mr-2`}></i>
									{name}
								</div>
							</div>
							<div className='float-right'>
								<div className='filter-item-btn ml-2 mt-2'>
									<a
										href='#'
										onClick={e => {
											e.preventDefault();
											let search = window.location.search;
											let params = new URLSearchParams(search);
											let backURL = params.get('back-url');

											if (backURL) this.props.history.push(backURL);
											else this.props.history.push('/contacts/companies');
										}}
									>
										<i className='fa fa-chevron-left' aria-hidden='true'></i>{' '}
										<Translate text={'back'} />
									</a>
								</div>
								{/* {
									(!_.isEmpty(dealersettings.rolePermissions) &&
										!_.isEmpty(dealersettings.rolePermissions.permissions) &&
										dealersettings.rolePermissions.permissions.deleteContact)
										?
										(
											<Dropdown className='enquiry-view-more'>
												<Dropdown.Toggle as={CustomToggle} className='common-button'>
													<i className='ico icon-more'></i>
												</Dropdown.Toggle>
												<Dropdown.Menu
													as={CustomMenu}
													ChildClass="more-dropdown dropsub-fix"
													xplacement="bottom-end"
												>
													<Dropdown.Item eventKey="2" onClick={(e) => { this.handleDeleteCompany(e); }}><i className="ico icon-delete"></i> <Translate text={'delete'} /> </Dropdown.Item>

												</Dropdown.Menu>
											</Dropdown>
										)
										:
										<></>
								} */}

							</div>
						</div>
						<div className='enquiry-detail-bottom'>
							<div className='float-left mt-2'>
								<ul className='enquiry-details'>
									{/* <li> <i className="ico icon-enquiry"></i> {displayID ? displayID : (`CNT-` + this.state.contactID)}</li> */}
									{dealerName ? (
										<li> <span className="blue-color"><i className="ico icon-group"></i>{dealerName}</span></li>
									) : (
										<></>
									)}
									<li>
										{' '}
										<i className='ico icon-call'></i>
										{phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}
									</li>
									<li>
										{' '}
										<i className='ico icon-Mail'></i> {email ? email : '--'}
									</li>
									{/* {!_.isEmpty(company) ? (
										<li>
											{' '}
											<i className='ico icon-company'></i> {company.name}
										</li>
									) : (
											<></>
										)} */}

									<li>
										<i className='ico icon-date'></i>{' '}
										<Translate text={'created'} /> -{' '}
										{addedDate
											? moment.unix(addedDate.seconds).format('DD MMM YYYY')
											: '--'}
									</li>
								</ul>
							</div>
							<div className='float-right'>
								<div className='filter-item-btn filter-owner-btn ml-2'>
									<ReactSelect
										options={this.state.clientUsers}
										name={'owner'}
										placeholder={'select ownership'}
										onChange={this.handleSelectSave}
										value={owner ? owner : addedBy}
										classNamePrefix={`cursor-pointer basic-select`}
										removeClearable={true}
										isSearchable={false}
										isDisabled={modifyContactOwner ? false : true}
									></ReactSelect>
								</div>
							</div>
						</div>
					</div>

					{
						!canModifyContact || isDeleted
							?
							<>
								<div className="enquiry-stagewrap">
									<div className="enquiry-stagewrap-head">
										<div className="float-left">
											{
												!canModifyContact ? (
													<div className="pipeline-enquiry-status ml-2">
														<div className="badge badge-pill badge-readonly">
															<i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
														</div>
													</div>
												) : (
													<></>
												)
											}

											{
												isDeleted ? (
													<div className="pipeline-enquiry-status ml-2">
														<div className="badge badge-pill badge-readonly">
															<i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
														</div>
													</div>
												) : (
													<></>
												)
											}

										</div>
									</div>
								</div>
							</>
							:
							<>
							</>
					}
					<div className='clearfix'></div>

					<div className='enquirydetail-grid flex-column mt-4'>
						{/** LEFT TAB */}
						<aside className='small-column mr-3 '>
							<div className='enquiry-sidebar'>
								<Accordion defaultActiveKey='0'>
									{/** COMPANY INFO */}
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											onClick={e => {
												e.preventDefault();

												if (activeLeftKey === '0')
													this.handleLeftActiveKey(null);
												else this.handleLeftActiveKey('0');
											}}
										>
											<div className='mb-0'>
												<div className='cardlink'>
													<a
														onClick={e => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeLeftKey === '0' ? '' : 'collapsed'
															}`}
													>
														<i className='ico icon-company'></i>{' '}
														<Translate text={'Company Info'} />{' '}
														<i className='arrow-collapse'></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										{
											canModifyContact
												?
												<>
													<div className='edit-button'>
														<a
															href='#'
															className='mini-button'
															onClick={e => {
																e.preventDefault();
																this.handleCompanyOpen()
															}}
														>
															<i className='ico icon-edit'></i>
														</a>
													</div>
												</>
												:
												<>
												</>
										}


										<Accordion.Collapse eventKey='0'>
											<Card.Body>
												<div className='info-table'>
													{/* <div className="contact-info-section-head"> <Translate text={'Primary Contact'} /></div> */}
													<table className='table-fill'>
														<tbody>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'phone'} />
																</td>
																<td className='text-left'>
																	{phone ? CommonHelper.phoneFormat(phoneCode, phone, dealersettings) : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'email'} />
																</td>
																<td className='text-left'>
																	{email ? email : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'type'} />
																</td>
																<td className='text-left'>
																	{type ? type : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'website'} />
																</td>
																<td className='text-left'>
																	{website ? (<a href={`https://${website}`} target='_blank' rel="noopener noreferrer">{website}</a>) : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'address'} />
																</td>
																<td className='text-left'>
																	{address ? address : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'Associated company'} />
																</td>
																<td className='text-left'>
																	{!_.isEmpty(this.state.company.associatedCompany) && this.state.company.associatedCompany.name ? this.state.company.associatedCompany.name : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'ABN'} />
																</td>
																<td className='text-left'>
																	{companyAbnAcn ? companyAbnAcn : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	<Translate text={'ACN'} />
																</td>
																<td className='text-left'>
																	{companyACN ? companyACN : '--'}
																</td>
															</tr>
															<tr>
																<td className='text-left label-text'>
																	{'RMS'}
																</td>
																<td className='text-left'>
																	{companyRMS ? companyRMS : '--'}
																</td>
															</tr>
														</tbody>
													</table>

												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</aside>

						{/** ACTIVITY TAB */}
						<Activity
							contacts={this.state.contacts}
							clientUsers={this.props.clientUsers}
							groupUsers={this.props.groupUsers}
							dealersettings={this.props.dealersettings}
							contactBasicVM={this.state.contactBasicVM}
							loadContacts={this.state.loadContacts}
							companyID={this.props.companyid}
							companyView={true}
							history={this.props.history}
						></Activity>

						{/** RIGHT TAB */}
						<aside className='small-column ml-3'>
							<div className='enquiry-sidebar'>
								<Accordion defaultActiveKey='02'>
									{/** CONTACTS INFO */}
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='02'
											onClick={e => {
												e.preventDefault();

												if (activeRightKey === '02')
													this.handleRightActiveKey(null);
												else this.handleRightActiveKey('02');
											}}
										>
											<div className='mb-0'>
												<div className='cardlink'>
													<a
														onClick={e => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeRightKey === '02' ? '' : 'collapsed'
															}`}
													>
														<i className='ico icon-Contacts'></i>{' '}
														<Translate text={'contacts'} />
														{this.state.contacts.length > 0 ? (
															<span className='badge badge-pill badge-cafe ml-1'>
																{this.state.contacts.length}
															</span>
														) : (
															<></>
														)}
														<i className='arrow-collapse'></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										<div className='add-button'>
											<a
												href='#'
												onClick={e => {
													e.preventDefault();
													this.setState({ showContact: true });
												}}
												className='mini-button'
											>
												<i className='ico icon-add'></i>
											</a>
										</div>
										<Accordion.Collapse eventKey='02'>
											<Card.Body>
												{this.state.contacts &&
													this.state.contacts.length > 0 ? (
													<>
														{this.state.contacts.map((_contact, index) => (
															<div key={_contact.documentID} className="vehicle-item" style={{ fontSize: '14px' }} id="info_wIYkzmVMRdy2JIrZowVN" onClick={() => {
																this.props.history.push(`/`)
																this.props.history.push(`contacts/details/${_contact.documentID}?back-url=/company/details/${this.state.companyID}`)
															}}>
																<div className="flex-vehicle">
																	<div className="vehicle-data">
																		<div className="vehicle-item-title mini-badge"><i className='ico icon-Contacts'></i>{' '}{_contact && CommonHelper.displayContactName([], _contact)}
																		</div>
																		<div className="vehicle-item-owner">
																			<i className="ico icon-enquiry" style={{ marginTop: '2px', paddingRight: '3px' }}></i>
																			{_contact.displayID ? _contact.displayID : (`CNT-` + _contact.documentID)}
																		</div>
																		{
																			_contact.dealerName ?
																				<div className="vehicle-item-owner blue-color">
																					<i className="ico icon-group" style={{ marginTop: '2px', paddingRight: '3px' }}></i>
																					{_contact.dealerName}
																				</div>
																				:
																				<></>
																		}
																		<div className="vehicle-item-owner">
																			<i className="ico icon-Mail" style={{ marginTop: '2px', paddingRight: '3px' }}></i>
																			{_contact.email ? _contact.email : '--'}
																		</div>
																		<div className="vehicle-item-owner">
																			<i className="ico icon-call" style={{ marginTop: '2px', paddingRight: '3px' }}></i>
																			{_contact.phone ? CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, dealersettings) : '--'}
																		</div>

																	</div>
																</div>
															</div>
														))}
													</>
												) : (
													<>
														<p className='details-accordion-no-items'>
															<Translate text={'No Contacts Found'} />
														</p>
													</>
												)}
											</Card.Body>
										</Accordion.Collapse>
									</Card>

									{/** ENQUIRY INFO */}
									{
										enquiryAccess
											?
											<>
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='01'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '01')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('01');
														}}
													>
														<div className='mb-0'>
															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '01' ? '' : 'collapsed'
																		}`}
																>
																	<i className='ico icon-enquiry'></i>{' '}
																	<Translate text={'enquiries'} />
																	{this.state.enquiriesCount > 0 ? (
																		<span className='badge badge-pill badge-cafe ml-1'>
																			{this.state.enquiriesCount}
																		</span>
																	) : this.state.enquiries.length > 0 ? (
																		<span className='badge badge-pill badge-cafe ml-1'>
																			{this.state.enquiries.length}
																		</span>
																	) : (
																		<></>
																	)}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													<Accordion.Collapse eventKey='01'>
														<Card.Body>
															{this.state.enquiries &&
																this.state.enquiries.length > 0 ? (
																<>
																	{this.state.enquiries.map((_enquiry, index) => (
																		<EnquiryInfo
																			key={index}
																			enquiry={_enquiry}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			sidepanelOpen={this.sidepanelOpen}
																			history={this.props.history}
																			showMore={true}
																			dealersettings={this.props.dealersettings}
																			companyView={true}
																		></EnquiryInfo>
																	))}
																	{
																		this.state.enqPagingLoader ? (<div className="pull-left mt-2 w-100"><ContentOverlay active={true} /></div>) :
																			this.state.enquiryhasMore.length > 0 ?
																				(<div className="load-more-button"> <a href="#" onClick={(e) => {
																					e.preventDefault();
																					this.setState({ enqPagingLoader: true });
																					this.loadPagingEnquiry();
																				}}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
																				: (<></>)
																	}
																</>
															) : (
																<>
																	<p className='details-accordion-no-items'>
																		<Translate text={'noEnquiriesFound'} />
																	</p>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>
											</>
											:
											<></>
									}
									{/** SERVICE INFO */}
									{
										serviceAccess
											?
											<>
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='04'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '04')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('04');
														}}
													>
														<div className='mb-0'>

															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '04' ? '' : 'collapsed'
																		}`}
																>
																	<i className='ico icon-service-settings'></i>{' '}
																	<Translate text={'services'} />
																	{this.state.services.length > 0 ? (
																		<span className='badge badge-pill badge-cafe ml-1'>
																			{this.state.services.length}
																		</span>
																	) : (
																		<></>
																	)}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													<Accordion.Collapse eventKey='04'>
														<Card.Body>
															{this.state.services &&
																this.state.services.length > 0 ? (
																<>
																	{this.state.services.map((_enquiry, index) => (
																		<ServiceInfo
																			key={index}
																			service={_enquiry}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			sidepanelOpen={this.sidepanelServiceOpen}
																			history={this.props.history}
																			showMore={false}
																			dealersettings={dealersettings}
																			companyView={true}
																		></ServiceInfo>
																	))}
																</>
															) : (
																<>
																	<div className='text-center p-3'>
																		<p>
																			<Translate text={'No Service Available'} />
																		</p>

																	</div>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>
											</>
											:
											<></>
									}

									{/** TRADEINPRO INFO */}
									{
										tradeinProAccess
											?
											<>
												<Card>
													<Accordion.Toggle
														as={Card.Header}
														eventKey='15'
														onClick={e => {
															e.preventDefault();

															if (activeRightKey === '15')
																this.handleRightActiveKey(null);
															else this.handleRightActiveKey('15');
														}}
													>
														<div className='mb-0'>

															<div className='cardlink'>
																<a
																	onClick={e => {
																		e.preventDefault();
																	}}
																	className={`btn btn-link ${activeRightKey === '15' ? '' : 'collapsed'
																		}`}
																>
																	<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein-module-icon')}></i>{' '}
																	<Translate text={'tradeIn'} />
																	{this.state.tradeinPro.length > 0 ? (
																		<span className='badge badge-pill badge-cafe ml-1'>
																			{this.state.tradeinPro.length}
																		</span>
																	) : (
																		<></>
																	)}
																	<i className='arrow-collapse'></i>
																</a>
															</div>
														</div>
													</Accordion.Toggle>

													<Accordion.Collapse eventKey='15'>
														<Card.Body>
															{this.state.tradeinPro &&
																this.state.tradeinPro.length > 0 ? (
																<>
																	{this.state.tradeinPro.map((_enquiry, index) => (
																		<TIPInfo
																			key={index}
																			fields={_enquiry}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			sidepanelOpen={this.sidepanelTIPOpen}
																			history={this.props.history}
																			showMore={false}
																			dealersettings={dealersettings}
																			companyView={true}
																		></TIPInfo>
																	))}
																</>
															) : (
																<>
																	<div className='text-center p-3'>
																		<p>
																			<Translate text={'No Tradein Available'} />
																		</p>
																	</div>
																</>
															)}
														</Card.Body>
													</Accordion.Collapse>
												</Card>
											</>
											:
											<></>
									}


									{/** FILES INFO */}
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='03'
											onClick={e => {
												e.preventDefault();

												if (activeRightKey === '03')
													this.handleRightActiveKey(null);
												else this.handleRightActiveKey('03');
											}}
										>
											<div className='mb-0'>
												<div className='cardlink'>
													<a
														onClick={e => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeRightKey === '03' ? '' : 'collapsed'
															}`}
													>
														<i className='ico icon-files'></i> <Translate text={'Files'} />{' '}
														<i className='arrow-collapse'></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										<div className='add-button'>
											<a
												href='#'
												onClick={e => {
													e.preventDefault();
													this.handleFileOpen();
												}}
												className='mini-button'
											>
												<i className='ico icon-add'></i>
											</a>
										</div>
										<Accordion.Collapse eventKey='03'>
											<Card.Body>
												<Files
													companyID={this.state.companyID}
													contacts={contacts}
													dealersettings={this.props.dealersettings}
													clientUsers={this.props.clientUsers}
													groupUsers={this.props.groupUsers}
													filePath={`companies/${this.state.companyID}/files`}
												></Files>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</aside>
					</div>

				</div>

				<LayoutConsumer>
					{({ dealersettings, clientUsers, groupUsers }) => (
						<StageProvider
							dealersettings={dealersettings}
							clientUsers={clientUsers}
							groupUsers={groupUsers}
							history={this.props.history}
							path={'contacts'}
						>
							<>
								<PopUpModal show={companyModal.show}>
									<AddCompany
										show={companyModal.show}
										docID={companyModal.docID}
										handleClose={this.handleCompanyClose}
										title={'company'}
										dealersettings={this.props.dealersettings}
										clientUsers={this.props.clientUsers}
										groupUsers={this.props.groupUsers}
										isReadOnly={false}
										isFilterApplied={false}
										updateCompany={this.updateCompany}
									>
									</AddCompany>
								</PopUpModal>

								<PopUpModal show={showContact}>
									<AddContact
										show={showContact}
										company={this.state.company}
										handleClose={this.handleContactClose}
										title={'addContact'}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										history={this.props.history}
									></AddContact>
								</PopUpModal>

								{/* <SidePanel
									clsActive={showpanel.clsActive}
									clsName={showpanel.clsName}
									sidepanelClose={this.sidepanelClose}
									title=''
								>
									<QuickView
										enquiryid={showpanel.enquiryid}
										history={this.props.history}
										sidepanelClose={this.sidepanelClose}
									/>
								</SidePanel> */}
								<QuickEnquiryInfo
									{...this.props}
									enquiryid={showpanel.enquiryid}
									clsActive={showpanel.clsActive}
									clsName={showpanel.clsName}
									sidepanelClose={this.sidepanelClose}
								/>

								<SidePanel
									clsActive={showServicepanel.clsActive}
									clsName={showServicepanel.clsName}
									sidepanelClose={this.sidepanelServiceClose}
									title=''
								>
									<QuickViewService
										serviceid={showServicepanel.serviceid}
										history={this.props.history}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										sidepanelClose={this.sidepanelServiceClose}
										isReadOnlyView={localStorage.defaultModule === 'service' ? false : true}
										handleModuleChange={this.props.handleModuleChange}
									/>
								</SidePanel>

								<SidePanel
									clsActive={showTradeinPropanel.clsActive}
									clsName={showTradeinPropanel.clsName}
									sidepanelClose={this.sidepanelTIPClose}
									title=''
								>
									<QuickViewTradeinPro
										tradeinproid={showTradeinPropanel.tradeinproid}
										history={this.props.history}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										sidepanelClose={this.sidepanelTIPClose}
										isReadOnlyView={localStorage.defaultModule === 'tradeinPro' ? false : true}
										handleModuleChange={this.props.handleModuleChange}
									/>
								</SidePanel>

								<PopUpModal show={fileModal.show}>
									<AddFile
										show={fileModal.show}
										companyID={this.state.companyID}
										handleClose={this.handleFileClose}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										filePath={`companies/${this.state.companyID}/files`}
									>
									</AddFile>
								</PopUpModal>
							</>
						</StageProvider>
					)}
				</LayoutConsumer>
			</>
		) : pagenotFound ? (
			<PageNotFound />
		) : (
			<LoaderOverlay text='Fetching company details...' active={true} />
		);
	}
}
